import { Component, OnInit, Input } from "@angular/core";
import { SystemData } from "src/app/models";
import { DrugStore } from "src/app/store";
import { FormGroup } from "@angular/forms";
import { EditDrugService } from "src/app/services/edit-drug.service";

@Component({
    selector: "app-drug-category",
    templateUrl: "./drug-category.component.html"
})
export class DrugCategoryComponent implements OnInit {

    @Input() systemData: SystemData;
    @Input() drugCatFG: FormGroup;
    @Input() drugCategoryList: any;
    source: string[] = [];
    constructor(private _drugStore: DrugStore,  private _editDrgSvc: EditDrugService) { }

    ngOnInit() {
        if(this.drugCategoryList["userDrugCats"] && this.drugCategoryList["userDrugCats"].length)
                    this.drugCategoryList["userDrugCats"].map(val => this.source.push(val["Name"].toUpperCase()));
    }

    makeDrugCatFgDirty() {
        this.drugCatFG.markAsDirty();
    }
}
