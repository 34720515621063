import { Component, OnInit, Input, ChangeDetectorRef } from "@angular/core";
import { DrugTabUtils } from "src/app/utils/drug-tabs.utils";
import { FormGroup } from "@angular/forms";
import { drugWarningHeader } from "../drug.constants";
import { AlertService } from "src/app/services";
import { MsgConfig } from "src/app";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PrintService } from "src/app/services/print.service";
import { SystemData } from "src/app/models";
import * as wjcGrid from "@grapecity/wijmo.grid"
import { CommonUtil, WijimoUtil } from "src/app/utils";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-edit-drug-counselling",
  templateUrl: "./edit-drug-counselling.component.html"
})
export class EditDrugCounsellingComponent implements OnInit {

    @Input() drugFG: FormGroup;
    @Input() drugId: number;
    @Input() systemData: SystemData;
    @Input() resetDrugInfo: any;
    wjHeaders: any;
    wjHeadersWarn: any;
    warnHeader = drugWarningHeader;
    counselingInfo: { result: any; dosageItemS: any; drugWarnItemS: any; } = { result: null, dosageItemS: null, drugWarnItemS: null };
    languageCode: any;
    medGuide: any;
    pdfContent: SafeResourceUrl;
    modalRef: any;
    activeModal: any;
    drugPregInfo: any;
    unsubscribe$ : Subject<void> = new Subject();
    constructor(private _drugTabUtils: DrugTabUtils, private _modalSvc: NgbModal,
        private _printServ: PrintService, private _comnUtil: CommonUtil,
        private _sanitizer: DomSanitizer, private _alertSer: AlertService, private _wijimoUtils: WijimoUtil, private _cdr: ChangeDetectorRef) { }

    ngOnInit() {
        this.getCounsellingData(true);
        if (!this.drugFG.value["Drug"]["IsWarningCodeChkd"]) {
            this.drugFG.controls["Drug"].patchValue({
                IsWarningCodeChkd: false
            });
        }
        this.patchDefaultValHeaders();
        this._wijimoUtils.wjSavedData$.pipe(takeUntil(this.unsubscribe$)).subscribe(w => {
            if (w && w["WijmoKey"] && (w["WijmoKey"] === "drugCounsellingListWJ" || w["WijmoKey"] === "drugCounsellingWarningListWJ" )) {
                this.patchDefaultValHeaders();
            }
        });
        if (this.resetDrugInfo) {
            const drugCounsellingInfo = this.drugFG.controls["Drug"] as FormGroup;
            drugCounsellingInfo.controls["Therapeutic"].setValue(this.resetDrugInfo.therapeutic);
            drugCounsellingInfo.controls["TherapeuticCode"].setValue(this.resetDrugInfo.therapeuticcode);
        }
    }

    focusToSave(event) {
        this._comnUtil.focusById("saveButton", event);
    }

    async getCounsellingData(isEng) {
        this.languageCode = isEng;
        const data = {
            Therapeutic: this.drugFG.value["Drug"]["Therapeutic"],
            TherapeuticCode: this.drugFG.value["Drug"]["TherapeuticCode"], lang: isEng, DrugCode: this.drugFG.value["Drug"]["DrugCode"]
        };
        this.counselingInfo = await this._drugTabUtils.getDrugCounsellingData(data);
    }

    async patchDefaultValHeaders() {
        const drugDosageId = document.getElementById("drugCouncellingId");
        let colWidth = drugDosageId ? drugDosageId.clientWidth : 0;
        const storedWJ = await this._wijimoUtils.getWJSavedData("drugCounsellingListWJ");
        this.wjHeaders = this._wijimoUtils.patchDefHeader("drugCounsellingListWJ", storedWJ, null,colWidth);
        const storedWJWarn = await this._wijimoUtils.getWJSavedData("drugCounsellingWarningListWJ");
        this.wjHeadersWarn = this._wijimoUtils.patchDefHeader("drugCounsellingWarningListWJ", storedWJWarn);
    }

    async printMedGuide(content) {
        this.medGuide = await this._drugTabUtils.getMedGuidById(this.drugId);
        if (this.medGuide && this.medGuide.size > 0 && this.medGuide.type === "application/pdf") {
            const urlCreator = window.URL;
            this.pdfContent = this._sanitizer.bypassSecurityTrustResourceUrl(
                urlCreator.createObjectURL(this.medGuide)+"#toolbar=0"
            );
            this.openModal(content);
        } else {
            this._alertSer.error(MsgConfig.MEDGUIDE_NOT_AVAILABLE);
        }
    }

    print() {
        const newBlob = new Blob([this.medGuide], { type: "application/pdf" });
        const reader = new FileReader();
        reader.readAsDataURL(newBlob);
        reader.onloadend = function () {
            const base64data = reader.result;
            this._printServ.printPdf(base64data);
            this.closeModal();
        }.bind(this);
    }

    openModal(content) {
        if (content === "DrugCounselling") {
            this.activeModal = "DrugCounselling";
        } else {
            this.modalRef = this._modalSvc.open(content, {
                size: "lg",
                centered: true,
                keyboard: false
            });
        }
    }

    closeModal() {
        this.activeModal = null;
        if (this.modalRef) {
            this.modalRef.close();
            this.modalRef = null;
        }
    }

    async getPregrancyInfo(content) {
        this.drugPregInfo = await this._drugTabUtils.getPregancyInfo(this.drugId);
        this.modalRef = this._modalSvc.open(content, {
            size: "lg",
            keyboard: false,
            backdrop: false,
            centered : true
        });
    }

    loadedWJRows(flex: wjcGrid.FlexGrid) {
        flex.collapseGroupsToLevel(0);
    }

    async checkFGDirty(cntrlName, event) {
        const fg: any = this.drugFG.controls["Drug"];
        if (fg.controls[cntrlName].dirty && fg.value[cntrlName]) {
            await this.getCounsellingData(this.languageCode);
            fg.controls[cntrlName].markAsTouched();
            this._cdr.detectChanges();
             fg.controls[cntrlName].markAsUntouched();
         }
        else if (!fg.value[cntrlName]) {
            fg.controls[cntrlName].patchValue(0);
        }
        document.getElementById(cntrlName === "Therapeutic" ? "TherapeuticCode" : "Warn1")?.focus();
    }

    init(flex: wjcGrid.FlexGrid) {
        flex.columnHeaders.rows[0].wordWrap = true;
        flex.autoSizeRow(0, true);
    }
    ngOnDestroy(){
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}