import {
    Component,
    OnInit,
    Input,
    ViewChild,
    Output,
    EventEmitter,
    HostListener
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { NgbModalConfig, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PrivMaskGuard } from "src/app/guards";
import { AlertService, RxService, UserService } from "src/app/services";
import { NRxUtils } from "src/app/utils";
import { NRxSaveUtils } from "src/app/utils/nRx-save.util";
import { RxStore } from "src/app/store";
import { Observable } from "rxjs";
import { MsgConfig } from "src/app";

@Component({
    selector: "app-notes",
    templateUrl: "./notes.component.html",
    styles: []
})
export class NotesComponent implements OnInit {
    insurncListModified: any;
    modelRef: any;
    notes: string;
    openOverride: boolean;
    savedValue: any;
    override: boolean;
    drugIntrctn: any;
    drugInterctn$: Observable<any>;

    @Input() rxFG: FormGroup;
    @Input() isCash: boolean;

    @Output()
    IsPopUpClosed = new EventEmitter<any>();
    @Output()  CloseEditMode = new EventEmitter<any>();

    @ViewChild("RxNOTES", { static: true })
    RxNOTES: any;

    get RxNotes(): FormGroup {
        return this.rxFG.get("RxNotes") as FormGroup;
    }

    rxType: any;
    hasNoteChngPriv: boolean;
    categoryId: string;
    privType: string;
    notesSaved: boolean;
    oldNotes: string;
    userFullName:string;
    notesSubCatList: any   = []
    @Input() IsFromRxHistory:any;
    @HostListener("window:keydown", ["$event"])
    keyEventAlt(event: KeyboardEvent) {
        if (event.altKey && !this._modalService.hasOpenModals()) {
            if (event.which === 83) { // s
                event.preventDefault();
                if (this.rxType === "er") {
                    this.saveNotes();
                }
            } else if (event.which === 67) { // c
                if (this.rxType === "er") {
                    event.preventDefault();
                    this.cancelOnEdit(true);
                }
            }
        } else  if (event.which === 27 && this.modelRef && this._modalService.hasOpenModals()) { // esc
            this.modelRef.close();
            this.modelRef = null;
            event.preventDefault();
            event.stopImmediatePropagation();
            this.IsPopUpClosed.emit();
        }
    }

    constructor(config: NgbModalConfig,
        private _modalService: NgbModal,
        private _privMaskGuard: PrivMaskGuard,
        private _alertServ: AlertService,
        private _userService:UserService,
        private _nrxUtls: NRxUtils, private _saveRxUtls: NRxSaveUtils, private _rxStore: RxStore,
        private _rxServ: RxService) {
        config.backdrop = "static";
        this.drugInterctn$ = this._rxStore.drugInterctn$;
    }

    async ngOnInit() {
        this.drugIntrctn = this.drugInterctn$["source"]["value"];
        this.rxType = this.rxFG.get("rxType").value;
       if (this.rxType !== "er") {
            this.modelRef = this._modalService.open(this.RxNOTES, {centered: true, keyboard:false});
        }

        this.hasNoteChngPriv = this._nrxUtls.hasNotesChangPrv();
        this.notes = this.rxFG.value.Prescription.Remarks;
        this.oldNotes = this.notes;
        const techName = this._userService.getToken("TechUserName");
        const pharName = this._userService.getToken("PhUserName");
        this.userFullName = techName ? techName.toUpperCase() : pharName.toUpperCase();
        const resp = await this._nrxUtls.getNotesLog("Rx", 0) as any;
        if(resp && resp.NoteCat?.length) {
            this.notesSubCatList = resp.NoteCat;
            this.RxNotes.controls["NoteCatId"].patchValue( !this.RxNotes.value["NoteCatId"] ? this.notesSubCatList[0].Id :  this.RxNotes.value["NoteCatId"]);
        }
    }

    saveNotes() {
        const prescription: any = this.rxFG.controls.Prescription;
        prescription.controls["Remarks"].setValue(this.notes);
        if (this.rxType === "er") {
            this._alertServ.success("Notes saved successfully.");
            this.notesSaved = true;
            this.cancelOnEdit(false);
        } else {
            this.closeModal();
        }
    }

    changeNotes(notes: string) {
        this.notes = notes;
        if (this.rxFG &&  this.rxFG.controls["Prescription"]) {
            this.rxFG.controls["Prescription"].patchValue({Remarks: this.notes});
        }
    }

    closeModal() {
        if (this.modelRef) {
        this.modelRef.close();
        }
        this.IsPopUpClosed.emit();
    }

    cancelOnEdit(onClickCancel) {
        if (onClickCancel && ((this.oldNotes !== this.rxFG.value["Prescription"]["Remarks"]) && !this.notesSaved)) {
            this.changeNotes(!this.oldNotes ? null : this.oldNotes);
        }
        if(this.IsFromRxHistory){
            this.CloseEditMode.emit("cancel")
        }else{
            this.IsPopUpClosed.emit("Patient1");
        }


    }

    checkHasPrivsToAccess(category: string, privType: string) {
        return this._privMaskGuard.canActivate(category, privType);
    }

    OverrideRes(val) {
        this.openOverride = false;
        if (val) {
          this.override = true;
        } else {
            this.notes = this.rxFG.value.Prescription.Remarks;
        }
    }

    openOveride(value) {
        this.categoryId = "RxNotes";
        this.privType = "Change";
        this.openOverride = true;
        this.notes = value;
        this.savedValue = value;
    }

    focusOutFromCancel(event) {
        if (document.getElementById("rxnotes")) {
            event.preventDefault();
            document.getElementById("rxnotes").focus();
        }
    }

    async saveNotesWithRx() {
        const prescription: any = this.rxFG.controls.Prescription;
        prescription.controls["Remarks"].setValue(this.notes);
        const rxInfo = this._saveRxUtls.setValuesBfrSave(this.rxFG, this.rxType, this.drugIntrctn, this.isCash);
        const resp = await this._saveRxUtls.updateRxInfo(rxInfo);
        if (resp && !isNaN(+resp.RxNum) && +resp.RxNum > 0) {
            this.rxFG.controls["PrescReFill"].patchValue({"ModifiedDtTm": new Date()});
            rxInfo["PrescReFill"]["ModifiedDtTm"] = new Date();
            this._alertServ.success("Notes saved successfully.");
            this._rxStore.storeRxInfo(rxInfo);
            this.notesSaved = true;
        } else if (resp && isNaN(+resp.Message)) {
            if (resp.Message === MsgConfig.MULTIPLE_USER_SAVE) {
                this._alertServ.error(MsgConfig.MULTIPLE_USER_SAVE_MSG);
            } else if (typeof resp.Message === "string") {
            this._alertServ.error("Unable to save Notes.Missing " + resp.Message + " fields.");
            }
        } else {
            this._alertServ.error("Unable to Notes.");
        }
        this.cancelOnEdit(false);
    }
    /*ngOnDestroy(): void { // commented as due to this Saved notes is getting removed
            if (this.notes) {
                this.notes = "";
                this.rxFG.controls["Prescription"].patchValue({Remarks : this.notes});
            }

    }*/

}
