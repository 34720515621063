<ng-template #COPY let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">Search</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body edit--drug">
        <div class="exprx--common-block">
            <div>
                <div class="seconday-search--grid">
                    <div class="row">
                        <div class="col-4 color_cyan">
                            Search Drug</div>
                        <div class="col-8">
                            <!-- <div class="text-right">
                                <div><i class="far fa-lg fa-long-arrow-alt-right color_orange"></i><span> for
                                        Equv ;
                                    </span><b class="color_orange">Shift + <i
                                            class="far fa-lg fa-long-arrow-alt-right color_orange"></i></b><span> for Gen Equv</span></div>
                            </div> -->
                        </div>
                    </div>
                    <app-searchbox-global #drugSearch [IsFocused]="isDrugSearchFocused"
                        (FocusedOutofSearch)="isFocusedOutofSearch($event)" [SearchFor]="'Drug'" [IsAddNew]="false"
                        [DisableSerchFil]="true" [DisableRadioButtons]="true" (SelectedDrug)="copyDrug($event)" [SearchFrm]="'commonDrugSearch'" (CloseDrugSearch)="closeDrugsearchPopup($event)">
                    </app-searchbox-global>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="text-right pull-right col-md-10 padding-0">
            <button type="button" class="hotkey_success" (click)="closeModal()" (keydown.enter)="closeModal()"
                appShortcutKey [AltKey]="'c'">
                <span>C</span> Cancel
            </button>
        </div>
    </div>
</ng-template>
