export class AuditLog {
    tenantid: number = null;
    userid: number = null;
    entity: string = null;
    action: string = null;
    entityid: string = null;
    created: any = null;
    delta: Delta = null;
    overrideUserId: number = null;
    Comments: string = null;
    OverrideResult: boolean = null;
    username: any = null;
    overrrideUserName: any = null;
    isOverride: any = null;
}

export class NewAuditLog {
    tenantid: number = null;
    userid: number = null;
    entity: string = null;
    action: string = null;
    entityid: string = null;
    created: any = null;
    delta: Delta = null;
    comments: string = null;
    username: string = null;
}

export class Delta {
    original: Meta[] = [];
    changed: Meta[] = [];
}

export class Meta {
    name: string = null;
    value: any = null;
}

export class RxAuditLog {


    rxAuditModelForRefill = [
        {
            HeaderName: "Drug Lot#",
            ParentName: "PrescReFill",
            ControlName: "LotNum"
        },
        {
            HeaderName: "Lot Exp Dt",
            ParentName: "PrescReFill",
            ControlName: "LotExpiryDate"
        },
        {
            HeaderName: "Drug NDC",
            ParentName: "Drug",
            ControlName: "ndc"
        },
        {
            HeaderName: "Drug Name",
            ParentName: "Drug",
            ControlName: "drugname"
        },
        {
        HeaderName: "DAW",
        ParentName: "rxRelatedCodeInfo",
        ControlName: "DawCode"
        },
        {
            HeaderName: "Prescriber Address 1",
            ParentName: "Prescriber",
            SecondParentName: "PrescriberAddresses",
            ControlName: "AddressLine1"
        },
        {
            HeaderName: "Prescriber Address 2",
            ParentName: "Prescriber",
            SecondParentName: "PrescriberAddresses",
            ControlName: "AddressLine2"
        },
        {
            HeaderName: "Prescriber Zip Code",
            ParentName: "Prescriber",
            SecondParentName: "PrescriberAddresses",
            ControlName: "ZipCode"
        },
        {
            HeaderName: "Prescriber Zip Extention",
            ParentName: "Prescriber",
            SecondParentName: "PrescriberAddresses",
            ControlName: "Extension"
        },
        {
            HeaderName: "Prescriber State",
            ParentName: "Prescriber",
            SecondParentName: "PrescriberAddresses",
            ControlName: "StateName"
        },
        {
            HeaderName: "Prescriber City",
            ParentName: "Prescriber",
            SecondParentName: "PrescriberAddresses",
            ControlName: "CityName"
        },
        {
            HeaderName: "SPI#",
            ParentName: "Prescriber",
            SecondParentName: "PrescriberAddresses",
            ControlName: "SPINum"
        },
    ];

    rxAuditModel = [
        {
            HeaderName: "Patient Name",
            ParentName: "Patient",
            ControlName: "fullname"
        },
        {
            HeaderName: "Drug NDC",
            ParentName: "Drug",
            ControlName: "ndc"
        },
        {
            HeaderName: "Drug Name",
            ParentName: "Drug",
            ControlName: "drugname"
        },
        {
            HeaderName: "Prescriber Name",
            ParentName: "Prescriber",
            ControlName: "fullname"
        },
        {
            HeaderName: "DAW",
            ParentName: "rxRelatedCodeInfo",
            ControlName: "DawCode"
        },
        {
            HeaderName: "Compound",
            ParentName: "Drug",
            ControlName: "drugtypeid"
        },
        {
            HeaderName: "Drug Lot#",
            ParentName: "PrescReFill",
            ControlName: "LotNum"
        },
        {
            HeaderName: "Lot Exp Dt",
            ParentName: "PrescReFill",
            ControlName: "LotExpiryDate"
        },
        {
            HeaderName: "Qty Ordered",
            ParentName: "PrescReFill",
            ControlName: "OrdQty"
        },
        {
            HeaderName: "Qty Disp",
            ParentName: "PrescReFill",
            ControlName: "DispQty"
        },
        {
            HeaderName: "Due Date",
            ParentName: "RxFile",
            ControlName: "DueDtTm"
        },
        {
            HeaderName: "Deferred Reason Name",
            ParentName: "RxFile",
            ControlName: "DeferredReasonName"
        },
        {
            HeaderName: "Sig Code",
            ParentName: "SigCodes",
            ControlName: "SigCode"
        },
        {
            HeaderName: "Sig Description",
            ParentName: "SigCodes",
            ControlName: "Description"
        },
        {
            HeaderName: "Alternate Sig Description",
            ParentName: "SigCodes",
            ControlName: "AlternateLangDescription"
        },
        {
            HeaderName: "Prior Auth",
            ParentName: "PrescReFill",
            ControlName: "PriorAuthNum"
        },
        {
            HeaderName: "Auth Type",
            ParentName: "Prescription",
            ControlName: "PAType"
        },
        {
            HeaderName: "FollowUp tag",
            ParentName: "PrescRefill_RxTags",
            ControlName: "Name"
        },
        {
            HeaderName: "Is Non Rx",
            ParentName: "PrescReFill",
            ControlName: "IsNonRx"
        },
        {
            HeaderName: "Is PRN",
            ParentName: "PrescReFill",
            ControlName: "IsPRN"
        },
        {
            HeaderName: "Is 340B",
            ParentName: "PrescReFill",
            ControlName: "Is340B"
        },
        {
            HeaderName: "Bucket",
            ParentName: "PrescReFill",
            ControlName: "BucketName"
        },
        {
            HeaderName: "Spcl Prog",
            ParentName: "rxRelatedCodeInfo",
            ControlName: "SplProgram"
        },
        {
            HeaderName: "No. of Labels",
            ParentName: "PrescReFill",
            ControlName: "NoOfLabels"
        },
        {
            HeaderName: "Is Hold Rx",
            ParentName: "PrescReFill",
            ControlName: "IsRxHold"
        },
        {
            HeaderName: "Ord Date",
            ParentName: "PrescReFill",
            ControlName: "OrderDtTm"
        },
        {
            HeaderName: "Fill Date",
            ParentName: "PrescReFill",
            ControlName: "FillDtTm"
        },
        {
            HeaderName: "Days Supp.",
            ParentName: "PrescReFill",
            ControlName: "SupplyDays"
        },
        {
            HeaderName: "Ordered Days",
            ParentName: "PrescReFill",
            ControlName: "OrderDays"
        },
        {
            HeaderName: "Auth Refill",
            ParentName: "Prescription",
            ControlName: "ReFillNum"
        },
        {
            HeaderName: "Rx Serial#",
            ParentName: "Prescription",
            ControlName: "PrescSN"
        },
        {
            HeaderName: "Refill Num",
            ParentName: "PrescReFill",
            ControlName: "ReFillNum"
        },
        {
            HeaderName: "Tech Init",
            ParentName: "PrescReFill",
            ControlName: "TechInitials"
        },
        {
            HeaderName: "R.PH Init",
            ParentName: "PrescReFill",
            ControlName: "RegPharmacist"
        },
        {
            HeaderName: "Bill To",
            ParentName: "rxRelatedCodeInfo",
            ControlName: "BillTo"
        },
        {
            HeaderName: "Bill As",
            ParentName: "rxRelatedCodeInfo",
            ControlName: "InsuPriority"
        },
        {
            HeaderName: "Deliv.Meth",
            ParentName: "rxRelatedCodeInfo",
            ControlName: "DeliveryModeCode"
        },
        {
            HeaderName: "U&C",
            ParentName: "PrescReFill",
            ControlName: "UnitPriceUNC"
        },
        {
            HeaderName: "AWP",
            ParentName: "PrescReFill",
            ControlName: "UnitPriceAWP"
        },
        {
            HeaderName: "Price CD",
            ParentName: "rxRelatedCodeInfo",
            ControlName: "PriceCode"
        },
        {
            HeaderName: "Disc CD",
            ParentName: "rxRelatedCodeInfo",
            ControlName: "DiscCode"
        },
        {
            HeaderName: "Ingredient Cost",
            ParentName: "PrescReFill",
            ControlName: "Price"
        },
        {
            HeaderName: "P.Fee",
            ParentName: "PrescReFill",
            ControlName: "ProfFee"
        },
        {
            HeaderName: "Oth. Amount",
            ParentName: "PrescReFill",
            ControlName: "OtherAmnt"
        },
        {
            HeaderName: "Tax",
            ControlName: "TaxAmt"
        },
        {
            HeaderName: "Tot. Price",
            ParentName: "PrescReFill",
            ControlName: "TotalAmt"
        },
        {
            HeaderName: "Discount",
            ParentName: "PrescReFill",
            ControlName: "Discount"
        },
        {
            HeaderName: "Balance Due",
            ParentName: "PrescReFill",
            ControlName: "BalDue"
        },
        {
            HeaderName: "Cost Rx",
            ParentName: "PrescReFill",
            ControlName: "TotalRxCost"
        },
        {
            HeaderName: "Order Status",
            ParentName: "PrescReFill",
            ControlName: "OrderStatus"
        },
        {
            HeaderName: "Status",
            ParentName: "RxBill",
            ControlName: "StatusId"
        },
        {
            HeaderName: "Notes",
            ParentName: "Prescription",
            ControlName: "Remarks"
        },
        {
            HeaderName: "Professional Service Fee",
            ParentName: "RxOtherAmount",
            ControlName: "ProfFee"
        },
        {
            HeaderName: "Incentive Amount",
            ParentName: "RxOtherAmount",
            ControlName: "Incentive"
        },
        {
            HeaderName: "Flat Sales Tax Amount",
            ParentName: "RxOtherAmount",
            ControlName: "FlatSalesTax"
        },
        {
            HeaderName: "Sales Tax Percentage",
            ParentName: "RxOtherAmount",
            ControlName: "SalesTaxPer"
        },
        {
            HeaderName: "Percentage Sales Tax Amount",
            ParentName: "RxOtherAmount",
            ControlName: "SalesTax"
        },
        {
            HeaderName: "Percentage Sales Tax Basis",
            ParentName: "RxOtherAmount",
            ControlName: "SalesTaxBasisName"
        },
        {
            HeaderName: "Patient Paid Amount",
            ParentName: "RxOtherAmount",
            ControlName: "PaidAmnt"
        },
        {
            HeaderName: "Basis of Cost",
            ParentName: "RxOtherAmount",
            ControlName: "CostBasisCatName"
        },
        {
            HeaderName: "Usual & Customary",
            ParentName: "RxOtherAmount",
            ControlName: "UnitPriceUNC"
        },
        {
            HeaderName: "Other Amount",
            ParentName: "RxOtherAmount",
            ControlName: "OtherAmntMisc"
        },
        {
            HeaderName: "Profit Amount",
            ControlName: "ProfitAmnt"
        },
        {
            HeaderName: "Rx#",
            ParentName: "RxFile",
            ControlName: "Rx#"
        },
    ];

    dmeAuditModel = [
        {
            HeaderName: "Locator Code",
            ControlName: "LocatorCode"
        },
        {
            HeaderName: "Category Of Services",
            ControlName: "ServiceCat"
        },
        {
            HeaderName: "Clinic Speciality",
            ControlName: "ClinicSp"
        },
        {
            HeaderName: "Accident Code",
            ControlName: "AccidentCode"
        },
        {
            HeaderName: "Handicap Child Pg",
            ControlName: "IsHandiChildPg"
        },
        {
            HeaderName: "Family Planning",
            ControlName: "IsFamilyPlan"
        },
        {
            HeaderName: "Possible Disability",
            ControlName: "IsDisability"
        },
        {
            HeaderName: "Rental",
            ControlName: "IsRental"
        }
    ];
    pickupRxAuditModel = [
        {
            HeaderName: "Picked Up From",
            ControlName: "PickUpFromId"
        },
        {
            HeaderName: "Picked Up Date",
            ControlName: "ExpDtTm"
        },
        {
            HeaderName: "Picked Up Time",
            ControlName: "ExpDate"
        },
        {
            HeaderName: "Is Picked Up",
            ControlName: "IsPickedUp"
        },
        {
            HeaderName: "Last Name",
            ControlName: "PkLastName"
        },
        {
            HeaderName: "First Name",
            ControlName: "PkFirstName"
        },
        {
            HeaderName: "Relation",
            ControlName: "RelRemarks"
        },
        {
            HeaderName: "Pick Up ID",
            ControlName: "PkIdNum"
        },
        {
            HeaderName: "Pick Up ID Qualifier",
            ControlName: "IdProof"
        },
        {
            HeaderName: "ID Author",
            ControlName: "PkIdIssuer"
        },
        {
            HeaderName: "Drop Pick Qualifier",
            ControlName: "DropPickQulifierName"
        },
        {
            HeaderName: "Notes",
            ControlName: "Remarks"
        }
    ];
    wcomAuditModel = [
        {
            HeaderName: "Date Of Injury",
            ParentName: "WorkmanComp",
            ControlName: "AccidentDt"
        },
        {
            HeaderName: "Carrier Name",
            ParentName: "CarrOrg",
            ControlName: "Name"
        },
        {
            HeaderName: "Carrier Address",
            ParentName: "CarrAdd",
            ControlName: "AddressLine1"
        },
        {
            HeaderName: "Carrier Zip",
            ParentName: "CarrAdd",
            ControlName: "ZipCode"
        },
        {
            HeaderName: "Carrier State",
            ParentName: "CarrAdd",
            ControlName: "StateName"
        },
        {
            HeaderName: "Carrier City",
            ParentName: "CarrAdd",
            ControlName: "CityName"
        },
        {
            HeaderName: "Employer Name",
            ParentName: "EmpOrg",
            ControlName: "Name"
        },
        {
            HeaderName: "Employer Contact",
            ParentName: "EmpOrg",
            ControlName: "ContPerson"
        },
        {
            HeaderName: "Employer Phone",
            ParentName: "EmPhn",
            ControlName: "xPhone"
        },
        {
            HeaderName: "Employer Claim#",
            ParentName: "WorkmanComp",
            ControlName: "ClaimNum"
        },
        {
            HeaderName: "Employer Address",
            ParentName: "EmpAdress",
            ControlName: "AddressLine1"
        },
        {
            HeaderName: "Employer Zip",
            ParentName: "EmpAdress",
            ControlName: "ZipCode"
        },
        {
            HeaderName: "Employer State",
            ParentName: "EmpAdress",
            ControlName: "StateName"
        },
        {
            HeaderName: "Employer City",
            ParentName: "EmpAdress",
            ControlName: "CityName"
        },
        {
            HeaderName: "Bill Entity type",
            ParentName: "WorkmanComp",
            ControlName: "BillEntCatName"
        },
        {
            HeaderName: "Gen. Eq. Prod Id",
            ParentName: "WorkmanComp",
            ControlName: "GenEqProdNum"
        },
        {
            HeaderName: "Gen. Eq. Prod Id Q",
            ParentName: "WorkmanComp",
            ControlName: "GenEqProdName"
        },
        {
            HeaderName: "Pay to ID",
            ParentName: "WorkmanComp",
            ControlName: "PayToNum"
        },
        {
            HeaderName: "Pay to Qualifier",
            ParentName: "WorkmanComp",
            ControlName: "PayToQName"
        },
        {
            HeaderName: "Payor Name",
            ParentName: "WorkmanComp",
            ControlName: "PayToName"
        },
        {
            HeaderName: "Payer Address",
            ParentName: "PayerAddress",
            ControlName: "AddressLine1"
        },
        {
            HeaderName: "Payer Zip",
            ParentName: "PayerAddress",
            ControlName: "ZipCode"
        },
        {
            HeaderName: "Payer State",
            ParentName: "PayerAddress",
            ControlName: "StateName"
        },
        {
            HeaderName: "Payer City",
            ParentName: "PayerAddress",
            ControlName: "CityName"
        },
    ];

    rxExtraAuditModel = [
        {
            HeaderName: "Level Of Service",
            ParentName: "rxExtra",
            ControlName: "ServiceLvlName"
        },
        {
            HeaderName: "Intermediary Authorization ID",
            ParentName: "rxExtra",
            ControlName: "InterimAuthCode"
        },
        {
            HeaderName: "Intermediary Authorization Type",
            ParentName: "rxExtra",
            ControlName: "IntermAuthCatName"
        },
        {
            HeaderName: "Alternate Id",
            ParentName: "rxExtra",
            ControlName: "AlternateCode"
        },
        {
            HeaderName: "Rx Origin",
            ParentName: "rxExtra",
            ControlName: "RxOriginName"
        },
        {
            HeaderName: "Rx Expiry Date",
            ParentName: "rxExtra",
            ControlName: "RxExpDt"
        },
        {
            HeaderName: "Patient Assign Indicator",
            ParentName: "rxExtra",
            ControlName: "IsPatAssignInd"
        },
        {
            HeaderName: "Include In MAR",
            ParentName: "rxExtra",
            ControlName: "IsIncludedInMar"
        },
        {
            HeaderName: "Insurance Specific Value",
            ParentName: "rxExtra",
            ControlName: "InsSpVal"
        },
        {
            HeaderName: "Batch File Billing Code",
            ParentName: "rxExtra",
            ControlName: "BatchFileBillName"
        },
        {
            HeaderName: "Batch File Billing Ref",
            ParentName: "rxExtra",
            ControlName: "BatchFileBillRef"
        },
        {
            HeaderName: "Refill Reminder",
            ParentName: "rxExtra",
            ControlName: "IdRefillReminded"
        },
        {
            HeaderName: "Use PA For Refills",
            ParentName: "rxExtra",
            ControlName: "IsPAUsedForAllRefills"
        },
        {
            HeaderName: "Max Daily Dose",
            ParentName: "rxExtra",
            ControlName: "MaxDailyDose"
        },
        {
            HeaderName: "Billing Days Supply",
            ParentName: "rxExtra",
            ControlName: "BillDaysSup"
        },
        {
            HeaderName: "Subm. Clarification 1",
            ParentName: "rxExtra",
            ControlName: "SubClarification1Name"
        },
        {
            HeaderName: "Subm. Clarification 2",
            ParentName: "rxExtra",
            ControlName: "SubClarification2Name"
        },
        {
            HeaderName: "Subm. Clarification 3",
            ParentName: "rxExtra",
            ControlName: "SubClarification3Name"
        },
        {
            HeaderName: "Other Coverage Cd",
            ParentName: "rxExtra",
            ControlName: "OtherCoverageName"
        },
        {
            HeaderName: "Delay Reason Cd",
            ParentName: "rxExtra",
            ControlName: "DelayReasonName"
        },
        {
            HeaderName: "Ph. Service Type",
            ParentName: "rxExtra",
            ControlName: "PharmServiceCatName"
        },
        {
            HeaderName: "Originally Prescribed Product Type",
            ParentName: "prescInfo",
            ControlName: "OrgPrescProdCatName"
        },
        {
            HeaderName: "Originally Prescribed Product",
            ParentName: "prescInfo",
            ControlName: "OrgPrescDrugName"
        },
        {
            HeaderName: "Originally Prescribed Product NDC",
            ParentName: "prescInfo",
            ControlName: "OrgPrescProd"
        },
        {
            HeaderName: "Originally Prescribed Quantity",
            ParentName: "prescInfo",
            ControlName: "OrgPrescQty"
        },
        {
            HeaderName: "Coupon Type",
            ParentName: "couponInfo",
            ControlName: "CouponCatName"
        },
        {
            HeaderName: "Coupon Number",
            ParentName: "couponInfo",
            ControlName: "CouponNum"
        },
        {
            HeaderName: "Coupon Value",
            ParentName: "couponInfo",
            ControlName: "CouponVal"
        },
        {
            HeaderName: "Procedure Modifiers",
            ParentName: "procModifier",
            SubCntrlName: "CPTCode"
        },
        {
            HeaderName: "Diagnosis Code",
            ParentName: "diagCode",
            SubCntrlName: "DiagnosisId"
        },
        {
            HeaderName: "Treatment Type",
            ParentName: "rxExtra",
            ControlName: "TreatmentTypeName"
        }
    ];

    durAuditModel = [
        {
            HeaderName: "Reason For Service",
            ControlName: "ServReasonName"
        },
        {
            HeaderName: "Professional Service",
            ControlName: "ProfServName"
        },
        {
            HeaderName: "Result Of Service",
            ControlName: "ServResult"
        },
        {
            HeaderName: "Level Of Effort",
            ControlName: "EffortLvlName"
        },
        {
            HeaderName: "Co-Agent ID",
            ControlName: "CoAgentNum"
        },
        {
            HeaderName: "Co-Agent ID Type",
            ControlName: "CoAgentIdType"
        },
        {
            HeaderName: "Clinical Significance",
            ControlName: "ClinicalSignfName"
        },
        {
            HeaderName: "Pharmacist Comment",
            ControlName: "PhComment"
        },
    ];

    rxClinicalAuditModel = [
        {
            HeaderName: "Measure Date",
            ControlName: "MeasureDt"
        },
        {
            HeaderName: "Measure Time",
            ControlName: "MeasureTm"
        },
        {
            HeaderName: "Measure Value",
            ControlName: "ObsVal"
        },
        {
            HeaderName: "Dimension",
            ControlName: "Dimension"
        },
        {
            HeaderName: "Measure Unit",
            ControlName: "MeasurementUnit"
        },
    ];

    rxDiscontReasonAuditModel = [
        // {
        //     HeaderName: "DiscontReason",
        //     ControlName: "DiscontReasonId"
        // },
        {
            HeaderName: "Discontinued Note",
            ControlName: "Note"
        },
    ];

    // rxOrdStatusAuditModel = [
    //     {
    //         HeaderName: "Ord. Status",
    //         ControlName: "OrderStatus"
    //     }
    // ];
}


export class PatAuditLog {
    patAuditModel = [
        {
            HeaderName: "Last Name",
            ParentName: "Person",
            ControlName: "LastName"
        },
        {
            HeaderName: "Middle Name",
            ParentName: "Person",
            ControlName: "MiddleName"
        },
        {
            HeaderName: "First Name",
            ParentName: "Person",
            ControlName: "FirstName"
        },
        {
            HeaderName: "DOB",
            ParentName: "Person",
            ControlName: "BirthDt"
        },
        {
            HeaderName: "Gender",
            ParentName: "Person",
            ControlName: "GenderId"
        },
        {
            HeaderName: "Email",
            ParentName: "Emails",
            ControlName: "xeMail"
        },
        {
            HeaderName: "Telephone",
            ParentName: "Phones",
            ControlName: "xPhone"
        },
        {
            HeaderName: "Mobile",
            ParentName: "Phones",
            ControlName: "xPhone"
        },
        {
            HeaderName: "Work Number",
            ParentName: "Phones",
            ControlName: "xPhone"
        },
        {
            HeaderName: "Remarks",
            ParentName: "Patient",
            ControlName: "Remarks"
        },
        {
            HeaderName: "Address Line1",
            ParentName: "Addresses",
            ControlName: "AddressLine1"
        },
        {
            HeaderName: "Address Line2",
            ParentName: "Addresses",
            ControlName: "AddressLine2"
        },
        {
            HeaderName: "Zip Code",
            ParentName: "Addresses",
            ControlName: "ZipCode"
        },
        {
            HeaderName: "Zip Extention",
            ParentName: "Addresses",
            ControlName: "Extension"
        },
        {
            HeaderName: "State",
            ParentName: "Addresses",
            ControlName: "StateName"
        },
        {
            HeaderName: "City",
            ParentName: "Addresses",
            ControlName: "CityName"
        },
        {
            HeaderName: "Family Info",
            ParentName: "PatFamily",
            ControlName: "Name"
        },
        {
            HeaderName: "Insuarance Type",
            ParentName: "InsuCarrier",
            ControlName: "InsurerCode"
        },
        // {
        //     HeaderName: "Insurer Id",
        //     ParentName: "Insurances",
        //     ControlName: "InsurerId"
        // },
        {
            HeaderName: "Policy#",
            ParentName: "InsuCards",
            ControlName: "PolicyNum"
        },
        {
            HeaderName: "Group#",
            ParentName: "InsuGroups",
            ControlName: "Name"
        },
        // {
        //     HeaderName: "RelationShip",
        //     ParentName: "Insurances",
        //     ControlName: "Relation"
        // },
        {
            HeaderName: "Person Code",
            ParentName: "Insurances",
            ControlName: "PerNum"
        },
        {
            HeaderName: "Card Holder FN",
            ParentName: "InsuCards",
            ControlName: "FirstName"
        },
        {
            HeaderName: "Card Holder LN",
            ParentName: "InsuCards",
            ControlName: "LastName"
        },
        {
            HeaderName: "Card Expires",
            ParentName: "InsuCards",
            ControlName: "ExpiryDt"
        },
        {
            HeaderName: "Pat.Chart",
            ParentName: "PatientChartNums",
            ControlName: "ChartNum"
        },
        {
            HeaderName: "Language",
            ParentName: "Patient",
            ControlName: "LanguageCode"
        },
        {
            HeaderName: "Special coverage", // this input code commented.
            ParentName: "PatientSplPrograms",
            ControlName: "SplProgram"
        },
        {
            HeaderName: "Refill Reminder",
            ParentName: "PatSettings",
            ControlName: "IsRefillRemSet"
        },
        {
            HeaderName: "Is Ez-cap",
            ParentName: "PatSettings",
            ControlName: "IsEZCapReqd"
        },
        {
            HeaderName: "Is Packaging Pat",
            ParentName: "PatSettings",
            ControlName: "IsPkgAllowed"
        },
        // {
        //     HeaderName: "Workflow Patient?",
        //     ParentName: "PatSettings",
        //     ControlName: "IsWorkflowPatient"
        // },
        {
            HeaderName: "Print Drug Counselling?",
            ParentName: "PatSettings",
            ControlName: "IsPrintAllowed"
        },
        {
            HeaderName: "Active",
            ParentName: "Person",
            ControlName: "ActiveStatusId"
        },
        {
            HeaderName: "Discount Code",
            ParentName: "PatPricings",
            ControlName: "DiscCode"
        },
        {
            HeaderName: "Price Code Brand",
            ParentName: "PatPricings",
            ControlName: "BrandPriceCode"
        },
        {
            HeaderName: "Parent Preferred Contacts", // Need to check in Audit log and change.
            ParentName: "PerPrefContact",
            ControlName: "IsPreferred"
        },
        {
            HeaderName: "Price Code Generic",
            ParentName: "PatPricings",
            ControlName: "GenericPriceCode"
        },
        {
            HeaderName: "Applied To",
            ParentName: "PatPricings",
            ControlName: "IsAppliedCashRx"
        },
        {
            HeaderName: "Contact Type",
            ControlName: "ContactType",
        },
        {
            HeaderName: "Contact Name",
            ParentName: "PerPrefContact",
            ControlName: "Contactname"
        },
        {
            HeaderName: "Contact Number",
            ParentName: "PerPrefContact",
            ControlName: "ContactNumber"
        },
        {
            HeaderName: "Is Preferred",
            ParentName: "PerPrefContact",
            ControlName: "IsPreferred"
        },
        {
            HeaderName: "Allergies",
            ParentName: "PatAllergies",
            ControlName: "AllergyName"
         },
         {
            HeaderName: "RelationShipName",
            ParentName: "Insurances",
            ControlName: "RelationName"
        },
        {
            HeaderName: "340B",
            ParentName: "Patient",
            ControlName: "Is340B"
        },
        {
            HeaderName: "Patient Assignment",
            ParentName: "Insurances",
            ControlName: "IsPatAssigned"
        },
        {
            HeaderName: "Patient Consent Received?",
            ControlName: "SubscriptionResponse",
            ParentName:"PatientMessageSettings"
        },
        {
            HeaderName: "Send General Alerts",
            ControlName: "SendGeneralAlerts",
            ParentName:"PatientMessageSettings"
        },
        {
            HeaderName: "Send Refill Reminder Alerts?",
            ControlName: "SendRefillReminderAlerts",
            ParentName:"PatientMessageSettings"
        },
        {
            HeaderName: "Send Ready for Pickup Alerts?",
            ControlName: "SendReadyForPickupAlerts",
            ParentName:"PatientMessageSettings"
        },
        {
            HeaderName: "Send Birthday Alerts?",
            ControlName: "SendBirthdayGreetingAlerts",
            ParentName:"PatientMessageSettings"
        },
        {
            HeaderName: "New Rx Received Message Alerts?",
            ControlName: "SendNewRxMessageAlerts",
            ParentName:"PatientMessageSettings"
        },
    ];
    patientPhoneAuditModel = [
        {
            HeaderName: "Contact Type",
            ControlName: "ContactType",
        },
        {
            HeaderName: "Contact Name",
            ControlName: "Contactname"
        },
        {
            HeaderName: "Contact Number",
            ControlName: "ContactNumber"
        },
        {
            HeaderName: "Is Preferred",
            ControlName: "IsPreferred"
        }
    ];
    additionalModel = [
        {
            HeaderName: "Insurance Type",
            ParentName: "InsuCarrier",
            ControlName: "InsurerCode"
        },
        {
            HeaderName: "Insurance Id",
            ParentName: "InsuCard",
            ControlName: "PolicyNum"
        },
        {
            HeaderName: "Group#",
            ParentName: "Insgroup",
            ControlName: "Name"
        },
        {
            HeaderName: "Relationship",
            ParentName: "Insurance",
            ControlName: "Relation"
        },
        {
            HeaderName: "Person Code",
            ParentName: "Insurance",
            ControlName: "PerNum"
        },
        {
            HeaderName: "Card Holder FN",
            ParentName: "InsuCard",
            ControlName: "FirstName"
        },
        {
            HeaderName: "Card Holder LN",
            ParentName: "InsuCard",
            ControlName: "LastName"
        },
        {
            HeaderName: "Card Expires",
            ParentName: "InsuCard",
            ControlName: "ExpiryDt"
        },
        {
            HeaderName: "Billing Priority",
            ParentName: "Insurance",
            ControlName: "InsuPriId"
        },
        {
            HeaderName: "Is Active",
            ParentName: "Insurance",
            ControlName: "ActiveStatusId"
        },
        {
            HeaderName: "Patient Assignment",
            ParentName: "Insurance",
            ControlName: "IsPatAssigned"
        },
        {
            HeaderName: "Comments",
            ParentName: "Insurance",
            ControlName: "Remarks"
        },
    ];
    payRecordTrasmissionsModel = [
        {
            HeaderName: "Date Paid",
            ControlName: "DatePaid"
        },
        {
            HeaderName: "Coverage Code",
            ControlName: "CoverageCD"
        },
        {
            HeaderName: "Ingredient Cost Paid",
            ControlName: "ICostPaid"
        },
        {
            HeaderName: "Basis of Ingredient Cost Paid",
            ControlName: "BASISOFREI"
        },
        {
            HeaderName: "Dispensing Fee Paid",
            ControlName: "DFEEPaid"
        },
        {
            HeaderName: "Basis of Dispensing Fee Paid",
            ControlName: "BOC_DFEE"
        },
        {
            HeaderName: "Patient Pay Amount",
            ControlName: "PTPayment"
        },
        {
            HeaderName: "Basis of Patient Pay Amount",
            ControlName: "BOC_Copay"
        },
        {
            HeaderName: "Flat Sales Tax",
            ControlName: "FSTaxAmt"
        },
        {
            HeaderName: "Basis of Flat Sales Tax",
            ControlName: "BOC_FSTax"
        },
        {
            HeaderName: "Percentage Sales Tax",
            ControlName: "PSTaxAmt"
        },
        {
            HeaderName: "Basis of Percentage Sales Tax",
            ControlName: "BOC_PSTax"
        },
        {
            HeaderName: "Tax Rate",
            ControlName: "PSTaxRate"
        },
        {
            HeaderName: "Incentive Amount",
            ControlName: "INCAMTPaid"
        },
        {
            HeaderName: "Professional Service Fee",
            ControlName: "PSFEEPaid"
        },
        {
            HeaderName: "Other Amount Paid",
            ControlName: "OthAMTPaid"
        },
        {
            HeaderName: "Other Payer Paid Amount Recognized",
            ControlName: "OthPayRecg"
        },
        {
            HeaderName: "Total Amount Paid",
            ControlName: "TotAmtPaid"
        },
        {
            HeaderName: "Other Pay Amount1",
            ControlName: "OthPayAmt1"
        },
        {
            HeaderName: "Type1",
            ControlName: "OthPayQ1"
        },
        {
            HeaderName: "Other Pay Amount2",
            ControlName: "OthPayAmt2"
        },
        {
            HeaderName: "Type2",
            ControlName: "OthPayQ2"
        },
        {
            HeaderName: "Other Pay Amount3",
            ControlName: "OthPayAmt3"
        },
        {
            HeaderName: "Type3",
            ControlName: "OthPayQ3"
        },
        {
            HeaderName: "Amount Applied To Periodic Deductible",
            ControlName: "Amt_PERDED"
        },
        {
            HeaderName: "Amount Applied To Copay/Co-Ins",
            ControlName: "Amt_COPINS"
        },
        {
            HeaderName: "Authorization #",
            ControlName: "Auth_No"
        },
        {
            HeaderName: "Tax Exempt",
            ControlName: "TaxExempt"
        },
        {
            HeaderName: "Sales Tax Amount",
            ControlName: "Amt_SaleTx"
        },
        {
            HeaderName: "Amount Appl. Product Selection",
            ControlName: "Amt_PRODSL"
        },
        {
            HeaderName: "Amount Exceeding Max",
            ControlName: "Amt_EXCMAX"
        },
        {
            HeaderName: "% Sales Tax Basis Paid",
            ControlName: "PSTaxBasisPaid"
        },
        {
            HeaderName: "Accumulated Deductible Amount",
            ControlName: "AccumDedAmount"
        },
        {
            HeaderName: "Remaining Deductible Amount",
            ControlName: "RemDedAmount"
        },
        {
            HeaderName: "Remaining Benefit Amount",
            ControlName: "RemBenefitAmount"
        }
    ];
    morePatInfoModel = [
        {
            HeaderName: "Delivery Method",
            ControlName: "DeliveryMode"
        },
        {
            HeaderName: "Pregnancy Code Id",
            ControlName: "PregnancyCode"
        },
        {
            HeaderName: "Smoker Code Id",
            ControlName: "SmokerCode"
        },
        {
            HeaderName: "Place of Services",
            ControlName: "ServicePlaceName"
        },
        {
            HeaderName: "Employer ID",
            ControlName: "EmployerNum"
        },
        {
            HeaderName: "Home Plan",
            ControlName: "HomePlan"
        },
        {
            HeaderName: "Plan ID",
            ControlName: "HomePlanNum"
        },
        {
            HeaderName: "Social Security#",
            ControlName: "SSNum"
        },
        {
            HeaderName: "Drivers License#",
            ControlName: "DriveLicenseNum"
        },
        {
            HeaderName: "Eligibility Criteria",
            ControlName: "EligibleCdName"
        },
        {
            HeaderName: "Patient Salutation",
            ControlName: "Saluation"
        },
        {
            HeaderName: "Patient Suffix",
            ControlName: "NameSuffix"
        },
        {
            HeaderName: "Privacy Acknowledged Date",
            ControlName: "PrivacyAckDt"
        },
        {
            HeaderName: "Patient Residence",
            ControlName: "ResidenceCatName"
        },
        {
            HeaderName: "Privacy Acknowledged?",
            ControlName: "IsPrivacyAcked"
        },
        {
            HeaderName: "Pat Assignment?",
            ControlName: "IsFullyInsured"
        },
        {
            HeaderName: "Deceased",
            ControlName: "IsDeceased"
        },
        {
            HeaderName: "Primary Provider",
            ControlName: "PrescName"
        },
        {
            HeaderName: "Patient Race",
            ControlName: "PatientRaceName"
        },
        {
            HeaderName: "Patient Comorbidity Status",
            ControlName: "PatientComorbidityStatusName"
        },
        {
            HeaderName: "Patient Ethnicity",
            ControlName: "PatientEthnicityName"
        },
        {
            HeaderName: "Patient Relation",
            ControlName: "PatientRelRemarks"
        },
        {
            HeaderName: "Maiden Middle Name",
            ControlName: "MaidenMiddleName"
        },
        {
            HeaderName: "Guardien First Name",
            ControlName: "GuardienFirstName"
        },
        {
            HeaderName: "Guardien Last Name",
            ControlName: "GuardienLastName"
        },
        {
            HeaderName: "Species Code",
            ControlName: "SpeciesCdId"
        },
        {
            HeaderName: "Driver License Expiration Date",
            ControlName: "DriverLicExpDt"
        },
        {
            HeaderName: "Patient Id Type",
            ControlName: "PatientQualifName"
        },
        {
            HeaderName: "ID#",
            ControlName: "IdProofVal"
        },
    ];

    facilityPatModel = [
        {
            HeaderName: "Facility Code",
            ControlName: "FacilityName"
        },
        {
            HeaderName: "Physician",
            ControlName: "PrescribName"
        },
        {
            HeaderName: "Alternate Physician",
            ControlName: "AltPresName"
        },
        {
            HeaderName: "Primary Physician",
            ControlName: "PrimePrescName"
        },
        {
            HeaderName: "Facility Patient#",
            ControlName: "FacPatNum"
        },
        {
            HeaderName: "Admit Date",
            ControlName: "AdmitDt"
        },
        {
            HeaderName: "Location",
            ControlName: "FacLocationName"
        },
        {
            HeaderName: "Station",
            ControlName: "FacStationName"
        },
        {
            HeaderName: "Floor",
            ControlName: "FloorNum"
        },
        {
            HeaderName: "Room",
            ControlName: "RoomNum"
        },
        {
            HeaderName: "Bed",
            ControlName: "BedNum"
        },
        {
            HeaderName: "Diagnosis Note",
            ControlName: "DiagnosisNote"
        },
        {
            HeaderName: "Order Note",
            ControlName: "OrderNote"
        },
        {
            HeaderName: "Diet Note",
            ControlName: "DietNote"
        },
        {
            HeaderName: "Miscellaneous",
            ControlName: "Remarks"
        },
        {
            HeaderName: "Cycle Fill?",
            ControlName: "IsCycleFillAllowed"
        },
        {
            HeaderName: "Unit Dose?",
            ControlName: "IsUnitDoseAllowed"
        },
        {
            HeaderName: "Print Med Sheet?",
            ControlName: "IsPrintMedSheet"
        },
    ];

    deliveryAddModel = [
        {
            HeaderName: "Address Line1",
            ControlName: "AddressLine1"
        },
        {
            HeaderName: "Address Line2",
            ControlName: "AddressLine2"
        },
        {
            HeaderName: "ZipCode",
            ControlName: "ZipCode"
        },
        {
            HeaderName: "Zip Extention",
            ControlName: "Extension"
        },
        {
            HeaderName: "State Name",
            ControlName: "StateName"
        },
        {
            HeaderName: "City Name",
            ControlName: "CityName"
        },
        {
            HeaderName: "Is Active",
            ControlName: "IsActive"
        }
    ];
}

export class PrescAuditModel {
    prescInfo = [
        {
            HeaderName: "Quick Code",
            ParentName: "Prescriber",
            ControlName: "QckCode"
        },
        {
            HeaderName: "Last Name",
            ParentName: "Person",
            ControlName: "LastName"
        },
        {
            HeaderName: "First Name",
            ParentName: "Person",
            ControlName: "FirstName"
        },
        {
            HeaderName: "Telephone1",
            ParentName: "Telephone1",
            ControlName: "xPhone"
        },
        {
            HeaderName: "Telephone2",
            ParentName: "Telephone2",
            ControlName: "xPhone"
        },
        {
            HeaderName: "Mobile Number",
            ParentName: "Mobil",
            ControlName: "xPhone"
        },
        {
            HeaderName: "Email",
            ParentName: "EMail",
            ControlName: "xeMail"
        },
        {
            HeaderName: "Notes",
            ParentName: "Prescriber",
            ControlName: "Remarks"
        },
        {
            HeaderName: "Address 1",
            ParentName: "Address",
            ControlName: "AddressLine1"
        },
        {
            HeaderName: "Address 2",
            ParentName: "Address",
            ControlName: "AddressLine2"
        },
        {
            HeaderName: "ZipCode",
            ParentName: "Address",
            ControlName: "ZipCode"
        },
        {
            HeaderName: "Zip Extention",
            ParentName: "Address",
            ControlName: "Extension"
        },
        {
            HeaderName: "State",
            ParentName: "Address",
            ControlName: "StateName"
        },
        {
            HeaderName: "City",
            ParentName: "Address",
            ControlName: "CityName"
        },
        {
            HeaderName: "Fax",
            ParentName: "Fax",
            ControlName: "xPhone"
        },
        {
            HeaderName: "License Number",
            ParentName: "Prescriber",
            ControlName: "PrescbNum"
        },
        {
            HeaderName: "NPI Number",
            ParentName: "Person",
            ControlName: "NPINum"
        },
        {
            HeaderName: "Prescriber Id Type",
            ParentName: "Prescriber",
            ControlName: "PrescIdCatId"
        },
        {
            HeaderName: "Prescriber Type",
            ParentName: "Prescriber",
            ControlName: "PrescCatId"
        },
        {
            HeaderName: "SPI Number",
            ParentName: "PrescAdds",
            ControlName: "SPINum"
        },
        {
            HeaderName: "Medicaid Pro#",
            ParentName: "Prescriber",
            ControlName: "MedAidNum"
        },
        {
            HeaderName: "DEA Reg#",
            ParentName: "Prescriber",
            ControlName: "DEANum"
        },
        {
            HeaderName: "Ins. Restrictions",
            ParentName: null,
            ControlName: "InsuBlackList"
        },
        {
            HeaderName: "State SP Id",
            ParentName: "Prescriber",
            ControlName: "StateSpId"
        },
        {
            HeaderName: "Other Id",
            ParentName: "Prescriber",
            ControlName: "OtherIdNum"
        },
        {
            HeaderName: "Sales Person",
            ParentName: "PrescriberSalesPerson",
            ControlName: "SalesPersonId"
        },
        {
            HeaderName: "Facility",
            ParentName: "Prescriber",
            ControlName: "FacilityId"
        },
        {
            HeaderName: "Primary Speciality",
            ParentName: "PrimarySpeciality",
            ControlName: "SpecialityId"
        },
        {
            HeaderName: "Secondary Speciality",
            ParentName: "SecondarySpeciality",
            ControlName: "SpecialityId"
        },
        {
            HeaderName: "Tertiary Speciality",
            ParentName: "TertiarySpeciality",
            ControlName: "SpecialityId"
        },
        {
            HeaderName: "Is Active",
            ParentName: "Person",
            ControlName: "ActiveStatusId"
        },
        {
            HeaderName: "XDEA#",
            ParentName: "Prescriber",
            ControlName: "XDEANum"
        },
    ];
}

export class DrugAuditModel {
    drugInfo = [
        {
            HeaderName: "Quick Code",
            ParentName: "Drug",
            ControlName: "QuickCode"
        },
        {
            HeaderName: "Drug Name",
            ParentName: "Drug",
            ControlName: "Name"
        },
        {
            HeaderName: "Drug NDC Code",
            ParentName: "Drug",
            ControlName: "DrugCode"
        },
        {
            HeaderName: "NDC Qualifier",
            ParentName: "Drug",
            ControlName: "NDCQualiId"
        },
        {
            HeaderName: "Universal Product Code",
            ParentName: "Drug",
            ControlName: "UPC"
        },
        {
            HeaderName: "Quantity in Pack",
            ParentName: "Drug",
            ControlName: "QtyPack"
        },
        {
            HeaderName: "Drug Form",
            ParentName: "Drug",
            ControlName: "DrugFormId"
        },
        {
            HeaderName: "Unit Dose Indicator",
            ParentName: "Drug",
            ControlName: "UnitDoseIndId"
        },
        {
            HeaderName: "Drug Type",
            ParentName: "Drug",
            ControlName: "DrugTypeId"
        },
        {
            HeaderName: "Is Brand?",
            ParentName: "Drug",
            ControlName: "IsBranded"
        },
        {
            HeaderName: "Brand Name",
            ParentName: "Drug",
            ControlName: "BrandName"
        },
        {
            HeaderName: "Generic Name",
            ParentName: "Drug",
            ControlName: "GenericName"
        },
        {
            HeaderName: "Strength",
            ParentName: "Drug",
            ControlName: "Strength"
        },
        {
            HeaderName: "Exclude From Data Update",
            ParentName: "Drug",
            ControlName: "IsExclFrmUpdate"
        },
        {
            HeaderName: "Drug Full Name",
            ParentName: "Drug",
            ControlName: "DrugFullName"
        },
        {
            HeaderName: "Price Update",
            ParentName: "Drug",
            ControlName: "IsPriceUpdAuto"
        },
        {
            HeaderName: "FDA Approved?",
            ParentName: "Drug",
            ControlName: "IsFDAApprovedNULL"
        },
        {
            HeaderName: "Is Preferred?",
            ParentName: "Drug",
            ControlName: "IsPreferred"
        },
        {
            HeaderName: "Is 340B?",
            ParentName: "Drug",
            ControlName: "Is340B"
        },
        {
            HeaderName: "Include in Refill Reminder",
            ParentName: "Drug",
            ControlName: "IsIncInRefilRem"
        },
        {
            HeaderName: "Discontinue Date",
            ParentName: "Drug",
            ControlName: "DiscontDt"
        },
        {
            HeaderName: "Is Active?", // changed to radio(Yes/No)
            ParentName: "Drug",
            ControlName: "IsActive"
        },
        {
            HeaderName: "Drug Class",
            ParentName: "Drug",
            ControlName: "DrugClass"
        },
        {
            HeaderName: "Default Sig Code",
            ParentName: "Drug",
            ControlName: "DefaultSigCode"
        },
        {
            HeaderName: "Default Days",
            ParentName: "Drug",
            ControlName: "DefaultDays"
        },
        {
            HeaderName: "Diagnosis Code",
            ParentName: "diagnosisInfo",
            ControlName: "DIAGCODE"
        },
        {
            HeaderName: "Billing Units",
            ParentName: "Drug",
            ControlName: "DrugUnitId"
        },
        {
            HeaderName: "Drug Manufacturer",
            ParentName: "Drug",
            ControlName: "ManufactId"
        },
        {
            HeaderName: "Default Quantity",
            ParentName: "Drug",
            ControlName: "DefaultQty"
        }, // pricing tab - cash pricing
        {
            HeaderName: "Cash Price Scheduled",
            ParentName: "Drug",
            ControlName: "PriceSchId"
        },
        {
            HeaderName: "Surcharge",
            ParentName: "Drug",
            ControlName: "Surcharge"
        },
        {
            HeaderName: "%Off Brand",
            ParentName: "Drug",
            ControlName: "PerOfBrand"
        },
        {
            HeaderName: "Discountable",
            ParentName: "Drug",
            ControlName: "IsDiscountable"
        }, //
        {
            HeaderName: "Pack AWP Price",
            ParentName: "Drug",
            ControlName: "AWPPack"
        },
        {
            HeaderName: "Pack Cost",
            ParentName: "Drug",
            ControlName: "CostPack"
        },
        {
            HeaderName: "Unit AWP Price",
            ParentName: "Drug",
            ControlName: "UnitPriceAWP"
        },
        {
            HeaderName: "Unit Cost",
            ParentName: "Drug",
            ControlName: "UnitPriceCost"
        },
        {
            HeaderName: "Unit Price Direct",
            ParentName: "Drug",
            ControlName: "DirectUnitPrice"
        },//
       /* {
            HeaderName: "AWP Pack",
            ParentName: "Drug",
            ControlName: "AWPPack"
        },
        {
            HeaderName: "Cost Pack",
            ParentName: "Drug",
            ControlName: "CostPack"
        },
        {
            HeaderName: "Unit Price_DIR",
            ParentName: "Drug",
            ControlName: "DirectUnitPrice"
        },
        {
            HeaderName: "Unit AWP Price",
            ParentName: "Drug",
            ControlName: "UnitPriceAWP"
        },
        {
            HeaderName: "Unit Price Cost",
            ParentName: "Drug",
            ControlName: "UnitPriceCost"
        },*/
        {
            HeaderName: "Alternate Cost",
            ParentName: "Drug",
            ControlName: "AlternateCost"
        },
        {
            HeaderName: "MAC",
            ParentName: "Drug",
            ControlName: "MAC"
        },
        {
            HeaderName: "WAC",
            ParentName: "Drug",
            ControlName: "WAC"
        },
        // inventory
        {
            HeaderName: "Manage Inventory",
            ParentName: "DrugPO",
            ControlName: "IsInvManaged"
        },
        {
            HeaderName: "Auto. Order",
            ParentName: "DrugPO",
            ControlName: "IsAutoPO"
        },
        {
            HeaderName: "Reorder level",
            ParentName: "DrugPO",
            ControlName: "ReorderQty"
        },
        {
            HeaderName: "Target Qty",
            ParentName: "DrugPO",
            ControlName: "TargetQty"
        },
        {
            HeaderName: "Preferred Vendor",
            ParentName: "DrugPO",
            ControlName: "PrefVendId"
        }, // Drug counselling tab
        {
            HeaderName: "Therapeutic Class ID",
            ParentName: "Drug",
            ControlName: "Therapeutic"
        },
        {
            HeaderName: "Specific Product",
            ParentName: "Drug",
            ControlName: "TherapeuticCode"
        },
        {
            HeaderName: "Display Warning Codes?",
            ParentName: "Drug",
            ControlName: "IsWarningCodeChkd"
        },
        {
            HeaderName: "Warning Code",
            ParentName: "Drug",
            ControlName: "WarningCode"
        }, // notes
        {
            HeaderName: "Notes",
            ParentName: "Drug",
            ControlName: "Remarks"
        }, // Insurance restriction
        // {
        //     HeaderName: "Is Triplicate",
        //     ParentName: "Drug",
        //     ControlName: "IsTriplicate"
        // },
        {
            HeaderName: "Vaccine Group Name",
            ParentName: "Drug",
            ControlName: "VaccineGroupName"
        }
    ];
    drugInsuranceRestriction = [
        {
            HeaderName: "Apply To All Insurances?",
            ParentName: "drugInsuFG",
            ControlName: "IsAllInsu"
        },
        {
            HeaderName: "Insurances",
            ParentName: "drugInsuFG",
            ControlName: "InsurId"
        },
        /*{
            HeaderName: "Allowed By Insurance?",
            ParentName: "drugInsuFG",
            ControlName: "IsAllowed"
        },
        {
            HeaderName: "Billing NDC",
            ParentName: "drugInsuFG",
            ControlName: "BillingNDC"
        },
        {
            HeaderName: "NDC Qualifier",
            ParentName: "drugInsuFG",
            ControlName: "NDCQualiId"
        },
        {
            HeaderName: "Prior Approval Required?",
            ParentName: "drugInsuFG",
            ControlName: "IsPriorApprReqd"
        },*/
        {
            HeaderName: "Max Refills Allowed",
            ParentName: "drugInsuFG",
            ControlName: "MaxRefillsAllowed"
        },
        {
            HeaderName: "Max Qty Allowed",
            ParentName: "drugInsuFG",
            ControlName: "MaxQtyAllowed"
        },
        {
            HeaderName: "Incentive Amount",
            ParentName: "drugInsuFG",
            ControlName: "Incentive"
        },
        {
            HeaderName: "Vaccine Group",
            ParentName: "drugInsuFG",
            ControlName: "VaccineGrp"
        },
        {
            HeaderName: "Submission Clarification",
            ParentName: "drugInsuFG",
            ControlName: "SubClarificationRem"
        },
        {
            HeaderName: "Basis of Cost",
            ParentName: "drugInsuFG",
            ControlName: "CostBasis"
        },
        {
            HeaderName: "Reason For Service",
            ParentName: "drugInsuFG",
            ControlName: "ServReasonName"
        },
        {
            HeaderName: "Professional Service",
            ParentName: "drugInsuFG",
            ControlName: "ProfServName"
        },
        {
            HeaderName: "Result of Service",
            ParentName: "drugInsuFG",
            ControlName: "ServResult"
        },
        {
            HeaderName: "Level of Effort",
            ParentName: "drugInsuFG",
            ControlName: "EffortLvlName"
        },
        {
            HeaderName: "Co-Agent",
            ParentName: "drugInsuFG",
            ControlName: "CoAgentNum"
        },
        {
            HeaderName: "Co-Agent Type",
            ParentName: "drugInsuFG",
            ControlName: "CoAgentIdType"
        },
        {
            HeaderName: "Clinical Significance",
            ParentName: "drugInsuFG",
            ControlName: "ClinicalSignfName"
        },
        {
            HeaderName: "Pharmacist Comment",
            ParentName: "drugInsuFG",
            ControlName: "PhComment"
        }
        ];
    drugMiscInfo = [
         // Misc. info tab
        {
            HeaderName: "Quantity Qualifier",
            ParentName: "DrugMisc",
            ControlName: "QtyQualId"
        },
        {
            HeaderName: "Potency Unit Code",
            ParentName: "DrugMisc",
            ControlName: "PotencyUnitCodeId"
        },
        {
            HeaderName: "Drug Strength Qualifier",
            ParentName: "DrugMisc",
            ControlName: "DrugStrQualId"
        },
        {
            HeaderName: "Dosage Form Code",
            ParentName: "DrugMisc",
            ControlName: "DoseFrmCodeId"
        },
        {
            HeaderName: "Compound Type",
            ParentName: "DrugMisc",
            ControlName: "CompoundTypId"
        },
        {
            HeaderName: "Route of Administration(SNOMED)",
            ParentName: "DrugMisc",
            ControlName: "AdmnstrnRoute2Id"
        },
        {
            HeaderName: "Medication Guide Available?",
            ParentName: "DrugMisc",
            ControlName: "IsMedGuide"
        },
        {
            HeaderName: "Inventory Location",
            ParentName: "DrugMisc",
            ControlName: "InvLocId"
        },
        {
            HeaderName: "Automated Machine ID",
            ParentName: "DrugMisc",
            ControlName: "AutoMcId"
        },
        {
            HeaderName: "Dispensing Unit Form",
            ParentName: "DrugMisc",
            ControlName: "DispUnitFormId"
        },
        {
            HeaderName: "Dosage Form Description",
            ParentName: "DrugMisc",
            ControlName: "DoseFrmDescId"
        },
        {
            HeaderName: "Route of Administration",
            ParentName: "DrugMisc",
            ControlName: "AdmnstrnRouteId"
        },
        {
            HeaderName: "Lot Number",
            ParentName: "Druglot",
            ControlName: "LotNum"
        },
        {
            HeaderName: "Expiration Date",
            ParentName: "Druglot",
            ControlName: "ExpDt"
        },
        {
            HeaderName: "Expiration Date",
            ParentName: "Druglot",
            ControlName: "ExpDt"
        },
    ];

}

export class InsuranceAuditModel {
    planInfo = [ // Insu carrier
        {
            HeaderName: "Insurance Code",
            ParentName: "InsuCarrier",
            ControlName: "InsurerCode"
        },
        { // organization
            HeaderName: "Insurance Name",
            ParentName: "Organization",
            ControlName: "Name"
        }, // Insuplan
        {
            HeaderName: "Pharmacy Number#",
            ParentName: "InsuPlan",
            ControlName: "PharmacyNum"
        },
        {
            HeaderName: "ID Qualifier",
            ParentName: "InsuPlan",
            ControlName: "InsuPlanIdQualifId"
        },
        {
            HeaderName: "BIN",
            ParentName: "InsuCarrier",
            ControlName: "BINNum"
        },
        {
            HeaderName: "Processor Type",
            ParentName: "InsuPlan",
            ControlName: "ProcCatId"
        },
        {
            HeaderName: "Payor Name",
            ParentName: "InsuPlan",
            ControlName: "PayorId"
        },
        {
            HeaderName: "Medicaid Agency#",
            ParentName: "InsuPlan",
            ControlName: "MedicaidAgNum"
        },
        {
            HeaderName: "Is Active?",
            ParentName: "InsuPlan",
            ControlName: "IsActive"
        },
        {
            HeaderName: "Is Medicare PartD",
            ParentName: "InsuPlan",
            ControlName: "IsMedicareSup"
        },
        {
            HeaderName: "Is Medigap",
            ParentName: "InsuPlan",
            ControlName: "IsMedigapSup"
        },
        {
            HeaderName: "Is Medicaid",
            ParentName: "InsuPlan",
            ControlName: "IsMedicaidSup"
        },
        {
            HeaderName: "Notes",
            ParentName: "InsuPlan",
            ControlName: "Remarks"
        }, // Address
        {
            HeaderName: "Address Line1",
            ParentName: "Address",
            ControlName: "AddressLine1"
        },
        {
            HeaderName: "Zip Code",
            ParentName: "Address",
            ControlName: "ZipCode"
        },
        {
            HeaderName: "Zip Extention",
            ParentName: "Address",
            ControlName: "Extension"
        },
        {
            HeaderName: "State",
            ParentName: "Address",
            ControlName: "StateName"
        },
        {
            HeaderName: "City",
            ParentName: "Address",
            ControlName: "CityName"
        }, // phone
        {
            HeaderName: "Telephone",
            ParentName: "Phone",
            ControlName: "xPhone"
        }, // Organization
        {
            HeaderName: "Contact",
            ParentName: "Organization",
            ControlName: "ContPerson"
        }, // InsuTransmission
        {
            HeaderName: "NCPDP Version",
            ParentName: "InsuTransmission",
            ControlName: "NCPDPVer"
        },
        {
            HeaderName: "Submission Type",
            ParentName: "InsuTransmission",
            ControlName: "InsuXmitSubCatId"
        },
        {
            HeaderName: "Submission Clarification",
            ParentName: "InsuTransmission",
            ControlName: "SubClarifId"
        }, // InsuPlanPrice
        {
            HeaderName: "Basis of Cost",
            ParentName: "InsuPlanPrice",
            ControlName: "CostBasisId"
        },
        {
            HeaderName: "Price Code - Brand",
            ParentName: "InsuPlanPrice",
            ControlName: "BrandPriceSchId"
        },
        {
            HeaderName: "Price Code - Generic",
            ParentName: "InsuPlanPrice",
            ControlName: "GenPriceSchId"
        }, // InsuPlanOther
        {
            HeaderName: "Reprice Refill",
            ParentName: "InsuPlanOther",
            ControlName: "IsRefillRepriced"
        }, // InsuPlanPrice
        {
            HeaderName: "Copay Type",
            ParentName: "InsuPlanPrice",
            ControlName: "CoPayCatId"
        },
        {
            HeaderName: "Copay Brand",
            ParentName: "InsuPlanPrice",
            ControlName: "CoPayBrandAmnt"
        },
        {
            HeaderName: "Copay Generic",
            ParentName: "InsuPlanPrice",
            ControlName: "CoPayGenAmnt"
        },
        {
            HeaderName: "% Off Brand",
            ParentName: "InsuPlanPrice",
            ControlName: "BrandPricePer"
        },
        {
            HeaderName: "% Off Generic",
            ParentName: "InsuPlanPrice",
            ControlName: "GenPricePer"
        },
        {
            HeaderName: "Flat Sales Tax Amount",
            ParentName: "InsuPlanPrice",
            ControlName: "FlatSalesTaxAmt"
        },
        {
            HeaderName: "Tax Rate %",
            ParentName: "InsuPlanPrice",
            ControlName: "TaxRatePer"
        },
        {
            HeaderName: "Sales Tax Basis",
            ParentName: "InsuPlanPrice",
            ControlName: "SalesTaxBasisId"
        },
        {
            HeaderName: "Tax on Rx Item",
            ParentName: "InsuPlanPrice",
            ControlName: "IsTaxOnRx"
        },
        {
            HeaderName: "Tax On OTC Item",
            ParentName: "InsuPlanPrice",
            ControlName: "IsTaxOnOTC"
        },
        {
            HeaderName: "U and C Same?",
            ParentName: "InsuPlanPrice",
            ControlName: "IsUNCSame"
        },
        {
            HeaderName: "Professional Fee",
            ParentName: "InsuPlanPrice",
            ControlName: "ProFee"
        },
        {
            HeaderName: "Add Prof. Fee to OTC?",
            ParentName: "InsuPlanPrice",
            ControlName: "IsPFForOTC"
        },
        {
            HeaderName: "Allow Zero Billing?",
            ParentName: "InsuPlanPrice",
            ControlName: "IsZeroBillAllowed"
        },
        {
            HeaderName: "Allow Zero U&C?",
            ParentName: "InsuPlanPrice",
            ControlName: "IsZeroUNC"
        },
        {
            HeaderName: "Accept Patient Claim Amount Assignment",
            ParentName: "InsuPlanPrice",
            ControlName: "IsAssigntAccepted"
        }, // InsuPlanOther
        {
            HeaderName: "Prescriber ID Type",
            ParentName: "InsuPlanOther",
            ControlName: "PrescIdCatId"
        },
        {
            HeaderName: "Primary Care Prescriber ID",
            ParentName: "InsuPlanOther",
            ControlName: "PrimePrescIdCatId"
        },
        {
            HeaderName: "Max Days Supply ?",
            ParentName: "InsuPlanOther",
            ControlName: "MaxDaysSupply"
        },
        {
            HeaderName: "Max Quantity",
            ParentName: "InsuPlanOther",
            ControlName: "MaxQty"
        },
        {
            HeaderName: "Order To Fill Days",
            ParentName: "InsuPlanOther",
            ControlName: "OrderToFillDays"
        },
        {
            HeaderName: "Max Refills",
            ParentName: "InsuPlanOther",
            ControlName: "MaxRefills"
        },
        {
            HeaderName: "Order To Last Refill",
            ParentName: "InsuPlanOther",
            ControlName: "OrderToLastRefill"
        }, // InsuPlanPrice
        {
            HeaderName: "Allow Partial Fill?",
            ParentName: "InsuPlanPrice",
            ControlName: "IsPartialFillAllowed"
        }, // InsuPlanOther
        {
            HeaderName: "'Refill Due Consumption Threshold (%)",
            ParentName: "InsuPlanOther",
            ControlName: "RefillDueConsumptionThreshold"
        },
        {
            HeaderName: "Sort Order(Rx#, Date)",
            ParentName: "InsuPlanOther",
            ControlName: "IsSortByRx"
        },
        {
            HeaderName: "Workman Comp?",
            ParentName: "InsuPlanOther",
            ControlName: "IsWorkmanComp"
        },
        // {
        //     HeaderName: "Rx Serial# Required?",
        //     ParentName: "InsuPlanOther",
        //     ControlName: "IsRxSNReqd"
        // },
        {
            HeaderName: "Compound Ingr. Values Can Differ From Claims?",
            ParentName: "InsuPlanOther",
            ControlName: "CompoundIngrcandiffer"
        },
        {
            HeaderName: "Rph ID To Submit",
            ParentName: "InsuPlanOther",
            ControlName: "RPhIdCatId"
        }, // InsuTransOther
        {
            HeaderName: "Payor Id",
            ParentName: "InsuTransOther",
            ControlName: "PayorNum"
        },
        {
            HeaderName: "Payor ID Qualifier",
            ParentName: "InsuTransOther",
            ControlName: "PayorIdQualifId"
        },
        {
            HeaderName: "Receiver Id",
            ParentName: "InsuTransOther",
            ControlName: "ReceiverNum"
        },
        {
            HeaderName: "NCPDP Payer Set",
            ParentName: "InsuTransOther",
            ControlName: "NCDPId"
        },
        {
            HeaderName: "COB HC Req Qualifier",
            ParentName: "InsuTransOther",
            ControlName: "COBHCReqQualif"
        },
        {
            HeaderName: "Force COB Seg for Secondary Billing?",
            ParentName: "InsuTransOther",
            ControlName: "ForceCoBSeg"
        },
        {
            HeaderName: "COB Method",
            ParentName: "InsuTransOther",
            ControlName: "COBMethodId"
        },
        {
            HeaderName: "Force OPPRA Method for OCC=08?",
            ParentName: "InsuTransOther",
            ControlName: "IsOPPRAFoced"
        },
        {
            HeaderName: "Send Other Coverage Code(C8) when Blank?",
            ParentName: "InsuTransOther",
            ControlName: "IsOthConvCodeSent"
        },
        {
            HeaderName: "Send Sub. Clarif. Code(DK) when Blank?",
            ParentName: "InsuTransOther",
            ControlName: "IsSubClarifCodeSent"
        },
        {
            HeaderName: "Send Leading Zero for 0th Cov.Code(C8)?",
            ParentName: "InsuTransOther",
            ControlName: "IsLeadingZeroSent"
        },
        {
            HeaderName: "Force Qualifier 06 for OPPRA Method?",
            ParentName: "InsuTransOther",
            ControlName: "IsQualifForced"
        },
        {
            HeaderName: "Default Other Coverage Code",
            ParentName: "InsuTransOther",
            ControlName: "DefOthrCovCode"
        },
        {
            HeaderName: "Other Payer Coverage Code",
            ParentName: "InsuTransOther",
            ControlName: "OthrPayorCovCode"
        },
        {
            HeaderName: "Batch Quantity",
            ParentName: "InsuTransOther",
            ControlName: "BatchQty"
        },
        {
            HeaderName: "V. Certification",
            ParentName: "InsuTransOther",
            ControlName: "VendCert"
        },
        {
            HeaderName: "Default Place of Service",
            ParentName: "InsuTransOther",
            ControlName: "DefServPlace"
        },
        {
            HeaderName: "P/A Support",
            ParentName: "InsuTransOther",
            ControlName: "IsPASupport"
        },
        {
            HeaderName: "Rebill Support(B3)",
            ParentName: "InsuTransOther",
            ControlName: "IsRebillSupport"
        }, // OtherPayor
        {
            HeaderName: "Other Insurance Code",
            ParentName: "OtherPayor",
            ControlName: "InsuPlanId"
        },
        {
            HeaderName: "Payer Id",
            ParentName: "OtherPayor",
            ControlName: "PayerCode"
        },
        {
            HeaderName: "Payer ID Qualifier",
            ParentName: "OtherPayor",
            ControlName: "PayerQualifId"
        },  // InsuBillFileSetting
        {
            HeaderName: "Segment Seperator",
            ParentName: "InsuBillFileSetting",
            ControlName: "SegSep"
        },
        {
            HeaderName: "Element Seperator",
            ParentName: "InsuBillFileSetting",
            ControlName: "ElementSep"
        },
        {
            HeaderName: "Field Seperator",
            ParentName: "InsuBillFileSetting",
            ControlName: "FieldSep"
        },
        {
            HeaderName: "Version Identifier",
            ParentName: "InsuBillFileSetting",
            ControlName: "VersionCode"
        },
        {
            HeaderName: "Sender Id",
            ParentName: "InsuBillFileSetting",
            ControlName: "SendorCode"
        },
        {
            HeaderName: "Auth Info Qualifier",
            ParentName: "InsuBillFileSetting",
            ControlName: "AuthInfoQualif"
        },
        {
            HeaderName: "Receiver Id",
            ParentName: "InsuBillFileSetting",
            ControlName: "ReceiverCode"
        },
        {
            HeaderName: "Receiver Qualifier",
            ParentName: "InsuBillFileSetting",
            ControlName: "ReceiverQualif"
        },
        {
            HeaderName: "Auth Information",
            ParentName: "InsuBillFileSetting",
            ControlName: "AuthInfo"
        },
        {
            HeaderName: "Security Information Qualifier",
            ParentName: "InsuBillFileSetting",
            ControlName: "SecurityInfoQualif"
        },
        {
            HeaderName: "Interch Cont Identifier",
            ParentName: "InsuBillFileSetting",
            ControlName: "InterContCode"
        },
        {
            HeaderName: "Security Information",
            ParentName: "InsuBillFileSetting",
            ControlName: "SecurityInfo"
        },
        {
            HeaderName: "Interch Cont Ver No",
            ParentName: "InsuBillFileSetting",
            ControlName: "InterContVersion"
        },
        {
            HeaderName: "Rece Ident Code",
            ParentName: "InsuBillFileSetting",
            ControlName: "RecCode"
        },
        {
            HeaderName: "Entity Name",
            ParentName: "InsuBillFileSetting",
            ControlName: "EntName"
        },
        {
            HeaderName: "Version Identifier 2",
            ParentName: "InsuBillFileSetting",
            ControlName: "VersionCode2"
        },
        {
            HeaderName: "Processor ID",
            ParentName: "InsuPlan",
            ControlName: "ProcessorNum"
        },
    ];

}
export class RxDMELog {

}
