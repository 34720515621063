<div class="newrx--body merge--body">
    <div class="editpatient">
        <div class="row content--heading">
            <div class="col-md-10 padding-0">
                <div class="row">
                    <div class="icon">
                        <i class="far fa-signature text-white font_size_1_25rem"></i>
                    </div>
                    <div class="col-md-11 padding-0">
                        <div class="content--heading__title padding-0">Prime Electronic Signature Reporting Utility
                            <span class="content--name"></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <button type="button" id="clearButton" class="hotkey_success float-right" appShortcutKey [AltKey]="'l'" (click)="clearFilters()"> <span>L</span>
                    Clear
                </button>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 padding--right__7 ">
            <div class="exprx--common-block erx--body insurance--body" *ngIf="!rxPatientModel?.patientid">
                <div class="eprx--block__header">
                    <div class="row">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-11">
                                    <div class="eprx--header__heading">
                                        <span>Select Patient</span>
                                    </div>
                                </div>
                                <div class="col-1 padding_left_7rem">
                                    <app-user-guide-icon [ActiveTabId]= "'SignByPatient'"></app-user-guide-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="eprx--block__content seconday-search--grid">
                    <app-searchbox-global  [SearchFor]="'Patient'" [DisableSerchFil]="true"
                        [DisableRadioButtons]="true" [IsAddNew]="false" (SelectedPatient)="selectedPatientInfo($event)"
                        class="rx-presc--search w-100" [IsFocused]="isInputFocused" (keydown.tab)="focusToFirst($event)">
                    </app-searchbox-global>
                </div>
            </div>
            <div class="exprx--common-block" *ngIf="rxPatientModel?.patientid">
                <div class="eprx--block__header">
                    <div class="eprx--header__heading">
                        Patient - {{rxPatientModel?.fullname? (rxPatientModel?.fullname.replace('_',', ') | uppercase):''}}
                        <ul class="pull-right eprx--header__icons margin_right_1rem">
                            <li>
                                <a (click)="resetPatientInfo()">
                                    <i class="far fa-redo-alt fa-lg" title="Reset Patient"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <app-rx-patient-card [RxPatientInfo]="rxPatientModel"></app-rx-patient-card>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="rxPatientModel?.patientid">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 padding--right__7 ">
            <div class="exprx--common-block erx--body insurance--body">
                <div class="eprx--block__header">
                    <div class="eprx--header__heading">
                        <div class="col-sm-12 padding-0">
                            <div class="row">
                                <div class="col-md-10">
                                    <span>Patient Acknowledgement History</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="eprx--block__content">
                    <div class="row">
                        <div class="col-md-12">
                            <wj-flex-grid #ackInfoGrid *ngIf="ackInfoWJ" [headersVisibility]="'Column'" [itemsSource]="ackInfoWJ" [isReadOnly]="true"
                                [selectionMode]="'Row'" [columnPicker]="'primeescPatAckListWJ'">
                                <wj-flex-grid-column [header]="'Action'" [width]="80">
                                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" class="text-center">
                                        <i class="far fa-signature fa-sm actions actions--Highlightfocus--icon" title="Show Sign" (click)="openSignModal(item,true)"></i>
                                    </ng-template>
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="rxheader['hName']" [binding]="rxheader['hName']" [visible]="rxheader['isVisible']"
                                    *ngFor="let rxheader of wjHeadersAck; let i = index" [width]="rxheader['width']">
                                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                            <div *ngIf="rxheader['hName'] === 'Date'">
                                                {{item[rxheader['hName']] | localTime | date :'MM/dd/yyyy hh:mm a'}}
                                            </div>
                                            <div *ngIf="!(rxheader['hName'] === 'Date')" title={{item[rxheader.hName]}}>
                                                {{ item[rxheader['hName']] }}
                                            </div>
                                        </ng-template>
                                        <ng-template wjFlexGridCellTemplate [cellType]="'ColumnHeader'" let-item="item">
                                            <div title={{rxheader.hName}}>
                                                {{ rxheader['hName'] | uppercase}}
                                            </div>
                                        </ng-template>
                                </wj-flex-grid-column>
                            </wj-flex-grid>
                            <h3 *ngIf="!ackInfoWJ" class="text-center">No Patient Acknowledgement History</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 padding--right__7 ">
            <div class="exprx--common-block erx--body insurance--body">
                <div class="eprx--block__header">
                    <div class="eprx--header__heading">
                        <div class="col-sm-12 padding-0">
                            <div class="row">
                                <div class="col-md-10">
                                    <span>Patient Transaction Signature History</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="eprx--block__content">
                    <div class="row">
                        <div class="col-md-12">
                            <wj-flex-grid *ngIf="histInfoWJ" #histInfoGrid [headersVisibility]="'Column'" [itemsSource]="histInfoWJ" [isReadOnly]="true"
                                [selectionMode]="'Row'" [columnPicker]="'primeescPatHistListWJ'">
                                <wj-flex-grid-column [header]="'Action'" [width]="80">
                                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item"  class="text-center">
                                        <i class="far fa-signature fa-sm actions actions--Highlightfocus--icon" title="Show Sign" (click)="openSignModal(item,false)"></i>
                                    </ng-template>
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="rxheader['hName']" [binding]="rxheader['hName']" [visible]="rxheader['isVisible']"
                                *ngFor="let rxheader of wjHeadersHist; let i = index" [width]="rxheader['width']">
                                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                        <div *ngIf="rxheader['hName'] === 'Date'">
                                            {{item[rxheader['hName']] | localTime | date :'MM/dd/yyyy hh:mm a'}}
                                        </div>
                                        <div *ngIf="!(rxheader['hName'] === 'Date')" title="{{item[rxheader['hName']]}}">
                                            {{ item[rxheader['hName']] }}
                                        </div>
                                    </ng-template>
                                    <ng-template wjFlexGridCellTemplate [cellType]="'ColumnHeader'" let-item="item">
                                        <div title={{rxheader.hName}}>
                                            {{ rxheader['hName'] | uppercase }}
                                        </div>
                                    </ng-template>
                                </wj-flex-grid-column>
                            </wj-flex-grid>
                            <h3 *ngIf="!histInfoWJ" class="text-center">Patient Transaction Signature History</h3>
                        </div>
                        <div class="col-md-12" *ngIf="histInfoWJ">
                            <button type="button" class="hotkey_success float-right" (click)="showPatientSignLog=true"  appShortcutKey [AltKey]="'p'" (keydown.tab)="focusToFirst($event)"> <span>P</span>
                                Print Patient History
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-patient-sign-record *ngIf="showPatientSign" [PatientInfo]="rxPatientModel" [SignId]="signId" [SigType]="sigType" [RPH]="RPH" [SignatureData]="signatureData" (IsPopupClosed)="showPatientSign=false"></app-patient-sign-record>
<app-patient-sig-log *ngIf="showPatientSignLog" [PatientInfo]="rxPatientModel" (IsPopUpClosed)="showPatientSignLog=false"></app-patient-sig-log>
