<div class="insurance--body">
    <ul class="insurance--icons">
        <i class="far fa-sync-alt" title="Refresh Data from MMS" (click)="insuMMSSearchPopUp(searchData)"></i>
    </ul>
    <ul class="insurance--icons">
        <span>
            <i class="far fa-copy" title="Copy Settings" (click)="openCopyModal(copyStng)"></i>
        </span>
    </ul>
</div>

<ng-template #copyStng let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Copy Record From</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="c('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-lg-12 padding--right__7">
                <input autofocus type="text" name="searchBoxOtherInsu" class="form-control search--input__box"
                    placeholder="To Start Search..." [ngbTypeahead]="typeHeadCopyInsu" id="InsuCodeSearch" [resultTemplate]="rt"
                    [inputFormatter]="copyInsuformatter" (selectItem)="selectedInsuForCopy($event)" KeyUpDown id="insSearchInp">
                <ng-template #rt let-r="result" let-t="term">
                    <div class="card patient-card" *ngIf="r">
                        <div class="row">
                            <div class="card__subTit--head"> Insurance Code : </div>
                            <div class="card__subTit--title"> {{r?.PayerId}}</div>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="hotkey_success" (keydown.enter)="copyInsuranceData();"
            (click)="copyInsuranceData()" appShortcutKey [AltKey]="'o'"><span>O</span>OK</button>
        <button type="button" class="hotkey_success" (click)="c('Close click')" (keydown.enter)="c('Close click')"
            appShortcutKey [AltKey]="'c'"><span>C</span>CANCEL</button>
    </div>
</ng-template>



<ng-template #searchData let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Search MMS Data</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close"
            (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body edit-patient">
        <div class="erx--body">

            <div class="eprx--block__content">
                <div class="col-sm-12 padding-0" *ngIf="!hideFilters">
                    <div class="row">
                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                            <eprx-input [LabelText]="'BIN'" [PlaceHolder]="'BIN - Enter min 3 Chars'" [Title]="'BIN'" [RxSelectId]="'BinMMS'"
                                [ControlName]="'BinNum'" [FormGroupName]="refreshFrmGrp" [MarkAsTouched]="refreshFrmGrp.get('BinNum').touched" [MaxLength]="6"   [MinLength]="3" [ErrorDefs]="{minlength: 'Enter min 3 Chars'}"
                                [AutoFocus]="true" (keydown.enter)="getInsuranceMMS()">
                            </eprx-input>
                        </div>

                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                            <eprx-input [LabelText]="'Processor#'" [PlaceHolder]="'Processor# - Enter min 3 Chars'" [RxSelectId]="'ZipMMS'" [RxSelectId]="'ProcessorNumMMS'"
                                [Title]="'Processor#'" [ControlName]="'ProcessorNum'" [MarkAsTouched]="refreshFrmGrp.get('ProcessorNum').touched" [FormGroupName]="refreshFrmGrp"  [MinLength]="3" [ErrorDefs]="{minlength: 'Enter min 3 Chars'}"
                                [MaxLength]="15" (keydown.enter)="getInsuranceMMS()">
                            </eprx-input>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 align-button text-right">
                            <button title="Search / Refresh" type="submit" class="hotkey_success pull-right" (click)="getInsuranceMMS()"
                                (keydown.enter)="getInsuranceMMS()" appShortcutKey
                                [AltKey]="'f'"><span>F</span>Search</button>
                            <button type="button" class="hotkey_success pull-right" (click)="clearFilters()"
                                (keydown.enter)="clearFilters()" appShortcutKey [AltKey]="'l'"><span>L</span>CLEAR
                            </button>
                        </div>
                    </div>
                </div>
                <div [ngClass]="!hideFilters ? 'hide-transistion' : 'show-transistion'" class="show-transistion">
                    <hr>
                    <div class="exprx--common-block">
                        <div class="eprx--block__header">
                            <div class="eprx--header__heading">
                                <div class="col-sm-12 padding-0">
                                    <div class="row">
                                        <div class="col-md-10">
                                            MMS Data
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="eprx--block__content">
                            <wj-flex-grid #refreshData [headersVisibility]="'Column'" [itemsSource]="searchedMMSList" [ngClass]="!hasMMSValues ? 'no-data':''"
                                [isReadOnly]="true" [selectionMode]="'Row'" [allowSorting]=true class="grid" [columnPicker]="'InsuFileSearchWJ'"
                                style="max-height: 43rem;" (keydown)="cancelEventOnWijimo($event)" (keydown.enter)="enterEventOnMMSGrid($event, refreshData)">
                                <wj-flex-grid-column [header]="'Select'" [width]="70">
                                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                        <div>
                                            <div class="insurance--body" style="text-align: center">
                                                <span>
                                                    <i class="far fa-file-import action" title="Select"
                                                        (click)="selectMMSData(item.row)"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </ng-template>
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="actvHeader['hName']" [binding]="actvHeader['hName']" [visible]="actvHeader['isVisible']"
                                    *ngFor="let actvHeader of wjHeaders; let i = index" title={{actvHeader}}
                                    [width]="actvHeader['width']">
                                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                        <div *ngIf="!(actvHeader['hName'] === 'Select')" [title] ="item[actvHeader['hName']] |  uppercase">
                                            {{item[actvHeader['hName']]}}
                                        </div>
                                     </ng-template>
                                </wj-flex-grid-column>
                                <app-no-data-found *ngIf="!isDataExists"></app-no-data-found>
                            </wj-flex-grid>
                            <app-eprx-pagination [TotalCount]="completeMMSData.length" [PageSize]="Size"
                                [WijmoName]="refreshData" [GridName]="'InsuranceMMS'" (OnPageSelected)="setPage($event)"
                                (OnSizeSelected)="setSize($event)" *ngIf="hasMMSValues" [FromModal]="true">
                            </app-eprx-pagination>
                        </div>
                    </div>
                </div>
                <div [ngClass]="!hideFilters ? 'show-transistion' : 'hide-transistion'" class="show-transistion">
                    <hr>
                    <div class="exprx--common-block">

                        <div class="eprx--block__content">
                            <wj-flex-grid #MMSdata [headersVisibility]="'Column'" [selectionMode]="'Row'"
                                [itemsSource]="refreshMMSModel" [isReadOnly]="true" (keydown.space)="spaceEventOnWijimo($event, MMSdata)" (keydown)="cancelEventOnWijimo($event)"
                                (updatedView)="updateMMSInit(MMSdata)" >
                                <wj-flex-grid-column [header]="'HEADING'" [width]="200" [binding]="'head'">
                                    {{refreshMMSModel?.head}}
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="'CURRENT VALUE'" [width]="350"
                                    [binding]="'currentvalue'">
                                    {{refreshMMSModel?.currentvalue}}
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="'MMS DATA VALUE'" [width]="350" [binding]="'MMsvalue'">
                                    {{refreshMMSModel?.MMsvalue}}
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="'USE THIS'" [width]="300">
                                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                        <div *ngIf="item?.display">
                                            <input class="form-check-input label-color" type="checkbox"
                                                id="{{item?.link}}"
                                                (change)="refreshMMSData(item,$event.target.checked)">
                                            <label class="form-check-label" for="{{item?.link}}">
                                            </label>
                                        </div>
                                    </ng-template>
                                </wj-flex-grid-column>
                            </wj-flex-grid>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="hotkey_success" (keydown.enter)="updateMMSData(); c('Close click')"
            *ngIf="hideFilters" (click)="updateMMSData();c('Close click')" appShortcutKey
            [AltKey]="'u'"><span>U</span>UPDATE</button>
        <button type="button" class="hotkey_success" (click)="c('Close click')" appShortcutKey
            [AltKey]="'c'"><span>C</span>CANCEL</button>
    </div>
</ng-template>
