<div class="row exprx--common-block">
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 padding-sm-15 flex--one">
        <div class="box-default">
            <div class="eprx--block__header">
                <div class="r eprx--header__heading">
                    <div class=" col-sm-12 padding-0">
                        Insurance Information
                    </div>
                </div>
            </div>
            <div class="eprx--block__content">
                <div class="row">
                    <div class="col-md-6 col-sm-6 col-xs-12  padding--right__7" *ngIf="!insuId">
                        <div class="col-sm-12 padding-0">
                            <eprx-input [RxSelectId]="'InsuCodeId'" [LabelText]="'Insurance Code'"
                                [Title]="'Insurance Carrier Code'" [AutoFocus]="!insuId && !openMMS" [IsRequired]="true"
                                [PlaceHolder]="'Insurance Code'" [ControlName]="'InsurerCode'" [ErrorDefs]="{
                                    required: 'Required'
                                }" [FormGroupInvalid]="formGroupInvalid" [FormGroupName]="planInfoFG?.get('InsuCarrier')" [MaxLength]="10"
                                [InputType]="'ALPHANUMERIC'"
                                [MarkAsTouched]="planInfoFG?.get('InsuCarrier.InsurerCode')?.touched"
                                (keydown.tab)="checkIfInsuranceExists($event?.target?.value)"  (keydown.enter)="checkIfInsuranceExists($event?.target?.value)"
                                >
                            </eprx-input>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-6 col-xs-12  padding--right__7" *ngIf="insuId">
                        <div class="col-sm-12 padding-0">
                            <eprx-input [LabelText]="'Insurance Code'" [RxSelectId]="'InsuCodeId'"
                                [Title]="'Insurance Carrier Code'" [PlaceHolder]="'Insurance Code'"
                                [ControlName]="'InsurerCode'" [FormGroupName]="planInfoFG?.get('InsuCarrier')"
                                [ReadOnly]="true" [MaxLength]="10">
                            </eprx-input>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-6 col-xs-12 padding--left__7">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 padding-0">
                            <eprx-input [LabelText]="'Insurance Name'" [RxSelectId]="'InsuCodeNameU'" [PlaceHolder]="'Insurance Name'"
                                [IsRequired]="true" [Title]="'Insurance Carrier Name'" [ControlName]="'Name'"
                                [FormGroupName]="planInfoFG?.get('Organization')" [MaxLength]="25"
                                [MarkAsTouched]="planInfoFG?.get('Organization.Name')?.touched"
                                [ErrorDefs]="{ required: 'Required' }" [AutoFocus]="insuId" [FormGroupInvalid]="formGroupInvalid">
                            </eprx-input>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--right__7">
                        <eprx-input [LabelText]="'Pharmacy#'" [PlaceHolder]="'Pharmacy#'"
                            [Title]="'Pharmacy#'" [ControlName]="'PharmacyNum'"
                            [FormGroupName]="planInfoFG?.get('InsuPlan')" [MaxLength]="15"
                            [MaskPattern]="'000000000000000'">
                        </eprx-input>
                    </div>

                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--left__7">
                        <eprx-select [LabelText]="'ID Qualifier'" [PlaceHolder]="'ID Qualifier'" [ControlName]="'InsuPlanIdQualifId'"
                            [Title]="'ID Qualifier'" [FormGroupName]="planInfoFG?.get('InsuPlan')" [HasMultiple]="false"
                             [BindLabel]="'Name'" [BindLabel2]="'Remarks'" [BindValue]="'Id'"
                            [LabelForId]="'Id'" [List]="dropDownData?.InsuPlanIdQualif" [Show2Values]="true" ></eprx-select>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--right__7">
                        <eprx-input [LabelText]="'Processor ID'" [PlaceHolder]="'Processor Id'" [MaxLength]="15"
                            [Title]="'Processor Id'" [ControlName]="'ProcessorNum'"
                            [FormGroupName]="planInfoFG?.get('InsuPlan')">
                        </eprx-input>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--left__7">
                        <eprx-input [LabelText]="'BIN'" [PlaceHolder]="'BIN'" [Title]="'BIN'"
                            [ControlName]="'BINNum'" [FormGroupName]="planInfoFG?.get('InsuCarrier')" [MaxLength]="6"
                            [MaskPattern]="000000">
                        </eprx-input>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--right__7">
                        <eprx-select [LabelText]="'Processor Type'" [PlaceHolder]="'Processor Type'" [Title]="'Processor Type'"
                            [ControlName]="'ProcCatId'" [FormGroupName]="planInfoFG?.get('InsuPlan')"
                            [HasMultiple]="false"  [BindLabel2]="'Name'" [BindLabel]="'Id'" [BindValue]="'Id'" [Show2Values]="true"
                            [LabelForId]="'Name'" [List]="
                                dropDownData
                                    ? dropDownData?.ProcCat
                                    : ''
                            ">
                        </eprx-select>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--left__7">
                        <eprx-select [LabelText]="'Payor'" [PlaceHolder]="'Payor'" [Title]="'Payor'"
                            [ControlName]="'PayorId'" [FormGroupName]="planInfoFG?.get('InsuPlan')"
                            [HasMultiple]="false" [HasBindLabel2]="true" [BindLabel]="'PayorInsId'" [BindLabel2]="'PayorName'"
                            [BindValue]="'PayorId'" [LabelForId]="'PayorId'" [List]="dropDownData?.PayorOrganizationModel"[Show2Values]="true">
                        </eprx-select>
                    </div>
                    <!-- <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--right__7">
                        <eprx-select [LabelText]="'Special Program'" [Title]="'Special Program'"
                            [PlaceHolder]="'Special Program'" [ControlName]="'SplProgramId'"
                            [FormGroupName]="planInfoFG?.get('SpecialProgram')" [HasMultiple]="false"
                            [HasBindLabel2]="false" [BindLabel]="'Name'" [BindValue]="'Id'" [LabelForId]="'Name'"
                            [List]="systemData ? systemData.SplProgram : ''">
                        </eprx-select>
                    </div> -->
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <eprx-input [LabelText]="'Medicaid Agency#'" [PlaceHolder]="'Medicaid Agency'"
                            [Title]="'Medicaid Agency '" [ControlName]="'MedicaidAgNum'"
                            [FormGroupName]="planInfoFG?.get('InsuPlan')" [MaxLength]="20">
                        </eprx-input>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 ">
                        <eprx-radio [LabelText]="'Is Active'" [ControlName]="'IsActive'" [FormGroupName]="planInfoFG?.get('InsuPlan')"
                            [List]="['Yes', 'No']" [ValueList]="[true, false]" (TriggerSelectValue)="triggerSelectedValue($event)" [IDForList]="['y', 'n']" [LabelForId]="">
                        </eprx-radio>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 ">
                        <label class="col-md-12 padding-0">
                            Medicare PartD
                        </label>
                        <eprx-radio [ControlName]="'IsMedicareSup'" [FormGroupName]="planInfoFG?.get('InsuPlan')"
                            [List]="['Yes', 'No']" [ValueList]="[true, false]" [IDForList]="['t', 'f']" [LabelForId]="">
                        </eprx-radio>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 ">
                        <label class="col-md-12 padding-0"> Medigap </label>
                        <eprx-radio [ControlName]="'IsMedigapSup'" [FormGroupName]="planInfoFG?.get('InsuPlan')"
                            [List]="['Yes', 'No']" [ValueList]="[true, false]" [IDForList]="['true', 'false']"
                            [LabelForId]="">
                        </eprx-radio>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 ">
                        <label class="col-md-12 padding-0">
                            Medicaid
                        </label>
                        <eprx-radio [ControlName]="'IsMedicaidSup'" [FormGroupName]="planInfoFG?.get('InsuPlan')"
                            [List]="['Yes', 'No']" [ValueList]="[true, false]" [IDForList]="['medicaidYes', 'medicaidNo']" [LabelForId]="">
                        </eprx-radio>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-xl-6 col-lg-6 drug--price__info padding-0">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 padding-sm-15 ">
            <div class="box-default">
                <div class="eprx--block__header">
                    <div class="eprx--header__heading">
                        Address Information
                    </div>
                </div>
                <div class="eprx--block__content">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--right__7">
                            <eprx-input [LabelText]="'Address'" [PlaceHolder]="'Address'" [Title]="'Address'"
                                [ControlName]="'AddressLine1'" [FormGroupName]="planInfoFG?.get('Address')"
                                [MaxLength]="25" [MarkAsTouched]="planInfoFG?.get('Address.AddressLine1')?.touched" (TriggerOnChange)="onAddressChange($event.target.value)">
                            </eprx-input>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--left__7">
                            <app-search-zip [FormGroupName]="planInfoFG?.get('Address')" [ControlName]="'ZipCode'"
                                (OnZipSelected)="patchStateAndCity($event)"
                             [zipExtnCntrl]="'Extension'"   [MarkAsTouched]="planInfoFG?.get('Address.ZipCode')?.touched"></app-search-zip>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--right__7">
                            <app-search-state [FormGroupName]="planInfoFG?.get('Address')" [ControlName]="'StateId'"
                                [CityControlName]="'CityId'" [StateSelected]="insuState$ | async"
                                (OnStateSelected)="patchStateAndCity($event, 'State')"
                                (TriggerOnClear)="clearStateAndCity()"></app-search-state>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--left__7">
                            <app-search-city [FormGroupName]="planInfoFG?.get('Address')" [ControlName]="'CityId'"
                                [StateControlName]="'StateId'" [zipControl]="'ZipCode'" [CitySelected]="insuCity$ | async"
                                (OnCitySelected)="patchStateAndCity($event, 'City')"
                                (TriggerOnClear)="clearStateAndCity('City')"></app-search-city>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--right__7">
                            <eprx-input [LabelText]="'Telephone#'" [PlaceHolder]="'Telephone#'" [Title]="'Telephone#'"
                                [ControlName]="'xPhone'" [FormGroupName]="planInfoFG?.get('Phone')"
                                [ValidPattern]="regex?.Numeric" [ErrorDefs]="{
                                    pattern: 'please enter 10 digits'
                                }" [MaskPattern]="'(000)000-0000'"
                                [MarkAsTouched]="planInfoFG?.get('Phone.xPhone')?.touched" [ShowMask]="true">
                            </eprx-input>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--left__7">
                            <eprx-input [LabelText]="'Contact'" [PlaceHolder]="'Contact'" [MaxLength]="15"
                                [ControlName]="'ContPerson'" [Title]="'Contact Person'"
                                [FormGroupName]="planInfoFG?.get('Organization')" [InputType]="'STRING'">
                            </eprx-input>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 padding-sm-15 ">
            <div class="box-default">
                <div class="eprx--block__header">
                    <div class="eprx--header__heading">
                        Transmission Information
                    </div>
                </div>
                <div class="eprx--block__content">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--right__7">
                            <eprx-input [LabelText]="'NCPDP Version'" [PlaceHolder]="'NCPDP version'"
                                [Title]="'NCPDP Version'" [ControlName]="'NCPDPVer'"
                                [FormGroupName]="planInfoFG?.get('InsuTransmission')" [MaskPattern]="'AA'"
                                [MaxLength]="2">
                            </eprx-input>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--left__7">
                            <eprx-select [LabelText]="'Submission Type'" [PlaceHolder]="'Submission Type'"
                            [IsDisabled]="systemData['DefaultValuese'][0]?.CashInsurerId === planInfoFG?.value?.InsuCarrier?.Id"
                                [ControlName]="'InsuXmitSubCatId'" [FormGroupName]="planInfoFG?.get('InsuTransmission')"
                                [HasMultiple]="false" [Title]="'Submission Type'" [HasBindLabel2]="true" (TriggerSelectValue)="getSubmissionType()"
                                [BindLabel]="'Name'" [BindLabel2]="'Remarks'" [BindValue]="'Id'" [LabelForId]="'Id'" [Show2Values]="true" [show2ValInInputhidden]="true"
                                [List]="
                                    dropDownData
                                        ? dropDownData.InsuXmitSubCat
                                        : ''
                                ">
                            </eprx-select>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--right__7">
                            <eprx-select
                                [LabelText]="'Submission Clarification'"
                                [PlaceHolder]="''"
                                [ControlName]="'SubClarifId'"
                                [secondCntrlN]="'SubClarificationName'"
                                [FormGroupName]="planInfoFG?.get('InsuTransmission')"
                                [List]="
                                    dropDownData
                                        ? dropDownData.subClarification
                                        : ''
                                "
                                [BindLabel]="'Name'"
                                [BindLabel2]="'Remarks'"
                                [bindScnCntrlV]="'Remarks'"
                                [updateDropDownPos]="true"
                                [IsTitle]="false"
                                [BindValue]="'Id'"
                                [LabelForId]="'Id'"
                                [Show2Values]="true"
                                (TriggerOnTabbing)="focusToSave($event)"
                            >
                            </eprx-select>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 ">
                            <div [formGroup]="planInfoFG">
                                <div class="row">
                                    <div class="col-lg-12" formGroupName="InsuPlan">
                                        <label class="control-label full--width">
                                            <span>Remarks</span>
                                            <textarea   class="custom--text__area" formControlName="Remarks" value="{{ planInfoFG?.value?.InsuPlan?.Remarks}}" placeholder="Remarks"
                                                style="width:99.5%; top: 0.3rem;"  [maxLength]="1000"></textarea>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #submissionTypeWarning let-c="close" let-d="dismiss">
    <div  class="modal-header">
        <h4 class="modal-title">Warning!!</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="closeSubmissionTypeModal('cancel')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div  class="modal-body edit-patient">
        <div class="row">
            <div class="col">
                <table class="table table-hover">
                    <tbody>
                        <label>Change in the submission type will impact the already created Rx's. Would you like to proceed?</label>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div  class="modal-footer">
        <button type="button" autofocus class="hotkey_primary"  (keydown.enter)="closeSubmissionTypeModal('yes')"  (click)="closeSubmissionTypeModal('yes')" appShortcutKey InputKey="y" >
          <b>Y</b>  YES
        </button>
        <button type="button"   class="hotkey_primary" (keydown.enter)="closeSubmissionTypeModal('cancel')"  (click)="closeSubmissionTypeModal('cancel')" appShortcutKey InputKey="n">
            <b>N</b>  NO
          </button>
    </div>
</ng-template>
