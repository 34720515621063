<ng-template #NEXTINSUTOBILL let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="emitPopUpClosed()">
        <h4 header>Please Confirm Next Insurance to Bill</h4>
        <span body id="contentToPrint">
            <div class="row transmit--block">
                <div class="col-md-12">
                    <h4 class="fw-600">Rx Information</h4>
                    <div class="row">
                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 trasmit--rx">
                            <div class="">
                                <label> Patient </label>
                                <div class="label--data">
                                    {{rxInfo?.Patient?.lastname}},&nbsp;{{rxInfo?.Patient?.firstname}}
                                </div>
                            </div>
                            <div class="">
                                <label> Drug </label>
                                <div class="label--data" *ngIf="rxInfo?.Drug?.drugname">
                                    {{rxInfo?.Drug?.drugname + " " + rxInfo?.Drug?.strength + " " + rxInfo?.Drug?.drugformname}}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-9 col-md-9 col-sm-9 col-xs-12 trasmit--rx">
                            <div class="row">
                                <div class="col-6">
                                    <label> Rx# </label>
                                    <div class="label--data">
                                        {{
                                            rxInfo?.Prescription?.PrescNum
                                        }} - {{
                                            rxInfo?.PrescReFill?.ReFillNum
                                        }}  <span *ngIf="this._commonSvc.checkIsPartialFill(rxInfo.PrescReFill, rxInfo.Prescription)">
                                            - {{this._commonSvc.checkIsPartialFill(rxInfo.PrescReFill, rxInfo.Prescription)
                                            ? rxInfo.PrescReFill.PartialFillNo : " "}}
                                        </span>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <label> BIN </label>
                                    <div class="label--data">
                                        {{ BIN ? (BIN | uppercase) : "--"}}
                                    </div>
                                </div>
                                <div class="col-6">
                                    <label>Last Ins Billed Code </label>
                                    <div class="label--data">
                                        <span>
                                            <span  class="ml-1 ">
                                                {{billCode ? (billCode  | uppercase) : "--"}}
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <label> PCN </label>
                                    <div class="label--data">
                                        {{PCN ? (PCN | uppercase) : "--"}}
                                    </div>
                                </div>
                                <div class="col-6">
                                    <label> Last Ins Billed Name </label>
                                    <div class="label--data">
                                        {{InsName ? (InsName | uppercase) : "--"}}
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="row">
                                            <div class="col-12">
                                                <label> Net Paid </label>
                                                <div class="label--data" >
                                                    <span>
                                                        <span  class="ml-1 text-danger">
                                                            <span>{{netPaid ? netPaid : "$0.00"}}
                                                                </span>
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <label > Copay </label>
                                                <div class="label--data" *ngIf="payRecords?.length > 0">
                                                        {{
                                                            payRecords[0]?.PTPayment ? (payRecords[0]?.PTPayment
                                                            | currency : "USD"
                                                                    : "symbol-narrow"
                                                                    : "1.2-2" ) : (0
                                                                | currency
                                                                    : "USD"
                                                                    : "symbol-narrow"
                                                                    : "1.2-2")
                                                        }}
                                                </div>
                                            </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr>
                <div>&nbsp;</div>
                <div class="col-md-12">
                    <h4 class="fw-600 margin_top_15px margin_bottom_10px" > Secondary / Tertiary Insurance Billing Information </h4>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 trasmit--rx">
                            <div class="d-inline-flex">
                                <label> Next Insurance to bill</label>
                                <div class="label--data margin_left_30px"> 
                                    {{nxtInsuToBill?.insuType | uppercase}}
                                </div>
                            </div>
                        </div>                        
                    </div>
                </div>
                <hr>
                <div>&nbsp;</div>
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12 trasmit--rx">
                            <h4 class="fw-600 margin_top_15px margin_bottom_10px"> Select next insurance to bill </h4>
                            <div class="transmit-list-grid transmit-insu-list-grid">
                                <a>
                                    <div class="row transmit-list-hdr">
                                        <div class="col-1">
                                            Ins.Code
                                        </div>
                                        <div class="col-4">
                                            Insurance Name
                                        </div>
                                        <div class="col-1">
                                            Bill Order
                                        </div>
                                        <div class="col-1">
                                            BIN
                                        </div>
                                        <div class="col-2">
                                            PCN
                                        </div>
                                        <div class="col-2">
                                            Ins.Id
                                        </div>
                                        <div class="col-1">
                                            Group#
                                        </div>
                                    </div>
                                </a>
                                <span *ngIf="nxtInsuLst?.length > 0">
                                    <span *ngFor="let insu of nxtInsuLst">
                                        <a (click)="!insu.hasPayRecords ? onChangeValue(insu, 'insuranceid') : ''">
                                            <div class="row transmit-list-item" [ngClass]="insu.hasPayRecords ? 'inactive_row' : (nxtInsuToBill?.insuType === insu?.insuType? 'active' : '')">
                                                <div class="col-1 text--ellipsis" [title]="insu.hasPayRecords ? 'Insurance is already used for Transmission' : (insu?.insuType)">
                                                    {{insu?.insuType | uppercase}}
                                                </div>
                                                <div class="col-4" [title]="insu.hasPayRecords ? 'Insurance is already used for Transmission' : ''">
                                                    {{insu?.insurancename | uppercase}}
                                                </div>
                                                <div class="col-1" [title]="insu.hasPayRecords ? 'Insurance is already used for Transmission' : ''">
                                                    {{insu?.insuPriId == 1? "P" : insu?.insuPriId == 2? "S" : insu?.insuPriId == 3?"T" : ''}}
                                                </div>
                                                <div class="col-1" [title]="insu.hasPayRecords ? 'Insurance is already used for Transmission' : ''">
                                                    {{insu?.binnum | uppercase}}
                                                </div>
                                                <div class="col-2" [title]="insu.hasPayRecords ? 'Insurance is already used for Transmission' : ''">
                                                    {{insu?.ProcessorNum | uppercase}}
                                                </div>
                                                <div class="col-2 text--ellipsis" [title]="insu.hasPayRecords ? 'Insurance is already used for Transmission' : (insu?.policynum)">
                                                    {{insu?.policynum | uppercase}}
                                                </div>
                                                <div class="col-1 text--ellipsis" [title]="insu.hasPayRecords ? 'Insurance is already used for Transmission' : (insu?.groupname)">
                                                    {{insu?.groupname | uppercase}}
                                                </div>
                                            </div>
                                        </a>
                                    </span>
                                </span>
                                <span *ngIf="nxtInsuLst?.length === 0">
                                    <div class="row transmit-list-item">
                                        <div class="col">
                                            No more insurances left for this patient.
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 trasmit--rx">
                            <h4 class="fw-600 margin_top_15px margin_bottom_10px"> Select other coverage code </h4>
                            <div class="transmit-list-grid">
                                <a class="">
                                    <div class="row transmit-list-hdr">
                                        <div class="col-1 p-0">
                                            Code
                                        </div>
                                        <div class="col-11">
                                            Description
                                        </div>
                                    </div>
                                </a>
                                <span  *ngFor="let other of systemData?.OtherCoverage">
                                    <a (click)="onChangeValue(other?.Id, 'othercover')">
                                        <div class="row transmit-list-item" [ngClass]="other?.Id == otherCoverId ? 'active': ''">
                                            <div class="col-1">
                                                {{other?.Name | uppercase}}
                                            </div>
                                            <div class="col-11 p-0">
                                                {{other?.Remarks | uppercase}}
                                            </div>
                                        </div>
                                    </a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </span>
        <hr />
        <div footer class="col-lg-8 col-md-8 col-sm-12 col-xs-12 pl-0">
            <div class="d-inline-flex">
                <input class="form-check-input" type="checkbox" [id]="carryFwdBillSequence" [checked]="carryFwdBillSequence" (click)="askFrBillSequenceConfirm($event?.target?.checked)" [disabled]="payRecords?.length === 2 ? true : false">
                <label class="font_size_16 ml-4 pl-1" [for]="carryFwdBillSequence">Follow same insurance sequence for further refills</label>                
            </div>
        </div>
        <button footer class="inactive" title="No insurances are available to perform the Transmission." *ngIf="disableNotInsList?.length === 0"><b>O</b> Ok </button>
        <button footer class="hotkey_primary" (click)="initiateSecondaryInsurance()" appShortcutKey InputKey="o"
            *ngIf="disableNotInsList?.length > 0"><b>O</b> Ok </button>
        <button footer class="hotkey_primary secondary" (click)="emitPopUpClosed()" appShortcutKey InputKey="c"><b>C</b> Cancel </button>
        <button footer class="hotkey_primary" appShortcutKey InputKey="a" (click)="openPatInsuAddModal(addInsurance)"
        ><b>A</b> Add New Patient Insurance</button>
    </app-custom-modal>
</ng-template>

<ng-template #addInsurance let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">ADD INSURANCE DETAILS</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <div class="eprx--block__header">
                    <div class="eprx--header__heading">
                        <h4>Insurance</h4>
                    </div>
                </div>
                <div class="eprx--block__content">
                    <div class="row">
                        <div class="col form-group custom--input" [ngClass]="{
                            'has-error':
                            insuranceFG?.get('Insurance.InsurerId')?.invalid &&
                                (insuranceFG?.get('Insurance.InsurerId')?.dirty || insuranceFG?.get('Insurance.InsurerId')?.touched) &&
                                insuranceFG?.get('Insurance.InsurerId')?.errors
                        }">
                            <label class="control-label pat-insuType">
                                <span> Ins.Type <span class="help--text__danger">*</span></span>
                                <span class="curency-format">
                                    <input type="text" #searchBoxInsType [value]="insuCode"
                                        class="right-part form-control search--input__box"
                                        placeholder="To Start Search..." appChangeDebounce [ngbTypeahead]="search"
                                        [required]="true" [resultTemplate]="rt" [inputFormatter]="formatter"
                                        (selectItem)="selectedInsType($event)" KeyUpDown [appAutofocus]="true">
                                    <div class="left-part">
                                        <input type="text" tabindex="-1" #searchBoxInsName [value]="insuName"
                                            name="searchBoxInsName" class="form-control search--input__box" readonly />
                                    </div>
                                </span>
                            </label>
                            <a class="add__icon" (click)="routeToAddInsurance()" appShortcutKey [AltKey]="'i'">
                                <i class="far fa-plus-circle fa-lg" title="Add Insurance"></i>
                            </a>
                            <div class="help-block"
                                *ngIf="insuranceFG?.get('Insurance.InsurerId')?.invalid && (insuranceFG?.get('Insurance.InsurerId')?.dirty || insuranceFG?.get('Insurance.InsurerId')?.touched)">
                                Required
                            </div>
                            <ng-template #rt let-r="result" let-t="term">
                                <div class="card patient-card secondary-patient-card" *ngIf="r">
                                    <div class="row">
                                        <div class="col">
                                            <div class="row">
                                                <div class="card__subTit--head">
                                                    Insurance Code:
                                                </div>
                                                <div class="card__subTit--title">
                                                    {{ r?.InsurerCode }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <div class="row">
                                                <div class="card__subTit--head">
                                                    Insurance Name :
                                                </div>
                                                <div class="card__subTit--title">
                                                    {{ r?.InsuranceName }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <div class="row">
                                                <div class="card__subTit--head">
                                                    Bin Num :
                                                </div>
                                                <div class="card__subTit--title">
                                                    {{ r?.BinNum }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <div class="row">
                                                <div class="card__subTit--head">
                                                    PCN Num :
                                                </div>
                                                <div class="card__subTit--title">
                                                    {{ r?.ProcessorNum }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </div>
                        <div class="col-2">
                            <eprx-input [RxSelectId]="'InsuId'" [LabelText]="'Insurance ID'"
                                [PlaceHolder]="'Insurance ID'" [FormGroupInvalid]="formGroupInvalid"
                                [ControlName]="'PolicyNum'" [FormGroupName]="insuranceFG?.get('InsuCard')"
                                [ErrorDefs]="{pattern: 'Invalid!', required: 'Required', invalid: ''}"
                                [IsRequired]="policyRequired" [ValidPattern]="regex?.IdNumeric" [MaxLength]=80
                                [MarkAsTouched]="insuranceFG?.get('InsuCard.PolicyNum')?.touched">
                            </eprx-input>
                        </div>
                        <div class="col-2">
                            <eprx-select [LabelText]="'Group#'" [PlaceHolder]="'Group#'"
                                [ControlName]="'InsuGrpId'" [FormGroupName]="insuranceFG?.get('Insurance')"
                                [List]="filteredGroups" [BindLabel]="'Name'"
                                (TriggerOnTabbing)="focusOutFromGroup()"
                                [BindValue]="'Id'" [InputValue]="insuranceFG?.get('Insgroup.Name')?.value"
                                [LabelForId]="'Name'" (TriggerSelectValue)="isTypedValue($event)" [AddItem]="true"
                                (TriggerOnClear)="isTypedValue($event)" [SelectOnTab]="true" [addItemMaxLength]="35">
                            </eprx-select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="eprx--block__header">
                    <div class="eprx--header__heading">
                        <h4>Billing</h4>
                    </div>
                </div>
                <div class="eprx--block__content">
                    <div class="row">
                        <div class="col">
                            <eprx-select [RxSelectId]="'primInsRela12'" [LabelText]="'Relationship'"
                                [PlaceHolder]="'Relationship'" [ControlName]="'RelCatId'"
                                [FormGroupName]="insuranceFG?.get('Insurance')"
                                [List]="systemData?.RelationCat ? (systemData?.RelationCat | filterRelation:'Patient') : []"
                                [BindLabel]="'Remarks'" [BindValue]="'Id'" [LabelForId]="'Name'">
                            </eprx-select>
                        </div>
                        <div class="col">
                            <!-- check these control -->
                            <eprx-input [LabelText]="'Person Code'" [PlaceHolder]="'Person Code'"
                                [ControlName]="'PerNum'" [FormGroupName]="insuranceFG?.get('Insurance')"
                                [ErrorDefs]="{pattern: 'Invalid!'}" [ValidPattern]="regex?.AlphaNumeric"
                                [MaxLength]="3">
                            </eprx-input>
                        </div>
                        <div class="col">
                            <eprx-input [LabelText]="'Card Holder\'s Last Name'"
                                [PlaceHolder]="'Card Holder\'s Last Name'" [ControlName]="'LastName'"
                                [FormGroupName]="insuranceFG?.get('InsuCard')" [ErrorDefs]="{pattern: 'Invalid!'}"
                                [ValidPattern]="regex?.AlphaNumeric" [MaxLength]="35">
                            </eprx-input>
                        </div>
                        <div class="col">
                            <eprx-input [LabelText]="'Card Holder\'s First Name'"
                                [PlaceHolder]="'Card Holder\'s First Name'" [ControlName]="'FirstName'"
                                [FormGroupName]="insuranceFG?.get('InsuCard')" [ErrorDefs]="{pattern: 'Invalid!'}"
                                [ValidPattern]="regex?.AlphaNumeric" [MaxLength]="35">
                            </eprx-input>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <eprx-date-picker [RxSelectId]="'primInsCardExp'" [LabelText]="'Card Expires'"
                                [PlaceHolder]="'Card Expires'" [ControlName]="'ExpiryDt'" [FormGroupName]="insuranceFG?.get('InsuCard')"
                                [MinDate]="minDate" [IsRequired]="true"
                                [ErrorDefs]="{ required: 'Required' ,minDate:'Date should be greater than today'}"
                                [MarkAsTouched]="insuranceFG?.get('InsuCard.ExpiryDt')?.touched">
                            </eprx-date-picker>
                            <!-- <eprx-date-picker [LabelText]="'Card Expires'" [PlaceHolder]="'Card Expires'"
                                [ControlName]="'ExpiryDt'" [FormGroupName]="InsuCard" [MinDate]="minDate">
                            </eprx-date-picker> -->
                        </div>
                        <div class="col">
                            <eprx-select [LabelText]="'Billing Priority'" [PlaceHolder]="'Billing Priority'"
                                [ControlName]="'InsuPriId'" [FormGroupName]="insuranceFG?.get('Insurance')"
                                [List]="[{Id: 0, value: 'None'}, { Id: 2, value: 'Secondary' },
                                { Id: 3, value: 'Tertiary' }]" [BindLabel]="'value'" [BindValue]="'Id'" [LabelForId]="'Name'"
                                (TriggerSelectValue)="selectedPriority($event['value'].Id)">
                            </eprx-select>
                        </div>
                        <div class="col">
                            <eprx-radio [LabelText]="'Is Active'" [ControlName]="'ActiveStatusId'"
                                [FormGroupName]="insuranceFG?.get('Insurance')" [List]="['Yes', 'No']"
                                [ValueList]="[1, 2]" [IDForList]="['ActiveStatusId4', 'ActiveStatusId5']"
                                [LabelForId]="" [Name]="'ActiveStatusId'">
                            </eprx-radio>
                        </div>
                        <div class="col">
                            <eprx-radio [LabelText]="'Patient Assignment'" [ControlName]="'IsPatAssigned'"
                                [FormGroupName]="insuranceFG?.get('Insurance')" [List]="['Yes', 'No']"
                                [ValueList]="[true, false]" [IDForList]="['IsPatAssigned2', 'IsPatAssigned3']"
                                [LabelForId]="" [Name]="'IsPatAssigned'">
                            </eprx-radio>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="eprx--block__header">
                    <div class="eprx--header__heading">
                        <h4>Comments</h4>
                    </div>
                </div>
                <div class="eprx--block__content">
                    <div class="row">
                        <div class="col">
                            <eprx-text-area [FormGroupName]="insuranceFG?.get('Insurance')" [ControlName]="'Remarks'"
                                [PlaceHolder]="'Comments'" [Rows]="3">
                            </eprx-text-area>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="hotkey_success" (click)="checkFGValid()" appShortcutKey
            [AltKey]="'s'"><span>S</span>SAVE</button>
        <button type="button" class="hotkey_success" appShortcutKey [AltKey]="'c'"
            (click)="d('Cross click')"><span>C</span>CANCEL</button>
    </div>
</ng-template>


