import { MsgConfig } from "src/app/app.messages";
import { SystemData, AccCategoryE, EditRxE, RxModel, RxAuditLog } from "src/app/models";
import { CommonService, RxService, AlertService } from "src/app/services";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { Component, OnInit, Input, HostListener, Output, EventEmitter, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment";
import { AuditLogUtils } from "../../../utils/audit-log.util";
import { Subscription } from "rxjs/internal/Subscription";
import { NgbDateStruct, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DomSanitizer } from "@angular/platform-browser";
import { PrivMaskGuard } from "src/app/guards";
import { LocalTimePipe } from "src/app/pipes";
import { NewAuditUtils } from "src/app/utils/new-audit.util";
import { PrimeEscService } from "src/app/services/prime-esc.service";
import { CommonUtil } from "src/app/utils";
import { CustomDateParserFormatter } from "../../cust-form-elemns";
import { DateCheckUtil } from "src/app/utils/date-checks.util";
import { CommonWarnorconfirmPopupComponent } from "../../shared/common-warnorconfirm-popup/common-warnorconfirm-popup.component";
import { PrintService } from "src/app/services/print.service";
import { forkJoin, Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";

@Component({
    selector: "app-pickuprx",
    templateUrl: "./pickuprx.component.html"
})
export class PickuprxComponent implements OnInit {
    @Input() SystemData: SystemData;
    @Input() rxDetails: RxModel;
    @Output() IsPopUpClosed = new EventEmitter<any>();
    @Input() rxFG: FormGroup;

    @ViewChild("PICKUPDELETECNFRM", { static: true })
    PICKUPDELETECNFRM: any;

    unsubscribe$ : Subject<void> = new Subject();
    localTimePipe = new LocalTimePipe();

    pickupFG: any;
    rxId: number;
    prscRfId: any;
    pickUpDetails: any;
    today: any;
    time: any;
    formGroupInvalid: boolean;
    todayTm: any;
    minDate: any;
    presRefIdF: any;
    time1: moment.Moment;
    todayTm1: string;
    imgURL: string;
    imageToShow: any;
    route: string;
    hasSignature: boolean;
    hasReceiptData: boolean;
    accessPrivCate: any;
    editRxPrivs: any;
    pickupMinDate: any;

    signatureData: any;
    sigType: any;
    signId: any;
    showPatientSign: boolean;
    rxPatientModel: any;
    refillId: any;
    disableDateField: boolean;
    pickTimeForDisplay: string = "AM";
    isPickTimeValid: any = false;
    RPH: any;
    rxNumber: string;
    readonly DELIMITER = '/';
    dateParser=new CustomDateParserFormatter();
    rxPickupFromOptions: any;
    isCancel:boolean = false;
    get ExpDtTm(): FormControl {
        return this.pickupFG.get("ExpDtTm") as FormControl;
    }
    get PRESCREFILL(): FormGroup {
        return this.rxFG.controls["PrescReFill"] as FormGroup;
    }
    constructor(
        private _fb: FormBuilder,
        public _commonSvc: CommonService,
        private _actvRoute: ActivatedRoute,
        private _rxService: RxService,
        private _alertSvc: AlertService,
        private _auditUtils: AuditLogUtils,
        private _route: Router,
        private _privMaskGuard: PrivMaskGuard,
        private _sanitizer: DomSanitizer,
        private _modalService: NgbModal,
        private _nwAudtUtl: NewAuditUtils,
        private _commonUtil: CommonUtil,
        private _primeEscSer: PrimeEscService,
        private _dateChecks: DateCheckUtil,
        private _printServ: PrintService
    ) {
        this.accessPrivCate = AccCategoryE;
        this.editRxPrivs = EditRxE;
        this.createPickupG();
        this._commonSvc.systemData$.pipe(takeUntil(this.unsubscribe$)).subscribe(resp => {
            if (resp) {
               this.rxPickupFromOptions = resp.pickUpFrom ? resp.pickUpFrom : [];
            }
        });
    }

    ngOnInit() {
        this.pickupFG.controls["IsPickedUp"].patchValue(this.rxFG?.value?.pickupRx?.IsPickedUp ? this.rxFG?.value?.pickupRx?.IsPickedUp : false)
        this.getCurrentDateFormat();
        this.getPickupDetails();
        if (this.rxDetails) {
            this.pickupMinDate = moment(this.rxDetails.PrescReFill.FillDtTm).format("MM/DD/YYYY");
        }
    }
    getCurrentDateFormat() {
        const today = new Date();
        this.today = moment().format("MM/DD/YYYY");
        this.minDate = {
            year: today.getFullYear(),
            month: today.getMonth() + 1,
            day: today.getDate()
        };
        this.time1 = moment();
        this.todayTm1 = this.time1.hour() + ":" + this.time1.minutes();
        this.time = moment().format("HH:mm A");
        this.todayTm = moment().format("MM/DD/YYYY HH:mm A");
    }

    getPartialFillNo() {
        return this._commonSvc.checkIsPartialFill(this.rxDetails?.PrescReFill, this.rxDetails?.Prescription) ? this.rxDetails?.PrescReFill?.PartialFillNo : "";
    }


    parse(value: string): NgbDateStruct | null {
        if (value) {
            let date = value.split(this.DELIMITER);
            return {
                day: parseInt(date[1], 10),
                month: parseInt(date[0], 10),
                year: parseInt(date[2], 10)
            };
        }
        return null;
    }
    createPickupG() {
        this.pickupFG = this._fb.group({
            Id: [],
            TenantId: [],
            RxRefillId: [],
            DropPickQulifierName: [],
            IdProof: [],
            RelRemarks: [],
            ExpDtTm: [],
            ExpDttm1: [],
            IsPickedUp: false,
            PickUpPos: false,
            POSTransId: [],
            POSUserId: [],
            ActDtTm: [],
            PkFirstName: [],
            PkLastName: [],
            RelationCatId: [],
            PkIdNum: [],
            PkIdProofId: [],
            PkIdIssuer: [],
            Remarks: [],
            HandOverById: [],
            Acttime: [],
            prescNum: [],
            reFillNum: [],
            PartialfillNum: [],
            DropPickQ: [],
            ExpDate: null,
            PickUpFromId: null
        });
    }


    createImageFromBlob(image: Blob) {
        const urlCreator = window.URL;
        this.imageToShow = this._sanitizer.bypassSecurityTrustResourceUrl(
            urlCreator.createObjectURL(image)
        );
    }
    getPickTimeForDisplay() {
        this.pickTimeForDisplay = (this.pickTimeForDisplay === "AM") ?"PM" :"AM";
    }
    async getPickupDetails(frmSave?) {
        const pickupSvc = [];
        let Sign;
        // this.pickUpDetails = await this._rxService.getPickupDetails(this.rxDetails["PrescReFill"]["Id"]).toPromise();
        pickupSvc.push(this._rxService.getPickupDetails(this.rxDetails["PrescReFill"]["Id"]));
        pickupSvc.push(this._rxService.getSignatureDetails(this.rxDetails["PrescReFill"]["Id"]));
        const respPickUp = await forkJoin(pickupSvc).toPromise();
        if(respPickUp?.length) {
            this.pickUpDetails = respPickUp[0];
            Sign = respPickUp[1];
        }
        if (this.pickUpDetails) {
            if(this.pickUpDetails.hasReceiptDetails == 1) {
                this.hasReceiptData = true;
            }
            // const Sign = await this._rxService.getSignatureDetails(this.rxDetails["PrescReFill"]["Id"]).toPromise();
            if (Sign) {
                this.hasSignature = true;
                this.imageToShow = "data:image/png;base64," + Sign;
            } else {
                this.hasSignature = false;
            }
            this.pickupFG.patchValue(this.pickUpDetails);
            this.pickupFG.controls.ExpDtTm.patchValue(
                this.pickUpDetails["ExpDtTm"] ? moment.utc(this.pickUpDetails["ExpDtTm"]).local().format("MM/DD/YYYY hh:mm:ss a") : null);
            this.pickupFG.controls["ExpDttm1"].patchValue(this.pickupFG.value["ExpDtTm"]);
            if( this.pickUpDetails["ExpDtTm"]) {
                this.getPickupDate(false);
            }
            this.pickupFG.controls.ActDtTm.patchValue(
                    this.pickUpDetails["ActDtTm"] ? moment.utc(this.pickUpDetails["ActDtTm"]).local().format("MM/DD/YYYY") : null);
            this.pickupFG.markAsPristine();
            if(frmSave){
                this.IsPopUpClosed.emit("Patient1");
            }
        }
    }
    withChangeInDateSetTime() {
        const date = moment(this.pickupFG.value["ExpDtTm"]).format("MM/DD/YYYY");
        // const time = moment().format("hh:mm:ss");
        const dateTime = moment(date + "", "MM/DD/YYYY");
        const dateTimeForDisplay = moment(date + "" +  moment().format("HH:mm:ss"), "MM/DD/YYYY HH:mm:ss a");
        this.pickTimeForDisplay = moment(dateTimeForDisplay).format("a");
        this.pickupFG.controls["ExpDtTm"].patchValue(moment(dateTime).format("MM/DD/YYYY"));
        let previousTime = this.pickupFG.value["ExpDate"] + " " + this.pickTimeForDisplay ;
        if(this.pickUpDetails)
            this.pickUpDetails.ExpDate = previousTime;
    }
    saveAuditChange() {
        const pickupRxAuditModel = new RxAuditLog().pickupRxAuditModel;
        this._nwAudtUtl.saveAuditChange(
            this.pickUpDetails,
            this.pickupFG.getRawValue(),
            "PickUpInfo",
            "Rx",
            this.rxDetails.PrescReFill.Id,
            pickupRxAuditModel
        );
  }
  checkPikcUpTimeValidation(type : boolean = false) {

    this.isPickTimeValid = false;
    if (this.pickupFG.value["ExpDate"]) {
        const expDate = this.pickupFG.value["ExpDate"];
        const date: any = expDate.split(":");
        if (date && date.length) {
            date[0] = parseInt(date[0], 0);
            date[1] = parseInt(date[1], 0);
            date[2] = parseInt(date[2], 0);
            if (date[0] > 12 || date[1] > 59 || date[2] > 59) {
                this.isPickTimeValid = true;
            }
        }
    } else {
        this.pickupFG.controls["ExpDate"].patchValue(null);
    }
    if(!type) {
        if(document.getElementById("DropPickQ") && document.getElementById("DropPickQ").getElementsByTagName('input'))
        {
            const dropTag: any = document.getElementById("DropPickQ").getElementsByTagName('input')[0];
            if (dropTag)
                dropTag.focus();
        }
    }
    return this.isPickTimeValid;
  }
    async savePickUp(type?: any) {
        const pickUpExpDtTmFG = this._fb.group({ExpDtTm: this.pickupFG.controls["ExpDtTm"].value});
        var dateChecks = [{
            control : "ExpDtTm",
            minDate:  this.dateParser.parse(this.pickupMinDate),
            maxDate: "",
            isRequired: this.pickupFG.value.IsPickedUp,
            Id:"pickUpRx"
        }]
        if(this._dateChecks.checkDateValidationAtTimeofSave(pickUpExpDtTmFG, dateChecks)) {
            return ;
        } else {
            if (this.checkPikcUpTimeValidation(true)){
                return;
            }
            this.pickupFG.patchValue({
                RxRefillId: this.rxDetails["PrescReFill"]["Id"],
                prescNum: this.rxDetails.Prescription.PrescNum,
                reFillNum: this.rxDetails.PrescReFill.ReFillNum,
                PartialfillNum: this.rxDetails.PrescReFill.PartialFillNo,
                ExpDate: this.pickupFG.value["ExpDate"] ? this.pickupFG.value["ExpDate"] + " " + this.pickTimeForDisplay : null
            });
            if (!this.pickupFG.value["IsPickedUp"] || (this.pickupFG.value["IsPickedUp"] && this.pickupFG.value["ExpDtTm"])) {
                this.formGroupInvalid = false;
                if (this.pickupFG.value["ExpDtTm"]) {
                    const actDt = moment.utc(this.pickupFG.value.ExpDtTm)
                    .add(this.rxFG.value.PrescReFill.SupplyDays, "days")
                    .local().format("YYYY-MM-DD");
                    this.pickupFG.patchValue({
                        ActDtTm: actDt,
                    });
                }
                if (this.pickUpDetails) {
                    const resp = await this._rxService.editPickupDetails(this.pickupFG.value).toPromise();
                        if (resp) {
                            if (this.rxFG && this.rxFG.controls["pickupRx"]) {
                                this.rxFG.controls["pickupRx"].patchValue({IsPickedUp : this.pickupFG.value["IsPickedUp"]});
                            }
                            this.saveAuditChange();
                            if (!type) {
                            this._alertSvc.success(MsgConfig.SAVE_SUCCESS);
                            this.getPickupDetails(true);
                            }
                        } else {
                            if (!type) {
                            this._alertSvc.error(MsgConfig.SAVE_FAIL);
                            }
                        }
                } else {
                    const resp = await this._rxService.addPickupDetails(this.pickupFG.value).toPromise();
                    if (resp) {
                        if (this.rxFG && this.rxFG.controls["pickupRx"]) {
                            this.rxFG.controls["pickupRx"].patchValue({IsPickedUp : this.pickupFG.value["IsPickedUp"]});
                        }
                        this.saveAuditChange();
                        if (!type) {
                        this._alertSvc.success(MsgConfig.SAVE_SUCCESS);
                        this.getPickupDetails(true);
                        }
                    }
                }
            } else {
                this.formGroupInvalid = true;
                if (!type) {
                    this._alertSvc.error(MsgConfig.ENTER_REQ_FIELD);
                }
            }
        }

    }
    openPickUpPrint() {

        if(this.rxFG && this.rxFG.controls["Prescription"] && this.rxFG.controls["PrescReFill"]) {
        this.rxNumber = this.rxFG.controls["Prescription"].value.PrescNum + "-" + this.rxFG.controls["PrescReFill"].value.ReFillNum + (this._commonSvc.checkIsPartialFill(this.rxFG.controls["PrescReFill"].value, this.rxFG.controls["Prescription"].value) ? "-" + this.rxFG.controls["PrescReFill"].value.PartialFillNo : "") + "(" + this.rxFG.controls["insuranceInfo"].value.insuCarrier.InsurerCode + ")";
        this._rxService.getPickupPrintDetails(this.rxFG.controls["PrescReFill"].value.Id).pipe(takeUntil(this.unsubscribe$)).subscribe((transId: any) => {
             this._primeEscSer.getPatAckSign(transId.Id, false).pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            this.rxPatientModel = this.rxFG.get("Patient").value;
            this.showPatientSign = true;
            this.signId = transId.Id;
            this.refillId = this.rxFG.controls["PrescReFill"].value.Id;
            this.RPH = transId.RPH;
            this.signatureData = resp;
              });
        });
     }
    }
    getPickupDate(isFromSelection) {
        let time;
        time =  moment(this.pickupFG.value["ExpDtTm"]).format("hh:mm:ss");
        if (isFromSelection) {
            this.pickupFG.controls["ExpDtTm"].patchValue(moment(this.pickupFG.value["ExpDtTm"]).format("MM/DD/YYYY"));
            time = this.pickupFG.value["IsPickedUp"] ?  this.pickupFG.value["ExpDate"] : moment().format("hh:mm:ss");
        }
        const date = moment(this.pickupFG.value["ExpDtTm"]).format("MM/DD/YYYY");
        this.pickTimeForDisplay = moment(this.pickupFG.value["ExpDtTm"]).format("a");
        // const dateTime = moment(date + "" + time, "MM/DD/YYYY hh:mm:ss");
        const a = this.pickupFG.controls["ExpDtTm"].patchValue(moment(date).format("MM/DD/YYYY"));
        this.pickupFG.controls["ExpDate"].patchValue(time);
        let previousTime = this.pickupFG.value["ExpDate"] + " " + this.pickTimeForDisplay ;
        if(this.pickUpDetails)
              this.pickUpDetails.ExpDate = previousTime;
    }
    setVal() {
        // const fillDtTm = this.PRESCREFILL.value.FillDtTm ? moment(this.PRESCREFILL.value.FillDtTm).format(MsgConfig.DATE_FORMAT) : null;
        // const expDtTm =
        // (this.pickupFG.value["ExpDtTm"]) ? moment(this.pickupFG.value["ExpDtTm"]).format(MsgConfig.DATE_FORMAT) : moment().format(MsgConfig.DATE_FORMAT);
        // const filDateDiffPickup = (fillDtTm && expDtTm) ?   moment(expDtTm).startOf("day").diff(moment(fillDtTm).startOf("day"), "days") : null;
        this.formGroupInvalid = false;
        if (this.checkHasPrivsToAccess(
            this.accessPrivCate.EditRx,
            this.editRxPrivs.CheckUncheckPickupRecord
        ) ) {
            if (this.pickupFG.value["IsPickedUp"]) {
                this.pickupFG.controls["PkFirstName"].patchValue(
                    this.rxDetails["Patient"]["firstname"]
                );
                this.pickupFG.controls["PkLastName"].patchValue(
                    this.rxDetails["Patient"]["lastname"]
                );
                this.pickupFG.controls["ExpDtTm"].patchValue(new Date());
                this.pickupFG.controls["ExpDttm1"].patchValue(new Date());
                this.getPickupDate(false);
                this.pickupFG.controls["ActDtTm"].patchValue(new Date());
                // Audit Alert - "Class 2 Drugs should be picked up within 30 days from Fill date"
                // if(this.rxFG.value  && this.rxFG.value["Drug"] && this.rxFG.value["Drug"]["drugclass"]  && +this.rxFG.value["Drug"]["drugclass"] === 2 && filDateDiffPickup && filDateDiffPickup > 30){
                //     const modelRef = this._modalService.open(CommonWarnorconfirmPopupComponent, {
                //         backdrop: false,
                //         keyboard: false, windowClass: "large--content"
                //     });
                //     modelRef.componentInstance.IsHeaderText ="Warning!!";
                //     modelRef.componentInstance.warnMsg = "According to compliance, pickup for Class 2 drug should be completed within 30 days, otherwise an audit may be conducted for continued Rx.";
                //     modelRef.componentInstance.okButton = true;
                //     modelRef.componentInstance.IsPopUpClosed.subscribe((resp: any) => {
                //         modelRef.close();
                //     });
                // }

                // this.pickupFG.controls["Acttime"].patchValue( this.time );
            } else {
                this.pickupFG.controls["IsPickedUp"].setValue(false);
                //open model
                this._modalService.open(this.PICKUPDELETECNFRM, { backdrop: "static", keyboard: false, centered: true,
                 windowClass: "large--content" });
                // this.pickupFG.reset();
            }
        } else {
            this.pickupFG.controls["IsPickedUp"].patchValue(!this.pickupFG.value["IsPickedUp"]);
            this._alertSvc.error("User does not have privileges to perform this action.", false, "long");
        }
        this.formGroupInvalid = true;
    }

    resetPickupRx(val: boolean) {
        if (val) {
        this.disableDateField = false;
        let fgVal = this.pickupFG.value;
        this.pickupFG.reset();
        this.pickupFG.controls["ExpDtTm"].setValue(null);
        this.pickupFG.controls["ExpDtTm"].setErrors(null);
        this.pickupFG.controls["ExpDtTm"].setValidators(null);
        this.pickupFG.controls["ExpDtTm"].markAsTouched();
        this.pickupFG.controls["ExpDtTm"].updateValueAndValidity();
        this.pickupFG.patchValue({
            Id: fgVal.Id,
            TenantId: fgVal.TenantId,
            IsPickedUp: false,
        });
        this.pickupFG.markAsDirty();
        this.formGroupInvalid = false;
        } else {
            this.disableDateField = true;
        }
    }

    checkHasPrivsToAccess(category: string, privType: string) {
        return this._privMaskGuard.canActivate(category, privType);
    }

    previousPage() {
        this.isCancel = true;
        this.pickupFG.reset();
        this.IsPopUpClosed.emit("Patient1");
    }

    focusOutFromRadio(event) {
        if (!this.pickupFG.value["IsPickedUp"]) {
           this.focusOnSave(event);
        }
    }

    focusOnSave(event) {
        if (document.getElementById("SaveButton")) {
            event.preventDefault();
            document.getElementById("SaveButton").focus();
        }
    }

   

    PrintPOSReceipt(){
        this._primeEscSer.getPOSreceipt(this.pickUpDetails.Id).pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp && resp.size > 0 && resp.type === "application/pdf") {
                    const newBlob = new Blob([resp], { type: "application/pdf" });
                    const reader = new FileReader();
                    reader.readAsDataURL(newBlob);
                    reader.onloadend = function () {
                        const base64data = reader.result;
                        this._printServ.printPdf(base64data);
                    }.bind(this);
                } else {
                    this._alertSvc.error("Print Unsuccessfull.");
                }
            });
    }

    ngOnDestroy(){
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
