import { Injectable, NgZone } from "@angular/core";
import { FormGroup } from "@angular/forms";
import * as _ from "lodash";
import { Subscription } from "rxjs";
import { EDrug, EPatient, SystemData } from "../models";
import { RxBillingCalculationInput } from "../models/rx-billing-calculation.model";
import { AlertService, CommonService, InsuranceService, RxService } from "../services";
import { RxStore } from "../store";
import { DrugBucketUtil } from "./drug-bucket.util";
import { NRxUtils } from "./nRx.util";

@Injectable({
    providedIn: "root"
})
export class RxBillingUtil {
    subscriptions = new Subscription();
    InsuSettings : any;
    constructor(private _rxService: RxService, private _insuSvc: InsuranceService,
         private _alertSvc: AlertService, private _rxStore: RxStore, private _nRxUtil: NRxUtils, private _drugBuckUtil: DrugBucketUtil,
         private _comnSvc: CommonService, private ngZone: NgZone) {}

    async getPriceDetlsNdCalc(tyPe: string, rxFG: FormGroup, pricSchdlLst: any, insuSetngs: any, rxType: String,
         priceValue?: any, fromCash?) {
        const PrescRefillFG: any = rxFG.controls["PrescReFill"] as FormGroup;
        const rxInfo = rxFG.getRawValue();
        const discntCodeList = this._nRxUtil.getDisctCodeList();
        await this.checkAndPatchDiscount(rxFG);
        if (rxInfo.PrescReFill.DispQty && +rxInfo.PrescReFill.DispQty !== 0) {
            const resp = priceValue ? priceValue : await this.getPriceCalculationValue(rxInfo, tyPe);

            if (resp) {
                rxFG.controls["pricingValues"].patchValue(resp);
                rxFG.controls["Precendence"].patchValue(resp.Precendence ? resp.Precendence : 'Price Schedule');
                if (tyPe !== "PriceSch") {
                    PrescRefillFG.controls.PriceSchId.setValue(+resp["PriceSchId"]);
                }
                PrescRefillFG.controls["Price"].setValue(
                    resp.Price ? parseFloat(resp.Price).toFixed(2) : "0.00"
                );
                PrescRefillFG.controls["BalDue"].setValue(
                    resp.BalDue ? parseFloat(resp.BalDue).toFixed(2) : "0.00"
                );
                PrescRefillFG.controls["ProfFee"].setValue(
                    resp.ProFee ? parseFloat(resp.ProFee).toFixed(2) : (0).toFixed(2)
                );
                PrescRefillFG.controls["TotalAmt"].setValue(
                    resp.TotalAwp
                        ? parseFloat(resp.TotalAwp).toFixed(2)
                        : "0.00"
                );
                let AWP = null;
                if (PrescRefillFG.value.DispQty) {
                    AWP =
                        +PrescRefillFG.value.DispQty *
                        rxInfo.Drug.unitpriceawp;
                    PrescRefillFG.controls[
                        "UnitPriceAWP"
                    ].setValue(AWP ? parseFloat(AWP).toFixed(2) : "0.00");
                    Object.assign(rxFG.value["pricingValues"],
                    {Awp: rxInfo.Drug.unitpriceawp ? parseFloat(rxInfo.Drug.unitpriceawp) : 0});

                    if (rxInfo.Drug && rxInfo.Drug.manageinventory && rxInfo.Drug.manageinventory.length) {
                        const isBuckets = rxInfo.Drug.manageinventory.filter(buck =>
                            buck.BucketId === (rxInfo["PrescReFill"] &&  rxInfo["PrescReFill"]['BucketId']))
                            || [];
                        const rxCost = isBuckets && isBuckets.length && isBuckets[0].UnitPriceCost
                        ? (+rxInfo.PrescReFill.DispQty * isBuckets[0].UnitPriceCost).toFixed(2) : "0.00";
                        PrescRefillFG.controls["TotalRxCost"].setValue(rxCost ? rxCost : "0.00");
                    }

                } else {
                    PrescRefillFG.controls["TotalRxCost"].setValue("0.00");
                }
                PrescRefillFG.controls["UnitPriceUNC"].setValue(
                    (resp && resp.UAndC ) ? parseFloat(resp.UAndC).toFixed(2) : "0.00"
                );
                if (PrescRefillFG.value["DiscSchId"]) {
                    await this.getDiscDtlsNdCalc(rxFG, discntCodeList);
                } else {
                    PrescRefillFG.controls["Discount"].setValue((0).toFixed(2));
                }
                const origProFee =  (resp && resp.OrigProFee ? (rxInfo["rxType"] === 'er' ? rxInfo['PrescReFill']['OrigProfFee'] : resp.OrigProFee) : 0.0);
                rxFG.controls["OrigProFee"].patchValue(origProFee);
                PrescRefillFG.controls["OrigProfFee"].patchValue(origProFee);
                if (fromCash) {
                    return this.initiateManualCal(rxFG, insuSetngs, rxType, fromCash);
                } else {
                    this.initiateManualCal(rxFG, insuSetngs, rxType);
                }
            } else {
                rxFG.controls["pricingValues"].patchValue(null);
                if (tyPe !== "PriceSch") {
                    if (pricSchdlLst && pricSchdlLst.length > 0) {
                        PrescRefillFG.controls.PriceSchId.setValue(
                            pricSchdlLst[0].Id
                        );
                    }
                }
                this.resetPriceRelatedFields(rxFG);
            }
        } else {
            rxFG.controls["pricingValues"].patchValue(null);
            PrescRefillFG.controls["TotalRxCost"].setValue("0.00");
            this.resetPriceRelatedFields(rxFG);
        }
    }

    async checkAndPatchDiscount(rxFG) {
        const prescRefll = rxFG.controls["PrescReFill"] as FormGroup;
        const isCash = this._rxStore.isCashRx$["source"]["value"];
        const discntCodeList = this._nRxUtil.getDisctCodeList();
        if (discntCodeList && discntCodeList.length > 0 && isCash && rxFG.value["Drug"]["isdiscountable"]) {
            const discnt = discntCodeList.filter(x => x["DiscCode"] === rxFG.value.Patient["DiscSchCd"])[0];
            if (discnt) {
                prescRefll.controls["DiscSchId"].setValue(discnt["Id"]);
            } else {
                prescRefll.controls["DiscSchId"].setValue(null);
            }
        } else {
            prescRefll.controls["DiscSchId"].setValue(null);
            prescRefll.controls["Discount"].setValue((0).toFixed(2));
        }
    }

    async getPriceCalculationValue(rxInfo, _type: string) {
        let result = null;
        if (rxInfo.PrescReFill.DispQty && +rxInfo.PrescReFill.DispQty !== 0) {
            const compIng = this._rxStore.compoundData$["source"]["value"];
            const inputData = new RxBillingCalculationInput();
            inputData.Discount = rxInfo.PrescReFill.DiscSchId;
            inputData.DrugId = rxInfo.Drug.id;
            inputData.OtherAmount = rxInfo.PrescReFill.OtherAmnt; // Todo: pavan This needs to be filled from other amt popup
            inputData.PriceScheduleId = rxInfo.PrescReFill.PriceSchId;
            inputData.QuantityDispense = +rxInfo.PrescReFill.DispQty;
            inputData.BucketId = +rxInfo.PrescReFill.BucketId;
            if (rxInfo.PrescReFill.PriceSchId && _type) {
                inputData.TypeOfCalculation = "PriceScheduleId";
            } else {
                inputData.TypeOfCalculation = "Drug";
            }
            inputData.PatientId = rxInfo.Patient.patientid;
            inputData.InsuranceId = rxInfo.RefillInsu.InsuId;
            inputData.PrescReFillId = rxInfo.PrescReFill.Id;
            inputData.prescReFillId = (rxInfo.rxType && rxInfo.rxType === "er" && rxInfo.Drug && rxInfo.Drug.drugtypeid === 2) ?
             rxInfo.PrescReFill.Id : 0;
            inputData.CompIngList = compIng && compIng["list"] ? compIng["list"] : null
            if (inputData.QuantityDispense) {
                result = await this._rxService.getPricingDetails(inputData).toPromise();
            }
        }
        if (this._rxStore.compoundData$["source"]["value"] && this._rxStore.compoundData$["source"]["value"]["list"] && result && result.CompIngList) {
            this._rxStore.compoundData$["source"]["value"]["list"] = result.CompIngList
        }
        return result;
    }

    resetPriceRelatedFields(rxFG: FormGroup) {
        const PrescRefillFG: any = rxFG.controls["PrescReFill"] as FormGroup;
        PrescRefillFG.controls["Price"].setValue("0.00");
        PrescRefillFG.controls["BalDue"].setValue("0.00");
        PrescRefillFG.controls["ProfFee"].setValue("0.00");
        PrescRefillFG.controls["TotalAmt"].setValue("0.00");
        PrescRefillFG.controls["UnitPriceAWP"].setValue("0.00");
        PrescRefillFG.controls["UnitPriceUNC"].setValue("0.00");
        PrescRefillFG.controls["OtherAmnt"].setValue("0.00");
        PrescRefillFG.controls["Discount"].setValue((0).toFixed(2));
    }

    async checkPriceChange(Frm, rxFG, insuSetngs?: any, insuplanId?: any, reasonFor?: boolean) {
        const rxInfo = rxFG.getRawValue();
        const priceCal = await this.checkPriceCalChange(rxFG);
        if(rxInfo.rxType === "rf") {
            rxInfo["pricingValues"] = priceCal.pricingVals;
        }
        let InsuVal = null;
        const result = [];
        if (priceCal && priceCal.changedValues && priceCal.changedValues.length) {
            priceCal.changedValues.map(val => {
                const hName = val === "BalDue" ? "Balance Due" : val === "Price" ? " Ingredient Cost" :
                    val === "PriceSchId" ? "Price Schedule" : val === "ProFee" ? "Pro. Fee" :
                        val === "TotalAwp" ? "Total Price" : val === "UAndC" ? "U&C" : "Awp";
                let oldPriceSchN = null;
                let newPriceSchN = null;
                if (val === "PriceSchId") {
                    const priceSchList = this._comnSvc.priceSchedule$["source"]["value"];
                    if (priceSchList && priceSchList["PriceScheduleList"] && priceSchList["PriceScheduleList"].length) {
                        if (rxInfo["pricingValues"] && rxInfo["pricingValues"][val]) {
                            const index = priceSchList["PriceScheduleList"].findIndex(v => v.Id === +rxInfo["pricingValues"][val]);
                            if (index > -1) {
                                oldPriceSchN = priceSchList["PriceScheduleList"][index]["PriceCode"];
                            }
                        }
                        if (priceCal["priceValues"] && priceCal["priceValues"][val]) {
                            const index1 = priceSchList["PriceScheduleList"].findIndex(v => v.Id === +priceCal["priceValues"][val]);
                            if (index1 > -1) {
                                newPriceSchN = priceSchList["PriceScheduleList"][index1]["PriceCode"];
                            }
                        }
                    }
                    if (oldPriceSchN && newPriceSchN) {
                        result.push({
                            Header: hName, OldValue: oldPriceSchN.toUpperCase(), NewValue: newPriceSchN.toUpperCase()
                        });
                    } else {
                        if((priceCal["priceValues"] && priceCal["priceValues"][val]))
                        priceCal["priceValues"][val] = (+(priceCal["priceValues"][val])).toFixed(2);
                 if(rxInfo["pricingValues"] && rxInfo["pricingValues"][val])
                             rxInfo["pricingValues"][val] = (+(rxInfo["pricingValues"][val])).toFixed(2);
                        if(((priceCal["priceValues"] && priceCal["priceValues"][val]) ||
                        (rxInfo["pricingValues"] && rxInfo["pricingValues"][val])) && (priceCal["priceValues"][val] != rxInfo["pricingValues"][val])) {
                            result.push({
                                Header: hName, OldValue: rxInfo["pricingValues"] ? rxInfo["pricingValues"][val] : null,
                                NewValue: priceCal["priceValues"] ? priceCal["priceValues"][val] : null
                            });
                        }
                    }
                } else {
                    if((priceCal["priceValues"] && priceCal["priceValues"][val]))
                           priceCal["priceValues"][val] = (+(priceCal["priceValues"][val])).toFixed(2);
                    if(rxInfo["pricingValues"] && rxInfo["pricingValues"][val])
                                rxInfo["pricingValues"][val] = (+(rxInfo["pricingValues"][val])).toFixed(2);
                    if(((priceCal["priceValues"] && priceCal["priceValues"][val]) ||
                    (rxInfo["pricingValues"] && rxInfo["pricingValues"][val])) && (priceCal["priceValues"][val] != rxInfo["pricingValues"][val])) {
                        const isAddorNot  =  (hName && (hName.toUpperCase() === "AWP")) ?
                        ((val === "Awp") ? true: false) : true;
                        if(isAddorNot) {
                            result.push({
                                Header: hName, OldValue: rxInfo["pricingValues"] ? rxInfo["pricingValues"][val] : null,
                                NewValue: priceCal["priceValues"] ? priceCal["priceValues"][val] : null
                            });
                        }
                    }

                }
            });
        }

        if (Frm === "Insurance" || reasonFor) {
            InsuVal = await this.checkChangeInInsuStng(insuSetngs, insuplanId, rxFG);
            if (InsuVal && InsuVal["showpopUp"] && InsuVal["showpopUp"].length) {
                InsuVal["showpopUp"].map(val => {
                    const hName = val === "FlatSalesTaxAmt" ? "Flat Sales Tax Amount" :
                        val === "TaxRatePer" ? "Sales Tax Percentage" : "";
                           // val === "SalesTaxBasisId" ? "Percentage Sales Tax Basis" : "";
                    if ((hName !== "")) {

                        if((insuSetngs && insuSetngs["InsuPlanPrice"] && insuSetngs["InsuPlanPrice"][val])
                         ||
                         (InsuVal["lstStng"] && InsuVal["lstStng"]["InsuPlanPrice"] && InsuVal["lstStng"]["InsuPlanPrice"][val]) ) {
                            result.push({
                                Header: hName, OldValue: (insuSetngs && insuSetngs["InsuPlanPrice"] && insuSetngs["InsuPlanPrice"][val]) ?
                                insuSetngs["InsuPlanPrice"][val] : 0,
                                NewValue: (InsuVal["lstStng"] && InsuVal["lstStng"]["InsuPlanPrice"] && InsuVal["lstStng"]["InsuPlanPrice"][val]) ?
                                InsuVal["lstStng"]["InsuPlanPrice"][val] : 0
                            });
                        }

                    }
                });
                if(InsuVal["lstStng"] && InsuVal["showpopUp"].includes("TaxRatePer")) {
                    this.InsuSettings = InsuVal["lstStng"];
                    var taxAmount = this.calTaxValuesForTrack(rxFG, InsuVal["lstStng"]);
                    const otherAmount = rxFG.value.RxOtherAmount['SalesTax'];
                    if(taxAmount && (taxAmount != otherAmount)) {
                        result.push({
                            Header: "Percentage Sales Tax Amount",
                            NewValue: taxAmount,
                            OldValue: otherAmount

                        });
                    }
                }

            }
            if (InsuVal && InsuVal["showPopup2"] && InsuVal["showPopup2"].length && rxFG.value["RxBill"]["StatusId"] !== 2) {
                const lstInsuVal = (insuSetngs && insuSetngs["InsuPlanPrice"]) ? JSON.parse(JSON.stringify(insuSetngs)) : [];
                if (lstInsuVal && lstInsuVal["InsuPlanPrice"]) {
                    lstInsuVal["InsuPlanPrice"]["IsTaxOnRx"] = InsuVal["lstStng"] && InsuVal["lstStng"]["InsuPlanPrice"] ?
                    InsuVal["lstStng"]["InsuPlanPrice"]["IsTaxOnRx"] : false;
                    lstInsuVal["InsuPlanPrice"]["IsTaxOnOTC"] = InsuVal["lstStng"] && InsuVal["lstStng"]["InsuPlanPrice"] ?
                    InsuVal["lstStng"]["InsuPlanPrice"]["IsTaxOnOTC"] : false;
                }
                await this.initiateManualCal(rxFG, lstInsuVal, rxFG.value["rxType"]);
            }
        }
        return {res:   _.uniqBy(result, "Header") , ltsPrice: priceCal ? priceCal["priceValues"] : null,
        ltsInsuPrice: InsuVal ? InsuVal["lstStng"] : null };
    }

    async getPriceCalVal(rxFG) {
        const rxInfo = rxFG.getRawValue();
        let latestData = null;
        const compIng = this._rxStore.compoundData$["source"]["value"];
        if (rxInfo.PrescReFill.DispQty && +rxInfo.PrescReFill.DispQty !== 0) {
            const inputData = new RxBillingCalculationInput();
            inputData.Discount = rxInfo.PrescReFill.DiscSchId;
            inputData.DrugId = rxInfo.Drug.id;
            inputData.OtherAmount = rxInfo.PrescReFill.OtherAmnt;
            inputData.PriceScheduleId = rxInfo.PrescReFill.PriceSchId;
            inputData.QuantityDispense = +rxInfo.PrescReFill.DispQty;
            inputData.TypeOfCalculation = "Drug";
            inputData.PrescReFillId = rxInfo.PrescReFill.Id;
            inputData.PatientId = rxInfo.Patient.patientid;
            inputData.InsuranceId = rxInfo.RefillInsu.InsuId;
            inputData.BucketId = +rxInfo.PrescReFill.BucketId;
            inputData.CompIngList = compIng && compIng["list"] ? compIng["list"] : null
            latestData = await this._rxService.getPricingDetails(inputData).toPromise();
            Object.assign(latestData? latestData : {}, {Awp: (rxInfo.Drug && rxInfo.Drug.unitpriceawp) ? parseFloat(rxInfo.Drug.unitpriceawp) : 0 });
        }
        return latestData;
    }

    async checkPriceCalChange(rxFG) {
        const rxInfo = rxFG.getRawValue();
        if (rxInfo["pricingValues"]) {
            rxInfo["pricingValues"]["UAndC"] = rxInfo["PrescReFill"]["UnitPriceUNC"];
            rxInfo["pricingValues"]["Price"] = rxInfo["PrescReFill"]["Price"];
            rxInfo["pricingValues"]["ProFee"] = rxInfo["PrescReFill"]["ProfFee"];
            rxInfo["pricingValues"]["Awp"] =
            rxInfo["PrescReFill"]["UnitPriceAWP"] ?
              (+rxInfo["PrescReFill"]["UnitPriceAWP"]).toFixed(3).toString() : "0.00";
        } else if (rxInfo.rxType === "rf" && (rxInfo["PrescReFill"]["UnitPriceUNC"] || rxInfo["PrescReFill"]["Price"])) {
            rxInfo["pricingValues"] = {
                "UAndC": rxInfo["PrescReFill"]["UnitPriceUNC"],
                "Price": rxInfo["PrescReFill"]["Price"],
                "ProFee": rxInfo["PrescReFill"]["ProfFee"],
                "Awp":  rxInfo["PrescReFill"]["UnitPriceAWP"] ?
                (+rxInfo["PrescReFill"]["UnitPriceAWP"]).toFixed(3).toString() : "0.00"
            }
        }

        let latestData = null;
        const result = [];
        latestData = await this.getPriceCalVal(rxFG);
        if (latestData) {
        latestData["UAndC"] = latestData["UAndC"] ? latestData["UAndC"].toFixed(2) : "0.00";
        latestData["Price"] = latestData["Price"] ? latestData["Price"].toFixed(2) : "0.00";
        latestData["ProFee"] = latestData["ProFee"] ? latestData["ProFee"].toFixed(2) : "0.00";
        latestData["Awp"] =  (latestData["TotalAwp"]) ? latestData["TotalAwp"].toFixed(3) : "0.00";
        if (rxInfo["pricingValues"] && latestData) {
            Object.keys(rxInfo["pricingValues"]).map(key => {
                if (rxInfo["pricingValues"][key] !== latestData[key]) {
                    result.push(key);
                }
            });
        } else if (rxInfo["pricingValues"]) {
            Object.keys(rxInfo["pricingValues"] ? rxInfo["pricingValues"] : latestData).map(key => {
                if ((rxInfo["pricingValues"] && rxInfo["pricingValues"][key]) || (latestData && latestData[key])) {
                    result.push(key);
                }
            });
        }
        return {changedValues: result, priceValues: latestData, pricingVals: rxInfo["pricingValues"]};
    }
    }

    async checkChangeInInsuStng(insuSetngs, insuplanId, _rxFG) {
        let showPopup = [];
        let showPopup2 = [];
        const keyPair = ["FlatSalesTaxAmt", "TaxRatePer", "SalesTaxBasisId", "IsTaxOnOTC", "IsTaxOnRx","SalesTax"];
        const insuDetails = await this._insuSvc.getInsSettingsInfo(insuplanId).toPromise();
        this.InsuSettings = insuDetails;
        if (insuSetngs && insuDetails && insuSetngs.InsuPlanPrice && insuDetails.InsuPlanPrice) {
            keyPair.map(key => {
                if (key === "FlatSalesTaxAmt" || key === "TaxRatePer" || key === "SalesTaxBasisId" || key === 'SalesTax') {
                    insuSetngs.InsuPlanPrice[key] = +insuSetngs.InsuPlanPrice[key];
                }
                if ((insuSetngs.InsuPlanPrice[key] !== insuDetails.InsuPlanPrice[key]) &&
                !(!insuSetngs.InsuPlanPrice[key] && (insuDetails.InsuPlanPrice[key] === 0 || insuDetails.InsuPlanPrice[key] === "0.000" || insuDetails.InsuPlanPrice[key] === "0.00"))) {
                    if (key === "IsTaxOnOTC" || key === "IsTaxOnRx") {
                        showPopup2.push(key);
                    } else {
                        showPopup.push(key);
                    }
                }
            });
        } else if ((insuSetngs && insuSetngs.InsuPlanPrice) || (insuDetails && insuDetails.InsuPlanPrice)) {
            showPopup = ["FlatSalesTaxAmt", "TaxRatePer", "SalesTaxBasisId","SalesTax"];
            showPopup2 = ["IsTaxOnOTC", "IsTaxOnRx"];
        }
        return {showpopUp: showPopup, lstStng: insuDetails, showPopup2: showPopup2};
    }

    initiateManualCal(rxFG, insuSetngs, rxType, fromCash?) {
        this.calTaxValues(rxFG, insuSetngs);
        const rxfgV: any = rxFG.getRawValue();
        rxFG.controls["TaxAmt"].setValue(+rxfgV.RxOtherAmount.FlatSalesTax + +rxfgV.RxOtherAmount.SalesTax);
        rxFG = this.caculateBalDue(rxFG, rxType);
        if (fromCash) {
            return rxFG.value;
        }
    }
    calTaxValuesForTrack(rxFG: any, _insurancSettings: any) {
        const rxInfo = rxFG.getRawValue();
        let salesTax: any = null;
        const SalesTaxPer = this.InsuSettings['InsuPlanPrice']['TaxRatePer'];
        if (this.InsuSettings && this.InsuSettings.InsuPlanPrice &&
            ((rxInfo.Drug.drugtypeid === 4 && this.InsuSettings.InsuPlanPrice.IsTaxOnOTC) ||
            (rxInfo.Drug.drugtypeid === 1 && this.InsuSettings.InsuPlanPrice.IsTaxOnRx))) {
            if (this.InsuSettings['InsuPlanPrice'].SalesTaxBasisId === 2) {
                salesTax =
                    (+rxFG.value.PrescReFill.Price && +rxFG.value.PrescReFill.Price > 0) ?
                        ((+rxFG.value.PrescReFill.Price *
                            (SalesTaxPer ? +SalesTaxPer : 0.0)) / 100
                        ).toFixed(2) : 0;
            } else if (this.InsuSettings['InsuPlanPrice'].SalesTaxBasisId === 1 ||
            this.InsuSettings['InsuPlanPrice'].SalesTaxBasisId === 3) {
                    const origProFee =  (rxFG.value ?
                        (rxFG.value['rxType'] === 'er' ? rxFG.value['PrescReFill']['OrigProfFee'] : +rxInfo.OrigProFee) : 0);

                const profPlusPrice = (
                    (rxFG.value.PrescReFill.Price ? +rxFG.value.PrescReFill.Price : 0.0) +
                    (rxInfo.Drug.drugtypeid === 4 ? (origProFee ? +origProFee: 0.0) :
                     (rxFG.value.PrescReFill.ProfFee ? +rxFG.value.PrescReFill.ProfFee : 0.0)));

                     salesTax = (profPlusPrice && +profPlusPrice > 0) ?
                    ((+profPlusPrice * (SalesTaxPer ? +SalesTaxPer : 0.0))
                        / 100).toFixed(2) : 0;
            }
        } else {
            salesTax = 0;
        }
        return salesTax;
    }
    calTaxValues(rxFG: any, insurancSettings: any) {
        const rxInfo = rxFG.getRawValue();
        const otherAmount = rxFG.controls.RxOtherAmount as FormGroup;
        if (insurancSettings && insurancSettings.InsuPlanPrice &&
            ((rxInfo.Drug.drugtypeid === 4 && insurancSettings.InsuPlanPrice.IsTaxOnOTC) ||
            (rxInfo.Drug.drugtypeid === 1 && insurancSettings.InsuPlanPrice.IsTaxOnRx))) { // OTC or Prescription
            if (otherAmount.value.SalesTaxBasis === 2) { // Ingredient Cost
                otherAmount.controls["SalesTax"].setValue(
                    (+rxFG.value.PrescReFill.Price && +rxFG.value.PrescReFill.Price > 0) ?
                        ((+rxFG.value.PrescReFill.Price *
                            (otherAmount.value.SalesTaxPer ? +otherAmount.value.SalesTaxPer : 0.0)) / 100
                        ).toFixed(2) : "0.00"
                );
            } else if (otherAmount.value.SalesTaxBasis === 1 ||
                otherAmount.value.SalesTaxBasis === 3) { // Gross Amount Due or Ingredient Cost+Disp.Fee
                    const origProFee =  (rxFG.value ?
                        (rxFG.value['rxType'] === 'er' ? rxFG.value['PrescReFill']['OrigProfFee'] : +rxInfo.OrigProFee) : 0);

                const profPlusPrice = (
                    (rxFG.value.PrescReFill.Price ? +rxFG.value.PrescReFill.Price : 0.0) +
                    (rxInfo.Drug.drugtypeid === 4 ? (origProFee ? +origProFee: 0.0) :
                     (rxFG.value.PrescReFill.ProfFee ? +rxFG.value.PrescReFill.ProfFee : 0.0)));
                otherAmount.controls["SalesTax"].setValue((profPlusPrice && +profPlusPrice > 0) ?
                    ((+profPlusPrice * (otherAmount.value.SalesTaxPer ? +otherAmount.value.SalesTaxPer : 0.0))
                        / 100).toFixed(2) : "0.00"
                );
            }
        } else {
            otherAmount.controls["SalesTax"].setValue("0.00");
        }
    }

    getInsuranceManualCalc(rxFG: any, insurancSettings: any) {
        const otherAmount = rxFG.controls.RxOtherAmount as FormGroup;
        if (insurancSettings && insurancSettings.InsuPlanPrice) {
            otherAmount.controls["FlatSalesTax"].patchValue(
                insurancSettings.InsuPlanPrice.FlatSalesTaxAmt
                ? +insurancSettings.InsuPlanPrice.FlatSalesTaxAmt
                : 0
            );
            otherAmount.controls["SalesTaxPer"].patchValue(
                insurancSettings.InsuPlanPrice.TaxRatePer
                    ? +insurancSettings.InsuPlanPrice.TaxRatePer
                    : 0
            );

            otherAmount.controls["SalesTaxBasis"].patchValue(
                insurancSettings.InsuPlanPrice.SalesTaxBasisId
            );
        }

        return rxFG;
    }

    caculateBalDue(rxFG: any, type: string) {
        let rxOthAmnt = 0;
        let rxOtherFee = 0;
        let otherAmount = 0;
        let tax = 0;
        let fakeTax = 0;
        const rxOtherAmnt = rxFG.value.RxOtherAmount;
        const PrescRefillFG: any = rxFG.controls["PrescReFill"];

        rxOtherFee = rxOtherAmnt ?
        (rxOtherAmnt.ProfFee ? +rxOtherAmnt.ProfFee : 0) + (rxOtherAmnt.Incentive ? +rxOtherAmnt.Incentive : 0)
        : 0;
        rxFG.controls["OtherFee"].setValue(rxOtherFee);

        if (rxOtherAmnt && rxOtherAmnt.OtherAmntMisc && rxOtherAmnt.OtherAmntMisc.length > 0) {
            otherAmount = (rxOtherAmnt.OtherAmntMisc[0] ? +rxOtherAmnt.OtherAmntMisc[0].Amount : 0)
                + (rxOtherAmnt.OtherAmntMisc[1] ? +rxOtherAmnt.OtherAmntMisc[1].Amount : 0) +
                (rxOtherAmnt.OtherAmntMisc[2] ? +rxOtherAmnt.OtherAmntMisc[2].Amount : 0);
            PrescRefillFG.controls["OtherAmnt"].setValue(otherAmount && otherAmount > 0 ? (otherAmount).toFixed(2) : "0.00");
        }
        rxOthAmnt = rxOtherAmnt ?
                (rxOtherAmnt.ProfFee ? +rxOtherAmnt.ProfFee : 0)
                + (rxOtherAmnt.Incentive ? +rxOtherAmnt.Incentive : 0)
                + (rxOtherAmnt.FlatSalesTax ? +rxOtherAmnt.FlatSalesTax : 0)
                + (rxOtherAmnt.SalesTax ? +rxOtherAmnt.SalesTax : 0)
                + (otherAmount ? +otherAmount : 0) : 0;

        if (type === "er") {
            fakeTax = rxOtherAmnt ? (rxOtherAmnt.FlatSalesTax ? +rxOtherAmnt.FlatSalesTax : 0)
             + (rxOtherAmnt.FaxeSalesTax ? +rxOtherAmnt.FaxeSalesTax : 0) : 0;
        } else {
            rxFG.controls["OtherAmount"].setValue(rxOthAmnt && rxOthAmnt > 0 ? (rxOthAmnt).toFixed(2) : "0.00");
            fakeTax = rxOtherAmnt ?
             (rxOtherAmnt.FlatSalesTax ? +rxOtherAmnt.FlatSalesTax : 0) + (rxOtherAmnt.SalesTax ? +rxOtherAmnt.SalesTax : 0)
             : 0;
        }

        tax = rxOtherAmnt ? (rxOtherAmnt.FlatSalesTax ? +rxOtherAmnt.FlatSalesTax : 0) + (rxOtherAmnt.SalesTax ? +rxOtherAmnt.SalesTax : 0)
        : 0;
        rxFG.controls["TaxAmt"].setValue(tax > 0 ? tax.toFixed(2) : "0.00");
        const tamt = (+rxOthAmnt - (rxOtherAmnt && rxOtherAmnt.FlatSalesTax ? +rxOtherAmnt.FlatSalesTax : 0)) +
            (PrescRefillFG.value.Price ? +PrescRefillFG.value.Price + 0 : 0)
            + (PrescRefillFG.value.ProfFee ? +PrescRefillFG.value.ProfFee : 0);
        PrescRefillFG.controls["TotalAmt"].setValue(+tamt > 0 ? (+tamt).toFixed(2) : "0.00");

        const isCashRx = this._rxStore.isCashRx$["source"]["value"];
        const rxInfo = rxFG.getRawValue();
        const balDue = ((PrescRefillFG.value.Price ? +PrescRefillFG.value.Price : 0) +
            (PrescRefillFG.value.ProfFee ? +PrescRefillFG.value.ProfFee : 0) +
            (rxInfo.OtherFee ? +rxInfo.OtherFee : 0) +
            (PrescRefillFG.value.OtherAmnt ? +PrescRefillFG.value.OtherAmnt : 0) +
            (tax ? +tax : 0)) -
            (PrescRefillFG.value.Discount ? +PrescRefillFG.value.Discount : 0);
            const balDue1 = ((PrescRefillFG.value.TotalAmt ? +PrescRefillFG.value.TotalAmt : 0)  -
            (PrescRefillFG.value.Discount ? +PrescRefillFG.value.Discount : 0));
          if (PrescRefillFG.value["IsManuaBalanceDue"]) {
             } else {
            if(isCashRx)
                  PrescRefillFG.controls["BalDue"].setValue(balDue1 > 0 ? balDue1.toFixed(2) : "0.00");
            else
                  PrescRefillFG.controls["BalDue"].setValue(balDue > 0 ? balDue.toFixed(2) : "0.00");
        }
        const proftamt =  (PrescRefillFG.value["BalDue"] ? +PrescRefillFG.value["BalDue"] : 0) -
        (rxInfo.PrescReFill.TotalRxCost ? +rxInfo.PrescReFill.TotalRxCost : 0);
        rxFG.controls["ProfitAmnt"].patchValue(+proftamt ? proftamt.toFixed(2) : "0.00");
        return rxFG;
    }

    checkIsCahsRx(rxInfo: any, patient: EPatient, systemData: SystemData) {
        let isCashRx = false;
        const systCshInsurcId = systemData && systemData["DefaultValuese"] && systemData["DefaultValuese"].length && systemData["DefaultValuese"][0] && systemData["DefaultValuese"][0]["CashInsurerId"] ? systemData["DefaultValuese"][0]["CashInsurerId"] : 0;
        if (!(rxInfo && rxInfo.RefillInsu && rxInfo.RefillInsu.InsuId)) {
            const insuCode =  patient && patient.insurance ? patient.insurance.filter(x => x.insuranceid === +patient.insuranceid)[0] : "";

            if (patient && patient.insuranceid && insuCode) {
                if (insuCode && ((insuCode.IsCash) || (insuCode.insurerid === systCshInsurcId))) {
                    isCashRx = true;
                }
            } else {
                isCashRx = true;
            }
        } else {
            const insuCode =  patient && patient.insurance ? patient.insurance.filter(x => x.insuranceid === +rxInfo.RefillInsu.InsuId)[0] : "";
            if (insuCode && ((insuCode.IsCash) || (insuCode.insurerid === systCshInsurcId))) {
                isCashRx = true;
            }
        }
        return isCashRx;
    }

    changeBillToFromActns(rxFG, systemData) {
        const patient = rxFG.get("Patient").value;
        const rxRefilInsu = rxFG.controls["RefillInsu"] as FormGroup;
        let isCashRx = this.checkIsCahsRx(rxFG.getRawValue(), patient, systemData);

        const primaryInsu = patient.insurance.filter(x => x.insuranceid === +patient.insuranceid)[0];
        const primaryCash = patient.insurance.filter(x => x.insurerid ===
            +systemData["DefaultValuese"][0]["CashInsurerId"])[0];

        const exceptCash = rxFG.value.Patient.insurance.filter(val =>
            (val["insurerid"] !== systemData["DefaultValuese"][0]["CashInsurerId"]));

        if (isCashRx) {
            if (primaryInsu && !(systemData["DefaultValuese"][0]["CashInsurerId"] === +primaryInsu["insurerid"])) {
                rxRefilInsu.controls["InsuId"].setValue(+primaryInsu["insuranceid"]);
                isCashRx = false;
            } else if (rxFG.value.Patient.insurance && rxFG.value.Patient.insurance.length > 0) {
                if (exceptCash && exceptCash.length > 0) {
                    rxRefilInsu.controls["InsuId"].setValue(+exceptCash[0]["insuranceid"]);
                } else {
                    this._alertSvc.error("There are no insurance except cash for this patient. Please add insurance.", false, "long");
                }
            }
        } else {
            if (primaryCash) {
                isCashRx = true;
                rxRefilInsu.controls["InsuId"].setValue(+primaryCash["insuranceid"]);
            } else {
                this._alertSvc.error("There is no cash for this patient. Please add cash.", false, "long");
            }
        }

        const isCsh = this.checkIsCahsRx(rxFG.getRawValue(), patient, systemData);
        this._rxStore.storeIsCashRx(isCsh);
        this.ngZone.run(() => {
            document.getElementById(rxFG.value.rxType === "er" ? "actionInputer" : "actionInput").focus();
        });
        // setTimeout(() => {
            // document.getElementById(rxFG.value.rxType === "er" ? "actionInputer" : "actionInput").focus();
        // }, 300);
    }

  

    async getDiscDtlsNdCalc(rxFG, discuntSchdl, check?: any, isPatchDis?) {

        if (check) {
            await this.checkAndPatchDiscount(rxFG);
        }
        const rxInfo: any = rxFG.value;
        if (rxInfo.PrescReFill.DiscSchId) {
            const discode: any = discuntSchdl.filter(x => x.Id === +rxInfo.PrescReFill.DiscSchId)[0];
            const resp = await this._rxService.getRxDiscount(discode.DiscCode).toPromise();
            if (resp) {
                this.assignToDiscountSchId(rxFG, resp, isPatchDis);
            }
        } else {
            this.assignToDiscountSchId(rxFG, null, isPatchDis);
        }
    }

    assignToDiscountSchId(rxFG, result, isPatchDis: boolean = false) {
        const prescReFill: FormGroup = rxFG.controls
            .PrescReFill as FormGroup;
        const rxBill: FormGroup = rxFG.controls.RxBill as FormGroup;
        let TotPrice = prescReFill.value.TotalAmt;
        let balDue = prescReFill.value.BalDue;
        if (TotPrice !== undefined && TotPrice !== null && TotPrice !== "") {
            TotPrice = parseFloat(TotPrice);
        }

        if (balDue !== undefined && balDue !== null && TotPrice !== "") {
            balDue = parseFloat(balDue);
        }

        if (result !== null && result !== false && rxFG.value["Drug"]["isdiscountable"]) {
            let DiscountAmount = 0;
            let Copay = 0;
            let tamt = 0;
            let BalDue = 0;
            let DiscountAmtTemp = 0;
            DiscountAmount = result.DiscountAmount;
            Copay = rxBill.value.CoPayChrg;

            if (
                DiscountAmount === undefined ||
                DiscountAmount === null ||
                isNaN(DiscountAmount)
            ) {
                DiscountAmount = 1;
            }

            if (!Copay) {
                Copay = 0;
            }

            if (result.DiscountType === "P") {
                if (DiscountAmount !== 0) {
                    DiscountAmount =
                        parseFloat(TotPrice) * (DiscountAmount / 100);
                }
            } else {
                DiscountAmount = Math.ceil(DiscountAmount * 100) / 100;
            }

            prescReFill.controls["Discount"].setValue(
                DiscountAmount.toFixed(2)
            );

            DiscountAmtTemp = DiscountAmount + Copay;
             const isCashRx = this._rxStore.isCashRx$["source"]["value"];
            if (TotPrice > DiscountAmtTemp) {
                tamt = TotPrice - DiscountAmtTemp;
                BalDue = balDue - DiscountAmtTemp;
                BalDue = +BalDue > 0 ? +BalDue : 0;
                const BalDue1 =  (prescReFill.value["TotalAmt"]) - DiscountAmtTemp;
                tamt = +tamt > 0 ? +tamt : 0;
                if(!isPatchDis)
                       prescReFill.controls["TotalAmt"].setValue(tamt.toFixed(2));
                prescReFill.controls["BalDue"].setValue(isCashRx ? BalDue1.toFixed(2) : BalDue.toFixed(2));
            } else {
                if(!isPatchDis)
                prescReFill.controls["TotalAmt"].setValue("0.00");
                prescReFill.controls["BalDue"].setValue("0.00");
            }
        } else {
            if(!isPatchDis)
            prescReFill.controls["TotalAmt"].setValue(TotPrice ? TotPrice.toFixed(2) : "0.00");
            prescReFill.controls["Discount"].setValue((0).toFixed(2));
            // EP-4962 : balance due should be clear when discount is clearing
            prescReFill.controls["BalDue"].setValue((0).toFixed(2));
        }
    }

    async getDrugInfoAfterEditDrug(rxtype: string, drugInfo: EDrug, rxFG?: FormGroup) {
        //while filling a deferred rx when editing the drug by changing the prices the rx sequence number is changing which need not to be done
        // if (!(rxtype === "rf" || rxtype === "er")) {
        //     const resp = await this._rxService.getPrescNoByDrugClass(drugInfo.drugclass).toPromise();
        //     this._nRxUtil.setPrescNoNdNotManulaRx(resp.nextPrescNum, rxFG);
        // }
        if (rxFG.value["RxBill"]["StatusId"] !== 2) {
            const discntCodeList = this._nRxUtil.getDisctCodeList();
            await this.getDiscDtlsNdCalc(rxFG, discntCodeList, true);
        }
    }

    async getBucketPriceAndUpdateDrug(rxInfo, drugInfo) {
        // const drugId = drugInfo ? drugInfo["id"] : null;
        const defBuck = rxInfo["PrescReFill"]["BucketId"] || this._drugBuckUtil.getDefaultBucketId();
        // This is commented due to cost price issue in drug card
    //     const bucketListFromObserval =  this._comnSvc.bucketList$["source"]["value"]
    //     const bucketList = bucketListFromObserval ? bucketListFromObserval : await this._drugBuckUtil.getDrugBucketList(drugId || null);
    //     if (bucketList) {
    //     const bucket: DrugBucketInfo = defBuck ? bucketList.find(list => list.Id === +defBuck) : bucketList[0];
    //     if (bucket && drugInfo) {
    //         drugInfo["costpack"] = bucket ? (bucket.CostPack).toFixed(2) : "0.00";
    //         drugInfo["unitpricecost"] = bucket ? (bucket.UnitPriceCost).toFixed(3) : "0.000";
    //     }
    //     return drugInfo;
    // }
    if (drugInfo && drugInfo.manageinventory && drugInfo.manageinventory.length) {
        const isExist = drugInfo.manageinventory.find(buck => buck.BucketId ===
            +defBuck) || null;
            drugInfo["costpack"] = isExist && isExist["CostPack"] ? isExist["CostPack"].toFixed(2) : "0.000";
            drugInfo["unitpricecost"] = isExist && isExist["UnitPriceCost"] ? isExist["UnitPriceCost"].toFixed(3) : "0.000";
    } 
    return drugInfo
    }

}
