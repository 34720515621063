import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from "@angular/core";
import { PatientInfo, PatAuditLog, SystemData, AccCategoryE, ToolsE, PatientFileE } from "src/app/models";
import { FormGroup } from "@angular/forms";
import { CreateNPatchFG, NewAuditUtils } from "src/app/utils";
import { MsgConfig } from "src/app";
import { AlertService, CommonService, PatientService } from "src/app/services";
import { PatientInfoUtil } from "src/app/utils/patient-info.util";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PatientStore } from "src/app/store";
import { TabNames } from "src/app/modules/patient/patient.constants";
import { EligibilityComponent } from "../../shared";
import { MergePatientComponent } from "../../tools/merge-patient/merge-patient.component";
import { PrivMaskGuard } from "src/app/guards";
import * as moment from "moment";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
@Component({
    selector: "app-common-header",
    templateUrl: "./common-header.component.html"
})
export class CommonHeaderComponent implements OnInit {

    @Input() patientInfoFG: FormGroup;
    @Input() systemData: SystemData;
    @Input() patientInfo: PatientInfo;
    @Input() Insurances: any;
    @Input() patientId: number;
    @Input() originalPatInfo: any;

    @Output() getPatData = new EventEmitter();
    @Output() closePopupModal = new EventEmitter();
    @Output() openEligibilityPopUp = new EventEmitter();
    lastEligibilityUpdtTime: any;
    eligibilityDtTm: string;
    unsubscribe$: Subject<void> = new Subject();

    constructor(private modalService: NgbModal, private _prvMskGrd: PrivMaskGuard,private _alertSvc:AlertService,private _patientStore: PatientStore) {}

    ngOnInit() {
      this.lastEligibilityUpdtTime =  (this.originalPatInfo && this.originalPatInfo["PatientElgb"] &&  this.originalPatInfo["PatientElgb"]["LastElgbDtTm"]) ? moment.utc(this.originalPatInfo["PatientElgb"]["LastElgbDtTm"]).local().format("MM/DD/YYYY hh:mm a") : "";
      this.eligibilityDtTm = this.lastEligibilityUpdtTime ? ("Last E1 check at: " + this.lastEligibilityUpdtTime) : 'Eligibility Check';
    }

    checkHasMergePriv() {
        return this._prvMskGrd.canActivate(AccCategoryE.Tools, ToolsE.MergePatients);
    }

    openEligblPopUpModal() {
        if (!this._prvMskGrd.canActivate(AccCategoryE.PatientFile,PatientFileE.AllowEligibilityCheck)) {
            this._alertSvc.error(MsgConfig.NO_PRIVS_ACCESS);
        }
        else{
            this.openEligibilityPopUp.emit();
        }
       
        // const modalRef = this.modalService.open(EligibilityComponent, {
        //     backdrop: false,
        //     size: "lg",
        //     keyboard: false
        // });
        // modalRef.componentInstance.PatientInfoFG = this.patientInfoFG;
        // modalRef.componentInstance.SystemData = this.systemData;
        // modalRef.componentInstance.PatientInfo = this.patientInfo;
        // modalRef.componentInstance.Insurances = this.Insurances;
        // modalRef.componentInstance.GetPatientInfo.subscribe(resp => {
        //     this.getPatData.emit();
        // });
    }

    openMergePatient() {
        const modalRef = this.modalService.open(MergePatientComponent, {
            backdrop: false,
            size: "lg", windowClass: "md-xx-lg comp--modal modal-padding-2 modal-center-to-page",
            keyboard: false
        });
        modalRef.componentInstance.frmPat = this.patientId;
        modalRef.componentInstance.emitPopupClosed.pipe(takeUntil(this.unsubscribe$)).subscribe(resp => {
            modalRef.close();
            if (resp) {
                this.closePopupModal.emit();
            }
        });
    }
    ngOnDestroy(){
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
