<div class="editpatient">
    <div class="row content--heading">
        <div class="col-md-8 padding-0">
            <div class="row">
                <div class="icon">
                    <img src="assets/dist/Icons/Icon_rx2.png">
                </div>
                <div class="col-md-11 padding-0">
                    <div class="content--heading__title padding-0"> Fill Records
                        <span class="content--name"></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-right pull-right col-md-4 padding-0">
            <button class="hotkey_success" autofocus (click)="changeFillRecInfo()"
            (keydown.tab)="$event.preventDefault()"><span>H</span> Change </button>
            <button class="hotkey_success" (click)="previousPage()" id="cancelButton" appShortcutKey [AltKey]="'c'">
                <span>C</span>Cancel
            </button>
        </div>
    </div>

    <div class="eprx--block__content">
        <div class="editpatient--body newrx--body edit--drug edit--drug">
            <div class="exprx--common-block">
                <div class="eprx--block__header ">
                    <div class="row">
                        <div class="col">
                            <span class="eprx--header__heading">
                                Fill Records</span>
                        </div>
                    </div>
                </div>
                <div class="eprx--block__content">
                    <div class="row">
                        <div class="col">

                            <wj-flex-grid #fileRecords [headersVisibility]="'Column'" [itemsSource]="fileRecordsWj" [isReadOnly]="true" [columnPicker]="'fileRecordsWJ'" (initialized)="init(fileRecords)">
                                <wj-flex-grid-column [header]="rxheader['hName']" [binding]="rxheader['hName']" [visible]="rxheader['isVisible']"
                                [width]="rxheader['width']" *ngFor="let rxheader of wjHeaders; let i = index" [align]="'center'">
                                   <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-row="row">
                                    <div> {{ item[rxheader['hName']]}} </div>
                                   </ng-template>
                               </wj-flex-grid-column>
                            </wj-flex-grid>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<app-other-amount
    *ngIf="activeModal"
    [rxFG]="rxFG"
    (IsPopUpClosed)="saveOtherAmount($event)"
    [insuSetng]="insuSetng"
    [insuDrpLst]="insuDrpLst"
    [systemData]="systemData"
></app-other-amount>
