<div class="editpatient">
    <div class="row content--heading">
        <div class="col-md-8 padding-0">
            <div class="row">
                <div class="icon">
                    <img src="assets/dist/Icons/Icon_rx2.png" />
                </div>
                <div class="col-md-11 padding-0">
                    <div class="content--heading__title padding-0">
                        Track Rx
                        <span class="content--name"></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-right pull-right col-md-4 padding-0">
            <button class="hotkey_success" (click)="previousPage()"><span>C</span>Cancel</button>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-6 col-md-6 col-lg-6">
            <div class="exprx--common-block">
                <div class="eprx--block__header">
                    <div class="eprx--header__heading">Rx Edit Information</div>
                </div>
                <div class="eprx--block__content">
                    <div class="row">
                        <div class="col-md-3 col-sm-3 col-lg-3">
                            <label>Rx Entered Date & Time</label>
                            <div class="label--data">
                                {{rxInfo?.PrescReFill?.CreatedDtTm ?
                                                            (rxInfo?.PrescReFill?.CreatedDtTm | localTime | date : 'MM/dd/yyyy hh:mm a') : "--"}}</div>
                        </div>
                        <div class="col-md-3 col-sm-3 col-lg-3">
                            <label>Rx Entered By</label>
                            <div class="label--data">{{rxInfo?.PrescReFill?.TechInitials ? rxInfo?.PrescReFill?.TechInitials : rxInfo?.PrescReFill?.RegPharmacist}}</div>
                        </div>
                        <div class="col-md-3 col-sm-3 col-lg-3">
                            <label>Last Modified Date</label>
                            <div class="label--data">
                                {{rxInfo?.PrescReFill?.ModifiedDtTm ?
                                                        (rxInfo?.PrescReFill?.ModifiedDtTm | localTime | date : 'MM/dd/yyyy hh:mm a') : "--"}}</div>
                        </div>
                        <div class="col-md-3 col-sm-3 col-lg-3">
                            <label>Last Modified By</label>
                            <div class="label--data">{{rxInfo?.PrescReFill?.UpdatedByUser ? rxInfo?.PrescReFill?.UpdatedByUser : rxInfo?.Prescription?.UserName}}</div>
                        </div>
                        <!-- <div class="col-md-3 col-sm-3 col-lg-3">
                            <label>Rx Recevied Date</label>
                            <div class="label--data">
                                {{(rxData && rxData.length > 0 ) ? (rxData[rxData.length - 1].VerifDtTm | localTime | date : 'MM/dd/yyyy hh:mm a'): "--"}}
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-3 col-lg-3">
                            <label>Rx Received By</label>
                            <div class="label--data">
                                {{(rxData && rxData.length > 0 ) ? rxData[rxData.length - 1].UserName : "--"}}
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-6 col-lg-6">
                            <label>Rx Received Status</label>
                            <div class="label--data">
                                {{rxData && rxData.length > 0 ?
                                (["r", "R", "R ", "r "].indexOf(rxData[rxData.length-1].Status) > -1) ? "Verified" :
                                rxData[rxData.length - 1].Status === 'RV' ? "Re-verify" : "--" : "--"}}
                            </div>
                        </div> -->
                        <div class="col-md-3 col-sm-3 col-lg-3">
                            <label>Label Printed?</label>
                            <div class="label--data">{{labelPrintData && labelPrintData.length > 0 ? "YES" : "NO"}}</div>
                        </div>
                        <div class="col-md-3 col-sm-3 col-lg-3">
                            <label>Ip Address</label>
                            <div class="label--data">{{auditData && auditData.AuditViewModel && auditData.AuditViewModel.length > 0 ? auditData.AuditViewModel[0]?.ipaddress : "--"}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="exprx--common-block">
                <div class="eprx--block__header">
                    <div class="eprx--header__heading">Pickup Information</div>
                </div>
                <div class="eprx--block__content">
                    <div class="row">
                        <div class="col-md-4 col-sm-4 col-lg-4">
                            <label>Picked Up Date</label>
                            <div class="label--data">
                                {{pickUpDet && pickUpDet.ExpDtTm ? (pickUpDet.ExpDtTm  | localTime | date : 'MM/dd/yyyy'): "--"}}</div>

                        </div>
                        <div class="col-md-4 col-sm-4 col-lg-4 padding--left__7">
                            <label>Picked Up Time</label>
                            <div class="label--data">{{pickUpDet && pickUpDet.ExpDtTm ? (pickUpDet.ExpDtTm  | localTime | date : 'hh:mm a'): "--"}}</div>

                        </div>
                        <div class="col-md-4 col-sm-4 col-lg-4 padding--left__7">
                            <label>Picked Up By</label>
                            <div class="label--data">
                                {{(pickUpDet && (pickUpDet.PkLastName || pickUpDet.PkFirstName)) ? ((pickUpDet.PkLastName ? pickUpDet.PkLastName : '') + ', ' +
                                (pickUpDet.PkFirstName ? pickUpDet.PkFirstName : '')) : "--"}}</div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xs-6 col-md-6 col-lg-6">
            <div class="exprx--common-block">
                <div class="eprx--block__header">
                    <div class="eprx--header__heading">Verifications</div>
                </div>
                <div class="eprx--block__content">
                    <div class="row">
                        <div class="col-md-4 col-sm-4 col-lg-4">
                            <label>Rx Verf. DtTm.</label>
                            <div class="label--data">
                                {{(rxData && rxData.length > 0 ) ? (rxData[rxData.length - 1].VerifDtTm | localTime | date : 'MM/dd/yyyy hh:mm a'): "--"}}
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-3 col-lg-3 padding--left__7">
                            <label>Verified By</label>
                            <div class="label--data">
                                {{(rxData && rxData.length > 0 ) ? rxData[rxData.length - 1].UserName : "--"}}</div>
                        </div>
                        <div class="col-md-3 col-sm-3 col-lg-3 padding--left__7">
                            <label>Rx Verf Status</label>
                            <div class="label--data">
                                {{rxData && rxData.length > 0 ?
                                    (["r", "R", "R ", "r "].indexOf(rxData[rxData.length-1].Status) > -1) ? (!rxData
                                    [rxData?.length-1].IsReverified ? "Verified" : "Re-Verified") :
                                    rxData[rxData.length - 1].Status === 'RV' ? "Re-Verify" : "--" : "--"}}
                            </div>
                        </div>
                        <div class="col-md-2 col-sm-2 col-lg-2 padding--left__7">
                            <label>Verf Hist</label>
                            <div class="label--data">
                                <i class="far fa-eye actions blue-eye-icon" (click)="openVerfHist(true)"></i>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-4 col-lg-4">
                            <label>DPV Verf. DtTm.</label>
                            <div class="label--data">
                                {{drugpickData && drugpickData.length > 0? (drugpickData[drugpickData.length - 1].VerifDtTm | localTime | date : 'MM/dd/yyyy hh:mm a'): "--"}}
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-3 col-lg-3 padding--left__7">
                            <label>Verified By</label>
                            <div class="label--data">
                                {{drugpickData && drugpickData.length > 0? drugpickData[drugpickData.length - 1].UserName: "--"}}</div>
                        </div>
                        <div class="col-md-3 col-sm-3 col-lg-3 padding--left__7">
                            <label>DPV Verf Status</label>
                            <div class="label--data">
                                {{drugPickVerfStatus ? drugPickVerfStatus : "--"}}
                            </div>
                        </div>
                        <div class="col-md-2 col-sm-2 col-lg-2 padding--left__7">
                            <label>Verf Hist</label>
                            <div class="label--data">
                                <i class="far fa-eye actions blue-eye-icon" (click)="openDrugVerfHist()"></i>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-4 col-lg-4">
                            <label>RPH Verf. DtTm.</label>
                            <div class="label--data">
                                {{(rphInfoData && rphInfoData.length > 0 ) ? (rphInfoData[rphInfoData.length - 1].VerifDtTm | localTime | date : 'MM/dd/yyyy hh:mm a'): "--"}}
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-3 col-lg-3 padding--left__7">
                            <label>Verified By</label>
                            <div class="label--data">{{(rphInfoData && rphInfoData.length > 0 ) ? rphInfoData[rphInfoData.length - 1].UserName : "--"}}</div>

                        </div>
                        <div class="col-md-3 col-sm-3 col-lg-3 padding--left__7">
                            <label>RPH Verf Status</label>
                            <div class="label--data">
                                {{rphInfoData && rphInfoData.length > 0 ? ["V", "V "].indexOf(rphInfoData[rphInfoData.length-1].Status) > -1 ? (!rphInfoData
                                [rphInfoData?.length-1].IsReverified ? "Verified" : "Re-Verified") :
                                rphInfoData[rphInfoData.length - 1].Status === 'RV' ? "Re-Verify" : "Not Verified" : "--"}}</div>
                        </div>
                        <div class="col-md-2 col-sm-2 col-lg-2 padding--left__7">
                            <label>Verf Hist</label>
                            <div class="label--data">
                                <i class="far fa-eye actions blue-eye-icon" (click)="openVerfHist(false)"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <!--
                EP-5100 as per the card, In PrimeRx cloud, We didn't have implemented it yet, So below html code commented. 
                <div class="exprx--common-block">
                <div class="eprx--block__header">
                    <div class="eprx--header__heading">Bin Management</div>
                </div>
                <div class="eprx--block__content">
                    <div class="row">
                        <div class="col-md-4 col-sm-4 col-lg-4">
                            <label>Date & Time In</label>
                            <div class="label--data">{{"--"}}</div>
                        </div>
                        <div class="col-md-4 col-sm-4 col-lg-4 padding--left__7">
                            <label>User In</label>
                            <div class="label--data">{{"--"}}</div>
                        </div>
                        <div class="col-md-4 col-sm-4 col-lg-4 padding--left__7">
                            <label>Rx Bin Status</label>
                            <div class="label--data">{{"--"}}</div>
                        </div>
                        <div class="col-md-4 col-sm-4 col-lg-4">
                            <label>Date & Time Out</label>
                            <div class="label--data">{{"--"}}</div>
                        </div>
                        <div class="col-md-4 col-sm-4 col-lg-4 padding--left__7">
                            <label>User Out</label>
                            <div class="label--data">{{"--"}}</div>
                        </div>
                        <div class="col-md-4 col-sm-4 col-lg-4 padding--left__7">
                            <label>Rx Bin</label>
                            <div class="label--data">{{"--"}}</div>
                        </div>
                    </div>
                </div>
            </div>-->
        </div>
        <div class="col-xs-12 col-md-12 col-lg-12">
            <div class="exprx--common-block">
                <div class="eprx--block__header">
                    <div class="eprx--header__heading">Rx Change History</div>
                </div>
                <div class="eprx--block__content">
                    <div class="row">
                        <div class="col-xs-4 col-md-4 col-lg-4">
                            <label>Rx Label Printing History</label>
                            <table class="table-striped table-sm label-printing-history-table my-3 table-bordered table">
                                <thead class="w-100 d-table bg-grey bordered">
                                    <tr>
                                        <th scope="col" class="w-50">
                                            Date Printed
                                        </th>
                                        <th scope="col" class="w-50">
                                            Printed By
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="d-block overflow-auto">
                                    <tr *ngFor="let labl of labelPrintData; let i=index;" class="d-table w-100">
                                        <td scope="col">
                                            {{ labl?.created | date : 'MM/dd/yyyy hh:mm a' }}
                                        </td>
                                        <td scope="col">
                                            {{ labl?.username | uppercase  }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="col-xs-8 col-md-8 col-lg-8">
                            <div class="row">
                                <div class="col padding_left_1_2rem" >
                                    <label>Rx Edit History</label>
                                </div>
                                <div class="col text-right margin_bottom_1rem">
                                    <label class="padding_right_1rem">Group By</label>
                                    <div class="row float-right" [formGroup]="groupFG">
                                        <div class="col-md-1 padding_right_6rem" >
                                            <input class="form-check-input" type="checkbox" [id]="'TrackcolumnGrp1'" value="10"
                                                formControlName="columnGrp" (change)="WijimoGroupBy()" />
                                            <label class="form-check-label" [for]="'TrackcolumnGrp1'" id="columnGrp">
                                                Column
                                            </label></div>
                                        <div class="col-md-1 padding_right_6rem">
                                            <input class="form-check-input" type="checkbox" [id]="'TrackdateGrp1'" value="11"
                                                formControlName="dateGrp" (change)="WijimoGroupBy()" />
                                            <label class="form-check-label" [for]="'TrackdateGrp1'" id="drugGrp">
                                                Date
                                            </label>
                                        </div>
                                        <div class="col-md-1 padding_right_6rem">
                                            <input class="form-check-input" type="checkbox" [id]="'TrackuserGrp1'" value="12"
                                                formControlName="userGrp" (change)="WijimoGroupBy()" />
                                            <label class="form-check-label" [for]="'TrackuserGrp1'" id="userGrp">
                                                By
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <wj-flex-grid #flex [headersVisibility]="'Column'" [itemsSource]="trackPatientWJ"
                                [isReadOnly]="true" [columnPicker]="''" [selectionMode]="'Row'"
                                (loadedRows)="loadedWJRows(flex)" class="track-rx-wijmo">
                                <wj-flex-grid-column [header]="actvHeader" [binding]="actvHeader" [visible]="true"
                                    *ngFor="let actvHeader of actvHeaders  ; let i = index"
                                    [width]="(actvHeader === 'DateTime') ? 220 :
                               (actvHeader === 'By' || actvHeader === 'New Value' || actvHeader === 'Old Value') ? 150 : '*'">
                                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                        <div  *ngIf="((actvHeader=== 'Old Value' || actvHeader=== 'New Value') && item['Column']?.toLowerCase() == 'notes')"  [innerHTML]="item[actvHeader]" [ngbTooltip]="RxNotesToolTip" container="body"  triggers="mouseover:mouseleave"   class="text_transform tooltip_Notes_display" >   </div>
                                        <ng-template #RxNotesToolTip >
                                            <div [innerHTML]="item[actvHeader]"></div>
                                        </ng-template>
                                        <div  *ngIf="((item['Column']?.toLowerCase() !== 'notes') || 
                                        ((item['Column']?.toLowerCase() == 'notes') && !(actvHeader=== 'Old Value' || actvHeader=== 'New Value')))"  [title]="(item[actvHeader] | uppercase)" [innerHTML]="item[actvHeader]">  </div>
                                    </ng-template>
                                </wj-flex-grid-column>
                            </wj-flex-grid>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <app-rph-verify-hist *ngIf="IsHistory" [IsRxVerification]="isRxVerification" [RxInfo]="rxInfo" (IsPopUpClosed)="closeHist($event)"></app-rph-verify-hist> -->
