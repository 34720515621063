import { Component, OnInit, Input, Renderer2, ChangeDetectorRef } from "@angular/core";
import { DrugTabUtils } from "src/app/utils/drug-tabs.utils";
import { EditDrugService, CommonService, AlertService, InsuranceService } from "src/app/services";
import * as wjcGrid from "@grapecity/wijmo.grid"
import { FormGroup } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {  NewAuditUtils, WijimoUtil } from "src/app/utils";
import { DrugAuditModel, PaginationModel } from "src/app/models";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FormatsUtil } from "src/app/utils/formats.util";

@Component({
    selector: "app-drug-ins-restriction",
    templateUrl: "./drug-ins-restriction.component.html"
})
export class DrugInsRestrictionComponent implements OnInit {
    paginationModel: PaginationModel = new PaginationModel();
    @Input() drugId: any;
    @Input() systemData: any;
    @Input() imgUrl: any;
    @Input() dropDownValues: any;
    @Input() VaccineId: any;
    @Input() drugInfoFG: FormGroup;
    formGroupInvalid: any;
    pageNu = this.paginationModel.pageNumber;
    Size = this.paginationModel.pageSize;
    wjHeaders: any;
    insuRestData$: any;
    drugInsuFG: FormGroup;
    popUpType: string;
    openPopUP: any;
    HasInsuFilter: boolean;
    insuranceData$: any;
    insuRestWJData: {count: number; wjSource: any; result: any; } = { count: 0, wjSource: null, result: null};
    deleteData: any;
    selectedData: any;
    isDataExists = true;
    unsubscribe$: Subject<void> = new Subject();
    costBasisDrpDown: any;
    selectedVaccine: any;
    constructor(private _drugTabUtil: DrugTabUtils, private _editDrugSvc: EditDrugService, private _renderer: Renderer2,  private _insuSvc: InsuranceService,
        private _modalSvc: NgbModal, private _commonSvc: CommonService, private _alertSvc: AlertService, private _wijimoUtils: WijimoUtil, private _auditUtils: NewAuditUtils,
        public _formatsUtil:FormatsUtil, private _cdr: ChangeDetectorRef
    ) {
            this.insuranceData$ = this._commonSvc.insuranceData$;
        }

    ngOnInit() {
        this.costBasisDrpDown = this._insuSvc.dropdownData$["source"]["value"];
        console.log(this.systemData);
        this.getInsuRestdata();
        this.patchDefaultValHeaders();
        this._wijimoUtils.wjSavedData$.pipe(takeUntil(this.unsubscribe$)).subscribe(w => {
            if (w && w["WijmoKey"] && w["WijmoKey"] === "drugInsuRestListWJ") {
                this.patchDefaultValHeaders();
            }
        });
    }

    async getInsuRestdata() {
        this.selectedVaccine = this.dropDownValues.VaccineGroups.find((item: any) => item.VaccineGroupID === this.VaccineId);
        this.insuRestWJData = await this._drugTabUtil.getDrugInsuRestData(this.drugId, this.pageNu, this.Size, this.selectedVaccine);
        if (this.insuRestWJData && this.insuRestWJData.count) {
            this.isDataExists = true;
        } else {
            this.isDataExists = false;
        }
    }

    async patchDefaultValHeaders() {
        const storedWJ = await this._wijimoUtils.getWJSavedData("drugInsuRestListWJ");
        this.wjHeaders = this._wijimoUtils.patchDefHeader("drugInsuRestListWJ", storedWJ);
    }

    setPage(page: number) {
        this.pageNu = page;
        this.getInsuRestdata();
    }

    setSize(size: any) {
        this.Size = size;
        this.pageNu = this.paginationModel.pageNumber;
        this.getInsuRestdata();
    }

    init(flex: wjcGrid.FlexGrid) {
        flex.columnHeaders.rows[0].wordWrap = true;
    }

    applytoAll() {
        if (this.drugInsuFG.controls["IsAllInsu"].value) {
            const totalList = this.insuRestWJData.result;
            const isExistAllInsu = totalList?.length ? totalList.find((item: any) => (item.IsAllInsu && (item.Id !== this.drugInsuFG.value["Id"]))) : false;
            if (isExistAllInsu) {
                this.drugInsuFG.controls["IsAllInsu"].patchValue(false);
                this._alertSvc.error("Insurance Preference with Apply to All Insurances - Yes, already exists.");
            } else {
                this.HasInsuFilter = true;
                this.drugInsuFG.controls["InsurId"].patchValue(null);
            }
        } else {
            this.HasInsuFilter = false;
            this.drugInsuFG.controls["InsurId"].patchValue(null);
        }
        this.drugInsuFG.controls["InsurId"].markAsTouched();
        this._cdr.detectChanges();
            this.drugInsuFG.controls["InsurId"].markAsUntouched();
    }

    allowUniqueInsurance(event) {
        console.log(event)
        if (event && event.value && event.value.InsuCarrierId) {
            const totalList = this.insuRestWJData.result;
            const isExistAllInsu = totalList?.length ? totalList.find((item: any) => ((item.InsurId === event.value.InsuCarrierId) && (item.Id !== this.drugInsuFG.value["Id"]))) : false;
            if (isExistAllInsu) {
                this.drugInsuFG.controls["InsurId"].patchValue(null);
                this._alertSvc.error("Insurance Preference with the selected Insurance, already exists.");
            }
        }
    }

    openAddModal(content) {
        this.HasInsuFilter = false;
        this.drugInsuFG = this._drugTabUtil.createInsuRestFG();
        this.drugInsuFG.controls["DrugId"].patchValue(this.drugId);
        this.drugInsuFG.controls["VaccineGrp"].patchValue(this.selectedVaccine ? this.selectedVaccine.VaccineGroupName : "");
        this.drugInsuFG.controls["ImageUrl"].patchValue(this.imgUrl);
        this.drugInsuFG.controls["Incentive"].patchValue(this.selectedVaccine ? 40.00 : 0.00);
        this.drugInsuFG.controls["CostBasisId"].patchValue(this.selectedVaccine ? 3 : null);
        this.drugInsuFG.controls["ServReasonId"].patchValue(this.selectedVaccine ? 38 : null);
        this.drugInsuFG.controls["ProfServId"].patchValue(this.selectedVaccine ? 8 : null);
        this.drugInsuFG.controls["ServResultId"].patchValue(this.selectedVaccine ? 23 : null);
        this.openPopUP = this._modalSvc.open(content, {
            centered: true,
            size: "lg",
            keyboard: false,
            backdrop: false
        });
        this.popUpType = "Add";
    }

    async addInsuRest() {
        const response = await this._drugTabUtil.addInsuRest(this.drugInsuFG.value);
        response ? this.getInsuRestdata() : this._alertSvc.error("Add Insurance restrictions save unsuccessful.");
       this.auditLog();
       
    }

    async updateInsuRest() {
        const response = await this._drugTabUtil.editInsuRest(this.drugInsuFG.value);
        response ? this.getInsuRestdata() : this._alertSvc.error("Edit Insurance restrictions save unsuccessful.");
        this.auditLog();
    }

    async checkAddInsuRest() {
        this.drugInsuFG.controls["DrugId"].patchValue(this.drugId);
        if (!this.drugInsuFG.value["IsAllInsu"]) {
            if (this.drugInsuFG.value["InsurId"]) {
                if (this.openPopUP) {
                    this.openPopUP.close();
                }
               (this.popUpType === "Add") ? this.addInsuRest() : this.updateInsuRest();
            } else {
                this.formGroupInvalid = true;
            }
        } else {
            if (this.openPopUP) {
                this.openPopUP.close();
            }
            (this.popUpType === "Add") ? this.addInsuRest() : this.updateInsuRest();
        }
    }

    editPopModal(content: any, item: any) {
        const completeData = this.insuRestWJData["result"];
        this.selectedData = completeData.find(val => val.Id === item["Id"]);
        this.drugInsuFG = this._drugTabUtil.createAndPatchInsuRestFG(this.selectedData);
        this.HasInsuFilter = item["Apply to All Ins"] === "YES" ? true : false;
        this.selectedVaccine = this.dropDownValues.VaccineGroups?.length ? this.dropDownValues.VaccineGroups.find((item: any) => item.VaccineGroupID === this.VaccineId) : null;
        this.drugInsuFG.patchValue({
            IsAllInsu: ((item["Apply to All Ins"] == "YES") ? true : false),
            InsurId:(item["InsuranceId"] ? item["InsuranceId"] : null),
            // IsAllowed : (item["Allowed"] == "YES") ? true : false,
            // BillingNDC : item["Billing NDC"],
            // NDCQualiId : item["NdcQualiId"],
            // IsPriorApprReqd:(item["PA.?"] == "YES") ? true : false,
            MaxRefillsAllowed:item["Max.Refills"],
            MaxQtyAllowed:item["MaxQty"],
            ImageUrl: this.imgUrl,
            VaccineGrp: this.selectedVaccine ? this.selectedVaccine.VaccineGroupName : ""
        })
        this.openPopUP = this._modalSvc.open(content, {
            centered: true,
            size: "lg",
            keyboard: false,
            backdrop: false
        });
        this.popUpType = "Update";
    }

    deletePopUpModal(content, item: any) {
        this.openPopUP = this._modalSvc.open(content, { centered: true , keyboard: false,
                            backdrop: false, windowClass: "large--content"});
        this.popUpType = "Delete";
        this.deleteData = item;
    }
    async deleteInsuRest() {
        const response = await this._drugTabUtil.deleteInsuRest(this.deleteData["Id"]);
        response ? this.getInsuRestdata() : this._alertSvc.error("Delete Insurance restrictions unsuccessful.");
    }
    auditLog(){
    const drugDataModel = new DrugAuditModel().drugInsuranceRestriction;
    this._auditUtils.saveAuditChange(
    this.popUpType == "Add" ? null : this.selectedData,
    this.drugInsuFG.value,
    "Add Insurance Restrictions",
    "Drug",
    this.drugId,
    drugDataModel,
    null);
    }
    async setNumberFormat() {
        this.drugInsuFG.controls["QtyConCal"].patchValue(
            (parseFloat(this.drugInsuFG.controls["QtyConCal"].value)).toFixed(3)
        );
    }
    ngOnDestroy(){
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    changeToNumber(val: string, prop: string) {
        this.drugInsuFG.controls[prop].setValue(val ? (parseFloat(val)).toFixed(2) : "0.00");
    }
}