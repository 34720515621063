import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import * as wjcCore from "@grapecity/wijmo";
import * as wjcGrid from "@grapecity/wijmo.grid";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import { PreviousRouteService } from 'src/app/services';
import { BaseUtils } from 'src/app/utils/base.utils';
import { PaginationModel } from "../../../models";
import { Data } from "./../../../models/erx-filter.model";
import { MergePatientService } from "./../../../services/merge-patient.service";

@Component({
    selector: "app-merge",
    templateUrl: "./merge.component.html"
})
export class MergeComponent implements OnInit, AfterViewInit {
    paginationModel: PaginationModel = new PaginationModel();
    patientRxHistoryWJ: any;
    patient2RxHistoryWJ: any;
    actvHeaders: string[];
    inActvHeaders: string[];
    hasPatientHistory = false;
    patientData: any;
    patient2Data: any;
    hasPatient2 = false;
    isdisable = true;
    hasPatient2D = false;
    patient1Id: any;
    patient2Id: any;
    data: Data;
    cantMerge = true;
    patient1: any;
    patient2D: any;
    urlPatienId: number;
    isSame = false;
    isAuto = false;
    isreset1 = false;
    pageNumber: number;
    pageSize: number;
    rxHistoryParams: FormGroup;
    @ViewChild("PatSearch1")
    patSearch1: ElementRef;

    @ViewChild("PatSearch2")
    patSearch2: ElementRef;

    @ViewChild("content", { static: true })
    content: any;

    @HostListener("window:keydown", ["$event"])
    keyEventAlt(event: KeyboardEvent) {
        if (event.altKey && !this.modalService.hasOpenModals()) {
            if (event.which === 67) {
                // c
                event.preventDefault();
                this.routeBackToPrevScreen();
            } else if (event.which === 77) {
                // m
                if (!this.cantMerge) {
                    event.preventDefault();
                    this.openModal(this.content);
                }
            }
        }
    }

    constructor(
        private _fb: FormBuilder,
        private _mergePatient: MergePatientService,
        private modalService: NgbModal,
        private _actvRoute: ActivatedRoute,
        private _baseUtils: BaseUtils,
        private _prevSer: PreviousRouteService
    ) {
        this._actvRoute.params.subscribe(params => {
            this.urlPatienId = +params["pid"];
            if (this.urlPatienId) {
                this.patientInfo1(this.urlPatienId);
            }
        });
    }

    ngOnInit() {
        this.rxHistoryParams = this._fb.group({
            patientId: 0,
            Billstatus: 0,
            RxStatus: 0,
            StartDate: null,
            EndDate: null,
            PageNumber: this.paginationModel.pageNumber,
            PageSize: this.paginationModel.pageSize,
            OrderByColumn: null
        });
    }

    ngAfterViewInit() {
        this.patSearch1["searchBox"].nativeElement.focus();
    }

    patientInfo1($event) {
        if ($event) {
            if (this.urlPatienId) {
                this.patient1Id = this.urlPatienId;
                this.isAuto = true;
            } else {
                this.patient1Id = $event.patientid;
            }
            this.getData(this.patient1Id, $event, true, false);
            this.isdisable = false;
        }
    }
    patientInfo2($event, error) {
        if ($event) {
            this.patient2Id = $event.patientid;
            if (this.patient1Id === this.patient2Id) {
                this.modalService.open(error, { centered: true, windowClass:"large--content"});
                this.hasPatient2D = false;
            } else if (this.patientData.RxCount === 0) {
                this.cantMerge = true;
                this.hasPatient2D = true;
                this.getData(this.patient2Id, $event, false, true);
            } else {
                this.cantMerge = false;
                this.hasPatient2D = true;
                this.getData(this.patient2Id, $event, false, true);
            }
        }
    }
    getData(id: any, data: any, hasPatient1: any, hasPatient2: any) {
        this._mergePatient.getData(id).subscribe(resp => {
            if (resp) {
                //  this.rxHistoryParams.value["patientId"] = id;
                this.rxHistoryParams.controls["patientId"].patchValue(id);
                if (hasPatient1) {
                    this.patient1 = data;
                    this.patientData = resp;
                    this.rxHistoryParams.value[
                        "PageSize"
                    ] = this.patientData.RxCount;
                    this.rxHistoryParams.controls["PageSize"].patchValue(
                        this.patientData.RxCount
                    );
                }
                if (hasPatient2) {
                    this.patient2D = data;
                    this.patient2Data = resp;
                    //  this.rxHistoryParams.value["PageSize"] = this.patient2Data.RxCount;
                    this.rxHistoryParams.controls["PageSize"].patchValue(
                        this.patient2Data.RxCount
                    );
                }
                if (this.rxHistoryParams.value["PageSize"] > 0) {
                    this._mergePatient
                        .getRx(this.rxHistoryParams.value)
                        .subscribe(resps => {
                            if (resps && resps["rxHistory"]) {
                                if (resps["rxHistory"].length > 0) {
                                    if (hasPatient1) {
                                        if (this.hasPatient2D) {
                                            this.cantMerge = false;
                                        }
                                        this.patientData.RxCount =
                                            resps["rxHistory"].length;
                                    } else if (hasPatient2) {
                                        this.patient2Data.RxCount =
                                            resps["rxHistory"].length;
                                    }
                                    this.generatePatientRxHistoryWJ(
                                        resps,
                                        hasPatient2
                                    );
                                } else {
                                    if (hasPatient1) {
                                        this.patientData.RxCount =
                                            resps["rxHistory"].length;
                                    } else if (this.hasPatient2D) {
                                        this.patient2Data.RxCount =
                                            resps["rxHistory"].length;
                                    }
                                }
                            } else {
                                if (hasPatient1) {
                                    this.patientData.RxCount = 0;
                                } else if (hasPatient2) {
                                    this.patient2Data.RxCount = 0;
                                }
                            }
                        });
                }
            }
        });
    }
    generatePatientRxHistoryWJ(patRxHist: any, hasPatient: any) {
        this.patientRxHistoryWJ = new wjcCore.CollectionView(
            patRxHist.rxHistory.map((patient, i) => {
                let k = {};
                k["Row Id"] = i;
                k["Rx#"] = patient.Prescription.PrescNum;
                k["Auth Ref"] = patient.Prescription.PriorAuthNum
                    ? patient.Prescription.PriorAuthNum
                    : 0;
                k["Ref No"] = patient.PrescReFill.ReFillNum;
                k["Insurance"] =
                    patient.insuranceInfo && patient.insuranceInfo.insuCarrier
                        ? patient.insuranceInfo.insuCarrier.InsurerCode
                        : null;
                k["Status"] = this.rxHistoryParams.value["RxStatus"];
                k["Drug"] = patient.Drug.genericname;
                k["Qty"] = patient.PrescReFill.DispQty;
                k["SDays"] = patient.PrescReFill.SupplyDays;
                k["Fill Date"] = moment(patient.PrescReFill.FillDtTm).format(
                    "MM/DD/YYYY"
                );
                k["Copay"] = patient.PrescReFill.CoPay;
                k["Price"] = patient.PrescReFill.Price;
                k["Prescriber Name"] =
                    patient.Prescriber.prescriberlastname +
                    ", " +
                    patient.Prescriber.prescriberfirstname;
                k["NDC"] = patient.Drug.ndc;
                const duedays = Math.round(patient.PrescReFill.SupplyDays) -
                    moment().startOf("day").diff(moment.utc(patient.PrescReFill.FillDtTm).startOf("day"), "days");
                k["Due Days"] = (k["Status"] === "T" || (k["Auth Ref"] === 0 && k["Ref No"] === 0)) ? 0 : duedays < 0 ? 0 : duedays;
                k["DueDays"] = duedays;
                k["DVrf?"] =
                    patient.drugPickVerificationStatus === "P" || "p"
                        ? (patient.drugPickVerificationStatus = "VERIFIED")
                        : (patient.drugPickVerificationStatus = "NOT VERIFIED");
                k["PVrf?"] = patient.rph2VerificationStatus;
                k["Copay Status"] = patient.PrescReFill.IsCopayPaid
                    ? (patient.PrescReFill.IsCopayPaid = "PAID")
                    : (patient.PrescReFill.IsCopayPaid = "UNPAID");
                k["PatientId"] = patient.Patient.patientid;
                return k;
            })
        );
        this.actvHeaders = [
            "Rx#",
            "Ref No",
            "Insurance",
            "Drug",
            "Qty",
            "SDays",
            "Fill Date"
        ];
        this.inActvHeaders = [
            "Row ID",
            "A reff (Prescription Refill Number)",
            "Status",
            "Rx History Prescriber Id",
            "Patient Name",
            "Bill type",
            "Refill Due Date",
            "Rx History Status ID",
            "Rx History Drug ID",
            "Rx History Rx ID",
            "Rx History Prescriber Status",
            "Picked Up",
            "St Order status",
            "Refill Exp",
            "Drug Class",
            "Ordered Date",
            "Presc Count",
            "Copay",
            "Price",
            "Prescriber Name",
            "NDC",
            "Due Days",
            "DVrf"
        ];
        this.hasPatientHistory = true;
        this.hasPatient2 = hasPatient;
        if (!hasPatient) {
            this.patient2RxHistoryWJ = this.patientRxHistoryWJ;
        }
    }
    init(flex: wjcGrid.FlexGrid) {
        flex.columnHeaders.rows[0].wordWrap = true;
    }
    mergePatient(success) {
        if (this.patient1Id && this.patient2Id) {
            this.data = {
                patientId: this.patient2Id,
                PatientIdForDelete: this.patient1Id,
                statusId: 0
            };
            this._mergePatient.mergePatient(this.data).subscribe(resp => {
                if (resp) {
                    this.patient2Data = null;
                    this.patientData = null;
                    this.hasPatientHistory = false;
                    this.hasPatient2 = false;
                    this.isdisable = true;
                    this.hasPatient2D = false;
                    this.modalService.open(success, { centered: true, windowClass:"large--content"});
                }
            });
        }
    }
    openModal(content) {
        this.modalService.open(content, { centered: true, windowClass:"large--content" });
    }
    resetPatient1() {
        // this.patientData = null;
        this.hasPatientHistory = false;
        this.isdisable = true;
        this.isreset1 = true;
        this.cantMerge = true;
    }
    resetPatient2() {
        this.cantMerge = true;
        this.hasPatient2 = false;
        this.patient2Data = null;
        this.hasPatient2D = false;
    }
    routeBackToPrevScreen() {
        this._prevSer.routeBackToPrevScreen();
        // this._route.navigate(["eprx/dashboard"]);
    }

    keytab(event: any, fg?: any) {
        if (event.which === 40 || event.which === 39 || event.which === 13) {
            let isFcValid = true;
            if (fg === "patient2Id") {
                if (this.patient1Id) {
                    isFcValid = true;
                } else {
                    isFcValid = false;
                }
            } else if (fg === "patient1Id") {
                if (this.patient2Id) {
                    isFcValid = true;
                } else {
                    isFcValid = false;
                }
            }
            this._baseUtils.keytab(event, isFcValid);
        }
    }
}
