<div class="row exprx--common-block">
    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 padding-sm-15 flex--one padding-0">
        <div class="box-default">
            <div class="eprx--block__header">
                <div class="eprx--header__heading">
                    Drug Information
                </div>
            </div>
            <div class="eprx--block__content">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 ">
                        <eprx-input [LabelText]="'Drug Quick Code'" [PlaceHolder]="'Drug Quick Code'"
                            [ControlName]="'QuickCode'" [ErrorDefs]="{invalid: 'Already Exist'}"
                            [FormGroupName]="drugInfoFG?.get('Drug')" [MaxLength]="10" [RxSelectId]="'QuickCode'"
                            [MarkAsTouched]="drugInfoFG?.get('Drug.QuickCode')?.touched">
                        </eprx-input>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <eprx-input [LabelText]="'Drug Name'" [PlaceHolder]="'Drug Name'" [ControlName]="'Name'"
                            [FormGroupName]="drugInfoFG?.get('Drug')" [IsRequired]="true"
                            [ErrorDefs]="{required: 'Required'}" [MaxLength]="35" [FormGroupInvalid]="formGroupInvalid"
                            [RxSelectId]="'Name'" [MarkAsTouched]="drugInfoFG?.get('Drug.Name')?.touched">
                        </eprx-input>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <eprx-input [LabelText]="'Drug Label Name'" [PlaceHolder]="'Drug Label Name'"
                            [ControlName]="'DrugLabelName'" [FormGroupName]="drugInfoFG?.get('Drug')" [MaxLength]="30"
                            [FormGroupInvalid]="formGroupInvalid" [RxSelectId]="'LabelName'">
                        </eprx-input>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <eprx-input [LabelText]="'Drug Full Name'" [PlaceHolder]="'Drug Full Name'"
                            [ControlName]="'DrugFullName'" [FormGroupName]="drugInfoFG?.get('Drug')" [MaxLength]="200"
                            [FormGroupInvalid]="formGroupInvalid" [RxSelectId]="'FullName'">
                        </eprx-input>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--right__7">
                        <eprx-input [LabelText]="'Drug NDC Code'" [PlaceHolder]="'_____-____-__'"
                            [ControlName]="'DrugCode'" [FormGroupName]="drugInfoFG?.get('Drug')" [MaxLength]="13" [MinLength]="1"
                             [IsRequired]="true" [InputType]="'ALPHANUMERIC'"    [MaskPattern]="'AAAAA-AAAA-AA'"
                            [ErrorDefs]="{required: 'Required', pattern: '', minlength: 'Enter Valid NDC'}"
                            [RxSelectId]="'DrugCode'" [MarkAsTouched]="drugInfoFG?.get('Drug.DrugCode')?.touched"
                            [IsDisabled]="drugId ? true : (drugInfo?.Drug?.DrugTypeId === 2)? true : false"
                            [FormGroupInvalid]="formGroupInvalid" >
                        </eprx-input>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <eprx-select [LabelText]="'NDC Qualifier'" [PlaceHolder]="'NDC Qualifier'" [ControlName]="'NDCQualiId'"
                            [FormGroupName]="drugInfoFG?.get('Drug')" [HasMultiple]="false" [BindLabel]="'Name'"
                            [RxSelectId]="'NDCQualiId'" [BindLabel2]="'Remarks'" [BindValue]="'Id'" [LabelForId]="'Id'"
                            [List]="systemData?.NdcQualifier" [Show2Values]="true" [show2ValInInputhidden]="true">
                        </eprx-select>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <eprx-input [LabelText]="'Strength'" [RxSelectId]="'Strength'" [PlaceHolder]="'Strength'"
                            [ControlName]="'Strength'" [FormGroupName]="drugInfoFG?.get('Drug')"
                            [ErrorDefs]="{required: 'Required', maxlength: 'length exceeding than 15'}"
                            [AutoFocus]="focusOn === 'Strength'" [FormGroupInvalid]="formGroupInvalid" [MaxLength]="15"
                            [MarkAsTouched]="drugInfoFG?.get('Drug.Strength')?.touched">
                        </eprx-input>
                    </div>
                    <!-- <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 padding--left__7">
                        <eprx-select [LabelText]="'  '" [PlaceHolder]="''" [ControlName]="'MeasureUnitId'"
                            [FormGroupName]="drugInfoFG?.get('Drug')" [HasMultiple]="false" [BindLabel]="'Remarks'"
                            [BindValue]="'Id'" [LabelForId]="'Name'"
                            [IsRequired]="drugInfoFG?.get('Drug.DrugTypeId').value === 1" [List]="systemData?.DrugUnit"
                            [AutoFocus]="focusOn === 'StrengthUnit'" [RxSelectId]="'strengthUnit'"
                            [ErrorDefs]="{required: 'Required'}" [FormGroupInvalid]="formGroupInvalid"
                            [MarkAsTouched]="drugInfoFG?.get('Drug.MeasureUnitId')?.touched">
                        </eprx-select>
                    </div> -->
                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <eprx-select [LabelText]="'Billing Units'" [PlaceHolder]="'Billing Units'"
                            [ControlName]="'DrugUnitId'" [FormGroupName]="drugInfoFG?.get('Drug')" [HasMultiple]="false"
                            [BindLabel]="'Remarks'" [BindLabel2]="'Name'" [BindValue]="'Id'" [LabelForId]="'Remarks'"
                            [List]="BillingUnitDropDowns" [AutoFocus]="focusOn === 'DrugUnit'"
                            [RxSelectId]="'MeasureUnitId'" [IsRequired]="drugInfoFG?.get('Drug.DrugTypeId').value === 1"
                            [ErrorDefs]="{required: 'Required'}" [FormGroupInvalid]="formGroupInvalid"
                            [MarkAsTouched]="drugInfoFG?.get('Drug.DrugUnitId')?.touched" [Show2Values]="true" [show2ValInInputhidden]="true">
                        </eprx-select>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <eprx-input [LabelText]="'Quantity in Pack'" [PlaceHolder]="'Quantity in Pack'"
                            [ControlName]="'QtyPack'" [FormGroupName]="drugInfoFG?.get('Drug')" [DropSpclChar]="false"
                            [DecimalValues]="3" [InputType]="'NUMBER'" [MaskPattern]="'099999.000'"
                            [RxSelectId]="'QtyPack'" (TriggerSearchValue)="convertToFixedDecimals('QtyPack')">
                        </eprx-input>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <eprx-select [LabelText]="'Drug Form'" [PlaceHolder]="'Drug Form'" [ControlName]="'DrugFormId'"
                            [FormGroupName]="drugInfoFG?.get('Drug')" [HasMultiple]="false" [RxSelectId]="'DrugFormId'"
                            [BindLabel]="'FormName'" [BindValue]="'FDAFormID'" [LabelForId]="'Name'"
                            [List]="dropDownValues?.FDA_Form" [IsRequired]="true" [ErrorDefs]="{required: 'Required'}"
                            [FormGroupInvalid]="formGroupInvalid" [MarkAsTouched]="drugInfoFG?.get('Drug.DrugFormId')?.touched">
                        </eprx-select>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <eprx-select [LabelText]="'Drug Manufacturer'" [PlaceHolder]="'Drug Manufacturer'"
                            [ControlName]="'ManufactId'" [FormGroupName]="drugInfoFG?.get('Drug')" [HasMultiple]="false"
                            [BindLabel]="'Name'" [BindValue]="'Id'" [LabelForId]="'Name'"
                             [AddItem]="true" [InputValue]="drugInfoFG?.controls['Drug'].value['ManufactName']" [SelectOnTab]="true"
                            [List]="manfactList" [RxSelectId]="'ManufactId'" (TriggerSelectValue)="isTypedValue($event)" (TriggerOnTabbing)="focusOutFromGroup()"
                            [ErrorDefs]="{required: 'Required'}" [FormGroupInvalid]="formGroupInvalid" [InputErrors]="drugInfoFG?.controls['Drug'].value['ManufactName']?.errors">
                        </eprx-select>
                    </div>
                    <!-- <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <eprx-input [LabelText]="'Drug Label Name'" [PlaceHolder]="'Drug Label Name'"
                            [ControlName]="'DrugLabelName'" [FormGroupName]="drugInfoFG?.get('Drug')" [MaxLength]="30"
                            [FormGroupInvalid]="formGroupInvalid" [RxSelectId]="'LabelName'">
                        </eprx-input>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <eprx-input [LabelText]="'Drug Full Name'" [PlaceHolder]="'Drug Full Name'"
                            [ControlName]="'DrugFullName'" [FormGroupName]="drugInfoFG?.get('Drug')" [MaxLength]="200"
                            [FormGroupInvalid]="formGroupInvalid" [RxSelectId]="'FullName'">
                        </eprx-input>
                    </div> -->

                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <eprx-select [LabelText]="'Drug Type'" [PlaceHolder]="'Drug Type'" [ControlName]="'DrugTypeId'"
                            [FormGroupName]="drugInfoFG?.get('Drug')" [HasMultiple]="false" [RxSelectId]="'DrugTypeId'"
                            [BindLabel]="'Remarks'" [BindValue]="'Id'" [LabelForId]="'Name'"
                            [List]="systemData?.DrugType" [IsRequired]="true" [ErrorDefs]="{required: 'Required'}"
                            [FormGroupInvalid]="formGroupInvalid" (TriggerSelectValue)="selectDrugType($event?.value)"
                            [IsDisabled]="(drugId && drugInfo?.Drug?.DrugTypeId === 2)? true : false" [MarkAsTouched]="drugInfoFG?.get('Drug.DrugTypeId')?.touched">
                        </eprx-select>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 height_4_8rem">
                        <label class="col-md-12 padding-0"> Is Brand? </label>
                        <eprx-radio [ControlName]="'IsBranded'" [FormGroupName]="drugInfoFG?.get('Drug')"
                            [List]="['Yes', 'No']" [ValueList]="[true, false]" [IDForList]="['IsBrandedyes', 'IsBrandedno']"
                            [LabelForId]="">
                        </eprx-radio>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <eprx-input [LabelText]="'Brand Name'" [PlaceHolder]="'Brand Name'" [ControlName]="'BrandName'"
                            [FormGroupName]="drugInfoFG?.get('Drug')" [RxSelectId]="'BrandName'" [MaxLength]="30">
                        </eprx-input>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <eprx-input [LabelText]="'Generic Name'" [PlaceHolder]="'Generic Name'"
                            [ControlName]="'GenericName'" [FormGroupName]="drugInfoFG?.get('Drug')"
                            [RxSelectId]="'GenericName'" [MaxLength]="30">
                        </eprx-input>
                    </div>
                    <div class="col-lg-5 col-md-5 col-sm-5 col-xs-5 pr-0">
                        <eprx-input
                            [LabelText]="(drugInfoFG?.get('Drug.IsBranded').value === true) ? 'Generic NDC' : 'Brand NDC'"
                            [PlaceHolder]="'_____-____-__'" [ControlName]="'BrandOrGenNDC'"
                            [FormGroupName]="drugInfoFG?.get('Drug')" [MaxLength]="13" [MinLength]="10" [MaskPattern]="'AAAA0-0000-00'"
                            [RxSelectId]="'BrandOrGenNDC'" [DropSpclChar]="true" [ErrorDefs]="{minlength: 'Enter Valid NDC'}">
                        </eprx-input>
                    </div>
                    <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 padding_top_1_8rem padding_left_0_3rem" >
                        <i class="far fa-lg fa-search" (click)="openCopyNDCPopUp(COPYNDC, 'BrandOrGenNDC')"></i>
                    </div>
                    <div class="col-lg-5 col-md-5 col-sm-5 col-xs-5 pr-0">
                        <eprx-input [LabelText]="'Replacement NDC'" [PlaceHolder]="'_____-____-__'" [MinLength]="10"
                            [ControlName]="'ReplaceNDC'" [FormGroupName]="drugInfoFG?.get('Drug')" [MaxLength]="13"
                            [MaskPattern]="'00000-0000-00'" [DropSpclChar]="true" [RxSelectId]="'ReplaceNDC'"
                            [ErrorDefs]="{minlength: 'Enter Valid NDC'}">
                        </eprx-input>
                    </div>
                    <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 padding_top_1_8rem padding_left_0_3rem">
                        <i class="far fa-lg fa-search" (click)="openCopyNDCPopUp(COPYNDC, 'ReplaceNDC')"></i>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <eprx-input [LabelText]="'Universal Product Code'" [PlaceHolder]="'Universal Product Code'" [ControlName]="'UPC'"
                            [FormGroupName]="drugInfoFG?.get('Drug')" [MaxLength]="20" [RxSelectId]="'UPC'">
                        </eprx-input>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <eprx-select [LabelText]="'Unit Dose Indicator'" [PlaceHolder]="'Unit Dose Indicator'"
                            [ControlName]="'UnitDoseIndId'" [FormGroupName]="drugInfoFG?.get('Drug')" [DropdownPosition]="'top'"
                            [HasMultiple]="false" [Show2Values]="true" [BindLabel2]="'Remarks'" [BindLabel]="'Name'"
                            [BindValue]="'Id'" [LabelForId]="''" [List]="systemData?.UnitDoseIndication"
                            [RxSelectId]="'UnitDoseIndId'">
                        </eprx-select>
                    </div>
                    <div class="col-lg-12 padding--right__7" [formGroup]="drugInfoFG?.get('Drug')">
                        <label class="control-label full--width">
                            <span>Remarks</span>
                            <textarea class="custom--text__area col-md-12"  
                            placeholder="Remarks"
                            formControlName="Remarks" value="{{(drugInfo$ | async)?.Drug?.Remarks}}"
                            ></textarea>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-xl-8 col-lg-8 drug--price__info">
        <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 padding-sm-15 flex--one padding-0">
                <div class="box-default">
                    <div class="eprx--block__header">
                        <div class="eprx--header__heading">
                            Drug Price Information
                        </div>
                    </div>
                    <div class="eprx--block__content">
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 padding--left__0">
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <app-rx-select
                                    [RxSelectId]="'bucketListFrmED'"
                                    [LabelText]="'Inv. Bucket Name'"
                                    [PlaceHolder]="'Bucket Name'"
                                    [ErrorDefs]="{required: 'Required'}"
                                    [ControlName]="'Id'"
                                    [FormGroupName]="DrugBucketFG"
                                    [BindLabel]="'Name'"
                                    [BindValue]="'Id'"
                                    [LabelForId]="'Id'"
                                    [List]="drugBucketList"
                                    (TriggerSelectValue)="checkIsBucketPriceExistsAndOpenPopUp(null)"
                                    [nextElement]="'AWPPack'">
                                    </app-rx-select>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--right__7">
                                <eprx-input [LabelText]="'Pack AWP Price'" [PlaceHolder]="'Pack AWP Price'"
                                    [ControlName]="'AWPPack'" [FormGroupName]="drugInfoFG?.get('Drug')"
                                    [DropSpclChar]="false" [DecimalValues]="2" [InputType]="'CURRENCY'"
                                    [MaskPattern]="'099999.00'" [RxSelectId]="'AWPPack'"
                                    (TriggerSearchValue)="convertToFixedDecimals('AWPPack')">
                                </eprx-input>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--left__7">
                                <eprx-input [LabelText]="'Pack Cost'" [InputType]="'CURRENCY'"
                                    [PlaceHolder]="'Pack Cost'" [ControlName]="'CostPack'"
                                    [FormGroupName]="drugInfoFG?.get('Drug')" [DropSpclChar]="false" [DecimalValues]="2"
                                    [InputType]="'NUMBER'" [MaskPattern]="'099999.00'" [RxSelectId]="'CostPack'"
                                    (TriggerSearchValue)="convertToFixedDecimals('CostPack')">
                                </eprx-input>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--right__7">
                                <eprx-input [LabelText]="'Unit AWP Price'" [InputType]="'CURRENCY'"
                                    [PlaceHolder]="'Unit AWP Price'" [ControlName]="'UnitPriceAWP3Digits'"
                                    [FormGroupName]="drugInfoFG?.get('Drug')" [DropSpclChar]="false" [DecimalValues]="3"
                                     [InputType]="'NUMBER'" [MaskPattern]="'09999.000'" [RxSelectId]="'UnitPriceAWP'"
                                    (TriggerSearchValue)="convertToFixedDecimals('UnitPriceAWP')"
                                    (TriggerOnChange)="saveUnitValues('awp')" [IsRequired]="true" [ErrorDefs]="{required: 'Required'}">
                                </eprx-input>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--left__7">
                                <eprx-input [LabelText]="'Unit Cost'" [InputType]="'CURRENCY'"
                                    [PlaceHolder]="'Unit Cost'" [ControlName]="'UnitPriceCost3Digits'"
                                    [FormGroupName]="drugInfoFG?.get('Drug')" [DropSpclChar]="false" [DecimalValues]="4"
                                     [MaskPattern]="'09999.0000'"    (TriggerSearchValue)="convertToFixedDecimals('UnitPriceCost')"
                                     (TriggerOnChange)="saveUnitValues('unitcost')" [IsRequired]="(_cmnSvc.getSetttingValue('RxSettings', 'WARN_BLANK_UNIT_COST') === '1') ? true : false" [ErrorDefs]="{required: 'Required'}"
                                    [RxSelectId]="'UnitPriceCost'" [AutoFocus]="focusOn === 'drugCost'"
                                    >
                                </eprx-input>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--right__7">
                                <eprx-input [LabelText]="'Unit Price Direct'" [InputType]="'CURRENCY'"
                                    [DropSpclChar]="false" [PlaceHolder]="'Unit Price Direct'"
                                    [ControlName]="'DirectUnitPrice'" [FormGroupName]="drugInfoFG?.get('Drug')"
                                    [MaxLength]="10" (TriggerOnChange)="setNumber('DirectUnitPrice', 3)"
                                    (TriggerSearchValue)="convertToFixedDecimals('DirectUnitPrice')"
                                    [MaskPattern]="'09999.000'" [DecimalValues]="3"
                                    [RxSelectId]="'DirectUnitPrice'">
                                </eprx-input>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--left__7">
                                <eprx-date-picker [LabelText]="'Last Update'" [PlaceHolder]="'Last Update'"
                                    [ControlName]="'LastUpdateDt'" [FormGroupName]="drugInfoFG?.get('Drug')"
                                    [MinDate]="" [MaxDate]="" [RxSelectId]="'LastUpdateDt'" [MarkAsTouched] = "drugInfoFG?.get('Drug.LastUpdateDt')?.touched">
                                </eprx-date-picker>
                            </div>
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="row">
                                            <div class="col-9">
                                                <label class="col-md-12 padding-0"> Exclude From Data Update </label>
                                                <eprx-radio [ControlName]="'IsExclFrmUpdate'" [FormGroupName]="drugInfoFG?.get('Drug')"
                                                    [List]="['Yes', 'No']" [ValueList]="[true, false]" [IDForList]="['IsExclFrmUpdate4', 'IsExclFrmUpdate5']"
                                                    [LabelForId]="">
                                                </eprx-radio>
                                            </div>
                                            <div class="col-1 margin_left_n3rem">
                                                <div class="font-weight-bolder color_white height_23px width_23px text-center font_size_1_2rem background_orange border_radius_50per" [ngbTooltip]="drugDataToolTip" container="body"
                                                     tooltipClass="search-tooltip"  triggers="mouseover:mouseleave" [autoClose]="true" placement="right">𝒊</div>
                                                <ng-template #drugDataToolTip>
                                                    <div class="text-left">
                                                        <b>Exclude From Data Update -</b>
                                                        <p>When the setting is set to <b>YES</b>, data is not updated regardless of the <b>Auto</b> setting in price update.</p>
                                                        <p>When the setting is set to <b>NO</b>, data related to drug received from <b>GSDD</b> is updated.</p>
                                                    </div>
                                                </ng-template>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="row">
                                            <div class="col-7">
                                                <label class="col-md-12 padding-0"> Price Update </label>
                                                <eprx-radio [ControlName]="'IsPriceUpdAuto'" [FormGroupName]="drugInfoFG?.get('Drug')"
                                                    [List]="['Auto', 'Manual']" [ValueList]="[true, false]" [IDForList]="['IsPriceUpdAuto6', 'IsPriceUpdAuto7']"
                                                    [LabelForId]="">
                                                </eprx-radio>
                                            </div>
                                            <div class="col-1 margin_left_n3rem">
                                                <div class="font-weight-bolder color_white width_23px height_23px width_23px text-center font_size_1_2rem background_orange border_radius_50per" [ngbTooltip]="drugPriceToolTip" container="body"
                                                     tooltipClass="search-tooltip"  triggers="mouseover:mouseleave" [autoClose]="true" placement="right">𝒊</div>
                                                <ng-template #drugPriceToolTip>
                                                    <div class="text-left">
                                                        <b>Price Update -</b>
                                                        <p>When set to <b>Auto</b>, this setting enables the <b>Automatic</b> update of the AWP price.</p>
                                                        <p>When set to <b>Manual</b>, this setting enables the <b>Manual</b> update of the AWP price.</p>
                                                    </div>
                                                </ng-template>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="col-md-6">
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 padding-sm-15 flex--one padding-0">
                <div class="box-default">
                    <div class="eprx--block__header">
                        <div class="eprx--header__heading">
                            Other Info / Default
                        </div>
                    </div>
                    <div class="eprx--block__content">
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--right__7">
                                <eprx-select [LabelText]="'Drug Class'" [PlaceHolder]="'Drug Class'"
                                    [ControlName]="'DrugClass'" [FormGroupName]="drugInfoFG?.get('Drug')"
                                    [HasMultiple]="false" [BindLabel]="'Name'" [BindValue]="'Name'"
                                    [LabelForId]="'Name'" [List]="[{Name:0},{Name:1},{Name:2},{Name:3},{Name:4},{Name:5}]" [RxSelectId]="'DrugClass'"
                                    [IsRequired]="true" [ErrorDefs]="{required: 'Required'}" [IsDisabled]="((rxBillStatusId === 2 || checkPayRec) || !isOriginalRx || (isOriginalRx && !(islatestrefill && isPartialRefStatus))) && (!frmAction) && (!enableDrugClassFromEDI)"
                                    [FormGroupInvalid]="formGroupInvalid" (TriggerSelectValue) = "setRefRemainderValue()">
                                </eprx-select>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--leftt__7 pl-2">
                                <eprx-input [LabelText]="'Default Sig Code'" [PlaceHolder]="'Default Sig Code'"
                                [ControlName]="'DefaultSigCode'" [FormGroupName]="drugInfoFG?.get('Drug')" [RxSelectId]="'DefaultSigCode'"
                                (TriggerOnBlur)="openSigCodeConfrim(sigCode)">
                                </eprx-input>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--right__7">
                                <eprx-input [LabelText]="'Default Quantity'" [PlaceHolder]="'Default Quantity'"
                                    [ControlName]="'DefaultQty'" [FormGroupName]="drugInfoFG?.get('Drug')"
                                    [DecimalValues]="3" [InputType]="'NUMBER'" [DropSpclChar]="false"
                                    [MaskPattern]="'099999.000'" [RxSelectId]="'DefaultQty'"
                                    (TriggerSearchValue)="convertToFixedDecimals('DefaultQty')">
                                </eprx-input>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--left__7">
                                <eprx-input [LabelText]="'Default Days'" [PlaceHolder]="'Default Days'"
                                    [ControlName]="'DefaultDays'" [FormGroupName]="drugInfoFG?.get('Drug')"
                                    [MaxLength]="3" [RxSelectId]="'DefaultDays'">
                                </eprx-input>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 padding--right__7">
                                <label class="col-md-12 padding-0"> Is Active? </label>
                                <eprx-radio [ControlName]="'IsActive'" [FormGroupName]="drugInfoFG?.get('Drug')"  [List]="['Yes', 'No']" [ValueList]="[true, false]"
                                    [IDForList]="['isActiveYes', 'isActiveNo']" [LabelForId]="">
                                </eprx-radio>
                                <!-- <eprx-select [LabelText]="'Status(A/I)'" [PlaceHolder]="'Status(A/I)'"
                                    [ControlName]="'IsActive'" [FormGroupName]="drugInfoFG?.get('Drug')"
                                    [HasMultiple]="false" [BindLabel]="'Name'" [BindValue]="'Id'" [LabelForId]="'Id'"
                                    [RxSelectId]="'IsActive'"
                                    [List]="[{'Id': true, 'Name': 'Active'}, {'Id': false, 'Name': 'InActive'}]">
                                </eprx-select> -->
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 padding--right__7">
                                <label class="col-md-12 padding-0"> Is Preferred? </label>
                                <!-- <fieldset
                                    [disabled]="drugInfo?.PreferredDrugsCount ? (drugInfo?.PreferredDrugsCount != 0 ? true : false) : false"> -->
                                    <eprx-radio [ControlName]="'IsPreferred'" [FormGroupName]="drugInfoFG?.get('Drug')"
                                        [List]="['Yes', 'No']" [ValueList]="[true, false]" [IDForList]="['IsPreferredt1', 'IsPreferredf2']"
                                        [LabelForId]="" (TriggerSelectValue)="checkPrefDrug()">
                                    </eprx-radio>
                                <!-- </fieldset> -->
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--left__7">
                                <eprx-date-picker [LabelText]="'Discontinue Date'" [PlaceHolder]="'Discontinue Date'"
                                    [ControlName]="'DiscontDt'" [FormGroupName]="drugInfoFG?.get('Drug')"
                                    [MinDate]="" [MaxDate]="" [RxSelectId]="'DiscontDt'" [MarkAsTouched] = "drugInfoFG?.get('Drug.DiscontDt')?.touched">
                                </eprx-date-picker>
                            </div>
                            <div class="col-md-6">
                                <label class="col-md-12 padding-0"> FDA Approved? </label>
                                <eprx-radio [ControlName]="'IsFDAApprovedNULL'"
                                    [FormGroupName]="drugInfoFG?.get('Drug')" [List]="['Yes', 'No', 'Unknown']"
                                    [ValueList]="[1, 0, 2]" [IDForList]="['IsFDAApprovedNULLy1', 'IsFDAApprovedNULLn2', 'IsFDAApprovedNULLu3']" [LabelForId]=""
                                    (keydown.tab)="focusToSave($event)">
                                </eprx-radio>
                            </div>
                            <div [ngClass]="(Is340BPharm$ | async) ? 'col-md-4 padding--left__7 pr-0' : 'col-md-6'">
                                <label class="col-md-12 padding-0" title="Include in Refill Reminder/Refill Request">Include in Ref Rem./Ref Req.</label>
                                <eprx-radio [ControlName]="'IsIncInRefilRem'" [FormGroupName]="drugInfoFG?.get('Drug')"
                                    [List]="['Yes', 'No']" [ValueList]="[true, false]" [IDForList]="['IsIncInRefilRemt1', 'IsIncInRefilRemf2']"
                                    [LabelForId]="">
                                </eprx-radio>
                            </div>
                            <div class="col-md-2 pl-1 pr-0" *ngIf="(Is340BPharm$ | async)">
                                <eprx-check-box [LabelText]="'Is 340B?'" [ControlName]="'Is340B'"
                                [FormGroupName]="drugInfoFG?.get('Drug')"></eprx-check-box>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--leftt__7">
                                <eprx-select [LabelText]="'Vaccine Group'" [PlaceHolder]="'Vaccine Group'"
                                    [ControlName]="'VaccineGroupId'" [FormGroupName]="drugInfoFG?.get('Drug')" [ShowLabel2]="true"
                                    [HasMultiple]="false" [BindLabel]="'VaccineGroupID'" [BindLabel2]="'VaccineGroupName'" [BindValue]="'VaccineGroupID'"
                                    [List]="dropDownValues?.VaccineGroups" [RxSelectId]="'vaccineGrp'" [secondCntrlN]="'VaccineGroupName'" [bindScnCntrlV]="'VaccineGroupName'"
                                    [IsTitle]="true">
                                </eprx-select>
                                <span
                                *ngIf="drugInfoFG?.value?.Drug?.VaccineGroupId">
                                <div class="col-4 d-inline compound--drug-cat left_8rem top_0rem" [ngbTooltip]="compNamdeTooltp" container="body"
                                    tooltipClass="search-tooltip-DrugVaccine" triggers="mouseover:mouseleave" [autoClose]="true"
                                    placement="top">𝒊</div>
                                <ng-template #compNamdeTooltp>
                                    <div class="text-left">
                                        <p>
                                            <b>Vaccine Group:</b>
                                            {{drugInfoFG?.value?.Drug?.VaccineGroupName}}
                                        </p>
                                    </div>
                                    <div *ngIf="InsuRestrictInfo?.length">
                                    <div class="text-left" *ngFor="let insPref of InsuRestrictInfo; let i = index">
                                        <div class="col-md-12">
                                            <div class="row">
                                                <i *ngIf="i !== (InsuRestrictInfo?.length)" class="fa fa-caret-right" style="padding-top:0.3rem"></i>&nbsp;
                                                <div>
                                                    <label>Ins : </label>
                                                    <span> {{insPref?.IsAllInsu ? 'ALL' : (insPref?.InsName ? (insPref?.InsName | uppercase) : '--')}} ; </span>&nbsp;
                                                </div>
                                                
                                                <div>
                                                    <label>DUR : </label>
                                                    <span>{{(insPref?.ServReasonName ? (insPref.ServReasonName) : "")+(insPref?.ProfServName ? (", "+ insPref.ProfServName) : "")+
                                                        (insPref?.ServResult ? (", "+ insPref.ServResult) : "")+(insPref?.EffortLvlName ? (", "+ insPref.EffortLvlName) : "")+(insPref?.CoAgentNum ? (", "+ insPref.CoAgentNum) : "")+
                                                    (insPref?.CoAgentIdType ? (", "+ insPref.CoAgentIdType) : "")+(insPref?.ClinicalSignfName ? (", "+ insPref.ClinicalSignfName) : "--")}} ; </span>
                                                    &nbsp;
                                                </div>
                                                <div>
                                                    <label>Sub Clarification : </label>
                                                    <span> {{insPref?.SubClarification ? insPref?.SubClarification : '--'}} ; </span>&nbsp;
                                                </div>
                                                <div>
                                                    <label>Incentive Amount : </label>
                                                    <span> {{insPref?.Incentive ? (insPref?.Incentive
                                                            | currency
                                                            : "$"
                                                            : "symbol"
                                                            : "1.2-2"): '$0.00'}} ; </span>&nbsp;
                                                </div>
                                                <div>
                                                    <label>Basis Of Cost : </label>
                                                    <span> {{insPref?.CostBasis ? insPref?.CostBasis : '--'}} ; </span>
                                                </div>  
                                            </div>
                                            <!-- <div class="row">
                                                <i *ngIf="i !== (InsuRestrictInfo?.length)" class="fa fa-caret-right" style="padding-top:0.3rem"></i>
                                                <div class="col-md-5">
                                                    <label>Ins :</label>
                                                    <span> {{insPref?.IsAllInsu ? 'ALL' : (insPref?.InsName | uppercase)}}</span>
                                                </div>
                                                <div class="col-md-6">
                                                    <label>DUR :</label>
                                                    <span> {{insPref?.ServReasonName+', '+insPref?.ProfServName+', '+insPref?.ServResult}}</span>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-5 margin_left_0_4rem">
                                                    <label>Sub Clarification :</label>
                                                     <span> {{insPref?.SubClarification ? insPref?.SubClarification : '--'}} </span>
                                                </div>
                                                <div class="col-md-6">
                                                    <label>Incentive Amount :</label>
                                                    <span> {{insPref?.Incentive ? (insPref?.Incentive
                                                        | currency
                                                        : "$"
                                                        : "symbol"
                                                        : "1.2-2"): '$0.00'}}</span>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12 margin_left_0_4rem">
                                                    <label>Basis Of Cost :</label>
                                                     <span> {{insPref?.CostBasis ? insPref?.CostBasis : '--'}} </span>
                                                </div>
                                            </div> -->
                                            <br>
                                            
                                            <!-- <span [innerHTML]="reasons"><span *ngIf="i === (rejectedReasons?.length - 2)"><br></span></span> -->
                                        </div>
                                    </div>
                                    </div>
                                    <div class="text-left" *ngIf="!InsuRestrictInfo?.length">
                                        <p>Define Insurance Preferences as needed.</p>
                                    </div>
                                </ng-template>
                            </span>
                            </div>
                            <div class="col-lg-5 col-md-5 col-sm-5 col-xs-5 padding--left__7 pr-0">
                                <eprx-input [LabelText]="'Diagnosis Code'" [PlaceHolder]="'Diagnosis Code'"
                                    [ControlName]="'DIAGCODE'" [FormGroupName]="drugInfoFG?.get('diagnosisInfo')"
                                    [ReadOnly]="true" [RxSelectId]="'DiagnosisId'">
                                </eprx-input>
                            </div>
                            <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 padding--left__7 padding_top_25px">
                                <i class="far fa-search fa-lg actions" (click)="openDiagList()"></i>
                            </div>
                            
                            <!-- <div class="col-md-6">
                                <label class="col-md-12 padding-0"> Is Triplicate? </label>
                                <eprx-radio [ControlName]="'IsTriplicate'"
                                    [FormGroupName]="drugInfoFG?.get('Drug')" [List]="['Yes', 'No']"
                                    [ValueList]="[true, false]" [IDForList]="['IsTriplicateYes', 'IsTriplicateNo']" [LabelForId]=""
                                    (keydown.tab)="focusToSave($event)">
                                </eprx-radio>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 exprx--common-block">
                <div class="box-default">
                    <div class="eprx--block__header">
                        <div class="eprx--header__heading">
                            Drug Description
                        </div>
                    </div>
                    <div class="eprx--block__content">
                        <div class="row">
                            <div class="col-md-4 col-sm-4 col-xs-12" *ngIf="drugId">
                                <div class="col-sm-12 padding-0">
                                    <img src="{{drugInfoFG.value['imgURL']}}" class="drug-img">
                                </div>
                            </div>
                            <div [ngClass]="!drugId ? 'col-md-12 col-sm-12 col-xs-12' : 'col-md-8 col-sm-8 col-xs-12'">
                                <div class="row">
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                        <label>Shape</label>
                                        <div class="label--data">
                                            {{drugInfo?.DrugDesc?.Shape ? drugInfo?.DrugDesc?.Shape : "--"}}</div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                                        <label>Color Front</label>
                                        <div class="label--data">
                                            {{drugInfo?.DrugDesc?.Color_Frnt? drugInfo?.DrugDesc?.Color_Frnt : "--"}}
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                                        <label>Color Back </label>
                                        <div class="label--data">
                                            {{drugInfo?.DrugDesc?.Color_Back? drugInfo?.DrugDesc?.Color_Back : "--"}}
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                                        <label>Side 1</label>
                                        <div class="label--data">
                                            {{drugInfo?.DrugDesc?.ID_FRONT? drugInfo?.DrugDesc?.ID_FRONT : "--"}}</div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                                        <label>Side 2</label>
                                        <div class="label--data">
                                            {{drugInfo?.DrugDesc?.ID_BACK?drugInfo?.DrugDesc?.ID_BACK : "--"}}</div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                                        <label>Flavours</label>
                                        <div class="label--data">
                                            {{drugInfo?.DrugDesc?.FlavorName? drugInfo?.DrugDesc?.FlavorName : "--"}}
                                        </div>
                                    </div>

                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                                        <label>Scoring</label>
                                        <div class="label--data">
                                            {{drugInfo?.DrugDesc?.ScoringName? drugInfo?.DrugDesc?.ScoringName : "--"}}
                                        </div>
                                    </div>

                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                                        <label>Coating</label>
                                        <div class="label--data">
                                            {{drugInfo?.DrugDesc?.CoatingName? drugInfo?.DrugDesc?.CoatingName : "--"}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #sigCode let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="d('Cross click'); OnSigCodePopupClose('DefaultSigCode')">
        <h4 header>Confirmation</h4>
        <span body>
            Entered Sig Code will be defaulted at Rx entry, would you like to keep this?
        </span>
        <button footer autofocus class="hotkey_primary" (click)="d('Cross click'); OnSigCodePopupClose('DefaultQty')" appShortcutKey InputKey="y">
           <b>Y</b> YES
        </button>
        <button footer class="hotkey_primary" (click)="d('Cross click'); OnSigCodePopupClose('DefaultSigCode')" appShortcutKey InputKey="n">
          <b>N</b>  NO
        </button>
    </app-custom-modal>
</ng-template>

<ng-template #drugclasschange let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="d('Cross click'); setRefRemainderValue()">
        <h4 header>Confirmation</h4>
        <span body>
           Drug class is changed ,sequence will be changed.
        </span>
        <button footer autofocus class="hotkey_primary" (click)="d('Cross click'); setRefRemainderValue()" appShortcutKey InputKey="o">
           <b>O</b> OK
        </button>
    </app-custom-modal>
</ng-template>

<ng-template #COPYNDC let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">Search</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body edit--drug">
        <div class="exprx--common-block">
            <div>
                <div class="seconday-search--grid">
                    <div class="row">
                        <div class="col-4 color_cyan">
                            Search Drug</div>
                        <div class="col-8">
                            <div class="text-right">
                                <!-- <div><i class="far fa-lg fa-long-arrow-alt-right color_orange"></i><span> for
                                        Equv ;
                                    </span><b class="color_orange">Shift + <i
                                            class="far fa-lg fa-long-arrow-alt-right color_orange"
                                            ></i></b><span> for Gen Equv</span></div> -->
                            </div>
                        </div>
                    </div>
                    <app-searchbox-global #drugSearch [IsFocused]="isDrugSearchFocused"
                        (FocusedOutofSearch)="isFocusedOutofSearch($event)" [SearchFor]="'Drug'" [IsAddNew]="false"
                        [DisableSerchFil]="true" [DisableRadioButtons]="true" (SelectedDrug)="copyNDC($event)" [SearchFrm]="'commonDrugSearch'" (CloseDrugSearch)="closeDrugsearchPopup($event)">
                    </app-searchbox-global>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="text-right pull-right col-md-10 padding-0">
            <button type="button" class="hotkey_success" (click)="d('Cross click')" appShortcutKey [AltKey]="'c'">
                <span>C</span> Cancel
            </button>
        </div>
    </div>
</ng-template>


<ng-template #AddDrugToBucketConfirm let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="d('Cross click');openAddDrugToBuckOrNot(false)">
        <h4 header>Add Drug To Inventory Bucket</h4>
        <span body *ngIf="compDrugList && !compDrugList.length">
            <b>'{{this.drugInfoFG?.value?.Drug?.Name | uppercase}}'</b> is not available for selected bucket <b>'{{this.DrugBucketFG?.value.Name | uppercase}}'</b>.
        <br/>Would you like to add it to the bucket?

        </span>
        <span body *ngIf="compDrugList && compDrugList.length">
            <b>{{this.compDrugList.join(', ') | uppercase}}</b> is not available for selected bucket <b>'{{this.DrugBucketFG?.value.Name | uppercase}}'</b>.
        <br/>Would you like to add it to the bucket?
        </span>
        <button footer autofocus class="hotkey_primary" (click)="d('Cross click');openAddDrugToBuckOrNot(true)" appShortcutKey InputKey="y">
           <b>Y</b> Yes
        </button>
        <button footer autofocus class="hotkey_primary" (click)="d('Cross click');openAddDrugToBuckOrNot(false)" appShortcutKey InputKey="n">
            <b>N</b> NO
         </button>
    </app-custom-modal>
</ng-template>
<ng-template #MarkAsPrefDrug let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="cancelMarkPref();c('Close click')">
        <h4 header>Confirmation</h4>
        <span body>
            <span *ngIf="DrugList && DrugList?.length">
                The following NDC(s)
                <b>{{DrugList.join(", ") }}</b>
                is(are) already marked as preferred. Each group can only have one preferred NDC. Would you like to remove this NDC(s) and mark
                <b>{{selectedDrugItem}}</b> as preferred drug?
            </span>
        </span>
        <span footer>
            <button class="hotkey_primary" ngbAutofocus appShortcutKey InputKey="y"
            (click)="continueMarkPref()"><b>Y</b>YES</button>
            <button class="hotkey_primary" appShortcutKey InputKey="n"
            (click)="cancelMarkPref(); c('Close click')"><b>N</b>NO</button>
        </span>
    </app-custom-modal>
</ng-template>
<ng-template #diagList let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">Diagnosis List</h4>
    </div>
    <div class="modal-body">
        <app-diag-list [FmChangeReq]="true" [isPopup]="true" (emitOnDbClick)="saveandCloseDiagList($event)">
        </app-diag-list>
    </div>
    <div class="modal-footer">
        <div class="text-right pull-right col-md-10 padding-0">
            <div>
                <button class="hotkey_success" (click)="d('Cross click')" appShortcutKey [AltKey]="'c'">
                    <span>C</span> Cancel
                </button>
            </div>
        </div>
    </div>
</ng-template>
