import {
    Component,
    Input,
    Output,
    EventEmitter,
    ElementRef,
    ViewChild,
    ChangeDetectorRef,
    AfterViewInit,
    OnDestroy,
    OnChanges,
    OnInit,
    HostListener
} from "@angular/core";
import { FormBuilder, FormGroup, FormControl, Validators } from "@angular/forms";
import {
    SystemData,
    Insurances,
    Insurance,
    InsuranceData,
    SearchInsurance,
    PatientInfo,
    AccCategoryE,
    InsuranceFileE
} from "../../../../models";
import { NgbTypeahead, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Observable ,  Subject } from "rxjs";

import {
    catchError,
    debounceTime,
    distinctUntilChanged,
    map,
    tap,
    switchMap,
    takeUntil
} from "rxjs/operators";
import * as _ from "lodash";
import { PatientService, CommonService, PreviousRouteService, AdditionalInsuranceService, InsuranceService, AlertService } from "../../../../services";
import { RegEx } from "../../../../app.regex";
import { IfStmt } from "@angular/compiler";
import { Router, ActivatedRoute } from "@angular/router";
import { PPatientInfoComponent } from "../patient-info.component";
import * as moment from "moment";
import { PatientStore } from "src/app/store";
import { InsuranceRefComponent } from "src/app/modules/insurance-ref/insurance-ref.component";
import { CommonUtil, RxUtils } from "src/app/utils";
import { PrivMaskGuard } from "src/app/guards";

@Component({
    selector: "app-prime-insurance",
    templateUrl: "./prime-insurance.component.html"
})
export class PPrimeInsuranceComponent
    implements AfterViewInit, OnDestroy, OnChanges, OnInit {

    insuranceModel = null;
    today: any;
    minDate: { year: any; month: any; day: any };
    isPolcRequired = false;
    patientInsuResult: any = [];
    modalRefSm: any;
    filteredGroups: any;
    isGroupRequired: boolean;
    isFrmErx: any;
    regex = RegEx;
    regularMask: any = "0000.00";
    percentageMask: any = "00.00";
    routeFrom: string;
    insuPlanId: number;
    inputValue = "";
    oldInsuData: any;
    @Input() FrmFamily: boolean;
    @Input() patientID: number;
    @Input() patientInfo: PatientInfo;
    @Input() patientInfoFG: FormGroup;
    @Input() systemData: SystemData;
    @Input() insuranceData: any;
    @Input() patientInsurances: any[];
    @Input() PatInsuId: number;
    @Input() Patch340BIns = false;
    @Input() focusId: any;
    @Input() formGroupInvalid: boolean;
    patGroups$: Observable<any>;
    insuName: any;
    showErrorMsg: boolean;
    isOnChanges = false;
    InsuranceEmpty: boolean = false;
    oldInsuCarId: any;
    alreadydatelocal = false;
    accessPrivCate: typeof AccCategoryE;
    insurancePrivs: typeof InsuranceFileE;
    hasAddInsuFilePrivs: any;
    openOverride: boolean;
    overrideFor: string;
    categoryId: string;
    privType: any;
    savedValue: any;
    overrideEndpoint: any;
    unsubscribe$: Subject<void> = new Subject();
    @Input()
    set InsuranceName(data) {
        this.inputValue = data;
    }

    @Output() AddtnlInsurncTab = new EventEmitter<null>();

    @ViewChild("searchBoxInsType", { static: true }) searchBoxInsType: ElementRef;
    @ViewChild("instance") instance: NgbTypeahead;
    @ViewChild("UNIQUEINSURNUMBER", { static: true }) UNIQUEINSURNUMBER: any;

    focus$ = new Subject<string>();
    click$ = new Subject<string>();

    get Insurances(): FormGroup {
        return this.patientInfoFG.get("Insurances") as FormGroup;
    }

    get InsuGroups(): FormGroup {
        return this.patientInfoFG.get("InsuGroups") as FormGroup;
    }

    get InsuCarriers(): FormGroup {
        return this.patientInfoFG.get("InsuCarriers") as FormGroup;
    }

    get InsuCards(): FormGroup {
        return this.patientInfoFG.get("InsuCards") as FormGroup;
    }

    constructor(
        private _cdr: ChangeDetectorRef,
        private _commonServ: CommonService,
        private _additionalInsu: AdditionalInsuranceService,
        private _commonSvc: CommonService,
        private _modalService: NgbModal,
        private _commonUtil: CommonUtil,
        private _patientStore: PatientStore,
        private _rxUtils: RxUtils,
        private _insuSvc: InsuranceService,
        private _alertService: AlertService,
        private _privMaskGuard: PrivMaskGuard
    ) {
        this.today = moment().format("MM/DD/YYYY");
        this.minDate = {
            year: moment(this.today).year(),
            month: moment(this.today).month() + 1,
            day: moment(this.today).date()
        };
        this.accessPrivCate = AccCategoryE;
        this.insurancePrivs = InsuranceFileE;
    }

    ngOnInit() {
        if (this.accessPrivCate) {
            this.hasAddInsuFilePrivs = this.checkHasPrivsToAccess(this.accessPrivCate.InsuranceFile, this.insurancePrivs.Add);
        }
        this.patGroups$ = this._patientStore.patGroups$;
        if (this.patientInfoFG.value["Insurances"]["InsurerId"] && !this.isOnChanges) {
            this.patchInsuranceValue(this.patientInfoFG.value["Insurances"]["InsurerId"]);
            this.isOnChanges = true;
        } else {
            if (!this.isOnChanges) {
            this.isOnChanges = true;
            const defInsu = this._commonSvc.getSetttingValue("PatientSettings", "PAT_TYPE");
            let insuFmStng = null;
            if (defInsu && this.insuranceData) {
                insuFmStng = this.insuranceData.find(ins => ins.InsurerCode.toLowerCase() === (defInsu+'').toLowerCase());
            }
            this.patchInsuranceValue(insuFmStng ? insuFmStng.InsuCarrierId
                : this.systemData["DefaultValuese"][0]["CashInsurerId"]);
                if (insuFmStng && insuFmStng.Id) {
                    this.getInsuranceSettings(insuFmStng.Id);
                }
            }
        }
    }

    checkHasPrivsToAccess(category: number, privType: number) {
        return this._privMaskGuard.canActivate(category, privType);
    }

    async getInsuranceSettings(Id) {
        if (Id) {
            const insuStng = await this._insuSvc.getInsSettingsInfo(Id).toPromise();
            if (insuStng && insuStng["InsuPlanPrice"]) {
                this.patientInfoFG.controls["Insurances"].patchValue(
                    {IsPatAssigned: insuStng["InsuPlanPrice"]["IsAssigntAccepted"]});
            } else {
                this.patientInfoFG.controls["Insurances"].patchValue(
                    {IsPatAssigned: true});
            }
        }
    }

    OverrideRes(val) {
        this.openOverride = false;
        if (val) {
            if (this.overrideFor === "Add") {
                this.routeToAddInsurance();
            }
        }
    }

    openOveride(content, value, type,endpoint?) {
        this.categoryId = "InsuranceFile";
        this.privType = type;
        this.openOverride = true;
        this.savedValue = value;
        this.overrideFor = type;
        this.overrideEndpoint = endpoint;
    }

    routeToAddInsurance() {
        const modalRefInsu = this._modalService.open(InsuranceRefComponent, {
            backdrop: false,
            size: "lg",
            windowClass: "md-x-lg erx-to-add-modal",
            keyboard: false
        });
        modalRefInsu.componentInstance.CloseInsuModal.pipe(takeUntil(this.unsubscribe$)).subscribe(async res => {
            if (res && res["Type"] !== "Cancel") {
                const search = new SearchInsurance();
                const data = await this._commonServ.getInsuData(search).toPromise();
                this._commonServ._insuranceData$.next(data["Records"]);
                setTimeout(() => {
                    this.InsuGroups.controls["Name"].patchValue(null);
                    this.patientInfoFG.controls["Insurances"].patchValue({
                        InsuGrpId: 0
                    });
                    if (document.getElementById("insutype")) {
                        document.getElementById("insutype").focus();
                    }
                    const insurance = this.insuranceData ? this.insuranceData.find(d => d["Id"] === +res["insuId"]) : null;
                    this.selectedInsType(insurance, "InsuAdd");
                   // this.patchInsuranceValue(res["insuId"], "InsuAdd");
                   // this.getInsuranceSettings(res["insuId"]);
                }, 1000);
            }
            modalRefInsu.close();
        });
    }

    async patchInsuranceValue(Id, type?: any) {
        const insurance = this.insuranceData ? this.insuranceData.find(d => d[type && type === "InsuAdd" ? "Id" : "InsuCarrierId"] === +Id) : null;
        if (insurance) {
            this.inputValue = insurance.PlanCode;
            this.insuName = insurance.InsuranceName ? insurance.InsuranceName : "";
            this.patientInfoFG.controls["Insurances"].patchValue({
                InsurerId: insurance.InsuCarrierId
            });
            this.oldInsuData = {
                oldInsurerID: insurance.InsuCarrierId,
                oldInputVal: this.inputValue,
                oldInsuName: this.insuName
            };
            this.patientInfoFG.controls["InsuCarrier"].patchValue({
                InsurerCode: insurance.InsurerCode,
                insutypeName: this.inputValue,
                insuranceIdName: this.insuName
            });

            if (type === "InsuAdd" || type === "InsuSelect") {
                const insuCardsFG: any = this.patientInfoFG.controls["InsuCards"];
                insuCardsFG.controls["PolicyNum"].setValue(null);
                insuCardsFG.controls["ExpiryDt"].setValue("12/31/2099");
            }
            this.checkIsPolicyRequired(insurance);
            if (type !== "group") {
                await this.filterGroupsBasedOnInsurerId(insurance.InsuCarrierId);
            }
            const date = this.patientInfoFG.controls["InsuCards"].value["ExpiryDt"];
            if (!this.alreadydatelocal && date && date.length > 16 &&  moment(date).isValid()) {
                this.alreadydatelocal = true;
            const expiredatelocal = moment.utc(this.patientInfoFG.controls["InsuCards"].value["ExpiryDt"]).local().format("MM/DD/YYYY");
            this.patientInfoFG.controls["InsuCards"].patchValue({ExpiryDt: expiredatelocal});
            }
        }
    }

    checkIsPolicyRequired(val) {
        const insuCardsFG: any = this.patientInfoFG.controls["InsuCards"];
        if (insuCardsFG.value["PolicyNum"] === "" || insuCardsFG.value["PolicyNum"] === " ") {
            insuCardsFG.controls["PolicyNum"].setValue(null);
        }
        if (val.SubmissionType === "9" || !val.SubmissionType) {
            this.isPolcRequired = true;
            insuCardsFG.controls["submissionType"].patchValue(val.SubmissionType);
            insuCardsFG.controls["PolicyNum"].setValidators([Validators.required]);
            insuCardsFG.controls["PolicyNum"].markAsUntouched();
        } else {
            this.isPolcRequired = false;
            insuCardsFG.controls["submissionType"].patchValue(val.SubmissionType);
            insuCardsFG.controls["PolicyNum"].setValidators([Validators.nullValidator]);
            insuCardsFG.controls["PolicyNum"].setErrors(null);
            setTimeout(() => {
                insuCardsFG.controls["PolicyNum"].markAsUntouched();
            }, 200);
            insuCardsFG.controls["ExpiryDt"].setErrors(null);
            insuCardsFG.controls["ExpiryDt"].setValidators([Validators.nullValidator]);
        }
    }

    async getGroupValues(insuCarrierId) {
        this.oldInsuCarId = insuCarrierId;
        this.filteredGroups = await this._additionalInsu.getInsuGroups(insuCarrierId).toPromise();
    }

    async filterGroupsBasedOnInsurerId(insuCarrierId) {
        // await this.getGroupValues(insuCarrierId);
        if (this.oldInsuCarId !== insuCarrierId) {
            await this.getGroupValues(insuCarrierId);
        }
        this.patientInfoFG.controls["Insurances"].patchValue({InsuGrpId: 0});
        this.patientInfoFG.controls["InsuGroups"].patchValue({Id: null});
        if (this.patientInfoFG.value["InsuGroups"]["Name"] && !this.patientInfoFG.value["Insurances"]["InsuGrpId"]) {
            this.filteredGroups = this.filteredGroups ? this.filteredGroups : [];
            if (this.filteredGroups && this.filteredGroups.length > 0 &&
                this.filteredGroups.find(val => val["Name"] === this.patientInfoFG.value["InsuGroups"]["Name"])) {
                const matchedGroup = this.filteredGroups.find(val => val["Name"] === this.patientInfoFG.value["InsuGroups"]["Name"]);
                this.patientInfoFG.controls["Insurances"].patchValue({InsuGrpId: matchedGroup["Id"]});
                this.patientInfoFG.controls["InsuGroups"].patchValue({Id: matchedGroup["Id"]});
            } else {
                this.filteredGroups.push({
                    Id: 0, InsurerId: this.Insurances.value["InsurerId"],
                    Name: this.patientInfoFG.value["InsuGroups"]["Name"], IsActive: true
                });
            }
        }
        this._patientStore.storePatGroups(this.filteredGroups);
    }

    ngOnChanges(changes?: any) {
        if (changes) {
            if (changes.PatInsuId && changes.PatInsuId.currentValue) {
                this.isOnChanges = true;
                this.patchInsuranceValue(this.PatInsuId);
            }
            if (changes.Patch340BIns && changes.Patch340BIns.currentValue) {
                const insId = +this._commonServ.getSetttingValue("ThreeFourtyBSettings", "Ins_Code_340BSettings");
                const Insu340B = insId && this.insuranceData && this.insuranceData.length ?
                this.insuranceData.find(ins => ins.InsuCarrierId === insId) : null;
                if (Insu340B && Insu340B.Id) {
                    this.selectedInsType(Insu340B, "select");
                }
            }
        }
    }



    search = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            map(term => (this.filterByPcnBin(term)))
        )

    filterByPcnBin(term) {
        if (term && term.length) {
            const searchTerms = term.split(",");
            const insuranceList = Object.assign([], this.insuranceData);
            const filteredData = this._rxUtils.filterInsurancesForSuggest(insuranceList, term);
            this.showErrorMsg = filteredData.length ? false : true;
            this.patientInfoFG.controls["InsuCarrier"].patchValue({
                insutypeName : null,
                insuranceIdName: null
            });
            this.inputValue = "";
            this.insuName = "";
            return filteredData.splice(0, 20);
        } else {
            this.showErrorMsg = false;
            return [];
        }

    }

    async selectedInsType(val, type?: any) {
        let patInsuSelected = null;
        let isInsuActv = null;
        if (val) {
            const insuranceList = this.patientInsurances?.find((item: any) => {
                return item.InsurerId === val.InsuCarrierId;
            });
            if (type) {
                isInsuActv = await this._additionalInsu.isInsuranceActiveFrmInsFilePatient(val.InsuCarrierId).toPromise();
                if (!isInsuActv || (insuranceList && insuranceList.IsActive !== 1)) {
                    this._alertService.error("The selected Insurance is inactive, hence cannot be added.", false, "long");
                } else {
                    this.InsuGroups.controls["Name"].patchValue(null);
                    this.patientInfoFG.controls["Insurances"].patchValue({
                        InsuGrpId: 0
                    });
                    this.patientInfoFG.controls["Insurances"].patchValue({
                        Id: 0
                    });
                }
            }
            this.inputValue = "";
            this.insuName = "";
            this.patientInfoFG.controls["Insurances"].patchValue({InsurerId: null, InsuCardId : null});
                // this.patientInfoFG.controls["Insurances"].patchValue({
                // InsurerId: this.oldInsuData.oldInsurerID});
            this.patientInfoFG.controls["InsuCards"].patchValue({
                PolicyNum: "" , Id: null});
            if (isInsuActv && (!insuranceList || (insuranceList && insuranceList.IsActive === 1))) {
            await this.patchInsuranceValue(val["InsuCarrierId"], "InsuSelect");
            }
            if (this.patientID) {
                if (this.patientInsurances) {
                     // this.patientInfoFG.value.Insurances.InsurerId !== val.insuCarrierId) removed after insu.InsurerId === val.InsuCarrierId as it doesn't satisfy
                    patInsuSelected = this.patientInsurances.find(insu => (insu.InsurerId === val.InsuCarrierId));
                }
            }

            if (patInsuSelected && (isInsuActv && (insuranceList && insuranceList.IsActive === 1))) {
                this.patientInfoFG.controls["Insurances"].patchValue({
                    Id: patInsuSelected.InsuranceId,
                    InsuCardId: patInsuSelected.InsucardId,
                    InsuGrpId: patInsuSelected.InsGroupId,
                    RelCatId: patInsuSelected.RelcatId,
                    IdProofId: patInsuSelected.IdProofId,
                    PerNum: patInsuSelected.PerNum,
                    IsPatAssigned: patInsuSelected.IsPatAssigned
                });
                this.patientInfoFG.controls["InsuGroups"].patchValue({
                    Name: patInsuSelected.GroupName,
                    Id: patInsuSelected.InsGroupId
                });
                this.patientInfoFG.controls["InsuCards"].patchValue({
                    Id: patInsuSelected.InsucardId,
                    PolicyNum: patInsuSelected.PolicyNum,
                    FirstName: patInsuSelected.FirstName,
                    LastName: patInsuSelected.LastName,
                    ExpiryDt: (patInsuSelected && patInsuSelected.CardExpiry) ?
                    moment(patInsuSelected.CardExpiry).format("MM/DD/YYYY") : "",
                });
                this.patientInfoFG.controls["InsuCarrier"].patchValue({
                    InsurerCode:  patInsuSelected.InsuType
                });
            } else if (val.InsuCarrierId && this.patientInfo && this.patientInfo.Insurances && this.patientInfo.Insurances.InsurerId
                && (val.InsuCarrierId === this.patientInfo.Insurances.InsurerId)) {
                this.patientInfoFG.controls["Insurances"].patchValue({
                    Id: this.patientInfo.Insurances.Id,
                    InsuCardId: this.patientInfo.Insurances.InsuCardId,
                    InsuGrpId: this.patientInfo.Insurances.InsuGrpId,
                    RelCatId: this.patientInfo.Insurances.RelCatId,
                    IdProofId: this.patientInfo.Insurances.IdProofId,
                    PerNum: this.patientInfo.Insurances.PerNum,
                    IsPatAssigned: this.patientInfo.Insurances.IsPatAssigned
                });
                this.patientInfoFG.controls["InsuGroups"].patchValue({
                    Name: this.patientInfo.InsuGroups ? this.patientInfo.InsuGroups.Name : null,
                    Id: this.patientInfo.Insurances.InsuGrpId
                });
                this.patientInfoFG.controls["InsuCards"].patchValue({
                    Id: this.patientInfo.InsuCards.Id,
                    PolicyNum: this.patientInfo.InsuCards.PolicyNum,
                    FirstName: this.patientInfo.InsuCards.FirstName,
                    LastName: this.patientInfo.InsuCards.LastName,
                    ExpiryDt: moment(this.patientInfo.InsuCards.ExpiryDt).format("MM/DD/YYYY"),
                });
                this.patientInfoFG.controls["InsuCarrier"].patchValue({
                    InsurerCode:  this.patientInfo.InsuCarrier.InsurerCode
                });
            } else {
                if (val.Id) {
                    const insuStng = await this._insuSvc.getInsSettingsInfo(val.Id).toPromise();
                    if (insuStng && insuStng["InsuPlanPrice"]) {
                        this.patientInfoFG.controls["Insurances"].patchValue({
                            Id: null,
                            IsPatAssigned: insuStng["InsuPlanPrice"]["IsAssigntAccepted"],
                            PerNum: null
                        });
                    } else {
                        this.patientInfoFG.controls["Insurances"].patchValue({
                            Id: null,
                            IsPatAssigned: true,
                            PerNum: null
                        });
                    }
                } else {
                    this.patientInfoFG.controls["Insurances"].patchValue({
                        Id: null,
                        PerNum: null
                    });
                }
            }
        }
    }

    isTypedValue(Value) {
        if (Value && Value["value"] && Value["value"]["Name"] !== "") {
            const insuGrp: any = this.patientInfoFG.controls["InsuGroups"];
            insuGrp.controls["Name"].patchValue(Value.value["Name"]);
            insuGrp.controls["InsurerId"].patchValue(this.Insurances.value["InsurerId"]);
            insuGrp.controls["IsActive"].patchValue(true);
            this.patientInfoFG.controls["Insurances"].patchValue({
                InsuGrpId: Value["value"]["Id"] ? Value["value"]["Id"] : 0
            });
            insuGrp.controls["CreatedDtTm"].patchValue(Value["value"]["CreatedDtTm"] ?
             Value["value"]["CreatedDtTm"] : this.today);
            insuGrp.controls["Id"].patchValue(Value["value"]["Id"] ? Value["value"]["Id"] : 0);
            if (this.filteredGroups) {
                this.filteredGroups.splice(this.filteredGroups.findIndex(val => val["Id"] === 0), 1);
            }
            if (!Value["value"]["Id"]) {
                this.filteredGroups = this.filteredGroups ? this.filteredGroups : [];
                const data = {
                    Id: null, InsurerId: this.Insurances.value["InsurerId"],
                    Name: Value.value["Name"], IsActive: true
                };
                this.filteredGroups.splice(1, 0, data);
            }
            this._patientStore.storePatGroups(this.filteredGroups);
        } else if (!Value) {
            const insuGrp: any = this.patientInfoFG.controls["InsuGroups"];
            insuGrp.controls["Name"].patchValue(null);
            insuGrp.controls["InsurerId"].patchValue(null);
            insuGrp.controls["IsActive"].patchValue(false);
        }
    }

    async focusOutOnAlt(event) {
        if (event.altKey) {
            if (event.which === 83) {
                event.preventDefault();
                await this.selectGroupOnBlur(event["target"]);
            }
        }
    }

    selectGroupOnBlur(target) {
        if (target && target.value && target.value.trim().length && !this.Insurances.value["InsuGrpId"]) {
            const insuGrp: any = this.patientInfoFG.controls["InsuGroups"];
            insuGrp.controls["Name"].patchValue(target.value);
            insuGrp.controls["InsurerId"].patchValue(this.Insurances.value["InsurerId"]);
            insuGrp.controls["IsActive"].patchValue(true);
            if (this.filteredGroups) {
                this.filteredGroups.splice(this.filteredGroups.findIndex(val => val["Id"] === 0), 1);
            }
            this.filteredGroups = this.filteredGroups ? this.filteredGroups : [];
            const data = {
                Id: null, InsurerId: this.Insurances.value["InsurerId"],
                Name: target.value, IsActive: true
            };
            this.filteredGroups.splice(1, 0, data);
            this._patientStore.storePatGroups(this.filteredGroups);
        }
    }

    formatter = (x: any) => {
        this.insuName = x.InsuranceName;
        this.inputValue = x.InsurerCode;
        return (x.PlanCode ? x.PlanCode : "");
    }

    checkForInsuranceDuplication(event?) {
        setTimeout(() => {
            const insuCardsFG: any = this.patientInfoFG.controls["InsuCards"];
            const newFG: any = this.patientInfoFG.controls["Insurances"];
            insuCardsFG.controls["Id"].setValue(newFG.value.InsuCardId);
            /*if (!(this.patientID && this.patientInfo.InsuCards.PolicyNum == insuCardsFG.value.PolicyNum &&
                this.patientInfo.Insurances.InsurerId == newFG.value.InsurerId)) {
                this._commonServ.checkIfPolicyNoExists(insuCardsFG.value.PolicyNum, newFG.value.InsurerId, insuCardsFG.value.Id)
                    .subscribe(resp => {
                        if (resp) {
                            if (!this.FrmFamily) {
                                this.modalRefSm = this._modalService.open(this.UNIQUEINSURNUMBER, {
                                    centered: true,
                                    backdrop: false,
                                    windowClass: "large--content"
                                });
                            }
                        } else {
                            insuCardsFG.controls["Id"].setValue(newFG.value.InsuCardId);
                        }
                    });
            }*/
        }, 10);
    }

    resetPolicyNumber() {
        if (this.modalRefSm)
            this.modalRefSm.close();
        const insuCardsFG: any = this.patientInfoFG.controls["InsuCards"];
        if (insuCardsFG && insuCardsFG.controls["PolicyNum"]) {
            insuCardsFG.controls["PolicyNum"].setValue(null);
            document.getElementById("primInsID").focus();
        }
    }

    mask() {
        if (this.InsuCards.get("CoPayCatId").value === 1) {
            return this.regularMask;
        }
        if (this.InsuCards.get("CoPayCatId").value === 2) {
            return this.percentageMask;
        }
    }

    ngAfterViewInit() {
        this._cdr.detectChanges();
        if(this.focusId && (this.focusId === "primInsCardExp" || this.focusId === "primInsID")) {
            const element: any = document.getElementById(this.focusId);
            if (element) {
                element.focus();
            }
        }
    }

    ngOnDestroy() {
        this._cdr.detach();
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    routeToAdditionalInsurance(insu) {
        this.AddtnlInsurncTab.emit(insu);
    }

    focusOutFromGroup() {
        if (document.getElementById("primInsRela")) {
            const data: any = document.getElementById("primInsRela").getElementsByTagName("input")[0];
            setTimeout(() => {
                data.focus();
            }, 100);
        }
    }

    focusToPatChart() {
        if (this.patientID) {
            if (document.getElementById("patOthChrtId")) {
                setTimeout(() => {
                    document.getElementById("patOthChrtId").focus();
                }, 10);
            }
        } else {
            if (document.getElementById("AddDiagButton2")) {
                setTimeout(() => {
                    document.getElementById("AddDiagButton2").focus();
                }, 10);
            }
        }
    }

    focusbyId(Id, event, isSelect?: any) {
        setTimeout(() => {
            this._commonUtil.focusById(Id, event, isSelect);
        }, 200);
    }

    patchRelationName() {
        this.systemData.RelationCat.find((item: any) => {
           if (item.Id === this.patientInfoFG.value["Insurances"]["RelCatId"]) {
            this.patientInfoFG.controls["Insurances"].patchValue({RelationName: item.Remarks});
           }
        });
    }
    onChangeInsurance(value){
        if(!value){
            this.InsuranceEmpty = true;
            this.patientInfoFG.controls["InsuCarrier"].patchValue({
                insutypeName : null,
                insuranceIdName: null
            })
            this.insuName = "";
        } else {
            this.InsuranceEmpty = false;
        }

    }
}
