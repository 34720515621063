<app-verification-checks *ngIf="isVerified" [rxFG]="rxFG" [flow]="'ExcludeDPVANDRPH'" (IsPopUpClosed)="confirmDEASubmission()"></app-verification-checks>
<ng-template #FILECONFIRMATION let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModal()">
        <h4 header>Defer Confirmation </h4>
        <span body>
            <span> Please confirm if you want to Defer this Rx. </span>
        </span>
        <button footer class="hotkey_primary"
        ngbAutofocus (click)="confirmToFile()" (keydown.enter)="confirmToFile()" appShortcutKey InputKey="y"><b>Y</b> Yes</button>
        <button footer class="hotkey_primary"
        (click)="closeModal()" (keydown.enter)="closeModal()" appShortcutKey InputKey="n"><b>N</b> No</button>
    </app-custom-modal>
</ng-template>

<ng-template #ResubmitConfirm let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="c('Close click');cancelResubmission()">
        <h4 header> Confirmation </h4>
        <span body>
            <div class="row">
                <div class="col-12">
                    If this Rx has been already reported to DEA, After filing, you must Re-Submit as a VOID RX.
                </div>
                <div class="col-12">
                    Would you like to Add it to the Resubmission List?
                </div>
            </div>
        </span>
        <button footer class="hotkey_primary"  ngbAutofocus (click)="c('Close click');submitResubmission()" (keydown.enter)="c('Close click');submitResubmission()" appShortcutKey InputKey="y"><b>Y</b> YES</button>
        <button footer class="hotkey_primary" (click)="c('Close click');cancelResubmission()" (keydown.enter)="c('Close click');cancelResubmission()" appShortcutKey InputKey="n"><b>N</b> NO</button>
    </app-custom-modal>
</ng-template>

<ng-template #FILE let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModal()">
        <h4 header *ngIf="(rxFG?.value?.PrescReFill?.StatusId != 2
        && rxFG?.value?.Prescription?.RxStatusId != 2 && rxFG?.value?.RxBill?.StatusId != 6)"> Please Enter Defer Due Date </h4>
        <h4 header *ngIf="(rxFG?.value?.PrescReFill?.StatusId == 2
        || rxFG?.value?.Prescription?.RxStatusId == 2 || rxFG?.value?.RxBill?.StatusId == 6)"> Fill A Deferred Rx </h4>
        <span body class="overflow_unset">
            <div class="row margin_bottom_1rem" *ngIf="(rxFG?.value?.PrescReFill?.StatusId != 2
                            && rxFG?.value?.Prescription?.RxStatusId != 2 && rxFG?.value?.RxBill?.StatusId != 6 && similarFiledRx)">
                <div class="col-md-12">
                    Similar Rx Last Deferred On: <b>{{similarFiledRx?.FillDateTime | date:'MM/dd/yyyy'}}</b> Rx#:
                    <b>{{similarFiledRx?.RxNumber}}</b>
                </div>
            </div>
            <div class="row">
                <div class="col padding--right__7">
                    <eprx-date-picker [AutoFocus]="true" [LabelText]="'Defer Due Date'" [PlaceHolder]="''" [ControlName]="'dueDateTime'"
                        [FormGroupName]="fileFG" [MaxDate]="" [IsRequired]="true" [ErrorDefs]="{required: 'Required'}"
                        [InputErrors]="dueDateTime?.errors" [FormGroupInvalid]="formGrpInvld" [MarkAsTouched]="fileFG?.get('dueDateTime')?.touched"
                        *ngIf="(rxFG?.value?.PrescReFill?.StatusId != 2
                        && rxFG?.value?.Prescription?.RxStatusId != 2 && rxFG?.value?.RxBill?.StatusId != 6)">
                    </eprx-date-picker>
                    <eprx-date-picker [AutoFocus]="true" [LabelText]="'Fill date'" [PlaceHolder]="''" [ControlName]="'dueDateTime'"
                        [FormGroupName]="fileFG" [MaxDate]="" [IsRequired]="true"
                        [InputErrors]="fileFG?.get('dueDateTime')?.errors"
                        [MarkAsTouched]="fileFG?.get('dueDateTime')?.touched"
                        *ngIf="(rxFG?.value?.PrescReFill?.StatusId == 2
                        || rxFG?.value?.Prescription?.RxStatusId == 2 || rxFG?.value?.RxBill?.StatusId == 6)"
                        (TriggerOnEnterValue)="valdiateFillDate()"
                        (TriggerOnTabbing)="valdiateFillDate()"
                        (TriggerOnDateSelect)="valdiateFillDate()"
                        [ErrorDefs]="{
                            required: 'Required',
                            ngbDate: 'Required',
                            minDate: fillDateErrorMsg,
                            maxDate: fillDateErrorMsg,
                            inValid: 'Invalid Date'
                        }">
                    </eprx-date-picker>
                </div>
            </div>
            <div class="row"  *ngIf="(rxFG?.value?.PrescReFill?.StatusId != 2
            && rxFG?.value?.Prescription?.RxStatusId != 2 && rxFG?.value?.RxBill?.StatusId != 6)">
                <div class="col padding--right__7">
                    <eprx-select
                    [LabelText]="'Deferred Reason'"
                    [PlaceHolder]="'Deferred Reason'"
                    [Title]="'Deferred Reason'"
                    [HasMultiple]="false"
                    [HasBindLabel2]="false"
                    [BindLabel]="'Name'"
                    [BindValue]="'Id'"
                    [LabelForId]="'Name'"
                    [FormGroupName]="fileFG"
                    [ControlName]="'DeferredReason'"
                    [secondCntrlN]="'DeferredReasonName'"
                    [bindScnCntrlV]="'Name'"
                    [List]="deferredReasonList">
                    </eprx-select>
                </div>
            </div>
            <div *ngIf="(rxFG?.value?.PrescReFill?.StatusId == 2 || rxFG?.value?.Prescription?.RxStatusId == 2 ||
                rxFG?.value?.RxBill?.StatusId == 6)">
                Assign New
                <form class="form-group custom--input was-validated">
                    <span class="inline-flex">
                        <div class="custom-control custom-radio">
                            <input type="radio"
                            class="custom-control-input"
                            id="s1"
                            name="newOrOld"
                            [disabled]="!(rxFG?.value?.PrescReFill?.ReFillNum === 0 && rxFG?.value?.PrescReFill?.PartialFillNo === 1)"
                            (change)="createNewFiledRx(true)"
                            [(ngModel)]="isFiledNew"
                            [value]="true"
                            >
                            <label class="custom-control-label" for="s1">
                                    Yes
                            </label>
                        </div>
                        <div class="custom-control custom-radio">
                            <input type="radio"
                            class="custom-control-input"
                            id="n1"
                            name="newOrOld1"
                            (change)="createNewFiledRx(false)"
                            [value]="false"
                            [disabled]="fileToFillIsControlSub"
                            [(ngModel)]="isFiledNew"
                            >
                            <label class="custom-control-label" for="n1">
                                    No
                            </label>
                        </div>
                    </span>
                </form>
            </div>
        </span>
        <button footer class="inactive" ngbAutofocus *ngIf="!fileFG?.valid"><span>S</span> Save </button>
        <button footer class="hotkey_success" ngbAutofocus (keydown.enter)="saveFileToInfo()"
        (click)="saveFileToInfo()" *ngIf="fileFG?.valid" appShortcutKey [AltKey]="'s'">
            <span>S</span> Save </button>
        <button footer class="hotkey_success" (click)="closeModal()"
        (keydown.enter)="closeModal()" appShortcutKey [AltKey]="'c'"> <span>C</span> Cancel </button>
    </app-custom-modal>
</ng-template>

<ng-template #UnbilledRxError let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Warning</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="closeModalSM()">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div>Rx#: {{ rxFG.value?.Prescription?.PrescNum }}-{{ rxFG.value?.PrescReFill?.ReFillNum }}{{_commonSvc.checkIsPartialFill(rxInfo?.PrescReFill, rxInfo?.Prescription) ? '-' + rxInfo?.PrescReFill?.PartialFillNo : ""}}</div>
        <p>Billed Rx(s) or Rx(s) with paid pay records cannot be Deferred.</p>
    </div>
    <div class="modal-footer">
        <button
            type="button"
            class="hotkey_primary"
            appShortcutKey
            InputKey="o"
            (click)="closeModalSM()"
            (keydown.enter)="closeModalSM()"
        >
            <b>O</b> OK
        </button>
    </div>
</ng-template>

<ng-template #FILEDETAIL let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModal()">
        <h4 header> Defer Details </h4>
        <span body class="overflow_unset">
            <div class="row">
                <div class="col padding--right__7">
                    <eprx-date-picker [AutoFocus]="true" [LabelText]="'Filing Due Date'" [PlaceHolder]="''" [ControlName]="'DueDtTm'"
                        [FormGroupName]="rxFG.controls['RxFile']" [IsRequired]="true" [ErrorDefs]="{required: 'Required'}"
                        [InputErrors]="rxFG.controls['RxFile.DueDtTm']?.errors" [FormGroupInvalid]="formGrpInvld"
                        [MarkAsTouched]="rxFG?.get('DueDtTm')?.touched" 
                    >
                    </eprx-date-picker>
                </div>
            </div>
            <div class="row">
                <div class="col padding--right__7">
                    <eprx-select
                    [LabelText]="'Deferred Reason'"
                    [PlaceHolder]="'Deferred Reason'"
                    [Title]="'Deferred Reason'"
                    [HasMultiple]="false"
                    [HasBindLabel2]="false"
                    [BindLabel]="'Name'"
                    [BindValue]="'Id'"
                    [LabelForId]="'Name'"
                    [FormGroupName]="rxFG.controls['RxFile']"
                    [ControlName]="'DeferredReason'"
                    [secondCntrlN]="'DeferredReasonName'"
                    [bindScnCntrlV]="'Name'"
                    [List]="deferredReasonList">
                    </eprx-select>
                </div>
            </div>
        </span>
        <button footer  [ngClass]="!rxFG?.valid ? 'inactive' : 'hotkey_success'" (keydown.enter)="!rxFG?.valid ? '':updateFileDetails()"
        (click)="!rxFG?.valid ? '':updateFileDetails()"  appShortcutKey [AltKey]="'s'">
            <span>S</span> Save </button>
        <button footer class="hotkey_success" (click)="closeModal(true)"
        (keydown.enter)="closeModal(true)" appShortcutKey [AltKey]="'c'"> <span>C</span> Cancel </button>
    </app-custom-modal>
</ng-template>


<ng-template #warnMsg let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">Warning!!</h4>
    </div>
    <div class="modal-body">
        The Prescription is Discontinued. Change order status to Current to continue.
    </div>
    <div class="modal-footer">
        <div class="text-right pull-right col-md-10 padding-0">
            <div>
                <button type="submit" class="hotkey_primary" (click)="c('Cross click')"
                    appShortcutKey InputKey="o">
                    <b>O</b> Ok
                </button>
            </div>
        </div>
    </div>
</ng-template>

