import {​ RxTransferService }​ from "./../../../services/rx-transfer.service";
import {​
    PharmacyDatas,
    PharmacyData,
    XferInfoDet,
    XferInfoEdit
}​ from "./../../../models/rxTransfer.model";
import {​ FormBuilder, FormGroup }​ from "@angular/forms";
import {​ NgbModal }​ from "@ng-bootstrap/ng-bootstrap";
import {​
    Component,
    OnInit,
    ViewChild,
    Output,
    EventEmitter,
    Input,
    HostListener
}​ from "@angular/core";
import {​ City, RxTransfer, State }​ from "../../../models";
import {​ RxTransferInComponent }​ from "../../shared";
import {​ RxStore }​ from "src/app/store";
import {​ PrintService }​ from "src/app/services/print.service";
import * as moment from "moment";
import {​ CollectionView }​ from "@grapecity/wijmo";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FormatsUtil } from "src/app/utils/formats.util";
import { environment } from "src/environments/environment";
import { PharmacyLabels } from "src/app/models/labels.models";
import { MsgConfig } from "src/app/app.messages";
@Component({​
    selector: "app-tr-rx-in-only",
    templateUrl: "./tr-rx-in-only.component.html"
}​)
export class TrRxInOnlyComponent implements OnInit {​
    @Input() rxType: string;
    @Input() rxFG: FormGroup;
    @Input() prevSaved: any;
    @Input() IsFromVerification: string;
    modalRef: any;
    rxTransferInfo: RxTransfer = new RxTransfer();
    Phone: any;
    saveRXIN = false;
    refillDetails: any;
    PharmacyNames: any;
    selectedPharmacist: string;
    pharmcyDet: PharmacyData = new PharmacyData();
    pharmacyabels = new PharmacyLabels();
    msgConfig = MsgConfig;
    activeModal = "";
    transferType =  false;
    RXINPOPUP: boolean;
    formGroupInvalid = false;
    @Output()
    IsPopUpClosed = new EventEmitter<null>();
    @ViewChild(RxTransferInComponent)
    rxTransferInComponent: RxTransferInComponent;
    @ViewChild("RXINMODAL", {​ static: true }​)
    RXINMODAL: any;
    @ViewChild("NOTRX", {​ static: true }​)
    NOTRX: any;
    @ViewChild("INDETAILS", {​ static: true }​)
    INDETAILS: any;
    @ViewChild("XEREDIT", {​ static: true }​)
    XEREDIT: any;
    @ViewChild("RXDETAILMODAL", {​ static: true}​)
    RXDETAILMODAL: any;
    @ViewChild("TRANSFERINFO", {​ static: true}​)
    TRANSFERINFO: any;
    notTransfered: boolean;
    rxTransferFG: FormGroup;
    recvRph: any;
    Phone1: any;
    Phone2: any;
    Fax: any;
    public citySelected = null;
    public stateSelected = null;
    transferDWJ: CollectionView;
    activeHeaders: any[];
    bothTransferViewModal: any;
    rxTransferDetails: any;
    pharmacyList: any;
    refillNos: string;
    rxTransferType: string;
    unsubscribe$ : Subject<void> = new Subject();
    @HostListener("window:keyup", ["$event"])
    keyEvent(event: KeyboardEvent) {​
      if (event.which === 27) {​
          if (this.activeModal === "NOTRX" || this.activeModal === "INDETAILS" || this.RXINPOPUP) {​
              this.closeModal();
          }​
      }​
    }​
    constructor(
        private _modalService: NgbModal,
        private _rxTransferDet: RxTransferService,
        private _rxStore: RxStore, private _printser: PrintService, private _fb: FormBuilder, public _formatsUtil: FormatsUtil
    ) {​}​
    ngOnInit() {​
        this.rxTransferType = environment.IseRxTransferRequest ? "MarkTransferIn" : '';
        this.patchPatientInfo();
        this.patchPrescbrInfo();
        this.patchDrugInfo();
        this.patchRxInfo();
        if (this.rxType === "er" || this.IsFromVerification === 'vrf') {​
            this.alreadySavedRxIn();
        }​ else {​
            this.openRXIN();
        }​
    }​
    selectedRphValue(e) {​
        this.recvRph = e?.value?.PharmacistName;
    }​
    async getRxTransferPopups() {​
        const resp = await this._rxTransferDet.getRxTransferPopups(
                        this.Prescription.value["PrescNum"], false, this.PrescReFill.value["ReFillNum"]).toPromise();
        if (resp && (resp === 3 || resp === 4)) {​
            this.alreadySavedRxIn();
        }​ else {​
            this.openRXIN();
        }​
    }​
    patchPatientInfo() {​
        const patient = this.rxFG.get("Patient").value;
        if (patient) {​
            this.rxTransferInfo.PatientName = patient.fullname ? patient.fullname.replace("_", ", ") : null;
            this.rxTransferInfo.DateOfBirth = patient.dob ? patient.dob : null;
            this.rxTransferInfo.Gender = patient.gender ? patient.gender : null;
            this.rxTransferInfo.PatientAddress = patient.fulladdress ? patient.fulladdress: null;
        }​
    }​
    patchPrescbrInfo() {​
        const prescriber = this.rxFG.get("Prescriber").value;
        if (prescriber) {​
            this.rxTransferInfo.PrescriberName = prescriber.prescriberlastname
                ? prescriber.prescriberlastname : null + ", " + prescriber.prescriberfirstname
                ? prescriber.prescriberfirstname : null;
            this.rxTransferInfo.DEA = prescriber.prescriberdeanum
                ? prescriber.prescriberdeanum : null;
            this.rxTransferInfo.NPINum = prescriber.prescribernpinum
                ? prescriber.prescribernpinum : null;
            this.rxTransferInfo.Licencenumber = prescriber.prescriberlicensenumber
                ? prescriber.prescriberlicensenumber : null;
            this.Phone = prescriber.telephone ? prescriber.telephone : null;
            this.rxTransferInfo.Fax = null;
        }​
    }​
    patchDrugInfo() {​
        const drug = this.rxFG.get("Drug").value;
        if (drug) {​
            this.rxTransferInfo.DrugDetail = drug.drugname + " " + drug.strength + " " + drug.drugformname;
            this.rxTransferInfo.NDC = drug.drugcode ? drug.drugcode : null;
            this.rxTransferInfo.AWP = drug.awppack ? drug.awppack : null;
            this.rxTransferInfo.PacketSize = drug.qtypack ? drug.qtypack : null;
        }​
    }​
    patchRxInfo() {​
        const rxInfo = this.rxFG.getRawValue();
        this.rxTransferInfo.Aref = rxInfo.Prescription.ReFillNum;
        this.rxTransferInfo.Qty = rxInfo.PrescReFill.OrdQty;
        this.rxTransferInfo.Days = rxInfo.PrescReFill.OrderDays;
        this.rxTransferInfo.SigDescription = rxInfo.SigCodes.Description;
        this.rxTransferInfo.Refillno = rxInfo.PrescReFill.ReFillNum;
        this.rxTransferInfo.Refillsleft = rxInfo.Prescription.RxStatusId === 4 ? 0 :
        rxInfo.Prescription.ReFillNum - rxInfo.PrescReFill.ReFillNum;
        this.rxTransferInfo.OriginalRxDate = rxInfo.PrescReFill.OrderDtTm;
        this.rxTransferInfo.RxFillDate = rxInfo.PrescReFill.FillDtTm;
        this.rxTransferInfo.InsurerCode = rxInfo.RefillInsu.InsuId;
        this.rxTransferInfo.RxStatusId = rxInfo["RxBill"]["RxStatusId"];
        this.rxTransferInfo.DispQty = rxInfo.PrescReFill.DispQty;
        if (rxInfo.PrescReFill.StatusId === 4 || rxInfo.Prescription.RxStatusId === 4 ) {​
            this.transferType = true;
            this.notTransfered = false;
        }​ else if (rxInfo.PrescReFill.StatusId === 3 || rxInfo.Prescription.RxStatusId === 3) {​
            this.transferType = false;
            this.notTransfered = false;
        }​ else  {​
            this.notTransfered = true;
        }​
    }​
    async alreadySavedRxIn() {​
        if (this.notTransfered) {​
            this.modalRef = this._modalService.open(this.NOTRX, {​
                centered: true, keyboard: false, backdrop: false, windowClass: "large--content"
            }​);
            this.activeModal = "NOTRX";
        }​ else {​
            await this.getPharmacyList();
            this.getTranferDet();
        }​
    }​
    async getTranferDet() {​
        const resp = await this._rxTransferDet.getTransferInDetails(
            this.Prescription.value["Id"], this.PrescReFill.value["ReFillNum"], this.transferType).toPromise();
        if (resp) {​
            this.rxTransferDetails = resp;
            if (resp["rxTransferIn"] && resp["rxTransferOut"]) {​
                await this.generateTransferWJ(resp);
                this.bothTransferViewModal =  this._modalService.open(this.RXDETAILMODAL , {​backdrop: false, centered: true, size: "lg"}​);
            }​ else {​
                this.openDetailTransferDet(resp);
            }​
        }​
    }​
    async getPharmacyList() {​
        this.pharmacyList = await this._rxTransferDet.getPharmacyNames().toPromise();
    }​
    findPharmacyName(Id) {​
        let result = null;
        if (this.pharmacyList && this.pharmacyList["pharmNames"] && this.pharmacyList["pharmNames"]["pharmNamesViewModels"] &&
         this.pharmacyList["pharmNames"]["pharmNamesViewModels"].length) {​
            const data = this.pharmacyList["pharmNames"]["pharmNamesViewModels"].find(v => v["Id"] === Id);
            result = data ? data["PharmacyName"] : null;
        }​
        return result;
    }​
    generateTransferWJ(data) {​
        const result = [];
        const k = {​}​;
        k["Rx#"] = this.Prescription.value["PrescNum"];
        k["NRefills Transfer (In/Out)"] = data["rxTransferIn"]["RefillsReceived"];
        k["TransferDirection"] = "I";
        k["TRPHARMAC"] = this.findPharmacyName(data["rxTransferIn"]["XferPharmId"]);
        k["PHCISTFRO"] = data["rxTransferIn"]["PharmacistName"];
        k["PHCISTTO"] = data["rxTransferIn"]["OrgPharmacistNm"];
        k["ORGRXN"] = data["rxTransferIn"]["OrgRxNum"];
        k["ORGRXDATE"] = moment.utc(data["rxTransferIn"]["OrgRxDtTm"]).local().format("MM/DD/YYYY");
        k["LFILLDATE"] = moment.utc(data["rxTransferIn"]["OrgLastRxFillDtTm"]).local().format("MM/DD/YYYY");
        k["TRANSDATE"] = moment.utc(data["rxTransferIn"]["TransferDtTm"]).local().format("MM/DD/YYYY");
        k["COMMENTS"] = data["rxTransferIn"]["Remarks"];
        result.push(k);
        const j = {​}​;
        j["Rx#"] = this.Prescription.value["PrescNum"];
        j["NRefills Transfer (In/Out)"] = data["rxTransferOut"]["RefillsTransferred"];
        j["TransferDirection"] = "O";
        j["TRPHARMAC"] = this.findPharmacyName(data["rxTransferOut"]["XferPharmId"]);
        j["PHCISTFRO"] = data["rxTransferOut"]["PharmacistName"];
        j["PHCISTTO"] = data["rxTransferOut"]["OrgPharmacistNm"];
        j["ORGRXN"] = 0;
        j["ORGRXDATE"] = moment.utc(data["rxTransferOut"]["OrgRxDtTm"]).local().format("MM/DD/YYYY");
        j["LFILLDATE"] = moment.utc(data["rxTransferOut"]["OrgLastRxFillDtTm"]).local().format("MM/DD/YYYY");
        j["TRANSDATE"] = moment.utc(data["rxTransferOut"]["TransferDtTm"]).local().format("MM/DD/YYYY");
        j["COMMENTS"] = data["rxTransferOut"]["Remarks"];
        result.push(j);
        this.transferDWJ = new CollectionView(result);
        this.activeHeaders = [
            "Actions",
            "Rx#",
            "NRefills Transfer (In/Out)",
            "TransferDirection",
            "TRPHARMAC",
            "PHCISTFRO",
            "PHCISTTO",
            "ORGRXN",
            "ORGRXDATE",
            "LFILLDATE",
            "TRANSDATE",
            "COMMENTS"
        ];
    }​
    openEditView(Type) {​
        this.transferType = Type === "I" ? false : true;
        this.closeDetailModal();
        this.openDetailTransferDet(this.rxTransferDetails);
    }​
    closeDetailModal(type?: any) {​
        if (this.bothTransferViewModal) {​
            this.bothTransferViewModal.close();
        }​
        this.bothTransferViewModal = null;
        if (type) {​
            this.IsPopUpClosed.emit(null);
        }​
    }​
    async openDetailTransferDet(resp) {​
        this.createXerFG();
        this.patchTransferDetFG(resp);
        this.refillDetails = resp;
        if (this.pharmacyList && this.pharmacyList["pharmNames"]) {
            const resp2 = Object.assign({​}​, this.pharmacyList);
            if (resp2) {​
                this.PharmacyNames = resp2;
                this.PharmacyNames.pharmNames = this.arrangePharmDet(resp2["pharmNames"]);
            }​
        }
        await this.getPharmcyDetails();
       if(environment.IseRxTransferRequest){
        this.modalRef = this._modalService.open(
            this.TRANSFERINFO, {​ centered: true, size: "lg", windowClass: "markasTransferIn_ediRx", keyboard: false }​);
       } else {
             this.modalRef = this._modalService.open(
            this.XEREDIT, {​ centered: true, size: "lg", windowClass: "md-x-lg", keyboard: false }​);
       }
        this.activeModal = "INDETAILS";
    }​
    arrangePharmDet(data) {​
        let pharmaDetails = [];
        if (data && data["pharmNamesViewModels"] && data["pharmNamesViewModels"].length) {​
            pharmaDetails = data["pharmNamesViewModels"].map(v => {​
                const k = {​}​;
                k["Id"] = v["Id"];
                k["PharmacyName"] = v["PharmacyName"];
                let phoneDet = null;
                if (data["Phones"] && data["Phones"].length) {​
                    phoneDet = data["Phones"].filter(d => d["Id"] === v["Id"]);
                }​
                if (phoneDet && phoneDet.length) {​
                    const ph12 = phoneDet.filter(pd => pd["PhoneCatId"] === 3);
                    k["Phone1"] = ph12 && ph12.length ? ph12[0]["Phone"] : null;
                    k["Phone2"] = ph12 && ph12.length > 1 ? ph12[1]["Phone"] : null;
                    const fax = phoneDet.filter(pd => pd["PhoneCatId"] === 4);
                    k["Fax"] = fax && fax.length ? fax[0]["Phone"] : null;
                }​ else {​
                    k["Phone1"] = null;
                    k["Phone2"] = null;
                    k["Fax"] = null;
                }​
                return k;
            }​);
        }​
        return pharmaDetails;
    }​
    createXerFG() {​
        this.rxTransferFG = this._fb.group(new XferInfoDet());
    }​
    patchTransferDetFG(data) {​
        const rxInfo = this.rxFG.getRawValue();
        this.rxTransferFG.patchValue(this.transferType ? data["rxTransferOut"] : data["rxTransferIn"]);
        const trnsRxDtTm  = new Date(this.rxTransferFG.value["TransferDtTm"]);
        const trnsRxDtTmFrmt = `${trnsRxDtTm.getHours()} : ${trnsRxDtTm.getMinutes()} : ${trnsRxDtTm.getMilliseconds()}`;
        const ordRxDtTm = new Date(this.rxTransferFG.value["OrgRxDtTm"]);
        const ordRxDtTmFrmt = `${ordRxDtTm.getHours()} : ${ordRxDtTm.getMinutes()} : ${ordRxDtTm.getMilliseconds()}`;
        const orgLastDtTm = new Date(this.rxTransferFG.value["OrgLastRxFillDtTm"]);
        const ordLastDtTmFrmt = `${orgLastDtTm.getHours()} : ${orgLastDtTm.getMinutes()} : ${orgLastDtTm.getMilliseconds()}`;
        const ordDtTm = new Date(rxInfo.PrescReFill.OrderDtTm);
        const ordDtTmFrmt = `${ordDtTm.getHours()} : ${ordDtTm.getMinutes()} : ${ordDtTm.getMilliseconds()}`;
        const fillDtTm = new Date(rxInfo.PrescReFill.FillDtTm);
        const fillDtTmFrmt = `${fillDtTm.getHours()} : ${fillDtTm.getMinutes()} : ${fillDtTm.getMilliseconds()}`;
        this.rxTransferFG.patchValue({​
            prescnumid: this.rxFG.value["Prescription"]["PrescNum"],
            OrgRxDtTm: (this.rxTransferFG.value["OrgRxDtTm"] && this.rxTransferFG.value["OrgRxDtTm"] === "0001-01-01T00:00:00" ?
            (rxInfo.PrescReFill.OrderDtTm && ordDtTmFrmt !== "0 : 0 : 0" ? moment.utc(rxInfo.PrescReFill.OrderDtTm).local().format("MM/DD/YYYY") :  moment.utc(rxInfo.PrescReFill.OrderDtTm).format("MM/DD/YYYY")):
            (this.rxTransferFG.value["OrgRxDtTm"] && ordRxDtTmFrmt !== "0 : 0 : 0" ? moment.utc(this.rxTransferFG.value["OrgRxDtTm"]).local().format("MM/DD/YYYY") : moment.utc(this.rxTransferFG.value["OrgRxDtTm"]).format("MM/DD/YYYY"))),
            OrgLastRxFillDtTm: (this.rxTransferFG.value["OrgLastRxFillDtTm"] &&
            this.rxTransferFG.value["OrgLastRxFillDtTm"] === "0001-01-01T00:00:00" ?
            (rxInfo.PrescReFill.FillDtTm && fillDtTmFrmt !== "0 : 0 : 0" ?  moment.utc(rxInfo.PrescReFill.FillDtTm).local().format("MM/DD/YYYY") : moment.utc(rxInfo.PrescReFill.FillDtTm).format("MM/DD/YYYY")):
            (this.rxTransferFG.value["OrgLastRxFillDtTm"] && ordLastDtTmFrmt !== "0 : 0 : 0" ? moment.utc(this.rxTransferFG.value["OrgLastRxFillDtTm"]).local().format("MM/DD/YYYY") : moment.utc(this.rxTransferFG.value["OrgLastRxFillDtTm"]).format("MM/DD/YYYY"))),
            TransferDtTm: (this.rxTransferFG && this.rxTransferFG.value["TransferDtTm"] && trnsRxDtTmFrmt !== "0 : 0 : 0" ?
            moment.utc(this.rxTransferFG.value["TransferDtTm"]).local().format("MM/DD/YYYY") : moment.utc(this.rxTransferFG.value["TransferDtTm"]).format("MM/DD/YYYY")),
            OrgPharmacistNm: this.transferType ? this.rxTransferFG.value["OtherPharmacistNm"] :
            this.rxTransferFG.value["OrgPharmacistNm"],
            ReFillNum: this.PrescReFill.value["ReFillNum"]
        }​);
        if (!this.rxTransferFG.value.IsRefillTransferred && this.transferType) {​
            this.refillNos = "";
            for (let i = this.rxTransferInfo.Refillno;
                 i <= this.rxTransferInfo.Aref; i++) {​
                this.refillNos = this.refillNos + i;
                if (i !== this.rxTransferInfo.Aref) {​
                    this.refillNos = this.refillNos + " , ";
                }​
            }​
        }​
    }​
    getPharmcyDetails() {​
        const Id = this.rxTransferFG.controls["XferPharmId"].value;
        this._rxTransferDet.getPharmacyDetails(Id).pipe(takeUntil(this.unsubscribe$)).subscribe(resp => {​
            if (resp) {​
                this.pharmcyDet.TransferPharmacy = resp["TransferPharmacies"];
                this.pharmcyDet.Phone = resp["Phone"];
                if (this.pharmcyDet.Phone) {​
                    if (this.pharmcyDet.Phone[0]) {​
                        if (this.pharmcyDet.Phone[0].Phone === " ") {​
                            this.Phone1 = null;
                        }​ else {​
                            this.Phone1 = this.pharmcyDet.Phone[0].Phone;
                        }​
                    }​ else {​
                        this.Phone1 = null;
                    }​
                    if (this.pharmcyDet.Phone[1]) {​
                        if (this.pharmcyDet.Phone[1].Phone === " ") {​
                            this.Phone2 = null;
                        }​ else {​
                            this.Phone2 = this.pharmcyDet.Phone[1].Phone;
                        }​
                    }​ else {​
                        this.Phone2 = null;
                    }​
                    if (this.pharmcyDet.Phone[2]) {​
                        if (this.pharmcyDet.Phone[2].Phone === " ") {​
                            this.Fax = null;
                        }​ else {​
                            this.Fax = this.pharmcyDet.Phone[2].Phone;
                        }​
                    }​ else {​
                        this.Fax = null;
                    }​
                }​ else {​
                    this.Phone1 = null;
                    this.Phone2 = null;
                    this.Fax = null;
                }​
                if (this.pharmcyDet) {​
                    this.citySelected = new City();
                    this.citySelected.Id = this.pharmcyDet.TransferPharmacy.CityId;
                    this.citySelected.Name = this.pharmcyDet.TransferPharmacy.CityName;
                    this.citySelected.StateId = this.pharmcyDet.TransferPharmacy.StateId;
                    this.stateSelected = new State();
                    this.stateSelected.Id = this.pharmcyDet.TransferPharmacy.StateId;
                    this.stateSelected.Name = this.pharmcyDet.TransferPharmacy.StateName;
                }​
                // for (const key in this.pharmcyDet) {​
                //     if (key === "Phone") {​
                //         this.generateFA(key, this.pharmcyDet.Phone);
                //     }​
                // }​
            }​
        }​);
    }​
    get PrescReFill(): FormGroup {​
        return this.rxFG.get("PrescReFill") as FormGroup;
    }​
    get RefillInsu(): FormGroup {​
        return this.rxFG.get("RefillInsu") as FormGroup;
    }​
    get Prescription(): FormGroup {​
        return this.rxFG.get("Prescription") as FormGroup;
    }​
    get SigCodes(): FormGroup {​
        return this.rxFG.get("SigCodes") as FormGroup;
    }​
    get RxBill(): FormGroup {​
        return this.rxFG.get("RxBill") as FormGroup;
    }​
    openRXIN() {​
        this.RXINPOPUP = true;
        this.modalRef = this._modalService.open(this.RXINMODAL, {​
            size: "lg",
            windowClass: environment.IseRxTransferRequest ? "markasTransferIn_modal" : "",
            keyboard: false,
            centered : true
        }​);
    }​
    closeModal() {​
        if (this.modalRef)​ {
            this.modalRef.close();
            this.modalRef = null;
        }
        if (this.rxTransferDetails && this.rxTransferDetails["rxTransferIn"] && this.rxTransferDetails["rxTransferOut"]) {​
            this.getTranferDet();
        }​ else {​
            this.IsPopUpClosed.emit(null);
        }​
    }​
    setSaveButton(val: any) {
        if (val) {​
            this._rxStore.storeTransfrInInfo(val);
            this.closeModal();
        }​
        this.saveRXIN = false;
        this.IsPopUpClosed.emit(null);
    }​
    saveNewRxTXIN(val?: any) {​
        if (val) {​
            this.saveRXIN = val;
        }​
    }​
    async saveEditRxXfer(type?: any) {​
        const data = new XferInfoEdit();
        data["CheckInOrOut"] = this.transferType;
        if (this.transferType) {​
            data["rxTransferOut"] = this.rxTransferFG.getRawValue();
            data["rxTransferOut"]["OtherPharmacistNm"] = this.rxTransferFG.value["OrgPharmacistNm"];
        } else {
            data["rxTransferIn"] = this.rxTransferFG.getRawValue();
        }​
        const resp = await this._rxTransferDet.updateXferInfo(data).toPromise();
        if (resp) {​
            if (type && type === "print") {​
                await this.printInfo();
            }​
            this.closeModal();
        }​
    }​
    async printInfo() {​
        const rxInfo = this.rxFG.getRawValue();
        const xRfInfoP = {​FromPrescRefillId: rxInfo.PrescReFill.Id,
            transferType: this.transferType ? "TransferOut" : "TransferIn", RxNum: rxInfo.Prescription.PrescNum}​;
        const presp = await this._rxTransferDet.printReport(xRfInfoP).toPromise();
        if (presp && presp.size > 0 && presp.type === "application/pdf") {​
            await this.printReport(presp);
        }​
    }​
    printReport(datToPrint) {​
        const newBlob = new Blob([datToPrint], {​ type: "application/pdf" }​);
        const reader = new FileReader();
        reader.readAsDataURL(newBlob);
        reader.onloadend = function () {​
            const base64data = reader.result;
            this._printser.printPdf(base64data);
        }​.bind(this);
    }
    
    ngOnDestroy(){
        this.unsubscribe$.next();
        this.unsubscribe$.complete();​
    }
}​





