export class Labels {
        Rx_Label= "Rx#";
        Fill_Date= "Fill Date";
        Order_Qty = "Order Qty";
        Disp_Qty = "Disp Qty";
        Days = "Days";
        Refill_Label = "Ref#";
        Fill_Label = "Fill#";
        Total_Amt = "Total Amt";
        Patient_Label = "Patient";
        Prescriber_Label = "Patient";
        Pharmacy_Label = "Patient";
        Requesting_Label = "Requesting By";
        Follow_Up_Tags = "Follow Up Tags"
}
export class ButtonLabels{
        
}
export class PatientLabels {
        Name =  "Name";
        DOB =  "DOB";
        Insurance_Type_Code = "Ins. Code(Group#/BIN/PCN)";
        Patient_notes = "Patient notes";
        Address = "Address";
        Allergies = "Allergies";
        Diagnosis_codes = "Diagnosis codes ";
        Gender = "Gender";
        Phone = "Phone";
}
export class DrugLabels {
        Name = "Name";
        DrugCode = "NDC";
        Brand =  "Brand";
        Patient_notes = "Patient notes";
        AWP =  "AWP";
        COST =  "Cost";
        Size =  "Size";
        Manufacturer = "Manufacturer ";
        DrugClass =  "Drug Class ";
        Drug_Notes = "Drug Notes";
        Qty_In_Hand: "Qty in Hand";
}
export class PharmacyLabels {
        Name = "Name";
        pName = "Pharmacy Name";
        Address = "Address";
        Brand = "Brand(Yes/No)";
        Phone =  "Phone1#";
        NPI =  "NPI#";
        NABP =  "NABP";
        DEA =  "DEA#";
        Fax =  "Fax#";
        Pharmacist_Name =  "Logged in Rph Name";
        Registration  = "Registration#";
        Contact  = "Telephone#";
}
export class CommonHeaders {
        Notes = "Notes";
        Override_credentials : "Override Credentials";
       
}