import { AuditLogUtils } from "./../../../utils/audit-log.util";
import { Component, OnInit, ViewChild, HostListener, Input } from "@angular/core";
import {
    PatientService,
    AlertService,
    CommonService,
    PatientCategoryService
} from "src/app/services";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import * as _ from "lodash";
import * as wjcGrid from "@grapecity/wijmo.grid"
import { CollectionView } from "@grapecity/wijmo";
import { DiagnosisList, PaginationModel } from "../../../models";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { RxUtils, NewAuditUtils, CommonUtil } from "src/app/utils";
import { MsgConfig } from "src/app/app.messages";

@Component({
    selector: "app-custom-category",
    templateUrl: "./custom-category.component.html"
})
export class PCustomCategoryComponent implements OnInit {
    paginationModel: PaginationModel = new PaginationModel();
    pageNumber = this.paginationModel.pageNumber;
    pageSize = this.paginationModel.pageSize;
    searchData = {Code: "", Name: "", pageNumber: this.pageNumber, pageSize: this.pageSize};
    totalCount: number;
    patCustomCat: any;
    patCatCount: number;
    @Input() patientId: number;
    patCategory: any;
    customWJ: CollectionView;
    patientCustomWJ: CollectionView;
    modelRef: any;
    modelRefSM: any;
    rowDetails: any;
    wijimoId: any;
    wijimoId1: wjcGrid.FlexGrid;
    optedData = [];
    ht:any;
    categoryData: any;
    isDataExists = true;
    spaceClick:boolean = false
    constructor(
        private _editPatientService: PatientService,
        private _alertService: AlertService,
        private modalService: NgbModal,
        private _newAuditUtils: NewAuditUtils,
        private _commonUtil: CommonUtil
    ) {
    }

    ngOnInit() {
        this.getPatientCustomCategory();
    }

    async getPatientCustomCategory() {
        this.patCustomCat = await  this._editPatientService.getPatientCustomCategories(this.patientId).toPromise();
        this.generatePatientCustomCatWJ(this.patCustomCat);
    }

    generatePatientCustomCatWJ(patCustomList) {
        if (this.patCustomCat && this.patCustomCat.length > 0) {
            this.isDataExists = true;
            this.patCatCount = this.patCustomCat.length;
            this.patientCustomWJ = new CollectionView(
                patCustomList.map((result, i) => {
                    const k = {};
                    k["Code"] = result.Code;
                    k["Name"] = result.Name;
                    k["Id"] = result.Id;
                    return k;
                })
            );
        } else {
            this.patCatCount = 0;
            this.isDataExists = false;
            this.patientCustomWJ = new CollectionView(null)
        }
    }

    async getCustomCategory() {
        const resp = await this._editPatientService.getPatientCatData(this.searchData).toPromise();
        this.categoryData = resp;
        this.generateCustomCatWJ(resp);
    }

    generateCustomCatWJ(customList) {
        if (customList && customList.length > 0) {
            this.totalCount = customList[0]["TotalRecords"];
            this.customWJ = new CollectionView(
                customList.map((result, i) => {
                    const k = {};
                    k["customCatId"] = result.Id;
                    if (this.patCustomCat && this.patCustomCat.length > 0 &&
                        this.patCustomCat.findIndex(val => val.Id === result.Id) > -1) {
                        k["AlreadySelected"] = true;
                    } else {
                        k["AlreadySelected"] = false;
                    }
                    k["IsSelected"] = false;
                    k["Code"] = result.Code;
                    k["Name"] = result.Name;
                    k["Id"] = result.Id;
                    return k;
                })
            );
        } else {
            this.totalCount = 0;
        }
    }

    async addMultipleItems(items) {
        const resp = await this._editPatientService.savePatientCustomCategories(items).toPromise();
            if (resp) {
                this._alertService.success("Patient Categories saved successfully.");
                await this.getPatientCustomCategory();
                this.auditChanges(items);
            } else {
                this._alertService.error(MsgConfig.SAVE_FAIL);
            }
    }

    async addSelectdItems() {
        const itemsToAdd = this.optedData;
        if (itemsToAdd && itemsToAdd.length > 0) {
            const dataToPost = itemsToAdd.map(elm => {
                const frameObj = {
                    patCustomCat: elm.customCatId,
                    patCode: elm.Code,
                    patientId: this.patientId,
                    patCustomCatName: elm.Name
                };
                return frameObj;
            });
            this.optedData = null;
            this.addMultipleItems(dataToPost);
            this.closeModal()
        } else {
            if (this.patCustomCat.length) {
                this.closeModal()
            } else {
                this._alertService.error("Please select atleast one patient category.");
            }
        }
    }

    closeModal() {
        if (this.modelRef) {
            this.modelRef.close();
            this.modelRef = null;
        }
    }

    getFilteredData(code, params) {
        if (code === "") {
            code = null;
            this.generateCustomCatWJ(this.categoryData);
        }
        if (code !== this.searchData[params]) {
            if (code) {
                this.searchData[params] = code;
                this.getCustomCategory();
            } else {
                this.searchData[params] = null;
                this.getCustomCategory();
            }
        }
    }

    setPage(page: number) {
        this.searchData.pageNumber = page;
        this.getCustomCategory();
    }

    setPageSize(size: number) {
        this.searchData.pageNumber = 1;
        this.searchData.pageSize = size;
        this.getCustomCategory();
    }

    preventSpaceEvent(event) {
        event.preventDefault();
    }

    changeCateg(item, event) {
        if (this.patCustomCat && (!item["IsSelected"] || (event && event.target.checked))) {
            item["IsSelected"] = !item["IsSelected"];
            this.optedData = this.optedData ? this.optedData : [];
            this.optedData.push(item);
        } else {
            item["IsSelected"] = !item["IsSelected"];
            const index = this.optedData.findIndex(val => val["Id"] === item["Id"]);
            this.optedData.splice(index, 1);
        }
    }

    Init(grid) {
        grid?.cells.hostElement.addEventListener('keydown', (e) => {
            if (e.key == 'Enter') {
                if (!this.patCustomCat || (this.patCustomCat && this.patCustomCat.length === 0)) {
                    this.removeFromSelectedList(grid.selectedItems[0]);
                    grid.selectedItems[0]["AlreadySelected"] = true;
                    this.saveCustomCategory(grid.selectedItems[0]);
                } else if (this.patCustomCat.findIndex(elm => elm.Id === grid.selectedItems[0].Id) === -1) {
                    this.removeFromSelectedList(grid.selectedItems[0]);
                    grid.selectedItems[0]["AlreadySelected"] = true;
                    this.saveCustomCategory(grid.selectedItems[0]);
                } else {
                    this._alertService.error("Patient Category already exists. Please select another one.");
                }
                
            }
        });
    }

    keyEventOnWijimo(grid, event) {
        if (event && event.which === 13) {
            event.preventDefault();
            this.spaceClick = true;
        }else {
            this.spaceClick = false
        this.ht = grid.hitTest(event);
        }
        if (this.spaceClick || this.ht.cellType === 1) {
        if (!this.patCustomCat || (this.patCustomCat && this.patCustomCat.length === 0)) {
            this.removeFromSelectedList(grid.selectedItems[0]);
            grid.selectedItems[0]["AlreadySelected"] = true;
            this.saveCustomCategory(grid.selectedItems[0]);
        } else if (this.patCustomCat.findIndex(elm => elm.Id === grid.selectedItems[0].Id) === -1) {
            this.removeFromSelectedList(grid.selectedItems[0]);
            grid.selectedItems[0]["AlreadySelected"] = true;
            this.saveCustomCategory(grid.selectedItems[0]);
        } else {
            this._alertService.error("Patient Category already exists. Please select another one.");
        }
        }
    }

    saveCustomCategory(item) {
        const frameObj = {
            patCustomCat: item.customCatId,
            patientId: this.patientId,
            patCustomCatName: item.Name,
            patCode: item.Code,
        };
        this.addMultipleItems([frameObj]);
    }

    removeFromSelectedList(data) {
        if (this.optedData && this.optedData.length > 0) {
            const index = this.optedData.findIndex(val => val["Id"] === data["Id"]);
            if (index > -1) {
                this.optedData.splice(index, 1);
            }
        }
    }

    auditChanges(data) {
        let addedCode = "";
        data.map(values => {
            addedCode = addedCode + (addedCode !== "" ? ", " : "") + values["patCode"];
        });
        this._newAuditUtils.SaveAuditOnDelete(
            null,
            [{name: "Added Custom Category", value: addedCode}],
            "CustomCategory",
            "Patient",
            this.patientId
        );
    }

    getRowDetails(content, s: any, e: any) {
        if (e.which !== 68) {
            s.hitTest(e);
        }
        this.rowDetails = s.selectedItems[0];
        this.modelRefSM = this.modalService.open(content, { centered: true, keyboard: false, backdrop: false,
             windowClass: "large--content" });
    }

    async deleteCustomCategory() {
        const resp = await this._editPatientService.deletePatientCustomCategories(this.rowDetails["Id"], this.patientId).toPromise();
        if (resp) {
            this._newAuditUtils.SaveAuditOnDelete(
                [{name: "Deleted Custom Category", value: this.rowDetails["Code"]}],
                null,
                "CustomCategory",
                "Patient",
                this.patientId
            );
            this._alertService.success("Patient Category deleted successfully.");
            this.getPatientCustomCategory();
        } else {
            this._alertService.error(MsgConfig.DELETE_FAIL);
        }
    }

    openPopOnWijimo(content, event) {
        if (event.ctrlKey) {
            if (event.which === 65) {
                event.preventDefault();
                this.openPopUpModal(content);
            }
        }
    }

    async openPopUpModal(content) {
        await this.getCustomCategory();
        this.modelRef = this.modalService.open(content, { size: "lg", windowClass: "modal-center-to-page", keyboard: false, backdrop: false});
    }

    init(flex: wjcGrid.FlexGrid) {
        this.wijimoId = flex;
    }

    init1(flex: wjcGrid.FlexGrid) {
        this.wijimoId1 = flex;
    }

    focusOutFromAdd(event) {
        if (!this.patCatCount) {
            this.focusToFirst(event);
        } else {
            event.preventDefault();
            this.wijimoId.focus();
        }
    }

    focusToFirst(event) {
        if (document.getElementById("SaveButton")) {
            event.preventDefault();
            setTimeout(() => {
                document.getElementById("SaveButton").focus();
            }, 10);

        }
    }

    closeAddPop(event) {
        if (event.ctrlKey) {
            if (event.which === 67) {
        event.preventDefault();
        if(this.modelRef) {
            this.modelRef.close();
              this.modelRef = null;
        }
            } else if (event.which === 65) {
                event.preventDefault();
                this.addSelectdItems();
                if(this.modelRef) {
                    this.modelRef.close();
                      this.modelRef = null;
                }
            }
        } else if (event.which === 9) {
            event.preventDefault();
            this._commonUtil.focusById("custmPage", event, "Page");
        }
    }
}
