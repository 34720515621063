import { AuditLogUtils } from "./../../../utils/audit-log.util";
import { Component, OnInit, ViewChild, HostListener, Input, Output, EventEmitter, ChangeDetectorRef } from "@angular/core";
import { CollectionView } from "@grapecity/wijmo";
import {
    PatientService,
    AlertService,
    CommonService,
    PreviousRouteService
} from "../../../services";
import { FormGroup, FormBuilder } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as wjcGrid from "@grapecity/wijmo.grid"
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment";
import {
    DiagnosisList,
    searchCriteria,
    PatDiagnosis
} from "src/app/models/diagnosis-details.model";
import { RxUtils, NewAuditUtils, CommonUtil, WijimoUtil } from "src/app/utils";
import { PaginationModel, PatientInfo } from "src/app/models";
import { MsgConfig } from "src/app/app.messages";
import { PatientStore } from "src/app/store";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
    selector: "app-diagnosis-details",
    templateUrl: "./diagnosis-details.component.html",
    styles: []
})
export class PDiagnosisDetailsComponent implements OnInit {
    @Input() patientId: number;
    @Input() OpenDiagPopUp: boolean;
    @Input() patientFG: FormGroup;
    @Input() frmBasicInfo: boolean;
    @Output() IsPopUpClosed = new EventEmitter();

    @ViewChild("DiagnosisExist", { static: true })
    DiagnosisExist: any;

    @ViewChild("DiagnosList")
    DiagnosList: wjcGrid.FlexGrid;

    @ViewChild("diagnosisList", { static: true })
    diagnosisList: any;

    diagnosisWJ: CollectionView;
    patientDiagnosisWJ: CollectionView;
    wjHeaders: any;
    diagnosisFG: FormGroup;
    search: searchCriteria;
    modelRef: any;
    WholeDiagnosis: DiagnosisList;
    hasDiagnosis;
    patientDiagnosis: PatDiagnosis[];
    modelRefSM: any;
    rowId: number;
    rowDetails: any;
    totalCount: number;
    pageCount: number;
    paginationModel: PaginationModel = new PaginationModel();
    patientInfo: any;
    routeFrom: string;
    modalRef: any;
    sharedCancel = false;
    modalType: string;
    diagnosisListWJ: wjcGrid.FlexGrid;
    parentRoute: any;
    diagnosisReqClick: any;
    wijimoId: wjcGrid.FlexGrid;
    patDiagnosisCount: any;
    diagList$: any;
    optedData = [];
    totalGridData: any;
    diagnosisData: any;
    isDataExists = true;
    unsubscibe$: Subject<void> = new Subject();
    constructor(
        private _editPatientService: PatientService,
        private _commonUtil: CommonUtil,
        private modalService: NgbModal,
        private _alertService: AlertService,
        private _newAuditUtils: NewAuditUtils,
        private _patientStore: PatientStore,
        private _wijimoUtils: WijimoUtil,
        private _cdr: ChangeDetectorRef,
    ) {
        this.search = {
            Code: null,
            Description: null,
            pageNum: this.paginationModel.pageNumber,
            pageSize: this.paginationModel.pageSize
        };
    }

    ngOnInit() {
        this.diagList$ = this._patientStore.diagList$;
        this.WholeDiagnosis = this.diagList$["source"]["value"];
        this.getPatDiag();
        this.patchDefaultValHeaders();
        this._wijimoUtils.wjSavedData$.pipe(takeUntil(this.unsubscibe$)).subscribe(w => {
            if (w && w["WijmoKey"] && w["WijmoKey"] === "patDiagnosisListWJ") {
                this.patchDefaultValHeaders();
            }
        });
    }

    async getPatDiag() {
        await this.getPatientDiagnosis();
        if (this.OpenDiagPopUp) {
            this.openPopUpModal();
        }
    }

    async patchDefaultValHeaders() {
        const storedWJ = await this._wijimoUtils.getWJSavedData("patDiagnosisListWJ");
        this.wjHeaders = this._wijimoUtils.patchDefHeader("patDiagnosisListWJ", storedWJ);
    }

    async getWholeDiagnosis() {
        const resp = await this._editPatientService.getDiagnosisFiltered(this.search).toPromise();
        this.diagnosisData = resp
        this.generateDiagnosisWJ(resp);
        this.totalGridData =  resp?.TempDiagnosis;
    }

    async openPopUpModal() {
        this.search = { Code: null, Description: null, pageNum: this.paginationModel.pageNumber, pageSize: this.paginationModel.pageSize };
        if (this.WholeDiagnosis) {
            this.WholeDiagnosis["TempDiagnosis"] = this.WholeDiagnosis["TempDiagnosis"].slice(this.search["pageNum"] - 1,
             this.search["pageSize"]);
             this.generateDiagnosisWJ(this.WholeDiagnosis);
        } else {
            await this.getWholeDiagnosis();
        }
        this.modalType = "diagList";
        this.modelRef = this.modalService.open(this.diagnosisList, { size: "lg", centered: true, backdrop: false });
    }

    getPatientDiagnosis() {
        if (this.patientFG.value["PatientDiagnosis"] && this.patientFG.value["PatientDiagnosis"]["length"] > 0) {
            this.patientDiagnosis = this.patientFG.value["PatientDiagnosis"];
            this.patDiagnosisCount = this.patientFG.value["PatientDiagnosis"]["length"];
            this.generatePatientDiagnosisWJ(this.patientDiagnosis);
            this.isDataExists = true;
        } else {
            this.patDiagnosisCount = 0;
            this.isDataExists = false;
            this.patientDiagnosisWJ = new CollectionView(null)
        }
    }

    generatePatientDiagnosisWJ(patientDiagnosis) {
        this.patientDiagnosisWJ = new CollectionView(
            patientDiagnosis.map((patDiagnosis,i) => {
                let k = {};
                k["rowId"]=i;
                k["patientId"] = this.patientId;
                k["Code"] = patDiagnosis.DiagnosisId;
                k["Diagnosis Description"] = patDiagnosis.DiagDesc;
                k["Type"] = "ICD10";
                k["IsActive"] = true;
                return k;
            })
        );
    }


    getCodeFilteredData(code, params) {
        if (code === "") {
            code = null;
            if (this.diagnosisData) {
                this.generateDiagnosisWJ(this.diagnosisData);
            } else {
                this.generateDiagnosisWJ(this.WholeDiagnosis);
            }
            
        }
        if (this.search[params] !== code) {
            this.search["pageNum"] = 1;
            if (code) {
                this.search[params] = code;
                this.getWholeDiagnosis();
            } else {
                this.search[params] = null;
                this.getWholeDiagnosis();
            }
        }
    }

    generateDiagnosisWJ(diagnosisList) {
        if (diagnosisList && diagnosisList.TempDiagnosis && diagnosisList.TempDiagnosis.length > 0) {
            this.totalCount = diagnosisList.TotalRecords;
            this.diagnosisWJ = new CollectionView(
                diagnosisList.TempDiagnosis.map((diagnosis, i) => {
                    let k = {};
                    k["rowId"]=i;
                    k["patientId"] = this.patientId;
                    k["diagnosisId"] = diagnosis.DIAGCODE;
                    k["Code"] = diagnosis.DIAGCODE;
                    k["Diagnosis Description"] = diagnosis.DIAGDESCR;
                    k["IsActive"] = true;
                    if (this.patientFG.value["PatientDiagnosis"] && this.patientFG.value["PatientDiagnosis"].length > 0) {
                        if (this.patientFG.value["PatientDiagnosis"].find(val => val.DiagnosisId === diagnosis.DIAGCODE)) {
                            k["AlreadySelected"] = true;
                        } else {
                            k["AlreadySelected"] = false;
                        }
                    } else {
                        k["AlreadySelected"] = false;
                    }
                    if (this.optedData && this.optedData.length > 0 &&
                         this.optedData.findIndex(val => val["diagnosisId"] === diagnosis.DIAGCODE) > -1) {
                        k["IsSelected"] = true;
                    } else {
                        k["IsSelected"] = false;
                    }
                    k["Type"] = "ICD10";
                    return k;
                })
            );
        } else {
            this.totalCount = 0;
        }
    }

    setPage(page: number) {
        this.search.pageNum = page;
        this.getWholeDiagnosis();
    }
    setPageSize(size: number) {
        this.search.pageNum = 1;
        this.search.pageSize = size;
        this.getWholeDiagnosis();
    }

    getRowDetails(content, s: any, e: any) {
        this.rowId = null;
        if (e.which !== 68) {
            s.hitTest(e);
        }
        this.rowDetails = s.selectedItems[0]["Code"];
        this.modelRefSM = this.modalService.open(content, { backdrop: false, centered: true, windowClass: "large--content" });
    }

    selectItemForDelete(content, Id) {
        this.rowDetails = Id;
        this.modelRefSM = this.modalService.open(content, { backdrop: false, centered: true, windowClass: "large--content" });
    }

    preventSpaceEvent(event) {
        event.preventDefault();
    }

    async addDiagnosis(data) {
        if (this.patientId) {
            await this._editPatientService.savePatientDiagnosis(data).toPromise();
            await this.saveAuditValues(data);
           const patinfo = await this._editPatientService.getEditPatientInformation(this.patientId).toPromise();
           const patdiag = patinfo["PatientDiagnosis"];
           data.map((val) => {
               const value = patdiag.find(diag => diag["DiagnosisId"] === val["DiagnosisId"]);
               if (value) {
                val["Id"] = value["Id"];
                val["CreatedDtTm"] = value["CreatedDtTm"];
               }
           });
        }
        this.patientDiagnosis = this.patientFG.value["PatientDiagnosis"] && this.patientFG.value["PatientDiagnosis"].length > 0
        ? this.patientFG.value["PatientDiagnosis"] : [];
        data.map(val => this.patientDiagnosis.push(val));
        this._commonUtil.generateFA("PatientDiagnosis", this.patientDiagnosis, this.patientFG);
        this._alertService.success(MsgConfig.SAVE_SUCCESS);
        this.getPatientDiagnosis();
    }

    addSelectdItems() {
        const itemsSelected = this.optedData;
        if (itemsSelected && itemsSelected["length"] > 0) {
            const datatoAdd = itemsSelected.map(val => {
                const newItem = new PatDiagnosis();
                newItem.DiagnosisId = val.diagnosisId;
                newItem.DiagDesc = val["Diagnosis Description"];
                newItem.IsActive = true;
                newItem.PatientId = this.patientId;
                return newItem;
            });
            this.optedData = null;
            this.addDiagnosis(datatoAdd);
            this.closeDiagonisModal()
        } else {
            if (this.patientFG && this.patientFG.value && this.patientFG.value["PatientDiagnosis"] && this.patientFG.value["PatientDiagnosis"].length) {
                this.closeDiagonisModal()
            } else {
                this._alertService.error("Please select atleast one Diagnosis.");
            }
        }
    }

    saveAuditValues(data) {
        let addedCode = "";
        data.map(values => {
            addedCode = addedCode + (addedCode !== "" ? ", " : "") + values["DiagnosisId"];
        });
        this._newAuditUtils.SaveAuditOnDelete(
            null,
            [{name: "Added Diagnosis Details", value: addedCode}],
            "DiagnosisDetails",
            "Patient",
            this.patientId
        );
    }

    saveDiagnosis(item, grid,type?) {
        if (!this.patientDiagnosis) {
            this.removeFromSelectedList(item);
            this.addDiagnosis([item]);
            if(type === "Enter"){
                grid.collectionView["_src"].filter(value=>{
                    if(value["diagnosisId"] === item["DiagnosisId"]){
                            value["AlreadySelected"]=true;
                     }
                 })
        }else{
        grid.selectedItems[0]["AlreadySelected"] = true;
        }
        } else if (this.patientDiagnosis.findIndex(val => val["DiagnosisId"] === item["DiagnosisId"]) === -1) {
            this.removeFromSelectedList(item);
            if(type === "Enter"){
                    grid.collectionView["_src"].filter(value=>{
                        if(value["diagnosisId"] === item["DiagnosisId"]){
                                value["AlreadySelected"]=true;
                         }
                     })
            }else{
            grid.selectedItems[0]["AlreadySelected"] = true;
        }
        this.addDiagnosis([item]);
        } else {
            this._alertService.error("Diagnosis already exists. Please select another one.");
        }
    }

    removeFromSelectedList(data) {
        if (this.optedData && this.optedData.length > 0) {
            const index = this.optedData.findIndex(val => val["diagnosisId"] === data["DiagnosisId"]);
            if (index > -1) {
                this.optedData.splice(index, 1);
            }
        }
    }


    changeDiognasis(item, event) {
        if ((!item["IsSelected"] || (event && event.target.checked))) {
            item["IsSelected"] = !item["IsSelected"];
            this.optedData = this.optedData ? this.optedData : [];
            this.optedData.push(item);
        } else {
            item["IsSelected"] = !item["IsSelected"];
            const index = this.optedData.findIndex(val => val["diagnosisId"] === item["diagnosisId"]);
            this.optedData.splice(index, 1);
        }
    }

    async deleteDiagnosis() {
        if (this.patientId) {
            const resp = await this._editPatientService.deletePatientDiagnosis(this.patientId, this.rowDetails).toPromise();
            if (resp) {
                this._newAuditUtils.SaveAuditOnDelete(
                    [{name: "Deleted Diagnosis Details", value: this.rowDetails}],
                    null,
                    "DiagnosisDetails",
                    "Patient",
                    this.patientId
                );
            }
        }
        this.patientDiagnosis = this.patientFG.value["PatientDiagnosis"] && this.patientFG.value["PatientDiagnosis"].length > 0
        ? this.patientFG.value["PatientDiagnosis"] : [];
        const index = this.patientDiagnosis.findIndex(val => val["DiagnosisId"] === this.rowDetails);
        this.patientDiagnosis.splice(index, 1);
        this._commonUtil.generateFA("PatientDiagnosis", this.patientDiagnosis, this.patientFG);
        this._alertService.success(MsgConfig.DELETE_SUCCESS);
        this.getPatientDiagnosis();
    }

    onRowDblclicked(s: any, e: any) {
        const selectedItem = s.selectedItems[0];
        const formatItem = this.addDiagnosisList(selectedItem);
        this.saveDiagnosis(formatItem, s);
    }

    addDiagnosisList(selectedItem) {
        const newItem = new PatDiagnosis();
        newItem.DiagnosisId = selectedItem.diagnosisId;
        newItem.IsActive = true;
        newItem.PatientId = this.patientId;
        newItem.DiagDesc = selectedItem["Diagnosis Description"];
        return newItem;
    }

    openPopOnWijimo(event) {
        if (event.ctrlKey) {
            if (event.which === 65) {
                event.preventDefault();
                this.openPopUpModal();
            }
        }
    }

    closeDiagonisModal() {
        if (this.modelRef) {
            this.modelRef.close();
            this.modelRef = null;
        }
        this.modalType = null;
        this.IsPopUpClosed.emit(null);
    }

    focusToList(event) {
        event.preventDefault();
        this.diagnosisListWJ.focus();
    }

    init(flex: wjcGrid.FlexGrid) {
        this.diagnosisListWJ = flex;
        flex.columnHeaders.rows[0].wordWrap = true;
    }

    focusToFirst(event) {
        event.preventDefault();
        if (document.getElementById("Code")) {
            document.getElementById("Code").focus();
        }
    }

    Init(grid) {
        grid?.cells.hostElement.addEventListener('keydown', (e) => {
            if (e.key == 'Enter') {
                const formatItem = this.addDiagnosisList(grid.collectionView["_src"][grid.selection.row]);
                this.saveDiagnosis(formatItem, grid,"Enter");  
            }
        });
    }

    onEnterDiag(eve) {
        if (eve.ctrlKey) {
            if (eve.which === 67  && this.modalService.hasOpenModals()) {
                event.preventDefault();
                this.closeDiagonisModal();
            } else if (eve.which === 65) {
                this.addSelectdItems();
                this.closeDiagonisModal();
            }
        } else if (eve.which === 9) {
            this._commonUtil.focusById("diagPage", eve, "Page");
        }
    }

    init1(flex: wjcGrid.FlexGrid) {
        this.wijimoId = flex;
    }

    focusOutFromAdd(event) {
        if (!this.hasDiagnosis) {
            // this.focusToCancel(event);
            this.focusToPatChart(event);
        } else {
            event.preventDefault();
            this.wijimoId.focus();
        }
    }

    focusToPatChart(event) {
        if (document.getElementById("patOthChrtId")) {
            event.preventDefault();
            setTimeout(() => {
                document.getElementById("patOthChrtId").focus();
            }, 10);
        }
    }

    focusToCancel(event) {
        if (document.getElementById("SaveButton")) {
            event.preventDefault();
            setTimeout(() => {
                document.getElementById("SaveButton").focus();
            }, 10);

        }
    }
    ngOnDestroy(){
        this.unsubscibe$.next();
        this.unsubscibe$.complete();
    }
}
