<ng-template #TRANSLOG let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Rx Transmission Log</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body edit-patient erx--body">
        <div class="row">
            <div class="col">
                <wj-flex-grid #rxTransmission [headersVisibility]="'Column'" [itemsSource]="rxTransmissionWJ" [isReadOnly]="true" [columnPicker]="'rxTransmissionLogWJ'" (initialized)="init(rxTransmission)">
                    <wj-flex-grid-column [header]="''" [width]="35">
                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                            <i class="far fa-poll-h actions" title="Show" (click)="showTransmitLog(item)"></i>
                        </ng-template>
                    </wj-flex-grid-column>
                    <wj-flex-grid-column [header]="rxheader['hName']" [binding]="rxheader['hName']" [visible]="rxheader['isVisible']"
                        [width]="rxheader['width']" *ngFor="let rxheader of wjHeaders; let i = index" [align]="'center'">
                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-row="row">
                            <div> {{ item[rxheader['hName']]}} </div>
                        </ng-template>
                    </wj-flex-grid-column>
                </wj-flex-grid>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button autofocus type="button" class="hotkey_primary secondary" (click)="closeModal()" appShortcutKey InputKey="o"><b>O</b> OK</button>
    </div>
</ng-template>

<ng-template #WARNINGMSG let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModal()">
        <h4 header>Warning!!</h4>
        <span body>
            There is no transmission message available to be displayed for this Rx#.
            <!-- {{rxInfo?.}} -->
        </span>
        <button footer autofocus type="submit" class="hotkey_primary" (click)="closeModal()" appShortcutKey InputKey="o">
            <b>O</b> Ok
        </button>
    </app-custom-modal>
</ng-template>

<app-tr-rx-only *ngIf="isTransmission === 'transMessage'" [rxInfo]="rxFG?.getRawValue()" [transmissionType]="'TransMessage'" [systemData]="systemData"
    [transmitParams]="transmitParams" (IsTransmissionCompleted)="completeTranmsns($event)"></app-tr-rx-only>
