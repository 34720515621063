import { Component, OnInit, Input, Renderer2, ViewChild, ChangeDetectionStrategy} from "@angular/core";
import { SystemData, InsuranceData } from "src/app/models";
import { FormGroup } from "@angular/forms";
import { InsuranceUtil, CommonUtil } from "src/app/utils";
import { RegEx } from "src/app/app.regex";
import { InsuranceStore } from "src/app/store";
import { AlertService, CommonService, InsuranceService} from "src/app/services";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { MsgConfig } from "src/app";
import { CommonWarnorconfirmPopupComponent } from "../../shared/common-warnorconfirm-popup/common-warnorconfirm-popup.component";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
    selector: "app-plan-info",
    templateUrl: "./plan-info.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlanInfoComponent implements OnInit {
    @Input() openMMS: any;
    @Input() planInfoFG: FormGroup;
    @Input() systemData: SystemData;
    @Input() planInfo: any;
    @Input() insuId: any;
    @Input() dropDownData: any;
    @Input() selectedRow: any;
    @Input() formGroupInvalid: boolean;
    
    @ViewChild("submissionTypeWarning", { static: true })
    submissionTypeWarning: any;
    regex = RegEx;
    insuCity$: any;
    insuState$: any;
    warningModelRef: any;
    result1 = false;
    systemData$: Observable<SystemData>;
    unsubscribe$: Subject<void> = new Subject();
    constructor(
        private _insuUtils: InsuranceUtil,
        private _insuStore: InsuranceStore,
        private _comnUtil: CommonUtil,
        private _insuSer: InsuranceService,
        private _alerSer: AlertService,
        private _renderer: Renderer2,
        private _modalService: NgbModal,
        private _commonServ: CommonService,
    ) {
        this.insuCity$ = this._insuStore.insuCity$;
        this.insuState$ = this._insuStore.insuState$;
        this.systemData$ = this._commonServ.systemData$;
    }

    ngOnInit() {
        if(this.selectedRow && !this.selectedRow['InsName']) {
                const insuCardsFG: any = this.planInfoFG.controls["InsuCarrier"];
                const InsuPlanFG: any = this.planInfoFG.controls["InsuPlan"];
                insuCardsFG.patchValue({
                    BINNum:  this.selectedRow['BINLocationNumber']
                });
                InsuPlanFG.patchValue({
                    ProcessorNum: this.selectedRow['ProcessorIdentificationNumber']
                });
        }
    }

    clearStateAndCity(type?: any) {
        const AddFG = this.planInfoFG.controls["Address"] as FormGroup;
        if (!type) {
            AddFG.controls["StateName"].patchValue(null);
            AddFG.controls["StateName"].markAsDirty();
        }
        AddFG.controls["CityName"].patchValue(null);
        AddFG.controls["CityName"].markAsDirty();
    }

    patchStateAndCity(data, type?: any) {
        this._insuUtils.patchCityAndStateValue(data, this.planInfoFG, (type ? type : null));
    }

    focusToSave(event) {
        this._comnUtil.focusById("InsSaveButton", event);
    }
    async triggerSelectedValue(value) {
        let cashWarnMsg = "";
        let defWarnMsg = "";
        if (value && value.target && value.target.id === "n" && this.planInfoFG.value && this.planInfoFG.value.InsuCarrier) {
            if (this.systemData$["source"]["value"]) {
                const systemData = this.systemData$["source"]["value"];
                if (systemData["DefaultValuese"] && systemData["DefaultValuese"].length > 0) {
                    if (systemData["DefaultValuese"][0].CashInsurerId === this.planInfoFG.value.InsuCarrier.Id) {
                        this.result1 = true;
                        cashWarnMsg = "The selected Insurance cannot be inactivated as it is Cash Insurance.";
                    }
                }
            }
            if (!this.result1) {
                const defInsu =  this._commonServ.getSetttingValue("PatientSettings", "PAT_TYPE");
                const def340bInsu =  this._commonServ.getSetttingValue("ThreeFourtyBSettings", "Ins_Code_340BSettings");
                if (defInsu && this.planInfoFG.value && this.planInfoFG.value.InsuCarrier) {
                    if (defInsu === this.planInfoFG.value.InsuCarrier.InsurerCode) {
                        defWarnMsg = "The selected Insurance cannot be inactivated as it is set as Patient Default Insurance.";
                    }
                    if (def340bInsu == this.planInfoFG.value.InsuCarrier.Id) {
                        defWarnMsg =
                        "The selected Insurance cannot be inactivated as it is set as Patient Default 340B Insurance.";
                    }
                }
            }
            if (cashWarnMsg || defWarnMsg) {
                const modelRef = this._modalService.open(CommonWarnorconfirmPopupComponent, {backdrop: false,
                keyboard: false, windowClass: "large--content"});
                modelRef.componentInstance.warnMsg = cashWarnMsg ? cashWarnMsg : defWarnMsg;
                modelRef.componentInstance.okButton = true;
                modelRef.componentInstance.IsPopUpClosed.pipe(takeUntil(this.unsubscribe$)).subscribe((resp: any) => {
                    modelRef.close();
                    if (resp) {
                        this.planInfoFG.controls["InsuPlan"].patchValue({IsActive :  true});
                    }
                });
            } else {
                this.planInfoFG.controls["InsuPlan"].patchValue({IsActive :  false});
            }
        }
        /* this._insuSer.checkInsuranceAttach(this.insuId).subscribe((resp: any) => {
                if(resp) {
                    this.planInfoFG.controls["InsuPlan"].patchValue( {
                        IsActive :  true
                    });
                    this._alerSer.error(MsgConfig.INSURANCE_PATIENT_USE);
                }
        }); */
    }
    closeSubmissionTypeModal(type) {
        if (type === "cancel") {
           const InsuXmitSubCatId =  this.planInfoFG.value["InsuTransmission"]["InsuXmitSubCatId"] === 1 ? 2 : 1;
            this.planInfoFG.controls["InsuTransmission"].patchValue({
                InsuXmitSubCatId
             });
        }
        if (this.warningModelRef) {
            this.warningModelRef.close();
            this.warningModelRef = null;
        }
    }
    getSubmissionType() {
            this.warningModelRef  = this._modalService.open(
                this.submissionTypeWarning,
                {
                    centered: true,
                    keyboard: false,
                    size: "sm",
                    windowClass: "medium-sm-modal-30 large--content",
                });
    }
    async checkIfInsuranceExists(value) {
        if (value) {
            const resp = await this._insuSer.checkIfInsuCodeExists(value).toPromise();
            if (resp) {
                this.planInfoFG.controls["InsuCarrier"].patchValue({ InsurerCode: null });
                this._alerSer.error("Insurance Code Already Exists. Please enter a different one", false, "long");
                if (document.getElementById("InsuCodeId")) {
                    document.getElementById("InsuCodeId").focus();
                }
            } else {
                if (document.getElementById("InsuCodeNameU")) {
                    document.getElementById("InsuCodeNameU").focus();
                }
            }
        }
    }
    onAddressChange(address) {
        const result = address.replace(/^\s+/g, '');
        this.planInfoFG.controls["Address"].patchValue({
            AddressLine1: result
        });
    }
    ngOnDestroy(){
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
