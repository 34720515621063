<ng-template #RxNOTES let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModal()">
        <h4 header> Rx Notes </h4>
        <span body>
            <div class="eprx--block__content">
                <div class="row">
                    <div class="col" *ngIf="false">
                        <h5 class="notes--block--head">PATIENT :
                            {{(rxFG?.value?.Patient?.lastname).trim()|uppercase }}<span *ngIf="rxFG?.value?.Patient?.lastname && rxFG?.value?.Patient?.firstname">, </span>
                            {{rxFG?.value?.Patient?.firstname | uppercase }}

                        </h5>
                        <h5 class="notes--block--head">DRUG : {{rxFG?.value?.Drug?.drugname |uppercase}} {{rxFG?.value?.Drug?.strength}}{{rxFG?.value?.Drug?.drugunit}}
                            {{rxFG?.value?.Drug?.drugformname |uppercase}}
                        </h5>
                    </div>
                    <div class="col" >
                        <div class="card border_10 border_transparent" >
                            <div class="card-body   p-0 p-2 add_Note_border height_240 borderRadius_forBoxes" > 
                                <div >
                                    <div >
                                        <div class="row" >
                                            <div class="col-6">
                                                <eprx-select [RxSelectId]="'notesLogCategry'" 
                                                [LabelText]="'Subcategory'" [PlaceHolder]="'Subcategory'"
                                                    [ControlName]="'NoteCatId'" [FormGroupName]="RxNotes" [List]="notesSubCatList" 
                                                    [BindLabel]="'Name'" [BindValue]="'Id'" 
                                                    [LabelForId]="'Name'" [ErrorDefs]="{required: 'Required'}" 
                                                    [IsFirstElem]="true" [secondCntrlN]="'Name'" [bindScnCntrlV]="'Name'"
                                                  ></eprx-select> 
                                            </div>
                                            <div class="col-6 pr-0">
                                                <eprx-toggle [LabelText]="'Show as Pop-up?'" [ControlName]="'IsShownAsPopup'" [FormGroupName]="RxNotes" [IsChecked] = "RxNotes?.get('IsShownAsPopup').value" [RxselectId]="'ShownAsPopup'">
                                                </eprx-toggle>
                                            </div>  
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <eprx-text-area [LabelText]="'Notes'" [ControlName]="'xNote'" [FormGroupName]="RxNotes" [MaxLength]="4000" [AutoFocus]="true" 
                                                    [PlaceHolder]="'Notes'" [Rows]="4" 
                                                    [InputErrors]="RxNotes['controls'].xNote?.errors"
                                                    [ErrorDefs]="{required: 'Required'}" 
                                                    >
                                                </eprx-text-area>

                                                <div class="col-12 pl-0" >
                                                    Notes By: {{userFullName}}
                                                 </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>

                            </div>
                        
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="false">
                    <div class="col-md-12">
                        <textarea autofocus class="label--text__area col-md-12" id="rxnotes" maxlength="500" [value]="notes" (keyup)="changeNotes($event?.target?.value)" *ngIf="hasNoteChngPriv"></textarea>
                        <textarea autofocus class="label--text__area col-md-12" id="rxnotes" maxlength="500" [value]="notes" (keyup)="openOveride($event?.target?.value)" *ngIf="!hasNoteChngPriv && !override"></textarea>
                        <textarea autofocus class="label--text__area col-md-12" id="rxnotes" maxlength="500" [value]="notes" (keyup)="changeNotes($event?.target?.value)" *ngIf="!hasNoteChngPriv && override"></textarea>
                    </div>
                </div>
            </div>
        </span>
        <button ngbAutofocus footer class="hotkey_success" (click)="saveNotes()" appShortcutKey [AltKey]="'o'"><span>o</span> OK</button>
        <button class="hotkey_success" (click)="closeModal()" (keydown.tab)="focusOutFromCancel($event)" appShortcutKey [AltKey]="'c'"> <span>C</span> Cancel </button>
    </app-custom-modal>
</ng-template>


<div class="row" *ngIf="rxType === 'er'">
    <div class="col-md-12">
        <div class="row content--heading">
            <div class="col-md-8 padding-0">
                <div class="row">
                    <div class="icon">
                        <img src="assets/dist/Icons/Icon_rx2.png">
                    </div>
                    <div class="col-md-11 padding-0">
                        <div class="content--heading__title padding-0"> Rx Notes
                            <span class="content--name"></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-right pull-right col-md-4 padding-0">
                <button footer class="hotkey_success" (click)="saveNotesWithRx()" appShortcutKey [AltKey]="'s'"><span>S</span> Save</button>
                <button footer class="hotkey_success" (click)="cancelOnEdit(true)" appShortcutKey [AltKey]="'c'" (keydown.tab)="focusOutFromCancel($event)"><span>C</span> Cancel</button>
            </div>
        </div>
    </div>
    <div class="col-md-12">
        <div class="newrx--body edit--drug insurance--body">
            <div class="eprx--block__content" *ngIf="rxFG?.value?.Patient">
                <div class="row">
                    <div class="col-md-12">
                        <h5 class="notes--block--head">PATIENT:
                            {{rxFG?.value?.Patient?.lastname|uppercase}}<span *ngIf="rxFG?.value?.Patient?.lastname && rxFG?.value?.Patient?.firstname">, </span>
                            {{rxFG?.value?.Patient?.firstname | uppercase }}
                        </h5>
                        <h5 class="notes--block--head">DRUG: {{rxFG?.value?.Drug?.drugname | uppercase}} {{rxFG?.value?.Drug?.strength | uppercase}}
                            {{(rxFG?.value?.Drug?.drugunit + "") | uppercase}}
                            {{rxFG?.value?.Drug?.drugformname | uppercase}}
                        </h5>
                    </div>
                    <div class="col-md-12 notes--block">
                        <textarea autofocus class="label--text__area col-md-12" id="rxnotes" maxlength="500" [value]="notes" (keyup)="changeNotes($event?.target?.value)" *ngIf="hasNoteChngPriv"></textarea>
                        <textarea autofocus class="label--text__area col-md-12" id="rxnotes" maxlength="500" [value]="notes" (keyup)="openOveride($event?.target?.value)" *ngIf="!hasNoteChngPriv && !override"></textarea>
                        <textarea autofocus class="label--text__area col-md-12" id="rxnotes" maxlength="500" [value]="notes" (keyup)="changeNotes($event?.target?.value)" *ngIf="!hasNoteChngPriv && override"></textarea>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-override-log *ngIf="openOverride" [PrivMask]="privType" [CatId]="categoryId" [OverrideEndpoint]="'PUT_Rx'" (OverrideLoginDet)="OverrideRes($event)"></app-override-log>

