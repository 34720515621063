import { ValidateFormFieldsUtils } from "src/app/utils/validate-form-fields.util";
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import * as moment from "moment";
import { CommonService, AlertService, PreviousRouteService } from "src/app/services";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CollectionView } from "@grapecity/wijmo";
import { MsgConfig } from "src/app";
import { ReportsService } from "src/app/services/reports.service";
import { DomSanitizer } from "@angular/platform-browser";
import { saveAs } from 'file-saver';
import { WijimoUtil } from "src/app/utils";
import { PrintService } from "src/app/services/print.service";
import { AsyncReportEnum } from "src/app/models/AsyncReports.enum";
import { CommonWarnorconfirmPopupComponent } from "../../shared/common-warnorconfirm-popup/common-warnorconfirm-popup.component";
import { CommonStore } from "src/app/store";
import { Subject } from "rxjs";
import { Location } from "@angular/common";
import { takeUntil } from "rxjs/operators";
import { ReportsUtil } from "src/app/utils/reports.util";

@Component({
  selector: 'app-pos-copay-collected',
  templateUrl: './pos-copay-collected.component.html'
})
export class PosCopayCollectedComponent implements OnInit, OnDestroy {

    posCopayCollectedFG: FormGroup;
    @ViewChild("insuPopUp", { static: true })
    insuPopUp: any;
    insuranceData: any;
    wjHeaders: any;
    optedData: any[];
    prevSearchValue: any;
    insuWJ: CollectionView;
    posCopayCollectedReport: Blob;
    pdfContent: any;
    isPrint: boolean;
    showPreview = false;
    copayValEnableDisable = false;
    alreadyReportGenerated: any;
    reportIsInProgress: string;
    unsubscribe$: Subject<void> = new Subject();
    reportAlreadyPrevd: any;
    modelRef: any;

    constructor(private _fb: FormBuilder, private _commonSvc: CommonService, private _sanitizer: DomSanitizer,
        private _modalSvc: NgbModal, private _alertSer: AlertService, private _reportsServ: ReportsService,
        private _prevServ: PreviousRouteService, private _cd: ChangeDetectorRef, private _formUtils: ValidateFormFieldsUtils, private _wijimoUtils: WijimoUtil,
        private _printServ: PrintService,
        private _commonStore: CommonStore,
        private _location: Location,
        private _reportUtil: ReportsUtil,
        private _modalService: NgbModal,
        ) {
        this.posCopayCollectedFG = this._fb.group({
            FromDate: moment().subtract(7, "days").format("MM/DD/YYYY"),
            ToDate: moment().format("MM/DD/YYYY"),
            InsuranceType: null,
            IncludeInsIDs: [],
            ForCopay: false,
            ForCopayLessThan: null,
            SortBy: "Patient"
        });
    }

    ngOnInit() {
        this._reportsServ.IsAsyncReportExist(AsyncReportEnum.posCopayCollected).pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
            if (res) {
                this.reportIsInProgress =  this._reportUtil.getReportIsInProgress(res);
                + " " + MsgConfig.ASYNC_REPORT_HOVEROVER;
            }
        });
        this._commonStore.reportGenerated$.pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
            if (res && res.length) {
                res.map((report) => {
                    if (this._reportUtil.IsCurrentReportGenerated({ curremtReportName: AsyncReportEnum.posCopayCollected, 
                        ReportName: report.ReportName, IsReportGenerated: report.IsReportGenerated, currentRoute: this._location.path() })
                        && this._reportUtil.checkForReportGeneration(report)) {
                                        this.alreadyReportGenerated = report;
                                        this.generateReport();
                                    }
                });
            }
        });
        this.patchDefaultValHeaders();
        this._wijimoUtils.wjSavedData$.pipe(takeUntil(this.unsubscribe$)).subscribe(w => {
            if (w && w["WijmoKey"] && w["WijmoKey"] === "primeescRxPickUpDetInsuWJ") {
                this.patchDefaultValHeaders();
            }
        });
    }

    async patchDefaultValHeaders() {
        const storedWJ = await this._wijimoUtils.getWJSavedData("primeescRxPickUpDetInsuWJ");
        this.wjHeaders = this._wijimoUtils.patchDefHeader("primeescRxPickUpDetInsuWJ", storedWJ);
    }
    generateReport() {
        const payLoad = {
            ReportName: this.alreadyReportGenerated.ReportName,
            AsyncReportId:  this.alreadyReportGenerated.AsyncReportId
        };
        if (this.alreadyReportGenerated.EventKey) {
            this._reportsServ.getAsyncFilters(payLoad).pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
                if (res) {
                    if (res.Filters) {
                        this._alertSer.error(MsgConfig.ASYNC_REPORT_ERROR + this.alreadyReportGenerated.EventKey);
                        this.setDefaultValues(res.Filters);
                        this.resetReport();
                    } if (res.isReportReviewed && !this.modelRef) {
                       this.reportViewedPopup();
                    }
                }
            });
        } else {
        this._reportsServ.getAsyncFilters(payLoad).pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
            if (res) {
                if (res.Filters) {
                this._reportsServ.AsyncReport(payLoad).pipe(takeUntil(this.unsubscribe$)).subscribe(resp => {
                    if (resp && resp.size > 0 && resp.type === "application/ms-excel") {
                        this.posCopayCollectedReport = resp;
                               const blob = new Blob([resp], { type : 'application/ms-excel' });
                                const file = new File([blob], "POS - Copay Collected" + '.xlsx', { type: 'application/ms-excel' });
                               saveAs(file);

                    } else if (resp && resp.size > 0 && resp.type === "application/pdf") {
                        if (this.alreadyReportGenerated.IsPrint) {
                            this.posCopayCollectedReport = resp;
                            this.printPOSCopayCollectedAck();
                        } else {
                        this.showPreview = true;
                        const urlCreator = window.URL;
                        this.pdfContent = this._sanitizer.bypassSecurityTrustResourceUrl(
                            urlCreator.createObjectURL(resp) + "#toolbar=0&zoom=115"
                        );
                        }
                    } else if(!this.modelRef){
                        this.reportViewedPopup();
                    }
                    this.resetReport();
                });
                this.setDefaultValues(res.Filters);

                } if (res.isReportReviewed && !this.modelRef) {
                   this.reportViewedPopup();
                }
            }
        });
    }
    }

    reportViewedPopup() {
        this.reportAlreadyPrevd = true;
        this.modelRef = this._modalService.open(CommonWarnorconfirmPopupComponent, {
            backdrop: false,
            keyboard: false, windowClass: "large--content"
            });
            this.modelRef.componentInstance.warnMsg = "The generated report has been previewed in another system.";
            this.modelRef.componentInstance.IsHeaderText = "Information";
            this.modelRef.componentInstance.okButton = true;
            this.modelRef.componentInstance.IsPopUpClosed.pipe(takeUntil(this.unsubscribe$)).subscribe((response: any) => {
            this.resetReport();
                this.modelRef.close();
                this.modelRef = null;
                this.reportAlreadyPrevd = false;
            });
    }
    resetReport() {
        const reportsData = this._commonStore.reportGenerated$["source"]["value"];
        reportsData.map(report => {
            if (report.ReportName === AsyncReportEnum.posCopayCollected && report.IsReportGenerated) {
                report.IsReportGenerated = false;
                report.AsyncReportId = null;
                report.Count = 0;
                report.EventKey = null;
                report.IsPrint = false;
                report.IsExport = false;
            }
        });
        this.reportIsInProgress = null;
    this._commonStore._reportGenerated$.next(reportsData);
    }
    setDefaultValues(resp?) {
        if (resp) {
            this.posCopayCollectedFG.patchValue({
                FromDate: resp.FromDate,
                ToDate: resp.ToDate,
                InsuranceType: resp.InsuranceType,
                ForCopay: resp.ForCopay,
                ForCopayLessThan: resp.ForCopayLessThan,
                SortBy: resp.SortBy,
            });
        }
    }

    async previewRxPickupAck(val: boolean, IsExport: boolean = false) {
        await this._formUtils.blurAndDelay();
        let resp = "VALID";
        this.isPrint = val;
        // const pickDetailsValue = this.posCopayCollectedFG.getRawValue();

        if (!this.posCopayCollectedFG.value.rxSelected) {
            resp = this._formUtils.checkDateErrors(this.posCopayCollectedFG, "FromDate", "ToDate", 7);
        }
        if (resp === "DATEISSUE") {
            this._alertSer.error(MsgConfig.DATE_RANGE_ERROR);
        } else if (resp === "INVALID") {
            this._alertSer.error(MsgConfig.INVALID_DATES_ERROR);
        } else if (resp === "VALID") {
            this.posCopayCollectedFG.value["IsExport"] = IsExport;
            const selectedInsuCodes = this.posCopayCollectedFG.value["InsuranceType"];
            const dataInfo = this.posCopayCollectedFG.value;
            delete dataInfo['InsuranceType'];
                const reportName = AsyncReportEnum.posCopayCollected;
                this._reportsServ.IsAsyncReportExist(reportName).pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
                    if (res) {
                        this.reportIsInProgress =  this._reportUtil.getReportIsInProgress(res);
                    } else {
                            const payload = {
                                Filters: dataInfo,
                                ReportName: AsyncReportEnum.posCopayCollected,
                                IsNotificationReq: true,
                                IsPrint: this.isPrint,
                                IsExport: IsExport
                            };
                            this._reportsServ.getAsyncReport(payload).toPromise();
                            this.reportIsInProgress =  this._reportUtil.getReportIsInProgress(res);
                        }
                });
        }
    }

    printPOSCopayCollectedAck() {
        const newBlob = new Blob([this.posCopayCollectedReport], { type: "application/pdf" });
        const reader = new FileReader();
        reader.readAsDataURL(newBlob);
        reader.onloadend = function () {
            const base64data = reader.result;
            this._printServ.printPdf(base64data);
        }.bind(this);
    }

    openInsuSearch() {
        this.insuranceData = this._commonSvc.insuranceData$["source"]["value"];
        this.optedData = [];
        this.prevSearchValue = null;
        this.generateInsuWJ(this.insuranceData);
        this._modalSvc.open(this.insuPopUp, { centered: true, size: "lg", backdrop: false });
    }

    generateInsuWJ(data) {
        this.insuWJ = new CollectionView(
            data.map((ins, i) => {
            const j = {};
            j["Ins.Code"] = ins["PlanCode"];
            j["Ins.Name"] = ins["InsuranceName"];
            j["Bin#"] = j["BIN"] = ins.BinNum ? ins.BinNum : "";
            j["Processor Ctrl#"] = ins.ProcessorNum ? ins.ProcessorNum : "";
            j["Pharmacy Id"] = ins.PharmacyNum ? ins.PharmacyNum : "";
            j["Address"] = ins.AddressLine1 ? ins.AddressLine1 : "";
            j["FullAddress"] = ins.AddressLine1 ? ins.AddressLine1  + "," + " " + ins.Address : " ";
            j["Phone No"] = j["Phone#"] = ins.PhoneNumber ? ins.PhoneNumber : "";
            j["RowId"] = ins["PlanCode"] + ins["Id"];
            j["Id"] = ins["Id"];
            if (this.posCopayCollectedFG.value["InsuranceType"] && (this.posCopayCollectedFG.value["InsuranceType"]).trim() !== "") {
                const selectedInsuCodes = (this.posCopayCollectedFG.value["InsuranceType"].toLowerCase()).split(", ");
                if (selectedInsuCodes.indexOf(j["Ins.Code"].toLowerCase()) > -1) {
                    j["IsSelected"] = true;
                    if (this.optedData && this.optedData.length > 0) {
                        if (this.optedData.findIndex(d => d["Ins.Code"] === j["Ins.Code"]) === -1) {
                            this.optedData = this.optedData ? this.optedData : [];
                            this.optedData.push(j);
                        }
                    } else {
                        this.optedData = this.optedData ? this.optedData : [];
                        this.optedData.push(j);
                    }
                } else {
                    if (this.optedData && this.optedData.length > 0
                        && this.optedData.findIndex(d => d["Ins.Code"] === j["Ins.Code"]) > -1) {
                        j["IsSelected"] = true;
                    } else {
                        j["IsSelected"] = false;
                    }
                }
            } else {
                if (this.optedData && this.optedData.length > 0
                    && this.optedData.findIndex(d => d["Ins.Code"] === j["Ins.Code"]) > -1) {
                    j["IsSelected"] = true;
                } else {
                    j["IsSelected"] = false;
                }
            }
            return j;
        })
        );
    }

    fiterInsu(value) {
        if (value === "") {
            value = null;
        }
        if (value !== this.prevSearchValue) {
            if (value && value.trim() !== "") {
                this.prevSearchValue = value;
                const insuranceList = Object.assign([], this.insuranceData);
                const filteredData = insuranceList.filter(v => {
                    let valExists = false;
                    ["PlanCode", "InsuranceName", "BinNum"].forEach(function (key) {
                        const val = v[key] ? v[key] : "";
                        valExists =
                            valExists ||
                            val
                                .toString()
                                .toLowerCase()
                                .startsWith(value.toLocaleLowerCase());
                    });
                    return valExists;
                });
                this.generateInsuWJ(filteredData);
            } else {
                this.prevSearchValue = null;
                this.generateInsuWJ(this.insuranceData);
            }
        }
    }

    addSelectedValue() {
        let codesString = "";
        let InsuIds = [];
        if (this.optedData && this.optedData.length > 0) {
            this.optedData.map((val, i) => {
                codesString = codesString + val["Ins.Code"].toUpperCase() + (i + 1 !== this.optedData.length ? ", " : "");
                InsuIds.push(val.Id);
            });
        }
        this.posCopayCollectedFG.controls["InsuranceType"].patchValue(codesString);
        this.posCopayCollectedFG.controls["IncludeInsIDs"].patchValue(InsuIds);
        this.setFocusOnInsuType();
    }

    setFocusOnInsuType() {
        if (document.getElementById("InsuTypeSearch")) {
            document.getElementById("InsuTypeSearch").focus();
        }
        // this.patchDefaultValHeaders();
    }

    selectCode(item, event) {
        if ((!item["IsSelected"] || (event && event.target.checked))) {
            item["IsSelected"] = !item["IsSelected"];
            this.optedData = this.optedData ? this.optedData : [];
            this.optedData.push(item);
        } else {
            item["IsSelected"] = !item["IsSelected"];
            const index = this.optedData.findIndex(val => val["Id"] === item["Id"]);
            this.optedData.splice(index, 1);
        }
    }

    preventSpaceEvent(event) {
        event.preventDefault();
    }

    forCopyChange(checked) {
        this.copayValEnableDisable = checked;
        if (checked) {
            this.posCopayCollectedFG.controls["ForCopayLessThan"].patchValue(null);
        }
    }

    routeBack() {
        this._prevServ.routeBackToPrevScreen();
    }
    enableDisableCheckBox(event){
        if(event.target.value !== "" && event.target.value >=0) {
            this.posCopayCollectedFG.controls["ForCopay"].patchValue(false);
        }
        const isdisable = (event.target.value !== "" && event.target.value >=0) ? true : false;
        if(document.getElementById("all1") as HTMLInputElement) {
            (document.getElementById("all1") as HTMLInputElement).disabled = isdisable;
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

}
