import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DrugComponent } from "./drug.component";
import { SharedModule } from "../shared/shared.module";
import { DrugTabComponent } from "./drug-tab/drug-tab.component";
import { DrugInfoComponent } from "./drug-info/drug-info.component";
import { DrugCommonHeaderComponent } from "./drug-common-header/drug-common-header.component";
import { DrugNdcoccComponent } from "./drug-common-header/drug-ndcocc/drug-ndcocc.component";
import { DrugSalesSummaryComponent } from "./drug-common-header/drug-sales-summary/drug-sales-summary.component";
import { DrugCopyComponent } from "./drug-common-header/drug-copy/drug-copy.component";
import { DrugRefreshMmsComponent } from "./drug-common-header/drug-refresh-mms/drug-refresh-mms.component";
import { WjChartModule } from "@grapecity/wijmo.angular2.chart";
import { WjGridModule } from "@grapecity/wijmo.angular2.grid";
import { DrugPricingComponent } from "./drug-pricing/drug-pricing.component";
import { DrugInsRestrictionComponent } from "./drug-ins-restriction/drug-ins-restriction.component";
import { DrugBucketPricingComponent } from "./drug-bucket-pricing/drug-bucket-pricing.component";
import { DrugPriceUpdateComponent } from "./drug-price-update/drug-price-update.component";
import { DrugDispHistComponent } from "./drug-disp-hist/drug-disp-hist.component";
import { DrugInventoryComponent } from "./drug-inventory/drug-inventory.component";
import { DrugMiscInfoComponent } from "./drug-misc-info/drug-misc-info.component";
import { PhysicalInventoryComponent } from "./drug-inventory/physical-inventory/physical-inventory.component";
import { DrugCategoryComponent } from "./drug-category/drug-category.component";
import { AngularDualListBoxModule } from "angular-dual-listbox";
import { EditDrugCounsellingComponent } from "./edit-drug-counselling/edit-drug-counselling.component";
import { InventoryService } from "src/app/services";
import { VendorItemCodesComponent } from "./drug-inventory/vendor-item-codes/vendor-item-codes.component";
import { AddDrugToBucketComponent } from "../shared/add-drug-to-bucket/add-drug-to-bucket.component";
import { InventoryTransAuditComponent } from './drug-inventory/inventory-trans-audit/inventory-trans-audit.component';

@NgModule({
    imports: [CommonModule, SharedModule, WjChartModule, WjGridModule, AngularDualListBoxModule],
    entryComponents: [DrugComponent, PhysicalInventoryComponent, AddDrugToBucketComponent],
    declarations: [
        DrugComponent,
        DrugTabComponent,
        DrugInfoComponent,
        DrugCommonHeaderComponent,
        DrugNdcoccComponent,
        DrugSalesSummaryComponent,
        DrugCopyComponent,
        DrugRefreshMmsComponent,
        DrugPricingComponent,
        DrugInsRestrictionComponent,
        DrugBucketPricingComponent,
        DrugPriceUpdateComponent,
        DrugDispHistComponent,
        DrugInventoryComponent,
        DrugMiscInfoComponent,
        PhysicalInventoryComponent,
        DrugCategoryComponent,
        EditDrugCounsellingComponent,
        VendorItemCodesComponent,
        InventoryTransAuditComponent
    ],
    exports: [PhysicalInventoryComponent],
    providers: [InventoryService],
    schemas:  [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA
      ],
})
export class DrugModule {}
