<div class="exprx--common-block">
    <div class="eprx--block__header">
        <div class="row">
            <div class="col">
                <span class="eprx--header__heading">Track Patient</span>
            </div>
        </div>
    </div>

    <div class="eprx--block__content">
        <div class="col-sm-12 padding-0">
            <div class="row">
                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                    <eprx-date-picker [LabelText]="'From Date'" [ControlName]="'LogDtTm'" [FormGroupName]="searchFG" [PlaceHolder]="'From Date'"
                     [MaxDate]="minDate" [IsFirstElem]="true" [AutoFocus]="true">
                    </eprx-date-picker>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                        <eprx-date-picker [LabelText]="'To Date'" [ControlName]="'ToDtTm'" [FormGroupName]="searchFG" [PlaceHolder]="'To Date'"
                         [MaxDate]="minDate">
                        </eprx-date-picker>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                    <eprx-input [LabelText]="'Created Date'" [PlaceHolder]="''" [InputValue]="patientFG?.value?.Person?.CreatedDtTm ? (patientFG?.value?.Person?.CreatedDtTm | date: 'MM/dd/yyyy') : ''" [HasControl]="false"
                        [IsDisabled]="true">
                    </eprx-input>
                </div>
                <div class="col-md-3 margin_bottom_1rem" *ngIf="totalCount">
                    <label>Group By</label>
                    <div class="row" [formGroup]="groupFG">
                        <div class="col-4">
                    <input class="form-check-input" type="checkbox" [id]="'columnGrp1'" value="10"
                        formControlName="columnGrp" (change)="WijimoGroupBy('Column', $event['target']?.checked)" />
                    <label class="form-check-label" [for]="'columnGrp1'" id="columnGrp">
                        Column
                    </label></div>
                    <div class="col-4">
                    <input class="form-check-input" type="checkbox" [id]="'dateGrp1'" value="11"
                        formControlName="dateGrp" (change)="WijimoGroupBy('Date Changed', $event['target']?.checked)" />
                    <label class="form-check-label" [for]="'dateGrp1'" id="drugGrp">
                        Date Changed
                    </label>
                </div>
                <div class="col-4">
                    <input class="form-check-input" type="checkbox" [id]="'userGrp1'" value="12"
                        formControlName="userGrp" (change)="WijimoGroupBy('Changed By', $event['target']?.checked)" />
                    <label class="form-check-label" [for]="'userGrp1'" id="userGrp">
                        Changed By
                    </label>
                </div>
            </div>
                </div>
                <div [ngClass]="totalCount ? 'col-lg-3 col-md-3 col-sm-3 col-xs-3 align-button text-right' : 'col-lg-6 col-md-6 col-sm-6 col-xs-6 align-button text-right'">
                    <button title="Search / Refresh" type="submit" class="hotkey_success pull-right" (click)="getFilteredData()" appShortcutKey [AltKey]="'f'"><span>F</span>SEARCH</button>
                    <button type="button" class="hotkey_success pull-right" (keydown.tab)="focusToFirst($event, 'cancel')"  (click)="clear()" appShortcutKey [AltKey]="'l'"><span>L</span>CLEAR</button>
                </div>
            </div>
        </div>
    </div>
    <div class="eprx--block__content erx--body">
        <wj-flex-grid #trackPatient [isReadOnly]="true" [headersVisibility]="'Column'"
        [itemsSource]="trackPatientWJ" [ngClass]="!isDataExists ? 'no-data':''"
        [selectionMode]="'None'" [allowDragging]="'None'" (loadedRows)="loadedWJRows(trackPatient)">
                    <wj-flex-grid-column [header]="header" [binding]="header" [visible]="true"
                        *ngFor="let header of activeHeaders; let i = index" 
                        [width]="'*'" [format]="''">
                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                            <div>
                                <span [ngbTooltip]="RxNotesToolTip" container="body"  triggers="mouseover:mouseleave"   tooltipClass="rxnotes-tooltip"   *ngIf="((header=== 'Old Value' || header=== 'New Value') && item['Column']?.toLowerCase() == 'notes')"  
                                [innerHTML]="item[actvHeader]"  class="text_transform tooltip_Notes_display"   [innerHTML]="item[header]">
                                    
                                </span>
                                <ng-template #RxNotesToolTip >
                                    <div [innerHTML]="item[header]"></div>
                                </ng-template>
                                <span    *ngIf="((item['Column']?.toLowerCase() !== 'notes') || 
                                        ((item['Column']?.toLowerCase() == 'notes') && !(header=== 'Old Value' || header=== 'New Value')))"  title={{item[header]|uppercase}}   [innerHTML]="item[header]">
                                    
                                </span>
                            </div>
                        </ng-template>
                    </wj-flex-grid-column>
                    <app-no-data-found *ngIf="!isDataExists"></app-no-data-found>
        </wj-flex-grid>
    </div>
</div>
