import { FormGroup } from "@angular/forms";
import {
    Component,
    OnInit,
    Input,
    AfterViewInit,
    SimpleChanges
} from "@angular/core";
import * as moment from "moment";
import { CommonService } from "src/app/services";
import { RegEx } from "src/app/app.regex";
import { CommonUtil } from "src/app/utils";
import { SystemData } from "src/app/models";

@Component({
    selector: "app-more-patient-info",
    templateUrl: "./more-patient-info.component.html"
})
export class PMorePatientInfoComponent implements OnInit, AfterViewInit {

    @Input() patientId: any;
    @Input() morePatFG: FormGroup;
    @Input() morePatInfo: any;
    @Input() focusId: any;
    @Input() patientInfoFG: FormGroup;
    @Input() formGroupInvalid: boolean;
    @Input() systemData: SystemData;
    regex = RegEx;
    prescInputValue: any = "";
    today: any;
    maxDate: any;
    minDate: any;

    constructor(
        private _commonServ: CommonService,
        private _commonUtil: CommonUtil,
    ) {
    }

    ngOnInit() {
        this.today = moment().format("MM/DD/YYYY");
        this.maxDate = {
            year: moment(this.today).year(),
            month: moment(this.today).month() + 1,
            day: moment(this.today).date()
        };
        this.minDate = {
            year: moment(this.today).year(),
            month: moment(this.today).month() + 1,
            day: moment(this.today).date()
        }
        if (this.focusId) {
            const element: any = document.getElementById(this.focusId);
            if (element)
                element.focus();
        }
        if (!(this.morePatInfo && this.morePatInfo["MorePatientInfo"])) {
            this.setDefaultVals();
        } else {
            this.prescInputValue = this.morePatInfo.MorePatientInfo.PrescName;
            this.morePatFG.controls["PrivacyAckDt"].value? this.morePatFG.controls["PrivacyAckDt"].patchValue(moment.utc(this.morePatFG.controls["PrivacyAckDt"].value).local().format("MM/DD/YYYY")) : this.morePatFG.controls["PrivacyAckDt"].patchValue(null);
            this.morePatFG.controls["DriverLicExpDt"].value? this.morePatFG.controls["DriverLicExpDt"].patchValue(moment.utc(this.morePatFG.controls["DriverLicExpDt"].value).local().format("MM/DD/YYYY")) : this.morePatFG.controls["DriverLicExpDt"].patchValue(null);
        }
        if (this.morePatFG && this.morePatFG.controls["ServicePlaceId"] && this.morePatFG.controls["ServicePlaceId"].value) {
            this.morePatFG.patchValue({
                ServicePlaceId: parseInt(this.morePatFG.controls["ServicePlaceId"].value, 10)
            });
        }
        if (this.morePatFG && this.morePatFG.controls["ResidenceCatId"] &&
        !this.morePatFG.controls["ResidenceCatId"].value) {
        this.morePatFG.controls["ResidenceCatId"].patchValue(
            this.patientInfoFG && this.patientInfoFG.value.Facility &&
                this.patientInfoFG.value.Facility.PatResiCatId ?
                 this.patientInfoFG.value.Facility.PatResiCatId : null);
        if ((this.morePatInfo && this.morePatInfo["ResidenceCatogiries"] && this.morePatInfo["ResidenceCatogiries"].length)) {
            const ResidenceName = this.morePatInfo["ResidenceCatogiries"].find(v => v["Id"] === this.morePatFG.value["ResidenceCatId"]);
            this.morePatFG.controls["ResidenceCatName"].patchValue(ResidenceName ? ResidenceName["Name"] : null);
        }
    }
    }
    ngOnChanges(changes: SimpleChanges) {

    }
    ngAfterViewInit() {
        if (this.focusId) {
            const element: any = document.getElementById(this.focusId);
            if (element)
                element.focus();
        } else if (document.getElementById("Delivery")) {
            const data: any = document.getElementById("Delivery").getElementsByTagName("input")[0];
            if (data) {
                data.focus();
            }
        }
    }

    setDefaultVals() {
        this.morePatFG.patchValue({
            PatientId: this.patientId,
            IsDeceased: false,
            IsPrivacyAcked: false,
            PrivacyAckDt: null,
            IsFullyInsured: true,
            DeliveryModeId: Number(this._commonServ.getSetttingValue("RxSettings", "DEF_DELIVERY_METHOD")),
        });
        if (this.morePatInfo && this.morePatInfo["DeliveryModes"] && this.morePatInfo["DeliveryModes"].length
            && this.morePatFG.value["DeliveryModeId"]) {
            const DeliveryName = this.morePatInfo["DeliveryModes"].find(v => v["Id"] === this.morePatFG.value["DeliveryModeId"]);
            this.morePatFG.controls["DeliveryMode"].patchValue(DeliveryName ? DeliveryName["Name"] : null);
        }
        this.prescInputValue = this.morePatFG.value["PrescName"] ?
            this.morePatFG.value["PrescName"] : "";
    }

    selectedPresInfo(val) {
        this.prescInputValue = false;
        if (val && val.prescriberid) {
            this.morePatFG.controls["PrimPrescbId"].setValue(val.prescriberid);
            // setTimeout(() => {
                this.prescInputValue = val.prescriberlastname + (val.prescriberfirstname ? ", " + val.prescriberfirstname : "");
            // }, 0); // commenting out because in track patient we are getting false as changed value as prescInputValue got assigned with false
            this.morePatFG.controls["PrescName"].setValue(this.prescInputValue);
            this.morePatFG.controls["PrimPrescbId"].markAsDirty();
        } else {
            this.morePatFG.controls["PrimPrescbId"].setValue(null);
            this.morePatFG.controls["PrescName"].setValue(this.prescInputValue);
            this.prescInputValue = "";
        }

    }

    focusToFirst(event) {
        if (document.getElementById("SaveButton")) {
            event.preventDefault();
            setTimeout(() => {
                document.getElementById("SaveButton").focus();
            }, 10);

        }
    }

    clearValues() {
        if (this.morePatFG &&  this.morePatFG.controls && this.morePatFG.controls["SpeciesCdId"].value && this.morePatFG.controls["SpeciesCdId"].value === 1) {
            this.morePatFG.patchValue({
                PetOwnerLastName: null,
                PetOwnerFirstName: null,
                PetOwnerDOB: null
            });
        }
    }
    onAckChange(){
        if(!this.morePatFG.value['IsPrivacyAcked']){
            this.morePatFG.controls["PrivacyAckDt"].patchValue(null);
            this.morePatFG.controls["PrivacyAckDt"].setValidators(null);
            this.morePatFG.controls["PrivacyAckDt"].updateValueAndValidity();
        }
    }
}
