<div class="exprx--common-block">
        <div class="eprx--block__header">
            <div class="eprx--header__heading">
                Patient Diagnosis
                <ul class="pull-right eprx--header__icons">
                    <li>
                        <a (click)="openPopUpModal()">
                                 <button class="hotkey_success hotkey_primary--Override" appShortcutKey [AltKey]="'a'"><span>A</span>ADD</button>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="eprx--block__content">
            <div class="box-body table-responsive overflow_y_auto max-height_22_59rem">
                <table class="table table-hover">
                    <thead>
                        <tr>
                            <th>Code</th>
                            <th>Description</th>
                            <th>Type</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let patDiag of patientInfo?.PatientDiagnosis; let i=index">
                            <td>
                                {{patDiag?.DiagnosisId}}
                            </td>
                            <td>
                                {{patDiag?.Description}}
                            </td>
                            <td>ICD10</td>
                            <td>
                                <a>
                                    <i class="far fa-trash-alt danger-actve" aria-hidden="true" (click)="deleteSelectedDiag(patDiag?.DiagnosisId)"></i>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <ng-template #diagnosisList let-c="close" let-d="dismiss">
            <div class="modal-header">
                <h4 class="modal-title">Diagnosis Details</h4>
                <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="closeDiagonisModal()">
                    <span aria-hidden="true" class="close-button">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-4">
                        <eprx-input [LabelText]="'Code'" [RxSelectId]="'Code'"
                        [AutoFocus]="true" [PlaceHolder]="''"
                        (TriggerOnEnterValue)="getCodeFilteredData($event['target'].value)" [HasControl]="false">
                        </eprx-input>
                    </div>
                    <div class="col-md-8">
                        <eprx-input [LabelText]="'Diagnosis Description'" [PlaceHolder]="''"
                        (TriggerOnEnterValue)="getDescriptionFilteredData($event['target'].value)"
                            [HasControl]="false" (keydown.tab)="focusToList($event)">
                        </eprx-input>
                    </div>
                </div>
                <wj-flex-grid #DiagnosList [headersVisibility]="'Column'" [itemsSource]="diagnosisWJ"
                [isReadOnly]="true" [columnPicker]="'row'" [selectionMode]="'Row'"
                (keydown)="onEnterDiag(DiagnosList,$event)"
                (updatedView)="init(DiagnosList)"
                (dblclick)="onRowDblclicked(DiagnosList, $event)"
                (keydown.space)="preventSpaceEvent($event);changeDiognasis(DiagnosList.selectedItems[0],null)">
                <wj-flex-grid-column header="Select" [width]="100">
                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-cell="cell">
                        <span class="text-center">
                            <input class="form-check-input" type="checkbox" [id]="item.RowId" name="" [(ngModel)]="item.IsSelected"
                                [checked]="item.IsSelected" (click)="changeDiognasis(item,$event)" />
                            <label class="form-check-label" [for]="item.RowId">
                            </label>
                        </span>
                    </ng-template>
                </wj-flex-grid-column>
                <wj-flex-grid-column [header]="'Code'" [width]="150" [binding]="'diagnosisId'">
                </wj-flex-grid-column>
                <wj-flex-grid-column [header]="'Diagnosis Description'" [width]="'*'" [binding]="'Diagnosis Description'">
                </wj-flex-grid-column>
                <wj-flex-grid-column [header]="'Type'" [width]="75" [binding]="'Type'">
                </wj-flex-grid-column>
                </wj-flex-grid>
                <app-eprx-pagination [TotalCount]="totalCount" [PageSize]="pageSize" [WijmoName]="DiagnosList" [GridName]="'bucketPricing'" (OnPageSelected)="setPage($event)"
                (OnSizeSelected)="setPageSize(pageSize)" [FromModal]="true" (OnFocusOut)="focusToFirst($event)"></app-eprx-pagination>
            </div>
            <div class="modal-footer">
                <button type="button" class="hotkey_success" (click)="addSelectdItems()" id="diogAddBtn"  appShortcutKey [AltKey]="'a'"><span>A</span> Add</button>
                <button type="button" (keydown.tab)="focusToFirst($event)" id="buttononpop" class="hotkey_success" (click)="closeDiagonisModal()" appShortcutKey [AltKey]="'o'"><span>O</span> Ok</button>
            </div>
        </ng-template>
