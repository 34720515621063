import { NgModule } from "@angular/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { WjCoreModule } from "@grapecity/wijmo.angular2.core";
import { WjInputModule } from "@grapecity/wijmo.angular2.input";
import { WjGridModule } from "@grapecity/wijmo.angular2.grid";
import { SharedModule } from "../shared/shared.module";
import { PrescriberComponent } from "./prescriber.component";
import {
    PrescInfoComponent,
    PrescTabsComponent,
    PrescHistoryComponent,
    PrescSearchComponent
} from ".";
import { NotesLogService } from "src/app/services";
import { PrivMaskGuard, PrescriberNotesGuard } from "src/app/guards";
import { PrescriberMMSComponent } from "./prescriber-mms/prescriber-mms.component";
import { NgxMaskModule } from "ngx-mask";

@NgModule({
    imports: [
        NgbModule,
        WjCoreModule,
        WjInputModule,
        WjGridModule,
        SharedModule,
        NgxMaskModule.forRoot()
    ],

    declarations: [
        PrescriberComponent,
        PrescInfoComponent,
        PrescTabsComponent,
        PrescHistoryComponent,
        PrescSearchComponent,
        PrescriberMMSComponent
    ],
    exports: [PrescriberComponent, PrescSearchComponent],
    entryComponents: [PrescriberComponent],
    providers: [NotesLogService, PrivMaskGuard, PrescriberNotesGuard]
})
export class PrescriberModule {}
