import {
    Component,
    OnInit,
    Input,
    ViewChild,
    EventEmitter,
    Output,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { DrugTabUtils } from "src/app/utils/drug-tabs.utils";
import { Vendordruginfo } from "src/app/models";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as wjcGrid from "@grapecity/wijmo.grid";
import { MsgConfig } from "src/app";
import { AlertService, EditDrugService } from "src/app/services";
import { CommonUtil, WijimoUtil } from "src/app/utils";
import { DrugBucketUtil } from "src/app/utils/drug-bucket.util";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FormatsUtil } from "src/app/utils/formats.util";

@Component({
    selector: "app-vendor-item-codes",
    templateUrl: "./vendor-item-codes.component.html",
})
export class VendorItemCodesComponent implements OnInit {
    @Input() drugId: number;
    @Input() drugFG: FormGroup;
    @Input() dropDownValues: any;
    @Input() openForAdd: boolean;
    @Input() bucketINVFG: FormGroup;
    @Output() IspopUpClosed = new EventEmitter();
    vendorItemData: { result: Vendordruginfo[]; itemSWJ: any } = {
        result: null,
        itemSWJ: null,
    };
    wjHeaders: any;
    @ViewChild("VendorItem", { static: true })
    VendorItemPopup: any;
    @ViewChild("vendoritem", { static: true })
    vendoritem: any;
    @ViewChild("warnVendorExist", { static: true })
    warnVendorExist: any;
    modelRef: any;
    activeModal: any;
    vendorItemFG: FormGroup;
    vendorFgInvalid: boolean;
    popUpmodelRef: any;
    vendorDeleteId: any;
    selectedVendor: any;
    selectVendList: any;
    isDataExists = true;
    unsubscribe$: Subject<void> = new Subject();
    warningText = {
        deleteHeaderTxt: null,
        deleteConfrmMsg: null
    }

    constructor(
        private _drugTabUtil: DrugTabUtils,
        private _modalSvc: NgbModal,
        private _alrtSvc: AlertService,
        private _comnUtil: CommonUtil,
        private _editDrugSvc: EditDrugService,
        private _wijimoUtils: WijimoUtil,
        private _drugBuckUtil: DrugBucketUtil,
        public _formatsUtil:FormatsUtil
    ) {}

    ngOnInit() {
        if (this.openForAdd) {
            this.openVendorItemModal("VAdd", this.vendoritem, null);
        } else {
            this.openVendorItemOpen();

            this.patchDefaultValHeaders();
            this._wijimoUtils.wjSavedData$.pipe(takeUntil(this.unsubscribe$)).subscribe((w) => {
                if (
                    w &&
                    w["WijmoKey"] &&
                    w["WijmoKey"] === "drugInvVendorListWJ"
                ) {
                    this.patchDefaultValHeaders();
                }
            });
        }
        this.warningText.deleteHeaderTxt = MsgConfig.Delete_Header_Text;
        this.warningText.deleteConfrmMsg = MsgConfig.Delete_Confirmation;
    }

    async getVendorItemCodes() {
        this.vendorItemData = await this._drugTabUtil.getDrugVendors(
            this.drugId
        );
        if (this.vendorItemData && this.vendorItemData['result'] && this.vendorItemData['result'].length) {
            this.isDataExists = true;
        } else {
            this.isDataExists = false;
        }
    }
    async openVendorItemOpen() {
        await this.getVendorItemCodes();
        this.popUpmodelRef = this._modalSvc.open(this.VendorItemPopup, {
            centered: true,
            backdrop: false,
            keyboard: false,
            windowClass :"max-modal-1350"
        });
    }

    async patchDefaultValHeaders() {
        const storedWJ = await this._wijimoUtils.getWJSavedData(
            "drugInvVendorListWJ"
        );
        this.wjHeaders = this._wijimoUtils.patchDefHeader(
            "drugInvVendorListWJ",
            storedWJ
        );
    }

    async openVendorItemModal(type, content, item?: any) {
        this.activeModal = type;
        if (type === "VAdd") {
            this.vendorItemFG = this._drugTabUtil.createVendorItemFG();

            if (this.openForAdd) {
                this.selectVendList = this.dropDownValues && this.dropDownValues.Vendor?.length ? this.dropDownValues.Vendor.find((item: any) => {
                    return item.ContractId === this.drugFG.value.DrugPO.ContractId
                }) : null;
                this.vendorItemFG.controls["ContractId"].patchValue(this.selectVendList ? this.selectVendList.ContractId : null);
                this.vendorItemFG.controls["VendorId"].patchValue(
                    this.selectVendList ? this.selectVendList.Id : null
                );
                this.vendorItemFG.controls["AWP"].patchValue(
                    this.drugFG.value.Drug.AWPPack
                );
                this.vendorItemFG.controls["UnitAWP"].patchValue(
                    this.drugFG.value.Drug.UnitPriceAWP
                );
                this.vendorItemFG.controls["UnitCost"].patchValue(
                    this.bucketINVFG.value.UnitPriceCost.toFixed(3)
                );
                this.vendorItemFG.controls["PackCost"].patchValue(
                    this.bucketINVFG.value.CostPack.toFixed(3)
                );
            }
        } else {
            const selectedData = this.vendorItemData["result"].find(
                (val) => val.Id === item.Id
            );
            this.vendorItemFG =
                this._drugTabUtil.patchVendorItems(selectedData);
        }
        this.modelRef = this._modalSvc.open(content, {
            centered: true,
            backdrop: false,
            size: "lg",
            keyboard: false,
        });
    }

    convertNumberFormat(control, type) {
        if (this.vendorItemFG.controls[control].valid) {
            if (
                Number(this.vendorItemFG.value[control]) === 0 ||
                !this.vendorItemFG.value[control] ||
                Number(this.drugFG.value.Drug.QtyPack) === 0
            ) {
                this.vendorItemFG.controls[control].patchValue(
                    (0).toFixed(type)
                );
            }
            if (control === "UnitCost" || control === "UnitAWP") {
                this.vendorItemFG.controls[control].patchValue(
                    parseFloat(this.vendorItemFG.value[control]).toFixed(type)
                );
                if (control === "UnitCost") {
                    this.vendorItemFG.controls["PackCost"].patchValue(
                        (
                            this.vendorItemFG.value[control] *
                            this.drugFG.value.Drug.QtyPack
                        ).toFixed(2)
                    );
                } else {
                    this.vendorItemFG.controls["AWP"].patchValue(
                        (
                            this.vendorItemFG.value[control] *
                            this.drugFG.value.Drug.QtyPack
                        ).toFixed(2)
                    );
                }
            } else if (control === "AWP" || control === "PackCost") {
                this.vendorItemFG.controls[control].patchValue(
                    parseFloat(this.vendorItemFG.value[control]).toFixed(type)
                );
                if (control === "AWP") {
                    this.vendorItemFG.controls["UnitAWP"].patchValue(
                        (
                            this.vendorItemFG.value[control] /
                            this.drugFG.value.Drug.QtyPack
                        ).toFixed(3)
                    );
                } else {
                    this.vendorItemFG.controls["UnitCost"].patchValue(
                        (
                            this.vendorItemFG.value[control] /
                            this.drugFG.value.Drug.QtyPack
                        ).toFixed(3)
                    );
                }
            }
        }
    }

    async checkVendorDuplicate() {
        const data = {
            Id: this.vendorItemFG.value["Id"],
            DrugId: this.drugId,
            VendorId: this.vendorItemFG.value["VendorId"],
        };
        const resp = await this._editDrugSvc
            .checkVendorExists(data)
            .toPromise();
        if (resp) {
            this.vendorItemFG.controls["VendorId"].patchValue(null);
            this._modalSvc.open(this.warnVendorExist, {
                backdrop: false,
                centered: true,
                windowClass: "large--content",
            });
        }
    }

    async saveVendorItems() {
        if (this.vendorItemFG.valid) {
            this.vendorFgInvalid = false;
            this.selectVendList = this.dropDownValues && this.dropDownValues.Vendor?.length ? this.dropDownValues.Vendor.find((item: any) => {
                return item.ContractId === this.vendorItemFG.controls["ContractId"].value
            }) : null;
            this.vendorItemFG.controls["VendorId"].patchValue(this.selectVendList ? this.selectVendList.Id : null);
            this.vendorItemFG.controls["DrugId"].patchValue(this.drugId);
            const result = await this._drugTabUtil.addOrEditVendorItems(
                this.activeModal,
                this.vendorItemFG.value
            );
            result === 1
                ? (this.activeModal==='VAdd'? this._alrtSvc.success(MsgConfig.SAVE_SUCCESS) :  this._alrtSvc.success(MsgConfig.UPDATE_SUCCESS))
                : this._alrtSvc.error(MsgConfig.SAVE_FAIL);
            if (this.modelRef) {
                this.modelRef.close();
                this.modelRef = null;
            }
            this.activeModal = null;
            this.getVendorItemCodes();
            if (this.openForAdd) {
                this.directClose("save");
            }
        } else {
            this.vendorFgInvalid = true;
        }
    }

    deleteWarnPopUp(content, Id) {
        this.vendorDeleteId = Id;
        this.modelRef = this._modalSvc.open(content, {
            centered: true,
            backdrop: false,
            windowClass: "large--content",
        });
    }

    async deleteVendorDrugInfo() {
        const response = await this._drugTabUtil.deleteVendor(
            this.vendorDeleteId
        );
        if (response) {
            this._alrtSvc.success(MsgConfig.DELETE_SUCCESS);
            this.getVendorItemCodes();
        } else {
            this._alrtSvc.error(MsgConfig.DELETE_FAIL);
        }
    }

    discontVendorItem(item: any, content) {
        this.selectedVendor = item;
        this.modelRef = this._modalSvc.open(content, {
            centered: true,
            backdrop: false,
            windowClass: "large--content",
        });
    }

    async vendorDisContinue() {
        const response = await this._drugTabUtil.discVendor(
            this.selectedVendor
        );
        if (response) {
            this.getVendorItemCodes();
        }
    }

    closeVendorModal() {
        if (this.popUpmodelRef) {
            this.popUpmodelRef.close();
            this.popUpmodelRef = null;
        }
        this.IspopUpClosed.emit(null);
    }

    vendorInit(flex: wjcGrid.FlexGrid) {
        flex.columnHeaders.rows[0].wordWrap = true;
        // flex.focus();
    }

    setFocusById($event, Id, type) {
        this._comnUtil.focusById(Id, $event, type);
    }

    directClose(val?) {
        this.IspopUpClosed.emit(val ? val : null);
    }
    ngOnDestroy(){
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
