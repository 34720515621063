import { Component, NgZone, OnInit } from "@angular/core";
import { PrimeEscService } from "src/app/services/prime-esc.service";
import * as wjcCore from "@grapecity/wijmo";
import * as wjcGrid from "@grapecity/wijmo.grid"
import { CommonService, AlertService } from "src/app/services";
import { WijimoUtil } from "src/app/utils";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-sign-by-rx",
  templateUrl: "./sign-by-rx.component.html"
})
export class SignByRxComponent implements OnInit {
    public focus: string;
    rxPatientModel: any;
    ackInfoWJ: wjcCore.CollectionView;
    RxSignData: any;
    histInfoWJ: wjcCore.CollectionView;
    showPatientSign = false;
    signatureData: any;
    sigType: any;
    signId: any;
    rxNumber: String;
    wjHeadersAck: any;
    wjHeadersHist: any;
    RPH: any;
    unsubscribe$: Subject<void> = new Subject();


    constructor(private _primeEscSer: PrimeEscService,
         private _ngZone: NgZone, private _commonServ: CommonService,
    private _alertServ:AlertService, private _wijimoUtils: WijimoUtil) { }

    ngOnInit() {
        this.focus = "Rx Number#";
        this.patchDefaultValHeaders();
        this._wijimoUtils.wjSavedData$.pipe(takeUntil(this.unsubscribe$)).subscribe(w => {
            if (w && w["WijmoKey"] && (w["WijmoKey"] === "primeescSignByRxAcknListWJ" || w["WijmoKey"] === "primeescSignByRxHistListWJ" )) {
                this.patchDefaultValHeaders();
            }
        });
    }

    async patchDefaultValHeaders() {
        const storedWJAck = await this._wijimoUtils.getWJSavedData("primeescSignByRxAcknListWJ");
        this.wjHeadersAck = this._wijimoUtils.patchDefHeader("primeescSignByRxAcknListWJ", storedWJAck);
        const storedWJHist = await this._wijimoUtils.getWJSavedData("primeescSignByRxHistListWJ");
        this.wjHeadersHist = this._wijimoUtils.patchDefHeader("primeescSignByRxHistListWJ", storedWJHist);
    }

    searchWithRx() {
        this._primeEscSer.getSignByRx(this.rxNumber).pipe(takeUntil(this.unsubscribe$)).subscribe(resp => {
            if (resp) {
                this.RxSignData = resp;
                this.generateAckInfoListWJ(resp.PrivacyAcknowledge);
                this.generateHistInfoListWJ(resp.InsSignatureTrans);
                if (resp.InsSignatureTrans && resp.InsSignatureTrans.length > 0) {
                    this.getPatientDetails(resp.InsSignatureTrans[0].PatientId);
                }
            } else {
                this._alertServ.error("No records found with given Rx number");
            }
            this.rxNumber = null;
        });
    }

    getPatientDetails(patientId) {
        if (patientId) {
            this._commonServ.getRxPatientDetailsById(patientId).pipe(takeUntil(this.unsubscribe$)).subscribe(resp => {
                if (resp) {
                    this.rxPatientModel = resp;
                }
            });
        }
    }

    selectedPatientInfo(patientInfo: any) {
        this.rxPatientModel = patientInfo;
    }

    clearFilters() {
        this.generateAckInfoListWJ([]);
        this.generateHistInfoListWJ([]);
        this.rxPatientModel = null;
        this.RxSignData = null;
        this.rxNumber = null;
        // this.patchDefaultValHeaders();
    }


    generateAckInfoListWJ(ackInfoList: any[]) {
        if (ackInfoList && ackInfoList.length > 0) {
            this.ackInfoWJ = new wjcCore.CollectionView(
                ackInfoList.map((acknowldge, i) => {
                    const j = {};
                    j["Action"] = "";
                    j["Trans No"] = j["Trans#"] = acknowldge.Id;
                    j["Date"] = acknowldge.DateSigned;
                    j["Accept"] = acknowldge.PatAccept;
                    j["Event Type"] = acknowldge.Zip;
                    return j;
                }));
        } else {
            this.ackInfoWJ = null;
        }

    }

    generateHistInfoListWJ(histInfoList: any[]) {
        if (histInfoList && histInfoList.length > 0) {
            this.histInfoWJ = new wjcCore.CollectionView(
                histInfoList.map((historyy, i) => {
                    const j = {};
                    j["Action"] = "";
                    j["Trans No"] =  j["Trans#"] = historyy.Id;
                    j["Pickup Date"] = historyy.CreatedDtTm;
                    j["Ins"] = historyy.InsType;
                    j["Couns?"] = historyy.CounselingReq;
                    j["Rx#"] = historyy.PrescNum ;
                    j["Ref#"] = historyy.ReFillNum  ;
                    j["Fill#"] = historyy["PartialFillNo"] ;
                    j["RPH"] = historyy["RPH"];
                    return j;
                })
            );
        } else {
            this.histInfoWJ = null;
        }

    }

    openSignModal(item, isAck) {
        this._primeEscSer.getPatAckSign(item["Trans No"], isAck).pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp) {
                    this.signatureData = resp;
                    this.sigType = isAck;
                    this.signId = item["Trans No"];
                    this.RPH = item["RPH"];
                    this._ngZone.run(() => {
                        this.showPatientSign = true;
                    });
                }
            });
    }

    focusToFirst(event) {
        if (document.getElementById("clearButton")) {
            event.preventDefault();
            // setTimeout(() => {
                document.getElementById("clearButton").focus();
            // }, 10);
        }
        }
        ngOnDestroy() {
            this.unsubscribe$.next();
            this.unsubscribe$.complete();
        }
}
