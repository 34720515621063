import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';  
import { IdentifierTypeEnum } from 'src/app/models/messaging.enum';
import { MessageService } from 'src/app/services/message.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from "../../../../environments/environment";

@Injectable({
    providedIn: 'root',
})
export class UnreadCountService implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject<void>();
    private unreadCountSubject = new BehaviorSubject<number>(0);
    unreadCount$ = this.unreadCountSubject.asObservable(); 
    isSecurePatientComm:boolean;
    constructor(
        private _userService: UserService,
        private _msgServ: MessageService) 
        { }
    ngOnInit() { 
        this.getUnreadCounts();
    }
    getUnreadCounts(): void { 
        try { 
            const npi = this._userService.getToken('UniquePharmacyId');
            const payload = {
            Recipient: {
                Identifier: npi,
                IdentifierType: IdentifierTypeEnum.NPI,
                },
            };

            this._msgServ
            .postMessageUnreadCount(payload)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (res: { unreadCount: number }) => {
                    const unreadCount = res?.unreadCount || 0;
                    this.unreadCountSubject.next(unreadCount);
                },
                error: (err) => {
                    console.error('Error fetching unread message count:', err);
                    this.unreadCountSubject.next(0);
                },
            }); 
        } catch (error) {
            console.error('Error fetching unread message count:', error);
            this.unreadCountSubject.next(0);
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
