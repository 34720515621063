import {
    Component,
    OnInit,
    Input,
    ViewChild,
    ChangeDetectorRef,
    OnDestroy,
    Output,
    EventEmitter,
    OnChanges,
    HostListener
} from "@angular/core";
import { RxPatientModel, EPatient, PatientInsuList } from "../../../models";
import { FormGroup } from "@angular/forms";
import { CollectionView } from "@grapecity/wijmo";
import { NgbModalConfig, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CommonService } from "src/app/services";
import * as _ from "lodash";

@Component({
    selector: "app-bill-to",
    templateUrl: "./bill-to.component.html",
    styles: []
})
export class BillToComponent implements OnInit, OnDestroy, OnChanges {
    rxPatientModel: EPatient;
    rxFG: FormGroup;
    insurncListModified: any;
    modelRef: any;
    insureList: any;
    patInsurList: any;

    @Input()
    set RxPatientInfo(data: EPatient) {
        this.rxPatientModel = data;
    }

    @Input()
    set RxFG(fg: FormGroup) {
        this.rxFG = fg;
    }

    @Output()
    IsPopUpClosed = new EventEmitter<null>();

    @Input()
    set PatInsurList(pil: any) {
        this.patInsurList = pil;
    }

    @ViewChild("BILLTO", { static: true })
    BILLTO: any;

    @HostListener("window:keydown", ["$event"])
    keyEvent(event: KeyboardEvent) {
        if (event.which === 27 || event.which === 89 || event.which === 78) {
            event.preventDefault();
            this.closeModal();
        }
    }

    constructor(
        config: NgbModalConfig,
        private _modalService: NgbModal,
        private _cdr: ChangeDetectorRef,
        private _commonServ: CommonService
    ) {
        config.backdrop = "static";
    }

    ngOnInit() {
        // this.openBillToPopupModal();
    }

    ngOnChanges(changes: any): void {
        if (changes.PatInsurList.currentValue && changes.PatInsurList.currentValue) {
            this.openBillToPopupModal();
        }
    }

    ngOnDestroy() {
        this._cdr.detach();
    }

    openBillToPopupModal() {
        this.insureList = _.clone(this.patInsurList);
        this.insureList.splice(0, 1);
        this.insurncListModified = new CollectionView(this.insureList);
        this.modelRef = this._modalService.open(this.BILLTO, {
            size: "lg",
            keyboard:false,
        });
        this._cdr.detectChanges();
    }

    selectedInsurence(isChecked: boolean, checkedVal: any) {
        if (isChecked) {
            const rxRefilInsu: FormGroup = this.rxFG.controls
                .RefillInsu as FormGroup;
            rxRefilInsu.controls["InsuId"].setValue(checkedVal);
        }
    }

    closeModal() {
        if (this.modelRef) {
        this.modelRef.close();
        }
        this.IsPopUpClosed.emit(null);
    }
}
