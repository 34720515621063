// Add warning or error or success messages here
export const MsgConfig = {
    SOMETHING_WENT_WRONG: "Something went wrong. Please contact system admin.",
    INVALID_NAME_PASSW: "Please enter valid credentials.",
    LOGOUT: "You are logged out of the application. Please login to continue.",
    SAVE_SUCCESS: "Saved successfully.",
    SAVE_FAIL: "Save unsuccessful.",
    NO_DATA: "No data is available in the table to save.",
    UNABLE_TO_DEL:"Unable to delete the item.",
    UPDATE_SUCCESS: "Updated successfully.",
    UPDATE_FAIL: "Update unsuccessful.",
    DELETE_SUCCESS: "Deleted successfully.",
    REMOVE_SUCCESS: "Removed successfully.",
    DELETE_FAIL: "Delete unsuccessful.",
    REMOVE_FAIL: "Remove unsuccessful.",
    PHY_INVENTORY_PROCESS_SUCCESS:"Physical Inventory processed successfully.",
    PHY_INVENTORY_PROCESS_FAIL:"Physical Inventory processing failed.",
    PHY_INVENTORY_ADDED_SUCESS:"Physical Inventory added successfully.",
    PHY_INVENTORY_Updated_SUCESS:"Physical Inventory updated successfully.",
    INVALID_ADMIN_USER: "Logged-in user is not an Admin.",
    PASSWORD_RESET_PREVIOUS: "New password must be different from the previous 3 passwords.",
    INVALID_PWD_FORMAT: "Password didn't match the format.",
    PHARMICIST_ACCOUNT_LOCKED: "Pharmacist account is locked due to multiple failed attempts. Please contact Admin to reset your password.",
    TECHNICIAN_ACCOUNT_LOCKED: "Technician account is locked due to multiple failed attempts. Please contact Admin to reset your password.",
    MANDATORY_FIELDS: "Please fill all mandatory fields.",
    RECALL_DELETED_RX_SUCCESS: "Recall Rx successful.",
    RECALL_DELETED_RX_FAIL: "Recall Rx failed.",
    RECALL_DELETED_RX_EXIST: "Refill cannot be recalled as it is already in use.",
    NO_PRIVS_ACCESS: "You do not have privilege to perform this action.",
    MEDGUIDE_NOT_AVAILABLE: "No medguide available to print!!",
    CREATE_NEW_TENAT: "New Tenant created successfully.",
    ERX_REQUEST_SUCCESS: "eRx request sent successfully.",
    ERX_REQUEST_SUBMITTED: "eRx request submitted successfully",
    ERX_CHANGE_REQ_SUCCESS: "eRx Change request sent successfully.",
    ERX_CHANGE_REQ_SUBMITTED: "eRx Change request submitted successfully.",
    ERX_REQUEST_ERROR: "Error while sending eRx request.",
    PAT_PRE_DRUG_CHECK_PAT_CREATE_FAIL: "Patient save unsuccessful.",
    PAT_PRE_DRUG_CHECK_PAT_CREATE_CANCEL: "Patient add Cancelled.",
    PAT_PRE_DRUG_CHECK_PRE_CREATE_CANCEL: "Prescriber add Cancelled.",
    PAT_PRE_DRUG_CHECK_PRE_CREATE_SUCCESS: "Prescriber saved successfully.",
    PAT_PRE_DRUG_CHECK_PRE_CREATE_FAIL: "Prescriber save unsuccessful",
    PAT_PRE_DRUG_CHECK_PRE_INVALID_INSU: "Invalid insurance restriction value..!!",
    PAT_PRE_DRUG_CHECK_DRUG_DUP_NDC: "This drug already exist in the system. Duplicate NDC code.",
    PAT_PRE_DRUG_CHECK_DRUG_DUP_QUICK: "Duplicate quick code.",
    PAT_PRE_DRUG_CHECK_DRUG_CREATE_SUCCESS: "Drug saved successfully.",
    PAT_PRE_DRUG_CHECK_DRUG_CREATE_FAIL: "Drug save unsuccessful.",
    PAT_PRE_DRUG_CHECK_NEWRX_GENER_FAIL: "Rx number generation failed.",
    COPIED_SUCCESS: "Copied successfully.",
    COPIED_UNSUCCESS: "Copy unsuccessful.",
    WRONG_MSG: "Unfortunately, there was a technical error while processing your request. Please try again. If the problem persists, please contact the support team.",
    INVALID_INSURANCE_REST: "Invalid insurance restriction value.",
    PATIENT_ALLERGY_EXISTS: "This allergy has already been assigned to the patient.",
    PRESCRIBER_SPECIALITY_EXISTS: "Prescriber specialities must be unique.",
    DUPLICATE_NCDP_PAYER_SET: "NCPDP PayerSet Id with this transaction type already exists.",
    ENTER_REQ_FIELD: "Please enter required fields.",
    PRICE_CODE_ALREADY: "Price code already exists.",
    DISCOUNT_CODE_ALREADY: "Discount code already exists.",
    PRICE_NAME_ALREADY: "Price Schedule name already exists.",
    DATE_RANGE_ERROR: "To date must be later than From date.",
    INVALID_DATES_ERROR: "Please enter Valid date.",
    TO_FROM_DATES_MISSING : "Please fill both From Date and To Date.",
    INSURANCE_PATIENT_USE: "Cannot delete the insurance as it is already assigned to another patient.",
    TRANSTYPE_NAME_EXISTS: "Type name already exists.",
    TRANSTYPE_ALREADY_IN_USE: "Trans type cannot be deleted as it is already in use.",
    VENDOR_CODE_EXISTS: "Vendor Id already exists.",
    BUCKET_CODE_EXISTS: "Bucket Code already exists.",
    BUCKET_NAME_EXISTS: "Bucket Name already exists.",
    BUCKET_BOTH_EXISTS: "Bucket Name and Code already exists.",
    FACILITY_CODE_EXISTS: "Facility code already exists.",
    PATIENT_CATEGORY_CODE_EXISTS: "Category Code already exists.",
    PRESCRIBER_SPECIALITY_CODE_EXISTS: "Speciality Code already exists.",
    PATIENT_ALREADY_IN_USE: "Cannot delete the Patient as it is already assigned to Rx.",
    PRESCRIBER_ALREADY_IN_USE: "Cannot delete the Prescriber as it is already assigned to Rx.",
    DRUG_ALREADY_IN_USE: "Cannot delete the Drug as it is already assigned to Rx.",
    REPORT_GENERATION_ERROR: "Error while generating report.",
    DOCUMENTS_SAVED_SUCCESS: "Documents save successful.",
    DOCUMENTS_FAILED_SUCCESS: "Documents save unsuccessful.",
    OTHER_AMT_SAVE_TEMP: "Other amount information added. Please click Rx save.",
    FILED: "Rx Filled and saved successfully.",
    RX_SAVE: "Rx saved successfully.",
    TRANSMIT_SUCCESS: "Rx saved and Transmitted successfully.",
    MULTIPLE_USER_SAVE: "The selected Rx is currently in use by a different user on the same tenant.",
    MULTIPLE_USER_SAVE_MSG: "Unable to save Rx, as the selected Rx is currently in use by a different user on the same tenant.",
    VENDOR_ALREADY_IN_USE: "Cannot delete the Vendor as it is already in use.",
    RphVerifySuccess: "Marked Rxs are successfully verified.",
    MSG_RESENT_SUCCESS: "Message Resent successfully.",
    MSG_SENT_SUCCESS: "Message sent successfully.",
    Cancel_MSG_SUCCESS: "Sending message Cancelled successfully",
    REFILL_DUE_MSG_SUCCESS: "Refill Due Message sent successfully",
    REPORT_GENERATED_SUCCESS: "Rx By Pharmacist Report is generated successfully.",
    ASYNC_REPORT_ERROR: "The action you were trying to perform was not successful due to a technical issue on our servers. Our engineers have been informed of this issue and will work to resolve this. If you need immediate assistance with this issue, you can contact the MicroMerchant Systems support team with the following event key:",
    ADD_ATLEAST_ONE_RECORD: "Please add atleast one field.",
    ASYNC_REPORT_HOVEROVER: "is in progress, once the report is generated you can continue working on this report.",
    DATE_FORMAT:"MM/DD/YYYY",
    DATE_FORMAT_WITH_EIPHEN:"YYYY-MM-DD",
    ALREADY_EXIST:"already exists.",
    isRejectedEligiblity:"We ran the automated Eligibility Checks with Troop and Commercial but did not find any results. If you'd like to perform additional search (Troop) by entering the Medicare ID/Policy#, please enter the same and re-transmit.",
    isCashRejectedEligiblity:"We ran the automated Eligibility Checks with Commercial but did not find any results. If you'd like to perform additional search by entering the Medicare ID/Policy#, please enter the same and re-transmit.",
    isApprovedEligiblity:"We ran the automated Eligibility Checks and updated the Insurance information results respectively.",
    TITLE_SUCCESS: "Title saved successfully.",
    Warn_Text_Header :"Warning!!",
    No_DataDisplay_Text: "No data to display!",
    Delete_Confirmation :"Are you sure you want to delete?",
    Delete_Header_Text :"Confirmation",
    Unselect_Warn :"Please select at least one record to process.",
    UnSelectFor_MultiSelect:"Please select only one record to process.",
    HOA_SAVE: "HOA configured Successfully",
    HOA_SELECT: "HOA not configured for the selected Rx's",
    USER_EMAIL_EXIST: "The User Email entered is already associated with another User",
    No_MedGuide_Print : "This setting is not applicable for Transfer In/Out and Deferred Rxs.",
    DRUG_ALREADY_ADDED : "The Selected Drug is already added, Please Select another Drug.",
    PRESCRIBER_ALREADY_ADDED : "The Selected Prescriber is already added, Please Select another Prescirber",
    PATIENT_ALREADY_ADDED : "The Selected Patient is already added, Please Select another Patient",
    PATIENT_ALLERGY_NOTEXISTS : "Please select atleast one Allergy.",
    PATIENT_ALLERGY_REMOVEALL : "First remove all the allergy in List",
    DEFAULT_NO_DATA_FOUND_WARN : "No Data Found!",
    Authorization_Required: " authorization is required.<br> Please enter credentials for authorization.", 
    EMPTY_Data: "--",
    DOT_Three_Fixed: "0.000",
    No_Known_Allergies : "No Known Allergies",
    YES_Display : "YES",
    NO_Display : "NO",
    Inactive_Patient: "Inactive Patient",
    Inactive_Drug: "Inactive Drug",
    Information_msg: "Information",
    Notes_Saved: "Notes saved successfully.",
    Immunization_Success: "Immunization files successfully submitted to the state.",
    Immunization_Error: "Failed to submit immunization file.",
    EnterMsg: 'Enter Message',
    PleaseEnterMsg: 'Please enter a message',
    NO_Internet: "No internet connection.",
    Today:"Today",
    Yesterday:"Yesterday",
    Transfer_In_Req: " Rx Transfer Initiation is sent successfully."
};
