import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from "@angular/core";
import { WjInputModule } from "@grapecity/wijmo.angular2.input";
import { WjGridModule } from "@grapecity/wijmo.angular2.grid";
import { ErxRoutingModule } from "./erx-routing.module";
import { ErxComponent, ErxListingComponent, ErxFilterComponent } from ".";
import {
    ErxService,
    NotesLogService,
    RphVerificationService,
    PatientService,
    DrugService
} from "../../services";
import { CustFormElemnsModule } from "../cust-form-elemns/cust-form-elemns.module";
import { AppTranslateChildModule } from "../../app.translate.child.module";
import { NgbTooltipModule, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { SharedModule } from "../shared/shared.module";
import { WjCoreModule } from "@grapecity/wijmo.angular2.core";
import { NgxMaskModule } from "ngx-mask";
import { ErxToNewrxCheckComponent } from "./erx-to-newrx-check/erx-to-newrx-check.component";
import { PatientModule } from "../patient/patient.module";
import { PrescriberModule } from "../prescriber/prescriber.module";
import { DrugModule } from "../drug/drug.module";
import { NotesLogComponent } from "../shared";
import { ErxActionListGuard } from "src/app/guards";
import { ErxNewRequestComponent } from './erx-new-request/erx-new-request.component';
import { BenfitCheckComponent } from './benfit-check/benfit-check.component';
import { WjGridDetailModule } from "@grapecity/wijmo.angular2.grid.detail";
import { CurrencyPipe, DecimalPipe } from "@angular/common";
import { NewBenefitInsuranceComponent } from './new-benefit-insurance/new-benefit-insurance.component';

@NgModule({
    imports: [
        WjCoreModule,
        WjInputModule,
        WjGridModule,
        NgbTooltipModule,
        ErxRoutingModule,
        CustFormElemnsModule,
        AppTranslateChildModule,
        NgbModule,
        SharedModule,
        NgxMaskModule.forRoot(),
        PatientModule,
        PrescriberModule,
        DrugModule,
        WjGridDetailModule
    ],
    declarations: [
        ErxComponent,
        ErxListingComponent,
        ErxFilterComponent,
        ErxToNewrxCheckComponent,
        ErxNewRequestComponent,
        BenfitCheckComponent,
        NewBenefitInsuranceComponent
    ],
    providers: [
        ErxService,
        NotesLogService,
        RphVerificationService,
        PatientService,
        DrugService,
        ErxActionListGuard,
        CurrencyPipe,
        DecimalPipe
    ], exports:[
        ErxComponent,
        ErxListingComponent,
        ErxFilterComponent,
        ErxNewRequestComponent,
        BenfitCheckComponent,
        NewBenefitInsuranceComponent
    ],
    schemas:[
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA
    ],
    entryComponents:[NotesLogComponent, ErxComponent,
        ErxListingComponent,
        ErxNewRequestComponent,
        BenfitCheckComponent,
        ErxFilterComponent,]
})
export class ErxModule {}
