<div class="exprx--common-block">
    <div class="eprx--block__header">
      <div class="eprx--header__heading">
        Facility Info
      </div>
    </div>
    <div class="eprx--block__content">
      <div class="row">
        <div class="col-md-3">
          <eprx-select [RxSelectId]="'Facility'" [LabelText]="'Facility Code'" [PlaceHolder]="'Facility Code'"
          [secondCntrlN]="'FacilityName'" [ControlName]="'FacilityId'" [FormGroupName]="facilityFG"
          [IsFirstElem]="true" [HasMultiple]="false" [HasBindLabel2]="true" [Show2Values]="true" [BindLabel2]="'FacilityName'" [BindLabel]="'FacilityCode'" [bindScnCntrlV]="'FacilityName'"
            [BindValue]="'Id'" [LabelForId]="''" [List]="facilityInfo?.Facility" (TriggerSelectValue)="getFacLoc()"
            (keydown.enter)="getFacLoc()" [LabelTitle1]="'CODE'" [LabelTitle2]="'NAME'">
          </eprx-select>
        </div>
        <div class="col-md-3">
          <eprx-input [LabelText]="'Physician'" [PlaceHolder]="'Physician'" [ControlName]="'PrescribName'"
            [FormGroupName]="facilityFG"  [MaxLength]="250">
          </eprx-input>
        </div>
        <div class="col-md-3">
          <eprx-input [LabelText]="'Alternate Physician'" [PlaceHolder]="'Alternate Physician'" [ControlName]="'AltPresName'"
            [FormGroupName]="facilityFG" [MaxLength]="250">
          </eprx-input>
        </div>
        <div class="col-md-3 seconday-search--grid common_global_search_max_content_display_for_Presc line_height_1">
          <label class="align-label">Primary Physician</label>
          <app-searchbox-global [SearchFor]="'Prescriber'" [DisableRadioButtons]="true" [IsAddNew]="false"
          [notSelectSingleMatch]="true"  (SelectedPrescriber)="selectedPresInfo($event)" [ClearInputValue]="prescInputValue" class="common_global_search_max_content_display_length_left"></app-searchbox-global>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <eprx-input [LabelText]="'Facility Patient#'" [PlaceHolder]="'Facility Patient#'" [ControlName]="'FacPatNum'"
            [FormGroupName]="facilityFG"  [MaxLength]="50">
          </eprx-input>
        </div>
        <div class="col-md-3">
          <eprx-date-picker  [RxSelectId]="'AdmitDtID'"    [LabelText]="'Admit Date'" [PlaceHolder]="''" [ControlName]="'AdmitDt'" [FormGroupName]="facilityFG"
            [MinDate]="" [MaxDate]="maxDate"  [MarkAsTouched]="facilityFG?.get('AdmitDt')?.touched">
          </eprx-date-picker>
        </div>
        <div class="col-md-3">
          <eprx-select [LabelText]="'Location'" [PlaceHolder]="'Location'" [secondCntrlN]="'FacLocationName'" [ControlName]="'FacLocId'" [FormGroupName]="facilityFG"
            [BindLabel]="'Id'" [BindValue]="'Id'" [bindScnCntrlV]="'Name'" [LabelForId]="'Id'" [List]="locations" [BindLabel2]="'Name'" [Show2Values]="true">
          </eprx-select>
        </div>
        <div class="col-md-3">
          <eprx-select [LabelText]="'Station'" [PlaceHolder]="'Station'" [secondCntrlN]="'FacStationName'" [ControlName]="'FacStationId'" [FormGroupName]="facilityFG"
          [bindScnCntrlV]="'Name'" [BindLabel]="'Id'" [BindValue]="'Id'" [LabelForId]="'Id'" [List]="stations" [BindLabel2]="'Name'" [Show2Values]="true">
          </eprx-select>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <eprx-input [LabelText]="'Floor'" [PlaceHolder]="'Floor'" [ControlName]="'FloorNum'" [FormGroupName]="facilityFG"
            [InputType]="'NUMBER'" [MaxLength]="2">
          </eprx-input>
        </div>
        <div class="col-md-3">
          <eprx-input [LabelText]="'Room'" [PlaceHolder]="'Room'" [ControlName]="'RoomNum'" [FormGroupName]="facilityFG"
          [InputType]="'NUMBER'" [MaxLength]="5">
          </eprx-input>
        </div>
        <div class="col-md-3">
          <eprx-input [LabelText]="'Bed'" [PlaceHolder]="'Bed'" [ControlName]="'BedNum'" [FormGroupName]="facilityFG"
          [InputType]="'NUMBER'" [MaxLength]="4">
          </eprx-input>
        </div>
      </div>
      <div class="row remove--height__custom--input">
        <div class="col-md-3">
          <eprx-text-area [RxSelectId] ="'DiagnosisNote'" [LabelText]="'Diagnosis Note'" [ControlName]="'DiagnosisNote'" [FormGroupName]="facilityFG"
            [PlaceHolder]="'Diagnosis Note'" [Rows]="2">
          </eprx-text-area>
        </div>
        <div class="col-md-3">
          <eprx-text-area [RxSelectId] ="'OrderNote'" [LabelText]="'Order Note'" [ControlName]="'OrderNote'" [FormGroupName]="facilityFG"
            [PlaceHolder]="'Order Note'" [Rows]="2">
          </eprx-text-area>
        </div>
        <div class="col-md-3">
          <eprx-text-area [RxSelectId] ="'DietNote'" [LabelText]="'Diet Note'" [ControlName]="'DietNote'" [FormGroupName]="facilityFG"
            [PlaceHolder]="'Diet Note'" [Rows]="2">
          </eprx-text-area>
        </div>
        <div class="col-md-3">
          <eprx-text-area [RxSelectId] ="'Remarks'" [LabelText]="'Miscellaneous'" [ControlName]="'Remarks'" [FormGroupName]="facilityFG"
            [PlaceHolder]="'Miscellaneous'" [Rows]="2">
          </eprx-text-area>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <label class="col-md-12 padding-0"> Cycle Fill? </label>
          <eprx-radio [ControlName]="'IsCycleFillAllowed'" [FormGroupName]="facilityFG" [List]="['Yes', 'No']"
            [ValueList]="[true, false]" [IDForList]="['IsCycleFillAllowed1', 'IsCycleFillAllowed2']" [LabelForId]="">
          </eprx-radio>
        </div>
        <div class="col-md-3">
          <label class="col-md-12 padding-0"> Unit Dose? </label>
          <eprx-radio [ControlName]="'IsUnitDoseAllowed'" [FormGroupName]="facilityFG"  [List]="['Yes', 'No']"
            [ValueList]="[true, false]" [IDForList]="['IsUnitDoseAllowed3', 'IsUnitDoseAllowed4']" [LabelForId]="">
          </eprx-radio>
        </div>
        <div class="col-md-3">
          <label class="col-md-12 padding-0"> Print Med Sheet? </label>
          <eprx-radio (keydown.tab)="focusToFirst($event)" [ControlName]="'IsPrintMedSheet'" [FormGroupName]="facilityFG"   [List]="['Yes', 'No']" [ValueList]="[true, false]"
            [IDForList]="['IsPrintMedSheet5', 'IsPrintMedSheet6']" [LabelForId]="">
          </eprx-radio>
        </div>
      </div>
    </div>
  </div>
