import {
    Component,
    OnInit,
    Input,
    ViewChild
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { CollectionView, SortDescription } from "@grapecity/wijmo";
import * as moment from "moment";
import * as wjcGrid from "@grapecity/wijmo.grid"
import { AlertService, SecurityManagerService } from "src/app/services";
import { SearchTrackPatient } from "src/app/models";
import { ValidateFormFieldsUtils } from "src/app/utils";
import { MsgConfig } from "src/app/app.messages";

@Component({
    selector: "app-track-patient",
    templateUrl: "./track-patient.component.html"
})
export class PTrackPatientComponent implements OnInit {
    @Input() patientId: number;
    @Input() patientFG: FormGroup;
    search: SearchTrackPatient;
    totalCount: number;
    trackPatientWJ: CollectionView;
    activeHeaders = [ "Date Changed",
    "Column",
    "Old Value",
    "New Value",
    "Changed By"]
    searchFG: FormGroup;
    today: any;
    minDate: { year: number; month: number; day: number; };
    yesterday: Date;
    groupFG: FormGroup;
    auditData: Object;
    groupByNames = ["Function"];
    isDataExists = true;

    @ViewChild("trackPatient") trackPatient: wjcGrid.FlexGrid;

    constructor(
        private _fb: FormBuilder,
        private _secuManager: SecurityManagerService,
        private _formUtils: ValidateFormFieldsUtils,
        private _alertSer: AlertService
    ) {
        this.minDate = {
            year: moment(this.today).year(),
            month: moment(this.today).month() + 1,
            day: moment(this.today).date()
        };
        this.today = moment().format("MM/DD/YYYY");
        const today = new Date();
        this.yesterday = new Date(today.setDate(today.getMonth() - 3));
        this.searchFG = this._fb.group({
            LogDtTm: this.today,
            ToDtTm: this.today
        });
    }

    ngOnInit() {
        this.search = {
            Document: null,
            // tslint:disable-next-line:max-line-length
            Function: "EditPatient,FacilityInfo,MorePatientInfo,CustomCategory,LifeStyle,ClinicalInfo,DeliveryAddress,Notes,AdditionalInsurance,Documents,DiagnosisDetails,MessagingInfo",
            FromLogDate: this.today,
            ToLogDate: this.today,
            Initail: null,
            OverrideInitial: null,
            PageSize: null,
            PageNumber: null,
            entityId: this.patientId
        };
        this.groupFG = this._fb.group({
            columnGrp: [],
            dateGrp: [],
            userGrp: []
        });
        this.getTrackPatientLogs();
    }

    async getTrackPatientLogs() {
        this.auditData = await this._secuManager.getAuditLog(this.search).toPromise();
        if (this.auditData && this.auditData['TotalCount']) {
            this.generateTrackPatientWJ(this.auditData);
            this.isDataExists = true
        } else {
            this.totalCount = 0
            this.isDataExists = false;
            this.trackPatientWJ = new CollectionView([]);
        }
    }

    generateTrackPatientWJ(data) {
        data && data.AuditViewModel && data.AuditViewModel.length > 0
            ? (this.totalCount = data.TotalCount) : (this.totalCount = 0);
        if (this.totalCount) {
            const mapDataSet = [];
            data.AuditViewModel.map(patientLog => {
                let dataSet: any;
                if (patientLog.delta.changed && patientLog.delta.original) {
                    dataSet = (patientLog.delta.changed["length"] > patientLog.delta.original["length"])
                        ? "changed" : "original";
                } else {
                    dataSet = patientLog.delta.changed ? "changed" : "original";
                }
                patientLog.delta[dataSet].map((val, i) => {
                    const k = {};
                    k["Function"] = patientLog.action;
                    k["Date Changed"] = (patientLog.created) ? moment(patientLog.created).format("MM/DD/YYYY hh:mm:ss a") : "";
                    k["Changed By"] = patientLog.username;
                    k["Column"] = val.name;
                    k["New Value"] = dataSet === "changed" ? (val.value || val.value === false) ? (this.checkDateTimeFields(val) ?
                      (val.name === "Measure Time" ? moment.utc(val.value).local().format("hh:mm:ss a") :
                       moment.utc(val.value).local().format("MM/DD/YYYY")) :
                    (val.value).toString()) : "" :
                     (patientLog.delta["changed"] && patientLog.delta["changed"][i]
                       && (patientLog.delta["changed"][i]["value"] || patientLog.delta["changed"][i]["value"] === false) ? this.checkDateTimeFields(patientLog.delta["changed"][i]) ?
                       (patientLog.delta["changed"][i].name === "Measure Time" ?
                         moment.utc(patientLog.delta["changed"][i].value).local().format("hh:mm:ss a") :
                            moment.utc(patientLog.delta["changed"][i].value).local().format("MM/DD/YYYY")) :
                            (patientLog.delta["changed"][i]["value"]).toString() : null);
                    k["Old Value"] = dataSet === "original" ? (val.value || val.value === false) ? (this.checkDateTimeFields(val) ?
                    (val.name === "Measure Time" ? moment.utc(val.value).local().format("hh:mm:ss a") :
                     moment.utc(val.value).local().format("MM/DD/YYYY")) :
                    (val.value).toString()) : "" :
                     (patientLog.delta["original"] && patientLog.delta["original"][i]
                       && (patientLog.delta["original"][i]["value"] || patientLog.delta["original"][i]["value"] === false) ? this.checkDateTimeFields(patientLog.delta["changed"][i]) ?
                       (patientLog.delta["changed"][i].name === "Measure Time" ?
                         moment.utc(patientLog.delta["changed"][i].value).local().format("hh:mm:ss a") :
                            moment.utc(patientLog.delta["changed"][i].value).local().format("MM/DD/YYYY")) :
                            (patientLog.delta["original"][i]["value"]).toString() : null);
                    if (k["New Value"] !== k["Old Value"]) {
                        mapDataSet.push(k);
                    }
                });
            });
            const sortDesc = new SortDescription("Date Changed", false);
            this.trackPatientWJ = new CollectionView(mapDataSet,
                {
                    groupDescriptions: this.groupByNames,
                    sortDescriptions: [sortDesc],
                    sortComparer: (a: any, b: any) => {
                        return moment(a).isValid() && moment(b).isValid()
                            ? moment(a).diff(moment(b)) : null;
                    }
                });
        } else {
            this.trackPatientWJ = new CollectionView([]);
        }
    }

    WijimoGroupBy(type?, checked?, isClear?) {
        if (checked) {
            this.groupByNames = this.groupByNames ? this.groupByNames : ["Function"];
            this.groupByNames.push(type);
        } else if (this.groupByNames && this.groupByNames.length && !isClear) {
            this.groupByNames.splice(this.groupByNames.indexOf(type), 1);
        }
        this.generateTrackPatientWJ(this.auditData);
        if(this.trackPatient)
                this.trackPatient.collapseGroupsToLevel(0);
    }

    changeGroupBy(isChecked, flex) {
        if (isChecked) {
            // this.trackPatientWJ.groupDescriptions.push("Column");
        } else {
            this.trackPatientWJ.groupDescriptions.pop();
        }
        this.loadedWJRows(flex);
    }

    loadedWJRows(flex: wjcGrid.FlexGrid) {
        flex.collapseGroupsToLevel(0);
    }

  async  getFilteredData() {
        await this._formUtils.blurAndDelay();
        const resp = this._formUtils.checkDateErrors(this.searchFG, "LogDtTm", "ToDtTm", 0);
        if (resp === "DATEISSUE") {
            this._alertSer.error(MsgConfig.DATE_RANGE_ERROR);
        } else if (resp === "INVALID") {
            this._alertSer.error(MsgConfig.INVALID_DATES_ERROR);
        } else if (resp === "VALID") {
        const searchData = this.searchFG.value;
        this.search.FromLogDate = this.searchFG.value["LogDtTm"];
        this.search.ToLogDate = this.searchFG.value["ToDtTm"];
        this.getTrackPatientLogs();
        }
    }

    clear() {
        this.searchFG.controls["LogDtTm"].patchValue(this.today);
        this.searchFG.controls["ToDtTm"].patchValue(this.today);
        this.groupFG.reset();
        this.groupByNames = ["Function"];
        this.WijimoGroupBy(null, null, true);
    }


    focusToFirst(event, type?: any) {
        if (type === "cancel" && this.totalCount) {
            return;
        } else {
            if (document.getElementById("SaveButton")) {
                event.preventDefault();
                setTimeout(() => {
                    document.getElementById("SaveButton").focus();
                }, 10);

            }
        }
    }

    checkDateTimeFields(val) {
        if ((moment(val.value, "MM-dd-yyyy", true).isValid()) || (moment(val.value, "yyyy-MM-dd", true).isValid())
        || (moment(val.value, "YYYY-MM-DDTHH:mm:ss", true).isValid()) ||
         (moment(val.value, "YYYY-MM-DDTHH:mm:ss.SSSSSSS", true).isValid())) {
             return true;
         } else {
             return false;
         }
    }
}
