// export class MorePat {
//     MorePatientInfo: MorePatientInfo = null;
//     DeliveryModes: DeliveryModes[] = [];
//     SmokerCodes: SmokerCodes[] = [];
//     ServicePlaces: ServicePlaces[] = [];
//     EligibilityCodes: EligibilityCodes[] = [];
// }

export class MorePatientInfo {
    Id: number = null;
    TenantId: number = null;
    PatientId: number = null;
    DeliveryModeId: number = null;
    PregnancyCdId: number = null;
    SmokerCdId: number = null;
    ServicePlaceId: number = null;
    EmployerNum: string = null;
    HomePlan: string = null;
    HomePlanNum: string = null;
    FacCode: string = null;
    SSNum: string = null;
    DriveLicenseNum: string = null;
    PrimPrescbId: number = null;
    EligibleCdId: number = null;
    EligibleClId: number = null;
    SalutationId: number = null;
    NameSuffixId: number = null;
    SpeciesCdId: number = null;
    IsDeceased: boolean = null;
    SalesPersonId: number = null;
    IsPrivacyAcked: boolean = null;
    PrivacyAckDt: Date = null;
    ResidenceCatId: number = null;
    IsFullyInsured: boolean = null;
    DeliveryMode: string = null;
    EligibleCdName: string = null;
    ResidenceCatName: string = null;
    ServicePlaceName: string = null;
    PregnancyCode: string = null;
    SmokerCode: string = null;
    Saluation: string = null;
    NameSuffix: string = null;
    PrescName: string = null;
    PetOwnerLastName: string = null;
    PetOwnerFirstName: string = null;
    PetOwnerDOB: Date = null;
    PatientRaceId: number = null;
    PatientRaceName: string = null;
    PatientEthnicityId: number = null;
    PatientEthnicityName: string  = null;
    PatientComorbidityStatusId: number = null;
    PatientComorbidityStatusName : string = null;
    MaidenMiddleName: string = null;
    PatientRelationId: number = null;
    PatientRelRemarks: string = null;
    GuardienFirstName:string = null;
    GuardienLastName: string = null;
    DriverLicExpDt : Date = null;
    PatientQualifId: number = null;
    PatientQualifName: string = null;
    IdProofVal: number = null;
}

export class DeliveryModes {
    Id: number = null;
    Code: string = null;
    Name: string = null;
    Remarks: string = null;
    IsActive: boolean = null;
    CreatedDtTm: Date = null;
    ModifiedDtTm: Date = null;
}

export class SmokerCodes {
    Id: number = null;
    Code: string = null;
    Name: string = null;
    Remarks: string = null;
    IsActive: boolean = null;
    CreatedDtTm: Date = null;
    ModifiedDtTm: Date = null;
}

export class PregnancyCodes {
    Id: number = null;
    Code: string = null;
    Name: string = null;
    Remarks: string = null;
    IsActive: boolean = null;
    CreatedDtTm: Date = null;
    ModifiedDtTm: Date = null;
}

export class ServicePlaces {
    Id: number = null;
    Code: string = null;
    Name: string = null;
    Remarks: string = null;
    IsActive: boolean = null;
    CreatedDtTm: Date = null;
    ModifiedDtTm: Date = null;
}

export class EligibilityCodes {
    Id: number = null;
    Code: string = null;
    Name: string = null;
    Remarks: string = null;
    IsActive: boolean = null;
    CreatedDtTm: Date = null;
    ModifiedDtTm: Date = null;
}

export class NameSuffixes {
    Id: number = null;
    Code: string = null;
    Name: string = null;
    Remarks: string = null;
    IsActive: boolean = null;
    CreatedDtTm: Date = null;
    ModifiedDtTm: Date = null;
}

export class SalesPersons {
    Id: number = null;
    TenantId: number = null;
    FirstName: string = null;
    MiddleName: string = null;
    LastName: string = null;
    Initials: string = null;
    BirthDt: Date = null;
    GenderId: number = null;
    MaritalStatusId: number = null;
    ProfPicExtn: string = null;
    PrimeAddsId: number = null;
    PrimePhId: number = null;
    PrimeEmailId: string = null;
    NPINum: string = null;
    IdProofCode: string = null;
    IdProofId: number = null;
    Remarks: string = null;
    ActiveStatusId: number = null;
    CreatedDtTm: Date = null;
    ModifiedDtTm: Date = null;
}

export class ResidenceCatogiries {
    Id: number = null;
    Code: string = null;
    Name: string = null;
    Remarks: string = null;
    IsActive: boolean = null;
    CreatedDtTm: Date = null;
    ModifiedDtTm: Date = null;
}

export class SpeciesCodes {
    Id: number = null;
    Code: string = null;
    Name: string = null;
    Remarks: string = null;
    IsActive: boolean = null;
    CreatedDtTm: Date = null;
    ModifiedDtTm: Date = null;
}
