import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-eligibility-check",
    templateUrl: "./eligibility-check.component.html"
})
export class EligibilityCheckComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
