export const constant = {
    // TODO: Kartheek - move this to environment.ts variable

    // Account endpoints
    Login: "Account/login",
    Logout: "Account/LogOut/{id}",
    GET_LoggedPharmacists: "Account/LoggedPharmacists",
    POST_TokenRegeneration : "Account/TokenRegeneration",
    GET_APP_VERSION:"version.json",
    //RxExistOrNot
    Rx_Exist_Or_Not: "Rx/Exists",
    // Drug endpoints
    GET_Drug_Suggest: "Drug/Suggest",
    POST_Drug_Equivalent: "Drug/EquivalentList",
    GET_Drug_QtyInHandEquivalent: "Drug/QtyInHandEquivalent/{Drugid}/{ndc}",
    POST_Drug_Generics: "Drug/Generics",
    GET_Drug_Counselling: "Drug/Counselling/{drugId}/{islong}/{language}",
    POST_GET_Drug_Counselling: "Drug/CounsellingData",
    GET_Drug_Warnings: "Drug/Warnings/{drugId}/{gender}/{patientId}",
    GET_Drug_Check340B:
        "Drug/Check340B/{patientId}/{prescriberId}/{drugId}/{insurancePlanId}",
    GET_Drug_CheckAllergies: "Drug/CheckAllergies/{drugId}/{patientId}",
    GET_Drug_DeceaseContradiction:
        "Drug/DeceaseContradiction/{drugId}/{patientId}",
    GET_Drug_DuplicateDrugTherapy:
        "Drug/DuplicateDrugTherapy/{drugId}/{patientId}",
    GET_Drug_DrugToDrugInteration:
        "Drug/DrugToDrugInteration/{drugId}/{patientId}",
    GET_Drug_PregnancyInfo: "Drug/PregnancyInformation/{drugId}/{calledFrom}",
    GET_Drug_LifeStyleInteraction:
        "Drug/LifeStyleInteraction/{drugId}/{patientId}",
    GET_Drug_DosageInfo: "Drug/Dosage/{therapeuticCode}",
    GET_Drug_AdverseDrug: "Drug/AdverseDrug/{drugId}",
    PUT_Drug: "Drug",
    GET_Drug: "Drug/{drugId}",
    GET_Drug_DispenseHistory: "Drug/DispensedHistory",
    GET_Drug_MiscInfo: "Drug/MiscInfo/{drugId}/{bucketId}",
    PUT_Drug_MiscInfo: "Drug/MiscInfo",
    GET_Drug_CounsellingInfo: "Drug/CounsellingInfo/{drugId}/{lang}",
    PUT_Drug_CounsellingInfo: "Drug/CounsellingInfo",
    GET_Drug_Vendors: "Drug/Vendors/{drugId}",
    GET_Drug_VendorPrice: "Drug/VendorPrice/{drugId}",
    POST_Drug_VendorPrice: "Drug/VendorPrice",
    PUT_Drug_VendorPrice: "Drug/VendorPrice",
    POST_Drug_VendorCodeExist: "Drug/VendorPriceExist",
    POST_Drug_LotExist: "Drug/LotNumCheck",
    DELETE_Drug_VendorPrice: "Drug/VendorPrice/{vendorPriceId}",
    PUT_Drug_DiscontinueVendor: "Drug/DiscontinueVendor/{vendorId}/{status}",
    POST_Drug_DrugLot: "Drug/DrugLot",
    GET_Drug_Interactions:
        "Drug/Interactions",
    GET_Drug_IsFileExist: "Drug/IsFileExist/{filename}/{extension}",
    GET_Insurance_Restriction:
        "Drug/DrugInsuranceRestrictions/{drugId}/{pagenumber}/{pagesize}",
    GET_DrugInsurance_Popups:
        "Drug/DrugInsurancePopups/{insuId}",
    GET_Drug_Bucket_Pricing:
        "Drug/DrugBucketPricing/{drugid}/{pagenumber}/{pagesize}",
    POST_Add_Bucket_Pricing: "Drug/AddDrugBucketPricing",
    DELETE_Drug_Bucket_Pricing: "Drug/DeleteDrugBucketPricing",
    PUT_Drug_Bucket_Pricing: "Drug/EditDrugBucketPricing",
    GET_Drug_Bucket_BucketByDrugid:
        "Rx/GetBucketListWithDrugQuantity/{drugId}/{bucketId}",
    GET_Insurance_Dropdown: "Drug/DrugInsuranceRestrictionsDropDown",
    POST_Insurance_Restriction: "Drug/AddDrugInsuranceRestrictions",
    PUT_Insurance_Restriction: "Drug/EditDrugInsuranceRestrictions",
    DELETE_Insurance_Restriction:
        "Drug/DeleteDrugInsuranceRestrictions/{drugInsuId}",
    POST_Drug_Priceupdate: "Drug/DrugPriceUpdate",
    GET_All_Drug_Category: "Drug/DrugCategory",
    GET_Drug_Category: "Drug/DrugCategory/{drugId}",
    GET_Drug_CategoriesList: "Drug/DrugCategories/{drugId}",
    PUT_Drug_Category: "Drug/DrugCategory",
    POST_Drug_Mms_Data: "Drug/DrugMMSData",
    GET_Drug_Copy: "Drug/DrugSettings/Copy/{copyFromId}/{copyToId}",
    POST_Drug_DrugDescription: "Drug/Description/Search",
    GET_Drug_NDC_OCC:
        "Drug/DrugNdcOccurences/{drugtype}/{drugcode}/{pagenumber}/{pagesize}",
    POST_Drug_New: "Drug/AddNewDrug",
    GET_Drug_Ref_Ingredients: "Drug/DrugIngridrients/{drugId}/{prescReFillId}",
    POST_Drug_DrugIngredients: "Drug/DrugIngridrients",
    PUT_Drug_DrugIngredients: "Drug/DrugIngridrients",
    DELETE_Drug_DrugIngredients: "Drug/DrugIngridrients/{IngId}",
    GET_Drug_DrugLotSuggestById: "Drug/DrugLot/{term}/{Drugid}",
    GET_Drug_Default_values: "Drug/CreateDrugDropDowns",
    POST_Drug_Default_values: "Drug/CreateDrugDropDowns",
    GET_Drug_Sales_Summary: "Drug/DrugSalesSummary/{drugId}/{ShowEquivalent}",
    GET_Drug_MedGuide: "Drug/MedGuide/{drugId}",
    GET_COMPOUND_INGREDIENTS_CHECK: "Drug/DrugIngridrientsCheck/{drugId}/{drugsList}",
    POST_PrimeEDIPriceUpdateHistory: "Drug/PrimeEDIPriceUpdateExecutionHistory",
    POST_Drug_PrefDrug: "Drug/UpdatePreferredDrug",
    POST_Drug_EDI810_SuccessRecords: "Drug/EDI810SuccessfulRecords",
    POST_Drug_EDI810_UnSuccessRecords: "Drug/EDI810UnSuccessfulRecords",
    DELETE_Drug_EDI810_UnSuccessRecords: "Drug/EDI810DeleteUnSuccessfulRecords",
    POST_Drug_EDI810Process: "Drug/EDI810Process",
    GET_Drug_LOT_DESC: "Drug/DrugInfo/{drugId}/{bucketId}",
    POST_Drug_FromMMS : "Drug/AddDrugFromMMS",

    // drug price check endpoints
    GET_DRUG_DrugPriceHistory: "Drug/PriceHistory/{drugId}/{pageSize}",
    GET_DRUG_DrugData: "Drug/Details/{drugId}",
    GET_DRUG_DrugPriceSchedule: "Drug/PriceSchedule",
    POST_DRUG_DrugPriceCheck: "Drug/PriceCheck",
    POST_EQDRUG_PriceCheck: "Drug/Equivalent",
    GET_DRUG_GenerateCompNDC: "Drug/CompDrug/GenerateNDC",
    GET_PATIENT_RELATED_DRUGS: "Drug/RelatedToPatient/{patientId}",
    GET_PATIENT_DUR_INFO: "Drug/Interactions/{patientId}",
    POST_DRUG_BY_BARCODE: "Drug/Barcode",
    POST_DDI_REPORT: "Reports/DDI",
    GET_DRUG_BUCKET_PRICE: "Inventory/DrugPrice/{drugId}/{bucketId}",
    GET_BARCODE_VALIDATE:"Rx/GetBarCodeTokenAndValidate",
    SAVE_DSCSA_RX: "Rx/SaveDSCSARx",
    GET_DSCSA_PRESCREFILL_DATA : "Rx/GetDSCSADataWithPrescRefillId/{PrescRefillId}",
    GET_DSCSA_DATA : "Rx/Getdatawithdscsaid/{DSCSAId}",
    DELETE_DSCSA_DATA : "Rx/DeleteDSCSAWithDrugId/{DrugId}",

    // eRx endpoints
    POST_eRx_Search: "eRx/Search",
    DELETE_eRx: "eRx/{eropTransactionId}/{erxId}",
    GET_eRx_AwaitingResponse: "eRx/AwaitingResponse/{eRxId}/{ERxOpId}",
    GET_eRx_ReadyToProcess: "eRx/ActionListReadyToProcess/{eRxId}",
    POST_ErxExceptionReport: "Rx/ErxException",
    GET_eRx_Process: "eRx/Process/{eRxId}",
    POST_eRx_ProcessDetails: "eRx/ProcessIds",
    GET_eRx_ProcessIds: "eRx/ProcessIds/{eRxId}",
    POST_eRx_ProcessLink: "{eRxVersion}/eRx/Link",
    POST_eRx_BenefitCheck: "{eRxVersion}/eRx/BenefitCheck",
    POST_eRx_ProcessUNLink: "{eRxVersion}/eRx/UnLink",
    PUT_eRx_Recall: "eRx/Recall",
    PUT_eRx_UpdateRx: "eRx/UpdateErx",
    GET_eRx_RefillsByPrescnum: "eRx/Rx/Details/{prescNum}",
    PUT_eRx_AcceptorRejectCancelRx: "eRx/Rx/ProcessCancelRequest",
    PUT_eRx_ReverseCancelRxs: "eRx/Rx/Discontinue",
    PUT_eRx_DeleteCancelRxs: "eRx/Rx/Status",
    PUT_eRx_UpdatePatientDetails: "eRx/Patient",
    PUT_eRx_UpdatePrescriberDetails: "eRx/Prescriber",
    GET_eRx_PrintErxReport: "Reports/ErxMessageData/{erxId}",
    GET_eRx_Status: "eRx/status/{eRxOpId}",
    PUT_eRx_Process: "eRx/IsProcessingByUser/{eRxOpId}/{status}",
    POST_eRx_CompoundDrugList: "eRx/CompoundDrug",
    GET_compound_drugById: "eRx/CompoundDrug/{drugId}",
    GET_ErxCompoundIngredientsReport: "Reports/compoundeRx/{erxId}",

    //RxVerification
    POST_RxVerification_Filters: "Rx/RxVerification",
    // Insurance endpoints
    POST_INSURANCE_EXISTS:"Insurance/InsCodeCheck/{insCode}",
    POST_Insurance_Search: "Insurance/Search",
    GET_Insurance_BinSuggest: "Insurance/BinNumSuggest/{searchBy}",
    GET_Insurance_ProcessorSuggest: "Insurance/ProcessorNumSuggest/{searchBy}",
    GET_Insurance_DropDownData: "Insurance/DropDownData",
    POST_Insurance: "Insurance",
    PUT_Insurnace: "Insurance",
    DELETE_Insurance: "Insurance/{insuranceId}",
    GET_Insurance_Attach: "Insurance/Check/{insuranceId}",
    GET_Insurance: "Insurance/{insuranceId}",
    GET_Insurnace_Settings_byId: "Insurance/Settings/{insuPlanId}",
    PUT_Insurance_Settings: "Insurance/Settings",
    GET_Insurance_Settings: "Insurance/Settings",
    GET_Insurance_Settings_Copy:
        "Insurance/Settings/Copy/{copyFromId}/{copyToId}",
    GET_Insurance_MMSData: "Insurance/MMSData/{binNum}/{processorNum}",
    GET_Insurance_TransSettings_byId:
        "Insurance/TransmissionSettings/{insuPlanId}",
    PUT_Insurance_TransSettings: "Insurance/TransmissionSettings",
    GET_Insurance_NCPDP: "Insurance/NCPDP/{ncpdpId}",
    PUT_Insurance_NCPDP: "Insurance/UpdateNCPDPPayor",
    PUT_Insurance_Notes: "Insurance/Notes",
    POST_Insurance_OtherInsPayer: "Insurance/OtherInsPayer",
    DELETE_Insurance_OtherInsPayer: "Insurance/OtherInsPayer/{otherPayorId}",
    GET_Insurance_OtherInsuSuggest:
        "Insurance/OtherInsuPayerSuggest/{searchBy}",
    POST_Insurance_AddOtherInsu: "Insurance/OtherInsPayer/Add",
    PUT_Insurance_EditOtherInsu: "Insurance/OtherInsPayer",
    GET_Insurance_InsBilling_byId: "Insurance/BillingFileSetting/{insuPlanId}",
    PUT_Insurance_InsBilling: "Insurance/BillingFileSetting/{insuPlanId}",
    POST_Insurance_NCPDP_Filters: "Insurance/NCPDP",
    POST_Insurance_NCPDP: "Insurance/AddNCPDPPayor",
    GET_NCPDP_DeleteResponse: "Insurance/DeleteNCPDPResponse/{NcpdpId}",
    DELETE_Insurane_NCPDP: "Insurance/DeleteNCPDPPayor/{NcpdpId}",
    GET_Insurance_Priority_Exists: "Patient/Insurance/PriorityCheck/{patientId}/{PriorityId}",
    GET_Eligibility_Default_Lists: "Insurance/EligibilityInsList",
    POST_Eligibility_AddInsuranceToPatient: "Patient/InsuEligibility/UpdatePatientInsu/AdditionalInsurance/{insuplanId}",
    POST_INSURANCE_EXCEPTION_LIST: "Insurance/ExceptionTracking",
    GET_INSURANCE_EXCEPTION_FILTERS: "Insurance/ExceptionTrackingFilters",
    POST_INSURANCE_EXCEPTION_FILTERS: "Insurance/ExceptionTrackingFilters",
    GET_InsuranceDeleteResponse: "Insurance/DeleteResponse/{insuPlanId}",

    // Payor File Endpoints

    POST_ADD_PAYOR_FILE: "Rx/PayorInsertion",
    GET_PAYOR_FILE_LIST: "Rx/GetPayorsDetails",
    PUT_PAYOR_FILE: "Rx/UpdatePayorDetails",
    DELETE_PAYOR_FILE: "Rx/DeletePayorDetails/{PayorId}",
    POST_CHECK_PAYOR_INSURANCE_LINK: "Rx/CheckInsLinkPayor",
    GET_PAYOR_LINKED_INS: "Rx/InsurancesLinkedToPayor/{payorId}",
    POST_INS_LINK_TO_PAYOR: "Rx/InsuranceLinkPayor",
    POST_MULTI_PAYOR_LINKED_INS: "Rx/InsurancesLinkedToMultiplePayors",
    GET_PayorFileDeleteResponse: "Rx/DeletePayorDetailsResponse/{PayorId}",

    // Inventory endpoints
    POST_Inventory_TransTypeSearch: "Inventory/TransType/Search",
    GET_Inventory_IsTransNameExists: "Inventory/TransType/{transTypeName}",
    POST_Inventory_TransType: "Inventory/TransType",
    DELETE_Inventory_TransType: "Inventory/TransType/{transTypeIds}",
    GET_Inventory_TransNameSuggest: "Inventory/TransType/suggest/{searchText}",
    POST_Inventory_BucketLogsSearch: "Inventory/Bucket/Log",
    GET_Inventory_DrugNDCSuggest: "Inventory/SuggestNDC/{searchText}",
    GET_Inventory_MadeBySuggest: "Inventory/SuggestMadeBy/{searchText}",
    GET_Inventory_PoNumSuggest: "Inventory/SuggestPoNum/{searchText}",
    GET_Inventory_DropDownsList: "Inventory/DropDowns",
    GET_Inventory_VendorByID: "Inventory/Vendor/{vendorID}/{ContractId}",
    GET_Inventory_DrugByID: "Inventory/Drug/{drugID}/{bucketId}",
    GET_Inventory_DrugHistoryByID:
        "Inventory/Drug/History/{drugID}/{isOneYear}",
    POST_Inventory_InventoryList: "Inventory/Search",
    POST_Inventory_InventoryAdd: "Inventory",
    PUT_Inventory_UpdateInventory: "Inventory/UpdateInventory",
    POST_Inventory_PhysclInvn: "Inventory/PhysicalInventory",
    Update_Inventory_PhysclInvn: "Inventory/PhysicalInventory",
    POST_Inventory_PhysclInvn_Process: "Inventory/PhysicalInventory/process",
    GET_Inventory_PhysclInvn: "Inventory/PhysicalInventory/{drugId}/{bucketId}",
    DELETE_Inventory_PhysclInvn: "Inventory/PhysicalInventory/{inventoryId}",
    POST_Inventory_Audit: "Inventory/Audit",
    POST_ADD_DRUG_TO_BUCKET_MNG_INV: "Inventory/Manage",
    GET_BUCK_DRUGPO: "Inventory/DrugPO/{drugId}/{bucketId}",
    GET_DRUG_BUCK_INV_INFO: "Inventory/InventoryDetails/{drugId}/{bucketId}",
    GET_DRUG_BUCK_INV_HIST: "Inventory/Drug/{drugId}/{bucketId}/{considerBucket}",
    GET_Drug_CheckQty : "Inventory/CheckQuantity/{drugid}",
    GET_Drug_LatestPriceUpdateDate : "Drug/LatestPrice/{drugid}",
    POST_DrugSuggestFilterSaving : "Inventory/DrugSuggestFilterSettings",

    // Bucket EndPoints
    POST_Inventory_CompoundDrugBucketsInfo:"Inventory/CompoundDrugBucketsInfo",
    POST_Inventory_BucketSearch: "Inventory/Bucket/Search",
    GET_Inventory_BucketDropDowns: "Inventory/Bucket/Dropdowns",
    GET_Inventory_Buckets: "Inventory/BucketList/{drugId}",
    PUT_BUCK_INV_MANAGE: "Inventory/Manage",
    POST_Inventory_CreateBucket: "Inventory/Bucket",
    PUT_Inventory_UpdateBucket: "Inventory/Bucket",
    DELETE_Inventory_DeleteBucket: "Inventory/Bucket/{bucketId}",
    GET_Inventory_BucketbyId: "Inventory/Bucket/{bucketId}",
    POST_Inventory_BucketHistbyId: "Inventory/Bucket/History",
    GET_Inventory_BucketDrugList: "Inventory/Bucket/DrugList/{drugName}",
    GET_Inventory_DrugQTYbyId: "Inventory/Bucket/DrugQty/{drugId}",
    GET_Inventory_DrugQTYInHand: "Rx/GetQtyInHand/{drugId}/{bucketId}",
    POST_Inventory_TransferBucket: "Rx/BucketTransfer",
    GET_Inventory_BucketCodeSuggest:
        "Inventory/Bucket/SuggestCode/{searchText}",
    GET_Inventory_BucketNameSuggest:
        "Inventory/Bucket/SuggestName/{searchText}",
    GET_Inventory_SpecificTypeSuggest:
        "Inventory/Bucket/SuggestSpecificType/{searchText}",
    GET_Inventory_DrugNameSuggest:
        "Inventory/Bucket/SuggestDrugName/{searchText}",
    GET_Inventory_IsBucketCodeExists:
        "Inventory/Bucket/IsCodeExist/{BucketCode}",
    POST_Inventory_IsBucketNameExists: "Inventory/Bucket/IsNameExist",
    POST_Inventory_PreferredVendorID: "Inventory/PreferredVendor",
    PUT_Inventory_PreferredVendorPrice: "Inventory/PreferredVendorPrice",
    POST_INVENTORY_LatestDrugLot: "Inventory/LatestDrugLot",
    // Patient endpoints
    POST_PATIENT_ACKNOWLEDGE: "Patient/PrivacyAcknowledgement/{patientId}/{isPrivacyAcked}",
    GET_Patient_Suggest: "Patient/Suggest",
    GET_Patient_SuggestByRxNo: "Patient/PattientDetailsByRxNo",
    POST_Patient_RxHistory: "Patient/RxHistory",
    GET_Patient: "Patient/{patientID}",
    GET_RX_Patient: "Patient/RxPatientDetails/{patientID}",
    PUT_Patient: "Patient",
    POST_Patient: "Patient",
    POST_Patient_Merge: "Patient/Merge",
    GET_Patient_MoreInfo: "Patient/MoreInfo/{patientId}",
    PUT_Patient_MoreInfo: "Patient/MoreInfo",
    DELETE_Patient_Allergy:
        "Patient/Allergy/{allergyId}/{patientId}/{allergyCatId}",
    POST_Patient_InsuEligibility: "Patient/InsuEligibility",
    PUT_Patient_InsuEligibility_UpdatePatientInsu:
        "Patient/InsuEligibility/UpdatePatientInsu/{insurerid}/{policyNum}/{patientId}",
    POST_Patient_DiscountSchedule_Search: "Patient/DiscountSchedule/Search",
    POST_Patient_DiscountSchedule: "Patient/DiscountSchedule",
    PUT_Patient_DiscountSchedule: "Patient/DiscountSchedule",
    DELETE_Patient_DiscountSchedule: "Patient/DiscountSchedule",
    PUT_Patient_Family_Link: "Patient/Family/Link",
    POST_Patient_Family_Search: "Patient/Family/Search",
    POST_Patient_Family: "Patient/Family",
    PUT_Patient_Family: "Patient/Family",
    DELETE_Patient_Family: "Patient/PatFamily/{familyId}",
    GET_Patient_DemographInfo: "Patient/CheckDemograph/{patientId}",
    GET_Patient_PatFamily: "Patient/Family/{patFamilyId}",
    GET_Patient_Family_Exist: "Patient/PatFamily/CheckPatientExists/{patientId}/{patfamilyId}",
    PUT_Remove_Family_Member: "Patient/PatFamily/{familyId:long}/{patientId:long}",
    GET_Check_If_PolicyNo_Exits: "Patient/IsPolicyNumExist/{policyNum}/{insurerId}/{insuCardId}",
    GET_Patient_InsuranceData: "Patient/InsuranceData/{patientId}",
    POST_Patient_Allergies: "Patient/Allergy",


    // Additional Insurance
    GET_Patient_InsuranceExists:
        "Patient/IsInsuranceExist/{patientid}/{insurerid}/{insuranceId}",
    GET_Patient_InsuranceGroups: "Patient/InsuGroup/{insurerId}",
    GET_Patient_Insurance_Search: "Patient/Insurance/Search",
    POST_Patient_Insurance: "Patient/Insurance",
    PUT_Patient_Insurance: "Patient/Insurance",
    PUT_Patient_Insurance_SetPrimary:
        "Patient/Insurance/SetPrimary/{insuranceid}/{patientid}",
    GET_Patient_Insurance_SetPrimary_ForRecall:
        "Rx/SetPrimarInsuranceAndUpdateRx/{patientId}/{RefillId}",
        GET_Patient_Insurance_SetPrimary_Details:
        "Rx/GetPatientPrimaryInsurance/{patientId}",
    DELETE_Patient_Insurance:
        "Patient/Insurance/{patientinsuranceid}/{insuranceid}/{patientId}",
    PUT_Patient_Insurance_Status:
        "Patient/Insurance/Status/{insuranceId}/{status}",
    GET_Patient_FacilityLocation: "Patient/PatientLocAndStaById/{facilityId}",
    GET_Patient_FacilityInfo: "Patient/Facility/{patientId}",
    PUT_Patient_Facility: "Patient/Facility",
    GET_Patient_CheckDemoGraph: "Patient/CheckDemoGraph/{patientId}",
    GET_Patient_Messaging: "Patient/Messaging/{patientId}",
    PUT_Patient_Messaging: "Patient/Messaging",
    POST_Patient_ClinicalInfo: "Patient/ClinicalInfo/{patientId}",
    POST_Patient_AddClinicalInfo: "Patient/ClinicalInfo",
    DELETE_Patient_ClinicalInfo:
        "Patient/ClinicalInfo/{clincalInfoId}/{patientId}",
    GET_Patient_Clinical_Units: "Patient/ClinicalInformation/{dimensionId}",
    PUT_Patient_ClinicalInfo: "Patient/ClinicalInfo",

    PUT_Patient_Diagnosis: "Patient/Diagnosis",
    POST_Patient_Diagnosis_Search: "Patient/Diagnosis/Search",
    POST_Patient_Diagnosis: "Patient/Diagnosis",
    GET_Patient_Diagnosis: "Patient/Diagnosis/{patientId}",
    GET_Diagnosis_Details: "Patient/DiagnosisDetails/{diagnosis}",
    DELETE_Patient_Diagnosis: "Patient/Diagnosis/{patientId}/{diagnosisId}/",
    POST_Patient_DeliveryAdd_Search: "Patient/DeliveryAdd/Search/{patientId}",
    POST_Patient_DeliveryAdd: "Patient/DeliveryAdd/{patientId}",
    DELETE_Patient_DeliveryAdd: "Patient/DeliveryAdd/{addressId}",
    PUT_Patient_DeliveryAdd: "Patient/DeliveryAdd",
    PUT_Patient_DeliveryAdd_SetPrimaryAdd:
        "Patient/DeliveryAdd/SetPrimaryAdd/{patientId}/{addressId}",
    POST_Patient_LifeStyleConcept_Search: "Patient/LifeStyleConcept/Search",
    GET_Patient_LifeStyleConcept: "Patient/LifeStyleConcept/{patientId}",
    POST_Patient_LifeStyleConcept: "Patient/LifeStyleConcept",
    DELETE_Patient_LifeStyleConcept:
        "Patient/LifeStyleConcept/{lifeStyleId}/{patientId}",
    POST_Patient_TrackPatient: "Patient/TrackPatient/{patientId}",
    GET_Patient_CustomCat: "Patient/CustomCat/{patientId}",
    PUT_Patient_CustomCat: "Patient/CustomCat",
    DELETE_Patient_CustomCat: "Patient/CustomCat/{customCatId}/{patientId}",
    GET_Insurance_GetInsurance:
        "Insurance/GetInsurance/{insuCarrierId}/{patientId}",
    GET_Patient_InsuPayerSetCheck: "Patient/InsuPayerSetCheck/{insurerid}",
    POST_Check_HasPayer: "Patient/InsuBinPayerSetCheck",
    GET_Rx_Similar: "Rx/CheckRxFile/{patientId}/{prescriberId}/{drugId}",
    GET_Patient_InsuranceActiveStatus:
        "Patient/CheckIfInsuranceIsActive/{insurerid}",
    GET_Patient_InsuranceDeleteResponse: "Patient/InsuranceResp/{insuranceid}",
    POST_InsuranceFile_Status:
        "Insurance/ActivateInsurance",

    // Prescriber endpoints
    GET_Prescriber_Suggest: "Prescriber/Suggest",
    POST_Prescriber: "Prescriber",
    GET_Prescriber_ClinicAddress:
        "Prescriber/ClinicAddress/{prescriberId}",
    POST_Prescriber_AddClinicAddress: "Prescriber/AddClinicAddress",
    PUT_Prescriber_ClinicAddress: "Prescriber/ClinicAddress",
    GET_Prescriber_DropDownData: "Prescriber/DropDownData",
    GET_Prescriber: "Prescriber/{prescriberId}",
    PUT_Prescriber: "Prescriber",
    PUT_Prescriber_Clinic_PrimaryAddress:
        "Prescriber/ClinicAddress/PrimaryAddress/{prescId}/{addressId}",
    DELETE_Prescriber_Clinical_Delete: "Prescriber/ClinicAddress/{addressId}/{prescId}",
    POST_Prescriber_MMSData: "Prescriber/MmsData",
    GET_Prescriber_CheckNPI:
        "Prescriber/CheckPrescriber/{npiNumber}/{deanumber}/{prescriberId}",
    GET_Prescriber_History:
        "Prescriber/History/{prescId}/{startIndex}/{maxRecords}",
    GET_Prescriber_DEANumberCheck:
        "Prescriber/CheckDea/{prescDea}",
    GET_Prescriber_PrescriberTypeCheck:"Prescriber/PrescriberType/Search",
    GET_Prescriber_PrescTypeDeleteResponse :"Prescriber/DeletePrescriberType/{prescriberTypeId}",
    POST_Prescriber_AddTypeFile : "Prescriber/AddPrescriberType",
    POST_Presciber_ADD_Type_Check : "Prescriber/PrescriberTypeCodeCheck/{prescriberCatCode}",
    PUT_Prescriber_File_Type : "Prescriber/EditPrescriberType",

    // Prescriber Specialities endpoints
    POST_Prescriber_PrescSpecialties: "Prescriber/PrescriberSpeciality/Search",
    GET_Prescriber_IsSpecialityCodeExists:
        "Prescriber/PrescriberSpeciality/IsExist/{code}",
    POST_Prescriber_AddPrescSpec: "Prescriber/PrescriberSpeciality",
    PUT_Prescriber_UpdatePrescSpec: "Prescriber/PrescriberSpeciality",
    DELETE_Prescriber_DeletePresSpec: "Prescriber/PrescriberSpeciality/{PresSpecId}",
    GET_Prescriber_SpecCodeSuggest:
        "Prescriber/PrescriberSpeciality/SuggestCode/{searchText}",
    GET_Prescriber_DescrSuggest:
        "Prescriber/PrescriberSpeciality/SuggestDescription/{searchText}",
    GET_Prescriber_SpecDeleteResponse: "Prescriber/DeletePrescriberSpecialityResponse/{specialityId}",

    // Patient Category endpoints
    GET_Patient_PatientCategories: "Patient/PatientCategory/Search",
    GET_Patient_IsCategoryCodeExists: "Patient/PatientCategory/IsCodeExist/{code}",
    POST_Patient_AddCategory: "Patient/PatientCategory",
    PUT_Patient_UpdateCategory: "Patient/PatientCategory",
    DELETE_Patient_DeleteCategory: "Patient/PatientCategory/{categoryId}",
    GET_Patient_CatNameSuggest: "Patient/PatientCategory/SuggestName/{searchText}",
    GET_Patient_CatCodeSuggest: "Patient/PatientCategory/SuggestCode/{searchText}",
    GET_Patient_CatDeleteResponse: "Patient/DeletePatientCategoriesResponse/{categoryId}",

    // Rx Endpoints
    POST_Rx: "Rx",
    PUT_Rx: "Rx",
    POST_NewRxWithDrugChange: "Rx/NewRxWithDrugChange",
    GET_Rx_DosageSchedule: "Rx/DoseSchedule/{scheduleId}",
    GET_Rx_GetHOADetails: "Rx/GetHOADetails",
    POST_Rx_HOADetails: "Rx/HOADetails",
    GET_Rx_RxNotes: "Rx/RxNotes/{prescriptionId}",
    PUT_Rx_RxNotes: "Rx/RxNotes",
    // TODO: Move to Patient and see if we can reuse
    GET_Rx_BillToInsurances: "Rx/BillToInsurances/{patientId}",
    GET_Rx_BillingType: "Rx/BillingType/{patientId}/{cashInsId}",
    GET_Rx_printPickupDetails: "Rx/PrintPickUpDetails/{prescRefillId}",
    GET_Rx_SigCode: "Rx/SigCode/{id}",
    DELETE_Rx_SigCode: "Rx/SigCode/{id}",
    GET_Rx_SigCode_IsExist: "Rx/SigCode/IsExist",
    POST_Rx_SigCode: "Rx/SigCode",
    PUT_Rx_SigCode: "Rx/SigCode",
    POST_Rx_SigCode_Suggest: "Rx/Suggest",
    POST_Rx_SigLangDetails: "Rx/SigLangDetails",
    PUT_Rx_SigLangDetails: "Rx/SigLangDetails",
    DELETE_Rx_SigLangDetails: "Rx/SigLangDetails/{id}",
    GET_Rx_SigData: "Rx/SigData/{startIndex}/{noOfDocuments}",
   // POST_Rx_CalculatePricing: "Rx/CalculatePricing",
    POST_Rx_CalculatePricing: "Rx/PriceCalculation",
    POST_Rx_CalculateUandC: "Rx/CalculateUandC",
    GET_Rx_DefaultSettings: "Rx/DefaultSettings",
    POST_Rx_SigCodesInfoByLangCode: "Rx/SigCodesInfoByLangCode",
    POST_Rx_IsValidTechnician: "Rx/IsValidTechnician",
    POST_Rx_IsValidPharmacist: "Rx/IsValidPharmacist",
    POST_Rx_ValidateTechnicianPassword: "Rx/ValidateTechnicianPassword",
    GET_Rx_IsDuplicatePrescNumber:
        "Rx/IsDuplicatePrescNumber/{prescnum}",
    POST_Rx_DME: "Rx/DME/{rxId}",
    GET_Rx_DME: "Rx/DMEDetails/{rxId}",
    PUT_Rx_DME: "Rx/UpdateDME/{rxId}",
    GET_Rx_DiagnosisList: "Rx/DiagnosisList",
    Allergies: "Patient/InsertAllergy", // DOESN'T exist,
    GET_Rx_TransferIn: "Rx/TrasferRxDetails/{PrescNum}",
    GET_Rx_WCOM_PrevCheck: "Rx/WCOMCopy/{RxId}/{PatientId}",
    GET_Rx_WCOM_Carrier: "Rx/CarrierDetails/{CarrierId}",
    GET_Rx_WCOMCheckPatient: "Rx/WCOMCheck/{patientId}",
    GET_Rx_WCOMDefaultVal: "Rx/WcomDefaultValues",
    GET_Rx_WCOM: "Rx/WCOM/{patientId}",
    POST_Rx_WCOM: "Rx/WCOM",
    GET_Rx_RxShowFile: "Rx/RShowxFile",
    POST_Rx_RxFile: "Rx/RxFile",
    GET_Rx_RxExtraDefaultValues: "Rx/RxExtraValues/{refillid}",
    POST_Rx_SaveRxExtraValues: "Rx/SaveRxExtraValues/{refillid}",
    POST_Get_Multi_RxExtraVal: "Rx/RxExtraValues",
    PUT_Rx_SaveRxExtraValFrmTr: "Rx/RxExtra/{refillId}/{otherCoverage}",
    GET_Rx_DurDefaultValues: "Rx/DurDefaultValues",
    GET_Rx_DurValues: "Rx/DUR/{prescriptionNo}/{prescrefillId}",
    POST_Rx_DurValues: "Rx/AddDUR",
    POST_RX_EditDur: "Rx/DUR/{durId}/{prescrefillId}",
    DELETE_Rx_Dur: "Rx/DUR/{durId}/{prescrefillId}/{rxId}",
    GET_Patients_Drug_Disp_his:
        "Patient/PatientDrugDispensedHistory/{patientId}/{drugId}",
    GET_Rx_Follow_Up_Tag_DropDownData: "Rx/FollowUpTag",
    GET_Rx_Follow_Up_Tag: "Rx/FollowUpTag/{PrescNum}",
    PUT_Rx_Follow_Up_Tag: "Rx/FollowUpTag",
    GET_Rx_GetQntyinHand: "Rx/GetQtyInHand/{drugId}/{bucketId}",
    GET_Rx_CloneRxByID: "Rx/Clone/Get/{PrescNum}",
    GET_Rx_IsRxPicked: "Rx/IsPickedRx/{patientId}",
    DELETE_Rx: "Rx/{PrescRefillId}/{rxOrigin}",
    GET_Rx_IslastRefill: "Rx/IslastRefill/{prescRefillId}/{prescNum}",
    POST_MultipleRX_File: "Rx/FileMultipleRxs",
    POST_CheckRxs_LatestFill: "Rx/CheckMultipleLatestFill", // rx's with latest fill or not.
    GET_Rx_GetRefillInfo: "Rx/GetRefillInfo/{prescNum}",
    // PUT_Rx_GetRxRefillIdsInfo: "Rx/PrescRefillIds/{PrescID}",
    POST_Rx_SaveMAR: "rx/MAR",
    GET_Rx_DiscountAmount: "Rx/DiscountAmount/{discountCode}",
    GET_Rx_ReverseClaimInfo: "Rx/GetReverseClaim/{rxId}/{refillNo}/{partialfillno}",
    GET_Rx_DiscountSchedule: "Rx/DiscountSchedule",
    GET_Rx_PriceSchedule: "Rx/PriceSchedule",
    GET_Rx_PriceCodePrec: "Rx/PriceCodePrecedence/{DrugId}/{PatientId}/{InsuranceId}",
    POST_Rx_ClinicalInfo: "Rx/RxClinicalInfo/{prescnum}",
    POST_Rx_AddClinicalInfo: "Rx/ClinicalInfo",
    PUT_Rx_ClinicalInfo: "Rx/RxClinicalInfo",
    DELETE_Rx_ClinicalInfo:
        "Rx/RxClinicalInfo/{clincalInfoId}/{prescId}",
    GET_Rx_DefaultValuesPresc: "Rx/RxDefaultValuesBasedOnPrecedence/{DrugId}",
    POST_RxDiscontinuedNoteOrderStatus: "Rx/DiscontinuedNoteOrderStatus/Add",
    GET_DiscontinuedNoteOrderStatus: "Rx/DiscontinuedNoteOrderStatus/{prescRefillId}",
    POST_Rx_doseCheck: "Rx/DoseCheck",
    POST_Rx_NextPrevRefillInfo: "Rx/NextPreviousRefillInfo",
    GET_Rx_ACBScoreByDrugTherapeuticCode: "Rx/ACBScoreByDrugTherapeuticCode/{DrugTherapeuticCode}/{DrugId}/{PatientId}",
    PUT_Rx_DiscontinuedOrderStatus: "Rx/DiscontinuedRx/{PrescID}",
    PUT_DiscontinuedMultipleRx: "Rx/DiscontinuedMultipleRx",
    POST_Rx_RRR: "Rx/RRR",
    POST_Rx_VaccineImmunization: "Rx/GetVaccineImmunization",
    Update_Rx_VaccineImmunization: "Rx/UpdateVaccineImmunization",
    POST_EquiDeferredRxs : "Patient/GetDeferredEquivalents",

    //get Rx data
    GET_RX_REFILL_DATA_DATA:"Rx/RefillData/{presNumber}",
    // Shared Endpoints
    POST_SHARED_ZIPCODES: "Shared/ZipCodeList/{stateId}",
    Notes: "Shared/Notes", // DOESN't exist
    POST_Shared_Documents: "Shared/Documents",
    POST_Shared_DocumentByName: "Shared/Document",
    GET_Documents_Thumbnails: "Documents/GetDocuments",
    POST_M3PLTBDocument_Documents: "Documents/GetM3PLTBDocument",
    GET_Shared_AttachedDocInfoByPatientId:
        "Shared/AttachDocument/{patientId}/{drugId}/{prescId}/{prescNum}/{insuCarrierId}/{requestFrom}",
    PUT_Shared_AttachDocument: "Shared/AttachDocument",
    PostRotate_Shared_AttachDocument: "Shared/RotateImage",
    POST_shared_UploadDocument: "Shared/AttachDocument/{docCatId}/{docSubCatId}/{remarks}",
    POST_Shared_AttachDocumentDelete: "Shared/DeleteAttachDocument",
    POST_Shared_AttachDocumentDeleteMultiple: "Shared/MultipleDeleteAttachDocument",
    POST_Shared_AttachDocument: "Shared/AttachDocument/{size}",
    // POST_Shared_AttachDocument: "Shared/AttachDocument/{requestFrom}/{patientId}/{drugId}/{prescId}/{prescNum}/{insuCarrierId}/{docCatId}/{docSubCatId}/{name}/{remarks}",
    POST_shared_AttachDocToRx: "Shared/AttachDocumentIds",
    GET_Shared_DownloadDoc: "Shared/Document/{docId}/{extn}/{isControlFile}",
    GET_Rx_NextPrescriptionId: "Rx/NextPrescriptionId/{drugClassId}",
    POST_Shared_UploadLogo: "Shared/UploadLogo/{docSize}",
    GET_Shared_File_Exist: "Shared/IsFileExist/{fileName}/{extension}",
    POST_Delete_PharmacyLogo: "Shared/DeletePharmacyLogo",
    // GET_Rx_GetRxDetails: "Rx/GetRxDetails/{PrescNum}",
    POST_Rx_GetRxDetails: "Rx/GetRxDetails",
    GET_Rx_IOU: "Rx/IOU/{PrescRefillId}",
    PUT_Rx_IOU: "Rx/IOU",
    DELETE_Rx_IOU: "Rx/IOU/{IouId}",
    GET_Rx_BucketsbyDrugId: "Rx/Bucket/{drugId}",
    GET_Rx_PatientSpecificPrescribers:
        "Rx/GetPatientSpecificPrescribers/{patientId}",
    GET_Rx_PrescNumberByDrugClass: "Rx/GenPrescNumByDrug/{drugClass}",
    GET_Rx_PatientById: "eRx/Patient/{patientId}",
    GET_Rx_PrescriberById: "eRx/Prescriber/{prescriberId}",
    GET_Rx_DrugById: "eRx/Drug/{drugId}",
    GET_Rx_PatientDataId: "eRx/PatientData/{patientId}",
    POST_RX_IOU: "Rx/IOU",
    DELETE_RX_IOURec: "Rx/IOU/Delete",
    UPDATE_IOU_OweQty: "Rx/QtyQweUpdate",

    // User end point
    GET_USER_Privs: "User/Privs/{userid}",
    PUT_USER_CHNG_PASS: "User/Password",

    // Utility endpoint
    GET_Utility_SystemData: "Utility/SystemData",
    GET_Utility_Cities: "Utility/Cities",
    GET_Utility_States: "Utility/States/{text}",
    GET_Utility_StateById: "Utility/State/{stateId}",
    GET_Utility_CityById: "Utility/City/{cityId}",

    GET_Utility_StateCity: "Utility/StateCity/{text}",
    GET_Utility_ZipByStateCity: "Utility/ZipCode",
    GET_Utility_NotesLog:
        "Utility/NotesLog/{parentPage}/{id}/{fromDate}/{toDate}",
    GET_NotesLog: "Reports/ExportNotesLog",
    POST_Utility_NotesLog: "Utility/NotesLog/{parentPage}/{id}",
    DELETE_Utility_NotesLog: "Utility/NotesLog/{id}",
    PUT_Utility_NotesLog: "Utility/NotesLog",
    POST_Utility_AuditLog: "Utility/Audit",
    PUT_Utility_UpdateNotesStatus: "Utility/NotesStatus/{notesId}/{isShowpopup}/{RxType}/{NoteType}",
    GET_NOTES_TRACK_CHANGES: "Utility/NotesHistory/{noteId}",
    GET_AUTH_TOKEN_MCSVC: "Utility/AuthOToken",

    // Vendor Endpoints
    GET_Vendor_DrugBucketList: "Vendor/DrugBucket/Search/{text}",
    POST_Vendor_Search: "Vendor/Search",
    GET_Vendor: "Vendor/{vendorId}",
    DELETE_Vendor: "Vendor/{vendorId}",
    POST_Vendor: "Vendor",
    PUT_Vendor: "Vendor",
    GET_Vendor_InventoryHistory: "Vendor/InventoryHistory/{vendorId}",
    GET_Vendor_IsCodeExists: "Vendor/IsCodeExist/{vendorCode}",
    GET_Vendor_IsExists: "Vendor/IsExist/{vendorName}",
    GET_Vendor_IdSuggest: "Vendor/IdSuggest/{searchText}",
    GET_Vendor_NameSuggest: "Vendor/NameSuggest/{searchText}",
    GET_VendorContract_Search: "Vendor/VendorContracts/{VendorId}",
    GET_VendorContract_Details: "Vendor/EDIVendorContracts/{vendorCode}",

    // drugWarning Endpoint
    POST_DRUG_DIAGNOSIS: "Patient/Diagnosis/Search",
    POST_SEARCH_RX_TAG: "Rx/RxFollowUpTagFile",
    GET_RX_FOLLOW_UP_SUGGEST: "Rx/RxFollowUpTagFileSuggest/{TagName}",

    // dose schedule endpoints
    POST_DOSE_DoseScheduleSearch: "Drug/DoseScheduleSearch",
    GET_DOSE_GetDoseSchedule: "Drug/DoseSchedule/{doseSchedID}",
    POST_DOSE_AddDoseSchedule: "Drug/DoseSchedule",
    PUT_DOSE_AddDoseSchedule: "Drug/DoseSchedule",
    POST_DOSE_AddFaclityDoseScedule: "Drug/FacilityDoseSchedule",
    PUT_DOSE_AddFaclityDoseScedule: "Drug/FacilityDoseSchedule",
    DELETE_DOSE_DeleteDoseSchedule: "Drug/DoseSchedule/{doseSchedId}",
    GET_DOSE_DrugFacilities: "Drug/Facility",
    GET_DOSE_GetDoseSchedByFacID:
        "Drug/FacilityDoseSchedule/{facilityId}/{doseSchedId}",
    DELETE_DOSE_DeleteFacDoseSchedule: "Drug/Facility/{facilityId}/{doseSchId}",
    GET_DOSE_IsDoseSchedNameExists: "Drug/DoseSchedule/IsExists/{DoseName}",
    GET_DoseSchedule_DeleteResponse: "Drug/DeleteDoseScheduleResponse/{doseIds}",
    // drugWarning Endpoint

    POST_Drug_Warning: "Drug/DrugWarning",
    POST_Drug_Diagnosis: "Patient/Diagnosis/Search",
    POST_Search_RxTag: "Rx/RxFollowUpTagFile",
    GET_Rx_Follow_Up_Suggest: "Rx/RxFollowUpTagFileSuggest/{TagName}",
    POST_RxTagInformation: "Rx/RxTagInformation",
    POST_RxTagFollowUp_DeleteResp: "Rx/RxFollowUpTagDeleteResponse",

    // Rx Transfer Endpoints

    GET_TransferRx_Details: "Rx/TransferRxDetails/{PrescNum}/{filter}/{PrescRefillId}",
    GET_TransferRx_Popups: "Rx/TransferRxPopups/{PrescNum}/{page}/{RefillNo}",
    GET_Pharmacy_Details: "Rx/GetTransferPharmacyInfo/{PharmId}",
    GET_Pharmacy_Names: "Rx/GetPharmacyNames",
    POST_TransferRxInOut: "Rx/TransferRXInandOut",
    POST_Add_Pharmacy: "Rx/AddNewPharmacy",
    PUT_Edit_Pharmacy: "Rx/EditTransferPharmacy",
    DELETE_Delete_Pharmacy: "Rx/DeleteTransferPharmacyInfo/{PharmId}",
    GET_Pharmacy_Pop: "Rx/TransferRxPopups/{PrescNum}",
    PUT_Transfer_Details: "Rx/RxTransferInfo",
    delete_Transfer_Pharmacy: "Rx/DeleteTransferPharmacyResponse/{PharmId}",
    POST_TransferRxOut_ChckList: "Rx/TransferRxPopupsCheckList",

    // labels
    GET_Labels: "LabelDesigner/GetLabelDesignFields",
    POST_Template: "LabelDesigner",
    PUT_LabelQUEUE: "LabelDesigner/LabelQueue",
    POST_QueueFilters: "LabelDesigner/QueueFilters",
    GET_QueueFilters: "LabelDesigner/QueueFilters",
    DELETE_Devlivery: "Rx/DeliverInfoRemoval",
    Delete_LabelQUEUE: "LabelDesigner/LabelQueue",
    UPDATE_Template: "LabelDesigner",
    DELETE_Template: "LabelDesigner/LabelDesigner/{TemplateId}",
    GET_PreviewLabels: "LabelDesigner/Preview",
    GET_TempAndRx: "LabelDesigner/GetLabelTemplates",
    GET_TemplateById: "LabelDesigner/GetLabelTemplate/{templateId}",
    GET_Compound_Formulation: "Reports/CompoundIngredientsReport/{drugId}/{prescRefillId}",
    POST_IsMonogram_Printed: "LabelDesigner/DrugCounsellingInfo",
    POST_Print_Drug_Counselling: "Reports/DrugCounselling",
    POST_Print_Counselling_Audit:"Utility/DrugCounsellingLog",
    POST_LabelDesigner_DefaultReturnToStock: "LabelDesigner/DefaultReturnToStock/{TemplateId}",
    Exist_In_LabelQueue: "LabelDesigner/CheckInLabelQueue/{prescRefillId}",
    POST_Automated_PrintCheck: "PillCounter/AutomatedPrintCheck",
    POST_PillCounter_EyeconReq: "Pillcounter/EyeconRequest",

    // Label print set endpoints
    GET_PrintSet_LabelNameDrops: "LabelDesigner/LabelTemplatesList",
    POST_PrintSet_PrintSetSearch: "LabelDesigner/PrintSet/Search",
    POST_PrintSet_AddPrintSet: "LabelDesigner/PrintSet",
    PUT_PrintSet_UpdatePrintSet: "LabelDesigner/PrintSet",
    DELETE_PrintSet_DeletePrintSet: "LabelDesigner/PrintSet/{printSetId}",
    GET_PrintSet_IsSetExists: "LabelDesigner/SetExist/{SetName}",
    POST_QZTRAY_KEY: "LabelDesigner/QztraySignature",
    GET_QZTRAY_CERT: "LabelDesigner/QztrayCertificate",
    GET_Reports_LabelDocuments : "Reports/LabelDocuments",

    // Facility file end points
    POST_Faclity_FacilitySearch: "Facility/Search",
    GET_Facility_GetDropdowns: "Facility/DropDowns",
    GET_Facility_GetFacilityDetailsById: "Facility/Search/{facilityId}",
    POST_Facility_AddFacility: "Facility",
    PUT_Facility_UpdateFacility: "Facility",
    DELETE_Facility_DeleteFacility: "Facility/{facilityId}",
    GET_Facility_IsFacilityCodeExists: "Facility/IsExistCode/{facCode}",
    GET_Facilities_Linked_To_Bucket: "Facility/IsFacilityLinked/{facilityId}/{isForGeneralBucket}/{bucketId}",
    GET_Facility_DeleteResponse:"Facility/DeleteResponse/{facilityIds}",
    // Browse Rx End POints
    POST_RX_BrowseRxCountbyDate: "Rx/BrowseRxCountbyDate",
    GET_RX_BrowseRxInfoByDate:
        "Rx/BrowseRxInfoByDate",

    // Transmit Rx
    GET_Transmit_PatientOnly: "Transmit/BatchTransmit/{patientId}",
    GET_Transmit_PatientOnlyNw: "Transmit/BatchTransmitNew/{patientId}",
    POST_Transmit_TransmitClaim: "Transmit/TransmitClaim",
    PUT_Transmit_PatientOnly: "Transmit/BatchTransmit/{patientId}",
    PUT_Transmit_Continue_Claim:
        "Transmit/TransmitClaim/Continue/{prescrefillId}/{statusId}/{drugId}",
    GET_Rx_TransmissionLog: "Rx/TransmissionLog/{prescRefillId}",
    POST_Transmit_TransmitClaim_Show:
        "Transmit/TransmitClaim/Show/{rxTransLogId}/{claimId}",
    POST_View_Transmit_Message: "Transmit/ViewTransmit",
    GET_Completed_Group_Tansmision: "Transmit/ShowTransDataForCompletedRx/{patientId}",
    POST_MandatoryCheckPriorApprvl: "Transmit/MandatoryCheckPa",

//Get Transmission Log Count
GET_Rx_TransmissionLogCount: "Rx/TransmissionLogCount/{prescRefillId}",

    // Rph Verification end points
    POST_RphVerification_Filters: "Rx/Verification",
    GET_RphVerification_UserSuggest: "Rx/Users/{user}",
    POST_GET_ACTIONBUTTONS_COUNT: "Rx/RphAndRxVerificationStats",
    Post_BatchRphVerification: "Rx/PharmacyVerification/List",
    POST_GetFormsWithEntity: "Forms/GetFormsWithEntity",
    GET_FORMS: "Forms/GetForm/{formId}",
    SAVE_FORMS: "Forms/SaveResponse",
    VIEW_SAVED_FORM: "Forms/ViewSavedResponse",
    UPDATE_FORMS: "Forms/EditResponse",

    // Drug Pick Verification end points
    GET_DrugPickVerification_Status:
        "Rx/DrugPickVerification/Verify/{PrescNum}/{RefillNum}/{PartialFillNum}/{NDC}",
    POST_DrugPickVerification_NotVerified:
        "Rx/DrugPickVerification/NotYetVerified",
    POST_DrugPickVerification_Verified: "Rx/DrugPickVerification/Verified",
    GET_DrugPickVerification_RxDetails:
        "Rx/DrugPickVerification/{PrescNum}/{RefillNum}/{PartialfillNum}",
    POST_DrugPickVerification_VerifyDetails: "Rx/DrugPickVerification/Verify",
    POST_BatchDrugVerification: "Rx/DrugPickVerification/BatchVerify",
    GET_DrugPickVerification_Rximage: "Rx/Erx/{PrescNum}",
    POST_Transmit_GroupTransmStatusofFailedRx: "Transmit/GroupTransmStatusofFailedRx",
    POST_DrugPickVerification_Reverify: "Rx/DrugPickVerification/ReVerify",

    // System Settings end points

    GET_System_Settings: "Settings/SystemSettings",
    POST_Account_Settings: "Settings/SettingsPrevBased",
    // GET_MAR_Details: "Rx/GetMarDetails/{patientId}", // commented as not using this endpoint
    GET_MonogramDropdownData: "Settings/MonogramDropdownData",
    GET_LabelMonogramData: "Settings/LabelMonogramData/{pharmId}",
    PUT_EditPharmacyandTenantDetails: "Settings/EditPharmacyandTenantDetails",
    POST_CreateNewTenat: "Settings/CreateNewPharmacy",
    GET_TenatInfo: "Settings/PharmacyDetails",
    PUT_NewTenantInfo: "Settings/EditPharmacyDetails",
    GET_IPConfig_StatusChange: "Rx/IpRestriction/{Id}/{status}",
    POST_IPConfig_AddIP: "Rx/IpRestriction",
    GET_IPConfig_GetIP: "Rx/IpRestriction",
    GET_Interface_Token: "Settings/InterfaceHubAPIToken",
    GET_Installation_Key: "Settings/GenerateTenantAccessKey",
    GET_RegenerateKey:"Settings/ReGenerateTenantAccessKey",
    GET_ReportWriterToken: "Reports/ReportWriterToken",

    // Quick Refill
    POST_Rx_QuickRefill: "Rx/QuickRefill/List",
    GET_Rx_DeliveryInfo: "Rx/DeliverInfo/{rxId}/{prescRefillId}",
    PUT_Rx_DeliveryInfo: "Rx/DeliverInfo",
    PUT_Rx_QuickRefill: "Rx/QuickRefill",
    PUT_Rx_DrugExpiry: "Rx/DrugExpiry",
    POST_Rx_RxAdmin: "Rx/RxAdmin",
    PUT_Rx_RxAdmin: "Rx/RxAdmin",
    GET_Rx_ShowRefills: "Rx/ShowRefills",

    GET_RphVerification_IsPharmacyDrugPickVerified:
        "Rx/IsPharmacyDrugPickVerified",
    GET_RphVerification_IsDrugPickVerified:
        "Rx/IsDrugPickVerified/{prescnum}/{prescRefillId}/{PartialfillNum}",
    GET_RphVerification_IsAlready:
        "Rx/IsExistVerification/{prescId}/{presRefillId}/{isForRxVerification}",
    GET_RphVerification_RxFollowUp: "Rx/RxFollowUp/{prescId}/{prescRefillId}",
    POST_RphVerification_Verify: "Rx/PharmacyVerification",
    GET_RphVerification_History: "Rx/VerificationHistory/{rxid}/{refilno}/{partialFillNum}",
    PUT_Save_System_Settings: "Settings/SaveSystemSettings",
    POST_PHARM_VERIF_HISTORY: "Rx/PharmacistVerificationHistory",
    POST_PHARM_VERIF_REPORT: "Reports/ReviewPharmacistReport",
    POST_RX_FILLED_SUM_REPORT: "Reports/RxFilledSummaryReport",
    POST_RXS_BY_INSURANCES_REPORT: "Reports/RxsByInsuranceReportGeneration",
    POST_RX_DRUG_PICK_HISTORY: "Rx/DrugPickVerificationHistory",
    POST_DPH_VERIF_REPORT: "Reports/ReviewDrugPickVerificationReport",


    // Refill Rx End Points
    GET_RX_RefillRxDetails: "Rx/RefillRxDetails",
    GET_REFILL_INSULIST: "Rx/GetRxRefillInsuList",

    // Rx (or) Patient History Actions End points
    PUT_Rx_MarkVerifed: "Rx/MarkVerified/{status}",
    PUT_Rx_ManageCopay: "Rx/ManageCopay/{paidStatus}",
    POST_ErxAndDocumentQueue_Count: "eRx/DocAndErxCount",

    // Discount schedule end points
    POST_Discount_Schedule_Filters: "Patient/DiscountSchedule/Search",
    POST_Discount_Schedule_Add: "Patient/DiscountSchedule",
    PUT_Discount_Schedule_Add: "Patient/DiscountSchedule",
    DELETE_Discount_Schedule_Add: "Patient/DiscountSchedule",
    POST_Discount_Schedule: "Patient/DiscountSchedule",
    PUT_Discount_Schedule: "Patient/DiscountSchedule",
    DELETE_Discount_Schedule: "Patient/DiscountSchedule/{discSchId}",
    GET_DiscountSchedule_DeleteResponse: "Patient/DiscountScheduleResponse/{discSchId}",

    // Dashboard endpoint
    POST_Rx_RxDashboardStats: "Rx/RxDashboardStats",
    POST_Rx_RefillsQueueDataForDashBoard: "Rx/RefillsQueueDataForDashBoard",
    POST_Rx_RxDashboardErxStats: "Rx/RxDashboardErxStats",

    // priceList end points
    POST_PriceList_Filters: "Rx/RxPriceList",
    POST_PriceList_Add: "Rx/AddRxPriceList",
    PUT_PriceList_Edit: "Rx/EditRxPriceList",
    DELETE_PriceList: "Rx/DeleteRxPriceList/{BulkPriceId}",

    // priceSchedule end points
    POST_PriceSchedule_Filters: "Rx/RxPriceSchedule",
    POST_PriceSchedule_Add: "Rx/AddRxPriceSchedule",
    PUT_PriceSchedule_Edit: "Rx/EditRxPriceSchedule",
    DELETE_PriceSchedule: "Rx/DeleteRxPriceLisSchedule/{PriceScheduleId}",
    GET_PriceSchedule_DeleteResponse: "Rx/DeleteRxPriceListScheduleResponse/{PriceScheduleId}",

    // RxFilledSummary end Point
    POST_RxFilledSummary: "Rx/RxFilledSummary",

    // Add Annotation History for  Class  2 Drug
    POST_RxAnnonationHistory: "Rx/AddAnnotationNotes",
     // Rx Annotation History for  Class  2 Drug
    GET_RxAnnonationHistory: "Rx/AnnotationNotes/{prescrefillId}",

    POST_PrintAnnonationHistory : "reports/AnnotationNotes",

    // security manager end points
    POST_Login_Login: "Account/login",
    PUT_Login_Logout: "Account/LogOut/{id}",
    POST_SecurityManger_Roles: "User/Role",
    GET_SecurityManager_Role: "User/Role/{roleId}",
    GET_SecurityManger_Roles: "User/Role/{PageNumber}/{PageSize}",
    PUT_SecurityManager_Role: "User/Role",
    DELETE_SecurityManager_Role: "User/Role/{roleId}",
    ControlFileUpdateUnresolvedRxsForUser_User: "ControlFile/UpdateUnresolvedRxsForUser/{userId}/{prescriberId}",
    // print ERX request end points
    GET_ERxReq_CheckErxRequest: "eRx/CheckErxRequest/{rxId}",
    GET_ERxReq_IsCompundDrug: "eRx/CheckCompDrug/{drugId}",
    GET_ERx_CheckDAW: "eRx/CheckDaw/{prescRefillId}",
    GET_ERx_CheckPrescriber: "eRx/CheckPrescriber/{prescriberId}",
    GET_ERx_CheckDrugValues: "eRx/CheckErxDrugValues/{drugId}",
    GET_ERX_GetERxDetails: "eRx/ERefillRequest/{prescRefillId}/{drugId}/{requestType}",
    GET_ERX_GetERxDropdowns: "eRx/ErxDrugValues",
    POST_ERX_SaveMissingFields: "eRx/ErxDrugValues",
    PUT_ERX_AddSPINumber: "eRx/SpiNumber",
    GET_ERX_ProcessIds: "eRx/ProcessIds/{eRxId}",
    POST_ERX_RxGeneration: "eRx/RxGeneration",
    POST_ERX_XMLCreation: "eRx/XmlCreation",
    POST_ERX_ChangeReq: "eRx/RxChange",
    GET_ERx_CheckCompoundDrugValues: "eRx/CheckCompoundErxValues/{drugId}/{prescRefillId}",

    // user endpoints
    POST_SecurityManger_User_Add: "User",
    PUT_SecurityManger_User: "User",
    GET_SecurityManger_User: "User/SecurityManagerUsers",
    DELETE_SecurityManger_UserStatus: "User/{personId}/{status}",
    GET_SecurityManger_DropDownValues: "User/DropDownData",
    GET_SecurityManger_UserById: "User/{personId}",
    POST_SecurityManager_UsersHistory: "Account/LoginHistory",
    PUT_USER_LOCK_OR_UNLOCK: "User/LockStatus",
    // sig file end points
    POST_SigFile_Add: "Rx/SigCode",
    GET_SigFile_ById: "Rx/SigDescDetails/{sigId}",
    PUT_SigFile: "Rx/SigCode",
    DELETE_SigFile: "Rx/DeleteSigCode",
    POST_SigFile_Filters: "Rx/Sig",
    POST_SigCode_Response: "Rx/DeleteSigCodeResponse",

    // QuickVarf end Points

    GET_AppUsersByTenantId: "Rx/AppUsersByTenantId",
    POST_QuickVerificationUserCheck: "Rx/QuickVerificationUserCheck",
    POST_SaveQuickVerfication: "Rx/QuickVerification",

    // recall deleted Rx endpoints
    POST_RecallRx_RxSearch: "Rx/RecallDeleted",
    PUT_RecallRx_Recall: "Rx/Recall",
    PUT_RecallRx_ReplaceBucket: "Rx/Bucket",
    PUT_RecallRx_ReplaceDrug: "Rx/Drug",
    PUT_RecallRx_CreateRx: "Rx/PrescNum",
    PUT_DeleteRx_ControlRx: "Rx/PrescRefill",
    PUT_RecallRx_ControlRx: "Rx/RecallControl",
    GET_Rx_InActive: "Rx/InActive/{prescRefillId}",
    GET_RX_DETAILS_FOR_RECALL:"Rx/RecallDeletedRx",
    POST_RECALLRXDETAILS: "Rx/GetRecallRxDetails",
    GET_RECALLDETAILS_INACTDRUG: "Rx/RecallRxDetails/{RxEsId}",

    // patient history print end points
    POST_PatientHistPrint_RxReport: "Reports/PatientDataRxReport",
    POST_PatientHistPrint_RxDelivery: "Reports/PatientDataRxDelivery",
    POST_PatientHistPrint_RxReceipt: "Reports/PatientDataRxReceipt",
    POST_PatientHistPrint_Copay: "Reports/PatientDataUnbilledCopay",
    POST_PatientHistPrint_RefillExpirationReport: "Reports/PatientDataRefillExpiration",


    GET_PatientHistPrint_PatientAddresses: "Reports/PatientAddress/{patientId}",
    GET_PatientHistPrint_Insurances: "Reports/Insurances/{patientId}",
    GET_PatientHistory_ReportConfig: "Reports/ReportConfig",
    POST_PatientHistory_SaveReportConfig: "Reports",
    POST_Rx_FORM_REPORT:"Reports/PrintMultipleRx",

    // Control File
    POST_ControlFile_List: "ControlFile/List",
    POST_ControlFile_ResubmitListState : "ControlFile/ResubmitList/{state}",
    POST_ControlFile_Add: "ControlFile/Add",
    PUT_Transmit_ControlFile_Continue: "ControlFile/Continue",
    GET_ControlFile: "ControlFile",
    POST_Reports_Asap: "Reports/PreviewASAPControlData",
    GET_ControlFile_Settings_List: "ControlFile/Settings/List",
    PUT_ControlFile_Settings_List: "ControlFile/Settings/List",
    GET_Utility_States_List: "Utility/States/List",
    GET_ControlFile_Settings: "ControlFile/Settings/{StateId}",
    GET_ControlFile_Settings_RefillOptions:
        "ControlFile/Settings/RefillOptions/{stateId}",
    POST_ControlFile_Settings_Add: "ControlFile/Settings/Add",
    GET_Control_PatIdPriorties: "ControlFile/PatIdPriorites/{StateId}",
    PUT_ControlFile_UpdateStatus: "Controlfile/ResubmitList",
    POST_ControlFile_Resubmi_HardStop: "ControlFile/ResubmitList/Hardstop",
    POST_ControlFile_ResubmitList: "ControlFile/ResubmitList",
    GET_CheckIOCDrug: "ControlFile/CheckIOC/{ndc}/{patientState}",
    POST_ControlFile_Delete: "ControlFile/ResubmitList/Remove",
    POST_ControlFile_CheckIsControlSubmitted: "ControlFile/CheckIsControlRxSubmited",
    Delete_ControlFile_Remove:"ControlFile/Delete/{id}",
    GET_ControlFile_CheckIfSettingExist : "ControlFile/CheckIfSettingExist/{stateId}",

    // TransferpharmacyList

    GET_TransferPharmacy_List: "Rx/GetTransferPharmacyInfo",
    POST_Duplicate_Pharmacy: "Rx/CkeckDuplicatePharmacy",
    PUT_ControlFile_ControlRefillOptions: "ControlFile/ControlRefillOptions",
    POST_ControlFile_Settings_Update: "ControlFile/Settings/Update",

    // Billed Unbille Rx
    PUT_Rx_RxBillStatus: "Rx/RxBillStatus/{rxBillId}/{status}/{prescRefillId}",

    // Reports endpoints
    POST_Reports_TransactionAudit: "Reports/Audit",
    POST_Reports_DailyLog: "Reports/DailyLog",
    GET_Reports_DailyLog_DropDown: "Reports/DailyLogDropDowns",
    POST_Reports_ControlRx: "Reports/ControlRx",
    GET_Reports_FiledRx_DropDown: "Reports/FiledRx",
    POST_Reports_FiledRx: "Reports/FiledRx",
    POST_Reports_Summary: "Reports/BusinessSummaryReport",
    POST_Reports_Summary_Graph: "Reports/BusinessSummaryGraphs",
    POST_Reports_UnBilledRx: "Reports/UnBilledRx",
    POST_Reports_RxByPharmacist: "Reports/RxByPharmacist",
    POST_Reports_RxByPrescriber: "Reports/RxByPrescriber",
    GET_Reports_RxByPharmacist_DropDown: "Reports/RxByPharmacistDropDowns",
    GET_Reports_RxByPrescriber_DropDown: "Reports/RxByPrescriberDropDowns",
    POST_Reports_ByPatientSelection: "Reports/PatientMedicationFilters",
    GEt_Reports_ByDrugCat: "Drug/MedAdherCatInfo",
    GET_Drug_Report_PatientAdherenceReportData : "Reports/PatientAdherenceReportData",
    POST_Drug_Report_ViewDataSet : "Reports/GetPDCandMPRInfo",
    POST_Inventory_Report_ViewDataSet : "Reports/InventoryReportData",
    POST_Inventory_Reconciliation_Report_ViewDataSet : "Reports/InventoryReconciliationReportData",
    POST_Inventory_Reconciliation_Report_ViewDataSet_RW : "Report/InventoryReconciliationReportData",
    POST_Inventory_Reconciliation_Report_Explanation: "Reports/UpdateExplanationInPhysicalInventory",
    GEt_Reports_DrugCatConceptNames: "Drug/DrugCatConceptNames",
    POST_Reports_RefillDue: "Reports/RefillDueReport",
    POST_Reports_AddDrugConcept: "Drug/AddDrugConcept",
    POST_Reports_AddMedicationDrugCategory: "Drug/AddMedCategory/{categoryName}",
    DELETE_Report_DeleteMedAdhereCategory: "Drug/DeleteMedAdhereCategory/{categoryId}",
    DELETE_Report_DeleteConceptName: "Drug/DeleteConceptName/{id}",
    POST_Reports_DrugDispense: "Reports/DrugDispensedHistory",
    POST_Reports_DrugDispenseEquivalency: "Reports/DrugDispensedEquivalency",
    POST_Reports_RefillExpiration: "Reports/RefillExpirationReports",
    POST_Reports_ProfitRevn: "Reports/PRRSummaryReportGeneration",
    POST_Reports_ErxReport: "Reports/ErxReport",
    POST_Reports_MultiErxReport:"Reports/ErxMessageData",
    POST_AnnotationPrint:"Reports/RxMessageData",
    POST_Reports_Analyse: "Reports/AuditTrails",
    POST_Reports_Inventory: "Reports/InventoryReport",
    POST_Report_PriceList: "Reports/PriceListReport",
    PUT_REPLACEALL:"Rx/RepriceList",
    POST_Report_HipaaAck: "Reports/HippaAcknowledgementReport",
    POST_Report_NotPickUpRx: "Reports/RxsNotPickedUpReport",
    POST_Report_TransferredRx: "Reports/RxTransferredReport",
    POST_Report_PickedUpRx: "Reports/PrintRxPickUpLogReport",
    POST_Report_PickedUpRxDetail: "Reports/RxsPickedUpReport",
    POST_Report_SignatureClaimsLog: "Reports/SignatureClaimsLog",
    POST_Report_RxListWithNoAttDocs: "Reports/DMSNoAttachedReports",
    POST_RxListWithAttDocs: "Reports/DMSAttachedReports",
    POST_Report_RxListWithAttDocs: "Reports/DMSAttachedReportGeneration",
    POST_Print_RejectedTrans: "Reports/TransmissionRejected",
    POST_DRUG_PRICE_CHECK:"Reports/DrugPriceCheck",
    POST_Price_Change_Report : "Reports/PriceChange",
    POST_PHARM_CUSTOM_REPORTS: "Reports/PharmacyCustomReport",
    POST_RETURN_TO_STOCK: "Reports/ReturnToStockReportGeneration",
    POST_Reports_PartialFill: "Reports/PartialFill",
    POST_Reports_ViewDataSet: "Reports/PRRDetailViewDataSetData",
    POST_Reports_ViewDataSetReport: "Reports/PRRDetailViewDataSetReportGeneration",
    POST_Reports_GSDD_PriceUpdate: "Reports/GsddPriceUpdate",
    POST_Report_POSCopayCollectedDetail: "Reports/POSCopayCollectedReport",
    POST_Reports_CounsellingReport: "Reports/Counselling",
    // GET_ReportWriter_ElemFilters: "Reports/ReportWriterElementFilters",
    GET_ALL_REPORTS: "Report/GetAllReports",
    GET_ReportWriter_ElemFilters: "Report/MasterData",
    POST_ReportFilters: "Report/GetAllReportFilters",
    DELETE_CUST_REPORT: "Report/DeleteReport",
    POST_SAVE_CUSTOMREPORT:"Report/ConfigureCustomReport",
    GET_REPORTDETAILS: "Report/GetReportDetails/{reportId}",
    UPDATE_REPORTDETAILS: "Report/EditReport",
    POST_ViewData_Remittance: "Report/EDI835Data",
    // POST_UPDATE

    // Pickup endpoints

    GET_Pickup_Details: "Rx/RxPickUp/{prescId}",
    POST_Add_Pickup_Details: "Rx/RxPickUp",

    // TransferIn Details

    GET_TransferIn_Details: "Rx/GetTransferRxInDetails/{rxnum}/{refillNum}/{filter}",

    // refill save rx endpoints

    POST_Pres_Refill: "Rx/PrescRefill/{prescNum}/{refillNum}",
    POST_PriorApproval: "Transmit/PriorApp",

    // Audit Log Get

    POST_Get_AuditLog: "Utility/Audit/Get",
    POST_Priv_Check: "Account/PrivCheck",

    // RefillQueue endpoints

    POST_Get_Refill_Queue: "Rx/RefillsQueue",
    POST_Add_Queue_Fm_Reports: "Reports/AddFromReportdata",
    POST_Add_To_Refill_Queue: "Rx/AddToRefillsQueue",
    POST_Delete_Refill_Queue: "Rx/DeletefromRefillsQueue/{IsCompleteEmpty}/{FromRefQ}",
    GET_PrescriberSpiData:"eRx/PrescriberSpiData/{prescriberId}",

    // Group Transmission End points
    POST_TransUnbilledQueue: "Transmit/UnBilledQueue",
    PUT_BatchTransmission: "Transmit/BatchTransmission",
    PUT_GetPending_Rx: "Transmit/PendingBatchTransmission",
    POST_GroupTransmissions: "Transmit/GroupTransmissions",
    GET_MenuGroupTransmissions: "Transmit/MenuGroupTransmissions/{patientId}/{IsCompleted}/{groupnum}",
    GET_UserTransmissionHistory: "Transmit/UserTransmissionHistory",
    PUT_UpdateUserReviewStatus: "Transmit/UpdateUserReviewStatus/{prescrefillId}/{statusId}",

    // Prime ESC End points

    POST_PrimeESCToken: "PrimeESC/PrimeESCToken",
    GET_PrimeESC: "PrimeESC/PrimeESCSettings",
    GET_PrimeESC_Image: "PrimeESC/PrimeESignatureCapture/{rxid}",
    PUT_Update_Status: "PrimeESC/UpdateTokenStatus/{tokenId}/{activestatus}",
    GET_PrimeEsc_SearchByRx: "PrimeESC/SignatureByRx/{rxnum}",

    // Pay records End point

    GET_Pay_record: "Rx/RxPay/{prescRefillId}",
    DELETE_Rx_RxPay: "Rx/RxPay/{payId}",
    UPDATE_Rx_RxPay: "Rx/RxPay",

    GET_Pay_Details: "Transmit/RxPayRepDetails/{prescRefillId}",
    PUT_Benefit_Info: "Transmit/RxPayRepDetails",

    GET_Spi_Refresh: "eRx/SyncSpiNumbers/{npinumber}/{prescriberId}",

    GET_Sync_Prescriber_SPI: "eRx/SyncPrescriberSPI/{npinumber}/{SPI}/{prescriberId}",

    POST_Email_Valid: "Patient/DuplicateEmail",


    // Duplicate drug check in price list

    GET_Check_duplicate_Drug: "Rx/DuplicateDrugPopup/{DrugId}",


    // EPCS End Points

    GET_Login_Audit: "EPCS/EPCSLoginAudit",
    GET_Login_Access_Control: "EPCS/UserRole",
    GET_EPCSControlled_RxSearchFields: "EPCS/EPCSControlledRxSearchFields",
    POST_ControlRx_Change: "EPCS/EPCSControlledRxChangeReport",
    GET_UserAudit_Fields: "EPCS/UserAccountAuditFields",
    POST_Get_UserAudit: "EPCS/UserAccountAudit",
    POST_Get_Override: "EPCS/Override",

    // patient search

    POST_Patient_Data: "Patient/File",
    DELETE_Patient: "Patient/File/{patientId}",
    GET_PatientDeleteResponse: "Patient/DeleteResponse/{patientId}",
    GET_Prescriber_Data: "Prescriber/File",
    DELETE_Prescriber: "Prescriber/File/{prescriberId}",
    GET_PrescriberDeleteResponse: "Prescriber/DeleteResponse/{prescriberId}",
    GET_Drug_Data: "Drug/File",
    DELETE_Drug: "Drug/File/{drugId}",
    GET_DrugDeleteResponse: "Drug/DeleteResponse/{drugId}",

    // check duplicate Quick Code
    GET_Check_DuplicateQuickCode: "Drug/CheckDuplicateQuickCode/{drugId}/{quickcode}",

    // Get Privs and Roles

    GET_Privs_Roles: "Utility/AccessCategoryAndPrivs",
    GET_Duplicate_DrugLot: "Drug/CheckforDuplicateInactiveDrugLot/{drugid}/{lotnum}",

    // Save Assign NewFill

    PUT_RxFile: "Rx/RxFile",

    // Price List Calculation

    POST_PriceList_Calculation : "Rx/PriceCalculation",

    GET_Latest_Prescriber : "Prescriber/GetLatestPrescriberByPatientId/{patientId}",

    // Rph Dashboard

    POST_GetRph_Today_Data: "Rx/RphDashboard",
    POST_SaveDefault_RphFilter: "Rx/RphDashboard/Filters",
    GET_Default_RphFilter: "Rx/RphDashboard/Filters",

    // MAC WAC endpoint
        GET_MAC_WAC_DrugPricing: "Drug/MacandWac/{drugcode}",
        GET_MAC_WAC_UpdateFromMms: "Drug/MacandWac/{DrugCode}",
        POST_MAC_WAC_NewPriceValues: "Drug/MacWac",

    // Filed Rx Queue

    POST_Get_FiledRx_Queue: "Rx/FiledQueue",
    PUT_ChangeDefReasonAndFileDueDate: "Rx/ChangeDefReasonAndFileDueDate",

    // Label Rx Queue

    POST_Get_LabelRx_Queue: "LabelDesigner/LabelQueueData ",


    // Prime esc
    GET_Signatures_ByPatient: "PrimeESC/SignatureByPatient/{patientId}",
    GET_Signature_ACKSign: "PrimeESC/ShowPatientAcknoledgeSign/{transId}/{sigtype}",
    POST_Signature_TransHist: "PrimeESC/PatientTransactionHistory",
    GET_Signature_Print: "PrimeESC/PatientSignatureRecordPrint/{transId}/{sigtype}/{refillId}",
    GET_POSReceipt_Print: "Reports/POSReceipt/{pickUpRxId}",
    POST_Signature_GenerateReport: "PrimeESC/PatientTransactionHistoryReport",
    POST_Signature_ByDate: "PrimeESC/SignatureByDate",


    // New Audit
    POST_Save_Audit: "Utility/AuditLog",
    POST_Get_ErxChange_Details: "eRx/ChangeRequestData",
    GET_changeResponseStatus: "eRx/changeResponseStatus/{eRxOpId}",

    // NewRxRequest
    POST_NewRxRequest: "v2017071/eRx/NewRxRequest",
    GET_SPI: "{version}/eRx/PrescriberSpiData/{prescriberId}",

    // DDI Check

    GET_DDI_Drug: "Drug/Drug2DrugInteractions/{drugNDC1}/{drugNDC2}",

    // Defferred Reason List
    POST_Get_DfrdReason_List: "Rx/GetDeferredReason",
    POST_Add_DfrdReason: "Rx/DeferredReason",
    PUT_Update_DfrdReason: "Rx/DeferredReason",
    DELETE_DfrdReason: "Rx/DeferredReason/{id}",
    GET_DfrdReason_DeleteResponse: "Rx/DeferredReasonResponse/{id}",

    // bussiness Inteligence

    POST_GET_Patient_BI: "Patient/BI",
    POST_Prescriber_BI: "Prescriber/BI",
    POST_Insurance_BI: "Insurance/BI",
    POST_Drug_BI: "Drug/BI",
    POST_GET_My_Patients: "Patient/MyPatients",
    POST_GET_My_Patinets_Amount: "Patient/MyPatients/AmtRecal",

    // DMS
    POST_DocumentSubCategory: "Shared/DocumentSubCategory",
    GET_DocumentCategory: "Shared/DocumentCategory",
    GET_SubCat_CatId: "Rx/SubCategoryDetails/{catId}",
    POST_Document_SubCategory: "Shared/DocumentSubCategory",
    DELETE_DMSSubCategory: "Shared/DocumentSubCategory/{docSubId}/{canBeDeleted}",
    PUT_UPDATE_DocumentSubCategory: "Shared/DocumentSubCategory",
    POST_DMS_PROCESS_IMAGE: "Rx/DocumentManagementScan",
    GET_DMS_SUBCATS: "Rx/RxSubCategoryDetails",
    POST_DMS_SAVE_BATCH_DOCS: "Rx/SaveAttachedDocuments",
    POST_DMS_DOC_QUEUE_SEARCH: "Rx/DocumentQueue",
    GET_DOCUMENT_BY_ID: "Shared/Document/{docId}/{extn}",
    POST_IMPORT_DOCS_TO_QUEUE: "Rx/DocumentQueueScanNew",
    POST_DELETE_DOCS_FROM_QUEUE: "Rx/DeleteDocsFromQueue",
    PUT_UPDATE_DOCS_IN_QUEUE: "Rx/DocumentQueue",
    POST_ATTACH_DOCS_TO_ENTITY: "Rx/AttachDocumentToEntity",
    PUT_MARK_DOCS_TO_USER: "Rx/MarkedupDocuments",
    POST_ATTACH_RX_FROM_QUEUE: "Rx/ProcessDocForRx",
    DELETE_MOVE_TO_QUEUE: "Rx/DocQueueFromEntity/{docId}",
    GET_DMS_SUBCAT_SEQ: "Shared/DocumentSubCategoryPriority",
    POST_DMS_SUBCAT_SEQ: "Shared/DocumentSubCategoryPriority",
    POST_DMS_UNTAGLIST: "Rx/UntagAll",


    // Error Log
    POST_SHARED_ERRORLOG: "Shared/Error",

    // Transmission Reject Codes
    GET_TRANS_REJECT_CODES: "Transmit/RejectCodes",
    POST_SFAX_BATCHEDRXS: "Fax/BatchFax",
    POST_STOCK_RETURN_FILTER_DATA: "Transmit/UnpickedRxsForReturntostock",
    POST_REVERSE_TRANS: "Transmit/ReverseGroupTransmission",

    // Fax End Points
    POST_SFAX_REQUEST: "Fax",
    PUT_UPDATEFAXNO_TOPRESC: "Fax/Prescriber",
    POST_PRINT_LOCALLY: "Fax/Download",
    POST_FAX_HISTORY: "Fax/History",

    // Get Bucket specific Qty for Drug
    GET_Bucket_Drug_Qty: "Inventory/DrugBucketQtyInHand/{drugId}",

    // Fax related endpoints
    PUT_FAX_BATCHSTATUS:"Fax/BatchStatus",
    PUT_BATCHDELETE:"Fax/BatchDelete",
    POST_FAX_PROCESSBATCH:"Fax/ProcessBatch",
    POST_FAX_FOLLOWUPLOG:"Fax/FollowUpLog",
    POST_FAX_FOLLOUP_LIST: "Fax/FollowUp",
    PUT_FAX_MARK_RECORDS: "Fax/Mark",
    PUT_FAX_UPDATE_COMMENT: "Fax/Comments",
    PUT_FAX_DELETE: "Fax/Status",
    POST_FAX_RX_GENERATION: "Fax/RxGeneration",
    POST_FAXLOG:"Fax/Log",
    POST_FAX_CANCEL:"Fax/Cancel",
    POST_FAX_RESEND:"Fax/Resend",
    POST_FAX_REPORT: "Reports/FaxFollowUp",
    POST_SEND_FAX: "Fax/TransferOut",
    PUT_UPDATE_FAXREFILL_STATUS: "Fax/ProcessStatus",
    PUT_UPDATE_FAXNUM_TO_PHARMACY:"Fax/UpdateAndFax/{pharmacyNum}/{faxNum}",

    //reports

    POST_PRINT_REPORT: "Reports/TransferOutFaxReport",
    POST_INCOMING_FAX_LIST: "Fax/Incoming",
    POST_INCOMING_FAX_MARK: "Fax/MarkAsRead",
    POST_INCOMING_FAX_REMOVE: "Fax/Remove",
    POST_DOCUMENT_SPLIT_ASSIGN: "Fax/DocumentQueue",

    // ZipCode Check

    GET_ZipCode_Value: "Shared/AddressValidation/{stateId}/{cityId}",

    // Wijimo Grid Details

    GET_Saved_WjGrid_Det: "Utility/WijmoColumnOrder/{wijmokey}",
    POST_Save_WjGrid_Det: "Utility/WijmoColumnOrder",

    //TopPerformers

    GET_TOPPERFOMERS_DATA: "Reports/TopPerformersDropDownData",
    POST_TOPPERFORMERS_DATA: "Reports/TopPerformersReportData",
    POST_TOPPERFRMSREPORTDATA: "Reports/TopPerformersReportGeneration",
    POST_TOPPERFRMS_ADDRESSLABEL_DATA: "Reports/TopPerformersAddressLabelReport",

    // BatchEdit
    POST_Get_StngForBatchEdit: "Rx/Patient/RefillSettings",
    POST_Save_Batch_Edit: "Rx/Batch",

    //Review Rx Tag

    POST_REVIEW_RX_TAG: "Rx/ReviewRxsTagged",
    POST_RX_CLEARTAG: "Rx/ClearRxTag",
    POST_RX_REVIEW_REPORT: "Reports/TaggedRxs",
    GET_TAG_RX_HISTORY: "Rx/RxFollowUpTagHistory/{prescRefillId}",

    //Partial Fill
    POST_GET_CummalativeDetails: "Rx/PartialFillCummulativeDetails",

    //REFILL_QUEUE_SCHEDULER

    POST_ADD_TASK_FOR_SCHEDULER: "ScheduledTask/AddTask",
    POST_SCHEDULE_TASKLIST: "ScheduledTask/TaskHistory",
    PUT_SCHEDULE_TASK: "ScheduledTask/DeleteTaskScheduleById",
    POST_RUNTASK_NOW: "ScheduledTask/RunTask",
    POST_GET_TASKEXCHIST: "ScheduledTask/TaskExecutionHistory",
    POST_TASKNAME_ALREADYEXISTS: "ScheduledTask/CheckTaskNameExistorNot",
    GET_LATEST_TASKINFO: "ScheduledTask/TaskScheduleById/{taskScheduleId}",

    // Submission Clarification Remove with 340B change
    GET_Has_SubmissionCla: "Rx/CheckSubclarification/{prescrefillId}/{insuPlanId}",
    POST_Remove_SubmissionCla: "Rx/RxSubclarification",

    // Wijimo and DWTLicense key endpoint

    get_Wijimo_DWTKey: "User/LicenseKeys",

    delete_Vendor_File: "Vendor/vendorDeleteResponse/{vendorId}",

    //envelope display
    POST_envelope_Display : "ControlFile/NotificationCount",
    POST_NotAutoSubmit : "Reports/ASAPAutoSubNot",
    POST_Display_Notification_Count : "ControlFile/NotificationSubCount",
    POST_ControlErrorDetails:"ControlFile/ControlErrorDetails",
    PUT_ControlErrorStatusUpdate : "ControlFile/ControlFileErrorStatusUpdate",
    POST_NY_Error_Details:"ControlFile/GetNYErrors",
     // Get message Data
     GET_MESSAGE_DATA: "Message/Data",
     POST_MESSAGE_SMSTEmplate: "Message/Template",
     POST_MESSAGE_PREFERENCE: "Message/MessagePreference",
     POST_MESSAGE_ReSendSubscription: "Patient/ReSendSubscription",
     GET_MESSAGE_TEMPLATEDATA: "Message/Templates/{messageTypeId}",
     POST_SMS_DATA: "Message/SMS",
     GET_MESSAGE_LOG: "Message/History",
     Get_MESSAGE_QUEUE_DATA: "Message/Log",
     CANCEL_SENDING_MESSAGE: "Message/Cancel",
     SAVE_QUEUEREFRESH: "Message/QueueRefreshlimit",
     GET_DATA_REFILL_DUE_MSG: "Reports/RefillDueMessage",
     SEND_DATA_REFILL_DUE_MSG: "Message/Refill",
     GET_MESSAGE_TEMPLATEDATA_BY_ID: "Message/Template/{messageTemplateId}",

     // Side Nav changes
     GET_SIDE_NAV_COUNTS: "Rx/QueueDataCounts",


     POST_GET_DROPDOWN_VALUES: "Reports/DailyLogDropDownsData",

     //While doing group Transmission  large price values check
     POST_LARGE_VALUES: "Transmit/BatchPriceCheck",

     //Phone number validation for sms
     GET_VALIDATE_PHONE: "Message/CheckPhone/{phoneNumber}",

     //Asynchronous report generation

     IS_ASYNC_REPORT_EXIST: "Reports/IsAsyncReportExists/{ReportName}",
     GENERATE_ASYNC_REPORT: "Reports/GenerateAsyncReport",
     GET_ASYNC_REPORT: "Reports/GetAsyncReport",
     GET_ASYNC_FILTERS: "Reports/GetAsyncFilters",
     ASYNC_REPORT_LOGIN: "Reports/AsyncReports",

     //ReportWriter Asynchronous report generation
     IS_ASYNC_REPORT_EXIST_RW: "Report/IsAsyncReportExists/{ReportId}/{ReportName}",
     GENERATE_ASYNC_REPORT_RW: "Report/GenerateAsyncReport",
     GET_ASYNC_REPORT_RW : "Report/GetAsyncReport",
     GET_ASYNC_FILTERS_RW: "Report/GetAsyncFilters",
     IS_DUPLICATE_REPORTNAME_RW: "Report/CheckDuplicateReportName",

     //Delete Rx Tag
     DELETE_RxTag: "Rx/DeleteRxTagInformation/{deleteId}",

    GET_ChangePrescriberAddress: "Prescriber/ChangePrescriberAddress/{prescNum}/{addressId}",
    GET_ReleaseNotes : "User/ReleaseNotes/{pageId}",
    GET_ReleaseNotesPages: "User/ReleaseNotesPages",
    POST_ReleaseNotesBuildVersion : "User/ReleaseNotes/IsViewed",
    GET_BuildVersion : "User/ReleaseNotes/BuildVersion",
    //User Guide
    GET_UserGuide: "User/UserGuide/{pageKey}",

    //Drug Categories
    DELETE_DrugCategories: "Drug/UserDrugCategory/{catId}/{isCheck}",
    GET_DrugCategoriess: "Drug/UserDrugCategory/Search",
    POST_AddDrugCategories: "Drug/UserDrugCategory",
    POST_UpdateDrugCategories: "Drug/UserDrugCategory",
    AddOrUpdate_DrugCategories: "Drug/AddOrUpdateDrugCategories",


    //Intake - Queue

    POST_IntakeQueuedata : "Rx/IntakeQueue",

    //rx Valid or not
    Rx_ExistorNot: "PrimePOS/RxExists",
    Rx_ExistorNotWithObj: "Shared/RxExists",
    //Get Intake List
    Rx_GetIntakeQueue: "Rx/GetIntakeQueue",

    //SSO redirection
    POST_SSOREQUEST: "SSO/ePrimeRxRedirecturl",

     //Get file Generator List
     GET_FILEGENERATORLIST: "patient/RxHistoryforHOA",
     POST_SENDPACKING: "Rx/GenerateHOAFile",

     //Interface Settings
     POST_Interface_PharmacySettings: "Tenant/PharmacySettings",
     POST_Interface_ModifyPharmacySettings: "Tenant/ModifyPharmacySettings",
     GET_Interface_DscsaSettings: "Settings/DSCSASettings",
     POST_Interface_ModifyDscsaSettings: "Settings/UpdateDSCSASettings",


    //Notes SubCategories  

    GET_NoteSubCategories: "Shared/NotesSubCategory",
    GET_NoteSubCategoriesById: "Shared/NotesSubCategory/{noteSubCategoryId}",
    GET_NoteCategoriess: "Shared/NoteCategories",
    DELETE_NoteCategories: "Shared/NotesSubCategory/{noteSubCategoryId}/{isType}",
    POST_AddNoteCategories: "Shared/NotesSubCategory",
    PUT_UpdateNoteCategories: "Shared/NotesSubCategory",
    AddOrUpdate_NoteCategories: "Drug/AddOrUpdateDrugCategories",

    //Vaccine Immunization Generator endpoints
    GET_VaccinesDropDownData: "Rx/GetVaccineDropDownData",
    POST_VaccineFileData: "Rx/GetVaccineFile",
    POST_ImmunizationFileGeneration: "Rx/ImmunizationFileGeneration",
    UPDATE_COMPLETION_STATUS: "Rx/CompletionStatusUpdate",

    //Logicoy
    POST_CheckLogiCoyRegitration:"Patient/CheckLogiCoyRegistration/{stateId}",
    POST_LogiCoyPDMPReport:"Patient/LogiCoyPDMPReport/{stateCode}",
    // Get Rx History for Current View
    GET_Rx_CurrentView: "Patient/CurrentViewRxHistory",
    //EDI810
    POST_Transaction_List:"Inventory/TransType/Fetch",
    POST_NDC_Search: "Drug/Search",

    //Secure Patient communication
    //primecommproxy.dev.eprimerx.com
    POST_MESSAGE_CONVERSATION: "PrimeRxCloud/Conversation",
    POST_MESSAGE_CHATSUMMARY: "PrimeRxCloud/Summary",
    POST_MESSAGE_SMSLOG: "PrimeRxCloud/SMSLog",
    //primecomm.dev.eprimerx.com
    POST_MESSAGE_UNREADCOUNT: "api/v1/CommunicationLog/UnReadMessageCount",    
    POST_MESSAGE_MARKASREAD: "api/v1/Message/MarkAsRead",
    NEGOTIAGE:"negotiate",
    CHAT_PAGE_MIN:0,
    CHAT_PAGE_MAX:200,

    // PA Queue endpoints
    GET_PriorAuth_PAQueue : "PriorAuth/PAQueue",
    POST_PriorAuth_PAApproved:"PriorAuth/MarkPAApproved",
    POST_PriorAuth_Send_PARequest : "PriorAuth/SendPA",
    GET_PAQueueStats:"PriorAuth/PAQueueStats/{isForQueueData}",
    POST_GetPharmacy_VendorConfiguration:"VendorConfiguration/GetPharmacyVendorConfiguration",
    POST_UpdatePharmacy_VendorConfiguration:"VendorConfiguration/UpdatePharmacyVendorConfiguration",

    //Transfer in req
    Transfer_In_Req_Send: "v202311/eRx/TransferinInitiation",
    Resend_Transfer_In_Req_Send: "v202311/eRx/RetryTransferinInitiation/{eRxId}/{ErxOperationId}",

}

