import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ChangeDetectorRef} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MsgConfig } from 'src/app/app.messages';
import { AlertService, ErxService, RxTransferService, UserService } from 'src/app/services';
import { CommonUtil } from 'src/app/utils';

@Component({
  selector: 'app-transfer-in',
  templateUrl: './transfer-in.component.html',
  styleUrls: ['./transfer-in.component.scss']
})
export class TransferInComponent implements OnInit {
  eRxModelFG: FormGroup;
  @Output() ClosePopUpModal = new EventEmitter();
  @ViewChild("TransferRx", { static: true })
  TransferRx: any;
  modelRef: any;
  msgConfig = MsgConfig;
  selectedPatientDetails: any;
  selectedTransferTypeDetails: any;
  selectedDruginformation: any;
  awaitInfo: any;
  unsubscribe$: Subject<void> = new Subject();
  @Input() IsfrmPatHis;
  @Input() TransferType: string;
  @Input() currentDetails;
  
  @Input()
  set PatientDetails(patient : any){
     this.selectedPatientDetails = patient;
  }

  @Input()
  set AwaitInfo(awaitInf: any) {
    if (awaitInf) {
      this.awaitInfo = awaitInf;
      if (awaitInf?.Drug)
      {
        this.selectedDruginformation = awaitInf?.Drug?.result[0];
        this.eRxModelFG.controls['drugID'].patchValue(awaitInf?.Drug?.result[0].id);
        this.eRxModelFG.controls['IsTransferIn'].patchValue(false);
      }
      else
       {
        this.selectedTransferTypeDetails = "All";
        this.eRxModelFG.controls['IsTransferIn'].patchValue(true);
       }
    }
  }
  
  constructor(private _fb:FormBuilder, 
              private _cdr: ChangeDetectorRef,
              private _eRxService:ErxService,
              private _alrtSev: AlertService,
              private _userService: UserService,
              private _commonUtil: CommonUtil,
              private _modalService:NgbModal) {
    this.eRxModelFG = this._fb.group({
      patientID: new FormControl(null, [Validators.required]),
      drugID: null,
      IsTransferIn : false,
      sourcePharmacy: {
        NCPDPID : null,
        BusinessName: null,
        Specialty : null,
        Address : {
          AddressLine1: null,
          AddressLine2: null,
          City: null,
          State: null,
          ZipCode: null,
          CountryCode: null,
          PhoneNumbers: []
        }
      },
      Notes:{},
      destinationPharmacyID: new FormControl(null, [Validators.required]),
      sourceNPI: null,
      drugNDC: null,
      drugName: null,
      drugDesc: null,
  });
   }

  ngOnInit(): void {
    this.eRxModelFG.controls['destinationPharmacyID'].patchValue(
      this._userService.getToken("UniquePharmacyId")
    )
    this.modelRef = this._modalService.open(this.TransferRx, {centered: true,
      backdrop: false, keyboard: false ,windowClass: "max-modal-lg-size transfer-in"});
  }
  selectedDrugDetails(drug: any) {
    this.selectedDruginformation = drug ? drug: null;
      if(drug) {
          this.eRxModelFG.patchValue({
            drugID: drug.id,
            drugName: drug.drugname,
            drugNDC: drug.drugcode,
            drugDesc: drug.drugfullname
          });
      } else {
        this.eRxModelFG.patchValue({
          drugID: null,
          drugName: null,
          drugNDC: null,
          drugDesc: null
        });
      }
  }
  closePopup() {
      this.ClosePopUpModal.emit(false);
  }
  selectedTransTypeDetails(details: any) {
      this._cdr.detectChanges();
      this.selectedTransferTypeDetails = details;
      if(details && details !== "All")
      {
        this.eRxModelFG.controls['drugID'].patchValue(details);
        this.eRxModelFG.controls['IsTransferIn'].patchValue(false);
      }
      else if(details == "All")
      {
        this.eRxModelFG.controls['IsTransferIn'].patchValue(true);
      }
  }
  selectedPatientID(patientId) {
      this.eRxModelFG.controls['patientID'].patchValue(patientId);
  }
  transferSingleNotes(event) {
        event["PopupScreenCodes"] = event["PopupScreenCodes"]?.length ? event["PopupScreenCodes"]?.toString() : null;
        event["IsfromTransferIn"]   = true;
    this.eRxModelFG.controls['Notes'].patchValue(event);
  }
  sendTransferInReq() {
    if(this.eRxModelFG.valid && (this.eRxModelFG.value['drugID'] || this.eRxModelFG.value['IsTransferIn']))
    {
      this._eRxService.sendTransferInReq(this.eRxModelFG.value, this._commonUtil.getErxVersion()).subscribe((resp: any) => {
        if(resp?.Result) {
          this._alrtSev.success(MsgConfig.Transfer_In_Req);
        } else  if(resp?.Message && !resp?.Result) {
          this._alrtSev.error(resp.Message);
        }
        this.closePopup();
    });
    }
  }
  selectedToPharmacy(selectedPharmacy) {
    console.log(selectedPharmacy , "selectedPharmacy");
    if(selectedPharmacy) {
      this.eRxModelFG.controls['sourceNPI'].patchValue(selectedPharmacy?.NPINum);
      let phones: any = [];
      if(selectedPharmacy.Phone2) {
        phones.push(selectedPharmacy.Phone2);
      }
      if(selectedPharmacy.Phone1) {
        phones.push(selectedPharmacy.Phone1);
      }
      this.eRxModelFG.controls['sourcePharmacy'].patchValue({
        NCPDPID : null,
        BusinessName: null,
        Specialty : null,
        Address : {
          AddressLine1: selectedPharmacy.AddressLine1,
          AddressLine2: selectedPharmacy.AddressLine1,
          City: selectedPharmacy.city,
          State:selectedPharmacy.state,
          ZipCode: selectedPharmacy.zipcode,
          CountryCode: selectedPharmacy.CountryId,
          PhoneNumbers: phones.length ? phones : []
        }
      })
    }
  }
}
