<div class="exprx--common-block">
    <div class="eprx--block__header">
        <div class="eprx--header__heading">
            <span>Drug Price Update</span>
        </div>
    </div>
    <div class="eprx--block__content">
        <div class="col-sm-12 padding-0 erx--body">
            <div class="row">

                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                    <eprx-date-picker [LabelText]="'From Date'" [PlaceHolder]="''" [ControlName]="'FromDateTime'"
                        [FormGroupName]="searchFG" [AutoFocus]="true" [MinDate]="" [MaxDate]="" [IsRequired]="true"
                        (keydown.enter)="getPriceData()">
                    </eprx-date-picker>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                    <eprx-date-picker [LabelText]="'To Date'" [PlaceHolder]="''" [ControlName]="'ToDateTime'"
                        [FormGroupName]="searchFG" [MinDate]="" [MaxDate]="" [IsRequired]="true"
                        (keydown.enter)="getPriceData()">
                    </eprx-date-picker>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 align-button text-right allign-bottom">
                    <button title="Search / Refresh" type="submit" class="hotkey_success pull-right" (click)="getPriceData()"appShortcutKey [AltKey]="'f'"><span>F</span>Search</button>
                    <button type="button" class="hotkey_success pull-right" (click)="onClickOnClear()"appShortcutKey [AltKey]="'l'"><span>L</span>clear
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="eprx--block__content pb-3">
        <wj-flex-grid #priceUpdateList [headersVisibility]="'Column'" [itemsSource]="priceUpdateData['wjSource']" [ngClass]="isDataExists ? '':'no-data'"
            [isReadOnly]="true" [columnPicker]="'drugPriceUpdateListWJ'" [selectionMode]="'None'" (updatedView)="init(priceUpdateList)">
            <wj-flex-grid-column [header]="rxheader['hName']" [binding]="rxheader['hName']" [visible]="rxheader['isVisible']"
                *ngFor="let rxheader of wjHeaders ; let i = index" [width]="rxheader['width']"
                [format]="(rxheader['hName'] === 'New Price') || (rxheader['hName'] === 'Old Price') ?'c2' : ''">
            </wj-flex-grid-column>
            <app-no-data-found *ngIf="!isDataExists"></app-no-data-found>
        </wj-flex-grid>
        <app-eprx-pagination [TotalCount]="priceUpdateData['count']" [PageSize]="Size" [WijmoName]="priceUpdateList" *ngIf="priceUpdateData['count'] > 0"
            [GridName]="'priceUpdateList'" (OnPageSelected)="setPage($event)" (OnSizeSelected)="setSize(Size)">
        </app-eprx-pagination>
    </div>
</div>
