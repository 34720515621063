<div class="insurences--tabing navTabStyles d-flex flex-row">
    <ul ngbNav #nav="ngbNav" [(activeId)]="activeTabId" class="nav-tabs col-11" #InsuranceTABS
        (tabChange)="beforeChange($event?.nextId)">
        <li [ngbNavItem]="'Patient1'"  (click)="beforeChange('Patient1')">
            <a ngbNavLink class="nav-link" id="Patient1">
                <div class="txt">
                    <div>Plan Info</div>
                </div>
            </a>
        </li>
        <li [ngbNavItem]="'Patient2'" (click)="beforeChange('Patient2')">
            <a ngbNavLink class="nav-link">
                <div class="txt">
                    <div>Settings </div>
                </div>
            </a>
        </li>
        <li [ngbNavItem]="'Patient3'" (click)="beforeChange('Patient3')">
            <a ngbNavLink class="nav-link">
                <div class="txt">
                    <div>Transmission Settings </div>
                </div>
            </a>
        </li>
        <li [ngbNavItem]="'Patient4'" (click)="beforeChange('Patient4')">
            <a ngbNavLink class="nav-link">
                <div class="txt">
                    <div>Other Ins
                        <br> Payer ID</div>
                </div>
            </a>
        </li>
        <li [ngbNavItem]="'Patient6'" (click)="beforeChange('Patient6')">
            <a ngbNavLink class="nav-link">
                <div class="txt">
                    <div>Notes  </div>
                </div>
            </a>
        </li>
        
        <li [ngbNavItem]="'Patient7'" (click)="beforeChange('Patient7')">
            <a ngbNavLink class="nav-link" [ngClass]="{ 'avail-rec': insuranceInfo?.IsDocumentExists }">
                <div class="txt">
                    <div>Documents</div>
                </div>
            </a>
        </li>
       
        <li [ngbNavItem]="'Patient8'" (click)="beforeChange('Patient8')">
            <a ngbNavLink class="nav-link">
                <div class="txt">
                    <div>Ins Billing
                        <br> File Settings</div>
                </div>
            </a>
        </li>
        
        
       
    </ul>
    <ul class="col-1 height_2_9rem border_bottom_0_21_rem_blue">
        <li class="text-right">
            <app-user-guide-icon *ngIf="!insuId" [ActiveTabId]="'AddInsurance'"></app-user-guide-icon>
        </li>
    </ul>
</div>
    
    <ng-template #warning let-c="close" let-d="dismiss">
        <app-custom-modal (oncrossBtnClick)="c('Close click');closeWarning()">
                <h4 header>Insurance</h4>
                <span body>Please save Insurance record first.
                </span>
                <button footer autofocus  type="button" class="hotkey_primary" (click)="c('Close click');" (click)="emitAddinsurance()" appShortcutKey InputKey="y"> <b>Y</b>YES</button>
                <button footer type="button" class="hotkey_primary" (click)="c('Close click');closeWarning()" appShortcutKey InputKey="n"> <b>N</b> NO</button>
            </app-custom-modal>

    </ng-template>
