import { Component, OnInit, Input, Output, EventEmitter, HostListener, OnDestroy } from "@angular/core";
import { RxPatientModel, KeyCodes, SystemData } from "src/app/models";
import { FormGroup, FormArray, FormControl } from "@angular/forms";
import { RxService, AlertService, InsuranceService, CommonService } from "src/app/services";
import { MiscUtils, WijimoUtil } from "src/app/utils";
import { Subject, Subscription } from "rxjs";
import { CollectionView } from "@grapecity/wijmo";
import * as wjcGrid from "@grapecity/wijmo.grid"
import * as moment from "moment";
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { takeUntil } from "rxjs/operators";

@Component({
    selector: "app-fill-recs",
    templateUrl: "./fill-recs.component.html"
})
export class FillRecsComponent implements OnInit, OnDestroy {
    @Input() insuSetng: any;
    @Input() insuDrpLst: any;
    @Input() systemData: SystemData;

    @Input()
    set RxPatientInfo(data: RxPatientModel) {
        this.rxPatientModel = data;
    }

    @Input()
    set RxFG(fg: FormGroup) {
        this.rxFG = fg as FormGroup;
    }


    @Output()
    SaveRxInfo = new EventEmitter<null>();

    @Output()
    CloseFillRecords = new EventEmitter<any>();

    modelRef: any;
    rxFG: FormGroup;
    rxPatientModel: RxPatientModel;
    activeModal: boolean;
    unsubscribe$ : Subject<void> = new Subject();
    insuSettings$: any;
    insuDropList$: any;
    fileRecordsFlex: any;
    fileRecordsWj: CollectionView;
    wjHeaders: { hName: string; isVisible: boolean; width: number; }[];

    @HostListener("window:keydown", ["$event"])
    keyEventAlt(event: KeyboardEvent) {
        if (event.altKey && event.which === KeyCodes.Key_h) {
            event.preventDefault();
            this.changeFillRecInfo();
        }
    }

    // @HostListener("window:keyup.alt.c", ["$event"])
    // keyEventC(event: KeyboardEvent) {
    //     event.preventDefault();
    //     this.changeFillRecInfo();
    // }

    // @HostListener("window:keydown.alt.s", ["$event"])
    // keyEvents(event: KeyboardEvent) {
    //     event.stopPropagation();
    // }

    constructor(private _miscUtils: MiscUtils, private _alertService: AlertService,
        private _rxService: RxService, private _insurService: InsuranceService, private _wijimoUtils: WijimoUtil,
        private currencyPipe: CurrencyPipe, private decimalPipe: DecimalPipe, private _commonSer: CommonService) {
        this.insuSettings$ = this._insurService.insuSettings$;
        this.insuDropList$ = this._insurService.dropdownData$;
    }

    ngOnInit() {
        this.activeModal = false;
        this.generateWJ();
        this.patchDefaultValHeaders();
        this._wijimoUtils.wjSavedData$.pipe(takeUntil(this.unsubscribe$)).subscribe(w => {
            if (w && w["WijmoKey"] && w["WijmoKey"] === "fileRecordsWJ") {
                this.patchDefaultValHeaders();
            }
        });
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    get OtherAmntMisc(): FormArray {
        return this.rxFG.controls.RxOtherAmount.get(
            "OtherAmntMisc"
        ) as FormArray;
    }

    get UnitPriceUNC(): FormControl {
        return this.rxFG.controls["PrescReFill"].get(
            "UnitPriceUNC"
        ) as FormControl;
    }

    changeFillRecInfo() {
        this.activeModal = !this.activeModal;
    }

    saveOtherAmount(val) {
        this.activeModal = false;
        this.generateWJ(true);
    }

    previousPage() {
        this.CloseFillRecords.emit("Patient1");
    }

    init(flex: wjcGrid.FlexGrid) {
        this.fileRecordsFlex = flex;
    }
    async patchDefaultValHeaders() {
        const storedWJ = await this._wijimoUtils.getWJSavedData("fileRecordsWJ");
        this.wjHeaders = this._wijimoUtils.patchDefHeader("fileRecordsWJ", storedWJ);
    }
    generateWJ(frmSave?) {
        if (this.rxFG.value.PrescReFill) {
            const data = this.rxFG.value.PrescReFill;
            const partialData = this.rxFG.value.partialCummulativeDetails;
            this.fileRecordsWj = new CollectionView([{
                "Fill#" : this._commonSer.checkIsPartialFill(data, this.rxFG.value.Prescription) ? data.PartialFillNo : " ",
                "Ref#": ((data.ReFillNum) || (data.ReFillNum === 0)) ? data.ReFillNum : "",
                "Fill Date": data.FillDtTm ? moment(data.FillDtTm).format("MM/DD/YYYY") : "",
                "Total Qty Disp.": partialData.TotDispQty ? this.decimalPipe.transform(partialData.TotDispQty, '1.3-3') : "0.000",
                "Qty Disp.": data.DispQty ? this.decimalPipe.transform(data.DispQty, '1.3-3') : "",
                // "Qty Remaining": partialData.TotRemQty ? this.decimalPipe.transform(partialData.TotRemQty, '1.3-3') : "",
                "Total Rx Qty Rem": this.rxFG.value.TotalRemainingQty ? this.decimalPipe.transform(this.rxFG.value.TotalRemainingQty, '1.3-3') : "0.000",
                "Days": data.SupplyDays ? data.SupplyDays : "",
                "Total Ingr. Cost": partialData.TotIngCost ? this.currencyPipe.transform(partialData.TotIngCost) : 0.00,
                "Ingredient Cost": data.Price ? this.currencyPipe.transform(data.Price) : 0.00,
                "Dispensing Fee": data.ProfFee ? this.currencyPipe.transform(data.ProfFee) : 0.00,
                "Usual and Custom": data.UnitPriceUNC ? this.currencyPipe.transform(data.UnitPriceUNC) : "",
            }]);
        }
        if(frmSave){
            this.previousPage();
        }
    }

}
