
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WebPubSubClient, WebPubSubClientCredential } from '@azure/web-pubsub-client';
import { environment } from 'src/environments/environment';
import { constant } from '../app.constants';
import { IdentifierTypeEnum, ParticipantTypeEnum } from '../models/messaging.enum';
import { Subject } from 'rxjs';
import { UserService } from './user.service';
import { AlertService } from "src/app/services";
@Injectable({
    providedIn: 'root'
})
export class WebPubSubService {
    private client: WebPubSubClient; 
    public isConnected = false;
    private newMessageSubject = new Subject<any>();
    private newMessageReceived = new Subject<any>();
    newMessage$ = this.newMessageSubject.asObservable();
    newMessageReceived$ = this.newMessageReceived.asObservable();
    userId:any;
    constructor(private http: HttpClient,private _userService: UserService, private _alertService: AlertService) {
    }
    async initializePharmacistWebSocket(npi: string) {
        const reqHeaders = {
            headers: new HttpHeaders().set("WebPubSubURL", "true")
        };
        try {
            const apiUrl = `${environment.Negotiate_apiBaseUrl}/${constant.NEGOTIAGE}?userid=${npi}`;
            const response: any = await this.http.get(apiUrl, reqHeaders).toPromise(); 

            const clientAccessUrl = response?.url;
            if (!clientAccessUrl) {
                throw new Error('clientAccessUrl is undefined');
            }

            this.client = new WebPubSubClient({
                getClientAccessUrl: clientAccessUrl,
            } as WebPubSubClientCredential);

            this.client.on("connected", (e) => {
                console.log(`Connected: ${e.connectionId}.`);
            });

            // Listen for messages from patients 
            this.initializeWebSocket();

            await this.client.start();
            this.isConnected = true;
            console.log('WebPubSub WebSocket connection established'); 
        } catch (error) {
            console.error('Error initializing WebSocket for pharmacist:', error);
        }
    } 

    private initializeWebSocket() {
        if (this.client) {
            this.client.on("group-message", (e) => {
                if(e && e?.message?.data){
                    const resMSG = e?.message?.data || '';
                    const fromPat = JSON.parse(resMSG as any);
                    console.log('Received message from patient:',fromPat);   
                    this.newMessageSubject.next(fromPat); 
                    this.newMessageReceived.next({
                        patientID: fromPat?.Sender?.Identifier, 
                        lastMessage: fromPat?.MessageText,
                        lastMessageReceivedAt: fromPat?.MessageReceivedAt
                    });
                }
            });
        } else {
            console.error("WebSocket client is not initialized");
        }
    }

    isWebSocketConnected(): boolean {
        return this.isConnected;
    }

     // Send message to a specific patient
    async sendMessageToPatient(npi: string, patientId:string, message: string, pharmacyName?:string, userName?:string) {
        this.userId = this._userService.getToken("UserId");
        if (!this.isConnected || !this.client) {
            console.error('WebSocket client is not connected. Cannot send message.');
            return;
        }

        try {
                // Send the message event to the server 
                await this.client.sendEvent('message', {
                    "messageText": message,
                        "sender": {
                            "applicationId": 1,
                            "participantType": ParticipantTypeEnum.PHARMACY,
                            "applicationDescription": "",
                            "identifier": npi,
                            "identifierType": IdentifierTypeEnum.NPI,
                            "userName": this.userId
                        },
                        "recipient": {
                            "participantType": ParticipantTypeEnum.PATIENT,
                            "recipientMobileNumber": "",
                            "Identifier": patientId,
                            "IdentifierType": IdentifierTypeEnum.PATIENT,
                            "userName": userName
                        }
                    }, 'json');                
                
                console.log(`Message sent to patient ${patientId}: ${message}`);
            } catch (error) {
                console.error('Error sending message to patient:', error);
            }
    }
    storeMessages(messages: any[], patID:any) {
        if (messages && patID) {
            localStorage.setItem(`messages_${patID}`, JSON.stringify(messages));
            console.log(JSON.parse(localStorage.getItem(`messages_${patID}`)));
        }
    }

    getStoredMessages(patID:any): any[] {
        const storedMessages = localStorage.getItem(`messages_${patID}`);
        return storedMessages ? JSON.parse(storedMessages) : [];
    }

    clearStoredMessages(patID:any) {
        localStorage.removeItem(`messages_${patID}`);
    }
}
