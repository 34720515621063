import { AuditLogUtils } from "./../../../utils/audit-log.util";
import { MsgConfig } from "src/app/app.messages";
import {
    Component,
    OnInit,
    ViewChild,
    EventEmitter,
    Output,
    OnChanges,
    AfterViewInit,
    OnDestroy,
    ChangeDetectorRef,
    HostListener,
    Input
} from "@angular/core";
import { DME, RxAuditLog } from "./../../../models";
import { FormBuilder, FormGroup } from "@angular/forms";
import { NgbModal, NgbModalConfig } from "@ng-bootstrap/ng-bootstrap";
import { RxService, AlertService, CommonService, RxObservableService } from "../../../services";
import * as _ from "lodash";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs/internal/Subscription";
import { RxStore } from "src/app/store";
import { NewAuditUtils } from "src/app/utils";

@Component({
    selector: "app-dme",
    templateUrl: "./dme.component.html",
    styles: []
})
export class DmeComponent
    implements OnInit, OnChanges, AfterViewInit, OnDestroy {
    dmeFG: FormGroup;
    modalRef: any;
    dMEDefaultValues: any;
    dMEValues: Object;
    originalData: any;
    actvRouteName: any;
    prescNumber: any;
    addDME: boolean;
    showPopUp = false;
    rxId: number;
    subscriptions = new Subscription();
    prevData: any;
    RxData: any;
    routeFrom: any;
    compDesOnSave: boolean;

    @Input()
    set SavedData(data) {
        if (data) {
            this.prevData = data;
        }
    }

    @Input() rxFG: FormGroup;

    @Output()
    IsPopUpClosed = new EventEmitter<any>();

    @Output()
    SaveDMEInfo = new EventEmitter<null>();

    @ViewChild("DME")
    DME: any;

    @HostListener("window:keydown", ["$event"])
    keyEventAlt(event: KeyboardEvent) {
        if (event.altKey && this._modalService.hasOpenModals()) {
             if (event.which === 67) { // c
                 event.preventDefault();
                this.previousPage();
                this.closeModal();
            } else if (event.which === 83) { // s
                if (this.routeFrom === "er" || this.routeFrom === "brx") {
                    if (this.addDME) {
                        event.preventDefault();
                        this.saveDME();
                    } else {
                        event.preventDefault();
                        this.updateDME();
                    }
                } else if (this.routeFrom === "nr" || this.routeFrom === "nrf" || this.routeFrom === "rf") {
                    event.preventDefault();
                    this.newRxDme();
                }
            }
        }
    }

    constructor(
        private _fb: FormBuilder,
        private _route: Router,
        private _rxService: RxService,
        private _alertServ: AlertService,
        private _auditUtils: AuditLogUtils,
        private _nwAudtUtl: NewAuditUtils,
        private _cdr: ChangeDetectorRef,
        private _actvRoute: ActivatedRoute,
        private _modalService: NgbModal,
        private _rxStore: RxStore
    ) {
        this.createDMEFG();
    }

    ngOnInit() {
        this.routeFrom = this.rxFG.get("rxType").value;
        this.RxData = this.rxFG.getRawValue();
        this.prescNumber = this.RxData.Prescription.PrescNum;
        this.checkGetDME();
    }

    async checkGetDME() {
        this.dMEDefaultValues = await this._rxService.getDiagnosisList().toPromise();
        if (this.RxData.Prescription && this.routeFrom === "er") {
            await this.getDMEData();
        }
        if (this.prevData && this.dMEDefaultValues) {
            this.dmeFG.patchValue(this.prevData);
        } else if (!this.prevData && this.dMEDefaultValues && !this.dMEValues) {
            await this.setDefaultValues();
        } else if (this.dMEValues) {
            await this.patchValue();
        }
        this.originalData = Object.assign({}, this.dmeFG.getRawValue());
        this.dmeFG.markAsPristine();
    }

    ngAfterViewInit() {
        this._cdr.detectChanges();
    }

    ngOnChanges(changes: any) {}

    ngOnDestroy() {
        this._cdr.detach();
        if (!this.compDesOnSave) {
            if (this.routeFrom === "er" && this.dmeFG.dirty) {
                if (this.addDME) {
                    this.saveDME("destroy");
                } else {
                    this.updateDME("destroy");
                }
            } else {
                this.newRxDme("destroy");
            }
        }
        // this.subscriptions.unsubscribe();
    }

    async getDMEData() {
        const resp = await this._rxService.getDMEDetails(this.RxData["Prescription"]["Id"]).toPromise();
        if (resp["length"] === 0 || resp === null) {
            this.addDME = true;
        } else {
            this.dMEValues = resp[0];
            this.addDME = false;
        }
    }

    createDMEFG() {
        this.dmeFG = this._fb.group(new DME()) as FormGroup;
    }

    setDefaultValues() {
        this.dmeFG.patchValue({
            AccidentCode: this.dMEDefaultValues.AccidentCode,
            ClinicSp: this.dMEDefaultValues.ClinicSp,
            DiagCode: this.dMEDefaultValues.DiagCode,
            IsDisability: this.dMEDefaultValues.IsDisability,
            IsFamilyPlan: this.dMEDefaultValues.IsFamilyPlan,
            IsHandiChildPg: this.dMEDefaultValues.IsHandiChildPg,
            IsRental: this.dMEDefaultValues.IsRental,
            LocatorCode: this.dMEDefaultValues.LocatorCode,
            ServiceCat: this.dMEDefaultValues.ServiceCat,
            ServicePlace: this.dMEDefaultValues.ServicePlace
        });
    }

    saveDME(type?: any) {
        this.dmeFG.controls["RxId"].patchValue(this.RxData["Prescription"]["Id"]);
        this.subscriptions.add(
            this._rxService
                .saveDMEdata(this.dmeFG.getRawValue(), this.RxData["Prescription"]["Id"])
                .subscribe(resp => {
                    if (resp) {
                        const dmeRxAudtMdl = new RxAuditLog().dmeAuditModel;
                        this._nwAudtUtl.saveAuditChange(
                            this.originalData,
                            this.dmeFG.getRawValue(),
                            "DME",
                            "Rx",
                            this.RxData["PrescReFill"]["Id"],
                            dmeRxAudtMdl
                        );
                        this.compDesOnSave = true;
                        if (this.showPopUp && !type) {
                            this.closeModal();
                        }
                        if (!type) {
                            this._alertServ.success(MsgConfig.SAVE_SUCCESS);
                        }
                    } else {
                        this._alertServ.error(MsgConfig.SAVE_FAIL);
                    }
                })
        );
    }

    updateDME(type?: any) {
        this.dmeFG.controls["RxId"].patchValue(this.RxData["Prescription"]["Id"]);
        this.subscriptions.add(
            this._rxService
                .updateDMEdata(this.dmeFG.getRawValue(), this.RxData["Prescription"]["Id"])
                .subscribe(resp => {
                    if (resp) {
                        const dmeRxAudtMdl = new RxAuditLog().dmeAuditModel;
                        this._nwAudtUtl.saveAuditChange(
                            this.originalData,
                            this.dmeFG.getRawValue(),
                            "DME",
                            "Rx",
                            this.RxData["PrescReFill"]["Id"],
                            dmeRxAudtMdl
                        );
                        this.compDesOnSave = true;
                        if (this.showPopUp && !type) {
                            this.closeModal();
                        }
                        if (!type) {
                            this._alertServ.success(MsgConfig.UPDATE_SUCCESS);
                        }
                    } else {
                        this._alertServ.error(MsgConfig.UPDATE_FAIL);
                    }
                })
        );
    }

    patchValue() {
        // tslint:disable-next-line:prefer-const
        for (let key in this.dMEValues) {
            if (key) {
                this.dmeFG.controls[key].patchValue(this.dMEValues[key]);
            }
        }
    }

    closeModal() {
        this.IsPopUpClosed.emit("Patient1");
    }

    previousPage() {
        // this.subscriptions.add(
        //     this._actvRoute.queryParams.subscribe(params => {
        //         if (params && params.fm) {
        //             if (params.fm === "er") {
        //                 this._route.navigate(["eprx/rx"], {
        //                     queryParams: { fm: params.fm }
        //                 });
        //             }
        //         }
        //     })
        // );
    }

    newRxDme(type?: any) {
        // this.SaveDMEInfo.emit(this.dmeFG.getRawValue());
        this._rxStore.storeDMEInfo(this.dmeFG.getRawValue());
        if (!type) {
            this.compDesOnSave = true;
            this._alertServ.success(MsgConfig.SAVE_SUCCESS);
            this.closeModal();
        }
    }

    /*focusOnNextElement() { // not in use
        setTimeout(() => {
            document.getElementById("locatorCodeDME").focus();
        }, 100);
    }*/

    foucsOnDIACode() {
        const element: any = document.getElementById("DiagnosisCodeDME");
        if (element) {
            element.focus();
        }
    }

    focusOnSave(event) {
        if (document.getElementById("dmeSave")) {
            event.preventDefault();
            document.getElementById("dmeSave").focus();
        }
    }

}
