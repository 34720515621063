<div class="exprx--common-block">
    <div class="eprx--block__header large_header">
        <div class="row">
            <div class="col">
                <span class="eprx--header__heading">
                </span>
            </div>
            <div class="col pull-right text-right">
                <button class="hotkey_success  hotkey_primary--Override" title="Add Insurance Restriction"
                autofocus appShortcutKey [AltKey]="'a'"(click)="openAddModal(popup)"><span>A</span>ADD INSURANCE PREFERENCES</button>
            </div>
        </div>
    </div>
    <div class="eprx--block__content">
        <wj-flex-grid #insuResList [headersVisibility]="'Column'" [itemsSource]="insuRestWJData['wjSource']" [isReadOnly]="true" [columnPicker]="'drugInsuRestListWJ'"
            [selectionMode]="'Row'" (updatedView)="init(insuResList)" [ngClass]="!isDataExists ? 'no-data':''">
            <wj-flex-grid-column [header]="'Actions'" [width]="75">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                    <div class="text-center">
                        <i class="far fa-edit fa-sm  actions actions--Highlightfocus--icon" title="edit" (click)="editPopModal(popup, item)"></i>
                        <i class="far fa-trash-alt fa-sm actions danger-actve" title="delete" (click)="deletePopUpModal(delete, item)"></i>
                    </div>
                </ng-template>
            </wj-flex-grid-column>
            <wj-flex-grid-column *ngIf="VaccineId" [header]="'Vaccine Group'" [width]="111">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                    <div class="text-center">
                        {{item["Vaccine Group"]}}
                    </div>
                </ng-template>
            </wj-flex-grid-column>
            <wj-flex-grid-column [header]="rxheader['hName']" [binding]="rxheader['hName']" [visible]="rxheader['isVisible']" *ngFor="let rxheader of wjHeaders; let i = index"
                [width]="rxheader['width']">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                    <div *ngIf="rxheader['hName']==='Billing NDC'" >
                        {{item[rxheader['hName']] | mask : "AAAA0-0000-00"}}
                    </div>
                    <div *ngIf="rxheader['hName']!='Billing NDC'" >
                        {{item[rxheader['hName']]}}
                    </div>
                </ng-template>
            </wj-flex-grid-column>
            <app-no-data-found *ngIf="!isDataExists"></app-no-data-found>
        </wj-flex-grid>
        <app-eprx-pagination [TotalCount]="insuRestWJData['count']" [PageSize]="Size" [FromModal]="true" [WijmoName]="insuResList" [GridName]="'InsuranceRestriction'"
        *ngIf="insuRestWJData['count']" (OnPageSelected)="setPage($event)" (OnSizeSelected)="setSize($event)"></app-eprx-pagination>
    </div>
</div>

<ng-template #popup let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">Drug/Insurance Preferences Settings</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-6">
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6" *ngIf="drugInsuFG?.controls?.VaccineGrp?.value">
                        <eprx-input [LabelText]="'Vaccine Group'" [PlaceHolder]="''" [ControlName]="'VaccineGrp'" [FormGroupName]="drugInsuFG"
                        [ReadOnly]="true">
                        </eprx-input>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <label class="col-md-12 padding-0"> Apply to all Insurances? </label>
                        <eprx-radio [LabelText]="''" [ControlName]="'IsAllInsu'" [FormGroupName]="drugInsuFG" [List]="['Yes', 'No']" [ValueList]="[true, false]"
                          [AutoFocus]="true"  [IDForList]="['yes', 'no']" (TriggerSelectValue)="applytoAll()">
                        </eprx-radio>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <eprx-select [LabelText]="'Insurances'" [PlaceHolder]="''" [ControlName]="'InsurId'" [FormGroupName]="drugInsuFG" [BindLabel]="'InsurerCode'"
                            [BindLabel2]="'InsuranceName'" [Show2Values]="true" [ShowLabel2]="true" [BindValue]="'InsuCarrierId'" [LabelForId]="'InsuCarrierId'" [List]="(insuranceData$ | async)" [IsDisabled]="HasInsuFilter"
                            [IsRequired]="!HasInsuFilter"  [ErrorDefs]="{required: 'Required'}" [FormGroupInvalid]="formGroupInvalid" (TriggerSelectValue)="allowUniqueInsurance($event)"
                            [LabelTitle2]="'Name'" [LabelTitle1]="'Code'" [RxSelectId]="'InsuranceId'"  [MarkAsTouched]="drugInsuFG?.controls?.InsurId?.touched">
                        </eprx-select>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <eprx-input [LabelText]="'Max Refills Allowed'" [PlaceHolder]="''" [ControlName]="'MaxRefillsAllowed'" [FormGroupName]="drugInsuFG"
                            [MaskPattern]="'00'" >
                        </eprx-input>
                    </div> 
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <eprx-input [LabelText]="'Max Qty Allowed'" [PlaceHolder]="''" [DecimalValues]="3" [ControlName]="'MaxQtyAllowed'" [InputType]="'NUMBER'"
                        [MaskPattern]="'09999.000'" [DropSpclChar]="false" [FormGroupName]="drugInsuFG">
                        </eprx-input>
                    </div> 
                </div>
            </div>
            <div class="col-6">
                <div *ngIf="drugId">
                    <div class="row">
                        <div class="col-6">
                            <div class="col-md-12">
                                <label class="font-weight-bold">Drug Name: </label>&nbsp;<div class="background_antiquewhite">{{ drugInfoFG?.value?.Drug?.Name | uppercase}}
                                {{ drugInfoFG?.value?.Drug?.Strength ?drugInfoFG?.value?.Drug?.Strength : "" | uppercase  }}
                                {{ drugInfoFG?.value?.Drug?.DrugFormName ? drugInfoFG?.value?.Drug?.DrugFormName : "" | uppercase}}</div>
                            </div>
                            <br>
                            <div class="col-md-12">
                                <label class="font-weight-bold">NDC#: </label>&nbsp;<span>{{ _formatsUtil.getNDCFormat(drugInfoFG?.value?.Drug?.DrugCode) }}
                                </span>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="col-sm-12 padding-0">
                                <img src="{{drugInsuFG?.value['ImageUrl']}}" class="drug-img">
                            </div>
                        </div>
                    </div>    
                </div>
            </div>
            <div class="col-12">
                <div class="row">
                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <eprx-input [LabelText]="'Incentive Amount'" [PlaceHolder]="''" [ControlName]="'Incentive'" [InputType]="'CURRENCY'" [DecimalValues]="2" [MaxLength]="9"
                        [FormGroupName]="drugInsuFG" (TriggerSearchValue)="changeToNumber($event?.value, 'Incentive')" [MaskPattern]="'099999.00'" [DropSpclChar]="false" [RxSelectId]="'IncentiveAmountDrug'">
                        </eprx-input>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <eprx-select [LabelText]="'Submission Clarification'" [PlaceHolder]="''"
                        [ControlName]="'SubClarifId'" [secondCntrlN]="'SubClarificationRem'"
                        [FormGroupName]="drugInsuFG" [List]="systemData?.subClarificationData" [BindLabel]="'Name'"
                        [BindLabel2]="'Remarks'" [bindScnCntrlV]="'Remarks'" [IsTitle]="true" [BindValue]="'Id'"
                        [LabelForId]="'Id'" [LabelTitle1]="'Code'" [LabelTitle2]="'Description'"
                        [Show2Values]="true">
                        </eprx-select>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <eprx-select [LabelText]="'Basis of Cost'" [RxSelectId]="'BasCostInsPref'" [PlaceHolder]="''" [List]="costBasisDrpDown ? costBasisDrpDown?.CostBasis : ''"
                         [BindLabel]="'Name'" [BindLabel2]="'Remarks'" [ShowLabel2]="true" [IsTitle]="true" [BindValue]="'Id'" [LabelForId]="'Name'" [ControlName]="'CostBasisId'"
                            [FormGroupName]="drugInsuFG" [bindScnCntrlV]="'Name'" [secondCntrlN]="'CostBasis'" [Show2Values]="true">
                        </eprx-select>
                    </div>
                </div>
            </div>

            <!-- <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--right__7">
                <label class="col-md-12 padding-0">Allowed By Insurance? </label>
                <eprx-radio [LabelText]="''" [ControlName]="'IsAllowed'" [FormGroupName]="drugInsuFG" [List]="['Yes', 'No']" [ValueList]="[true, false]"
                    [IDForList]="['y', 'n']">
                </eprx-radio>
            </div> -->
            <!-- <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--left__7">
                <eprx-input [LabelText]="'Billing NDC'" [PlaceHolder]="''" [ControlName]="'BillingNDC'" [FormGroupName]="drugInsuFG" [MaskPattern]="'00000-0000-00'"
                    [MaxLength]="'13'" [DropSpclChar]="true">
                </eprx-input>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--right__7">
                <eprx-select [LabelText]="'NDC Qualifier'" [PlaceHolder]="''" [ControlName]="'NDCQualiId'" [FormGroupName]="drugInsuFG" [BindLabel]="'Name'"
                    [BindLabel2]="'Remarks'" [BindValue]="'Id'" [LabelForId]="'Id'" [List]="systemData?.NdcQualifier" [Show2Values]="true">
                </eprx-select>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--left__7">
                <label class="col-md-12 padding-0"> Prior Approval Required? </label>
                <eprx-radio [LabelText]="''" [ControlName]="'IsPriorApprReqd'" [FormGroupName]="drugInsuFG" [List]="['Yes', 'No']" [ValueList]="[true, false]"
                    [IDForList]="['ye', 'No']">
                </eprx-radio>
            </div> -->

            <!-- <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--right__7">
                <eprx-input [LabelText]="'Max Refills Allowed'" [PlaceHolder]="''" [ControlName]="'MaxRefillsAllowed'" [FormGroupName]="drugInsuFG"
                    [MaskPattern]="'00'" >
                </eprx-input>
            </div> -->
            <!-- <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--left__7">
                <eprx-input [LabelText]="'Qty Conversion Calculation'" [PlaceHolder]="''" [ControlName]="'QtyConCal'" [FormGroupName]="drugInsuFG"     [BeforeDecimal]="10"  [DecimalValues]="3"  [InputType]="'NUMBER'" 
                (TriggerOnTabbing)="setNumberFormat()"
                (EmitOnChangeValue)="setNumberFormat()" >
                </eprx-input>
            </div> -->
            <!-- <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--right__7">
                <eprx-input [LabelText]="'Max Qty Allowed'" [PlaceHolder]="''" [DecimalValues]="0" [ControlName]="'MaxQtyAllowed'" [InputType]="'NUMBER'"
                [MaskPattern]="'09999'" [DropSpclChar]="false" [FormGroupName]="drugInsuFG" [MaxLength]="5">
                </eprx-input>
            </div> -->
            <!-- <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--left__7">
                <eprx-input [LabelText]="'Result'" [PlaceHolder]="''" [ControlName]="'Result'" [FormGroupName]="drugInsuFG">
                </eprx-input>
            </div> -->
    </div><br/>
    <div class="box box-default box-solid border_AllRadius">
                <div class="eprx--block__header margin-left-window">
                    <label class="font-weight-bold ">DUR Entry</label>
                </div>
            <div class="box-body">
                <div class="col-12">
                <div class="row">
                    <div class="col-3">
                        <app-sub-dur [DurFG]="drugInsuFG" [Defaultval]="systemData?.servReason" [InputErrors]="ServReasonId?.errors"
                            ></app-sub-dur>
                    </div>
                    <div class="col-3">
                        <eprx-select [LabelText]="'Professional Service'" [PlaceHolder]="''" [ControlName]="'ProfServId'"
                            [FormGroupName]="drugInsuFG" [secondCntrlN]="'ProfServName'" [bindScnCntrlV]="'Name'" [HasMultiple]="false"
                            [BindLabel]="'Name'" [BindLabel2]="'Remarks'" [BindValue]="'Id'" [LabelForId]="'Id'" [IsTitle]="true"
                            [List]="systemData?.profServs" [InputErrors]="ProfServId?.errors" 
                         [Show2Values]="true">
                        </eprx-select>
                    </div>
                    <div class="col-3">
                        <eprx-select [LabelText]="'Result of Service'" [PlaceHolder]="''" [ControlName]="'ServResultId'"
                            [FormGroupName]="drugInsuFG" [secondCntrlN]="'ServResult'" [bindScnCntrlV]="'Name'" [HasMultiple]="false"
                            [BindLabel]="'Name'" [BindLabel2]="'Remarks'" [BindValue]="'Id'" [LabelForId]="'Id'" [IsTitle]="true"
                            [List]="systemData?.servResult" [InputErrors]="ServResultId?.errors" 
                         [Show2Values]="true">
                        </eprx-select>
                    </div>
                    <div class="col-3">
                        <eprx-select [LabelText]="'Level of Effort'" [PlaceHolder]="''" [ControlName]="'EffortLvlId'"
                            [FormGroupName]="drugInsuFG" [HasMultiple]="false" [secondCntrlN]="'EffortLvlName'" [bindScnCntrlV]="'Name'"
                            [BindLabel]="'Name'" [BindLabel2]="'Remarks'" [BindValue]="'Id'" [LabelForId]="'Id'" [IsTitle]="true"
                            [List]="systemData?.effortLvl" [InputErrors]="EffortLvlId?.errors"
                         [Show2Values]="true">
                        </eprx-select>
                    </div>
                </div>
                </div>
                <div class="col-12">
                <div class="row">
                    <div class="col-3">
                        <eprx-input [LabelText]="'Co-Agent ID'" [PlaceHolder]="''" [ControlName]="'CoAgentNum'" [MaxLength]="19"
                            [InputType]="'ALPHANUMERIC'" [FormGroupName]="drugInsuFG"></eprx-input>
                    </div>
                    <div class="col-3">
                        <eprx-select [LabelText]="'Co-Agent ID Type'" [PlaceHolder]="''" [ControlName]="'CoAgentIdType'"
                            [FormGroupName]="drugInsuFG" [HasMultiple]="false" [BindLabel]="'Name'" [BindLabel2]="'Remarks'" [IsTitle]="true"
                            [BindValue]="'Name'" [LabelForId]="'Id'" [List]="systemData?.coAgentIdCat" [Show2Values]="true">
                        </eprx-select>
                    </div>
                    <div class="col-3">
                        <eprx-select [LabelText]="'Clinical Significance'" [PlaceHolder]="''" [ControlName]="'ClinicalSignfId'"
                            [FormGroupName]="drugInsuFG" [secondCntrlN]="'ClinicalSignfName'" [bindScnCntrlV]="'Name'" [IsTitle]="true"
                            [HasMultiple]="false" [BindLabel]="'Name'" [BindLabel2]="'Remarks'" [BindValue]="'Id'" [LabelForId]="'Id'"
                            [List]="systemData?.clinicalSignf" [InputErrors]="ClinicalSignfId?.errors"
                            [Show2Values]="true"
                            [show2ValInInputhidden]="true">
                        </eprx-select>
                    </div>
                    <div class="col-3" [formGroup]="drugInsuFG">
                        <label class="align-label"> Pharmacist Comment </label>
                        <textarea class="custom--text__area" rows="1" cols="73" formControlName="PhComment"
                            placeholder="Comments"></textarea>
                    </div>
                </div>
                </div>
            </div>
    </div>
    </div>
    <div class="modal-footer">
        <div class="text-right pull-right col-md-10 padding-0">
            <div>
                <button class="hotkey_success" (click)="checkAddInsuRest()" appShortcutKey [AltKey]=" popUpType === 'Add'? 's' : 'u'"><span>{{ popUpType === 'Add'? 'S' : 'U' }}</span>{{ popUpType === 'Add'? 'Save' : 'Update' }}</button>
                <button class="hotkey_success" (keydown.enter)="d('Cross click')" (click)="d('Cross click')" appShortcutKey [AltKey]="'c'"><span>C</span>Cancel</button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #delete let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">Warning!!</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        Are you sure, you want to delete?
    </div>
    <div class="modal-footer">
        <div class="text-right pull-right col-md-10 padding-0">
            <div>
                <button class="hotkey_primary" (click)="d('Cross click')" (click)="deleteInsuRest()" appShortcutKey InputKey="y"><b>Y</b> YES </button>
                <button class="hotkey_primary" (click)="d('Cross click')" appShortcutKey InputKey="n"><b>N</b>NO</button>
            </div>
        </div>
    </div>
</ng-template>
