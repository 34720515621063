
<div class="spc-users-list-scroll">   
    <div class="spc-users-list">
        <div *ngIf="filteredList?.length === 0 && searchText" class="no-matches">
            No data found.
        </div>
        <div class="spc-user-sec" [ngClass]="{'selected': isSelected(item)}" (click)="userSelect(item)" *ngFor="let item of filteredList">
            <div class="profile-pic">
                <img class="profile-icon" 
                    [src]="item?.gender?.toLowerCase() === 'male' ? '/assets/img/male_pic.png' :
                        item?.gender?.toLowerCase() === 'female' ? '/assets/img/female_pic.png' : 
                        '/assets/img/dp_pic.png'" 
                    alt="Profile Image"/>
            </div>
            <div class="spc-user-txt">
                <span class="title">{{item?.userName}}</span>
                <span class="desc ellipsis-text"><!--<img alt="doble-tick" src="/assets/img/tick-blue.svg" class="tick-icon">-->{{item?.lastMessage}}</span>
            </div>
            <div class="spc-user-act">
                <span class="active-time">{{ getRelativeTime(item?.lastMessageReceivedAt) }}</span>
                <span class="active-counts" *ngIf="item?.unreadCount !== 0"><span>
                    {{item?.unreadCount }}
                </span></span>
            </div>
        </div>
    </div>
</div>
