<ng-template #TRANSPATONLY let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeAndReviewModal('R')">
        <h4 header>Transmission Result</h4>
        <span body>
            <div class="col transmit--block">
                <div class="col-xs-12 col-md-12 padding-0">
                    <div class="row">
                        <div class="col-md-10 padding-0">
                            <div class="row trasmit--rx">
                                    <div class="col-md-12 col-xs-12">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 inline-flex">
                                            <label> Rx# </label>
                                            <div class="label--data">
                                                {{ trasmitRx?.RXNo }}
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 inline-flex pad-left-24">
                                            <div class="col-xs-12 inline-flex">
                                                    <label> Ins </label>
                                                    <div class="label--data">
                                                        {{ trasmitRx?.InsType }}
                                                    </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 inline-flex pad-left-34">
                                            <div class="col-xs-12 inline-flex">
                                                    <label> Transaction Response </label>
                                                    <div class="label--data">
                                                        <span
                                                            *ngIf="
                                                                trasmitRx?.ResponseStatus === 'P' || trasmitRx?.ResponseStatus === 'A'
                                                            "
                                                            [ngClass]="transcResp?'bg--green txt--white':null"
                                                            >{{ transcResp }}</span
                                                        >
                                                        <span
                                                            *ngIf="
                                                                trasmitRx?.ResponseStatus === 'R' || trasmitRx?.ResponseStatus === 'TRANSMISSION ERROR'
                                                            "
                                                            [ngClass]="transcResp?'bg--red txt--white':null"
                                                            >{{ transcResp }}</span
                                                        >

                                                    </div>

                                            </div>
                                        </div>
                                    </div>
                            </div>
                            <div class="row trasmit--rx">
                                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                    <div class="col-md-12 col-xs-12 inline-flex">
                                        <label> Patient </label>
                                        <div class="label--data">
                                            {{
                                                trasmitRx?.PatientName
                                            }}
                                        </div>
                                    </div>
                                    <div class="col-md-12 col-xs-12 inline-flex">
                                        <label> Doc </label>
                                        <div class="label--data">
                                            {{
                                                trasmitRx?.PrescriberName
                                            }}
                                        </div>
                                    </div>
                                    <div class="col-md-12 col-xs-12 inline-flex">
                                        <label> Drug </label>
                                        <div class="label--data">
                                            {{ trasmitRx?.DrugName }}
                                        </div>
                                    </div>
                                    <div class="col-md-12 col-xs-12 inline-flex">
                                        <label> Txmt. By </label>
                                        <div class="label--data">
                                            {{
                                                trasmitRx?.TransmittedBy
                                            }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                    <div class="col-md-12 col-xs-12 inline-flex">
                                        <label> Ins. Info </label>
                                        <div class="label--data">
                                            {{ trasmitRx?.InsuInfo }}
                                        </div>
                                    </div>
                                    <div class="col-md-12 col-xs-12 inline-flex">
                                        <label> Fill Date </label>
                                        <div class="label--data">
                                            {{ trasmitRx?.FillDate }}
                                        </div>
                                    </div>
                                    <div class="col-md-12 col-xs-12 inline-flex">
                                        <label> Days / Qty </label>
                                        <div class="label--data">
                                            {{
                                                trasmitRx?.Days
                                                    ? trasmitRx?.Days
                                                    : 0
                                            }}
                                            /
                                            {{
                                                trasmitRx?.Qty
                                                    ? trasmitRx?.Qty
                                                    : 0
                                            }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                    <div class="col-md-12 col-xs-12 inline-flex">
                                        <label> Authorization </label>
                                        <div class="label--data">
                                            {{ trasmitRx?.AuthNo }}
                                            {{
                                                trasmitRx?.VersionInfo
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 padding-0 transmit--btns">
                            <button class="ternary" (click)="routeToPrescriber()">Doc Info (ctrl+R)</button>
                            <button class="ternary" (click)="routeToInsurence()">Ins. Info (ctrl+I)</button>
                            <button class="ternary" (click)="routeToDrug()">
                                Drug Info (ctrl+D)
                            </button>
                            <button class="ternary" (click)="routeToPatient()">
                                Pat. Info (ctrl+P)
                            </button>
                        </div>
                    </div>
                    <hr>
                    <div class="col-xs-12 col-md-12 padding-0">
                        <div class="row">
                            <div class="col-md-10">
                                <div class="exprx--common-block">
                                    <div class="eprx--block__header">
                                        <div class="eprx--header__heading">
                                            Payment Details
                                        </div>
                                    </div>
                                    <div
                                        class="eprx--block__content"
                                        *ngIf="
                                            transcResp === 'Paid' ||
                                            transcResp === 'Approved'
                                        "
                                    >
                                        <div class="row trasmit--rx">
                                            <div
                                                class="col-lg-4 col-md-4 col-sm-4 col-xs-12 inline-flex"
                                            >
                                                <table class="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col"></th>
                                                            <th scope="col">Paid</th>
                                                            <th scope="col">Billed</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <th>Deductible/Copay</th>
                                                            <td>
                                                                {{
                                                                    trasmitRx
                                                                        ?.Deductible
                                                                        | currency
                                                                            : "USD"
                                                                            : "symbol-narrow"
                                                                            : "1.2-2"
                                                                }}
                                                            </td>
                                                            <td>
                                                                {{
                                                                    trasmitRx
                                                                        ?.BillCopay
                                                                        | currency
                                                                            : "USD"
                                                                            : "symbol-narrow"
                                                                            : "1.2-2"
                                                                }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">
                                                                Ingredient Cost ({{
                                                                    trasmitRx
                                                                        ?.PercDiff
                                                                }})
                                                            </th>
                                                            <td>
                                                                {{
                                                                    trasmitRx
                                                                        ?.IngredientCost
                                                                        | currency
                                                                            : "USD"
                                                                            : "symbol-narrow"
                                                                            : "1.2-2"
                                                                }}
                                                            </td>
                                                            <td>
                                                                {{
                                                                    trasmitRx
                                                                        ?.BillIngredient
                                                                        | currency
                                                                            : "USD"
                                                                            : "symbol-narrow"
                                                                            : "1.2-2"
                                                                }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Fee Paid</th>
                                                            <td>
                                                                {{
                                                                    trasmitRx
                                                                        ?.DispensingFee
                                                                        | currency
                                                                            : "USD"
                                                                            : "symbol-narrow"
                                                                            : "1.2-2"
                                                                }}
                                                            </td>
                                                            <td>
                                                                {{
                                                                    trasmitRx
                                                                        ?.BillDispensingFee
                                                                        | currency
                                                                            : "USD"
                                                                            : "symbol-narrow"
                                                                            : "1.2-2"
                                                                }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">
                                                                Other Fee Paid
                                                            </th>
                                                            <td>
                                                                {{
                                                                    trasmitRx
                                                                        ?.OtherFeePaid
                                                                        | currency
                                                                            : "USD"
                                                                            : "symbol-narrow"
                                                                            : "1.2-2"
                                                                }}
                                                            </td>
                                                            <td>
                                                                {{
                                                                    trasmitRx
                                                                        ?.BilledOtherFeePaid
                                                                        | currency
                                                                            : "USD"
                                                                            : "symbol-narrow"
                                                                            : "1.2-2"
                                                                }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">
                                                                Tax Paid
                                                            </th>
                                                            <td>
                                                                {{
                                                                    trasmitRx
                                                                        ?.SalesTax
                                                                        | currency
                                                                            : "USD"
                                                                            : "symbol-narrow"
                                                                            : "1.2-2"
                                                                }}
                                                            </td>
                                                            <td>
                                                                {{
                                                                    trasmitRx
                                                                        ?.BilledSalesTaxPaid
                                                                        | currency
                                                                            : "USD"
                                                                            : "symbol-narrow"
                                                                            : "1.2-2"
                                                                }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">
                                                                Other Amount
                                                            </th>
                                                            <td>
                                                                {{
                                                                    trasmitRx
                                                                        ?.otherAmount
                                                                        | currency
                                                                            : "USD"
                                                                            : "symbol-narrow"
                                                                            : "1.2-2"
                                                                }}
                                                            </td>
                                                            <td>
                                                                {{
                                                                    trasmitRx
                                                                        ?.BillOtherAmount
                                                                        | currency
                                                                            : "USD"
                                                                            : "symbol-narrow"
                                                                            : "1.2-2"
                                                                }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">
                                                                Total Amount ({{
                                                                    trasmitRx
                                                                        ?.BasisInfo
                                                                }})
                                                            </th>
                                                            <td>
                                                                {{
                                                                    trasmitRx
                                                                        ?.TotalAmount
                                                                        | currency
                                                                            : "USD"
                                                                            : "symbol-narrow"
                                                                            : "1.2-2"
                                                                }}
                                                            </td>
                                                            <td>
                                                                {{
                                                                    trasmitRx
                                                                        ?.BillTotalAmount
                                                                        | currency
                                                                            : "USD"
                                                                            : "symbol-narrow"
                                                                            : "1.2-2"
                                                                }}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div
                                                class="col-lg-4 col-md-4 col-sm-4 col-xs-12 inline-flex"
                                            >
                                                <table class="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Ins</th>
                                                            <th scope="col">Amount</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let insuProp of trasmitRx?.InsAmountList">
                                                            <th scope="row">
                                                                {{
                                                                    insuProp?.Ins
                                                                }}
                                                            </th>
                                                            <td>
                                                                {{
                                                                    insuProp?.Amount | currency
                                                                            : "USD"
                                                                            : "symbol-narrow"
                                                                            : "1.2-2"
                                                                }}
                                                            </td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                            <div
                                                class="col-lg-4 col-md-4 col-sm-4 col-xs-12"
                                            >
                                                <div class="col-md-12">
                                                    <label> Other Info </label>
                                                    <div>
                                                        {{
                                                            trasmitRx
                                                                ?.OtherInfotTxt
                                                        }}
                                                    </div>
                                                </div>
                                                <div>&nbsp;</div>
                                                <div>&nbsp;</div>
                                                <div>&nbsp;</div>
                                                <div class="col-md-12">
                                                    <label> Messages </label>
                                                    <div>
                                                        {{ trasmitRx?.ClaimProcess?.objncpdRecv?.MessageFQ ?
                                                            trasmitRx?.ClaimProcess?.objncpdRecv?.MessageFQ.toString().replace(".", "") : ""
                                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="eprx--block__content"
                                        *ngIf="trasmitRx?.ResponseStatus === 'R' || trasmitRx?.ResponseStatus === 'TRANSMISSION ERROR'"
                                    >
                                        <div class="row trasmit--rx">
                                            <div
                                                class="col-md-12 col-xs-12 inline-flex color-red"
                                            >
                                                {{
                                                    trasmitRx?.RejectInfo
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="exprx--common-block">
                                    <div class="eprx--block__header">
                                        <div class="eprx--header__heading">
                                            DUR Messages
                                        </div>
                                    </div>
                                    <div class="eprx--block__content">
                                        <table class="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Reason</th>
                                                    <th scope="col">Significance</th>
                                                    <th scope="col">Other.PH</th>
                                                    <th scope="col">Prev.Fill</th>
                                                    <th scope="col">Fill Qty</th>
                                                    <th scope="col">DB</th>
                                                    <th scope="col">Other Pres.</th>
                                                    <th scope="col">Comment</th>
                                                </tr>
                                            </thead>
                                            <!--
                                                <tbody>
                                                    <tr>
                                                        <th scope="row">1</th>
                                                        <td>Mark</td>
                                                        <td>Otto</td>
                                                        <td>@mdo</td>
                                                    </tr>
                                                </tbody>
                                            -->
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 padding-0 transmit--btns">
                                <button footer class="inactive">
                                        Correct
                                    </button>
                                <button footer class="inactive">Cash</button>
                                <button footer class="inactive">Delete</button>
                                <button footer class="inactive">File</button>
                                <button footer class="inactive">DUR</button>
                                <button footer class="inactive">Serv. Auth</button>
                                <button footer class="secondary" *ngIf="trasmitRx?.ResponseStatus === 'R' || trasmitRx?.ResponseStatus === 'TRANSMISSION ERROR'">Print Rej</button>
                                <button footer class="secondary" *ngIf="trasmitRx?.ResponseStatus === 'R' || trasmitRx?.ResponseStatus === 'TRANSMISSION ERROR'">Followup Tag</button>
                                <button footer class="secondary" *ngIf="trasmitRx?.ResponseStatus === 'R' || trasmitRx?.ResponseStatus === 'TRANSMISSION ERROR'">Prior APP</button>

                                <button footer class="secondary" *ngIf="trasmitRx?.ResponseStatus === 'P'|| trasmitRx?.ResponseStatus === 'A'">Print Accept</button>
                                <button footer class="secondary">Print Screen</button>
                                <button footer class="secondary" *ngIf="trasmitRx?.ResponseStatus === 'P'|| trasmitRx?.ResponseStatus === 'A'">No Label</button>
                                <button footer class="inactive" *ngIf="trasmitRx?.ResponseStatus != 'P' && trasmitRx?.ResponseStatus != 'A'"> Continue</button>
                                <button
                                    footer
                                    class="secondary"
                                    (click)="continueTransmitProcess()"
                                    *ngIf="trasmitRx?.ResponseStatus === 'P'|| trasmitRx?.ResponseStatus === 'A'"
                                >
                                    Continue(Y)
                                </button>
                                <button footer class="secondary" (click)="closeAndReviewModal('R')">
                                    Close(N)
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </span>
    </app-custom-modal>
</ng-template>

<ng-template #TRANSMISSIONCONF let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeConfirmModal()">
        <h4 header>Confirmation</h4>
        <span body>
            Amount paid is LESS than the Drug cost. Do you want to continue?
        </span>
        <button
            footer
            ngbAutofocus
            type="submit"
            class="submit"
            (click)="saveTransmitClaims()"
            InputKey="y"><b>Y</b> YES
        </button>
        <button
            footer
            type="submit"
            class="submit"
            (click)="closeConfirmModal()"
            InputKey="n">
        >
        <b>N</b>
        NO
        </button>
    </app-custom-modal>
</ng-template>

<ng-template #WARNINGMSGS let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeConfirmModal()">
        <h4 header>Warning!!</h4>
        <span body> {{ warningMsg }} </span>
        <button
            footer
            ngbAutofocus
            type="submit"
            class="submit"
            (click)="closeConfirmModal()"
            InputKey="o"
        > <b>O</b>
            Ok
        </button>
    </app-custom-modal>
</ng-template>
