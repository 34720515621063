<div class="exprx--common-block">
    <div class="eprx--block__header large_header">
        <div class="row">
            <div class="col">
                <span class="eprx--header__heading">
                </span>
            </div>
            <div class="col pull-right text-right">
                <button class="hotkey_primary hotkey_primary--Override" autofocus appShortcutKey
                    [CtrlKey]="'a'" title="Add Bucket Pricing" (click)="openAddModal(BucketPrice)" (keydown.tab)="focusToSave($event, 'Add')"><span>A</span>ADD BUCKET PRICING</button>
            </div>
        </div>
    </div>
    <div class="eprx--block__content" *ngIf="bucketPriceData['count']">
        <wj-flex-grid #bucket [headersVisibility]="'Column'" [itemsSource]="bucketPriceData['wjSource']"
            [isReadOnly]="true" [selectionMode]="'Row'" (updatedView)="init(bucket)" [allowDragging]="'None'">
            <wj-flex-grid-column [header]="'Actions'" [width]="240" [binding]="'Actions'" [visible]="true">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                    <div class="text-center">
                        <i class="far fa-edit fa-sm  actions" title="edit"
                            (click)="openEditModal(BucketPrice, item)"></i>
                        <i class="far fa-trash-alt fa-sm actions danger-actve" title="delete"
                            (click)="deletePopUpModal(delete, item)"></i>
                    </div>
                </ng-template>
            </wj-flex-grid-column>
            <wj-flex-grid-column [header]="'Bucket Name'" [width]="975" [binding]="'Bucket Name'"></wj-flex-grid-column>
            <wj-flex-grid-column [header]="'Unit Pricing'" [width]="547" [binding]="'Unit Pricing'">
            </wj-flex-grid-column>
        </wj-flex-grid>
        <app-eprx-pagination [TotalCount]="bucketPriceData['count']" [PageSize]="Size" [WijmoName]="bucket"
            [GridName]="'bucketPricing'" (OnPageSelected)="setPage($event)" (OnSizeSelected)="setSize(Size)"
            (OnFocusOut)="focusToSave($event, null)">
        </app-eprx-pagination>

    </div>
    <div class="eprx--block__content eprx--block__footer" *ngIf="bucketPriceData['count'] === 0">
        <div>
            <span></span>
            <i class="fa fa-file"></i><br/>
            No Data Found!
        </div>
    </div>
</div>
<ng-template #BucketPrice let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title" *ngIf="activeModal === 'Add'">Add Bucket Pricing</h4>
        <h4 class="modal-title" *ngIf="activeModal === 'Update'">Update Bucket Pricing</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click');">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--right__7">
                <eprx-select [LabelText]="'Bucket Name'" [PlaceHolder]="''" [ControlName]="'DrugBucketId'"
                    [RxSelectId]="'DrugBucketId'" [IsFirstElem]="true" [IsRequired]="true"
                    [FormGroupInvalid]="formGroupInvalid" [ErrorDefs]="{required: 'Required'}"
                    [FormGroupName]="bucketFG" [BindLabel]="'Name'" [BindValue]="'Id'" [LabelForId]="'Id'"
                    [List]="bucketlist">
                </eprx-select>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--left__7">
                <eprx-input [LabelText]="'Unit Price'" [PlaceHolder]="''" [ControlName]="'UnitPrice'"
                    [IsRequired]="true" [FormGroupInvalid]="formGroupInvalid" [ErrorDefs]="{required: 'Required'}"
                    [FormGroupName]="bucketFG" [DropSpclChar]="false" [DecimalValues]="3"
                    (TriggerSearchValue)="setNumberFormat('UnitPrice')" [MaskPattern]="'099999.000'">
                </eprx-input>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="text-right pull-right col-md-10 padding-0">
            <div>
                <button class="hotkey_primary" (click)="checkBucketValid()" (keydown.enter)="checkBucketValid()"
                    appShortcutKey [CtrlKey]="'s'"><span>S</span>Save</button>
                <button class="hotkey_primary" (click)="d('Cross click')" (keydown.enter)="d('Cross click');"
                    appShortcutKey [CtrlKey]="'c'"><span>C</span>Cancel </button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #delete let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">{{warningText?.deleteHeaderTxt}}</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click');">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        {{warningText?.deleteConfrmMsg}}
    </div>
    <div class="modal-footer">
        <div class="text-right pull-right col-md-10 padding-0">
            <div>
                <button class="hotkey_primary" (click)="d('Cross click')" (click)="deleteBucketList()" appShortcutKey
                    InputKey="y"><b>Y</b> YES </button>
                <button class="hotkey_primary" (click)="d('Cross click')" appShortcutKey
                    InputKey="n"><b>N</b>NO</button>
            </div>
        </div>
    </div>
</ng-template>
