import { Component, OnInit, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { CommonUtil } from "src/app/utils";

@Component({
    selector: "app-insu-billing",
    templateUrl: "./insu-billing.component.html"
})
export class InsuBillingComponent implements OnInit {

    @Input() InsBillingFG: FormGroup;

    constructor(private _comnUtil: CommonUtil) { }

    ngOnInit() {
    }

    focusToSave(event) {
        this._comnUtil.focusById("InsSaveButton", event);
    }

}
