<div class="exprx--common-block">
    <div class="eprx--block__header">
        <div class="eprx--header__heading">
            Personal Information
        </div>
    </div>
    <div class="eprx--block__content">
        <div class="row">
            <div class="col-6">
                <eprx-input [RxSelectId]="'patLastName'" [LabelText]="'Last Name'" [PlaceHolder]="'Last name'"
                    [ControlName]="'LastName'" [FormGroupName]="patientInfoFG?.get('Person')" [IsRequired]="true"
                    [ErrorDefs]="{required: 'Required'}" [MaxLength]="35"
                    [FormGroupInvalid]="formGroupInvalid">
                </eprx-input>
            </div>
            <div class="col-6">
                <eprx-input [RxSelectId]="'patMI'" [LabelText]="'MI'" [PlaceHolder]="'Middle Initial'"
                    [ControlName]="'MiddleName'" [FormGroupName]="patientInfoFG?.get('Person')"
                    [MaxLength]="35">
                </eprx-input>
            </div>
            <div class="col-6">
                <eprx-input [RxSelectId]="'patFirstName'" [LabelText]="'First Name'" [PlaceHolder]="'First Name'"
                    [FormGroupInvalid]="formGroupInvalid" [ControlName]="'FirstName'"
                    [FormGroupName]="patientInfoFG?.get('Person')" [IsRequired]="true"
                    [ErrorDefs]="{required: 'Required', pattern: 'Invalid!'}" [MaxLength]="35">
                </eprx-input>
            </div>
            <div class="col-6">
                <eprx-date-picker [RxSelectId]="'patDOB'" [LabelText]="'DOB'" [PlaceHolder]="'DOB'" [ControlName]="'BirthDt'"
                    [FormGroupName]="patientInfoFG?.get('Person')"
                    [ErrorDefs]="{required: 'Required', inValid:'Invalid Date', pattern: 'Invalid Date', maxDate:'Max Date'}"
                    [MinDate]="" [MaxDate]="maxDate" [IsRequired]="true" [FormGroupInvalid]="formGroupInvalid" >
                </eprx-date-picker>
                <!-- <eprx-date-picker [RxSelectId]="'patDOB'" [LabelText]="'DOB'" [PlaceHolder]="'DOB'"
                    [ControlName]="'BirthDt'" [FormGroupName]="patientInfoFG?.get('Person')"
                    [ErrorDefs]="{required: 'Required', inValid:'Invalid Date', pattern: 'Invalid Date', maxDate:'Invalid Date'}" [MinDate]="" [MaxDate]="maxDate"
                    [IsRequired]="true" [FormGroupInvalid]="formGroupInvalid">
                </eprx-date-picker> -->
            </div>
            <div class="col-6">
                <eprx-select [RxSelectId]="'patGend'" [LabelText]="'Gender'" [PlaceHolder]="'Gender'"
                    [ControlName]="'GenderId'" [FormGroupName]="patientInfoFG?.get('Person')"
                    [List]="systemData?.Gender" [HasMultiple]="false" [BindLabel]="'Remarks'" [BindLabel2]="'Name'" [BindValue]="'Id'"
                    [IsRequired]="true" [FormGroupInvalid]="formGroupInvalid" [LabelForId]="'Name'" [ShowLabel2]="true"
                    [ErrorDefs]="{required: 'Required'}" [Show2Values]="true" >
                </eprx-select>
            </div>
            <div  class="col-6" *ngFor="let phone of Phones.controls; let i=index">
                <div class="row">
                    <div [class]="(phone?.value?.PhoneCatId ===2) ? 'col-7' : 'col-md-10 padding_0_0_0_1rem'">
                        <eprx-input [RxSelectId]="'patTelep' + phone?.value?.PhoneCatId"
                            [LabelText]="(phone?.value?.PhoneCatId)===3?'Telephone#':(phone?.value?.PhoneCatId)===2?'Mobile#':'Work#'"
                            [PlaceHolder]="(phone?.value?.PhoneCatId)===3?'Telephone#':(phone?.value?.PhoneCatId)===2?'Mobile#':'Work#'"
                            [ControlName]="'xPhone'" [FormGroupName]="phone"
                            [MaskPattern]="'(000)000-0000'" [DropSpclChar]="true"
                            [MarkAsTouched]="phone['controls']?.xPhone?.touched" [ShowMask]="true"  (TriggerOnEnterValue)="phoneValidationCheck(phone?.value?.PhoneCatId)"
                            (TriggerOnBlur) = "phoneValidationCheck(phone?.value?.PhoneCatId)" (TriggerOnTabbing)="phoneValidationCheck(phone?.value?.PhoneCatId,$event)" (TriggerOnChange)="clearTextMsg($event,phone?.value?.PhoneCatId)"></eprx-input>
                            <div  *ngIf="(phone?.value?.PhoneCatId)===2 && phone['controls']?.xPhone?.value">
                                <div class="mt-n3" 
                                 [ngClass]="errorToDisplay === 'Valid!' ? 'help--text__success' : 'help--text__danger'"><span>
                                 <span [ngClass]="errorToDisplay === 'Valid!' ? 'ml-65' : ''">
                                    {{errorToDisplay}} 
                                 </span>
                                 <div  *ngIf="(patientInfoFG.value['Patient']['MobileVerifiedDtTm'] && errorToDisplay)"  tooltipclass="search-tooltip-pat" triggers="mouseover:mouseleave" container="body" placement="right" class="d-inline tooltip-patient-file_mobile i-icon-left" [ngbTooltip]="lastValidatedDate"  >𝒊</div>
                                 <ng-template #lastValidatedDate>
                                    <div class="col-12">
                                        Last Validated Date : 
                                        {{patientInfoFG.value["Patient"]["MobileVerifiedDtTm"] | datetimeUtc:"time"}} 
                                    </div>
                                </ng-template>
                                 </span></div>

                            </div>
                    </div>
                    <div [class]="(phone?.value?.PhoneCatId ===2) ? 'phone-symbol__9' : 'col-md-2 phone-symbol__3'">
                        <i title="Not Preferred" class="far fa-phone-square-alt actions font_size_1_9rem color_red" (click)="setPhonesPreferred(phone?.value?.PhoneCatId)"
                            *ngIf="!(phone?.value?.PhoneCatId === phonesPreferred)" aria-hidden="true"></i>
                        <i title="Preferred" class="far fa-phone-square-alt actions color_green font_size_1_9rem"
                            *ngIf="(phone?.value?.PhoneCatId === phonesPreferred)" aria-hidden="true"></i>

                    </div>
                    <div class="col-1 margin_top_13px_left" *ngIf="(phone?.value?.PhoneCatId)===2">
                        <button *ngIf = "(phone?.value?.xPhone && (patientSettings || subscriptionMessage)) && patientId" class="configure-color hotkey_primary params-buttons min-width__SendSMS"
                        (click)="openSendSMS()">Send SMS</button>
                        <button class="inactive configure-color min-width__SendSMS"
                        *ngIf = "!(phone?.value?.xPhone) || !(patientSettings || subscriptionMessage) || !patientId" [tabindex]="-1">Send SMS</button>
                    </div>
                </div>
            </div>
            <div class="col-6 no-textTransform"
            >
                <eprx-input [RxSelectId]="'patEmail'" [LabelText]="'E-Mail'" [PlaceHolder]="'E-Mail'"
                    [Title]="patientInfoFG?.value['Emails']?.xeMail || '' "
                    [ControlName]="'xeMail'" [FormGroupName]="patientInfoFG?.get('Emails')"
                    [MaxLength]="320"
                    [MarkAsTouched]="(patientInfoFG?.value['Emails']?.xeMail?.length > 320)"
                    [ErrorDefs]="{maxlength: 'Max length',pattern: 'Invalid Email'}"
                    [ValidPattern]="regex?.Email"
                    [FormGroupInvalid]="formGroupInvalid || (patientInfoFG?.value['Emails']?.xeMail?.length > 320)"           
                   >
                </eprx-input>
            </div>
            <div class="col-6">
                <app-family-info-ref [familyInfoFrom]="'Patient'" [PatientInfo]="patientInfoFG?.value" [patientId]="patientId"
                (familyUpdated)="updatepatFG($event)"></app-family-info-ref>
            </div>
        </div>
        <!-- <app-family-info [familyInfoFrom]="'Patient'" [systemData]="systemData" [PatientInfo]="patientInfo"
            [patientId]="patientId" [FrmFamily]="FrmFamily" (familyUpdated)="updatepatFG($event)">
        </app-family-info> -->

        <div class="row">
            <div class="col insurance--body">
                <div class="form-group" [formGroup]="patientInfoFG?.get('Patient')">
                    <label class="control-label full--width">
                        <span>Remarks</span>
                        <textarea rows="2" cols="50" formControlName="Remarks" class="custom--text__area"
                            placeholder="Remarks"></textarea>
                    </label>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="exprx--common-block">
    <div class="eprx--block__header">
        <div class="eprx--header__heading">
            Address Information
        </div>
    </div>
    <div class="eprx--block__content">
        <div class="row">
            <div class="col-6">
                <eprx-input [RxSelectId]="'patAddress'" [LabelText]="'Address Line 1'" [PlaceHolder]="'Address Line 1'"
                    [ControlName]="'AddressLine1'" [FormGroupName]="patientInfoFG?.get('Addresses')" [IsRequired]="true"
                    [ErrorDefs]="{required: 'Required'}" [MarkAsTouched]="patientInfoFG?.get('Addresses')?.touched" (TriggerOnChange)="onAddressChange($event.target.value,'Address1')" [MaxLength]="eRxVersion==2 ? 40 : 35"
                    [FormGroupInvalid]="formGroupInvalid">
                </eprx-input>
            </div>
            <div class="col-6">
                <eprx-input [RxSelectId]="'patAddress2'" [LabelText]="'Address Line 2'" [PlaceHolder]="'Address Line 2'"
                    [ControlName]="'AddressLine2'" [FormGroupName]="patientInfoFG?.get('Addresses')" (TriggerOnChange)="onAddressChange($event.target.value,'Address2')"
                    [MaxLength]="eRxVersion==2 ? 40 : 35"></eprx-input>
            </div>
            <div class="col-6 height_1rem">
                <app-search-zip [FormGroupName]="patientInfoFG?.get('Addresses')" [ControlName]="'ZipCode'"
                [zipExtnCntrl]="'Extension'" [isForErx] = "true" [FormGroupInvalid]="formGroupInvalid" [IsRequired]="true"
                    (OnZipSelected)="patchStateAndCity($event)"></app-search-zip>
            </div>
            <div class="col-2">
                <app-search-state [FormGroupName]="patientInfoFG?.get('Addresses')" [ControlName]="'StateId'"
                    [CityControlName]="'CityId'" [StateSelected]="patientState$ | async"
                    [FormGroupInvalid]="formGroupInvalid" [ErrorDefs]="{ required: 'Required' }" [IsRequired]="true"
                    (OnStateSelected)="patchStateAndCity($event, 'State')"></app-search-state>
            </div>
            <div class="col-4">
                <app-search-city [FormGroupName]="patientInfoFG?.get('Addresses')" [ControlName]="'CityId'"
                    [StateControlName]="'StateId'" [zipControl]="'ZipCode'" [CitySelected]="patientCity$ | async"
                    [ErrorDefs]="{ required: 'Required' }" [IsRequired]="true" [FormGroupInvalid]="formGroupInvalid"
                    (OnCitySelected)="patchStateAndCity($event, 'City')"></app-search-city>
            </div>
        </div>
    </div>
</div>
<div class="exprx--common-block">
    <div class="eprx--block__header">
        <div class="eprx--header__heading">
            Additional Phones
            <ul class="pull-right eprx--header__icons">
                <li>
                    <a (click)="openAddExtraPhonePopup(AddExtraPhone)">
                        <button class="hotkey_success hotkey_primary--Override" title="Alt+P" appShortcutKey
                            [AltKey]="'P'" tabIndex="-1"><span>P</span>ADD EXTRA PHONE</button>
                    </a>
                </li>
            </ul>
        </div>
    </div>
    <div class="eprx--block__content edit--drug">
        <div class="row">
            <div class="col-md-12 max-height_6rem overflow_y_auto">
                <table class="table">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Label</th>
                            <th>Number</th>
                            <th>Contact Name</th>
                            <th>Pref?</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let contact of patientInfoFG.value['PerPrefContact']; let i=index;" [ngStyle]="{display:
                        (contact['Action'] === 'D' || !contact['IsActive']
                        || contact['ContactType'] === 'Telephone' || contact['ContactType'] === 'Mobile' || contact['ContactType'] === 'Work Number')
                                ? 'none'
                                : 'table-row'}">
                            <td class="actions">
                                <a class="padding_right_0_4rem">
                                    <i class="far fa-edit" aria-hidden="true"
                                        (click)="ExtraPhoneEdit(AddExtraPhone, contact, i)"></i>
                                </a>
                                <a>
                                    <i class="far fa-trash-alt actions danger-actve" *ngIf="!contact?.IsPreferred" aria-hidden="true" (click)="openDeleteModal(delete, i)"></i>
                                </a>
                            </td>
                            <td>
                                {{contact?.ContactType | uppercase}}
                            </td>
                            <td>
                                {{contact?.ContactNumber | mask : "(000)000-0000"}}
                            </td>
                            <td class="word-break">
                                {{contact?.Contactname | uppercase}}
                            </td>
                            <td>
                                <span>
                                    <i title="Not Preferred" class="far fa-phone-square-alt actions  color_red font_size_1_9rem" (click)="checkIsPreferred(i)"
                                        *ngIf="!contact?.IsPreferred" aria-hidden="true"></i>
                                    <i title="Preferred" class="far fa-phone-square-alt actions  color_green font_size_1_9rem" *ngIf="contact?.IsPreferred"
                                        aria-hidden="true"></i>
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

<ng-template #AddExtraPhone let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" *ngIf="extraPhoneAdd">Add Extra Phone</h4>
        <h4 class="modal-title" *ngIf="!extraPhoneAdd">Edit Extra Phone</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <eprx-select [LabelText]="'Label Name'" [AutoFocus]="true"
                [ControlName]="'ContactType'" [FormGroupName]="ExtraPhoneFG"
                [RxSelectId]="'ExtraPhoneName'" [FormGroupInvalid]="extraFromInValid"
                [ErrorDefs]="{required: 'Required'}" [IsRequired]="true"
                [MarkAsTouched]="ExtraPhoneFG['controls']?.ContactType?.touched"
                [BindValue]="'Name'" [BindLabel]="'Name'" (TriggerSelectValue)="checkPhoneNameDup()"
                [List]="[{Id: 1, Name: 'Emergency Contact '}, {Id: 2, Name: 'Legal Representative'},
                {Id: 3, Name: 'Parent/Guardian'}, {Id: 4, Name: 'Other'}]">
                </eprx-select>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <eprx-input [LabelText]="'Contact Name'" [RxSelectId]="'ExtraPhoneCName'" [PlaceHolder]="''" [ControlName]="'Contactname'" [FormGroupName]="ExtraPhoneFG">
                </eprx-input>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <eprx-input [LabelText]="'Number'" [InputErrors]="ExtraPhoneFG['controls']?.ContactNumber?.errors"
                    [ErrorDefs]="{required: 'Required'}" [IsRequired]="true"
                    [MarkAsTouched]="ExtraPhoneFG['controls']?.ContactNumber?.touched" [PlaceHolder]="''"
                    [ControlName]="'ContactNumber'" [FormGroupName]="ExtraPhoneFG" [MaskPattern]="'(000)000-0000'"
                    [DropSpclChar]="true" [ShowMask]="true" [FormGroupInvalid]="extraFromInValid">
                </eprx-input>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 margin_top_1_7rem" [formGroup]="ExtraPhoneFG">
                <input class="form-check-input" type="checkbox" id="extraphonePrefered" formControlName="IsPreferred" />
                <label class="form-check-label" for="extraphonePrefered">
                    Is Preferred
                </label>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" appShortcutKey [AltKey]="'o'" class="hotkey_success"
            (click)="addExtraPhone()"><span>O</span>Ok</button>
        <button type="button" appShortcutKey [AltKey]="'c'" class="hotkey_success"
            (click)="closeModal()"><span>C</span>Cancel</button>
    </div>
</ng-template>

<ng-template #delete let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Confirmation</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body edit-patient">
        <div class="row">
            <div class="col">
                <table class="table table-hover">
                    <tbody>
                        <label>Are you sure to delete the Selected Contact?</label>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="hotkey_primary" (click)="c('Close click')" (click)="deleteExtraPhone()"
            appShortcutKey InputKey="y"><b>Y</b> YES
        </button>
        <button type="button" class="hotkey_primary" (click)="c('Close click')" appShortcutKey [InputKey]="'n'">
            <b>N</b> NO
        </button>
    </div>
</ng-template>
<app-send-sms *ngIf="openSmsPopup"
[PatientData] = "patientInfoFG?.value"
(ClosePatientModal)="closeModalSMS($event)"
>
</app-send-sms>
