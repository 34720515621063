import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormBuilder } from "@angular/forms";
import { BucketService, CommonService, EditDrugService, InventoryService } from "src/app/services";
import { DrugPO, PaginationModel, PhysicalInventory } from "src/app/models";
import { ValidateFormFieldsUtils } from "src/app/utils";
import * as moment from "moment";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FormatsUtil } from "src/app/utils/formats.util";

@Component({
  selector: "app-physical-inventory",
  templateUrl: "./physical-inventory.component.html"
})
export class PhysicalInventoryComponent implements OnInit, AfterViewInit {

    @Input() drugInfo: any;
    @Input() drugDefVal: any;
    @Input() openedFrm: any;
    @Input() BucketInfo: any = null;
    @Input() frmEDI810: boolean;
    @Input() ImageURL: any;
    @Input() selectedData: any;

    @Output()
    CancelPhyscInven = new EventEmitter<boolean>();

    @ViewChild("PHYSICALINVEN", { static: true })
    PHYSICALINVEN: ElementRef;

    modelRef: any;
    physcInventFG: any;
    bucketSearchFil: {
        Code: any;
        Name: any;
        BucketType: any;
        SpecificType: any;
        pageNumber: number;
        pageSize: number;
    };
    bucketInfo: any[];
    totalQty: any;
    manuFacName: any;
    drugLotExpiryDate: any;
    paginationModel: PaginationModel = new PaginationModel();
    unsubscribe$: Subject<void> = new Subject();
    constructor(
        private _modalService: NgbModal,
        private _fb: FormBuilder,
        private _bucketServ: BucketService,
        private _invenServ: InventoryService,
        private _formUtils: ValidateFormFieldsUtils,
        private _drugSvc: EditDrugService,
        public _formatsUtil:FormatsUtil,
        private _comnSvc: CommonService
    ) {
    }

    ngOnInit() {
        if (this.drugInfo && this.drugInfo.Drug && this.drugDefVal && !this.openedFrm) {
            if (this.drugDefVal["Organization"].find(val => val["Id"] === this.drugInfo.Drug.ManufactId)) {
                this.manuFacName = this.drugDefVal["Organization"].find(val => val["Id"] === this.drugInfo.Drug.ManufactId)["Name"];
            }
        } else if (this.openedFrm) {
            this.manuFacName = this.openedFrm;
        }
        this.bucketSearchFil = {
            Code: null,
            Name: null,
            BucketType: null,
            SpecificType: null,
            pageNumber: this.paginationModel.pageNumber,
            pageSize: this.paginationModel.pageSize
        };
        this.bucketInfo = this._comnSvc.bucketList$["source"]["value"];
        this.createAndPatchDefault();
        this.modelRef = this._modalService.open(this.PHYSICALINVEN, {
            size: "lg",
            windowClass: "modal-center-to-page",
            keyboard: false,
            backdrop: false
        });
        this.getLatestDrugLotExpiryDate();
    }

    ngAfterViewInit() {
        // Need SetTimeOut 
        setTimeout(() => {
            if (document.getElementById("packs")) {
                const input = <HTMLInputElement>document.getElementById("packs");
                input.setSelectionRange(0, 1);
                input.focus();
            }
        },20);
    }

    createAndPatchDefault() {
        this.totalQty = 0;
        this.createFG();
        // const stngValue = this._comnSvc.getSetttingValue("RxSettings", "DEF_GENERAL_BUCKET");
        // this.physcInventFG.controls["bucketType"].patchValue(stngValue && stngValue !== "" ? +stngValue :
        // this.bucketInfo && this.bucketInfo.length > 0 ? this.bucketInfo[0]["Id"] : null);
        if (this.BucketInfo) {
            const bucket = this.bucketInfo.find(list => list.Id === this.BucketInfo.BucketId);
            if (bucket && bucket.Id) {
            this.physcInventFG.patchValue({
                bucketType: this.selectedData && this.selectedData.BucketId ? this.selectedData.BucketId : bucket.Id,
                QtyPack:  (this.drugInfo && this.drugInfo.Drug  && this.drugInfo.Drug.QtyPack ) ?
                (parseFloat(this.drugInfo.Drug.QtyPack)).toFixed(3) : "0.000"
            });
        }
        }
    }

    createFG() {
        this.physcInventFG = this._fb.group({
            packs: 0,
            looseQTY: 0.000,
            bucketType: null,
            comments: "",
            QtyPack: null
        });
    }

    async calculatePhysicalInvent() {
        if (this.physcInventFG.valid) {
            const physcInven = this.physcInventFG.getRawValue();
            const newQty = (this.drugInfo?.Drug?.QtyPack * +physcInven.packs) + +physcInven.looseQTY;
            this.totalQty = newQty;
            const physcInvenObj = new PhysicalInventory();
            physcInvenObj.oldQty = this.drugInfo["QtyInHand"];
            physcInvenObj.newQty = newQty;
            physcInvenObj.drugId = this.drugInfo.Drug.Id;
            physcInvenObj.bucketId = physcInven.bucketType;
            physcInvenObj.IsFromEditDrug = true;
            physcInvenObj.comments = physcInven.comments;
            physcInvenObj.Packs = physcInven.packs;
            physcInvenObj.LooseQty = physcInven.looseQTY;
            if (this.openedFrm) {
                this.drugInfo["Drug"]["IsInvMangd"] = true;
                await this._drugSvc.updateDrugInfo(this.drugInfo).toPromise();
            }
            this._invenServ
                .addPhyscialInventoryData(physcInvenObj).pipe(takeUntil(this.unsubscribe$))
                .subscribe(resp => {
                    if (resp) {
                        this.closeCanclPhysicalInven(true);
                       }
                    });
        } else {
            this._formUtils.validateAllFormFields(this.physcInventFG);
        }

    }


    calculatePhysicInventTotalQty(type?) {
        const physcInven = this.physcInventFG.getRawValue();
        if(this.drugInfo.Drug && this.drugInfo.Drug.QtyPack && physcInven && physcInven.packs && physcInven.looseQTY) {
            const newQty = (this.drugInfo.Drug.QtyPack * +physcInven.packs) + +physcInven.looseQTY;
            this.totalQty = newQty.toFixed(3);
        }
        if(type ===  "LooseQty")
             this.physcInventFG.controls["looseQTY"].patchValue(physcInven.looseQTY ? (+physcInven.looseQTY).toFixed(3) : "0.000")
    }

    closeModal() {
        if (this.modelRef) {
            this.modelRef.close();
            this.modelRef = null;
        }
    }

    closeCanclPhysicalInven(isCancel: boolean) {
        this.closeModal();
        this.CancelPhyscInven.emit(isCancel);
    }

    getLatestDrugLotExpiryDate() {
        let drugpo = new DrugPO();
        const physcInven = this.physcInventFG.getRawValue();
        drugpo.DrugId = this.drugInfo.Drug.Id;
        drugpo.BucketId = physcInven.bucketType;
        this._bucketServ
            .getInventoryLatestDrugLotExpiryDate(drugpo).pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp) {
                    this.drugLotExpiryDate = moment.utc(resp).local().format("MM/DD/YYYY");
                }
            });
    }
    ngOnDestroy(){
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
