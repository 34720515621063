import { ToolsComponent } from "./tools.component";
import { WjGridModule } from "@grapecity/wijmo.angular2.grid";
import { SharedModule } from "./../shared/shared.module";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgbModule} from "@ng-bootstrap/ng-bootstrap";
import { ToolsRoutingModule } from "./tools-routing.module";
import { MergeComponent } from "./merge/merge.component";
import { CustFormElemnsModule } from "../cust-form-elemns/cust-form-elemns.module";
import { MergePatientComponent } from './merge-patient/merge-patient.component';
import { MergePatGuard } from "src/app/guards";

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    ToolsRoutingModule,
    CustFormElemnsModule,
    SharedModule,
    WjGridModule
  ],
  declarations: [ MergeComponent , ToolsComponent, MergePatientComponent],
  exports: [MergePatientComponent],
  providers: [MergePatGuard]
})
export class ToolsModule { }
