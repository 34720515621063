import { Component, OnInit, Input, Output, EventEmitter, HostListener, ViewChild } from "@angular/core";
import { CollectionView } from "@grapecity/wijmo";
import * as wjcGrid from "@grapecity/wijmo.grid"
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { RxStore } from "src/app/store";
import { DrugBucketUtil } from "src/app/utils/drug-bucket.util";
import { AlertService, CommonService, DrugService } from "src/app/services";
import * as moment from "moment";
import { CommonUtil, DrugUtils, NRxUtils, RxBillingUtil, WijimoUtil } from "src/app/utils";
import { FormBuilder, FormGroup } from "@angular/forms";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { FormatsUtil } from "src/app/utils/formats.util";
import { DrugComponent } from "../../drug/drug.component";
import { AccCategoryE, DrugFileE } from "src/app/models";
import { PrivMaskGuard } from "src/app/guards";
import { MsgHoverOverConfig } from "src/app/app.hover-over";
import { OverrideLogComponent } from "../override-log/override-log.component";


@Component({
    selector: "app-equivalent-drugs",
    templateUrl: "./equivalent-drugs.component.html",
    styles: []
})
export class EquivalentDrugsComponent implements OnInit {
    checkedGenDrug: any;
    checkedBrandDrug: any;
    selectedDrugItem: string;
    accessPrivCate: any;
    drugPrivs: any;
    DrugId: null;
    eqvSearchRest: any;

    @Input() set EquivalentDrugWJ(ed: any) {
        this.equivalentDrugWJ = ed;
    }
    @Input() rxFG: any;
    @Input() rxFGFormGroup: FormGroup;
    @Input()
    set EqulDrugPopType(ed: any) {
        this.equlDrugPopType = ed;
    }

    @Input()
    set IsFrom(ed: any) {
        this.isFrom = ed;
    }

    @Input()
    set DiscDrugInfo(dd: any) {
        this.discDrugInfo = dd;
    }
    @Input() isGenrcEql: boolean;
    @Input() isFromRecall: boolean;
    @Input() BucketId: number;
    @Input() preferredDrugInfo : any;
    @Output() OptedDrugInfo = new EventEmitter();
    @Output() emitValuesForeRx = new EventEmitter();
    @Output() emitWhenContinueDrug = new EventEmitter();

    @Output()
    CloseAdminRxPopUp = new EventEmitter<{}>();
    @Output() closeDiscForDrugReset = new EventEmitter<any>();
    @Output() emitPrefStatus = new EventEmitter<any>();
    @ViewChild("MarkAsPrefDrug", { static: true })
    MarkAsPrefDrug: any;

    equivalentDrugWJ: CollectionView;
    equivalentBrandDrugWJ: CollectionView;
    equivalentGenDrugWJ: CollectionView;
    equlDrugPopType: any;
    discDrugInfo: any;
    rxHeaders: any[] = [];
    selectedRow: any;
    wjHeaders: { hName: string; isVisible: boolean; width: number; }[];
    wjHeadersForGen: { hName: string; isVisible: boolean; width: number; }[];
    selectedItem: any;
    typeofDrug: any;
    brandedDrugList: any;
    brandedDrugListCopy: any;
    genricDrugList: any;
    genricDrugListCopy: any;
    payLoad: any;
    PrefDrugStng: any;
    DrugList = [];
    modelRef: any;
    isFrom: string;
    prefDrugSetting: any;
    isHideButton: boolean = true;
    isErxRx: boolean = false;
    selectedDrugInfo: any = null;
    refreshFG: FormGroup;
    genericGrid: wjcGrid.FlexGrid;
    branchGrid: wjcGrid.FlexGrid;
    unsubscribe$: Subject<void> = new Subject();
    isPaidPayRecAvail : boolean;
    isDiscChecked: boolean = false;

    @HostListener("window:keydown", ["$event"])
    keyEvent(event: KeyboardEvent) {
        if (event.which === 27 || event.which === 9) {
            // event.preventDefault();
            //  this.closepopmodal();
        }
    }

    constructor(public activeModal: NgbActiveModal, private _rxStore: RxStore,
        private _alertService: AlertService, private _wijimoUtils: WijimoUtil,
        private _drugBuckUtil: DrugBucketUtil, private _drugServc: DrugService,
        private _nrxUtls: NRxUtils, private _fb: FormBuilder, public _cmnUtils : CommonUtil,
          private _commonServ: CommonService, private _modalServ: NgbModal,
        public _formatsUtil:FormatsUtil,private _privMaskGuard: PrivMaskGuard, private _drugUtils : DrugUtils, private _rxBillng: RxBillingUtil, private _commonUtils: CommonUtil) {
                this.createFG();
                this.accessPrivCate = AccCategoryE;
                this.drugPrivs = DrugFileE;
          }

    ngOnInit() {
        if (this.equlDrugPopType && this.rxFGFormGroup)
            this.rxFGFormGroup.controls['equilantSelectTypeForPrefDrug'].patchValue(this.equlDrugPopType);
        this.prefDrugSetting = +this._commonServ.getSetttingValue("RxSettings", "PRF_DRUG_SLCT_ON_RX_FILL");
        this.patchDefaultValHeaders();
        this._wijimoUtils.wjSavedData$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(w => {
            if (w && w["WijmoKey"] && (w["WijmoKey"] === "equivalentBrandDrugs" || w["WijmoKey"] === "equivalentGenDrugs")) {
                this.patchDefaultValHeaders();
            }
        });
        this.filterData();
        if ((this.prefDrugSetting == 2 && (this.equlDrugPopType == 'notPref' || this.equlDrugPopType == 'eRx'))) {
            this.isHideButton = false;
        } else {
            this.isHideButton = true;
        }
        this.eqvSearchRest = MsgHoverOverConfig;
    }
    filterData() {
        const Branded = [], Genric = [];
        if (this.equivalentDrugWJ && this.equivalentDrugWJ.items.length > 0) {
         if(this.equlDrugPopType && this.rxFGFormGroup)
             this.rxFGFormGroup.controls['equilantSelectTypeForPrefDrug'].patchValue(this.equlDrugPopType);

         this.prefDrugSetting =  +this._commonServ.getSetttingValue("RxSettings", "PRF_DRUG_SLCT_ON_RX_FILL");
        const Branded = [], Genric = [];
        if (this.equivalentDrugWJ && this.equivalentDrugWJ.items.length > 0 ) {
            this.equivalentDrugWJ.items.forEach(item => {
                item["isbranded"] ? Branded.push(item) : Genric.push(item);
            });

            if (this.equlDrugPopType && this.equlDrugPopType === 'rxScreen') {
                Branded.sort((a, b) => (a.ispreferred > b.ispreferred) ? -1 : 1);
                Genric.sort((a, b) => (a.ispreferred > b.ispreferred) ? -1 : 1);
            }
            this.brandedDrugList = Branded;
            this.brandedDrugListCopy = Branded;
            this.genricDrugList = Genric;
            this.genricDrugListCopy = Genric;
            this.discontinuedDrugFilter(false,Branded,Genric)

        }
        if(this.rxFGFormGroup && this.rxFGFormGroup.value && this.rxFGFormGroup.value.RxPay && this.rxFGFormGroup.value.insuranceInfo){
            this.isPaidPayRecAvail = (this._cmnUtils.checkPayRecExistOrNotForSelcRx(this.rxFGFormGroup.value.RxPay) && !this.rxFGFormGroup.value.insuranceInfo.insurance.IsCash);
        }
        
    }
}
    async patchDefaultValHeaders() {
        const storedWJ = await this._wijimoUtils.getWJSavedData("equivalentBrandDrugs");
        this.wjHeaders = this._wijimoUtils.patchDefHeader("equivalentBrandDrugs", storedWJ);
        const storedWJ1 = await this._wijimoUtils.getWJSavedData("equivalentGenDrugs");
        this.wjHeadersForGen = this._wijimoUtils.patchDefHeader("equivalentGenDrugs", storedWJ1);
    }

    checkHasPrivsToAccess(category: string, privType: string) {
        return this._privMaskGuard.canActivate(category, privType);
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    generateWJBrand(BrandedOrGenric) {
        if (BrandedOrGenric && BrandedOrGenric.length) {
            const mapDataSet = [];
            BrandedOrGenric.map((Rx, i) => {
                const k = {};
                k["item"] = Rx;
                k["Drug Name"] = Rx.drugname +' '+ (Rx?.strength ? Rx?.strength : '') +' '+ (Rx?.drugunit ? Rx?.drugunit : '') +' '+ (Rx?.drugformname ? Rx?.drugformname : '');
                k["Drug Class"] = Rx.drugclass;
                k["NDC"] = this._formatsUtil.getNDCFormat(Rx.ndc);
                k["Size"] = Rx.qtypack.toFixed(3);
                k["Pck/Unit Cost"] = Rx.unitpricecost ? "$" + Rx.unitpricecost.toFixed(4) : "$0.0000";
                k["Pck/Unit AWP"] = Rx.unitpriceawp ? "$" + Rx.unitpriceawp.toFixed(3) : "$0.000";
                k["Made By"] = Rx.manufacturername;
                k["Qty in Hand"] = this.getQtyInHand(Rx.manageinventory);
                k["Last Disp"] = Rx.dispensedDate ? moment(Rx.dispensedDate).format("MM/DD/YYYY") : "";
                k["D/C Date"] = Rx.discontdt ? moment.utc(Rx.discontdt).local().format("MM/DD/YYYY") : null;
                k["Brand Name"] = Rx.drugbandname ? (Rx.drugbandname).toUpperCase() : "";
                k["Generic Name"] = Rx.genericname ? (Rx.genericname).toUpperCase() : "";
                k["Drug Type"] = Rx.drugtype ? Rx.drugtype : null;
                k["Brand?"] = Rx.isbranded ? "Y" : "N";
                k["Is Pref?"] = Rx.ispreferred ? "Y" : "N";
                k["Equ.Avl?"] = Rx.equivalentAvailability;
                k["Gen.Avl?"] = Rx.genericAvailability;
                k["Inv?"] = Rx.isinvmangd ? "Y" : "N";
                k["Is 340B?"] = Rx.Is340B ? "Y" : "N";
                k["itemFromEquil"] = true;
                k["Ispreferred"] = Rx.ispreferred;
                k["Isdiscontdate"] = Rx.isdiscontdate;
                k["isactive"] = Rx.isactive;
                k["id"] = Rx.id;
                k["drugcode"] = (Rx.drugcode) ? Rx.drugcode : null;
                k["manageinventory"] = Rx.manageinventory;
                mapDataSet.push(k);
            })
            this.equivalentBrandDrugWJ = new CollectionView(mapDataSet,
                {
                    sortComparer: (a: any, b: any) => {
                        return moment(a).isValid() && moment(b).isValid()
                            ? moment(a).diff(moment(b)) : null;
                    }
                });
        } else{
            this.equivalentBrandDrugWJ= new CollectionView(null);
        }

    }
    itemFormatter = (panel, r, c, cell) => {
        const row = panel.rows[r];
        if (row.dataItem) {
            cell.style.background = "";
            if (panel.cellType === wjcGrid.CellType.Cell && panel.columns[c]._hdr === "Is Pref?") {
                let key = panel.columns[c]._hdr;
                if (row.dataItem[key] === "Y") {
                    cell.style.backgroundColor = "#34e534";
                    cell.style.color = "#fff";
                } else {
                    cell.style.backgroundColor = "";
                    cell.style.color = "";
                }
            }
        }
    }
    generateWJGeneric(BrandedOrGenric) {
        if (BrandedOrGenric && BrandedOrGenric.length) {
            const mapDataSet = [];
            BrandedOrGenric.map((Rx, i) => {
                const k = {};
                k["rowId"] = i;
                k["item"] = Rx;
                k["Drug Name"] = Rx.drugname +' '+ (Rx?.strength ? Rx?.strength : '') +' '+ (Rx?.drugunit ? Rx?.drugunit : '') +' '+ (Rx?.drugformname ? Rx?.drugformname : '');
                k["Drug Class"] = Rx.drugclass;
                k["NDC"] = this._formatsUtil.getNDCFormat(Rx.ndc);
                k["Size"] = Rx.qtypack.toFixed(3);
                k["Pck/Unit Cost"] = Rx.unitpricecost ? "$" + Rx.unitpricecost.toFixed(4) : "$0.0000";
                k["Pck/Unit AWP"] = Rx.unitpriceawp ? "$" + Rx.unitpriceawp.toFixed(3) : "$0.000";
                k["Made By"] = Rx.manufacturername;
                k["Qty in Hand"] = this.getQtyInHand(Rx.manageinventory);
                k["Last Disp"] = Rx.dispensedDate ? moment(Rx.dispensedDate).format("MM/DD/YYYY") : "";
                k["D/C Date"] = Rx.discontdt ? moment.utc(Rx.discontdt).local().format("MM/DD/YYYY") : null;
                k["Brand Name"] = Rx.drugbandname ? (Rx.drugbandname).toUpperCase() : "";
                k["Generic Name"] = Rx.genericname ? (Rx.genericname).toUpperCase() : "";
                k["Drug Type"] = Rx.drugtype ? Rx.drugtype : null;
                k["Brand?"] = Rx.isbranded ? "Y" : "N";
                k["Is Pref?"] = Rx.ispreferred ? "Y" : "N";
                k["Equ.Avl?"] = Rx.equivalentAvailability;
                k["Gen.Avl?"] = Rx.genericAvailability;
                k["Inv?"] = Rx.isinvmangd ? "Y" : "N";
                k["Is 340B?"] = Rx.Is340B ? "Y" : "N";
                k["Ispreferred"] = Rx.ispreferred;
                k["Isdiscontdate"] = Rx.isdiscontdate;               
                k["id"] = Rx.id;
                k["itemFromEquil"] = true;
                k["drugcode"] = (Rx.drugcode) ? Rx.drugcode : null;
                k["isactive"] = Rx.isactive;                
                k["manageinventory"] = Rx.manageinventory;
                k["drugname"] = (Rx.drugname + " " + (Rx.strength ? Rx.strength : '') + " " + (Rx.drugformname ? Rx.drugformname : ''));
                k["ndc"] = this._formatsUtil.getNDCFormat(Rx.ndc);
                k["unitpricecost"] = Rx.unitpricecost ? "$" + Rx.unitpricecost.toFixed(3) : "$0.000";
                k["awppack"] = Rx.unitpriceawp ? "$" + Rx.unitpriceawp.toFixed(3) : "$0.000";
                k["manufacturername"] = Rx.manufacturername;
                mapDataSet.push(k);
            })
        this.equivalentGenDrugWJ = new CollectionView(mapDataSet,
            {
                sortComparer: (a: any, b: any) => {
                    return moment(a).isValid() && moment(b).isValid()
                        ? moment(a).diff(moment(b)) : null;
                }
            });
        } else{
            this.equivalentGenDrugWJ = new CollectionView(null);
        }
        this.rxHeaders = [
            "Drug Name",
            "NDC",
            "Manufacturer",
            "Size",
            "AWP",
            "Stock Qty",
            "Is Pref?",
            "Cost",
            "Last Dispensed",
            "Inv?",
            "Is 340B?"
        ]

    }

    onRowDblclicked(s: any, e: any) {
        if(!this.isPaidPayRecAvail){
            const drugInfo = s.selectedRows[0].dataItem;
                const is340B =  this._commonServ.getSetttingValue("ThreeFourtyBSettings", "340B_PHARM") === "1";
                if (drugInfo && drugInfo.Isdiscontdate === "YES" && this.rxFG?.rxType != 'er') {
                    if (this.activeModal) {
                        this.activeModal.close();
                    }
                    this.openOveride("Rx Annotation","AllowDiscontinueDrug", drugInfo?.item ? drugInfo?.item : drugInfo);
                    // this._alertService.error("Discontinued Drug cannot be selected.");
                    return;
                }
                if (this.rxFG && this.rxFG.PrescReFill && this.rxFG.PrescReFill.Is340B && this._drugBuckUtil.getInvSetForPatPrescDrg("Force_340B_NDC_for_340B_Rx") && drugInfo && !drugInfo.Is340B && is340B) {
                    this._alertService.error("Non-340B Drug cannot be selected for 340B Rx.");
                    return;
                }
                if (this.activeModal) {
                    this.activeModal.close();
                }
                this.emitWhenContinueDrug.emit("Continue");
                     this.OptedDrugInfo.emit(drugInfo);
                this._rxStore.storeGenrcEquvlntDrugs(null);
        }
    }


    init(flex: wjcGrid.FlexGrid) {
        flex.refresh();
        flex.columnHeaders.rows[0].height = 42;
        flex.columnHeaders.rows[0].wordWrap = true;
        flex.focus();
        this.genericGrid = flex;
    }
   
    closepopmodal(type?: any, cancelOverride?) {
        if (this.activeModal) {
            this.activeModal.close();
        }
        if (this.discDrugInfo.isdiscontdate == "YES" && !cancelOverride && this.equivalentDrugWJ && this.equivalentDrugWJ.items && this.equivalentDrugWJ.items.length && this.equlDrugPopType !== 'popup' && this.rxFG?.rxType == 'nr') {
            this.allowDiscontinue(true)
        } else {
            if((this.equlDrugPopType && this.equlDrugPopType === 'Disc' && this.equivalentDrugWJ && this.equivalentDrugWJ.items && this.equivalentDrugWJ.items.length == 0) || (this.discDrugInfo.isdiscontdate == "YES" && type == 'Close' && this.equlDrugPopType !== 'popup')){
                this.emitValuesForeRx.emit(type);  
            } else{
                this.CloseAdminRxPopUp.emit(type);
            }
        }
    }

    allowDiscontinue(val?) {
        this.openOveride("Rx Annotation","AllowDiscontinueDrug", val ? this.discDrugInfo?.item ? this.discDrugInfo?.item : this.discDrugInfo : this.selectedDrugInfo);
    }

    openOveride(CategoryType, type,drugData) {
        this.modelRef = this._modalServ.open(OverrideLogComponent, {
           centered: true,
           keyboard: false, backdrop: false,
           windowClass:'medium-modal-50'
       });
       this.modelRef.componentInstance.PrivMask = type;
       this.modelRef.componentInstance.CatId = CategoryType;
       this.modelRef.componentInstance.RxInfo = this.rxFGFormGroup.value;
       this.modelRef.componentInstance.Consolidated = true;
       this.modelRef.componentInstance.IsFrmInfo = true;
       this.modelRef.componentInstance.RxFG = this.rxFGFormGroup;
       this.modelRef.componentInstance.PrescReFillId = this.rxFG["PrescReFill"]['Id'];
       this.modelRef.componentInstance.PrescriptionId = this.rxFG["Prescription"]['Id'];
       this.modelRef.componentInstance.IsNotesDisplay = true;
       this.modelRef.componentInstance.Comp = true;
       this.modelRef.componentInstance.DrugInfofrmReset = drugData;
       this.modelRef.componentInstance.isFrmEqvPopup = true;
       this.modelRef.result.then(res => {
           if(res){
                type === "AllowDiscontinueDrug" ? this.emitWhenContinueDrug.emit("FrmOvveride") : this.emitWhenContinueDrug.emit("notDiscOvveride")
                this.OptedDrugInfo.emit(drugData);
                this._rxStore.storeGenrcEquvlntDrugs(null);
           } else {
                this.closepopmodal('Close', true)
           }
       });
   }

    closeEquavalentDrgsPoup() {
        this._rxStore.storeGenrcEquvlntDrugs(null);
        this.equivalentDrugWJ = null;
        this.CloseAdminRxPopUp.emit(null);
    }

    drugFieldFocus() {
        setTimeout(() => {
            if (document.getElementById("DrugNameMMS"))
               document.getElementById("DrugNameMMS").focus();
          }, 0);
    }

    getQtyInHand(manageInventory: any): any {
        if (manageInventory && manageInventory.length) {
            const isExist = manageInventory.find(buck => buck.BucketId === +this._drugBuckUtil.getDefaultBucketId()) || null;
            return isExist ? isExist["QtyInHand"].toFixed(3) : "0.000";
        } else {
            return "0.000";
        }
    }

    selectedRxGen(isChecked: boolean, checkedVal: any, type: any) {
        if (isChecked) {
            this.typeofDrug = type;
            this.selectedItem = checkedVal;
            this.checkedBrandDrug = this.checkedBrandDrug ? null : this.checkedBrandDrug;
            this.checkedGenDrug =
                (checkedVal && checkedVal["item"]?.["ndc"]) ? checkedVal["item"]["ndc"] : null;
        }
    }

    selectedRxBrand(isChecked: boolean, checkedVal: any, type: any) {
        if (isChecked) {
            this.typeofDrug = type;
            this.selectedItem = checkedVal;
            this.checkedGenDrug = this.checkedGenDrug ? null : this.checkedGenDrug;
            this.checkedBrandDrug = (checkedVal && checkedVal["item"]?.["ndc"]) ? checkedVal["item"]["ndc"] : null;
        }
    }
    markAsPref() {
        this.DrugList = [];
        this.PrefDrugStng = this._commonServ.getSetttingValue(
            "DrugSettings", "ONE_PREF_DRUG_PER_CLASS");
        if (this.selectedItem) {
            if (!this.selectedItem.Ispreferred) {
                if (this.typeofDrug === "gen") {
                    this.genricDrugList.map((item: any) => {
                        if (item.ndc === this.selectedItem.item?.ndc) {
                            this.payLoad = item;
                        }
                    });
                } else {
                    this.brandedDrugList.map((item: any) => {
                        if (item.ndc === this.selectedItem.item?.ndc) {
                            this.payLoad = item;
                        }
                    });
                }
                if (this.preferredDrugInfo.isPreferred) {
                    const name =
                            (this.preferredDrugInfo.drugname
                                ? this.preferredDrugInfo.drugname.trim().toUpperCase()
                                : "") +
                            " " +
                            (this.preferredDrugInfo.strength
                                ? this.preferredDrugInfo.strength.trim().toUpperCase()
                                : "") +
                            " " +
                            (this.preferredDrugInfo.drugformname
                                ? this.preferredDrugInfo.drugformname.trim().toUpperCase()
                                : "") +
                            " - " +
                            this._formatsUtil.getNDCFormat(this.preferredDrugInfo?.ndc);
                        this.DrugList.push(name);
                }
                this.equivalentDrugWJ.items.map((item) => {
                    if (item && item.ispreferred) {
                        const name =
                            (item.drugname
                                ? item.drugname.trim().toUpperCase()
                                : "") +
                            " " +
                            (item.strength
                                ? item.strength.trim().toUpperCase()
                                : "") +
                            " " +
                            (item.drugformname
                                ? item.drugformname.trim().toUpperCase()
                                : "") +
                            " - " +
                            this._formatsUtil.getNDCFormat(item?.ndc);
                        this.DrugList.push(name);
                    }
                });
                this.selectedDrugItem =
                (this.payLoad &&
                    (this.payLoad.drugname
                        ? this.payLoad.drugname.trim().toUpperCase()
                        : "") +
                    " " +
                    (this.payLoad.strength
                        ? this.payLoad.strength.trim().toUpperCase()
                        : "") +
                    " " +
                    (this.payLoad.drugformname
                        ? this.payLoad.drugformname.trim().toUpperCase()
                        : "") +
                    " - " +
                    (this.payLoad.ndc
                        ? this._formatsUtil.getNDCFormat(this.payLoad.ndc).toUpperCase(): null));
                    // (this.payLoad.ndc
                    //     ? this.payLoad.ndc.substr(0, 5) +
                    //       "-" +
                    //       this.payLoad.ndc.substr(5, 4) +
                    //       "-" +
                    //       this.payLoad.ndc.substr(9, 11)
                    //     : null));
                if(!this.payLoad?.id){
                    const dataForMMS = {
                        NDCCode : this.payLoad?.ndc ? this.payLoad?.ndc : null
                    }
                    this._drugServc.addDrugAutomaticallyFrmMMS(dataForMMS).pipe(takeUntil(this.unsubscribe$)).subscribe((resp)=>{
                        if(resp && resp.id){
                            this.payLoad = resp;
                            this.equivalentDrugWJ?.items?.map((res)=>{
                                if(res?.ndc === this.payLoad?.ndc){
                                    this.equivalentDrugWJ.remove(res) //To remove the item with ID = 0 
                                    res = this.payLoad 
                                    this.equivalentDrugWJ.addNew(res); // To add the item after adding from MMS Search
                                }
                            });
                            this.modelRef = this._modalServ.open(this.MarkAsPrefDrug, {
                                centered: true,
                                backdrop: false,
                                keyboard: false,
                                windowClass: "large--content",
                            });
                        } else{
                            this._alertService.error("Drug is not found in MMS Search");
                        }
                   });
                } else{
                    this.modelRef = this._modalServ.open(this.MarkAsPrefDrug, {
                        centered: true,
                        backdrop: false,
                        keyboard: false,
                        windowClass: "large--content",
                    });
                }

            } else {
                this._alertService.error(
                    "Selected Drug is already marked as Preferred."
                );
            }
        } else {
            this._alertService.error("Please select atleast one Drug.");
        }
    }

    discontinuedDrugFilter(val,branded?,generic?) {
        if (!val) {
            let brandedNotDiscDrugList  = branded.filter(item => item.isdiscontdate !== "YES");
            let genricDiscDrugList  = generic.filter(item => item.isdiscontdate !== "YES");
            this.generateWJBrand(brandedNotDiscDrugList);
            this.generateWJGeneric(genricDiscDrugList);
        } else {
            this.generateWJBrand(branded);
            this.generateWJGeneric(generic);
        }
    }

    continueMarkPref() {
        if (this.modelRef) {
            this.modelRef.close();
            this.modelRef = null;
        }
        const Branded = [], Genric = [];
        this._drugServc.getUpdatePrefDrug(this.payLoad)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((resp) => {
            if (resp && resp.length > 0) {
                if (this.preferredDrugInfo) {
                    resp.map((data: any) => {
                        if (this.preferredDrugInfo.drugID === data.DrugId) {
                            this.preferredDrugInfo.isPreferred = data.IsPreferred;
                            this.emitPrefStatus.emit(data.IsPreferred);
                        }
                    });
                }
                this.equivalentDrugWJ.items.map((item: any) => {
                    resp.map((data: any) => {
                        if (item.id === data.DrugId) {
                            item.ispreferred = data.IsPreferred;
                        }
                    });
                    if (item["isbranded"] === true) {
                        Branded.push(item);
                    } else {
                        Genric.push(item);
                    }
                });
                this.discontinuedDrugFilter(this.isDiscChecked,Branded,Genric)
                this.selectedItem = null;
                this.checkedGenDrug = null;
                this.checkedBrandDrug = null;
            } else {
                this.selectedItem = null;
                this.checkedGenDrug = null;
                this.checkedBrandDrug = null;
                this._alertService.error(
                    "Mark as Preferred update is unsuccessful."
                );
            }
        });
    }

    cancelMarkPref() {
        if (this.modelRef) {
            this.modelRef.close();
            this.modelRef = null;
        }
    }
    closepopmodalForeRx(type) {
        if(type === 'IGNORE' || type === 'Cancel') {
                this.cancelMarkPref();
               this.emitValuesForeRx.emit(type);
        } else  if(["QuickInactive","Disc"].includes(this.equlDrugPopType)) {
            if (this.activeModal) {
                this.activeModal.close();
            }
            this._rxStore.storeGenrcEquvlntDrugs(null);
            this.OptedDrugInfo.emit(this.selectedDrugInfo);
        } else {
            this.emitWhenContinueDrug.emit(type);
            this.emitValuesForeRx.emit(this.selectedItem["item"]);
        }
    }
    onRowDblclicked1(s: any, e: any) {
        if(!this.isPaidPayRecAvail){
            const drugInfo = s?.selectedRows?.[0]?.dataItem;
        const is340B =  this._commonServ.getSetttingValue("ThreeFourtyBSettings", "340B_PHARM") === "1";
        // if (drugInfo && drugInfo.Isdiscontdate === "YES" && this.equlDrugPopType && (this.equlDrugPopType === 'Disc' || this.equlDrugPopType === 'inactive' || this.equlDrugPopType === 'QuickInactive' || this.equlDrugPopType === 'notPref')) {
        //     this._alertService.error("Discontinued Drug cannot be selected.");
        //     return;
        // }
        if (this.rxFG && this.rxFG.PrescReFill && this.rxFG.PrescReFill.Is340B && this._drugBuckUtil.getInvSetForPatPrescDrg("Force_340B_NDC_for_340B_Rx") && drugInfo && !drugInfo.Is340B && is340B) {
            this._alertService.error("Non-340B Drug cannot be selected for 340B Rx.");
            return;
        }
        this.selectedDrugInfo = drugInfo;
        }
    }
    createFG() {
        this.refreshFG = this._fb.group({ DrugName: null, NDCCode: null, QuickCode: null });
    }
    initBrand(flex: wjcGrid.FlexGrid) {
        this.genericGrid = flex;
    }
    async getDrugMMS() {
        let drugName = this.refreshFG.value['DrugName'];
        if (drugName) {
            drugName = drugName ? (drugName + '').toLowerCase().trim() : "";
            if (this._commonUtils.checkNDCFormat(drugName)) {
                drugName = drugName.replace(/-/g, '');
            }
            this.brandedDrugList = this.brandedDrugListCopy?.filter((item: any) => {
                item["strength"] = item["strength"] ? ((item["strength"] + '').toLowerCase()) : "";
                item["drugformname"] = item["drugformname"] ? ((item["drugformname"] + '').toLowerCase()) : "";
                item["drugname"] = item["drugname"] ? ((item["drugname"] + '').toLowerCase()) : "";
                const orginalDrugName = item["drugname"] + " " + item["strength"] + " " + item["drugformname"];
                return (item["drugcode"]?.includes(drugName) || item["strength"]?.includes(drugName) || item["drugformname"]?.includes(drugName) ||
                    item["drugname"]?.includes(drugName) || orginalDrugName?.includes(drugName));
            });

            this.genricDrugList = this.genricDrugListCopy?.filter((item: any) => {
                item["strength"] = item["strength"] ? ((item["strength"] + '').toLowerCase()) : "";
                item["drugformname"] = item["drugformname"] ? ((item["drugformname"] + '').toLowerCase()) : "";
                item["drugname"] = item["drugname"] ? ((item["drugname"] + '').toLowerCase()) : "";
                const orginalDrugName = item["drugname"] + " " + item["strength"] + " " + item["drugformname"];
                return (item["drugcode"]?.includes(drugName) || item["strength"]?.includes(drugName) || item["drugformname"]?.includes(drugName) ||
                    item["drugname"]?.includes(drugName) || orginalDrugName?.includes(drugName));

            });
            this.discontinuedDrugFilter(this.isDiscChecked,this.brandedDrugList,this.genricDrugList)

        } else
            this.clearFilters();

    }
    focusToMMsData(event) {
        if (this.brandedDrugListCopy && this.brandedDrugListCopy.length) {
            event.preventDefault();
            this.branchGrid.focus();
        } else {
            event.preventDefault();
            this.genericGrid.focus();
        }
    }
    clearFilters() {
        this.refreshFG.reset();
        this.createFG();
        this.discontinuedDrugFilter(this.isDiscChecked,this.brandedDrugListCopy,this.genricDrugListCopy)
    }

    getFocusVal(){
        return (this.selectedItem) && ((this.equlDrugPopType && (this.equlDrugPopType === 'eRx') || (this.isFrom == 'nDrug')) && (this.equlDrugPopType !== 'popup')) || (this.equlDrugPopType == 'QuickInactive' && this.selectedDrugInfo)
    }
    toggleColorLegend() {
        this._alertService.showHelpText({RxStatus: false, DrugName: false, Status: false, DrugForDrugFile: true});
    }

}
