<div class="exprx--common-block">
    <div class="eprx--block__header large_header">
        <div class="row">
            <div class="col">
                <span class="eprx--header__heading">Delivery Address</span>
            </div>
            <div class="col pull-right text-right">
                <button class="hotkey_success hotkey_primary--Override" title="Add Delivery Address" (keydown.tab)="focusOutFromAdd($event)"
                    autofocus (click)="openPopUpModalAdd(deliveryAddress)" appShortcutKey
                    [AltKey]="'a'"><span>A</span>ADD Delivery Address</button>
            </div>
        </div>
    </div>
    <div class="eprx--block__content erx--body">
        <wj-flex-grid #DeliveryAdd [headersVisibility]="'Column'" [selectionMode]="'Row'" [itemsSource]="deliveryAddWJ"
            [isReadOnly]="true" [columnPicker]="'patDeliveryAddListWJ'" (updatedView)="init(DeliveryAdd)" [allowSorting]=false
            (initialized)="Init(deliveryAddress, DeliveryAdd)" [ngClass]="!totalCount ? 'no-data':''"
            (keydown)="openPopOnWijimo(deliveryAddress, $event)">
            <wj-flex-grid-column [header]="'Action'" [width]="'0.7*'">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                        <i class="far fa-edit actions actions--Highlightfocus--icon" title="edit"
                            (click)="openEditAddress(deliveryAddress, DeliveryAdd, $event)"></i>
                        <i class="far fa-trash-alt actions danger-actve" title="delete"
                            (click)="getRowDetails(deleteAddress, DeliveryAdd, $event)"></i>
                        <i class="far fa-file-alt actions actions--Highlightfocus--icon" aria-hidden="true" title="Set as Primary Address"
                            (click)="getRowDetails(primaryAddress, DeliveryAdd, $event)"></i>
                </ng-template>
            </wj-flex-grid-column>
            <wj-flex-grid-column [header]="rxheader['hName']" [binding]="rxheader['hName']" [visible]="rxheader['isVisible']"
                *ngFor="let rxheader of wjHeaders; let i = index;"
                [width]="rxheader['width']">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                    <div *ngIf="([ 'Address1', 'Address2', 'City', 'State', 'Zip'].indexOf(rxheader['hName']) > -1)">
                        {{item[rxheader['hName']]}}
                    </div>
                </ng-template>
            </wj-flex-grid-column>
            <app-no-data-found *ngIf="!isDataExists"></app-no-data-found>
        </wj-flex-grid>
        <app-eprx-pagination *ngIf="totalCount" [TotalCount]="totalCount" [FromModal]="true" [PageSize]="pageSize" [WijmoName]="DeliveryAdd"
            [GridName]="'Deliveryaddress'" (OnPageSelected)="setPage($event)" (OnSizeSelected)="setPageSize(pageSize)"
            (OnFocusOut)="focusToFirst($event)"></app-eprx-pagination>
    </div>
</div>

<!-- Add/Edit Delivery Address modal popup -->
<ng-template #deliveryAddress let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Delivery Address </h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col">
                <eprx-input [AutoFocus]="true" [LabelText]="'Address'" [PlaceHolder]="'Address Line 1'"
                    [ControlName]="'AddressLine1'" [FormGroupName]="deliveryAddressFG" (TriggerOnChange)="onAddressChange($event.target.value,'Address1')"
                    [InputErrors]="deliveryAddressFG?.controls?.AddressLine1?.errors" [IsRequired]="true"
                    [MarkAsTouched]="deliveryAddressFG?.controls?.AddressLine1?.touched"
                    [ErrorDefs]="{required: 'Required'}" [MaxLength]="40">
                </eprx-input>
            </div>
            <div class="col">
                <eprx-input [LabelText]="'Address 2'" [PlaceHolder]="'Address Line 2'" [ControlName]="'AddressLine2'" (TriggerOnChange)="onAddressChange($event.target.value,'Address2')"
                    [FormGroupName]="deliveryAddressFG"></eprx-input>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <app-search-zip [FormGroupName]="deliveryAddressFG"
                [zipExtnCntrl]="'Extension'"  [FormGroupInvalid]="deliveryAddressFG?.controls?.ZipCode?.touched" [ControlName]="'ZipCode'"
                    [IsRequired]="true" [PatchZip]="deliveryAddressFG?.value?.ZipCode"
                    (OnZipSelected)="getCityStateFromZipCode($event)"></app-search-zip>
            </div>
            <div class="col">
                <app-search-state [FormGroupName]="deliveryAddressFG" [ControlName]="'StateId'"
                    [CityControlName]="'CityId'" [StateSelected]="stateSelected"
                    [InputErrors]="deliveryAddressFG?.controls?.StateId?.errors" [ErrorDefs]="{ required: 'Required' }"
                    [FormGroupInvalid]="deliveryAddressFG?.controls?.StateId?.touched" [IsRequired]="true"
                    (OnStateSelected)="optedStateValue($event)"></app-search-state>
            </div>
        </div>
        <div class="row margin_top_11px">
            <div class="col-md-6">
                <app-search-city [FormGroupName]="deliveryAddressFG" [ControlName]="'CityId'"
                    [StateControlName]="'StateId'" [zipControl]="'ZipCode'" [CitySelected]="citySelected"
                    [InputErrors]="deliveryAddressFG?.controls?.CityId?.errors" [ErrorDefs]="{ required: 'Required' }"
                    (OnCitySelected)="optedCitySelected($event)"
                    [FormGroupInvalid]="deliveryAddressFG?.controls?.CityId?.touched" [IsRequired]="true">
                </app-search-city>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button *ngIf="modalType === 'Add'" type="button" class="hotkey_success" (click)="addDeliveryAddress()"
            appShortcutKey [AltKey]="'s'"><span>S</span> SAVE</button>
        <button *ngIf="modalType === 'Update'" type="button" class="hotkey_success" (click)="updateDeliveryAddress()"
            appShortcutKey [AltKey]="'u'"><span>U</span> UPDATE</button>
        <button type="button" class="hotkey_success" (click)="d('Cross click')" appShortcutKey
            [AltKey]="'c'"><span>C</span> CANCEL</button>
    </div>
</ng-template>

<!-- delete delivery address modal popup -->
<ng-template #deleteAddress let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Confirmation</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="c('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>Are you sure you want to delete the selected delivery address(s)?</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="hotkey_primary" (click)="deletePatientAddress(); c('Close click')" appShortcutKey
            InputKey="y"><b>Y</b> YES </button>
        <button type="button" class="hotkey_primary" (click)="c('Close click')" appShortcutKey InputKey="n"><b>N</b>
            NO</button>
    </div>
</ng-template>

<!-- set primary address modal popup -->
<ng-template #primaryAddress let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Confirmation</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="c('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>Are you sure to make the selected delivery address as Primary?</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="hotkey_primary" (click)="c('Close click'); setPrimary()" appShortcutKey
            InputKey="y"><b>Y</b> YES </button>
        <button type="button" class="hotkey_primary" (click)="c('Close click')" appShortcutKey InputKey="n"><b>N</b>
            NO</button>
    </div>
</ng-template>
