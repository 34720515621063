<div class="exprx--common-block">
    <div class="eprx--block__header">
        <div class="eprx--header__heading">
            Patient Allergies (F2)
            <ul class="pull-right eprx--header__icons">
                <li>
                    <a (click)="openAllergiesModal()">
                             <button class="hotkey_success--Override hotkey_primary--Override"  title="Alt+A"  appShortcutKey [AltKey]="'a'" ><span>A</span>ADD</button>
                    </a>
                </li>
            </ul>
        </div>
    </div>
    <div class="eprx--block__content">
        <div class="box-body table-responsive overflow_y_auto max-height_10rem">
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th>Code</th>
                        <th>Description</th>
                        <th>Type</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let alergies of selectedAllergyList; let i=index">
                        <td>
                            {{alergies?.AllergyClassID}}
                        </td>
                        <td>
                            {{alergies?.AllergyDescription}}
                        </td>
                        <td> A</td>
                        <td>
                            <a (click)="deleteSelectedAllergy(alergies?.AllergyClassID, 'allergy')">
                                <i class="far fa-trash-alt danger-actve" aria-hidden="true"></i>
                            </a>
                        </td>
                    </tr>
                    <tr *ngFor="let drugAllergy of selectedDrugAllergyList; let i=index">
                        <td>
                            {{drugAllergy?.DrugCode}}
                        </td>
                        <td>
                            {{drugAllergy?.Name}}
                        </td>
                        <td> D</td>
                        <td>
                            <a (click)="deleteSelectedAllergy(drugAllergy?.DrugCode, 'drug')">
                                <i class="far fa-trash-alt danger-actve" aria-hidden="true"></i>
                            </a>
                        </td>
                    </tr>
                    <tr *ngFor="let alergies of selectedOtherAllergyList; let i=index">
                        <td>
                            {{alergies?.Id}}
                        </td>
                        <td>
                            {{alergies?.Name}}
                        </td>
                        <td>O</td>
                        <td>
                            <a (click)="deleteSelectedAllergy(alergies?.Name, 'other')">
                                <i class="far fa-trash-alt danger-actve" aria-hidden="true"></i>
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<ng-template #allergey let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title"> Patient Allergies aad</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')" (click)="emitAllergyData()">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body edit-patient">
        <div class="eprx--block__content erx--body">
            <div class="row">
                <div class="col-6">
                    <eprx-input [RxSelectId]="'Allergy Name'" [LabelText]="'Allergy Name'" [PlaceHolder]="'Allergy Name'" [AutoFocus]="true"
                        [HasControl]="false" (keydown.tab)="focusOutFromAllergySearch($event)"
                        (TriggerOnChange)="filterAllergy($event.target.value)"></eprx-input>
                    <wj-flex-grid class="height_38rem" *ngIf="hasAllegry" #allergyList [headersVisibility]="'Column'" [selectionMode]="'Row'"
                        [itemsSource]="AllergyWJ" [isReadOnly]="true"
                        (dblclick)="onRowDblclicked(allergyList, $event)"
                        (keydown.enter)="keyEventOnAllergyWijimo(allergyList)"
                        (keydown.space)="keyEventOnAllergyWijimo(allergyList)"
                        (updatedView)="init(allergyList)"
                        (keydown.tab)="focusOutFromAllergyWJ($event, allergyList)">
                        <wj-flex-grid-column header="" [width]="50">
                            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-cell="cell">
                                <span  class="text-center">
                                    <input class="form-check-input" type="checkbox" [id]="'quick'+item.Code" name=""
                                        [(ngModel)]="item.IsSelected" [checked]="item.IsSelected" (change)="clickOnCheckBox($event?.target?.checked, item)" />
                                    <label class="form-check-label" [for]="'quick'+item.Code">
                                    </label>
                                </span>
                            </ng-template>
                        </wj-flex-grid-column>
                        <wj-flex-grid-column [header]="allergy" [binding]="allergy" [visible]="true"
                            *ngFor="let allergy of allergyHeader; let i=index;"
                            [width]="(allergy === 'Name') ? 420 : 100">
                            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                <div>
                                    {{item[allergy]}}
                                </div>
                            </ng-template>
                        </wj-flex-grid-column>
                    </wj-flex-grid>
                    <span *ngIf="hasAllegry" class="color_orange">
                            Use Arrow keys to highlight .
                            Press Space or Enter key to select
                    </span>
                </div>
                <div class="col-6">
                    <div class="row">
                        <div class="col-12 form-group custom--input">
                            <label class="control-label seconday-search--grid">
                                <div class="row">
                                    <div class="col-4 color_cyan">
                                        Drug Name</div>
                                    <div class="col-8">
                                        <!-- <div class="text-right">
                                            <div><i class="far fa-lg fa-long-arrow-alt-right color_orange"></i><span> for Equv ;
                                                </span><b class="color_orange">Shift + <i class="far fa-lg fa-long-arrow-alt-right color_orange"
                                                        ></i></b><span> for Gen Equv</span></div>
                                        </div> -->
                                    </div>
                                </div>
                                <app-searchbox-global [SearchFor]="'Drug'" [DisableSerchFil]="true" [ClearInputValue]="resetDrugName"
                                    [DisableRadioButtons]="true" [IsAddNew]="false" [IsFocused]="drugFocus"   [IsHideDrugFilters]="true"
                                    (SelectedDrug)="selectedDrugInfo($event)" (keydown.shift.tab)="focusOutFromAllergySearch($event)" [SearchFrm]="'commonDrugSearch'"></app-searchbox-global>

                            </label>
                        </div>
                        <div class="col-12 form-group custom--input">
                            <label class="control-label">
                                <span> Other Allergies </span>
                                <input class="form-control" placeholder="To Start Search..."
                                    (keydown.enter)="saveOtherAllergValue($event)" (keydown.tab)="focustoSelectedList($event)">
                            </label>
                        </div>
                        <div class="col-12">
                                <label> Allergies Selected </label>
                            <wj-flex-grid class="height_31rem max-height_32rem"
                            *ngIf="hasselectedList" #selectedListwj [headersVisibility]="'Column'" [selectionMode]="'Row'"
                                [itemsSource]="selectedListWJ" [isReadOnly]="true"
                                (keydown.enter)="keyEventonSelectedWJ(selectedListwj)"
                                (updatedView)="init1(selectedListwj)"
                                (keydown.tab)="focusOutFromSelectedList($event)">
                                <wj-flex-grid-column [header]="allergy" [binding]="allergy" [visible]="true"
                                    *ngFor="let allergy of actvHeader; let i=index;"
                                    [width]="(allergy === 'Description') ? 340 : 100">
                                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                        <div>
                                            {{item[allergy]}}
                                        </div>
                                    </ng-template>
                                </wj-flex-grid-column>
                                <wj-flex-grid-column header="" [width]="50">
                                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-cell="cell">
                                            <span  class="text-center">
                                                    <a (click)="deleteAllergy(item.Code, item.Type)">
                                                            <!-- <i class="far fa-trash-alt danger-actve" aria-hidden="true"></i> -->
                                                            <i class="far fa-trash-alt danger fa-lg actions danger-actve"></i> 
                                                    </a>
                                            </span>
                                        </ng-template>
                                </wj-flex-grid-column>
                            </wj-flex-grid>
                            <span *ngIf="hasselectedList" class="color_orange">
                              Press Enter key to delete
                            </span>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <div class="modal-footer">
        <button id="okbutton" type="button" appShortcutKey [AltKey]="'o'"
         class="hotkey_success" (click)="emitAllergyData()"
            (click)="c('Close click');"><span>O</span>OK</button>
    </div>
</ng-template>
