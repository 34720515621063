<div class="editpatient">
    <div class="row content--heading">
        <div class="col-md-8 padding-0">
            <div class="row">
                <div class="icon">
                    <img src="assets/dist/Icons/Icon_rx2.png">
                </div>
                <div class="col-md-11 padding-0">
                    <div class="content--heading__title padding-0">Medical Administration Record (MAR)
                        <span class="content--name"></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-right pull-right col-md-4 padding-0">
            <button class="hotkey_success" *ngIf="(this.routeFrom === 'er' || this.routeFrom === 'brx')" (click)="saveMAR()">
                <span>S</span> Save</button>
            <button class="hotkey_success" *ngIf="(this.routeFrom === 'nr' || this.routeFrom === 'nrf' || this.routeFrom === 'rf')" (click)="saveRxLoc()">
                <span>S</span> Save</button>

            <button class="hotkey_success" (click)="closeModal()">Cancel</button>
        </div>
    </div>
    <div class="exprx--common-block edit-drug">
        <div class="eprx--block__header">
            <div class="eprx--header__heading">
                <div class="col-sm-12 padding-0">
                    <div class="row">
                        <div class="col-md-10">Printing Categories</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="eprx--block__content">
            <eprx-select ngbAutoFocus [LabelText]="'Category'" [PlaceHolder]="''" [FormGroupName]="MarFG" [ControlName]="'FacilityId'" [HasMultiple]="false"
                [List]="rxPatientMAR" [BindLabel]="'Code'" [BindLabel2]="'Name'" [BindValue]="'Code'" [LabelForId]="'Code'"
                (TriggerSelectValue)="selectedMARObj($event?.value)">
            </eprx-select>
        </div>
    </div>
</div>
