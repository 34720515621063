import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { constant } from "../app.constants";

@Injectable()
export class PrimeEscService {

    constructor(private _http: HttpClient) { }

    getSignHistoryByPatientId(patientId: any): Observable<any> {
        return this._http.get<any>(
            constant.GET_Signatures_ByPatient.replace("{patientId}", "" + patientId)
        );
    }

    getPatAckSign(transId: any, sigtype: any): Observable<any> {
        return this._http.get<any>(
            constant.GET_Signature_ACKSign
                .replace("{transId}", "" + transId)
                .replace("{sigtype}", "" + sigtype)
        );
    }

    getSignTransHist(filterData: any): Observable<any> {
        return this._http.post<any>(
            constant.POST_Signature_TransHist, filterData
        );
    }

    getPatientSignPrint(transId: any, sigtype: any, refillId: any): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set("Accept", "application/pdf");
        return this._http.get(
            constant.GET_Signature_Print
                .replace("{transId}", "" + transId)
                .replace("{sigtype}", "" + sigtype)
                .replace("{refillId}", "" + refillId)
            ,
            { headers: headers, responseType: "blob" }
        );
    }

    getSignatureReport(dataToPost: any): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set("Accept", "application/pdf");
        return this._http.post(
            constant.POST_Signature_GenerateReport, dataToPost, { headers: headers, responseType: "blob" }
        );
    }

    getSignByPatient(dataToPost: any): Observable<any> {
        return this._http.post<any>(
            constant.POST_Signature_ByDate, dataToPost
        );
    }

    getSignByRx(rxnum: any): Observable<any> {
        return this._http.get<any>(
            constant.GET_PrimeEsc_SearchByRx
                .replace("{rxnum}", "" + rxnum)
        );
    }

    getPOSreceipt(pickUpRxId: any): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set("Accept", "application/pdf");
        return this._http.get(
            constant.GET_POSReceipt_Print
                .replace("{pickUpRxId}", "" + pickUpRxId)
            ,
            { headers: headers, responseType: "blob" }
        );
    }

}
