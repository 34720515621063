
<ng-template #QUCKVERIFICATION let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModal()">
        <h4 header> Quick Verification </h4>
        <span body>
            <label> Received by</label>
            <div class="row">
                <div class="col padding--right__7">
                    <eprx-input ngbAutofocus [LabelText]="'Intials of the person who Received the Rx'" [PlaceHolder]="''"
                        [ControlName]="'RxReceivedBy'" [FormGroupName]="RxQuickVerification" [MaskPattern]="'000000.00'"
                        [IsRequired]="true" [ErrorDefs]="{required: 'Required'}" [InputErrors]="rxFG?.controls?.RxQuickVerification?.controls?.RxReceivedBy?.errors">
                    </eprx-input>
                </div>
            </div>
            <div class="row">
                <div class="col padding--right__7">
                    <eprx-date-picker [LabelText]="'Received Date'" [PlaceHolder]="''" [ControlName]="'RxRecedDate'"
                        [FormGroupName]="rxFG?.controls?.RxQuickVerification" [MaxDate]="" [IsRequired]="true"
                        [InputErrors]="rxFG?.controls?.RxQuickVerification?.controls?.RxRecedDate?.errors" [ErrorDefs]="{required: 'Invalid!'}">
                    </eprx-date-picker>
                </div>
                <div class="col padding--right__7">
                    <eprx-date-picker [LabelText]="'Received Time'" [PlaceHolder]="''" [ControlName]="'SpnRxRecedTime'"
                        [FormGroupName]="rxFG?.controls?.RxQuickVerification" [MaxDate]="" [IsRequired]="true"
                        [InputErrors]="rxFG?.controls?.RxQuickVerification?.controls?.SpnRxRecedTime?.errors"
                        [ErrorDefs]="{required: 'Invalid!'}">
                    </eprx-date-picker>
                </div>
            </div>
            <div class="row">
                <div class="col padding--right__7">
                    <eprx-input [LabelText]="'Password'" [PlaceHolder]="''" [ControlName]="'RxReceivedPassword'"
                        [FormGroupName]="rxFG?.controls?.RxQuickVerification" [IsRequired]="true" [InputErrors]="rxFG?.controls?.RxQuickVerification?.controls?.RxReceivedPassword?.errors"
                        [ErrorDefs]="{required: 'Invalid!'}">
                    </eprx-input>
                </div>
                <div class="col padding--right__7">
                </div>
            </div>
        </span>
        <!-- <button footer type="submit" class="submit" *ngIf="!rxFG?.controls?.RxQuickVerification?.valid"> Save2 </button> -->
        <button footer type="submit" class="hotkey_primary" (click)="closeModal()" (click)="saveFileDueDate()" (click)="saveQuickVerificationData()" appShortcutKey [CtrlKey]="'s'">
          <span>S</span>  Save </button>
        <button footer type="submit" class="hotkey_primary" (click)="closeModal()" appShortcutKey [CtrlKey]="'c'"><span>C</span> Cancel </button>
    </app-custom-modal>
</ng-template>
