import { Component, OnInit, Input } from "@angular/core";
import { CollectionView } from "@grapecity/wijmo";
import {
    rowDataLifeStyle,
    LifeStyleData,
    PaginationModel
} from "../../../models";
import {
    PatientService,
    AlertService
} from "../../../services";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NewAuditUtils, CommonUtil } from "src/app/utils";
import { MsgConfig } from "src/app/app.messages";
import * as wjcGrid from "@grapecity/wijmo.grid"

@Component({
    selector: "app-life-style-concept",
    templateUrl: "./life-style-concept.component.html"
})
export class PLifeStyleConceptComponent implements OnInit {

    @Input() patientId: any;
    paginationModel: PaginationModel = new PaginationModel();
    search = { Code: null, Description: null, pageNum: this.paginationModel.pageNumber, pageSize: this.paginationModel.pageSize };
    patLifeStyleCount: number;
    totalCount: number;
    lifeStyleWJ: CollectionView;
    patientLifeStyleWJ: CollectionView;
    actvHeaders: string[];
    modelRef: any;
    patientLifeStyle: LifeStyleData[] = [];
    modelRefSM: any;
    rowDetails: rowDataLifeStyle;
    wijimoId: wjcGrid.FlexGrid;
    optedData = [];
    lifestyleResp: any;
    isDataExists = true;


    constructor(
        private _editPatientService: PatientService,
        private modalService: NgbModal,
        private _alertService: AlertService,
        private _newAudit: NewAuditUtils,
        private _commonUtil: CommonUtil
    ) { }

    ngOnInit() {
        this.getPatientLifeStyle();
    }

    async getPatientLifeStyle() {
        this.patientLifeStyle = await this._editPatientService.getPatientLifeStyleConcepts(this.patientId).toPromise();
        this.generatePatientLifeStyleWJ(this.patientLifeStyle);
    }

    generatePatientLifeStyleWJ(patientLifeStyle) {
        if (patientLifeStyle && patientLifeStyle.length) {
            this.patLifeStyleCount = patientLifeStyle.length;
            this.isDataExists = true;
            this.patientLifeStyleWJ = new CollectionView(
                patientLifeStyle.map(patLifeStyle => {
                    const k = {};
                    k["LifestyleId"] = patLifeStyle.ConceptID;
                    k["Code"] = patLifeStyle.ConceptName;
                    k["Description"] = patLifeStyle.ConceptDescription;
                    return k;
                })
            );
        } else {
            this.patLifeStyleCount = 0;
            this.isDataExists = false;
            this.patientLifeStyleWJ = new CollectionView(null)
        }
    }

    async openPopUpModal(content) {
       this.search = { Code: null, Description: null, pageNum: this.paginationModel.pageNumber, pageSize: this.paginationModel.pageSize };
        await this.getLifeStyleList();
        this.modelRef = this.modalService.open(content, { size: "lg", windowClass: "modal-center-to-page", keyboard: false });
    }


    async getLifeStyleList() {
        const response = await this._editPatientService.getLifeStyleConceptsFiltered(this.search).toPromise();
        this.lifestyleResp = response;
        this.generateLifeStyleWJ(response);
    }

    generateLifeStyleWJ(lifeStyleList) {
        if (lifeStyleList && lifeStyleList.TotalCount > 0) {
            this.totalCount = lifeStyleList.TotalCount;
            this.lifeStyleWJ = new CollectionView(
                lifeStyleList.LifeStyles.map(lifeStyle => {
                    let k = {};
                    k["PatientId"] = this.patientId;
                    k["LifestyleId"] = lifeStyle.ConceptID;
                    k["Code"] = lifeStyle.ConceptName;
                    k["Description"] = lifeStyle.ConceptDescription;
                    if (this.patientLifeStyle && this.patientLifeStyle.length > 0 &&
                        this.patientLifeStyle.findIndex(val => val["ConceptID"] === lifeStyle.ConceptID) > -1) {
                            k["AlreadySelected"] = true;
                    } else {
                        k["AlreadySelected"] = false;
                    }
                    if (this.optedData && this.optedData.length > 0 &&
                        this.optedData.findIndex(val => val["LifestyleId"] === lifeStyle.ConceptID) > -1) {
                        k["IsSelected"] = true;
                    } else {
                        k["IsSelected"] = false;
                    }
                    k["IsDeleted"] = false;
                    return k;
                })
            );
            this.actvHeaders = ["Action", "Code", "Description"];
        } else {
            this.totalCount = 0;
        }
    }

    async addSelectdItems(itemsToAdd) {
        if (itemsToAdd && itemsToAdd.length > 0) {
            const resp = await this._editPatientService.savePatientLifeStyle(itemsToAdd).toPromise();
            if (resp === 1) {
                this._alertService.success("Life Styles added successfully.");
                await this.getPatientLifeStyle();
                this.saveAuditOnAdd(itemsToAdd);
            } else {
                this._alertService.error(MsgConfig.SAVE_FAIL);
            }
        }
    }

    Init(grid) {
        grid?.cells.hostElement.addEventListener('keydown', (e) => {
            if (e.key == 'Enter') {
                if (!this.patientLifeStyle || (this.patientLifeStyle && this.patientLifeStyle.length === 0)) {
                    this.removeFromSelectedList(grid.selectedItems[0]);
                    grid.selectedItems[0]["AlreadySelected"] = true;
                    this.addSelectdItems([grid.selectedItems[0]]);
                } else if (this.patientLifeStyle.findIndex(elm => elm.ConceptID === grid.selectedItems[0].LifestyleId) === -1) {
                    this.removeFromSelectedList(grid.selectedItems[0]);
                    grid.selectedItems[0]["AlreadySelected"] = true;
                    this.addSelectdItems([grid.selectedItems[0]]);
                } else {
                    this._alertService.error("Life Style already exists. Please select another one.");
                }
            }
        });
    }

    keyEventOnWijimo(grid, event) {
        const ht = grid.hitTest(event);
        if (event.which === 13) {
            event.preventDefault();
        }
        if (ht.cellType === 1) {
        if (!this.patientLifeStyle || (this.patientLifeStyle && this.patientLifeStyle.length === 0)) {
            this.removeFromSelectedList(grid.selectedItems[0]);
            grid.selectedItems[0]["AlreadySelected"] = true;
            this.addSelectdItems([grid.selectedItems[0]]);
        } else if (this.patientLifeStyle.findIndex(elm => elm.ConceptID === grid.selectedItems[0].LifestyleId) === -1) {
            this.removeFromSelectedList(grid.selectedItems[0]);
            grid.selectedItems[0]["AlreadySelected"] = true;
            this.addSelectdItems([grid.selectedItems[0]]);
        } else {
            this._alertService.error("Life Style already exists. Please select another one.");
        }
    }
    }

    removeFromSelectedList(data) {
        if (this.optedData && this.optedData.length > 0) {
            const index = this.optedData.findIndex(val => val["LifestyleId"] === data["LifestyleId"]);
            if (index > -1) {
                this.optedData.splice(index, 1);
            }
        }
    }

    addMultiple() {
        const itemsSelected = this.optedData;
        if (itemsSelected && itemsSelected["length"] > 0) {
            this.optedData = null;
            this.addSelectdItems(itemsSelected);
            this.closeModal()
        } else {
            if (this.patientLifeStyle.length) {
                this.closeModal()
            } else {
                this._alertService.error("Please select atleast one Life Style.");
            }
        }
    }

    closeModal() {
        if (this.modelRef) {
            this.modelRef.close();
        }
    }

    changeLifeStyle(item, event) {
        if (!item["IsSelected"] || (event && event.target.checked)) {
            item["IsSelected"] = !item["IsSelected"];
            this.optedData = this.optedData ? this.optedData : [];
            this.optedData.push(item);
        } else {
            item["IsSelected"] = !item["IsSelected"];
            const index = this.optedData.findIndex(val => val["LifestyleId"] === item["LifestyleId"]);
            this.optedData.splice(index, 1);
        }
    }

    getFilteredData(code, param) {
        if (code === "") {
            code = null;
            this.generateLifeStyleWJ(this.lifestyleResp);
        }
        if (code !== this.search[param]) {
            if (code) {
                this.search[param] = code;
                this.getLifeStyleList();
            } else {
                this.search[param] = null;
                this.getLifeStyleList();
            }
        }
    }

    setPage(page: number) {
        this.search.pageNum = page;
        this.getLifeStyleList();
    }

    setPageSize(size: number) {
        this.search.pageNum = this.paginationModel.pageNumber;
        this.search.pageSize = size;
        this.getLifeStyleList();
    }

    preventSpaceEvent(event) {
        event.preventDefault();
    }


    getRowDetails(content, s: any, e: any) {
        this.modelRefSM = this.modalService.open(content, { centered: true, keyboard: false, backdrop: false,
             windowClass: "large--content" });
        this.rowDetails = s.selectedItems[0];
    }

    async deleteLifeStyle() {
        const resp = await this._editPatientService.deletePatientLifeStyle(this.rowDetails["LifestyleId"], this.patientId).toPromise();
        if (resp) {
            this._newAudit.SaveAuditOnDelete(
                [{name: "Deleted Life Style", value: this.rowDetails["Code"]}],
                null,
                "LifeStyle",
                "Patient",
                this.patientId
            );
            this._alertService.success(MsgConfig.DELETE_SUCCESS);
            this.getPatientLifeStyle();
        } else {
            this._alertService.error(MsgConfig.DELETE_FAIL);
        }
    }

    openPopOnWijimo(content, event) {
        if (event.ctrlKey) {
            if (event.which === 65) {
                event.preventDefault();
                this.openPopUpModal(content);
            }
        }
    }

    closeAddPop(event) {
        if (event.ctrlKey) {
            if (event.which === 67) {
                event.preventDefault();
                if(this.modelRef) {
                    this.modelRef.close();
                      this.modelRef = null;
                }
            } else if (event.which === 65) {
                event.preventDefault();
                this.addMultiple();
            }
        } else if (event.which === 9) {
            this._commonUtil.focusById("lifeStylePage", event, "Page");
        }
    }

    init(flex: wjcGrid.FlexGrid) {
        this.wijimoId = flex;
    }

    saveAuditOnAdd(item) {
        let addedCode = "";
        item.map(values => {
            addedCode = addedCode + (addedCode !== "" ? ", " : "") + values["Code"];
        });
        this._newAudit.SaveAuditOnDelete(
            null,
            [{name: "Added Life style", value: addedCode}],
            "LifeStyle",
            "Patient",
            this.patientId
        );
    }

    focusOutFromAdd(event) {
        if (!this.patLifeStyleCount) {
            this.focusToFirst(event);
        }
    }

    focusToFirst(event) {
        if (document.getElementById("SaveButton")) {
            event.preventDefault();
            setTimeout(() => {
                document.getElementById("SaveButton").focus();
            }, 10);

        }
    }


}
