import { Component, OnInit, Input, ViewChild, ChangeDetectorRef, ElementRef } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { DrugLot, DropDown, MiscInfo } from "src/app/models";
import { CommonUtil, WijimoUtil } from "src/app/utils";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DrugTabUtils } from "src/app/utils/drug-tabs.utils";
import { CollectionView } from "@grapecity/wijmo";
import * as moment from "moment";
import { AlertService, EditDrugService } from "src/app/services"; 
import { DrugBucketUtil } from "src/app/utils/drug-bucket.util";
import { CommonStore } from "src/app/store";
import { DateCheckUtil } from "src/app/utils/date-checks.util";
import { AuditLogUtils } from "src/app/utils/audit-log.util";
import * as _ from 'lodash';
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
    selector: "app-drug-misc-info",
    templateUrl: "./drug-misc-info.component.html"
})
export class DrugMiscInfoComponent implements OnInit {
    @Input() drugId: number;
    @Input() miscFG: FormGroup;
    @Input() isFromCompound: boolean;
    @Input() miscInfo: MiscInfo;

    @ViewChild("COMPDRUGPATCH")
    COMPDRUGPATCH: any;

    @ViewChild("vendoritem")
    vendoritem: any;

    @ViewChild("warning", { static: true })
    warning: ElementRef;

    drugLotFG: any;
    activeModal: any;
    model: any;
    model1: any;
    drugLotData = [];
    drugLotWJ: CollectionView;
    lotfgInvalif: boolean;
    pkMgFG: FormGroup;
    drugPkMgData: any;
    wjHeaders: any;
    minDate: any;
    selectedRowId: any;
    drugBucketList: any[];
    BucketFG: FormGroup;
    oldDrugLotData: any;
    unsubscribe$ : Subject<void> = new Subject();
    constructor(private _fb: FormBuilder, private _modalSvc: NgbModal, private _comnUtil: CommonUtil,
        private _drugtabUtil: DrugTabUtils, private _alrtSvc: AlertService,   private _commonStore: CommonStore,
        private _drugBuckUtil: DrugBucketUtil, private _editDrugSrv: EditDrugService,
        private _wijimoUtils: WijimoUtil, private _cdr: ChangeDetectorRef,
        private _dateUtil: DateCheckUtil, private _newAuditUtils: AuditLogUtils) { }

    ngOnInit() {
        if (this.miscInfo && !this.miscInfo["DrugMisc"]) {
            this.miscFG.controls["DrugMisc"].patchValue({
                DrugId: this.drugId,
                IsMedGuide: true,
                IsUnitDrug: false
            });
        }
        this.BucketFG = this._fb.group({
            BucketId: []
        });
        this.getDrugBucketListNPatch();
        // this.getDrugLotData();
        const today = new Date();
        this.minDate = {
            year: today.getFullYear(),
            month: today.getMonth() + 1,
            day: today.getDate()
        };
        this._cdr.detectChanges();
        this.patchDefaultValHeaders();
        this._wijimoUtils.wjSavedData$.pipe(takeUntil(this.unsubscribe$)).subscribe(w => {
            if (w && w["WijmoKey"] && w["WijmoKey"] === "drugMiscInfoListWJ") {
                this.patchDefaultValHeaders();
            }
        });
    }

    async getDrugBucketListNPatch() {
        if(this.drugId){
            const resp = await this._drugBuckUtil.getDrugBucketList(this.drugId);
            if (resp) {
                this.drugBucketList = resp;
                this.BucketFG.patchValue({
                    BucketId: this._drugBuckUtil.getDefaultBucketId()
                });
                this.getDrugBucketMiscInfo();
            }
        }
    }

    async getDrugBucketMiscInfo(forDrugPkg = false) {
        this.drugLotData = [];
        this.drugPkMgData = [];
        if (this.drugId) {
            const resp = await this._editDrugSrv.getMiscInfo(this.drugId, this.BucketFG.value.BucketId || null).toPromise();
            if (resp) {
                if (!this.miscFG) {
                    this.miscFG = this._drugtabUtil.createMiscFG();
                }
                this._drugtabUtil.patchMiscInfo(resp, this.miscFG);
                this.getDrugLotData(forDrugPkg);
            }
        }
    }

    createDrugLotFG() {
        this.drugLotFG = this._fb.group(new DrugLot());
        this.drugLotFG.patchValue({
            BucketId: this.BucketFG.value.BucketId
        });
    }

    getDrugLotData(forDrugPkg = false) {
        this.drugLotData = this.miscFG.value["DrugLot"] ? this.miscFG.value["DrugLot"] : [];
        this.drugPkMgData = this.miscFG.value["PkgMc"] ? this.miscFG.value["PkgMc"] : [];
        if (!forDrugPkg) {
            this.drugLotWJ = this._drugtabUtil.generateDrugLotWj(this.drugLotData);
        }
    }

    async updateBucketDrugLot(forDrugPkg = false, auditData?: any) {
        const resp = await this._editDrugSrv.updateMisc(this.miscFG.value).toPromise();
        if (resp) {
            // audit log changes need to be implemented here.
            let oldData = null, newData = null;
            if (!auditData) {
                if (!this.oldDrugLotData && this.drugLotFG && this.drugLotFG.value && this.drugLotFG.value["Action"] === "A") {
                    oldData = null;
                    const formData = this.miscFG.value["DrugLot"][this.miscFG.value["DrugLot"].length - 1];
                    newData = {"Lot Number": formData.LotNum, "Lot Expiration Date": formData.ExpDt};
                } else if (this.oldDrugLotData && this.drugLotFG && this.drugLotFG.value && this.drugLotFG.value["Action"] === "U") {
                    const selectedData = this.drugLotData[this.selectedRowId];
                    const oldExpDate =  (moment.utc(this.oldDrugLotData.ExpDt).local().format("MM/DD/YYYY") === this.drugLotFG.controls["ExpDt"].value) ? null :
                     moment(this.oldDrugLotData.ExpDt).format("MM/DD/YYYY");
                    // const newExpDate = moment.utc(selectedData.ExpDt).local().format("MM/DD/YYYY");
                    const newExpDate = (moment.utc(this.oldDrugLotData.ExpDt).local().format("MM/DD/YYYY") === this.drugLotFG.controls["ExpDt"].value) ? null :
                    selectedData.ExpDt; //31
                    oldData = {"Lot Number": this.oldDrugLotData.LotNum, "Lot Expiration Date": oldExpDate};
                    newData = {"Lot Number": selectedData.LotNum, "Lot Expiration Date": newExpDate};
                }
            } else {
                oldData = auditData.old;
                newData = auditData.new;
            }
            if (!(_.isEqual(oldData, newData))) {
                this._newAuditUtils.getChangedValues(
                    oldData,
                    newData,
                    "Drug Lot",
                    "Drug",
                    this.drugId,
                );
            }
            this.getDrugBucketMiscInfo(forDrugPkg);
            this._commonStore.storeDrugLotData(true);
        }
    }

    async patchDefaultValHeaders() {
        const storedWJ = await this._wijimoUtils.getWJSavedData("drugMiscInfoListWJ");
        this.wjHeaders = this._wijimoUtils.patchDefHeader("drugMiscInfoListWJ", storedWJ);
    }
    updateDrugLot(auditData?: any) {
        this._comnUtil.generateFA("DrugLot", this.drugLotData, this.miscFG);
        this.miscFG.markAsDirty();
        this.updateBucketDrugLot(false, auditData);
        // this.getDrugLotData();
    }

    checkLotisExpired(date) {
        return new Date(date) >= new Date(moment().format("MM/DD/YYYY"));
    }

    openLotAddOrEditModal(content, type, rowId?: any) {
        this.createDrugLotFG();
        this.activeModal = type;
        if (type === "LAdd") {
            this.drugLotFG.patchValue({
                IsActive: true,
                Action: "A",
                DrugId: this.drugId
            });
            this.oldDrugLotData = null;
        } else {
            this.selectedRowId = rowId;
            const selectedData = this.drugLotData[rowId];
            this.oldDrugLotData = selectedData;
            this.drugLotFG.patchValue(selectedData);
            if (selectedData["Id"]) {
                this.drugLotFG.controls["Action"].patchValue("U");
                this.drugLotFG.controls["ExpDt"].patchValue(moment.utc(selectedData.ExpDt).local().format("MM/DD/YYYY"));
            }
        }
        // this.model = this._modalSvc.open(content, { centered: true, backdrop: false });
        this.model  = this._modalSvc.open(this.vendoritem, { centered: true, backdrop: false});
    }

    changeLotStatus(checkedValue, rowId) {
        const selectedData = this.drugLotData[rowId];
        if (selectedData["Id"]) {
            this.drugLotData[rowId]["Action"] = checkedValue ? "U" : "D";
        }
        this.drugLotData[rowId]["IsActive"] = checkedValue;
        let old = {};
        old["Lot No." + selectedData.LotNum + " Status"] = !checkedValue;
        let newD = {};
        newD["Lot No." + selectedData.LotNum + " Status"] = checkedValue;
        const auditData = {"old": old, "new": newD};
        this.updateDrugLot(auditData);
    }

   async addorUpdateData() {
    const drugExpDtFG = this._fb.group({ExpDt: this.drugLotFG.controls["ExpDt"].value});
    const dateChecks = [{
        control: "ExpDt",
        minDate: this.minDate,
        maxDate: "",
        isRequired: true
    }];
     const dateCheckFields: any = this._dateUtil.checkDateValidationAtTimeofSave(drugExpDtFG, dateChecks);
    if (dateCheckFields) {
        this.lotfgInvalif = true;
        return;
    } else if (this.drugLotFG.valid) {
            this.lotfgInvalif = false;
            const resp = await this._editDrugSrv.checkLotExists(this.drugLotFG.value).toPromise();
            if (!resp) {
                if (this.activeModal === "LAdd") {
                    this.drugLotData.push(this.drugLotFG.value);
                } else {
                    this.drugLotData[this.selectedRowId] = this.drugLotFG.value;
                }
                if (this.model) {
                this.model.close();
                this.model = null;
                }
                this.updateDrugLot();
            } else {
                 this.model1 = this._modalSvc.open(this.warning, { centered: true, backdrop: false, windowClass: "large--content"});
            }
        } else {
            this.lotfgInvalif = true;
        }
    }
    closeModal() {
        if(this.model) {
            this.model.close();
            this.model = null;
        }
        if(this.model1) {
            this.model1.close();
            this.model1 = null;
        }
        this.lotfgInvalif = false;
    }

    afterLotWarnClose() {
        if (document.getElementById("LotNumPop")) {
            document.getElementById("LotNumPop").focus();
        }
        if(this.model1) {
            this.model1.close();
            this.model1 = null;
        }
    }

    createPkMgFG() {
        this.pkMgFG = this._fb.group(new DropDown());
    }

    addPkMg(data) {
        const pkMgData = new DropDown();
        if (this.drugPkMgData && this.drugPkMgData.find(val => val.Name === data.target.value)) {
            data.target.value = null;
        } else {
            pkMgData["Action"] = "A";
            pkMgData["IsActive"] = true;
            pkMgData["Name"] = data.target.value;
            data.target.value = null;
            this.drugPkMgData.push(pkMgData);
            this.updateDrugPkMg();
        }
    }

    deltePkMg(rowId) {
        const selectedData = this.drugPkMgData[rowId];
        if (selectedData["Id"] && selectedData[rowId]) {
            selectedData[rowId]["Action"] = "U";
        } else {
            this.drugPkMgData.splice(rowId, 1);
        }
        this.updateDrugPkMg();
    }

    async updateDrugPkMg() {
        this._comnUtil.generateFA("PkgMc", this.drugPkMgData, this.miscFG);
         this.miscFG.markAsDirty();
        this.drugPkMgData = this.miscFG.value["PkgMc"] ? this.miscFG.value["PkgMc"] : [];
        this.updateBucketDrugLot(true);
    }

    focusToSave(event) {
        this._comnUtil.focusById("saveButton", event, null);
    }
    ngOnDestroy(){
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
