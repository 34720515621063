<div class="row exprx--common-block">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 padding-sm-15 flex--one">
        <div class="box ">
            <div class="eprx--block__header">
                <div class="eprx--header__heading">
                    Insurance Billing Information
                </div>
            </div>
            <div class="box-body">
                <div class="row">
                    <div class="col-md-6">
                        <div class="col-md-12 col-sm-12 col-xs-12  padding--left__7">
                            <div class="col-sm-12 padding-0">
                                <eprx-input [LabelText]="'Segment Seperator'" [Title]="'Segment seperator'"
                                    [PlaceHolder]="'Segment seperator'" [ControlName]="'SegSep'" [AutoFocus]="true"
                                    [FormGroupName]="InsBillingFG" [MaxLength]="2">
                                </eprx-input>
                            </div>
                        </div>
                        <div class="col-md-12 col-sm-12 col-xs-12 padding--left__7">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 padding-0">
                                <eprx-input [LabelText]="'Element Seperator'" [PlaceHolder]="'Element seperator'"
                                    [Title]="'Element seperator'" [ControlName]="'ElementSep'"
                                    [FormGroupName]="InsBillingFG" [MaxLength]="2">
                                </eprx-input>
                            </div>
                        </div>
                        <div class="col-md-12 col-sm-12 col-xs-12 padding--left__7">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 padding-0">
                                <eprx-input [LabelText]="'Field Seperator'" [PlaceHolder]="'Field seperator'"
                                    [Title]="'Field seperator'" [ControlName]="'FieldSep'"
                                    [FormGroupName]="InsBillingFG" [MaxLength]="2">
                                </eprx-input>
                            </div>
                        </div>
                        <div class="col-md-12 col-sm-12 col-xs-12 padding--left__7">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 padding-0">
                                <eprx-input [LabelText]="'Version Identifier'" [PlaceHolder]="'Version Identifier'"
                                    [Title]="'Version Identifier'" [ControlName]="'VersionCode'"
                                    [FormGroupName]="InsBillingFG" [MaxLength]="30">
                                </eprx-input>
                            </div>
                        </div>
                        <div class="col-md-12 col-sm-12 col-xs-12 padding--left__7">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 padding-0">
                                <eprx-input [LabelText]="'Sender Id'" [PlaceHolder]="'Sender Id'" [Title]="'Sender Id'"
                                    [ControlName]="'SendorCode'" [FormGroupName]="InsBillingFG" [MaxLength]="30">
                                </eprx-input>
                            </div>
                        </div>
                        <div class="col-md-12 col-sm-12 col-xs-12 padding--left__7">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 padding-0">
                                <eprx-input [LabelText]="'Sender Qualifier'" [PlaceHolder]="'Sender Qualifier'"
                                    [Title]="'Sender Qualifier'" [ControlName]="'SendorQualif'"
                                    [FormGroupName]="InsBillingFG" [MaxLength]="2">
                                </eprx-input>
                            </div>
                        </div>
                        <div class="col-md-12 col-sm-12 col-xs-12 padding--left__7">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 padding-0">
                                <eprx-input [LabelText]="'Auth Info Qualifier'" [PlaceHolder]="'Auth Info Qualifier'"
                                    [Title]="'Auth Info Qualifier'" [ControlName]="'AuthInfoQualif'"
                                    [FormGroupName]="InsBillingFG" [MaxLength]="2">
                                </eprx-input>
                            </div>
                        </div>
                        <div class="col-md-12 col-sm-12 col-xs-12 padding--left__7">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 padding-0">
                                <eprx-input [LabelText]="'Receiver ID'" [PlaceHolder]="'Receiver ID'"
                                    [Title]="'Receiver ID'" [ControlName]="'ReceiverCode'"
                                    [FormGroupName]="InsBillingFG" [MaxLength]="30">
                                </eprx-input>
                            </div>
                        </div>
                        <div class="col-md-12 col-sm-12 col-xs-12 padding--left__7">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 padding-0">
                                <eprx-input [LabelText]="'Receiver Qualifier'" [PlaceHolder]="'Receiver Qualifier'"
                                    [Title]="'Receiver Qualifier'" [ControlName]="'ReceiverQualif'"
                                    [FormGroupName]="InsBillingFG" [MaxLength]="2">
                                </eprx-input>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="col-md-12 col-sm-12 col-xs-12 padding--left__7">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 padding-0">
                                <eprx-input [LabelText]="'Auth Information'" [PlaceHolder]="'Auth Information'"
                                    [Title]="'Auth Information'" [ControlName]="'AuthInfo'"
                                    [FormGroupName]="InsBillingFG" [MaxLength]="2">
                                </eprx-input>
                            </div>
                        </div>
                        <div class="col-md-12 col-sm-12 col-xs-12 padding--left__7">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 padding-0">
                                <eprx-input [LabelText]="'Security Information Qual'"
                                    [PlaceHolder]="'Security Information Qual'" [Title]="'Security Information Qual'"
                                    [ControlName]="'SecurityInfoQualif'" [FormGroupName]="InsBillingFG" [MaxLength]="2">
                                </eprx-input>
                            </div>
                        </div>
                        <div class="col-md-12 col-sm-12 col-xs-12 padding--left__7">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 padding-0">
                                <eprx-input [LabelText]="'Interch Cont Identifier'"
                                    [PlaceHolder]="'Interch Cont Identifier'" [Title]="'Interch Cont Identifier'"
                                    [ControlName]="'InterContCode'" [FormGroupName]="InsBillingFG" [MaxLength]="30">
                                </eprx-input>
                            </div>
                        </div>
                        <div class="col-md-12 col-sm-12 col-xs-12 padding--left__7">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 padding-0">
                                <eprx-input [LabelText]="'Security Information'" [PlaceHolder]="'Security Information'"
                                    [Title]="'Security Information'" [ControlName]="'SecurityInfo'"
                                    [FormGroupName]="InsBillingFG" [MaxLength]="30">
                                </eprx-input>
                            </div>
                        </div>
                        <div class="col-md-12 col-sm-12 col-xs-12 padding--left__7">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 padding-0">
                                <eprx-input [LabelText]="'Interch Cont Ver No'" [PlaceHolder]="'Interch Cont Ver No'"
                                    [Title]="'Interch Cont Ver No'" [ControlName]="'InterContVersion'"
                                    [FormGroupName]="InsBillingFG" [MaxLength]="30">
                                </eprx-input>
                            </div>
                        </div>
                        <div class="col-md-12 col-sm-12 col-xs-12 padding--left__7">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 padding-0">
                                <eprx-input [LabelText]="'Rece Ident Code'" [PlaceHolder]="'Rece Ident Code'"
                                    [Title]="'Rece Ident Code'" [ControlName]="'RecCode'" [FormGroupName]="InsBillingFG"
                                    [MaxLength]="30">
                                </eprx-input>
                            </div>
                        </div>
                        <div class="col-md-12 col-sm-12 col-xs-12 padding--left__7">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 padding-0">
                                <eprx-input [LabelText]="'Entity Name'" [PlaceHolder]="'Entity Name'"
                                    [Title]="'Entity Name'" [ControlName]="'EntName'" [FormGroupName]="InsBillingFG"
                                    [MaxLength]="30">
                                </eprx-input>
                            </div>
                        </div>
                        <div class="col-md-12 col-sm-12 col-xs-12 padding--left__7">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 padding-0">
                                <eprx-input [LabelText]="'Version Identifier 2'" [PlaceHolder]="'Version Identifier 2'"
                                    [Title]="'Version Identifier 2'" [ControlName]="'VersionCode2'"
                                    [FormGroupName]="InsBillingFG" [MaxLength]="30" (keydown.tab)="focusToSave($event)">
                                </eprx-input>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
