<div class="row exprx--common-block">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 padding-sm-15 flex--one padding-0">
        <div class="box-default">
            <div class="eprx--block__header">
                <div class="eprx--header__heading">
                    Inventory Information
                </div>
            </div>
            <div class="eprx--block__content">
                <div class="row">
                    <div class="col-md-12 row padding-sm-15">
                        <div class="col-4">
                            <app-rx-select [IsRequired]="true" [ErrorDefs]="{required: 'Required'}" [AutoFocus]="true"
                                [RxSelectId]="'bucketListFrmED'" [LabelText]="'Inventory Bucket to View Details'"
                                [PlaceHolder]="'Bucket Name'" [ControlName]="'Id'" [FormGroupName]="bucketINVFG"
                                [BindLabel]="'Name'" [BindValue]="'Id'" [nextElement]="'phyinvrec'" [LabelForId]="'Id'" [List]="bucketList"
                                (TriggerSelectValue)="checkMangInvForBucket()">
                            </app-rx-select>
                        </div>
                        <div class="col-8 text-right pull-right padding_top_1rem">
                            <button class="hotkey_success" (click)="openPhyInvRec()" appShortcutKey [AltKey]="'p'"
                                (keydown.enter)="openPhyInvRec()" id="phyinvrec"> <span>P</span> Phys. Inventory
                                Records</button>
                            <button class="hotkey_success" (click)="openVendorData(true)" appShortcutKey
                                [AltKey]="'v'"><span>V</span>Vendor
                                Item Codes</button>
                            <button class="hotkey_success" (click)="getTransAudit()" appShortcutKey
                                [AltKey]="'T'"><span>T</span>Inventory Trans.Audit</button>
                            <button class="hotkey_success"
                                *ngIf="bucketINVFG?.valid && bucketINVFG?.value?.IsInvManaged && checkHasEnterInvPriv()"
                                (click)="openEnterInventory()" appShortcutKey [AltKey]="'I'"><span>I</span>Add
                                Inventory</button>
                            <button class="inactive"
                                *ngIf="(!bucketINVFG?.valid || !bucketINVFG?.value?.IsInvManaged || !checkHasEnterInvPriv())"
                                [title]="checkHasEnterInvPriv() ? '' : 'You must have Inventory received privilege to perform this action'">Add
                                Inventory</button>
                        </div>
                    </div>
                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 max-width_15px " >
                        <div class="col-md-12 inventory inventory--color1 cursor_pointer" (click)="BucketDisplayPopUp()">
                            <div class="inv--information">
                                <div class="inv--image">
                                    <img src="assets/dist/Icons/icon_pills1_white.svg"
                                        title="Quantity In Hand (this NDC)" />
                                </div>
                                <div class="inv--value">
                                    <h3>{{ (bucketINVFG?.value?.QtyInHand ? bucketINVFG?.value?.QtyInHand : 0) | number:
                                        "1.3-3" }}</h3>
                                    <div class="inv--description">
                                        <h5>Quantity In Hand (this NDC)</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <button class="button" style="border:none"  onclick = "DrugInventoryPopUp()"> -->
                        <div class="col-md-12 inventory inventory--color2 cursor_pointer" (click)="DrugInventoryPopUp()">
                            <div class="inv--information">
                                <div class="inv--image">
                                    <img src="assets/dist/Icons/icon_pills_white.svg"
                                        title="Quantity In Hand (Equivalent drug)" />
                                </div>
                                <div class="inv--value">
                                    <h3>{{ (bucketINVFG?.value?.QtyInHandEqui ? bucketINVFG?.value.QtyInHandEqui : 0) |
                                        number: "1.3-3" }}
                                    </h3>
                                    <div class="inv--description">
                                        <h5>
                                            Quantity In Hand (Equivalent drug)
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    <!-- </button> -->
                        <div class="col-md-12 inventory inventory--color3">
                            <div class="inv--information">
                                <div class="inv--image">
                                    <img src="assets/dist/Icons/icon_History.svg" title="Last Received" />
                                </div>
                                <div class="inv--value">
                                    <h3>{{ (drugInfo?.InventoryHistory?.LastReceived !== "0001-01-01T00:00:00") ?
                                        (drugInfo?.InventoryHistory?.LastReceived | date : 'MM/dd/yyyy') : "--"}}
                                    </h3>
                                </div>
                                <div class="inv--description">
                                    <h5>Last Received</h5>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 inventory inventory--color4">
                            <div class="inv--information">
                                <div class="inv--image">
                                    <img src="assets/dist/Icons/icon_Pickup.svg" title="Last Dispensed" />
                                </div>
                                <div class="inv--value">
                                    <h3>{{ (drugInfo?.InventoryHistory?.LastDispensed !== "0001-01-01T00:00:00") ?
                                        (drugInfo?.InventoryHistory?.LastDispensed | date : 'MM/dd/yyyy') : "--"}}
                                    </h3>
                                </div>
                                <div class="inv--description">
                                    <h5>Last Dispensed</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 max-width_18px">
                        <div class="row">
                            <div class="col-md-12 padding-sm-15">
                                <div class="box-default">
                                    <div class="eprx--block__header">
                                        <div class="eprx--header__heading">
                                            Order Information
                                        </div>
                                    </div>
                                    <div class="eprx--block__content">
                                        <div class="row">
                                            <div class="col-lg-12  col-md-12 col-sm-12 col-xs-12">
                                                <eprx-radio [LabelText]="'Manage Inventory'" [IsRequired]="true"
                                                    [ErrorDefs]="{required: 'Required'}"
                                                    [IsDisabled]="!bucketINVFG.value.Id" [ControlName]="'IsInvManaged'"
                                                    [FormGroupName]="bucketINVFG" [List]="['Yes','No']"
                                                    [ValueList]="[true,false]" [IDForList]="['Yes','No']"
                                                    (TriggerSelectValue)="checkIsBuckPhyInv()" [LabelForId]="">
                                                </eprx-radio>
                                            </div>
                                            <div class="col-lg-12  col-md-12 col-sm-12 col-xs-12 mt-2">
                                                <eprx-radio [LabelText]="' Auto. Order '" [ControlName]="'IsAutoPO'"
                                                    [FormGroupName]="drugFG?.get('DrugPO')" [IsRequired]="true"
                                                    [List]="['Yes', 'No']" [ValueList]="[true, false]"
                                                    [IDForList]="['IsAutoPOyes', 'IsAutoPOno']" [LabelForId]="">
                                                </eprx-radio>
                                            </div>
                                            <div class="col-lg-12  col-md-12 col-sm-12 col-xs-12">
                                                <eprx-input [LabelText]="'Reorder level'" [PlaceHolder]="''"
                                                    [ControlName]="'ReorderQty'" [FormGroupName]="drugFG?.get('DrugPO')"
                                                    [InputType]="'NUMBER'" [MaxLength]="6" [DropSpclChar]="false">
                                                </eprx-input>
                                            </div>
                                            <div class="col-lg-12  col-md-12 col-sm-12 col-xs-12">
                                                <eprx-input [LabelText]="'Target Qty'" [PlaceHolder]="''"
                                                    [ControlName]="'TargetQty'" [FormGroupName]="drugFG?.get('DrugPO')"
                                                    [MaskPattern]="'099999.000'" [DropSpclChar]="false"
                                                    [DecimalValues]="3" [InputType]="'NUMBER'" (TriggerSearchValue)="
                                                        setNumberFormat('TargetQty')
                                                    ">
                                                </eprx-input>
                                            </div>
                                            <div class="col-lg-12  col-md-12 col-sm-12 col-xs-12">
                                                <eprx-select [LabelText]="'Preferred Vendor'"
                                                    [PlaceHolder]="'Preferred Vendor'" [ControlName]="'ContractId'"
                                                    [FormGroupName]="drugFG?.get('DrugPO')"
                                                    [List]="dropDownValues?.Vendor" [BindLabel]="'ContractName'" [LabelTitle2]="'Vendor Name'" [LabelTitle1]="'Account'"
                                                    [BindLabel2]="'VendorName'" [Show2Values]="true" [HasMultiple]="false" [HasBindLabel2]="true" [show2ValInInputhidden]="true"
                                                    [BindValue]="'ContractId'" [LabelForId]="'vendorpref'" (TriggerSelectValue)="drugPreferredVendor()">
                                                </eprx-select>
                                            </div>
                                            <div class="col-lg-12  col-md-12 col-sm-12 col-xs-12">
                                                <eprx-input [LabelText]="'Last Vendor'" [PlaceHolder]="'Last Vendor'"
                                                    [FormGroupName]="drugFG" [ControlName]="'LatestVendorName'"
                                                    [ReadOnly]="true">
                                                </eprx-input>
                                            </div>
                                            <div class="col-lg-12  col-md-12 col-sm-12 col-xs-12">
                                                <eprx-input [RxSelectId]="'MinOrderQty'"
                                                    [LabelText]="'Minimum Order Qty'"
                                                    [PlaceHolder]="'Minimum Order Qty'" [ControlName]="'MinOrderQty'"
                                                    [FormGroupName]="drugFG?.get('DrugPO')" [DecimalValues]="3"
                                                    [MaxLength]="6" [InputType]="'NUMBER'">
                                                </eprx-input>
                                            </div>
                                            <div class="col-lg-12  col-md-12 col-sm-12 col-xs-12">
                                                <eprx-input [RxSelectId]="'OrderQty'" [LabelText]="'Qty On Order'"
                                                    [PlaceHolder]="'Qty On Order'" [MaxLength]="6"
                                                    [ControlName]="'OrderQty'" [FormGroupName]="drugFG?.get('DrugPO')"
                                                    [InputType]="'NUMBER'">
                                                </eprx-input>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8">


                        <div class="box-default">
                            <div class="eprx--block__header">
                                <div class="eprx--header__heading">
                                    Inventory Received History
                                </div>
                            </div>
                            <div class="eprx--block__content height_25rem">
                                <div class="col-md-12 pl-0">
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 pl-0 margin_bottom_0_5rem"
                                         [formGroup]="bucketINVFG">
                                        <input class="form-check-input" type="checkbox" [id]="'showHisByBucktId3'"
                                            formControlName="showHisByBucktId" (change)="filterGrid()" />
                                        <label class="form-check-label" [for]="'showHisByBucktId3'"
                                            id="'showHisByBucktId'">
                                            Show History By Inv Bucket
                                        </label>
                                    </div>
                                </div>
                                <div class="text-center">
                                <wj-flex-grid #flex [headersVisibility]="'Column'" [itemsSource]="invenHistWJ" [isReadOnly]="true"
                                [ngClass]="!hasTransHis ? 'max-height_22rem no-data': 'max-height_22rem'"
                                    [columnPicker]="'drugInvHistoryListWJ'" [selectionMode]="'None'"
                                    (initialized)="init(flex)">
                                    <wj-flex-grid-column [header]="rxheader['hName']" [binding]="rxheader['hName']" [visible]="rxheader['isVisible']"
                                        *ngFor="let rxheader of wjHeaders; let i = index" [format]="rxheader['hName']=== 'Price' ? 'c2' : ''"
                                        [width]="rxheader['width']">
                                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                            <div *ngIf="rxheader['hName'] === 'Drug Lot'">
                                                <a [ngbTooltip]="lotToolTipCont" placement="left" container="body" tooltipClass="notes-tooltip" [openDelay]="500">
                                                    {{item[rxheader['hName']]}}
                                                    <ng-template #lotToolTipCont>
                                                        <div>
                                                            <div class="row text-left">
                                                                <div class="col-md-12">
                                                                    <label class="text-decoration-underline margin_bottom_1rem">Lot Info</label>
                                                                </div>
                                                                <div class="col-12">
                                                                    <div class="row" *ngFor="let lot of item['LotNumData']; let k = index">
                                                                        <div class="col-md-6">
                                                                            <label>Lot# :</label>
                                                                            <span> {{lot}}</span>
                                                                        </div>
                                                                        <div class="col-md-6">
                                                                            <label>Exp Dt :</label>
                                                                            <span>{{item['ExpDateList'][k] | date : 'MM/dd/yyyy'}} </span>
                                                                        </div>
                                                                        <div class="col-12">
                                                                            <hr>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                </ng-template>
                                                </a>
                                            </div>
                                            <div *ngIf="rxheader['hName'] !== 'Drug Lot' && (rxheader['hName'] !== 'Price') && (rxheader['hName'] !== 'Drug NDC')">
                                                {{item[rxheader['hName']]}}
                                            </div>
                                            <div *ngIf="rxheader['hName'] === 'Price'">
                                                {{ item[rxheader['hName']] | currency: "$":"symbol":"1.3-3" }}
                                            </div>
                                            <div *ngIf="rxheader['hName'] === 'Drug NDC'">
                                                {{ item[rxheader['hName']] | mask : "AAAA0-0000-00"}}
                                            </div>
                                        </ng-template>
                                    </wj-flex-grid-column>
                                    <app-no-data-found *ngIf="!isDataExists"></app-no-data-found>
                                </wj-flex-grid>
                                </div>
                            </div>
                        </div>
                        <div class="box-default">
                            <div class="eprx--block__content">
                                <div class="row">
                                    <div class="col-lg-6  col-md-6 col-sm-6 col-xs-6">
                                        <eprx-input [LabelText]="'Last Phys. Inv Count'"
                                            [PlaceHolder]="'Last Phys. Inv Count'" [FormGroupName]="invInfoFG"
                                            [ControlName]="'LastPhysicalInvCount'" [ReadOnly]="true">
                                        </eprx-input>
                                    </div>
                                    <div class="col-lg-6  col-md-6 col-sm-6 col-xs-6">
                                        <eprx-input [LabelText]="'Last Phys. Inv. Date'"
                                            [PlaceHolder]="'Last Phys. Inv. Date'" [FormGroupName]="invInfoFG"
                                            [ControlName]="'LastPhysicalInventoryDate'" [ReadOnly]="true">
                                        </eprx-input>
                                    </div>
                                    <div class="col-lg-6  col-md-6 col-sm-6 col-xs-6">
                                        <eprx-input [LabelText]="'Tot. Recvd. since Last Phy Inv.'"
                                            [PlaceHolder]="'Tot. Recvd. since Last Phy Inv.'"
                                            [FormGroupName]="invInfoFG" [ControlName]="'TotalRecieved'"
                                            [ReadOnly]="true">
                                        </eprx-input>
                                    </div>
                                    <div class="col-lg-6  col-md-6 col-sm-6 col-xs-6">
                                        <eprx-input [LabelText]="InvCal && +InvCal ?  'Tot. Rx Filled Since Last phys. Inv.' : 'Total Dispensed Since Last Physical Inv.'"
                                            [PlaceHolder]="InvCal && +InvCal ? 'Tot. Rx Filled Since Last phys. Inv.' : 'Total Dispensed Since Last Physical Inv.'"
                                            [FormGroupName]="invInfoFG" [ControlName]="'TotalDispensed'"
                                            [ReadOnly]="true">
                                        </eprx-input>
                                    </div>
                                    <div class="col-lg-6  col-md-6 col-sm-6 col-xs-6" *ngIf="InvCal && +InvCal">
                                        <eprx-input [LabelText]="'Tot. Drug Pick Vrf Qty Since Last Phy Inv.'"
                                            [PlaceHolder]="'Tot. Drug Pick Vrf Qty Since Last Phy Inv.'"
                                            [FormGroupName]="invInfoFG" [ControlName]="'TotalDrugPickVerifiedQty'"
                                            [ReadOnly]="true">
                                        </eprx-input>
                                    </div>
                                    <div class="col-lg-6  col-md-6 col-sm-6 col-xs-6">
                                        <eprx-input [LabelText]="'Tot. Adjustments Since Last Phy Inv'"
                                            [PlaceHolder]="'Tot. Adjustments Since Last Phy Inv'"
                                            [FormGroupName]="invInfoFG" [ControlName]="'PastAdjustmentsSinceLastInv'"
                                            [ReadOnly]="true">
                                        </eprx-input>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<ng-template #PHYINVRECORDS let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Physical Inventory Records</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="c('Close click');">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body edit--drug">
        <h4>
            <p>Inventory Status of <b>{{drugInfo?.Drug?.Name | uppercase}} {{drugInfo?.Drug?.Strength | uppercase}}
                    {{drugInfo?.Drug?.DrugFormName | uppercase}}</b> drug for available Buckets</p>
        </h4>
        <wj-flex-grid [headersVisibility]="'Column'" [itemsSource]="phyInvWJ" [isReadOnly]="true"
             class="max-height_30rem max-width_35rem" [columnPicker]="'physicalInvStatusRecWJ'">
            <wj-flex-grid-column [header]="rxheader['hName']" [binding]="rxheader['hName']" [visible]="rxheader['isVisible']"
            *ngFor="let rxheader of wjHeadersInvStatus; let i = index" [width]="rxheader['width']">
                                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                        <div>
                                            <span *ngIf="(rxheader['hName'] === 'View Records')">
                                                <div  class="text-center" *ngIf="item['View'] === true">
                                                    <i class="far fa-eye fa-sm actions" title="View Physical Inventory Records"
                                                        (click)="viewRecord(item)"></i>
                                                </div>
                                            </span>
                                            <span *ngIf="!(rxheader['hName'] ==='View Records')">
                                                {{ item[rxheader['hName']] }}
                                            </span>
                                        </div>
                                    </ng-template>
            </wj-flex-grid-column>
            <!-- <wj-flex-grid-column [width]="131" [header]="'View Records'">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                    <div  class="text-center" *ngIf="item['View'] === true">
                        <i class="far fa-eye fa-sm actions" title="View Physical Inventory Records"
                            (click)="viewRecord(item)"></i>
                    </div>
                </ng-template>
            </wj-flex-grid-column> -->
        </wj-flex-grid>
        <!-- <div class="col-md-12 mt-3" *ngIf="viewDocs">
            <h3>Physical Inventory Records.</h3>
        </div> -->
        <h4 *ngIf="viewDocs">
            <p>Physical Inventory Records</p>
        </h4>
        <wj-flex-grid [headersVisibility]="'Column'" [itemsSource]="PhyRecWJ" [isReadOnly]="true" [columnPicker]="'physicalInvRecWJ'"
            [selectionMode]="'None'" *ngIf="viewDocs">
            <wj-flex-grid-column [header]="rxheader['hName']" [binding]="rxheader['hName']" [visible]="rxheader['isVisible']"
            *ngFor="let rxheader of wjHeadersInvRecords; let i = index" [width]="rxheader['width']">
                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                        <div>
                                            {{ item[rxheader['hName']] }}
                                        </div>
                                 </ng-template>
            </wj-flex-grid-column>
        </wj-flex-grid>
    </div>
    <div class="modal-footer">
        <button autofocus class="hotkey_primary" (click)="c('Close click');" appShortcutKey InputKey="o"><b>O</b> OK
        </button>
    </div>
</ng-template>

<ng-template #InventoryTrans let-c="close" let-d="dismiss">
    <div class="modal-header">Inventory Transaction Audit Report
    </div>
    <button type="button" [tabindex]="-1" class="close" aria-label="Close"
        (click)="c('Close click'); setFocusById($event, 'MinOrderQty')">
        <span aria-hidden="true" class="close-button">&times;</span>
    </button>
    <div class="modal-body" id="inventory">
        <h4 class="mb-3">
            Inventory Transaction Audit Report
            for <b>{{drugFG?.value?.Drug?.Name | uppercase}} {{drugFG?.value?.Drug?.Strength | uppercase}}
                {{drugFG?.value?.Drug?.DrugFormName | uppercase}}</b> NDC: <b>{{this._formatsUtil.getNDCFormat(drugFG?.value?.Drug?.DrugCode)}}</b></h4>
                <wj-flex-grid  #InventoryTranscationReport [headersVisibility]="'Column'" [isReadOnly]="true" [columnPicker]="'InventoryTransactionAudit'" [itemsSource]="InventoryTransactionAuditWJ"
                [selectionMode]="'Row'">
                <wj-flex-grid-column [header]="rxheader['hName']" [binding]="rxheader['hName']" [visible]="rxheader['isVisible']" *ngFor="let rxheader of wjHeadersForInventoryAudit;"
                    [width]="rxheader['width']">
                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                            <div>
                                {{ item[rxheader['hName']] }}
                            </div>
                     </ng-template>
                </wj-flex-grid-column>
                <wj-flex-grid-column [header]="'IsDeleted?'" [width]="100">
                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                        <span>{{item['IsActive'] ? 'NO' : 'YES'}}</span>
                    </ng-template>
                </wj-flex-grid-column>
            </wj-flex-grid>


    </div>
    <div class="row mx-2">
        <label class="margin_right_1rem">Total Records:</label>
        <span>{{invTransDispAudit?.length}}</span>
    </div>
    <div class="modal-footer">
        <button class="hotkey_success" (click)="print(true)" appShortcutKey [AltKey]="'p'"><span>P</span>Print
        </button>
        <button class="hotkey_success" (click)="c('Close click'); setFocusById($event, 'MinOrderQty')" appShortcutKey
            [AltKey]="'c'"><span>C</span>Close
        </button>
    </div>
</ng-template>

<ng-template #PRIVANDMANG let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Warning!!</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close"
            (click)="c('Close click');mangedOrNoPriv($event,false)">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>{{messageFor}}</p>
    </div>
    <div class="modal-footer" *ngIf="hasPrivs">
        <button footer autofocus class="hotkey_primary" (click)="c('Close click');mangedOrNoPriv($event,true)"
            appShortcutKey InputKey="y"><b>Y</b> YES
        </button>
        <button footer class="hotkey_primary" (click)="c('Close click');mangedOrNoPriv($event,false)" appShortcutKey
            InputKey="n"><b>N</b> NO
        </button>
    </div>
    <div class="modal-footer" *ngIf="!hasPrivs">
        <button footer autofocus class="hotkey_primary" (click)="c('Close click');mangedOrNoPriv($event,false)"
            appShortcutKey InputKey="o"><b>O</b> OK
        </button>
    </div>
</ng-template>

<ng-template #AddDrugToBucketConfirm let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="d('Cross click');openAddDrugToBuckOrNot(false)">
        <h4 header>Add Drug To Inventory Bucket</h4>
        <span body  *ngIf="compDrugList && !compDrugList.length">
            <b>'{{this.drugFG?.value?.Drug?.Name | uppercase}}'</b> is not available for selected bucket
            <b>'{{this.bucketINVFG?.value.Name | uppercase}}'.</b>
            <br />Would you like to add it to the bucket?

        </span>
        <span body *ngIf="compDrugList && compDrugList.length">
            <b>{{this.compDrugList.join(', ') | uppercase}}</b> is not available for selected bucket <b>'{{this.bucketINVFG?.value.Name | uppercase}}'</b>.
        <br/>Would you like to add it to the bucket?
        </span>
        <button footer autofocus class="hotkey_primary" (click)="d('Cross click');openAddDrugToBuckOrNot(true)"
            appShortcutKey InputKey="y">
            <b>Y</b> Yes
        </button>
        <button footer autofocus class="hotkey_primary" (click)="d('Cross click');openAddDrugToBuckOrNot(false)"
            appShortcutKey InputKey="n">
            <b>N</b> NO
        </button>
    </app-custom-modal>
</ng-template>

<ng-template #UpdateCost let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="d('Cross click');openAddDrugToBuckOrNot(false)">
        <h4 header>Drug Preferred Vendor</h4>
        <span body>
            You have selected <span class="font-weight-bold">'{{(vendorDetails ? vendorDetails?.VendorName : vendorName) | uppercase }}'</span> as your preferred vendor. Would you like to update
            the drug cost from this vendor?
            <br><br>
            Current Unit Cost:   <span class="font-weight-bold"> {{bucketINVFG?.value?.UnitPriceCost  | currency
                : "$"
                : "symbol"
                : "1.3-3"}}</span>
            <br>
           <span class="font-weight-bold">{{(vendorDetails ? vendorDetails?.VendorName : vendorName) | uppercase }} </span>
           <span [ngClass]="(vendorDetails && vendorDetails?.UnitCost)? 'font-weight-bold':''">Unit Cost:   {{ vendorDetails ? (vendorDetails?.UnitCost  |  currency
            : "$"
            : "symbol"
            : "1.3-3") : "NOT in system, would you like to Add?"}}</span>
        </span>
        <button *ngIf="!hasVendorDetails" footer autofocus (click)="d('Cross click');openVendorData(true,true)" class="hotkey_primary" appShortcutKey InputKey="y"><b>Y</b> Yes</button>
        <button *ngIf="hasVendorDetails" footer autofocus (click)="d('Cross click');patchToDrugUnitCost()" class="hotkey_primary" appShortcutKey InputKey="y"><b>Y</b> Yes</button>
        <button footer class="hotkey_primary" (click)="d('Cross click');openVendorData(false)"appShortcutKey InputKey="n"><b>N</b> NO</button>
    </app-custom-modal>
</ng-template>

<app-vendor-item-codes *ngIf="openVendor " [drugFG]="drugFG" [drugId]="drugId" [openForAdd]="openForAdd" [bucketINVFG]="bucketINVFG" [dropDownValues]="dropDownValues"
    (IspopUpClosed)="openVendorData($event)"></app-vendor-item-codes>

<app-physical-inventory *ngIf="isPhysicalInventEnabl && bucketINVFG?.valid" [drugInfo]="drugFG?.value"
    [drugDefVal]="dropDownValues" (CancelPhyscInven)="closeNclearMangInv($event)"
    [BucketInfo]="{BucketId: this.bucketINVFG.value.Id, DrugId: this.drugId, IsInvMangd: false}">
</app-physical-inventory>
