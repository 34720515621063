import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-quick-verification",
    templateUrl: "./quick-verification.component.html",
    styles: []
})
export class QuickVerificationComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
