import { Component, OnInit, Input } from "@angular/core";
import { InsuranceUtil, CommonUtil } from "src/app/utils";
import { FormGroup } from "@angular/forms";

@Component({
    selector: "app-insu-trans-setting",
    templateUrl: "./insu-trans-setting.component.html"
})
export class InsuTransSettingComponent implements OnInit {
    @Input() insuId: any;
    @Input() dropDownData: any;
    @Input() transStngInfo: any;
    @Input() transStngFG: FormGroup;
    openNCDPE = false;
    ncpdpInfo: any;

    constructor(private _comnUtil: CommonUtil) { }

    ngOnInit() {
    }
    focusToSave(event) {
        this._comnUtil.focusById("InsSaveButton", event);
    }

    openNCDPEdit(val) {
        if (val) {
            this.openNCDPE = true;
            this.ncpdpInfo = this.transStngFG.value;
        } else {
            this.openNCDPE = false;
        }
    }
}
