import { Component, Input, OnInit, ViewEncapsulation, SimpleChanges, ChangeDetectorRef, NgZone } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import * as moment from 'moment';
import { MessageService } from 'src/app/services/message.service';
import { CommonService } from 'src/app/services';
import { Subject, Subscription } from 'rxjs';
import { MessagesHistory } from 'src/app/models/messaging-model';
import { IdentifierTypeEnum, Message} from 'src/app/models/messaging.enum';
import { takeUntil } from 'rxjs/operators';
import { WebPubSubService } from 'src/app/services/WebPubSubService';
import { constant } from 'src/app/app.constants';
import { MsgConfig } from 'src/app/app.messages';

@Component({
  selector: 'app-spc-chat-history',
  templateUrl: './spc-chat-history.component.html',
  styleUrls: ['./spc-chat-history.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SpcChatHistoryComponent implements OnInit {
  @Input() messages: any[] = [];
  @Input() enterMessage: any[] = [];
  @Input() dataSource: any[] = [];
  filteredMessages: Message[] = [];
  fetchedMessages: Message[] = [];
  groupedMessages: any[] = [];
  @Input() patientID: any;
  @Input() userName: any;
  @Input() resetMessages:any;
  @Input() searchHistoryText: string = '';
  isnewMsgReceived:boolean = false;
  pharmacyName:any;
  pharmacyMessages:any;
  patientMessages:any;
  private messageSubscription: Subscription;
  unsubscribe$: Subject<void> = new Subject();
  auth0_token:any;
  npi:any;

  constructor(
    private _msgServ: MessageService,
    private _userServ: UserService,
    private _commonServ: CommonService,
    private _cdr: ChangeDetectorRef,
    private _webPubSubService: WebPubSubService,
    private _ngZone: NgZone) { }

  ngOnInit() {
    this.pharmacyName = this._userServ.getToken("PharmacyName");
    this.auth0_token = JSON.parse(this._commonServ.DecryptData(sessionStorage.getItem("CmnMicroSvcToken")));
    this.npi = this._userServ.getToken("UniquePharmacyId");
    if (this.isValidPatientAndNpi()) {   
      this.loadMessages(); 
    }
    this.messageSubscription = this._webPubSubService.newMessage$.subscribe((res) => {
        if(res) {
          if (res?.Sender?.Identifier === this.patientID) {
            this.isnewMsgReceived = true;
            const newMessage = {
                date: res?.MessageReceivedAt,
                message: res?.MessageText,
                isRead: false,
                sender: {
                  type: 0,
                  identifier: res?.Sender?.Identifier,
                  identifierType: IdentifierTypeEnum.PATIENT,
                  userName: this.userName ? this.userName : ''
                },
                recipient: {
                  type: 0,
                  identifier: res?.Recipient?.Identifier,
                  identifierType: IdentifierTypeEnum.NPI,
                  userName: this.pharmacyName
                }
              };
              this.messages = [...this.messages, newMessage];    
              const uniqueMessages = Array.from(new Map(
                  this.messages.map(msg => [`${msg.message}-${msg.date}`, msg])
              ).values());   
              this.messages = uniqueMessages;
              this.sortMessages();
              this.groupMessagesByDate();
              this.filteredMessages = [...this.messages];
              this._ngZone.runOutsideAngular(() => {
              requestAnimationFrame(() => {
                this.isnewMsgReceived = false;
                this.scrollToBottom();
              });
            });
        }
      }
    });
}

groupMessagesByDate(): void {
    const groupedMessages: any[] = [];
    const today = moment().startOf('day');
    const yesterday = moment().subtract(1, 'day').startOf('day');
    const startOfWeek = moment().startOf('week'); 
    this.messages.forEach(msg => {
    const msgDate = moment(msg.date); 
    if(!msgDate.isValid()) {
        console.log('Invalid date format:', msg.date); 
        return; 
    }
    const msgDay = msgDate.startOf('day');
    let header = '';
    if (msgDay.isSame(today)) {
        header =  MsgConfig.Today;
    } else if (msgDay.isSame(yesterday)) {
        header = MsgConfig.Yesterday
    } else if (msgDay.isBetween(startOfWeek, today, undefined, '[)')) {
        header = msgDay.format('dddd'); 
    } else {
        header = msgDay.format('dddd, MMMM D'); 
    }
    if(!groupedMessages.some(g => g.isHeader && g.date === header)) {
        groupedMessages.push({ isHeader: true, date: header });
    } 
        groupedMessages.push(msg);
    }); 
    this.groupedMessages = groupedMessages;
}
private isValidPatientAndNpi(): boolean {
  return !!this.patientID && !!this.npi;
}
  
  
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['searchHistoryText']) {
        this.filterMessages();
    }
    if (changes.patientID || changes.npi) {
      if (this.isValidPatientAndNpi()) {
          this._cdr.detectChanges();
          if(!this.isnewMsgReceived && this.resetMessages){
            this.loadMessages(); 
          }
      }
    }
    
    if (changes['enterMessage'] && changes['enterMessage'].currentValue) {
        const incomingMessages = changes['enterMessage'].currentValue;
        if (incomingMessages.length > 0) {

            this.messages = [...this.messages, ...incomingMessages];
            const uniqueMessages = Array.from(new Map(
                this.messages.map(msg => [`${msg.message}-${msg.date}`, msg])
            ).values());
            this.messages = uniqueMessages;
            this.messages.forEach(msg => {
              if (msg?.sender?.userName) {
                msg.sender.userName = this.capitalizeName(msg.sender.userName);
              }
              msg.status = msg.status?.toLowerCase();
            });
            this.sortMessages();
            this.groupMessagesByDate();
            this._ngZone.runOutsideAngular(() => {
                requestAnimationFrame(() => {
                    this.scrollToBottom();
                });
            });
        }
      }
  }
  filterMessages(): void {
    const searchLower = this.searchHistoryText.toLowerCase(); 

    this.messages = this.filteredMessages.filter(m => { 
        const matchesSearch = m.message.toLowerCase().includes(searchLower);
        return matchesSearch;
    });
    if (!searchLower) { 
        this.messages = [...this.filteredMessages];
    } else {
        this.messages = this.filteredMessages.filter(m => 
            m.message.toLowerCase().includes(searchLower)
        );
    } 
    this.messages.forEach(msg => {
        if(msg?.sender?.userName) {
            msg.sender.userName = this.capitalizeName(msg?.sender?.userName);
        }
        msg.status = msg.status?.toLowerCase();
    }); 
    this.sortMessages();
    this.groupMessagesByDate();
    this._ngZone.runOutsideAngular(() => {
        requestAnimationFrame(() => {
            this.scrollToBottom();
        });
    });
}
  onScroll(event: any) {
    const element = event.target;
    if (element.scrollTop === 0) { 
      if (this.isValidPatientAndNpi()) { 
        //this.loadMessages();
      }
    } else if (element.scrollHeight - element.scrollTop === element.clientHeight) {
      this.loadMoreMessages(); 
    }
  }

  loadMoreMessages() { 
    // Lazy load more history data (scroll Down)
  }
  parseMessage(message: string): string {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return message.replace(urlRegex, '<a href="$1" target="_blank">$1</a>');
  }
  loadMessages() {
        if(this.resetMessages){
          this.messages = [];
        }        
        const startDateTime = moment('2000-01-01T10:44:50.785Z').format('YYYY-MM-DD') || null;
        const endDateTime = moment().format('YYYY-MM-DD') || null;
        const payLoad: MessagesHistory = {
                Sender: {
                    Identifier: this.npi,
                    IdentifierType: IdentifierTypeEnum.NPI
                },
                Recipient: {
                    Identifier: this.patientID,
                    IdentifierType: IdentifierTypeEnum.PATIENT
                },
                StartDateTime: startDateTime,
                EndDateTime: endDateTime,
                PageIndex: constant.CHAT_PAGE_MIN,
                PageSize: constant.CHAT_PAGE_MAX,
                pharmacyNPI: this.npi
        }; 
        this._msgServ.postMessageConversation(payLoad)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((res: any[]) => {
            if (res) {
                let apiMessages = res['communicationLogs'];
                this.fetchedMessages = apiMessages; 
                
                this.fetchedMessages = this.fetchedMessages.filter(apiMessage => 
                    !this.messages.some(existingMessage => 
                        existingMessage.message === apiMessage.message && 
                        existingMessage.date === apiMessage.date
                    )
                );
                this.messages = [...this.fetchedMessages, ...this.messages];
                this.filteredMessages = [...this.messages];
                this.messages.forEach(msg => {
                  if (msg?.sender?.userName) {
                    msg.sender.userName = this.capitalizeName(msg.sender.userName);
                  }
                  msg.status = msg.status?.toLowerCase();
                });
                if (this.messages && this.messages.length > 0) {
                    this.sortMessages();
                    this.groupMessagesByDate();
                    this._ngZone.runOutsideAngular(() => {
                      requestAnimationFrame(() => {
                        this.scrollToBottom();
                      });
                    });
                }
            }
        });
  }
  sortMessages(): void {
    this.messages = this.messages.sort((a, b) => moment(a.date, 'YYYY-MM-DD hh:mm:ss A').diff(moment(b.date, 'YYYY-MM-DD hh:mm:ss A')));
  }

  private capitalizeName(name: string): string {
    return name
      .toLowerCase()
      .replace(/\b\w/g, (char) => char.toUpperCase());
  }

  loadPreviousMessages(){
      console.log('Loading previous messages');
  }
  scrollToBottom() {
      const chatHistory = document.querySelector('.chat-history');
      if (chatHistory) {
          chatHistory.scrollTop = chatHistory.scrollHeight;
      }
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    if (this.messageSubscription) {
      this.messageSubscription.unsubscribe();
    }
  }
}
