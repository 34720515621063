import { Component, OnInit, Input, Renderer2, ViewChild, ElementRef} from "@angular/core";
import { InsuranceUtil, CommonUtil, RxUtils , WijimoUtil } from "src/app/utils";
import { otherInsuSearch, otherInsuHeaders } from "../insurance.constants";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormGroup } from "@angular/forms";
import { AlertService, CommonService } from "src/app/services";
import { MsgConfig } from "src/app/app.messages";
import {
    debounceTime,
    distinctUntilChanged,
    map,
    takeUntil
} from "../../../../../node_modules/rxjs/operators";
import { Observable } from "rxjs/observable";
import { InsuranceAuditModel } from "src/app/models";
import { NewAuditUtils } from "src/app/utils";
import { Subject } from "rxjs";

@Component({
    selector: "app-other-insu",
    templateUrl: "./other-insu.component.html"
})
export class OtherInsuComponent implements OnInit {

    @Input() insuId: any;
    @Input() dropDownData: any;
    @Input() planInfo: any;

    searchData = otherInsuSearch;
    actvHeaders = otherInsuHeaders;
    otherInsuData: { result: any; wjSource: any; count: number; } = { result: null, wjSource: null, count: null };
    typeHeadOtherInsu = this._insuUtils.searchOtherInsu;
    popUpType: any;
    modalRef: any;
    insuPayid: any = "";
    otherInsuFG: FormGroup;
    selectedId: any;
    insuName: any;
    inputValue: any;
    @ViewChild("InsuranceNotFound")
    InsuranceNotFound: ElementRef;
    wjHeaders: any;
    oldData: any;
    insCodeDropdownData: any;
    isDataExists = true;
    unsubscribe$ : Subject<void> = new Subject();
    warningText = {
        deleteHeaderTxt: null,
        deleteConfrmMsg: null
    }

    constructor(private _insuUtils: InsuranceUtil, private _modalSvc: NgbModal,
        private _alrtSvc: AlertService, private _commonSvc: CommonService, private _rxUtils: RxUtils,
        private _renderer: Renderer2, private _comnUtil: CommonUtil, private _wijimoUtils: WijimoUtil, private _newAuditUtils: NewAuditUtils) { }

    ngOnInit() {
        this.searchData["InsuPlanId"] = this.insuId;
        this.getOtherInsuData();
        this.patchDefaultValHeaders();
        this._wijimoUtils.wjSavedData$.pipe(takeUntil(this.unsubscribe$)).subscribe(w => {
            if (w && w["WijmoKey"] && w["WijmoKey"] === "otherInsuListWj") {
                this.patchDefaultValHeaders();
            }
        });
        this.warningText.deleteHeaderTxt = MsgConfig.Delete_Header_Text;
        this.warningText.deleteConfrmMsg = MsgConfig.Delete_Confirmation;
    }
    async patchDefaultValHeaders() {
        const storedWJ = await this._wijimoUtils.getWJSavedData("otherInsuListWj");
        this.wjHeaders = this._wijimoUtils.patchDefHeader("otherInsuListWj", storedWJ);
    }

    focusToSave(event) {
        this._comnUtil.focusById("InsSaveButton", event);
    }

    focusOutFrmAdd(event) {
        if (this.otherInsuData["count"] === 0) {
            this.isDataExists = false;
            this.focusToSave(event);
        }
    }

    async getOtherInsuData() {
        this.otherInsuData = await this._insuUtils.getOtherInsuData(this.searchData);
        if (this.otherInsuData['count']) {
            this.isDataExists = true;
        } else {
            this.isDataExists = false;
        }
    }

    setPage(page: number) {
        this.searchData.PageNumber = page;
        this.getOtherInsuData();
    }

    setSize(size: any) {
        this.searchData.PageNumber = 1;
        this.searchData.PageSize = size;
        this.getOtherInsuData();
    }

    selectedInsType(val, type?: any) {
        const data = val.item;
        if (val && val.item && val.item.Id) {
            this.insuName = data.InsurerName;
            this.inputValue = data.InsurerCode ? data.InsurerCode.trim() : data.InsurerCode;
            this.otherInsuFG.controls["InsuPlanId"].patchValue(val.item.Id);
            this.insCodeDropdownData = [{id: val.item.Id, code: data.InsurerCode}];
        } else {
            this.otherInsuFG.controls["InsuPlanId"].patchValue(null);
            this.insCodeDropdownData = [];
        }
    }

    search = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            map(term => (term.length < 1 ? [] : this.filterByPcnBin(term)))
        )

    filterByPcnBin(term) {
        const insuranceData = this._commonSvc.insuranceData$["source"]["value"];
        const insuranceList = Object.assign([], insuranceData);
        const filteredData = this._rxUtils.filterInsurancesForSuggest(insuranceList, term);
        if (!filteredData || (filteredData && filteredData.length === 0)) {
            this.modalRef = this._modalSvc.open(this.InsuranceNotFound, {centered: true, backdrop: false, windowClass: "large--content"});
            this.inputValue = "";
            setTimeout(() => {
                this.inputValue = null;
            }, 100);
        }
        return filteredData.splice(0, 20);
    }

    formatter = (x: any) => {
        this.insuName = x.InsuranceName ? x.InsuranceName.trim() : "";
        return x.InsurerCode ? x.InsurerCode.trim() : null;
    }

    closeInsuNotFound() {
        if (this.modalRef) {
            this.modalRef.close();
        }
        this.inputValue = "";
        this.insuName = "";
        setTimeout(() => {
            this.inputValue = null;
            this.insuName = null;
        }, 100);
    }

    openAddModal(content) {
        this.popUpType = "Add";
        this.inputValue = null;
        this.insuName = null;
        this.otherInsuFG = this._insuUtils.createOtherInsuFG();
        this.otherInsuFG.controls["InsuCarrierId"].patchValue(this.planInfo.InsuPlan.InsuCarrierId);
        this.modalRef = this._modalSvc.open(content, {
            centered: true,
            keyboard: false,
            backdrop: false
        });
    }

    openEditMode(content, item) {
        this.popUpType = "Update";
        const result = this.otherInsuData["result"].find(val => val.Id === item["Id"]);
        this.oldData = result;
        this.otherInsuFG = this._insuUtils.createOtherInsuFG();
        this.otherInsuFG.patchValue(result);
        this.otherInsuFG.controls["InsuCarrierId"].patchValue(this.planInfo.InsuPlan.InsuCarrierId);
        this.modalRef = this._modalSvc.open(content, {
            centered: true,
            keyboard: false,
            backdrop: false
        });
    }

    openDeleteMode(content, item) {
        this.selectedId = item.Id;
        this.modalRef = this._modalSvc.open(content, {
            centered: true,
            keyboard: false,
            backdrop: false,
            windowClass: "large--content"
        });
    }

    focusOnOtherInsu() {
        if (this._renderer.selectRootElement("#InsuCodeSearch")) {
            this._renderer.selectRootElement("#InsuCodeSearch").focus();
        }
    }

    async addOrEditOtherInsu() {
        if (!this.otherInsuFG.value["InsuPlanId"]) {
            this._alrtSvc.error(MsgConfig.ENTER_REQ_FIELD);
        } else {
            const response = this.popUpType === "Add" ?
                await this._insuUtils.addOtherInsu(this.otherInsuFG.value) :
                await this._insuUtils.editOtherInsu(this.otherInsuFG.value);
            if (response === -1 && this.popUpType === "Add") {
                this.otherInsuFG.controls["InsuPlanId"].patchValue(null);
                this.insuPayid = "";
                this._alrtSvc.error("Record for this insurance already exists.Please select other Insurance", false, "long");
            } else if (response === 1) {
                if (this.modalRef) {
                    this.modalRef.close();
                    this.modalRef = null;
                }
                this.getOtherInsuData();
                this.popUpType === "Add" ? this._alrtSvc.success(MsgConfig.SAVE_SUCCESS) : this._alrtSvc.success(MsgConfig.UPDATE_SUCCESS);
                const insuDataModel = new InsuranceAuditModel().planInfo;
                this.dropDownData["OtherInsureCodes"] = (this.insCodeDropdownData) ? this.insCodeDropdownData : [];
                if(this.popUpType === "Add") {
                    this._newAuditUtils.saveAuditOnAdd(
                        { "OtherPayor" : this.otherInsuFG.value },
                        "Add Other Insurance Payer Id",
                        "Insurance",
                        this.insuId,
                        insuDataModel,
                        this.dropDownData
                    );
                } else {
                    this._newAuditUtils.saveAuditChange(
                        { "OtherPayor" : this.oldData },
                        { "OtherPayor" : this.otherInsuFG.value },
                        "Edit Other Insurance Payer Id",
                        "Insurance",
                        this.insuId,
                        insuDataModel,
                        false,
                        this.dropDownData
                    );
                }
            } else {
                this.popUpType === "Add" ? this._alrtSvc.error(MsgConfig.SAVE_FAIL) : this._alrtSvc.error(MsgConfig.UPDATE_FAIL);
            }

        }

    }

    async deleteOtherInsu() {
        const response  = await this._insuUtils.deleteOtherInsu(this.selectedId);
        response ? this._alrtSvc.success(MsgConfig.DELETE_SUCCESS) : this._alrtSvc.error(MsgConfig.DELETE_FAIL);
        this.getOtherInsuData();
    }
    ngOnDestroy(){
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
