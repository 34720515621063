import { Component, OnInit, Output, EventEmitter, Input, ViewChild, OnChanges } from "@angular/core";
import { AlertService } from "src/app/services";
import { PrivMaskGuard } from "src/app/guards";
import { AccCategoryE, FileMaintenance1E } from "src/app/models";
import { MsgConfig } from "src/app";
import { InsuTabTitles } from "../insurance.constants";
import { FormGroup } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-insurance-tabs",
  templateUrl: "./insurance-tabs.component.html"
})
export class InsuranceTabsComponent implements OnInit, OnChanges {
    accessPrivCate: any;
    insuNotesPrivs: any;
    activeTabId: any;
    @Input() insuranceInfo: any;
    @Input() insuFG: FormGroup;
    @Input() insuId: any;
    @Output() ActivePrescriberTab = new EventEmitter();
    @Output() addInsurance = new EventEmitter();
    @Input() resetToFirst: boolean;

    @ViewChild("warning", { static: true })
    warning: any;
    selectedTab: string;

    @Input()
    ActiveTabId(data: any) {
        this.activeTabId = InsuTabTitles[data];
    }


    constructor(
        private _alertServ: AlertService,
        private _modalSvc: NgbModal,
        private _privMaskGuard: PrivMaskGuard
    ) {
        this.accessPrivCate = AccCategoryE;
        this.insuNotesPrivs = FileMaintenance1E;
    }

    ngOnInit() {

    }

    ngOnChanges(changes?: any) {
        if (changes && changes.resetToFirst && changes.resetToFirst.currentValue) {
            if (this.resetToFirst) {
                this.activeTabId = null;
                setTimeout(() => {
                    this.activeTabId = "Patient1";
                    this.ActivePrescriberTab.emit("Patient1");
                }, 100);
            }
        }
    }

    beforeChange(nextId: string) {
        if (nextId && this.insuId) {
             if (nextId === "Patient6") {
                if (
                    this.checkHasPrivsToAccess(
                        this.accessPrivCate.FileMaintenance1,
                        this.insuNotesPrivs.InsuranceNotesLog
                    )
                ) {
                    this.ActivePrescriberTab.emit(nextId);
                } else {
                   this._alertServ.error(MsgConfig.NO_PRIVS_ACCESS, false, "long");
                }
            } else {
                this.ActivePrescriberTab.emit(nextId);
            }
        } else {
            if (this.insuFG.valid) {
                this.selectedTab  = nextId;
                this._modalSvc.open(this.warning, {centered: true, backdrop: false, windowClass: "large--content"});
            } else {
                this.activeTabId = null;
                setTimeout(() => {
                    this.activeTabId = "Patient1";
                }, 100);
                this.addInsurance.emit("Patient1");
            }
        }
    }

    checkHasPrivsToAccess(category: string, privType: string) {
        return this._privMaskGuard.canActivate(category, privType);
    }

    emitAddinsurance() {
        this.addInsurance.emit(this.selectedTab);
    }

    closeWarning() {
        this.activeTabId = null;
        setTimeout(() => {
            this.activeTabId = "Patient1";
        }, 100);
        this.ActivePrescriberTab.emit("Patient1");
    }

}
