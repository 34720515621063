<div class="exprx--common-block">
    <div class="eprx--block__header large_header">
        <div class="row">
            <div class="col">
                <span class="eprx--header__heading">Additional Insurance</span>
            </div>
            <div class="col pull-right text-right">
                <button autofocus class="hotkey_success hotkey_primary--Override"
                    (keydown.tab)="focusOutFromAdd($event)" title="Add Insurance" (click)="openPopUpModal(addInsurance)"
                    appShortcutKey [AltKey]="'a'"><span>A</span>ADD INSURANCE</button>
            </div>
        </div>
    </div>
    <div class="eprx--block__content erx--body">
        <wj-flex-grid class="max-height_30rem" #InsuList [ngClass]="[patientInsurances && !patientInsurances?.length ? 'max-height_30rem no-data': 'max-height_30rem']"
            [headersVisibility]="'Column'" [selectionMode]="'Row'" [itemsSource]="patientInsuWJ" [isReadOnly]="true"
            [columnPicker]="'patAdditionalInsuListWJ'" (updatedView)="init(InsuList)" [allowSorting]=false
            (initialized)="Init(addInsurance,InsuList)"
            (keydown)="openPopOnWijimo(addInsurance, $event)" (keydown.tab)="focusToFirst($event)">
            <wj-flex-grid-column [header]="'Action'" [width]="'1.1*'">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                    <i class="far fa-edit actions actions--Highlightfocus--icon" title="edit"
                            (click)="OpenEditInsu(addInsurance, InsuList, $event)"></i>
                        <i class="far fa-trash-alt actions danger-actve" title="delete"
                            (click)="deleteInsuranceformList(deleteInsuranceContent, InsuList, $event)"></i>
                        <i class="far fa-file-alt actions actions--Highlightfocus--icon" aria-hidden="true" title="Set as Primary Insurance"
                            *ngIf="(item['Active for Patient']==='YES' && item['InsurerActiveStatus'] === true)"
                            (click)="getRowDetails(setPrimaryInsuranceContent, InsuList, $event)"></i>
                        <i class="far fa-file-alt actions in-active" aria-hidden="true" title="Cannot set the Insurance as Primary, as it is marked as Inactive on Insurance File."
                            *ngIf="(item['InsurerActiveStatus'] === false && item['Active for Patient']==='YES')"></i>
                        <i class="far fa-check-circle actions actions--Highlightfocus--icon" aria-hidden="true" title="Set Insurance Status"
                            (click)="getRowDetails(setInsuranceStatusContent, InsuList, $event)"></i>
                </ng-template>
            </wj-flex-grid-column>
            <wj-flex-grid-column [header]="rxheader['hName']" [binding]="rxheader['hName']" [visible]="rxheader['isVisible']"
            *ngFor="let rxheader of wjHeaders; let i = index;" [width]="rxheader['width']">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                    <div *ngIf="rxheader['hName'] === 'Comments'" [title] ="item[rxheader['hName']] |  uppercase">
                        {{item[rxheader['hName']]}}
                    </div>
                    <div
                        *ngIf="(['Ins Code', 'Insurer Name', 'Ins ID', 'PC', 'Priority', 'Group#',
                    'C.H.Last Name', 'C.H.First Name', 'Active for Patient', 'BIN', 'Rel.'].indexOf(rxheader['hName']) > -1)">
                        {{item[rxheader['hName']]}}
                    </div>
                    <div *ngIf="(rxheader['hName'] === 'Assig' || rxheader['hName'] === 'Is 340B?')">
                        {{item[rxheader['hName']] ? "YES": "NO"}}
                    </div>
                </ng-template>
                <ng-template wjFlexGridCellTemplate [cellType]="'ColumnHeader'" let-item="item">
                    <div title={{rxheader.hName}}>
                        {{ rxheader.hName }}
                    </div>
                </ng-template>
            </wj-flex-grid-column>
            <app-no-data-found *ngIf="!isDataExists"></app-no-data-found>
        </wj-flex-grid>
    </div>
</div>

<!-- Add Additional Insurance to a patient -->
<ng-template #addInsurance let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">
            <span *ngIf="!editMode">ADD INSURANCE DETAILS</span>
            <span *ngIf="editMode">EDIT INSURANCE DETAILS</span>
        </h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <div class="eprx--block__header">
                    <div class="eprx--header__heading">
                        <h4>Insurance</h4>
                    </div>
                </div>
                <div class="eprx--block__content">
                    <div class="row">
                        <div class="col form-group custom--input" [ngClass]="{
                            'has-error':
                            !inputValue && !isInputTouched
                        }">
                            <label class="control-label pat-insuType">
                                <span> Ins.Type <span class="help--text__danger">*</span></span>
                                <span class="curency-format">
                                    <input type="text" #searchBoxInsType [value]="inputValue" (focus)="onInputFocus()" (blur)="onInputBlur()"
                                        class="right-part form-control search--input__box" (keydown)="onInputKeydown($event)"
                                        placeholder="To Start Search..." appChangeDebounce [ngbTypeahead]="search"
                                        [required]="true" [resultTemplate]="rt" [inputFormatter]="formatter"
                                        (selectItem)="selectedInsType($event, '', Insurance)" KeyUpDown [appAutofocus]="true">
                                    <div class="left-part">
                                        <input type="text" tabindex="-1" #searchBoxInsName [value]="insuName"
                                            name="searchBoxInsName" class="form-control search--input__box" readonly />
                                    </div>
                                </span>
                            </label>
                            <div class="help-block"
                                *ngIf="!inputValue && !isInputTouched">
                                Required
                            </div>
                            <a class="add__icon" (click)="routeToAddInsurance()" appShortcutKey [AltKey]="'i'">
                                <i class="far fa-plus-circle fa-lg" title="Add Insurance"></i>
                            </a>
                            <ng-template #rt let-r="result" let-t="term">
                                <div class="card patient-card secondary-patient-card" *ngIf="r">
                                    <div class="row">
                                        <div class="col">
                                            <div class="row">
                                                <div class="card__subTit--head">
                                                    Insurance Code:
                                                </div>
                                                <div class="card__subTit--title">
                                                    {{ r?.InsurerCode }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <div class="row">
                                                <div class="card__subTit--head">
                                                    Insurance Name :
                                                </div>
                                                <div class="card__subTit--title">
                                                    {{ r?.InsuranceName }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <div class="row">
                                                <div class="card__subTit--head">
                                                    Bin Num :
                                                </div>
                                                <div class="card__subTit--title">
                                                    {{ r?.BinNum }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <div class="row">
                                                <div class="card__subTit--head">
                                                    PCN Num :
                                                </div>
                                                <div class="card__subTit--title">
                                                    {{ r?.ProcessorNum }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </div>
                        <div class="col-2">
                            <eprx-input [RxSelectId]="'InsuId'" [LabelText]="'Insurance ID'"
                                [PlaceHolder]="'Insurance ID'" [FormGroupInvalid]="formGroupInvalid"
                                [ControlName]="'PolicyNum'" [FormGroupName]="InsuCard"
                                [InputErrors]="InsuCard?.get('PolicyNum')?.errors"
                                [ErrorDefs]="{pattern: 'Invalid!', required: 'Required', invalid: ''}"
                                [IsRequired]="policyRequired" [ValidPattern]="regex?.IdNumeric" [MaxLength]="80"
                                [MarkAsTouched]="InsuCard?.get('PolicyNum')?.touched">
                            </eprx-input>
                        </div>
                        <div class="col-2">
                            <eprx-select [LabelText]="'Group#'" [PlaceHolder]="'Group#'"
                                [ControlName]="'InsuGrpId'" [FormGroupName]="Insurance"
                               [List]="filteredGroups" [BindLabel]="'Name'"
                                [BindValue]="'Id'" [InputValue]="InsuranceGroup?.get('Name')?.value"
                                [LabelForId]="'Name'" (TriggerSelectValue)="isTypedValue($event)"
                                [AddItem]="true" (TriggerOnClear)="isTypedValue($event)"
                                [SelectOnTab]="true" [addItemMaxLength]="35">
                            </eprx-select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="eprx--block__header">
                    <div class="eprx--header__heading">
                        <h4>Billing</h4>
                    </div>
                </div>
                <div class="eprx--block__content">
                    <div class="row">
                        <div class="col">
                            <eprx-select [RxSelectId]="'primInsRela'" [LabelText]="'Relationship'"
                                [PlaceHolder]="'Relationship'" [ControlName]="'RelCatId'" [FormGroupName]="Insurance"
                                [InputErrors]="Insurance?.get('RelCatId')?.errors" [List]="systemData?.RelationCat ? (systemData?.RelationCat | filterRelation:'Patient') : []"
                                [BindLabel]="'Name'" [BindLabel2]="'Remarks'" [ShowLabel2]="true" [BindValue]="'Id'" [LabelForId]="'Name'"
                                [bindScnCntrlV]="'Name'" [secondCntrlN]="'Relation'" [Show2Values]="true">
                            </eprx-select>
                        </div>
                        <div class="col">
                            <!-- check these control -->
                            <eprx-input [LabelText]="'Person Code'" [PlaceHolder]="'Person Code'"
                                [ControlName]="'PerNum'" [FormGroupName]="Insurance" [ErrorDefs]="{pattern: 'Invalid!'}"
                                [ValidPattern]="regex?.AlphaNumeric" [InputErrors]="Insurance?.get('PerNum')?.errors"
                                [MaxLength]="3">
                            </eprx-input>
                        </div>
                        <div class="col">
                            <eprx-input [LabelText]="'Card Holder\'s Last Name'" [PlaceHolder]="'Card Holder\'s Last Name'"
                                [ControlName]="'LastName'" [FormGroupName]="InsuCard"
                                [ErrorDefs]="{pattern: 'Invalid!'}" [ValidPattern]="regex?.AlphaNumeric"
                                [MaxLength]="35">
                            </eprx-input>
                        </div>
                        <div class="col">
                            <eprx-input [LabelText]="'Card Holder\'s First Name'" [PlaceHolder]="'Card Holder\'s First Name'"
                                [ControlName]="'FirstName'" [FormGroupName]="InsuCard"
                                [ErrorDefs]="{pattern: 'Invalid!'}" [ValidPattern]="regex?.AlphaNumeric"
                                [MaxLength]="35">
                            </eprx-input>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <eprx-date-picker [RxSelectId]="'primInsCardExp'" [LabelText]="'Card Expires'" [PlaceHolder]="'Card Expires'"
                                [ControlName]="'ExpiryDt'" [FormGroupName]="InsuCard" [MinDate]="minDate" [IsRequired]="true" [ErrorDefs]="{ required: 'Required' ,minDate:'Date should be greater than today'}"
                                [MarkAsTouched]="InsuCard?.get('ExpiryDt')?.touched" [InputErrors]="InsuCard?.get('ExpiryDt')?.errors">
                            </eprx-date-picker>
                            <!-- <eprx-date-picker [LabelText]="'Card Expires'" [PlaceHolder]="'Card Expires'"
                                [ControlName]="'ExpiryDt'" [FormGroupName]="InsuCard" [MinDate]="minDate">
                            </eprx-date-picker> -->
                        </div>
                        <div class="col">
                            <eprx-select [LabelText]="'Billing Priority'" [PlaceHolder]="'Billing Priority'"
                                [ControlName]="'InsuPriId'" [FormGroupName]="Insurance" [List]="billingOptions"
                                [BindLabel]="'value'" [BindValue]="'Id'" [LabelForId]="'Name'"
                                (TriggerSelectValue)="selectedPriority($event['value'])">
                            </eprx-select>
                        </div>
                        <div class="col">
                            <eprx-radio [LabelText]="'Is Active'" [ControlName]="'ActiveStatusId'"
                                [FormGroupName]="Insurance" [InputErrors]="Insurance?.get('ActiveStatusId')?.errors"
                                [List]="['Yes', 'No']" [ValueList]="[1, 2]" [IDForList]="['ActiveStatusId4', 'ActiveStatusId5']" [LabelForId]=""
                                [Name]="'ActiveStatusId'">
                            </eprx-radio>
                        </div>
                        <div class="col" [formGroup]="Insurance">
                            <eprx-radio [LabelText]="'Patient Assignment'" [ControlName]="'IsPatAssigned'"
                                [FormGroupName]="Insurance" [InputErrors]="Insurance?.get('IsPatAssigned')?.errors"
                                [List]="['Yes', 'No']" [ValueList]="[true, false]" [IDForList]="['IsPatAssigned2', 'IsPatAssigned3']"
                                [LabelForId]="" [Name]="'IsPatAssigned'">
                            </eprx-radio>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="eprx--block__header">
                    <div class="eprx--header__heading">
                        <h4>Comments</h4>
                    </div>
                </div>
                <div class="eprx--block__content">
                    <div class="row">
                        <div class="col" [formGroup]="Insurance">
                            <eprx-text-area [FormGroupName]="Insurance" [ControlName]="'Remarks'"
                                [PlaceHolder]="'Comments'" [Rows]="3">
                            </eprx-text-area>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" *ngIf="!editMode" class="hotkey_success" (click)="checkFGValid('add')" appShortcutKey
            [AltKey]="'s'"><span>S</span>SAVE</button>
        <button type="button" *ngIf="editMode" class="hotkey_success" (click)="checkFGValid('update')" appShortcutKey
            [AltKey]="'u'"><span>U</span>UPDATE</button>
        <button type="button" class="hotkey_success" appShortcutKey [AltKey]="'c'"
         (click)="d('Cross click');"   (click)="(IsNavigateFromPatientInfoTab) ?getpatientInsuList() : ''"><span>C</span>CANCEL</button>
    </div>
</ng-template>

<!-- deleteInsurance -->
<ng-template #deleteInsuranceContent let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Confirmation</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>Are you sure you want to delete the selected insurance(s)?</p>
    </div>
    <div class="modal-footer">
        <button type="button" ngbAutoFocus class="hotkey_primary" (click)="deleteInsurance()" appShortcutKey
            InputKey="y"><b>Y</b> YES </button>
        <button type="button" class="hotkey_primary" (click)="c('Close click')" appShortcutKey
            InputKey="n"><b>N</b>NO</button>
    </div>
</ng-template>

<!-- Set Primary Insurance -->
<ng-template #setPrimaryInsuranceContent let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Confirmation</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>Would you like to set
            <b>{{rowDetails["Insurer Name"] | uppercase}}</b> as the Primary Insurance?</p>
    </div>
    <div class="modal-footer">
        <button type="button" ngbAutoFocus class="hotkey_primary" (click)="setPatientPrimaryInsurance()" appShortcutKey
            InputKey="y"><b>Y</b> YES</button>
        <button type="button" class="hotkey_primary" (click)="c('Close click')" appShortcutKey InputKey="n"><b>N</b>
            NO</button>
    </div>
</ng-template>

<!-- setInsuranceStatusContent -->
<ng-template #setInsuranceStatusContent let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Confirmation</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Close')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>Set Insurance
            <b>{{rowDetails["Insurer Name"] | uppercase}}</b> status to
            <span *ngIf="rowDetails['Active for Patient']!=='YES'"><b>Active.</b> </span>
            <span *ngIf="rowDetails['Active for Patient']==='YES'"><b>Inactive.</b> </span>
        </p>
    </div>
    <div class="modal-footer">
        <button *ngIf="rowDetails['Active for Patient']!=='YES'" ngbAutoFocus type="button" class="hotkey_primary"
            (click)="setInsuranceStatus(1)" (click)="d('Close')" appShortcutKey InputKey="a"><b>A</b> ACTIVATE</button>
        <button *ngIf="rowDetails['Active for Patient']==='YES'" ngbAutoFocus type="button" class="hotkey_primary"
            (click)="setInsuranceStatus(2)" (click)="d('Close')" appShortcutKey InputKey="i"><b>I</b>INACTIVATE</button>
    </div>
</ng-template>

<ng-template #PrimaryInsuranceDelete let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Warning!!</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>Primary Insurance can not be deleted.</p>
    </div>
    <div class="modal-footer">
        <button type="button" ngbAutoFocus class="hotkey_primary" (click)="c('Close click')" appShortcutKey
            InputKey="o"><b>O</b> OK</button>
    </div>
</ng-template>

<ng-template #InsuranceNotFound let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Warning!!</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click');closeInsuNotFound()">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>Insurance not found in the file.</p>
    </div>
    <div class="modal-footer">
        <button type="button" ngbAutoFocus class="hotkey_primary" (click)="c('Close click');closeInsuNotFound()" appShortcutKey
            InputKey="o"><b>O</b> OK</button>
    </div>
</ng-template>
