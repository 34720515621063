import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { MergePatientComponent } from "./merge-patient/merge-patient.component";
import { MergePatGuard } from "src/app/guards";

const routes: Routes = [
            {path: "MergePatients", component : MergePatientComponent, canActivate: [MergePatGuard]},
            {path: "MergePatients/:pid", component : MergePatientComponent, canActivate: [MergePatGuard]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ToolsRoutingModule { }
