import { Component, EventEmitter, Input, OnInit, Output,ChangeDetectorRef } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-transfer-type',
  templateUrl: './transfer-type.component.html',
  styleUrls: ['./transfer-type.component.scss']
})
export class TransferTypeComponent implements OnInit {
 selectedDeatils: any;
 @Output()
 SelectedTransType = new EventEmitter<string>();
 @Output()
 SelectedDrugDetails = new EventEmitter<string>();
 @Input() IsHistory:boolean;
 @Input() eRxModelFG:FormGroup;
 @Input() IsfrmPatHis:boolean;
 @Input() RequestedInfo;
 isDrugSearchopen: boolean = false;
  constructor(private _cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
  }
  openDrugSearchpopup(type?) {
    this.selectedDeatils =  null;
    this._cdr.detectChanges();
    this.selectedDeatils = type ? type :  null;
    if(type && type === "Drug" && !this.eRxModelFG.value['drugID']) {
        this.isDrugSearchopen = false;
        this._cdr.detectChanges();
        this.isDrugSearchopen = true;
    } else if(type && type === "DrugIcon") {
      this.isDrugSearchopen = false;
      this._cdr.detectChanges();
      this.isDrugSearchopen = true;
    }
    this.SelectedTransType.emit(type);
    this.SelectedDrugDetails.emit(null);
  }
  selectedDrug(drug) {
    this.SelectedDrugDetails.emit(drug);
    this.SelectedTransType.emit(null);
  }
}
