import { AuditLogUtils } from "./../../../utils/audit-log.util";
import { FormGroup, FormBuilder, FormControl } from "@angular/forms";
import {
    Component,
    OnInit,
    Output,
    EventEmitter,
    ChangeDetectorRef,
    AfterViewInit,
    OnDestroy,
    HostListener,
    Input
} from "@angular/core";
import { ActivatedRoute, Router } from "../../../../../node_modules/@angular/router";
import { MorePatientInfoService } from "src/app/services/more-patientinfo.service";
import { RegEx } from "src/app/app.regex";
import * as moment from "moment";
import { AlertService, CommonService } from "src/app/services";
import { RxUtils, NewAuditUtils } from "src/app/utils";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PatAuditLog } from "src/app/models";
import { PatFacility } from "src/app/models/elastc-rx.model";
import { PatientStore } from "src/app/store";

@Component({
    selector: "app-facility-info",
    templateUrl: "./facility-info.component.html",
    styles: []
})
export class PFacilityInfoComponent implements OnInit, AfterViewInit {
    @Input() patientId: any;
    @Input() facilityFG: FormGroup;
    @Input() facilityInfo: any;
    @Input() focusId: any;
    regex = RegEx;
    prescInputValue = "";
    locations = [{Id: 0, Name: "All"}];
    stations = [{Id: 0, Name: "All"}];
    today: string;
    maxDate: { year: number; month: number; day: number; };

    constructor(
        private _morePatInfoSvc: MorePatientInfoService,
        private _patientStore: PatientStore
    ) {}

    ngOnInit() {
        this.today = moment().format("MM/DD/YYYY");
        this.maxDate = {
            year: moment(this.today).year(),
            month: moment(this.today).month() + 1,
            day: moment(this.today).date()
        };
        this.setDefaultValues();
    }

    ngAfterViewInit() {
        if (this.focusId) {
            const element: any = document.getElementById(this.focusId);
            if (element)
                element.focus();
        } else if (document.getElementById("Facility")) {
            const data: any = document.getElementById("Facility").getElementsByTagName("input")[0];
            if (data) {
                data.focus();
            }
        }
    }

    setDefaultValues() {
        this.setLocationAndStation(this.facilityInfo);
        this.facilityFG.patchValue({
            PatientId: this.patientId,
            IsActive: true,
            IsCycleFillAllowed: this.facilityFG.value["IsCycleFillAllowed"] !== null ? this.facilityFG.value["IsCycleFillAllowed"] : true,
            IsUnitDoseAllowed: this.facilityFG.value["IsUnitDoseAllowed"] !== null ? this.facilityFG.value["IsUnitDoseAllowed"] : false,
            IsPrintMedSheet: this.facilityFG.value["IsPrintMedSheet"] !== null ? this.facilityFG.value["IsPrintMedSheet"] : false,
            FacLocId: this.facilityFG.value["FacLocId"] ? this.facilityFG.value["FacLocId"] : 0,
            FacStationId: this.facilityFG.value["FacStationId"] ? this.facilityFG.value["FacStationId"] : 0,
        });
        this.prescInputValue = this.facilityFG.value["PrimePrescName"];
    }

    setLocationAndStation(data) {
        let loca = [];
        let stat = [];
        if (this.locations.length > 1) {
            this.locations.splice(1, this.locations.length);
        }
        if (this.stations.length > 1) {
            this.stations.splice(1, this.stations.length);
        }
        if (data && data["FacLocation"] && data["FacLocation"]["length"] > 0) {
            data["FacLocation"].map(val => this.locations.push(val));
            loca = Object.assign([], this.locations);
            this.locations = [];
            this.locations = loca;
        }
        if (data && data["FacStation"] && data["FacStation"]["length"] > 0) {
            data["FacStation"].map(val => this.stations.push(val));
            stat = Object.assign([], this.stations);
            this.stations = [];
            this.stations = stat;
        }
    }

    selectedPresInfo(val) {
        if (val && val.prescriberid) {
            this.prescInputValue = val.prescriberlastname + (val.prescriberfirstname ? ", " + val.prescriberfirstname : "");
            this.facilityFG.controls["PrimPrescbId"].setValue(val.prescriberid);
            this.facilityFG.controls["PrimePrescName"].setValue(this.prescInputValue);
        } else {
            this.facilityFG.controls["PrimPrescbId"].setValue(null);
            this.facilityFG.controls["PrimePrescName"].setValue(null);
            this.prescInputValue = "";
        }
        this.facilityFG.markAsDirty();
    }

    async getFacLoc() {
        const response = await this._morePatInfoSvc.getFacilityLocations(this.facilityFG.value["FacilityId"]).toPromise();
        if (this.facilityInfo && response) {
            this.facilityInfo["FacLocation"] = response["FacLocation"];
            this.facilityInfo["FacStation"] = response["FacStation"];
        }
        this._patientStore.storeFacilty(Object.assign({}, this.facilityInfo));
        this.setLocationAndStation(response);
        this.facilityFG.patchValue({
            FacLocId: null,
            FacStationId: null
        });
    }

    focusToFirst(event) {
        if (document.getElementById("SaveButton")) {
            event.preventDefault();
            setTimeout(() => {
                document.getElementById("SaveButton").focus();
            }, 10);

        }
    }
}
