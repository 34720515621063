import { Component, OnInit, Input } from "@angular/core";
import * as moment from "moment";
import { FormBuilder, FormGroup } from "@angular/forms";
import { DrugTabUtils } from "src/app/utils/drug-tabs.utils";
import * as wjcGrid from "@grapecity/wijmo.grid"
import { ValidateFormFieldsUtils, WijimoUtil } from "src/app/utils";
import { AlertService } from "src/app/services";
import { MsgConfig } from "src/app/app.messages";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { PaginationModel } from "src/app/models/elastc-rx.model";

@Component({
    selector: "app-drug-price-update",
    templateUrl: "./drug-price-update.component.html"
})
export class DrugPriceUpdateComponent implements OnInit {
    paginationModel: PaginationModel = new PaginationModel();
    @Input() drugId: any;
    today: string;
    searchFG: FormGroup;
    pageNu = this.paginationModel.pageNumber;
    Size = this.paginationModel.pageSize;
    priceUpdateData: { count: number; wjSource: any } = { count: null, wjSource: null };
    wjHeaders: any;
    isDataExists = true;
    unsubscribe$ : Subject<void> = new Subject();
    constructor(private _fb: FormBuilder, private _drugTabUtil: DrugTabUtils, private _wijimoUtils: WijimoUtil,  private _alertSer: AlertService, private _formUtils: ValidateFormFieldsUtils) { }

    ngOnInit() {
        this.today = moment().format("MM/DD/YYYY");
        this.searchFG = this._fb.group({
            DrugId: this.drugId,
            Pagenumber: this.pageNu,
            Pagesize: this.Size,
            FromDateTime: this.today,
            ToDateTime: this.today
        });
        this.getPriceData();
        this.patchDefaultValHeaders();
        this._wijimoUtils.wjSavedData$.pipe(takeUntil(this.unsubscribe$)).subscribe(w => {
            if (w && w["WijmoKey"] && w["WijmoKey"] === "drugPriceUpdateListWJ") {
                this.patchDefaultValHeaders();
            }
        });
    }

    async getPriceData() {
        await this._formUtils.blurAndDelay();
        const resp = this._formUtils.checkDateErrors(this.searchFG, "FromDateTime", "ToDateTime", 0);
        if (resp === "DATEISSUE") {
            this._alertSer.error(MsgConfig.DATE_RANGE_ERROR);
        } else if (resp === "INVALID") {
            this._alertSer.error(MsgConfig.INVALID_DATES_ERROR);
        } else {
            this.priceUpdateData = await this._drugTabUtil.getDrugPriceUpdate(this.searchFG.value);
            if (this.priceUpdateData['count']) {
                this.isDataExists = true;
            } else {
                this.isDataExists = false;
            }
        }
    }

    async patchDefaultValHeaders() {
        const storedWJ = await this._wijimoUtils.getWJSavedData("drugPriceUpdateListWJ");
        this.wjHeaders = this._wijimoUtils.patchDefHeader("drugPriceUpdateListWJ", storedWJ);
    }

    onClickOnClear() {
        this.searchFG.patchValue({
            FromDateTime: this.today,
            ToDateTime: this.today
        });
        // this.patchDefaultValHeaders();
    }

    setPage(page: number) {
        this.pageNu = page;
        this.searchFG.controls["Pagenumber"].patchValue(this.pageNu);
        this.getPriceData();
    }
    setSize(size: any) {
        this.pageNu = this.paginationModel.pageNumber;
        this.Size = size;
        this.searchFG.controls["Pagenumber"].patchValue(this.pageNu);
        this.searchFG.controls["Pagesize"].patchValue(this.Size);
        this.getPriceData();
    }
    init(flex: wjcGrid.FlexGrid) {
        flex.columnHeaders.rows[0].wordWrap = true;
    }
    ngOnDestroy(){
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
