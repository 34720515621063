<div class="row exprx--common-block">
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 padding-sm-15">
        <div class="box-default">
            <div class="eprx--block__header">
                <div class="row">
                    <div class="col">
                        <span class="eprx--header__heading"> Drug Lot Information</span>
                    </div>
                    <div class="col pull-right text-right">
                        <button aria-hidden="true" autofocus [ngClass]="drugId ? 'hotkey_success hotkey_primary--Override' : 'inactive height_2_5rem font_weight_bolder line_height_1_5rem cursor_noDropImp'" title="{{drugId?'Add Lot':'Please enter the compound related values for the compound drug'}}" (click)="drugId? openLotAddOrEditModal(vendoritem,'LAdd'): false" appShortcutKey [AltKey]="'a'"><span *ngIf="drugId">A</span>ADD LOT</button>
                    </div>
                </div>
            </div>
            <div class="eprx--block__content">
                <div class="col-4 pl-0">
                    <app-rx-select [RxSelectId]="'bucketListFrmED12'" [LabelText]="'Inv. Bucket Name'" [PlaceHolder]="'Bucket Name'"
                        [ErrorDefs]="{required: 'Required'}" [ControlName]="'BucketId'" [FormGroupName]="BucketFG" [BindLabel]="'Name'"
                        [BindValue]="'Id'" [LabelForId]="'Id'" [nextElement]="'Automated'" [List]="drugBucketList" (TriggerSelectValue)="getDrugBucketMiscInfo(null)">
                    </app-rx-select>
                </div>
                <div class="row">
                    <div class="col">
                        <wj-flex-grid #lotList [headersVisibility]="'Column'" [itemsSource]="drugLotWJ" [isReadOnly]="true" [columnPicker]="'drugMiscInfoListWJ'"
                            [selectionMode]="'Row'" class="grid">
                            <wj-flex-grid-column [header]="'Actions'" [width]="'0.5*'">
                                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                    <div class="text-center">
                                    <i class="far fa-edit fa-sm  actions actions--Highlightfocus--icon" title="edit" (click)="openLotAddOrEditModal(vendoritem,'LEdit',item.rowId)"></i>
                                    </div>
                                </ng-template>
                            </wj-flex-grid-column>
                            <wj-flex-grid-column [header]="rxheader['hName']" [binding]="rxheader['hName']" [visible]="rxheader['isVisible']" *ngFor="let rxheader of wjHeaders; let i = index"
                                [width]="rxheader['width']">
                                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                    <div *ngIf="rxheader['hName'] === 'IsActive'" class="text-center">
                                            <input class="form-check-input" type="checkbox" [id]="item.rowId + 'l'" name="" [(ngModel)]="item.IsActive" [disabled]="!checkLotisExpired(item['Expiration Date'])"
                                            (change)="changeLotStatus($event?.target?.checked, item.rowId)" [checked]="item.IsActive && checkLotisExpired(item['Expiration Date'])" />
                                        <label class="form-check-label" [for]="item.rowId + 'l'"> </label>
                                    </div>
                                    <div *ngIf="!((rxheader['hName'] === 'Actions') || (rxheader['hName'] === 'IsActive'))">
                                        {{item[rxheader['hName']]}}
                                    </div>
                                </ng-template>
                            </wj-flex-grid-column>
                        </wj-flex-grid>
                    </div>
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <eprx-select [RxSelectId]="'Automated'" [LabelText]="'Automated Machine ID'" [PlaceHolder]="'Automated Machine ID'" [ControlName]="'AutoMcId'" [FormGroupName]="miscFG?.get('DrugMisc')"
                                [IsFirstElem]="true && !isFromCompound" [HasMultiple]="false" [BindLabel]="'Id'" [BindValue]="'Id'" [LabelForId]="'Name'" [List]="miscInfo?.AutoMc"
                                [BindLabel2]="'Name'" [ShowLabel2]="true" [Show2Values]="true" >
                                </eprx-select>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <eprx-select [LabelText]="'Inventory Location'" [PlaceHolder]="'Inventory Location'" [ControlName]="'InvLocId'" [FormGroupName]="miscFG?.get('DrugMisc')"
                                    [HasMultiple]="false" [BindLabel]="'Id'" [BindValue]="'Id'" [LabelForId]="'Name'" [List]="miscInfo?.InvLoc"
                                    [BindLabel2]="'Name'" [ShowLabel2]="true" [Show2Values]="true">
                                </eprx-select>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <label class=" col-12 padding--left__7">Unit Dose Drug?</label>
                                <eprx-radio [ControlName]="'IsUnitDrug'" [FormGroupName]="miscFG?.get('DrugMisc')" [List]="['Yes', 'No']" [ValueList]="[true, false]" [IDForList]="['yes', 'no']">
                                </eprx-radio>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <label class=" col-12 padding--left__7" title="Medication Guide Available?">Med Guide?</label>
                                <eprx-radio [ControlName]="'IsMedGuide'" [FormGroupName]="miscFG?.get('DrugMisc')" [List]="['Yes', 'No']" [ValueList]="[true, false]" [IDForList]="['1', '0']">
                                </eprx-radio>
                            </div>
                            <!-- Will use it after Robotic M/C interfaces are implemented-->
                            <!-- <div class="col-sm-12 col-sm-12 col-sm-12 col-sm-12">
                                <div class="row">
                                    <div class="col-xl-4">
                                        <eprx-input [LabelText]="'Packaging Machine Id'" [PlaceHolder]="'Add Packaging ID'" [HasControl]="false"
                                            [MaxLength]="1" (TriggerOnEnterValue)="addPkMg($event)" (TriggerOnTabbing)="addPkMg($event)">
                                        </eprx-input>
                                    </div>
                                     <div class="col-xl-8" style="margin-top: 20px">
                                        <ul>
                                            <div class="row" *ngIf="drugPkMgData && drugPkMgData.length > 0">
                                                <div class="col-sm-2 input-group" *ngFor="let drug of drugPkMgData; let i=index">
                                                    <li>{{drug?.Name}}</li>
                                                    <i class="input-group-btn far fa-trash actions" (click)="deltePkMg(i)"></i>
                                                </div>
                                            </div>
                                        </ul>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 padding-sm-15">
        <div class="box-default">
            <div class="eprx--block__header">
                <div class="eprx--header__heading">
                    Rx Related Values
                </div>
            </div>
            <div class="eprx--block__content">
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--right__7">
                        <eprx-select [LabelText]="'Dosage Form Code'" [PlaceHolder]="'Dosage Form Code'"
                            [ControlName]="'DoseFrmCodeId'" [FormGroupName]="miscFG?.get('DrugMisc')" [HasMultiple]="false"
                            [BindLabel]="'Code'" [BindValue]="'Id'" [LabelForId]="'Name'" [List]="miscInfo?.DoseFrmCode"
                            [BindLabel2]="'Name'" [ShowLabel2]="true" [Show2Values]="true">
                        </eprx-select>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--left__7">
                        <eprx-select [LabelText]="'Drug Strength Qualifier'" [PlaceHolder]="'Drug Strength Qualifier'"
                            [ControlName]="'DrugStrQualId'" [FormGroupName]="miscFG?.get('DrugMisc')" [HasMultiple]="false"
                            [BindLabel]="'Code'" [BindValue]="'Id'" [LabelForId]="'Name'" [List]="miscInfo?.DrugStrQual"
                            [BindLabel2]="'Name'" [ShowLabel2]="true" [Show2Values]="true">
                        </eprx-select>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-3 col-xs-3 padding--right__7">
                        <eprx-select [LabelText]="'Potency Unit Code'" [PlaceHolder]="'Potency Unit Code'"
                            [ControlName]="'PotencyUnitCodeId'" [FormGroupName]="miscFG?.get('DrugMisc')"
                            [HasMultiple]="false" [BindLabel]="'Code'" [BindValue]="'Id'" [LabelForId]="'Name'"
                            [List]="miscInfo?.PotencyUnitCode" [BindLabel2]="'Name'" [ShowLabel2]="true" [Show2Values]="true">
                        </eprx-select>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--left__7">
                        <eprx-select [LabelText]="'Quantity Qualifier'" [PlaceHolder]="'Quantity Qualifier'"
                            [ControlName]="'QtyQualId'" [FormGroupName]="miscFG?.get('DrugMisc')" [HasMultiple]="false"
                            [BindLabel]="'Code'" [BindValue]="'Id'" [LabelForId]="'Name'" [List]="miscInfo?.QtyQual"
                             [BindLabel2]="'Name'" [ShowLabel2]="true" [Show2Values]="true">
                        </eprx-select>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 padding-sm-15">
        <div class="box-default">
            <div class="eprx--block__header">
                <div class="eprx--header__heading">
                    Compound Related Values
                </div>
            </div>
            <div class="eprx--block__content">
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--right__7">
                        <eprx-select [RxSelectId]="'DoseFrmDesc'" [LabelText]="'Dosage Form Description'"
                            [PlaceHolder]="'Dosage Form Description'" [ControlName]="'DoseFrmDescId'"
                            [FormGroupName]="miscFG?.get('DrugMisc')" [HasMultiple]="false" [BindLabel]="'Id'" [DropdownPosition]="'top'"
                            [BindLabel2]="'Name'" [BindValue]="'Id'" [LabelForId]="'Id'" [List]="miscInfo?.DoseFrmDesc"
                            [ShowLabel2]="true" [IsFirstElem]="isFromCompound" [AutoFocus]="isFromCompound" [Show2Values]="true">
                        </eprx-select>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--left__7">
                        <eprx-select [LabelText]="'Dispensing Unit Form'" [PlaceHolder]="'Dispensing Unit Form'" [DropdownPosition]="'top'"
                            [ControlName]="'DispUnitFormId'" [FormGroupName]="miscFG?.get('DrugMisc')" [HasMultiple]="false"
                            [BindLabel]="'Id'" [BindValue]="'Id'" [LabelForId]="'Name'" [List]="miscInfo?.DispUnitForm"
                            [BindLabel2]="'Name'" [ShowLabel2]="true" [Show2Values]="true">
                        </eprx-select>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-3 col-xs-3 padding--right__7">
                        <eprx-select [LabelText]="'Route of Administration'" [PlaceHolder]="'Route of Administration'" [DropdownPosition]="'top'"
                            [ControlName]="'AdmnstrnRouteId'" [FormGroupName]="miscFG?.get('DrugMisc')" [HasMultiple]="false"
                            [BindLabel]="'Code'" [BindValue]="'Id'" [LabelForId]="'Name'" [List]="miscInfo?.AdmnstrnRoute"
                            [BindLabel2]="'Name'" [ShowLabel2]="true" [Show2Values]="true">
                        </eprx-select>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--left__7">
                        <eprx-select [LabelText]="'Compound Type'" [PlaceHolder]="'Compound Type'" [DropdownPosition]="'top'"
                            [ControlName]="'CompoundTypId'" [FormGroupName]="miscFG?.get('DrugMisc')" [HasMultiple]="false"
                            [BindLabel]="'Code'" [BindValue]="'Id'" [LabelForId]="'Name'" [List]="miscInfo?.CompoundTyp"
                            [BindLabel2]="'Name'" [ShowLabel2]="true" [Show2Values]="true" [show2ValInInputhidden]="true">
                        </eprx-select>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--right__7">
                        <eprx-select [LabelText]="'Route of Administration(SNOMED)'" [PlaceHolder]="'Route of Administration(SNOMED)'"
                            [ControlName]="'AdmnstrnRoute2Id'" [FormGroupName]="miscFG?.get('DrugMisc')" [HasMultiple]="false" [DropdownPosition]="'top'"
                            [BindLabel]="'Code'" [BindValue]="'Id'" [LabelForId]="'Name'" [List]="miscInfo?.AdmnstrnRoute2"
                            [BindLabel2]="'Name'" (keydown.tab)="focusToSave($event)" [ShowLabel2]="true" [Show2Values]="true" [show2ValInInputhidden]="true">
                        </eprx-select>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #vendoritem let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">Drug Lot</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click');closeModal()">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col">
                <eprx-input [AutoFocus]="true" [RxSelectId]="'LotNumPop'" [LabelText]="'Lot# :'" [PlaceHolder]="'Lot#'" [IsRequired]="true" [ControlName]="'LotNum'" [FormGroupName]="drugLotFG"
                [InputType]="'ALPHANUMERIC'" [MaxLength]="30" [FormGroupInvalid]="lotfgInvalif" [ErrorDefs]="{required: 'Required'}">
                </eprx-input>
            </div>
            <div class="col">
                <eprx-date-picker [LabelText]="'Expiration Date :'" [PlaceHolder]="'Expiration Date'"  [ControlName]="'ExpDt'" [FormGroupName]="drugLotFG"
                  [RxSelectId]="'ExpirationDateLot'"  [MinDate]="minDate" [MaxDate]="" [ErrorDefs]="{required: 'Required'}" [IsRequired]="true" [FormGroupInvalid]="lotfgInvalif">
                </eprx-date-picker>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="text-right pull-right col-md-10 padding-0">
            <div>
                <button class="hotkey_success" (click)="addorUpdateData()" *ngIf="(activeModal === 'LAdd')" appShortcutKey [AltKey]="'s'"><span>S</span>Save</button>
                <button class="hotkey_success" (click)="addorUpdateData()" *ngIf="(activeModal !== 'LAdd')"   appShortcutKey [AltKey]="'u'"><span>U</span>Update</button>
                <button class="hotkey_success" (click)="d('Cross click');closeModal()" appShortcutKey [AltKey]="'c'"><span>C</span>Cancel </button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #warning let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">Warning!!</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click');afterLotWarnClose()">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        Same Drug Lot# with Expiration Date already exists.
    </div>
    <div class="modal-footer">
        <div class="text-right pull-right col-md-10 padding-0">
            <div>
                <button class="hotkey_primary" (click)="d('Cross click');afterLotWarnClose()" appShortcutKey InputKey="o"><b>O</b>Ok </button>
            </div>
        </div>
    </div>
</ng-template>
