
<div class="modal-header">Inventory Transaction Audit Report</div>
<button type="button" [tabindex]="-1" class="close" (click)="closeModal()" aria-label="Close">
<span aria-hidden="true" class="close-button">&times;</span>
</button>
<div class="modal-body">
<div class="eprx--block__content">
    <div class="col-sm-12 padding-0 erx--body">
        <div class="row">
            <div class="col-lg-2 col-md-3 col-sm-2 col-xs-2">
                <eprx-date-picker [RxSelectId]="'datePick'" [LabelText]="'From Date'" [PlaceHolder]="''" [ControlName]="'FromDate'" [FormGroupName]="InvAuditFG" [AutoFocus]="true"
                    [MinDate]="" [MaxDate]="" [MarkAsTouched]="InvAuditFG?.controls?.FromDate?.touched" >
                </eprx-date-picker>
            </div>
            <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                <eprx-date-picker [LabelText]="'To Date'" [PlaceHolder]="''" [ControlName]="'ToDate'" [FormGroupName]="InvAuditFG" [MinDate]=""
                    [MaxDate]=""   [MarkAsTouched]="InvAuditFG?.controls?.ToDate?.touched">
                </eprx-date-picker>
            </div>
            <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                <eprx-select [LabelText]="'Bucket'" id="bucket" [FormGroupName]="InvAuditFG" [ControlName]="'BucketId'"
                [BindLabel]="'Name'" [BindValue]="'Id'" [isFocusFirst]=true [List] = "BucketList" ></eprx-select>
            </div>
            <div class="'col-lg-2 col-md-2 col-sm-2 col-xs-2 align-button text-right allign-bottom'">
                <button title="Search / Refresh" type="submit" class="hotkey_success pull-right" (click)="searchData()" appShortcutKey [AltKey]="'f'">
                    <span>F</span> Search</button>
                <button type="button" class="hotkey_success pull-right" (click)="clearData()" appShortcutKey [AltKey]="'l'">
                    <span>L</span> clear
                </button>
            </div>
        </div>
    </div>
</div>
<div class="eprx--block__content erx--body height_48rem" >
    <div class="exprx--common-block">
    <div class="row">
        <div class="col-6 height_24rem">
            <div class="box box-default box-solid">
                <div class="eprx--block__header">
                    <span class="eprx--header__heading">
                       Manage Inventory
                    </span>
                </div>
                <div class="box-body insurance--body height_20rem">
                    <wj-flex-grid #manageInventory [headersVisibility]="'Column'" [isReadOnly]="true" [itemsSource]="manageInvWJ" [columnPicker]="'manageInvwj'" [selectionMode]="'None'" [ngClass]="!isDataExists ? 'no-data':'height_15rem'"
                       >
                        <wj-flex-grid-column  [header]="rxheader['hName']" [binding]="rxheader['hName']"
                        [visible]="rxheader['isVisible']" *ngFor="let rxheader of manageInv; let i = index"
                        [width]="rxheader['width']"
                        >
                            <ng-template wjFlexGridCellTemplate [cellType]="'ColumnHeader'" let-item="item">
                                <div title={{rxheader.hName}}>
                                    {{ rxheader.hName }}
                                </div>
                            </ng-template>
                        </wj-flex-grid-column>
                        <app-no-data-found *ngIf="!isDataExists"></app-no-data-found>
                    </wj-flex-grid>
                     <div  *ngIf="(invTransAudit?.manageInventoryAudits?.length)" class="col-12">
                    <app-eprx-pagination [InvAuditPayload]="InvAuditpayload"  (OnPageSelected)="setPage($event,'manageInvAudit')" (OnSizeSelected)="setSize($event,'manageInvAudit')"
                    [PageSize] = "manageInvFG.controls?.manageInvPageSize?.value" [TotalCount]="manageInventoryAuditsLength" [GridName]="'manageInvAudit'" [AltShortCutKey]="'p'" [IsShortCutKey]="'P'" [HasPrint]="true" [HasExport]="true" ></app-eprx-pagination>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-6 height_24rem">
            <div class="box box-default box-solid">
                <div class="eprx--block__header">
                    <span class="eprx--header__heading">
                        Physical Inventory
                    </span>
                </div>
                <div class="box-body insurance--body height_20rem">
                    <wj-flex-grid #physicalInventory [headersVisibility]="'Column'"  [isReadOnly]="true" [itemsSource]="physicalInvWJ"  [columnPicker]="'physicalInvWj'" [selectionMode]="'None'"
                        [ngClass]="!isDataExists1 ?'no-data':'height_15rem'">
                        <wj-flex-grid-column   [header]="rxheader['hName']" [binding]="rxheader['hName']"
                        [visible]="rxheader['isVisible']" *ngFor="let rxheader of physicInv; let i = index"
                        [width]="rxheader['width']">
                        <ng-template wjFlexGridCellTemplate [cellType]="'ColumnHeader'" let-item="item">
                        <div title={{rxheader.hName}}>
                            {{ rxheader.hName }}
                        </div>
                        </ng-template>
                        </wj-flex-grid-column>
                        <app-no-data-found *ngIf="!isDataExists1"></app-no-data-found>
                    </wj-flex-grid>

                     <div *ngIf="(invTransAudit?.physicalInventoryAudits?.length)" class="col-12">
                        <app-eprx-pagination [TotalCount]="physicalInventoryAuditsLenght"  (OnPageSelected)="setPage($event,'physicalInvAudit')" [AltShortCutKey]="'t'" [IsShortCutKey]="'T'" (OnSizeSelected)="setSize($event,'physicalInvAudit')" [PageSize]="physicalInvFG.controls?.PhysicalInvPageSize?.value" [GridName]="'physicalInvAudit'" [HasPrint]="true" [HasExport]="true" [InvAuditPayload]="InvAuditpayload" ></app-eprx-pagination>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6 height_20rem" >
                <div class="box box-default box-solid">
                    <div class="eprx--block__header">
                        <span class="eprx--header__heading">
                            Receive Inventory
                        </span>
                    </div>
                    <div class="box-body insurance--body height_18rem">
                        <wj-flex-grid #receiveInventory [headersVisibility]="'Column'" [isReadOnly]="true" [itemsSource]="receiveInvWJ" [columnPicker]="'receiveInvWj'" [selectionMode]="'None'"
                        [ngClass]="!isDataExists2 ?'no-data':'height_13rem'">
                            <wj-flex-grid-column [header]="rxheader['hName']" [binding]="rxheader['hName']"
                            [visible]="rxheader['isVisible']" *ngFor="let rxheader of receiveInv; let i = index"
                            [width]="rxheader['width']">
                                <ng-template wjFlexGridCellTemplate [cellType]="'ColumnHeader'" let-item="item">
                                    <div title={{rxheader.hName}}>
                                        {{ rxheader.hName }}
                                    </div>
                                </ng-template>
                            </wj-flex-grid-column>
                            <app-no-data-found *ngIf="!isDataExists2"></app-no-data-found>
                        </wj-flex-grid>
                        <div  *ngIf="(invTransAudit?.recvInventoryAudits?.length)"  class="col-12">
                            <app-eprx-pagination [InvAuditPayload]="InvAuditpayload"  (OnPageSelected)="setPage($event,'receiveInvAudit')" [AltShortCutKey]="'r'" [IsShortCutKey]="'R'" (OnSizeSelected)="setSize($event,'receiveInvAudit')" [PageSize]="receiveInvFG.controls?.RecvInvPageSize?.value"  [TotalCount]="recvInventoryAuditsLength" [GridName]="'receiveInvAudit'"  [HasPrint]="true" [HasExport]="true" ></app-eprx-pagination>
                        </div>
                    </div>
                </div>
            </div>

        <div class="col-6 height_20rem">
            <div class="box box-default box-solid">
                <div class="eprx--block__header">
                    <span class="eprx--header__heading">
                        Dispensed Inventory
                    </span>
                </div>
                <div class="box-body insurance--body height_18rem" >
                    <wj-flex-grid #dispensedInv [headersVisibility]="'Column'" [ngClass]="!isDataExists3 ?'no-data':'height_13rem'" [isReadOnly]="true" [itemsSource]="dispensedInvWJ" [columnPicker]="'dispensedInvWj'" [selectionMode]="'None'"
                         >
                        <wj-flex-grid-column [header]="rxheader['hName']" [binding]="rxheader['hName']"
                        [visible]="rxheader['isVisible']" *ngFor="let rxheader of dispensedInvArray; let i = index"
                        [width]="rxheader['width']"
                            >
                            <ng-template wjFlexGridCellTemplate [cellType]="'ColumnHeader'" let-item="item">
                                <div title={{rxheader.hName}}>
                                    {{ rxheader.hName }}
                                </div>
                            </ng-template>
                        </wj-flex-grid-column>
                        <app-no-data-found *ngIf="!isDataExists3"></app-no-data-found>
                    </wj-flex-grid>
                   <div  *ngIf="(invTransAudit?.dispInventoryAudits?.length)" class="col-12">
                        <app-eprx-pagination [InvAuditPayload]="InvAuditpayload"  (OnPageSelected)="setPage($event,'dispensedInvAudit')" [AltShortCutKey]="'i'" [IsShortCutKey]="'I'" (OnSizeSelected)="setSize($event,'dispensedInvAudit')" [PageSize]="dispensedInvFG?.controls?.DispPageSize?.value" [TotalCount]="dispInventoryAuditLength" [HasExport]="true"  [GridName]="'dispensedInvAudit'" [HasPrint]="true" ></app-eprx-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
</div>
<div class="modal-footer">
    <button class="hotkey_success"  appShortcutKey
        [AltKey]="'c'" (click)="closeModal()"><span>C</span>Close
    </button>
</div>
