import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    HostListener
} from "@angular/core";
import {
    PatientInfo,
    searchCriteria,
    DiagnosisList,
    KeyCodes,
    PaginationModel
} from "src/app/models";
import { CollectionView } from "@grapecity/wijmo";
import { PatientService, AlertService } from "src/app/services";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as wjcGrid from "@grapecity/wijmo.grid"
import { FormGroup } from "@angular/forms";
import { PatientStore } from "src/app/store";
import { CreateNPatchFG } from "src/app/utils";
import { PatientInfoUtil } from "src/app/utils/patient-info.util";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
    selector: "app-diag-add",
    templateUrl: "./diag-add.component.html"
})
export class PDiagAddComponent implements OnInit {
    search: searchCriteria;
    WholeDiagnosis: DiagnosisList;
    diagnosisWJ: CollectionView;
    totalCount: any;
    modalType: string;
    modelRef: any;
    paginationModel: PaginationModel = new PaginationModel();
    pageNumber = this.paginationModel.pageNumber;
    pageSize = this.paginationModel.pageSize;
    diagnosisListWJ: wjcGrid.FlexGrid;
    unsubscribe$: Subject<void> = new Subject();

    @Input() patientInfo: PatientInfo;
    @Input() patientInfoFG: FormGroup;

    @ViewChild("diagnosisList", { static: true })
    diagnosisList: any;

    @Output()
    EmitDiagnosis = new EventEmitter<PatientInfo>();

    @HostListener("window:keydown", ["$event"])
    keyEventEsc(event: KeyboardEvent) {
        if (event.which === 27 && this._modalService.hasOpenModals()) {
            // esc
            if (this.modelRef) {
                this.closeDiagonisModal();
                event.preventDefault();
            }
        }
    }

    constructor(
        private _editPatientService: PatientService,
        private _patStore: PatientStore,
        private _modalService: NgbModal,
        private _alertService: AlertService,
        private _cnpFG: CreateNPatchFG,
        private _patUtils: PatientInfoUtil
    ) {
        this.search = {
            Code: null,
            Description: null,
            pageNum: this.pageNumber,
            pageSize: this.pageSize
        };
    }

    ngOnInit() {
        this.getWholeDiagnosis();
        this._patStore.isModalTriggered$.pipe(takeUntil(this.unsubscribe$)).subscribe(resp => {
            if (resp === "DIAGNOSIS") {

                this.openPopUpModal();
            }
        });
    }

    getWholeDiagnosis() {
        this._editPatientService
            .getDiagnosisFiltered(this.search).pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                this.WholeDiagnosis = resp;
                this.generateDiagnosisWJ(resp);
            });
    }

    generateDiagnosisWJ(diagnosisList) {
        this.totalCount = diagnosisList.TotalRecords;
        this.diagnosisWJ = new CollectionView(
            diagnosisList.TempDiagnosis.map((diagnosis, i) => {
                let k = {};
                k["patientId"] = null;
                k["diagnosisId"] = diagnosis.DIAGCODE;
                k["Diagnosis Description"] = diagnosis.DIAGDESCR;
                k["isActive"] = true;
                k["IsSelected"] = false;
                k["Type"] = "ICD10";
                return k;
            })
        );
    }

    preventSpaceEvent(event) {
        event.preventDefault();
    }

    changeDiognasis(item, event) {
        if (
            this.patientInfo.PatientDiagnosis &&
            (!item["IsSelected"] || (event && event.target.checked))
        ) {
            if (
                this.patientInfo.PatientDiagnosis.findIndex(
                    elm => elm.DiagnosisId === item.diagnosisId
                ) === -1
            ) {
                item["IsSelected"] = !item["IsSelected"];
            } else {
                item["IsSelected"] = false;
                if (event) {
                    event.preventDefault();
                }
                this._alertService.error(
                    "Diognasis already exists. Please select another one."
                );
            }
        } else {
            item["IsSelected"] = !item["IsSelected"];
        }
    }

    addSelectdItems() {
        let itemsToAdd = this.diagnosisWJ._src.filter(elmm => elmm.IsSelected);
        //need to implement save logic
        itemsToAdd.forEach(selectedItem => {
            const data = [
                {
                    diagnosisId: selectedItem.diagnosisId,
                    diagnosisDesc: selectedItem["Diagnosis Description"]
                }
            ];
            this.addDiagnosisToLocalStorage(data);
        });
        this.closeDiagonisModal();
        this._patStore.clearTriggerModalTypeToShow();
    }

    setPage(page: number) {
        this.pageNumber = page;
        this.search.pageNum = this.pageNumber;
        this.getWholeDiagnosis();
    }
    setPageSize(size: number) {
        this.pageSize = size;
        this.search.pageNum = this.paginationModel.pageNumber;
        this.search.pageSize = size;
        this.getWholeDiagnosis();
    }

    openPopUpModal() {
        this.diagnosisWJ._src.forEach(elmm => (elmm.IsSelected = false));
        this.modalType = "diogList";
        this.modelRef = this._modalService.open(this.diagnosisList, {
            size: "lg",
            keyboard: false
        });
    }

    closeDiagonisModal() {
        if(this.modelRef) {
            this.modelRef.close();
              this.modelRef = null;
        }
        this.modalType = null;
        this.EmitDiagnosis.emit(this.patientInfo);
    }

    getCodeFilteredData(code) {
        if (code) {
            this.search.Code = code;
            this.getWholeDiagnosis();
        } else {
            this.search.Code = null;
            this.getWholeDiagnosis();
        }
    }

    getDescriptionFilteredData(desc) {
        if (desc) {
            this.search.Description = desc;
            this.getWholeDiagnosis();
        } else {
            this.search.Description = null;
            this.getWholeDiagnosis();
        }
    }

    focusToList(event) {
        event.preventDefault();
        this.diagnosisListWJ.focus();
    }

    onEnterDiag(grid, eve) {
        if (eve.which === 13) {
            if (
                this._modalService.hasOpenModals() &&
                this.modalType === "diogList"
            ) {
                eve.preventDefault();
                this.addDiagnosisList(grid.selectedItems[0]);
            }
        } else if (eve.which === 9) {
            if (
                this._modalService.hasOpenModals() &&
                this.modalType === "diogList"
            ) {
                // eve.preventDefault();
                setTimeout(() => {
                    if (document.getElementById("diogAddBtn")) {
                        document.getElementById("diogAddBtn").focus();
                    }
                }, 0);
            }
        }
    }

    init(flex: wjcGrid.FlexGrid) {
        this.diagnosisListWJ = flex;
        flex.columnHeaders.rows[0].wordWrap = true;
    }

    onRowDblclicked(s: any, e: any) {
        const ht = s.hitTest(e);
        const selectedItem = s.selectedRows[0].dataItem;
        if (selectedItem && ht.cellType === 1) {
            this.addDiagnosisList(selectedItem);
        }
    }

    focusToFirst(event) {
        event.preventDefault();
        if (document.getElementById("Code")) {
            document.getElementById("Code").focus();
        }
    }

    addDiagnosisList(selectedItem) {
        if (
            this.patientInfo.PatientDiagnosis &&
            this.patientInfo.PatientDiagnosis.length > 0
        ) {
            const alreadyExist = this.patientInfo.PatientDiagnosis.filter(
                val => val.DiagnosisId === selectedItem.diagnosisId
            );
            if (alreadyExist && alreadyExist.length > 0) {
                this._alertService.error(
                    "Diagnosis already exists, please select another one."
                );
            } else {
                const data = [
                    {
                        diagnosisId: selectedItem.diagnosisId,
                        diagnosisDesc: selectedItem["Diagnosis Description"]
                    }
                ];
                this.addDiagnosisToLocalStorage(data);
                this._alertService.success("Diagnosis added successfully.");
            }
        } else {
            const data = [
                {
                    diagnosisId: selectedItem.diagnosisId,
                    diagnosisDesc: selectedItem["Diagnosis Description"]
                }
            ];
            this.addDiagnosisToLocalStorage(data);
        }
    }

    addDiagnosisToLocalStorage(item) {
        const patient_diagonsis = {
            Id: null,
            TenantId: null,
            PatientId: null,
            DiagnosisId: item.length
                ? item[item.length - 1].diagnosisId
                : item.diagnosisId,
            IsActive: true,
            CreatedDtTm: null,
            ModifiedDtTm: null,
            Description: item.length
                ? item[item.length - 1].diagnosisDesc
                : item.diagnosisDesc
        };
        const patientData = this.patientInfoFG.getRawValue();

        if (
            !patientData.PatientDiagnosis ||
            patientData.PatientDiagnosis.length < 0
        ) {
            patientData.PatientDiagnosis = [];
        }
        if (patientData.PatientDiagnosis) {
            patientData.PatientDiagnosis.push(patient_diagonsis);
        }

        if (this.patientInfo) {
            this._patStore.storePatientInfo(Object.assign({}, patientData));
        }
        this._patUtils.patchPatientInfo(patientData, this.patientInfoFG);
    }

    deleteSelectedDiag(code) {
        const diag: any = this.patientInfo.PatientDiagnosis.filter(
            (n: any) => n.DiagnosisId !== code
        );
        this.patientInfo.PatientDiagnosis = diag;
        this._patUtils.patchPatientInfo(this.patientInfo, this.patientInfoFG);
    }
    ngOnDestroy(){
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
