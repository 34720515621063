<div class="newrx--body merge--body">
    <div class="editpatient">
        <div class="row content--heading">
            <div class="col-md-8 padding-0">
                <div class="row">
                    <div class="icon">
                        <img src="assets/dist/Icons/Icon_rx2.png">
                    </div>
                    <div class="col-md-11 padding-0">
                        <div class="content--heading__title padding-0">Merge Patient
                            <span class="content--name"></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-right pull-right col-md-4 padding-0">
                <button class="hotkey_success" (click)="openModal(content)" *ngIf="!cantMerge"
                (keydown.enter)="openModal(content) ; keytab($event,'Merge')" tabindex="3" id="Merged"><span>M</span> Merge</button>
                <button class="hotkey_success background_light-gray border_color_gray" *ngIf="cantMerge"
                tabindex="-1" id="Merge">Merge</button>
                <button class="hotkey_success mr-0" (click)="routeBackToPrevScreen()"
                (keydown.enter)="routeBackToPrevScreen() ; keytab($event, 'Cancel')" tabindex="4" id="Cancel"><span>C</span> Cancel</button>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 padding--right__7 ">
            <div class="exprx--common-block">
                <div class="eprx--block__header">
                    <div class="eprx--header__heading">
                            <span class="title" *ngIf="isdisable"> Select Patient1 </span>
                        <span class="title" *ngIf="!isdisable"> Patient - {{patientData?.Person?.LastName}},&nbsp;{{patientData?.Person?.FirstName}} </span>
                        <ul class="pull-right eprx--header__icons" *ngIf="!isdisable">
                            <li>
                                <a (click)="resetPatient1()">
                                    <i class="far fa-undo fa-lg" aria-hidden="true"></i>
                                </a>
                            </li>
                        </ul>
                    </div>

                </div>
                <div class="seconday-search--grid" *ngIf="isdisable">
                    <app-searchbox-global  id="patSearch1" [RxSelectId]="'PatSearch1'" tabindex="1" [TabIndex]="1" (OnEnter)="keytab($event, patient1Id)"
                    #PatSearch1 [SearchFor]="'Patient'" [IsAddNew]="false" [DisableSerchFil]="true" [DisableRadioButtons]="true" (SelectedPatient)="patientInfo1($event)"></app-searchbox-global>
                </div>
                <div class="eprx--block__content" *ngIf="!isdisable">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <label>RxCount</label>
                            <div class="label--data">&nbsp; {{patientData?.RxCount ? patientData?.RxCount : "0"}}</div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 ">
                            <label>Gender</label>
                            <div class="label--data">&nbsp;{{patientData?.Person?.GenderId === 2 ? "male" : patientData?.Person?.GenderId === 3 ?
                                "female" : "--"}} </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <label>DOB</label>
                            <div class="label--data">&nbsp;{{patientData?.Person?.BirthDt  | date : 'MM/dd/yyyy'  : "--" }}</div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <label>Insurance Id</label>
                            <div class="label--data">&nbsp;{{patientData?.Insurances?.InsurerId ? patientData?.Insurances?.InsurerId : "--"}}</div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <label>Ins.Type</label>
                            <div class="label--data">&nbsp;{{patientData?.InsuPlans?.PlanCode ? patientData?.InsuPlans?.PlanCode : "--"}}</div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <label>Group#</label>
                            <div class="label--data">&nbsp; --</div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <label>Phone Number</label>
                            <div class="label--data">&nbsp; {{patientData?.Phones ? patientData?.Phones[0]?.AreaCode : ""}} {{patientData?.Phones
                                ? patientData?.Phones[0]?.xPhone : "" }}</div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <label>Address</label>
                            <div class="label--data">&nbsp;{{patientData?.Addresses?.AddressLine1 ? patientData?.Addresses?.AddressLine1+"," : "--" }}
                                {{patientData?.Addresses?.CityName ? patientData?.Addresses?.CityName+"," : ""}}
                                {{patientData?.Addresses?.StateName ? patientData?.Addresses?.StateName+"," : ""}}
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <label>RxList</label>
                            <wj-flex-grid #patientHistory [headersVisibility]="'Column'" [itemsSource]="patient2RxHistoryWJ" [isReadOnly]="true" [columnPicker]="''"
                                (updatedView)="init(patientHistory)" *ngIf="hasPatientHistory === true && !(patientData?.RxCount === 0)"
                                class="grid">
                                <wj-flex-grid-column [header]="actvHeader" [binding]="actvHeader" [visible]="true" *ngFor="let actvHeader of actvHeaders; let i = index"
                                    [width]="(actvHeader === 'Rx#')||(actvHeader === 'Ref No')||(actvHeader === 'Qty')||(actvHeader === 'SDays') ? 80 : (actvHeader === 'Fill Date')||(actvHeader === 'Insurance') ? 120 : 300">
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="inActvHeader" [binding]="inActvHeader" [visible]="false" *ngFor="let inActvHeader of inActvHeaders; let i = index"
                                    [format]="'d'">
                                </wj-flex-grid-column>
                            </wj-flex-grid>
                            <div *ngIf="(hasPatientHistory === false) && patientData?.RxCount === 0" class="text-center">
                                No RX History exists..
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 padding--right__7" *ngIf="patientData">
            <div class="exprx--common-block">
                <div class="eprx--block__header">
                    <div class="eprx--header__heading">
                            <span class="title" *ngIf="!hasPatient2D"> Select Patient2</span>
                        <span class="title" *ngIf="hasPatient2D">Patient - {{patient2D?.lastname}},&nbsp;{{patient2D?.firstname}}</span>
                        <ul class="pull-right eprx--header__icons">
                            <li>
                                <a  (click)="resetPatient2()" *ngIf="hasPatient2D">
                                    <i class="far fa-undo fa-lg" aria-hidden="true"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="seconday-search--grid" *ngIf="!hasPatient2D">
                    <app-searchbox-global  id="patSearch2" [RxSelectId]="'PatSearch2'"  [TabIndex]="2" (OnEnter)="keytab($event, patient2Id)"
                    tabindex="2" #PatSearch2 [SearchFor]="'Patient'" [IsAddNew]="false" [DisableSerchFil]="true" [DisableRadioButtons]="true" (SelectedPatient)="patientInfo2($event , error)"></app-searchbox-global>
                </div>
                <div class="eprx--block__content" *ngIf="hasPatient2D">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <label>RxCount</label>
                            <div class="label--data"> {{patient2Data?.RxCount ? patient2Data?.RxCount : "0"}}</div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 ">
                            <label>Gender</label>
                            <div class="label--data"> {{patient2Data?.Person?.GenderId === 2 ? "male" : patient2Data?.Person?.GenderId === 3 ?
                                "female" : "--"}} </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <label>DOB</label>
                            <div class="label--data">{{patient2Data?.Person?.BirthDt  | date : 'MM/dd/yyyy' : "--"}}</div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <label>Insurance Id</label>
                            <div class="label--data">{{patient2Data?.Insurances?.InsurerId ? patient2Data?.Insurances?.InsurerId : "--"}}</div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <label>Ins.Type</label>
                            <div class="label--data">{{patient2Data?.InsuPlans?.PlanCode ? patient2Data?.InsuPlans?.PlanCode : "--" }}</div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <label>Group#</label>
                            <div class="label--data"> -- </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <label>Phone Number</label>
                            <div class="label--data">{{patient2Data?.Phones ? patient2Data?.Phones[0]?.AreaCode : "--"}} {{patient2Data?.Phones
                                ? patient2Data?.Phones[0]?.xPhone : "--" }} </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <label>Address</label>
                            <div class="label--data">{{patient2Data?.Addresses?.AddressLine1 ? patient2Data?.Addresses?.AddressLine1+"," : "" }} ,
                                {{patient2Data?.Addresses?.CityName ? patient2Data?.Addresses?.CityName+"," : ""}}
                                {{patient2Data?.Addresses?.StateName ? patient2Data?.Addresses?.StateName+"," : ""}}</div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <label>RxList</label>
                            <wj-flex-grid #patientHistory [headersVisibility]="'Column'" [itemsSource]="patientRxHistoryWJ" [isReadOnly]="true" [columnPicker]="''"
                                (updatedView)="init(patientHistory)"  *ngIf="hasPatient2 === true && !(patient2Data?.RxCount === 0)"
                                class="grid">
                                <wj-flex-grid-column [header]="actvHeader" [binding]="actvHeader" [visible]="true" *ngFor="let actvHeader of actvHeaders; let i = index"
                                    [width]="(actvHeader === 'Rx#')||(actvHeader === 'Ref No')||(actvHeader === 'Qty')||(actvHeader === 'SDays') ? 80 : (actvHeader === 'Fill Date')||(actvHeader === 'Insurance') ? 120 : 300">
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="inActvHeader" [binding]="inActvHeader" [visible]="false" *ngFor="let inActvHeader of inActvHeaders; let i = index"
                                    [format]="'d'">
                                </wj-flex-grid-column>
                            </wj-flex-grid>
                            <div *ngIf="patient2Data?.RxCount === 0" class="text-center">
                                No RX History exists..
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #content let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Message</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>Are you sure you want to Delete  {{patient1?.lastname}},&nbsp;{{patient1?.firstname}} and copy the prescription
            over to  {{patient2D?.lastname}},&nbsp;{{patient2D?.firstname}}?</p>
    </div>
    <div class="modal-footer">
        <button class="hotkey_primary" (click)="mergePatient(success)" (click)="c('Close click')" appShortcutKey InputKey="y"><b>Y</b> YES</button>
        <button type="button" class="hotkey_primary" (click)="c('Close click')" appShortcutKey InputKey="o"><b>C</b>Cancel</button>
    </div>
</ng-template>
<ng-template #error let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Warning!!</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>Same patients cannot be selected for merging </p>
    </div>
    <div class="modal-footer">

        <button type="button" class="hotkey_primary" (keyup.enter)="c('Close click')" (click)="c('Close click')" appShortcutKey InputKey="c"><b>C</b>Cancel</button>
    </div>
</ng-template>
<ng-template #success let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">success</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>  {{patient1?.lastname}},&nbsp;{{patient1?.firstname}} has been deleted and added in {{patient2D?.lastname}},&nbsp;{{patient2D?.firstname}} </p>
    </div>
    <div class="modal-footer">

        <button type="button" class="hotkey_primary" (keyup.enter)="c('Close click')" (click)="c('Close click')" appShortcutKey InputKey="c"><b>C</b>Cancel</button>
    </div>
</ng-template>
