<div class="exprx--common-block" *ngIf="!openModal">
    <div class="eprx--block__header">
        <div class="eprx--header__heading">
            Patient Allergies
            <ul class="pull-right eprx--header__icons">
                <li>
                    <a (click)="openAllergiesPopUp()">
                        <button class="hotkey_success hotkey_primary--Override" title="Alt+A" appShortcutKey
                            [AltKey]="'a'"><span>A</span>ADD ALLERGIES</button>
                    </a>
                </li>
            </ul>
        </div>
    </div>
    <div class="eprx--block__content">
        <div class="box-body table-responsive overflow_y_auto max-height_10rem">
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th>Code</th>
                        <th class="width_40per">Description</th>
                        <th>Type</th>
                        <th>Last Confirmed Date</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let alergies of this.patientFG.value['PatAllergies']; let i=index">
                        <td>
                            {{alergies?.AllergyClassID}}
                        </td>
                        <td [ngClass]="alergies?.AllergyDescription !== 'No Known Allergies' ? 'allergy-color' : ''">
                            {{alergies?.AllergyDescription | uppercase}}
                        </td>
                        <td> A</td>
                        <td>{{alergies?.LstConfDttm | date: "MM/dd/yyyy"}}</td>
                        <td>
                            <a (click)="openDeletePopup(alergies?.AllergyClassID,'A')">
                                <!-- <i class="far fa-trash-alt danger-actve" aria-hidden="true"></i> -->
                                <i class="far fa-trash-alt danger fa-lg actions danger-actve" aria-hidden="true"></i>
                            </a>
                        </td>
                    </tr>
                    <tr *ngFor="let drugAllergy of this.patientFG.value['Drugs']; let i=index">
                        <td>
                            {{drugAllergy?.DrugCode}}
                        </td>
                        <td class="allergy-color">
                            {{drugAllergy?.Name | uppercase }}
                        </td>
                        <td> D</td>
                        <td>{{drugAllergy?.LstConfDttm | date: "MM/dd/yyyy"}}</td>
                        <td>
                            <a (click)="openDeletePopup(drugAllergy?.DrugCode,'D')">
                                <!-- <i class="far fa-trash-alt danger-actve" aria-hidden="true"></i> -->
                                <i class="far fa-trash-alt danger fa-lg actions danger-actve" aria-hidden="true"></i>
                            </a>
                        </td>
                    </tr>
                    <tr *ngFor="let alergies of this.patientFG.value['OtherAllergies']; let i=index">
                        <td>
                            {{alergies?.Id}}
                        </td>
                        <td class="allergy-color">
                            {{alergies?.Name | uppercase}}
                        </td>
                        <td>O</td>
                        <td>{{alergies?.LstConfDttm | date: "MM/dd/yyyy"}}</td>
                        <td>
                            <a (click)="openDeletePopup(alergies?.Name, 'O')">
                                <!-- <i class="far fa-trash-alt danger-actve" aria-hidden="true"></i> -->
                                <i class="far fa-trash-alt danger fa-lg actions danger-actve" aria-hidden="true"></i>
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<ng-template #allergey let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title"> Patient Allergies</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')"
            (click)="c('Close click'); closeModal()">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body edit-patient">
        <div class="eprx--block__content erx--body">
            <div class="row">
                <div class="col-6">
                    <eprx-input [RxSelectId]="'Allergy Name'" [LabelText]="'Allergy Name'"
                        [PlaceHolder]="'Allergy Name'" [AutoFocus]="true" [HasControl]="false"
                        (keydown.tab)="focusOutFromAllergySearch($event)"
                        (TriggerOnChange)="filterAllergy($event.target.value)"></eprx-input>
                    <wj-flex-grid class="height_38rem" *ngIf="hasAllegry" #allergyList [headersVisibility]="'Column'"
                        [selectionMode]="'Row'" [itemsSource]="AllergyWJ" [isReadOnly]="true"
                        (dblclick)="onRowDblclicked(allergyList, $event)"
                        (initialized)="Init(allergyList)"
                        (keydown.space)="onRowDblclicked(allergyList, $event)" (updatedView)="init(allergyList)"
                        (keydown.tab)="focusOutFromAllergyWJ($event, allergyList)">
                        <wj-flex-grid-column header="" [width]="50">
                            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                <span  class="text-center">
                                    <input (focus)="$event.target.blur()" class="form-check-input" type="checkbox" [id]="'quick'+item.Code" name=""
                                        [(ngModel)]="item.IsSelected" [checked]="item.IsSelected"
                                        (change)="clickOnCheckBox($event?.target?.checked, item, $event)" />
                                    <label class="form-check-label" [for]="'quick'+item.Code">
                                    </label>
                                </span>
                            </ng-template>
                        </wj-flex-grid-column>
                        <wj-flex-grid-column [header]="allergy" [binding]="allergy" [visible]="true"
                            *ngFor="let allergy of ['Name', 'Code']; let i=index;"
                            [width]="(allergy === 'Name') ? 420 : 100">
                            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                <div>
                                    {{item[allergy]}}
                                </div>
                            </ng-template>
                        </wj-flex-grid-column>
                    </wj-flex-grid>
                    <span *ngIf="hasAllegry" class="color_orange">
                        Use Arrow keys to highlight .
                        Press Space or Enter key to select
                    </span>
                </div>
                <div class="col-6">
                    <div class="row">
                        <div class="col-12 form-group custom--input">
                            <label class="control-label seconday-search--grid">
                                <div class="row">
                                    <div class="col-4 color_cyan">
                                        Drug Name</div>
                                    <div class="col-8">
                                        <!-- <div class="text-right">
                                            <div><i class="far fa-lg fa-long-arrow-alt-right color_orange"
                                                    ></i><span> for Equv ;
                                                </span><b class="color_orange">Shift + <i
                                                        class="far fa-lg fa-long-arrow-alt-right color_orange"
                                                        ></i></b><span> for Gen Equv</span></div>
                                        </div> -->
                                    </div>
                                </div>
                                <div class="common_global_search_max_content_display_for_drug_allergy">
                                    <app-searchbox-global  [SearchFor]="'Drug'" [DisableSerchFil]="true"   [IsHideDrugFilters]="true"
                                    [ClearInputValue]="resetDrugName" [DisableRadioButtons]="true" [IsAddNew]="false" [isFrmAllergies]="isFrmAllergies"
                                    [IsFocused]="drugFocus" (SelectedDrug)="selectedDrugInfo($event)"
                                    (keydown.shift.tab)="focusOutFromAllergySearch($event)" [SearchFrm]="'commonDrugSearch'"></app-searchbox-global>
                                </div>

                            </label>
                        </div>
                        <div class="col-12 form-group custom--input">
                            <label class="control-label">
                                <span> Other Allergies </span>
                                <input class="form-control" placeholder="Add Other Allergy On Enter"
                                    (keydown.enter)="saveOtherAllergValue($event)"
                                    (keydown.tab)="focustoSelectedList($event)">
                            </label>
                            <span>
                                <span class="col-4 d-inline compound--drug-cat left_8rem top_0rem" [ngbTooltip]="compNamdeTooltp" container="body" tooltipClass="tooltip_rxTransfer_box hover--width"  triggers="mouseover:mouseleave" [autoClose]="true" placement="right">𝒊</span>
                                <ng-template #compNamdeTooltp>
                                    <div class="text-left">
                                        <p>'Other Allergies' has no clinical linkage. To indicate a drug allergy, please use the 'Drug Name' field.</p>
                                    </div>
                                </ng-template>
                            </span>
                        </div>
                        <div class="col-12">
                            <label> Allergies Selected</label>
                            <wj-flex-grid  class="height_31rem max-height_32rem" *ngIf="hasselectedList"
                                #selectedListwj [headersVisibility]="'Column'" [selectionMode]="'Row'"
                                [itemsSource]="selectedListWJ" [isReadOnly]="true"
                                (initialized)="keyEventonSelectedWJ(selectedListwj)"
                                (updatedView)="init1(selectedListwj)" (keydown.tab)="focusOutFromSelectedList($event)">
                                <wj-flex-grid-column [header]="allergy" [binding]="allergy" [visible]="true"
                                    *ngFor="let allergy of ['Code', 'Description', 'Type', 'Last Confirmed Date']; let i=index;"
                                    [width]="(allergy === 'Description') ? 285 : (allergy === 'Last Confirmed Date') ? 125 : 
                                    (allergy === 'Type') ? 40 : (allergy === 'Code') ? 65 : 20">
                                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                        <div [title]="((item[allergy]+'')|uppercase)">
                                            {{item[allergy]}}
                                        </div>
                                    </ng-template>
                                </wj-flex-grid-column>
                                <wj-flex-grid-column header="" [width]="52">
                                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item"
                                        let-cell="cell">
                                        <span  class="text-center">
                                            <a (click)="deleteSelectedAllergy(item, item.Type, 'PopUp')">
                                                <!-- <i class="far fa-trash-alt danger-actve" aria-hidden="true"></i> -->
                                              <i class="far fa-trash-alt danger fa-lg actions danger-actve"></i>  
                                            </a>
                                        </span>
                                    </ng-template>
                                </wj-flex-grid-column>
                            </wj-flex-grid>
                            <span *ngIf="hasselectedList" class="color_orange">
                                Press Enter key to delete
                            </span>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <div class="modal-footer">
        <button id="okbutton" type="button" appShortcutKey [AltKey]="'o'" class="hotkey_success"
            (click)="saveAllergies()"><span>O</span>OK</button>
        <button id="cancelbutton" type="button" appShortcutKey [AltKey]="'c'" class="hotkey_success"
            (click)="c('Close click'); closeModal()"><span>C</span>CANCEL</button>
    </div>
</ng-template>

<ng-template #delPop let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">{{warningText?.deleteHeaderTxt}}</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body edit-patient">
        <div class="row">
            <div class="col">
                <table class="table table-hover">
                    <tbody>
                        <label> {{warningText?.deleteConfrmMsg}}
                        </label>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" autofocus appShortcutKey class="hotkey_primary" (click)="c('Close click')"
            (click)="deleteSelectedAllergy(code,typeForPDO)" InputKey="y"><b>Y</b> YES
        </button>
        <button type="button" appShortcutKey InputKey="n"  class="hotkey_primary" (click)="c('Close click')">
          <b>N</b>  NO
        </button>
    </div>
</ng-template>


