import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from "@angular/core";
import { SystemData } from "src/app/models";
import { DrugUtils, CommonUtil,WijimoUtil } from "src/app/utils";
import {AlertService, CommonService, DrugService} from "src/app/services";
import { FormBuilder, FormGroup } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as wjcCore from "@grapecity/wijmo";
import { MsgConfig } from "src/app/app.messages";
import * as moment from "moment";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";


@Component({
    selector: "app-drug-pricing",
    templateUrl: "./drug-pricing.component.html"
})
export class DrugPricingComponent implements OnInit {

    @Input() drugInfoFG: FormGroup;
    @Input() drugInfo: any;
    @Input() drugId: any;
    @Input() systemData: SystemData;
    @Input() priceSchedule: any;
    @ViewChild("MacWac", { static: true })
    MacWac: any;
    @ViewChild("MacNew", { static: true })
    MacNew: any;
    @ViewChild("WacNew", { static: true })
    WacNew: any;
    Is340BPharm$: any;
    wjHeaders: any;
    modalRefSM: any;
    @Output()
    IsPopUpCancelled = new EventEmitter<any>();
    macWacData: any[];
    @Input() DrugCode: any;
    DrugOtherPriceswj: any;
    actvModal: any;
    modalService: any;
    macWacFG: FormGroup;
    IsMac: boolean;
    unsubscribe$ : Subject<void> = new Subject();
    constructor(       private _drugUtils: DrugUtils,
        private _wijimoUtils: WijimoUtil,
        private _commonUtil: CommonUtil,
        private _cmnSvc: CommonService,
        private _alertSer: AlertService,
        private _modalService: NgbModal,
        private _drugServ: DrugService,
        private _drugSvc: DrugService,
        private _alertService: AlertService,
        private _fb: FormBuilder,
    ) {

    }

    ngOnInit() {
        this.createFG();
        this.Is340BPharm$ = this._cmnSvc.Is340BVal$;
        if (this.drugInfo && this.drugInfo.Drug) {
            this.macWacData = [
                {
                    PriceType: "MAC",
                    UnitPrice: this.drugInfo.Drug.MAC,
                    LastUpdated: this.drugInfo.Drug.MacLastUpdated,
                    UpdatedBy: this.drugInfo.Drug.MacUpdatedByName,
                    UpdatedByID: this.drugInfo.Drug.MacUpdatedById
                },
                {
                    PriceType: "WAC",
                    UnitPrice: this.drugInfo.Drug.WAC,
                    LastUpdated: this.drugInfo.Drug.WacLastUpdated,
                    UpdatedBy: this.drugInfo.Drug.WacUpdatedByName,
                    UpdatedByID: this.drugInfo.Drug.WacUpdatedById
                },
            ];
            this.generateMacWacListWJ(this.macWacData);
        }
        this.patchDefaultValHeaders();
        this._wijimoUtils.wjSavedData$.pipe(takeUntil(this.unsubscribe$)).subscribe((w) => {
            if (w && w["WijmoKey"] && w["WijmoKey"] === "drugOtherPriceswj") {
                this.patchDefaultValHeaders();
            }
        });
    }
    convertToFixedDecimals(control) {
        this._drugUtils.convertByMouseDown(control, this.drugInfoFG);
    }
    createFG() {
        this.macWacFG = this._fb.group({

            DrugCode: null,
            MAC: null,
            WAC: null,
            MacLastUpdated: null,
            MacUpdatedByName: null,
            WacLastUpdated: null,
            WacUpdatedByName: null,
            MacUpdatedById: null,
            WacUpdatedById: null,
            IsMac: null

        });
    }
    setNumber(ControlName, number) {
        this._drugUtils.setNumberFormat(ControlName, number, this.drugInfoFG);
    }
    async patchDefaultValHeaders() {
        const storedWJ = await this._wijimoUtils.getWJSavedData(
            "drugOtherPriceswj"
        );
        this.wjHeaders = this._wijimoUtils.patchDefHeader(
            "drugOtherPriceswj",
            storedWJ
        );
    }
    updateAltCost(val, type) {
        const dataInfo: any = this.drugInfoFG.controls["DrugMiscPrice"];
        const data: any = dataInfo.controls[type];
        if (data.controls["Price"].dirty) {
        data.controls["Price"].setValue((Number(val["value"])).toFixed(3));
        }
    }
    async getMacWacUpdateMmsData() {
        this._drugSvc
            .getMacWacUpdateMmsData(this.drugInfo.Drug.DrugCode).pipe(takeUntil(this.unsubscribe$))
            .subscribe((resp: any) => {
                if (!resp) {
                   this.modalRefSM = this._modalService.open(this.MacWac, {
                    backdrop: false,
                    keyboard: false,
                    centered: true,
                    windowClass: "small--content2"
                });
                } else {
                    this.macWacData = [];
                    resp.map((item: any) => {
                        this.macWacData.push( {
                            PriceType: "MAC",
                            UnitPrice: item.MAC,
                            LastUpdated: item.MacLastUpdated,
                            UpdatedBy: item.MacUpdatedByName,
                            UpdatedByID: item.MacUpdatedById
                        });
                        this.macWacData.push( {
                            PriceType: "WAC",
                            UnitPrice: item.WAC,
                            LastUpdated: item.WacLastUpdated,
                            UpdatedBy: item.WacUpdatedByName,
                            UpdatedByID: item.WacUpdatedById
                        });
                    });
                    this.generateMacWacListWJ(this.macWacData);
                    this.drugInfoFG.controls["Drug"].patchValue({
                        MAC: this.macWacData["0"]["UnitPrice"],
                        WAC: this.macWacData["1"]["UnitPrice"],
                        MacLastUpdated : this.macWacData["0"]["LastUpdated"],
                        WacLastUpdated : this.macWacData["1"]["LastUpdated"],
                        MacUpdatedById: this.macWacData["0"]["UpdatedByID"],
                        WacUpdatedById: this.macWacData["1"]["UpdatedByID"]
                    });
                }
            });
    }
    ngAfterViewInit() {
        this.focusonAWPPack();

    }
    focusonAWPPack(){
        if (document.getElementById("AWPPack")) {
            const ele: any = document.getElementById("AWPPack");
            if(ele){
                ele.select(); 
            }
                
        }
    }
    closeModalSM() {
        if (this.modalRefSM) {
            this.modalRefSM.close();
            this.modalRefSM = null;
            }
        this.IsPopUpCancelled.emit(null);
    }
    async getMacWacDataList() {
        const resp = await this._drugSvc
            .getMacWacData(this.DrugCode)
            .toPromise();
        this.generateMacWacListWJ(resp);
    }
    generateMacWacListWJ(DrugList: any[]) {
        this.DrugOtherPriceswj = new wjcCore.CollectionView(
            DrugList.map((Drug, i) => {
                const j = {};
                j["Price Type"] = Drug.PriceType;
                j["Unit Price"] = (Drug.UnitPrice) ? (Drug.UnitPrice) : "0.000";
                 j["Updated By"] = Drug.UpdatedBy;
                j["Updated Id"] = Drug.UpdatedByID;
                j["Last Updated Date Time"] = (Drug.LastUpdated) ? moment.utc(Drug.LastUpdated).local().format(
                    "MM/DD/YYYY hh:mm:ss a"
                ) : "";
                return j;
            })
        );
    }
    focusToSave(event) {
        this._commonUtil.focusById("saveButton", event);
    }
    openNewMacPrice(val) {
        if (this.IsMac = ((val["Price Type"]) === "MAC" ? true : false)) {
            this.modalRefSM = this._modalService.open(this.MacNew,
                {
               backdrop: false,
               keyboard: false,
               centered : true
           });
           this.macWacFG.controls["IsMac"].patchValue("true");
           this.macWacFG.controls["DrugCode"].patchValue((this.drugInfo.Drug.DrugCode));
            this.macWacFG.controls["MAC"].patchValue((this.macWacData["0"]["UnitPrice"]) ?
           (((this.macWacData["0"]["UnitPrice"]) !== (this.macWacData["0"]["UnitPrice"]))
           ? (this.macWacData["0"]["UnitPrice"]) : (this.macWacData["0"]["UnitPrice"])) : (0).toFixed(3));
           this.macWacFG.controls["WAC"].patchValue((this.macWacData["1"]["UnitPrice"]) ?
           (((this.macWacData["1"]["UnitPrice"]) !== (this.macWacData["1"]["UnitPrice"]))
           ? (this.macWacData["1"]["UnitPrice"]) : (this.macWacData["1"]["UnitPrice"])) : (0).toFixed(3));
           this.macWacFG.controls["MacUpdatedByName"].patchValue(this.macWacData["0"]["UpdatedBy"]);
           this.macWacFG.controls["MacUpdatedById"].patchValue(this.macWacData["0"]["UpdatedByID"]);
           this.macWacFG.controls["MacLastUpdated"].patchValue(this.macWacData["0"]["LastUpdated"]);
           this.macWacFG.controls["WacUpdatedByName"].patchValue(this.macWacData["1"]["UpdatedBy"]);
           this.macWacFG.controls["WacUpdatedById"].patchValue(this.macWacData["1"]["UpdatedByID"]);
           this.macWacFG.controls["WacLastUpdated"].patchValue(this.macWacData["1"]["LastUpdated"]);
        } else {
            this.modalRefSM = this._modalService.open(this.MacNew,
                {
               backdrop: false,
               keyboard: false,
               centered : true
           });
           this.macWacFG.controls["IsMac"].patchValue("false");
           this.macWacFG.controls["DrugCode"].patchValue((this.drugInfo.Drug.DrugCode));
           this.macWacFG.controls["MAC"].patchValue((this.macWacData["0"]["UnitPrice"]) ?
          (((this.macWacData["0"]["UnitPrice"]) !== (this.macWacData["0"]["UnitPrice"]))
          ? (this.macWacData["0"]["UnitPrice"]) : (this.macWacData["0"]["UnitPrice"])) : (0).toFixed(3));
          this.macWacFG.controls["WAC"].patchValue((this.macWacData["1"]["UnitPrice"]) ?
          (((this.macWacData["1"]["UnitPrice"]) !== (this.macWacData["1"]["UnitPrice"]))
          ? (this.macWacData["1"]["UnitPrice"]) : (this.macWacData["1"]["UnitPrice"])) : (0).toFixed(3));
          this.macWacFG.controls["MacUpdatedByName"].patchValue(this.macWacData["0"]["UpdatedBy"]);
          this.macWacFG.controls["MacUpdatedById"].patchValue(this.macWacData["0"]["UpdatedByID"]);
          this.macWacFG.controls["MacLastUpdated"].patchValue(this.macWacData["0"]["LastUpdated"]);
          this.macWacFG.controls["WacUpdatedByName"].patchValue(this.macWacData["1"]["UpdatedBy"]);
          this.macWacFG.controls["WacUpdatedById"].patchValue(this.macWacData["1"]["UpdatedByID"]);
          this.macWacFG.controls["WacLastUpdated"].patchValue(this.macWacData["1"]["LastUpdated"]);
        }
        
    }
onSaveMacWacValue() {
    this._drugSvc.macWacNewPriceValues(this.macWacFG.value).pipe(takeUntil(this.unsubscribe$)).subscribe((resp: any) => {
            if (resp) {
                     this.macWacData = [];
                    this.macWacData.push( {
                        PriceType: "MAC",
                        UnitPrice: resp.MAC,
                        LastUpdated: resp.MacLastUpdated,
                        UpdatedBy: resp.MacUpdatedByName,
                        UpdatedByID: resp.MacUpdatedById
                    });
                    this.macWacData.push( {
                        PriceType: "WAC",
                        UnitPrice: resp.WAC,
                        LastUpdated: resp.WacLastUpdated,
                        UpdatedBy: resp.WacUpdatedByName,
                        UpdatedByID: resp.WacUpdatedById
                    });
                this.generateMacWacListWJ(this.macWacData);
                this.drugInfoFG.controls["Drug"].patchValue({
                    MAC: this.macWacData["0"]["UnitPrice"],
                    WAC: this.macWacData["1"]["UnitPrice"],
                    MacLastUpdated : this.macWacData["0"]["LastUpdated"],
                    WacLastUpdated : this.macWacData["1"]["LastUpdated"],
                    MacUpdatedById: this.macWacData["0"]["UpdatedByID"],
                    WacUpdatedById: this.macWacData["1"]["UpdatedByID"]
                });

                this._alertService.success(MsgConfig.SAVE_SUCCESS);
            } else {

                this._alertService.error(MsgConfig.SAVE_FAIL);

            }
        });
}
ngOnDestroy(){
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
}
}
