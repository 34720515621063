import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MsgConfig } from 'src/app/app.messages';
import { searchTrackPatientCriteria } from 'src/app/models';
import { CommonHeaders } from 'src/app/models/labels.models';
import { NotesMainCat } from 'src/app/models/notes.enum';
import { AlertService, CommonService, DrugService } from 'src/app/services';
import { NotesLogService } from 'src/app/services/notes-log.service';
import { CommonUtil } from 'src/app/utils';

@Component({
  selector: 'app-single-notes',
  templateUrl: './single-notes.component.html',
  styleUrls: ['./single-notes.component.scss']
})
export class SingleNotesComponent implements OnInit {
  commonlabels: any;
  notesLogFG: FormGroup;
  notesLogInfo: any;
  searchParams: searchTrackPatientCriteria;
  editorConfig: AngularEditorConfig = {
    editable: true,
      spellcheck: true,
      height: '50px',
      minHeight: '0',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Notes',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      toolbarHiddenButtons: [
        ['undo',
        'redo',
        'underline',
        'subscript',
        'superscript',
        'justifyLeft',
        'justifyCenter',
        'justifyRight',
        'justifyFull',
        'indent',
        'outdent',
        'insertUnorderedList',
        'insertOrderedList',
        'heading',
        'fontName'],
        [
            'fontSize',
            'textColor',
            'backgroundColor',
            'customClasses',
            'link',
            'unlink',
            'insertImage',
            'insertVideo',
            'insertHorizontalRule',
            'removeFormat',
            'toggleEditorMode'
          ]
      ]
};
unsubscribe$: Subject<void> = new Subject();
@Input() PrescReFillId : any;
@Input() PrescriptionId : any;
@Input() HeaderText : any;
@Input() BodyText : any;
@Input() RxType : any;
@Input() MainCategory : any;
@Input() IsNotPopup : any;
@Input()
set RequestedInfo(infoData: any) {
   if(infoData.Note){
      this.requestedNotesInfo = infoData.Note;
   }
}
@Input() TransferIn : string;
formGroupInvalid: boolean = false;
subCatInvalid: boolean = false;
modalRef: any;
@Output() IsPopUpClosed = new EventEmitter<any>();
@Output() TransferNotes = new EventEmitter<any>();
@ViewChild("SingleNotesPopup", {static: true})
SingleNotesPopup: any;
requestedNotesInfo: any;
  constructor(  private _notesLogSvc: NotesLogService,
               private _mdlServ: NgbModal, 
               private _alertService: AlertService,
              private _drugServ: DrugService, 
              private _commonServ: CommonService,
            private _commUtil:CommonUtil) { }

  ngOnInit(): void {
    this.createNoteForm();
    if(!this.IsNotPopup)
       this.modalRef = this._mdlServ.open(this.SingleNotesPopup, { backdrop: "static", keyboard: false, centered: true});
    this.commonlabels = new CommonHeaders();
    this.getNotesSubacatList();
    if(!this.IsNotPopup)
    {
      this.notesLogFG.controls["xNote"].valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((resp: any) => {
        this.checkMandatory(resp);
      });  
    }  else {
      this.notesLogFG.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((resp: any) => {
        this.checkMandatory(resp);
      });
    }
  }
  createNoteForm() {
    this.notesLogFG = new FormGroup({
        Id: new FormControl(),
        TenantId : new FormControl(),
        AppUserId:  new FormControl(),
        xNote:   new FormControl(),
        NoteCatId:  new FormControl(),
        IsShownAsPopup: new FormControl(),
        IsActive:   new FormControl(),
        IsDeleted:  new FormControl(),
        CreateDtTm:  new FormControl(),
        ModifiedDtTm:  new FormControl(),
        Name:  new FormControl(),
        PopupScreenCodes:  new FormControl(),
        IsAddItem: new FormControl(),
        IsfromTransferIn: new FormControl(),
    });
    this.setDefaultValues();
}
setDefaultValues() {
    this.notesLogFG.controls['IsActive'].patchValue(true);
    this.notesLogFG.controls['IsShownAsPopup'].patchValue(false);
    this.notesLogFG.controls['AppUserId'].patchValue(2);
    this.notesLogFG.controls['CreateDtTm'].patchValue(moment(Date.now()).format("MM/DD/YYYY"));
    this.notesLogFG.controls['ModifiedDtTm'].patchValue( moment(Date.now()).local().format(
        "MM/DD/YYYY hh:mm:ss a"
    ));
    this.notesLogFG.controls['IsDeleted'].patchValue(false);
}
closePopup() {
     if(this.modalRef) {
          this.modalRef.close();
          this.modalRef = null;
     }
}
getNotesSubacatList(catObj?: any) {
  this.searchParams = {
      SearchText: null,
      SearchBy: null,
      OrderByColumn: null,
      SortDirection: null,
      PageNumber: 1,
      PageSize: 30,
      PrescrefillId: null
  };
  this._notesLogSvc
  .getNotesLog(
      "RxAnnotation",
    this.PrescReFillId ? this.PrescReFillId : 0,
    "1973-01-01",
    moment(Date.now()).format(MsgConfig.DATE_FORMAT_WITH_EIPHEN),
      this.searchParams
  )
  .pipe(takeUntil(this.unsubscribe$))
  .subscribe((resp: any) => {
      if(resp)
      {
          this.notesLogInfo = resp;
          // eRx related subcategories we have to hide from the dropdown for cancel Rx's only will show it in the Edit Mode
          this.notesLogInfo.NoteCat = resp?.NoteCat?.filter((item: any) => (item?.CategoryName.toUpperCase() !== 'ERX'));
          if(catObj?.Name) {
            let manualSubCat = resp?.NoteCat?.find((item: any) => (catObj.Name.toUpperCase() === item?.Name?.toUpperCase()));
            this.notesLogFG .patchValue({
                NoteCatId:  manualSubCat ? manualSubCat.Id : 0,
            });  
            this.addNotesLog();
          }
      }
  });
}
  continueSavingRx() {
    const notesCatList: any = this._commonServ.notesCatList$["source"]["value"];
    const mainCat = notesCatList.find((val: any) => { if (val.CategoryName.toUpperCase() === NotesMainCat.RxAnnonations.toUpperCase()) return val });
    this.formGroupInvalid = false;
    if(this.notesLogFG.value.xNote && !this.notesLogFG.value.NoteCatId && !this.notesLogFG.value.Name) {
      this.formGroupInvalid = true;
      if(document.getElementById("notesLogCategry")) {
        document.getElementById("notesLogCategry").focus();
      }
    }
    else if (this.RxType === 'nr' && !this.notesLogFG.value.NoteCatId   &&  this.notesLogFG.value.Name && this.notesLogFG.value.xNote) {
      this.closePopup();
      this._drugServ.addoRUpdateNoteCategory(
        {
          Name: this.notesLogFG.value.Name,
          NoteCategoryId: mainCat?.CategoryId,
          IsActive: true,
          CreatedDtTm: null,
          Id: 0
        },
        "SAVE"
      ).pipe(takeUntil(this.unsubscribe$)).subscribe((resp: number) => {
        if (resp) {
          this.getNotesSubacatList({
            Name: this.notesLogFG.value.Name
          });
        }
      });
    } else {
      this.closePopup();
      const NoteCat = [
        {
          Id: 0,
          TenantId: null,
          Name: this.notesLogFG.value.Name,
          Remarks: null,
          IsActive: true,
          IsAddItem: true,
          CreatedDtTm: moment(Date.now()).format("MM/DD/YYYY"),
          ModifiedDtTm: moment(Date.now()).local().format("MM/DD/YYYY hh:mm:ss a"),
          NoteCategoryId: mainCat?.CategoryId
        }
      ]
      this.addNotesLog(NoteCat);
    }
  }
addNotesLog(NoteCat?) {
  if(this.RxType === 'er' && this.notesLogFG.value.xNote && (this.notesLogFG.value.NoteCatId || this.notesLogFG.value.Name)) {
    if(this.notesLogFG.value["PopupScreenCodes"]?.length)
      {
          this.notesLogFG.value["PopupScreenCodes"] = this.notesLogFG.value["PopupScreenCodes"]?.toString();
          this.notesLogFG.value["IsShownAsPopup"] = true;
      }
      else 
     {
      this.notesLogFG.value["IsShownAsPopup"] = false;
      this.notesLogFG.value["PopupScreenCodes"] = null
     }
    this._notesLogSvc
    .addNotesLog(NotesMainCat.RxAnnonation,this.PrescriptionId, 
        {Note: [this.notesLogFG.value] , NoteCat: (NoteCat && !(this.notesLogFG.value.NoteCatId)) ? NoteCat: null})
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(resp => {
      if(resp)
      {
       
        this._alertService.success(MsgConfig.Notes_Saved);
      }
    });  
    this.IsPopUpClosed.emit(false);
  } else
    {
      if(this.notesLogFG.value["PopupScreenCodes"]?.length)
        {
            this.notesLogFG.value["PopupScreenCodes"] = this.notesLogFG.value["PopupScreenCodes"]?.toString();
            this.notesLogFG.value["IsShownAsPopup"] = true;
        }
        else 
       {
        this.notesLogFG.value["IsShownAsPopup"] = false;
        this.notesLogFG.value["PopupScreenCodes"] = null
       }
      this.IsPopUpClosed.emit(this.notesLogFG.value);
    }
}
filterAllinList(formGrop) {
  this.notesLogFG.controls['PopupScreenCodes'].patchValue(this._commUtil.filterAllinList(this.notesLogFG.controls['PopupScreenCodes'].value));
  this.TransferNotes.emit(this.notesLogFG.value);
}
checkMandatory(xNote) {
      if(xNote&& !this.notesLogFG.value["NoteCatId"])
      {
        this.subCatInvalid = true;
          this.notesLogFG.controls["NoteCatId"].setErrors({
              required: true
          });
          this.notesLogFG.controls["NoteCatId"].markAllAsTouched();
      } else if(!xNote){
        this.subCatInvalid = false;
        this.notesLogFG.controls["xNote"].setValidators(null);
        this.notesLogFG.controls["xNote"].markAllAsTouched();
      }
      if(this.TransferIn === 'markasTransfer') {
          this.continueSavingRx();
      } else 
        this.TransferNotes.emit(this.notesLogFG.value);
}
}
