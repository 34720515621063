import { BaseUtils } from "src/app/utils/base.utils";
import { PreviousRouteService, AlertService, CommonService } from "src/app/services";
import { Router, ActivatedRoute } from "@angular/router";
import { Data } from "./../../../models/erx-filter.model";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { MergePatientService } from "./../../../services/merge-patient.service";
import { Component, OnInit, ElementRef, ViewChild, AfterViewInit, HostListener, Output, Input, EventEmitter, ChangeDetectorRef } from "@angular/core";
import * as wjcCore from "@grapecity/wijmo";
import * as moment from "moment";
import * as wjcGrid from "@grapecity/wijmo.grid"
import { PaginationModel,SystemData } from "../../../models";
import { FormBuilder, FormGroup } from "@angular/forms";
import * as _ from "lodash";
import { ColorCode, CommonUtil, WijimoUtil } from "src/app/utils";
import { HoverOver } from "../../../utils/hover-over.util";
import { ColorCodeRx } from "../../../utils/rx-color.util";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FormatsUtil } from "src/app/utils/formats.util";

@Component({
    selector: "app-merge-patient",
    templateUrl: "./merge-patient.component.html"
})
export class MergePatientComponent implements OnInit, AfterViewInit {
    paginationModel: PaginationModel = new PaginationModel();
    patientRxHistoryWJ: any;
    patient2RxHistoryWJ: any;
    wjHeaders: any;
    hasPatientHistory = false;
    patientData: any;
    patient2Data: any;
    hasPatient2 = false;
    isdisable = true;
    hasPatient2D = false;
    patient1Id: any;
    patient2Id: any;
    data: Data;
    cantMerge = true;
    patient1: any;
    patient2D: any;
    urlPatienId: number;
    isSame = false;
    isAuto = false;
    isreset1 = false;
    pageNumber: number;
    pageSize: number;
    rxHistoryParams: FormGroup;
    patient1Insu: any;
    patient2Insu: any;
    systemData: SystemData;
    @Input() frmPat: any;
    @Output() emitPopupClosed = new EventEmitter();

    @ViewChild("PatSearch1")
    patSearch1: ElementRef;

    @ViewChild("PatSearch2")
    patSearch2: ElementRef;

    @ViewChild("content", { static: true })
    content: any;

    @ViewChild("error", { static: true })
    error: any;

    @ViewChild("success", { static: true })
    success: any;

    isInputFocused: boolean;
    pat1FmEP: any;
    patient1Sugg: any;
    patient2WJ: wjcCore.CollectionView;
    isInputFocused1: boolean;
    patient2Sugg: any;
    pat2FmEP: any;
    modalRef: any;
    modalRef2: any;
    rxHistoryData: any;
    unsubscribe$: Subject<void> = new Subject();

    @HostListener("window:keydown", ["$event"])
    keyEventAlt(event: KeyboardEvent) {
        if (event.which === 27 && this.modalRef && this.modalService.hasOpenModals()) { // esc
            this.modalRef.close();
            this.modalRef = null;
            event.preventDefault();
        }

    }

    constructor(
        private _fb: FormBuilder,
        private _mergePatient: MergePatientService,
        private modalService: NgbModal,
        private _actvRoute: ActivatedRoute,
        private _route: Router,
        private _baseUtils: BaseUtils,
        private _alertSer: AlertService,
        private _prevSer: PreviousRouteService,
        private _commonSvc: CommonService,
        private _cmnUtil: CommonUtil,
        private _colorCode: ColorCode, private _comnUtil: CommonUtil,
        private _wijimoUtils: WijimoUtil,
        private _hoverOver: HoverOver,
        private _colorCodeRx: ColorCodeRx,
        private _cdr: ChangeDetectorRef,
        public _formatsUtil:FormatsUtil
    ) {}

    ngOnInit() {
        this.systemData = this._commonSvc.systemData$["source"]["value"];
        if (this.frmPat) {
            this.selectPatFrmEditpat(this.frmPat);
        }

        this.rxHistoryParams = this._fb.group({
            patientId: 0,
            Billstatus: 0,
            RxStatus: 0,
            StartDate: null,
            EndDate: null,
            PageNumber: this.paginationModel.pageNumber,
            PageSize: this.paginationModel.pageSize,
            OrderByColumn: null
        });
        this.patchDefaultValHeaders();
        this._wijimoUtils.wjSavedData$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(w => {
            if (w && w["WijmoKey"] && w["WijmoKey"] === "mergePatientListWJ") {
                this.patchDefaultValHeaders();
            }
        });
    }

    ngAfterViewInit() {
        this.patSearch1["searchBox"].nativeElement.focus();
    }

    async selectPatFrmEditpat(Id) {
        const patSuggest = await this._commonSvc.getRxPatientDetailsById(Id).toPromise();
        this.selectpatient1(patSuggest);
    }

    async patchDefaultValHeaders() {
        const storedWJ = await this._wijimoUtils.getWJSavedData("mergePatientListWJ");
        this.wjHeaders = this._wijimoUtils.patchDefHeader("mergePatientListWJ", storedWJ);
    }

    GetPatientPrimaryInsurance(patientData: any) {
        const insurance = patientData.insurance.filter(x =>
            x.insuranceid === +patientData.insuranceid)[0];
        return insurance;
    }

    selectpatient1($event) {
        if ($event) {
            this.patient1Id = $event.patientid;
            if (this.patient2Id && this.patient2Id === this.patient1Id) {
                this._alertSer.error("Same patients cannot be selected for merging.", false,
                    "long");
                this.resetPatient1();
            } else {
                this._mergePatient.getData(this.patient1Id)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(resp => {
                    if (resp) {
                        this.patient1Sugg = $event;
                        this.pat1FmEP = resp;
                        if (this.pat1FmEP) {
                            this.patient1Insu = this.GetPatientPrimaryInsurance(this.patient1Sugg);
                        }
                        this.rxHistoryParams.controls["patientId"].patchValue(this.patient1Id);
                        this.rxHistoryParams.controls["PageSize"].patchValue(resp["RxCount"]);
                        this._mergePatient
                            .getRx(this.rxHistoryParams.value)
                            .pipe(takeUntil(this.unsubscribe$))
                            .subscribe(resps => {
                                if (resps && resps["rxHistory"]) {
                                    if (resps["rxHistory"].length > 0) {
                                        this.pat1FmEP.RxCount =
                                            resps["rxHistory"].length;
                                        this.rxHistoryData = resps["rxHistory"];
                                        this.generateWJ1(resps["rxHistory"]);
                                        this.isInputFocused = false;
                                        this._cdr.detectChanges();
                                        this.isInputFocused = true;
                                    }
                                }
                                this.isInputFocused = false;
                                this._cdr.detectChanges();
                                this.isInputFocused = true;
                            });
                    }
                });
            }
        }
    }

    resetPatient1() {
        this.pat1FmEP = null;
        this.patient1Id = null;
        this.isInputFocused1 = false;
        this._cdr.detectChanges();
        this.isInputFocused = true;
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
    
    resetPatient2() {
        this.pat2FmEP = null;
        this.patient2Id = null;
        this.isInputFocused = false;
        this._cdr.detectChanges();
        this.isInputFocused = true;
    }

    init(flex: wjcGrid.FlexGrid) {
        flex.columnHeaders.rows[0].wordWrap = true;
    }

    selectpatient2($event, error) {
        if ($event) {
            this.patient2Id = $event.patientid;
            if (this.patient1Id === this.patient2Id) {
                this._alertSer.error("Same patients cannot be selected for merging.", false,
                    "long");
                this.resetPatient2();
            } else {
                this._mergePatient.getData(this.patient2Id)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(resp => {
                    if (resp) {
                        this.patient2Sugg = $event;
                        this.pat2FmEP = resp;
                        if (this.pat2FmEP) {
                            this.patient2Insu = this.GetPatientPrimaryInsurance(this.patient2Sugg);
                        }
                        this.rxHistoryParams.controls["patientId"].patchValue(this.patient2Id);
                        this.rxHistoryParams.controls["PageSize"].patchValue(resp["RxCount"]);
                        this._mergePatient
                            .getRx(this.rxHistoryParams.value)
                            .pipe(takeUntil(this.unsubscribe$))
                            .subscribe(resps => {
                                if (resps && resps["rxHistory"]) {
                                    if (resps["rxHistory"].length > 0) {
                                        this.pat2FmEP.RxCount =
                                            resps["rxHistory"].length;
                                        this.rxHistoryData = resps["rxHistory"];
                                        this.generateWJ2(resps["rxHistory"]);
                                    }
                                }
                            });
                    }
                });
            }
        }
    }

    openModal(content) {
        this.modalRef = this.modalService.open(content, { centered: true, keyboard: false, backdrop: false, windowClass: "large--content"});
    }

    routeBackToPrevScreen() {
        if (this.frmPat) {
            this.emitPopupClosed.emit(false);
        } else {
            this._prevSer.routeBackToPrevScreen();
        }
    }

    mergePatient(success) {
        if (this.patient1Id && this.patient2Id) {
            this.data = {
                patientId: this.patient2Id,
                PatientIdForDelete: this.patient1Id,
                statusId: 0
            };
            this._mergePatient.mergePatient(this.data)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp) {
                    this._alertSer.success(((this.patient1Sugg.firstname).toUpperCase() + " " +
                        (this.patient1Sugg.lastname).toUpperCase() +
                        " has been deleted and added in  " +
                        (this.patient2Sugg.firstname).toUpperCase() + " " + (this.patient2Sugg.lastname).toUpperCase())+". Total "+this.pat1FmEP.RxCount+"Rx's has been added.", false, "long");
                    // this.modalRef2 = this.modalService.open(success, { centered: true });
                    this.pat1FmEP = null;
                    this.patient1Id = null;
                    this.pat2FmEP = null;
                    this.patient2Id = null;
                    this.isInputFocused1 = false;
                    if (this.frmPat) {
                        this.emitPopupClosed.emit(true);
                    } else {
                        this._cdr.detectChanges();
                        this.isInputFocused1 = true;
                    }
                } else {
                    this._alertSer.error("Patient merge unsuccessful.");
                    if (this.frmPat) {
                        this.emitPopupClosed.emit(true);
                    }
                }
            });
        }
    }

    closeModal() {
        if (this.modalRef) {
            this.modalRef.close();
        } else {
            if (this.modalRef2) {
            this.modalRef2.close();
            }
            this.isInputFocused1 = false;
            this._cdr.detectChanges();
            this.isInputFocused1 = true;
        }
    }

    generateWJ2(patRxHist: any) {
        this.patient2WJ = new wjcCore.CollectionView(
            patRxHist.map((patient, i) => {
                let k = {};
                k["Row Id"] = i;
                k["Rx#"] = patient.Prescription.PrescNum.toString();
                k["Auth#"] = patient.Prescription.ReFillNum
                    ? patient.Prescription.ReFillNum
                    : 0;
                k["Ref#"] = patient.PrescReFill.ReFillNum;
                k["Fill#"] = this._commonSvc.checkIsPartialFill(patient.PrescReFill, patient.Prescription) ?
                patient.PrescReFill.PartialFillNo : " ";
                k["Insurance"] = this._commonSvc.rxInsurance(patient);
                k["Status"] = this._cmnUtil.getRxStatus(patient.Prescription.RxStatusId, patient.PrescReFill.StatusId,
                     patient.RxBill.StatusId);
                k["Drug Name"] = patient.Drug?.drugname + " " + patient.Drug?.strength + " " + patient.Drug?.drugformname;
                k["Qty"] = patient.PrescReFill.DispQty ? patient.PrescReFill.DispQty.toFixed(3) : "0.000";
                k["SDays"] = patient.PrescReFill.SupplyDays;
                k["Fill Date"] = moment(patient.PrescReFill.FillDtTm).format(
                    "MM/DD/YYYY"
                );
                k["Copay"] = patient.PrescReFill.CoPay ? "$" + patient.PrescReFill.CoPay.toFixed(2) : "$0.00";
                k["Price"] = patient.PrescReFill.Price ? "$" + patient.PrescReFill.Price.toFixed(2) : "$0.00";
                k["Prescriber Name"] =
                    patient.Prescriber.prescriberlastname +
                    ", " +
                    patient.Prescriber.prescriberfirstname;
                k["Patient Name"] =
                    patient.Patient.lastname + ", " + patient.Patient.firstname;
                k["NDC"] =  this._formatsUtil.getNDCFormat(patient.Drug?.ndc);
                const duedays = Math.round(patient.PrescReFill.SupplyDays) -
                    moment().startOf("day").diff(moment.utc(patient.PrescReFill.FillDtTm).startOf("day"), "days");
                k["Due Days"] = (k["Status"] === "T" || (k["Auth#"] === 0 && k["Ref#"] === 0)) ? 0 : duedays < 0 ? 0 : duedays;
                k["DueDays"] = duedays;
                k["DVrf?"] =
                    patient.drugPickVerificationStatus === "P" || "p"
                        ? (patient.drugPickVerificationStatus = "VERIFIED")
                        : (patient.drugPickVerificationStatus = "NOT VERIFIED");
                k["PVrf?"] = patient.rph2VerificationStatus;
                k["Copay Status"] = patient.PrescReFill.IsCopayPaid
                    ? (patient.PrescReFill.IsCopayPaid = "PAID")
                    : (patient.PrescReFill.IsCopayPaid = "UNPAID");
                k["PatientId"] = patient.Patient.patientid;
                k["IsBilled"] = this._comnUtil.checkIsGreenBilled(patient, this.systemData);
                k["DrugClass"] = patient.Drug?.drugclass;
                k["IsPicked"] = patient.pickupRx ? patient.pickupRx.IsPickedUp === true ? true : false : false;
                k["Picked Up"] = k["IsPicked"] ? "Yes" : "No";
                k["DrugHoverOver"] = this._hoverOver.getDrugNameHoverOver(k);
                k["StatusHoverOver"] = this._hoverOver.getStatusHoverOver(k);
                if (k["Status"] === "D") {
                    k["File Due Date"] = k["Status"] === "D" ? moment.utc(patient?.RxFile?.DueDtTm ? (patient.RxFile.DueDtTm): "").local().format("MM/DD/YYYY") : "";
                    k["Defer Reason"] = k["Status"] === "D" ? patient.RxFile?.DeferredReasonName : "";
                    k["FileDueDateHover"] = "<b>Defer Due Date: </b>" + k["File Due Date"];
                    k["DeferReasonHover"] = "<b>Defer Reason: </b>" +  k["Defer Reason"]?.toUpperCase();
                }
 
                const rowId = k["Row Id"] >= 0 ? k["Row Id"] : "";
                const RxDetails = rowId >= 0 ? patRxHist[rowId] : null;
                k["RxHoverOver"] = this._colorCodeRx.showRxColorCodeAndHoverOver(RxDetails, "RxObject", "Rx", "hoverOver", "", false);
                return k;
            })
        );
    }


    generateWJ1(patRxHist: any) {
        this.patientRxHistoryWJ = new wjcCore.CollectionView(
            patRxHist.map((patient, i) => {
                let k = {};
                k["Row Id"] = i;
                k["Rx#"] = patient.Prescription.PrescNum.toString();
                k["Auth#"] = patient.Prescription.ReFillNum
                    ? patient.Prescription.ReFillNum
                    : 0;
                k["Ref#"] = patient.PrescReFill.ReFillNum;
                k["Fill#"] = this._commonSvc.checkIsPartialFill(patient.PrescReFill, patient.Prescription) ?
                patient.PrescReFill.PartialFillNo : " ";
                k["Insurance"] = this._commonSvc.rxInsurance(patient);
                k["Status"] =  this._cmnUtil.getRxStatus(patient.Prescription.RxStatusId, patient.PrescReFill.StatusId,
                    patient.RxBill.StatusId);
                k["Drug Name"] = patient?.Drug?.drugname + " " + patient?.Drug?.strength + " " + patient?.Drug?.drugformname;
                k["Qty"] = patient.PrescReFill.DispQty ? patient.PrescReFill.DispQty.toFixed(3) : "0.000"
                k["SDays"] = patient.PrescReFill.SupplyDays;
                k["Fill Date"] = moment(patient.PrescReFill.FillDtTm).format(
                    "MM/DD/YYYY"
                );
                k["Copay"] = patient.PrescReFill.CoPay ? "$" + patient.PrescReFill.CoPay.toFixed(2) : "$0.00";
                k["Price"] = patient.PrescReFill.Price ? "$" + patient.PrescReFill.Price.toFixed(2) : "$0.00";
                k["Prescriber Name"] =
                    patient.Prescriber.prescriberlastname +
                    ", " +
                    patient.Prescriber.prescriberfirstname;
                k["NDC"] =  this._formatsUtil.getNDCFormat(patient.Drug?.ndc);
                const duedays = Math.round(patient.PrescReFill.SupplyDays) -
                    moment().startOf("day").diff(moment.utc(patient.PrescReFill.FillDtTm).startOf("day"), "days");
                k["Due Days"] = (k["Status"] === "T" || (k["Auth#"] === 0 && k["Ref#"] === 0)) ? 0 : duedays < 0 ? 0 : duedays;
                k["DueDays"] = duedays;
                k["DVrf?"] =
                    patient.drugPickVerificationStatus === "P" || "p"
                        ? (patient.drugPickVerificationStatus = "VERIFIED")
                        : (patient.drugPickVerificationStatus = "NOT VERIFIED");
                k["PVrf?"] = patient.rph2VerificationStatus;
                k["Copay Status"] = patient.PrescReFill.IsCopayPaid
                    ? (patient.PrescReFill.IsCopayPaid = "PAID")
                    : (patient.PrescReFill.IsCopayPaid = "UNPAID");
                k["PatientId"] = patient.Patient.patientid;
                k["IsBilled"] = this._comnUtil.checkIsGreenBilled(patient, this.systemData);
                k["DrugClass"] = patient.Drug?.drugclass;
                k["IsPicked"] = patient.pickupRx ? patient.pickupRx.IsPickedUp === true ? true : false : false;
                k["Patient Name"] = patient.Patient.lastname + ", " + patient.Patient.firstname;
                k["Picked Up"] = k["IsPicked"] ? "Yes" : "No";
                k["DrugHoverOver"] = this._hoverOver.getDrugNameHoverOver(k);
                k["StatusHoverOver"] = this._hoverOver.getStatusHoverOver(k);
                if (k["Status"] === "D") {
                    k["File Due Date"] = k["Status"] === "D" ? moment.utc(patient?.RxFile?.DueDtTm ? (patient.RxFile.DueDtTm): "").local().format("MM/DD/YYYY") : "";
                    k["Defer Reason"] = k["Status"] === "D" ? patient?.RxFile?.DeferredReasonName : "";
                    k["FileDueDateHover"] = "<b>Defer Due Date: </b>" + k["File Due Date"];
                    k["DeferReasonHover"] = "<b>Defer Reason: </b>" +  k["Defer Reason"]?.toUpperCase();
                }
                const rowId = k["Row Id"] >= 0 ? k["Row Id"] : "";
                const RxDetails = rowId >= 0 ? patRxHist[rowId] : null;
                k["RxHoverOver"] = this._colorCodeRx.showRxColorCodeAndHoverOver(RxDetails, "RxObject", "Rx", "hoverOver","", false);
                return k;
            })
        );
    }

    itemFormatter = (panel, r, c, cell) => {
        const row = panel.rows[r];
        if (row.dataItem) {
            cell.style.background = "";
            if (panel.cellType === wjcGrid.CellType.Cell && panel.columns[c].binding === "Rx#") {
                const rowId = row && row.dataItem ? row.dataItem["Row Id"] : "";
                const RxDetails = rowId >= 0 ? this.rxHistoryData[rowId] : null;
                const colors = this._colorCode.showRxColorRefactoring(row.dataItem, "Rx");
                  if(colors && colors.bg && colors.color) {
                      cell.style.background = colors.bg;
                      cell.style.color = colors.color;
                  } else {
                      cell.style.background = "#FFF";
                      cell.style.color = "#000";
                  }
                  cell.style.fontWeight = "bold";
                  cell.style.paddingLeft = "1rem";
            } else if (panel.cellType === wjcGrid.CellType.Cell && panel.columns[c].binding === "Drug Name") {
                const colors = this._colorCode.showDrugColorRefactoring(row.dataItem);
                if(colors && colors.bg && colors.color) {
                    cell.style.background = colors.bg;
                    cell.style.color = colors.color;
                } else {
                    cell.style.background = "#FFF";
                    cell.style.color = "#000";
                }
                cell.style.fontWeight = "bold";
            } else if (panel.cellType === wjcGrid.CellType.Cell && panel.columns[c].binding === "Status") {
                const colors = this._colorCode.showRxStatusColorRefactoring(row.dataItem);
                if(colors && colors.bg && colors.color) {
                    cell.style.background = colors.bg;
                    cell.style.color = colors.color;
                } else {
                    cell.style.background = "#FFF";
                    cell.style.color = "#000";
                }
                cell.style.fontWeight = "bold";
                cell.style.textAlign = "center";
            } else {
                cell.style.backgroundColor = "#FFF";
                cell.style.color = "#000";
                cell.style.fontWeight = "normal";
                cell.style.textAlign = "initial";
            }
        }
    };

    focusOutFromField(event) {
        if (document.getElementById("patSearch1")) {
            document.getElementById("patSearch1").getElementsByTagName("input")[0].focus();
            event.preventDefault();
        }
    }
}

