import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { constant } from '../app.constants';
@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor(private _http: HttpClient) { }
  getMessageLogData(data: any) {
    const reqOptns = {
        headers: new HttpHeaders().set("isSPCBaseUrl", "true")
    };
    return this._http.post(constant.POST_MESSAGE_SMSLOG, data,reqOptns);
}
  getMessageQueueData(data: any) {
    return this._http.post(constant.Get_MESSAGE_QUEUE_DATA , data);
}
  CancelMessage(data: any) {
    return this._http.post(constant.CANCEL_SENDING_MESSAGE , data);
}
    getAutoRefreshValue() {
        return this._http.get(constant.SAVE_QUEUEREFRESH);
    }
    SaveAutoRefreshValue(data: any) {
        return this._http.put(constant.SAVE_QUEUEREFRESH, data);
    }
    GetRefillDueData(data: any) {
        return this._http.post(constant.GET_DATA_REFILL_DUE_MSG , data);
    }
    SendRefillDueData(data: any) {
        return this._http.post(constant.SEND_DATA_REFILL_DUE_MSG , data);
    }
    ValidatePhone(phoneNumber) {
        return this._http.get<string>(
            constant.GET_VALIDATE_PHONE.replace("{phoneNumber}", "" + phoneNumber)
        );
    }
    // Secure Patient communication
    postMessageConversation(data: any) {
        const reqOptns = {
            headers: new HttpHeaders().set("isSPCBaseUrl", "true")
        };
        return this._http.post(constant.POST_MESSAGE_CONVERSATION , data, reqOptns);
    }

    postMessageChatSummary(data: any) {
        const reqOptns = {
            headers: new HttpHeaders().set("isSPCBaseUrl", "true")
        };
        return this._http.post(constant.POST_MESSAGE_CHATSUMMARY , data, reqOptns);
    }

    postMessageUnreadCount(data: any) {
        const reqOptns = {
            headers: new HttpHeaders().set("isSPCNoProxyBaseUrl", "true")
        };
        return this._http.post(constant.POST_MESSAGE_UNREADCOUNT , data, reqOptns);
    }

    postMessageMarkAsRead(data: any) {
        const reqOptns = {
            headers: new HttpHeaders().set("isSPCNoProxyBaseUrl", "true")
        };
        return this._http.post(constant.POST_MESSAGE_MARKASREAD , data, reqOptns);
    }

    
}
