<div class="row exprx--common-block">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 padding-sm-15">
        <div class="box box-default box-solid">
            <div class="eprx--block__header">
                <div class="eprx--header__heading">
                    Plan Pricing Information
                </div>
            </div>
            <div class="box-body">
                <div class="row">
                    <div class="col-md-3 col-sm-3 col-xs-12">
                        <div class="exprx--common-block">
                            <div class="box box-default box-solid">
                                <div class="trans-block-header">
                                    <div class="eprx--header__heading">
                                        Transmission
                                    </div>
                                </div>
                                <div style="padding-top: 10px">

                                </div>
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <eprx-input [LabelText]="'Payor ID'" [PlaceHolder]="'Payor ID'" [ControlName]="'PayorNum'" [FormGroupName]="transStngFG"
                                    [AutoFocus]="true"  [MaxLength]="10" [Title]="'Payor Id'">
                                    </eprx-input>
                                </div>
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <eprx-select [LabelText]="'Payor ID Qualifier'" [PlaceHolder]="'Payor ID Qualifier'" [ControlName]="'PayorIdQualifId'" [FormGroupName]="transStngFG"
                                        [HasMultiple]="false" [Title]="'Payor ID Qualifier'" [HasBindLabel2]="false" [BindLabel]="'Id'" [BindLabel2]="'Name'" [Show2Values]="true"
                                        [BindValue]="'Id'" [LabelForId]="'Id'" [List]="dropDownData ? dropDownData.PayorIdCat : ''">
                                    </eprx-select>
                                </div>
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <eprx-input [LabelText]="'Receiver ID'" [PlaceHolder]="'Receiver ID'" [ControlName]="'ReceiverNum'" [FormGroupName]="transStngFG"
                                        [MaxLength]="10" [Title]="'Receiver ID'">
                                    </eprx-input>
                                </div>
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div class="row">
                                        <div
                                            [ngClass]="transStngFG?.value['NCDPId'] ? 'col-lg-10 col-md-10 col-sm-10 col-xs-10' : 'col-lg-12 col-md-12 col-sm-12 col-xs-12'">
                                            <eprx-select [LabelText]="'NCPDP Payer Set'" [PlaceHolder]="'NCPDP Payer Set'" [ControlName]="'NCDPId'"
                                                [FormGroupName]="transStngFG" [HasMultiple]="false" [Title]="'NCPDP Payer Set'" [HasBindLabel2]="true"
                                                [BindLabel]="'Code'" [BindLabel2]="'Name'" [ShowLabel2]="true" [BindValue]="'Id'" [LabelForId]="'Id'" [Show2Values]="true"
                                                [List]="dropDownData ? dropDownData.NCPDP : ''"
                                                [RxSelectId]="'ncdppayerinsu'">
                                            </eprx-select>
                                        </div>
                                        <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2" *ngIf="transStngFG?.value['NCDPId']" style="padding-top: 1rem;">
                                            <i (click)="openNCDPEdit(true)" class="far fa-edit action text-right pull-right"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-4">
                        <div class="exprx--common-block">

                            <div class="box box-default box-solid">
                                <div class="trans-block-header">
                                    <div class="eprx--header__heading">
                                        Coordination of benefits
                                    </div>
                                </div>
                                <div style="padding-top: 10px">

                                </div>
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <eprx-input [LabelText]="'COB HC Req Qualifier'" [PlaceHolder]="'COB HC Req Qualifier'" [ControlName]="'COBHCReqQualif'"
                                        [FormGroupName]="transStngFG" [Title]="'COB HC Req Qualifier'" [MaxLength]="2">
                                    </eprx-input>
                                </div>
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <eprx-input [LabelText]="'Force COB Seg for Secondary Billing?'" [PlaceHolder]="'Force COB Seg for Sec Billing'" [ControlName]="'ForceCoBSeg'"
                                        [FormGroupName]="transStngFG" [Title]="'Force COB Seg for Sec Billing'" [MaxLength]="1">
                                    </eprx-input>
                                </div>
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <eprx-select [LabelText]="'COB Method'" [PlaceHolder]="'COB Method'" [ControlName]="'COBMethodId'" [FormGroupName]="transStngFG"
                                        [HasMultiple]="false" [Title]="'COB Method'" [HasBindLabel2]="true" [BindLabel]="'Name'" [Show2Values]="true"
                                        [BindLabel2]="'Remarks'" [ShowLabel2]="true" [BindValue]="'Id'" [LabelForId]="'Id'"
                                        [List]="dropDownData ? dropDownData.COBMethod : ''">
                                    </eprx-select>
                                </div>
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
                                    <label class="col-md-12 padding-0"> Force OPPRA Method for OCC=08?</label>
                                    <eprx-radio [List]="['Yes', 'No']" [ValueList]="[true, false]" [IDForList]="['a', 'b']" [LabelForId]="" [ControlName]="'IsOPPRAFoced'"
                                        [FormGroupName]="transStngFG">
                                    </eprx-radio>
                                </div>
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
                                    <label class="col-md-12 padding-0"> Send Other Coverage Code(C8) when Blank?</label>
                                    <eprx-radio [List]="['Yes', 'No']" [ValueList]="[true, false]" [IDForList]="['c', 'd']" [LabelForId]="" [ControlName]="'IsOthConvCodeSent'"
                                        [FormGroupName]="transStngFG">
                                    </eprx-radio>
                                </div>
                                <div class="row mx-0">
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                        <label class="col-md-12 padding-0"> Send Sub. Clarif. Code(DK) when Blank?</label>
                                        <eprx-radio [List]="['Yes', 'No']" [ValueList]="[true, false]" [IDForList]="['e', 'f']" [LabelForId]="" [ControlName]="'IsSubClarifCodeSent'"
                                            [FormGroupName]="transStngFG">
                                        </eprx-radio>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 px-0">
                                        <label class="col-md-12 padding-0">Send Leading Zero for 0th Cov.Code(C8)?</label>
                                        <eprx-radio [List]="['Yes', 'No']" [ValueList]="[true, false]" [IDForList]="['g', 'h']" [LabelForId]="" [ControlName]="'IsLeadingZeroSent'"
                                            [FormGroupName]="transStngFG">
                                        </eprx-radio>
                                    </div>
                                </div>
                                <div class="col-md-12 messsage-height">
                                    <label class="col-md-12 padding-0 "> Force Qualifier 06 for OPPRA Method?</label>
                                    <eprx-radio [List]="['Yes', 'No']" [ValueList]="[true, false]" [IDForList]="['q', 'r']" [LabelForId]="" [ControlName]="'IsQualifForced'"
                                        [FormGroupName]="transStngFG" (keydown.tab)="focusToSave($event)">
                                    </eprx-radio>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2 col-sm-2 col-xs-2">
                        <div class="exprx--common-block">

                            <div class="box box-default box-solid">
                                <div class="trans-block-header">
                                    <div class="eprx--header__heading">
                                        Other Coverage Code
                                    </div>
                                </div>
                                <div style="padding-top: 10px">

                                </div>
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <eprx-input [LabelText]="'Default Other Coverage Code'" [PlaceHolder]="'Default Other Coverage Code'" [ControlName]="'DefOthrCovCode'"
                                        [FormGroupName]="transStngFG" [Title]="'Default Other Coverage Code'" [MaxLength]="2">
                                    </eprx-input>
                                </div>
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <eprx-input [LabelText]="'Other Payer Coverage Code'" [PlaceHolder]="'Other Payer Coverage Code'" [ControlName]="'OthrPayorCovCode'"
                                        [FormGroupName]="transStngFG" [Title]="'Other Payer Coverage Code'" [MaxLength]="2">
                                    </eprx-input>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-3 col-xs-12">
                        <div class="exprx--common-block">

                            <div class="box box-default box-solid">
                                <div class="trans-block-header">
                                    <div class="eprx--header__heading">
                                        Other
                                    </div>
                                </div>
                                <div style="padding-top: 10px">

                                </div>
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <eprx-select [LabelText]="'Batch Quantity'" [PlaceHolder]="'Batch Quantity'" [ControlName]="'BatchQty'" [FormGroupName]="transStngFG"
                                        [HasMultiple]="false" [Title]="'Batch Quantity'" [HasBindLabel2]="false" [BindLabel]="'value'"
                                        [BindValue]="'value'" [LabelForId]="'Id'" [List]="[{value: 1}, {value: 2}, {value: 3}, {value: 4}]">
                                    </eprx-select>
                                </div>
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <eprx-input [LabelText]="'V. Certification'" [PlaceHolder]="'V. Certification'" [ControlName]="'VendCert'" [FormGroupName]="transStngFG"
                                        [Title]="'V. Certification'" [MaxLength]="10">
                                    </eprx-input>
                                </div>
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <eprx-input [LabelText]="'Default Place of Service'" [PlaceHolder]="'Default Place of Service'" [ControlName]="'DefServPlace'"
                                        [FormGroupName]="transStngFG" [Title]="'Default Place of Service'" [MaxLength]="2">
                                    </eprx-input>
                                </div>
                                <div class="col-md-12 messsage-height">
                                    <label class="col-md-12 padding-0" title="Prior Approval Support"> P/A Support</label>
                                    <eprx-radio [List]="['Yes', 'No']" [ValueList]="[true, false]" [IDForList]="['i', 'j']" [ControlName]="'IsPASupport'" [Name]="'IsPASupport'"
                                        [FormGroupName]="transStngFG">
                                    </eprx-radio>

                                </div>
                                <div class="col-md-12 messsage-height">
                                    <label class="col-md-12 padding-0"> Rebill Support(B3)</label>
                                    <eprx-radio [List]="['Yes', 'No']" [ValueList]="[true, false]" [IDForList]="['k', 'l']" [LabelForId]="" [ControlName]="'IsRebillSupport'"
                                        [FormGroupName]="transStngFG">
                                    </eprx-radio>
                                </div>
                                <!-- <div class="col-md-12 messsage-height">
                                    <label class="col-md-12 padding-0"> Allow Partial Fill? </label>
                                    <eprx-radio [List]="['Yes', 'No']" [ValueList]="[true, false]" [IDForList]="['m', 'n']" [LabelForId]="" [ControlName]="'IsPartialFill'"
                                        [FormGroupName]="transStngFG">
                                    </eprx-radio>
                                </div> -->

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-ncpdp-payerset *ngIf="openNCDPE" [From]="'Insurance'" [PopUpType]="'EDIT'" (setPopUpValue)="openNCDPEdit(false)" [NCPDPData]="ncpdpInfo"></app-ncpdp-payerset>
