<div class="exprx--common-block">
    <div class="eprx--block__header">
        <div class="eprx--header__heading">Pricing & A/R</div>
    </div>
    <div class="eprx--block__content">
        <div class="row">
            <div class="col">
                <eprx-select
                    [LabelText]="'Price Code Brand'"
                    [PlaceHolder]="'Price Code Brand'"
                    [ControlName]="'BrandPriceSchId'"
                    [secondCntrlN]="'BrandPriceCode'"
                    [FormGroupName]="PatPricings"
                    [InputErrors]="BrandPriceSchId?.errors"
                    [ErrorDefs]="{ required: 'Required' }"
                    [List]="priceScheduleList"
                    [BindLabel]="'PriceCode'"
                    [BindLabel2]="'Name'"
                    [BindValue]="'Id'"
                    [bindScnCntrlV]="'PriceCode'"
                    [LabelForId]="'Name'"
                    [DropdownPosition]="'top'"
                    [Show2Values]="true"
                >
                </eprx-select>
            </div>
            <div class="col">
                <eprx-select
                    [LabelText]="'Price Code Generic'"
                    [PlaceHolder]="'Price Code Generic'"
                    [ControlName]="'GenPriceSchId'"
                    [secondCntrlN]="'GenericPriceCode'"
                    [FormGroupName]="PatPricings"
                    [InputErrors]="GenPriceSchId?.errors"
                    [ErrorDefs]="{ required: 'Required' }"
                    [List]="priceScheduleList"
                    [BindLabel]="'PriceCode'"
                    [BindLabel2]="'Name'"
                    [BindValue]="'Id'"
                    [bindScnCntrlV]="'PriceCode'"
                    [LabelForId]="'Name'"
                    [DropdownPosition]="'top'"
                    [Show2Values]="true"
                >
                </eprx-select>
            </div>
        </div>

        <div class="row">

            <div class="col-md-6">
                <eprx-select
                    [LabelText]="'Discount Code'"
                    [PlaceHolder]="'Discount Code'"
                    [ControlName]="'DiscSchId'"
                    [secondCntrlN]="'DiscCode'"
                    [FormGroupName]="PatPricings"
                    [InputErrors]="DiscSchId?.errors"
                    [ErrorDefs]="{ required: 'Required' }"
                    [List]="discPriceDrops"
                    [BindLabel]="'DiscCode'"
                    [BindLabel2]="'Name'"
                    [BindValue]="'Id'"
                    [bindScnCntrlV]="'DiscCode'"
                    [LabelForId]="'Name'"
                    (keydown.tab)="focusToFirst($event)"
                    [DropdownPosition]="'top'"
                    [Show2Values]="true"
                >
                </eprx-select>
            </div>
            <!-- <div class="col">
                <label class="col-md-12 padding-0"> Applied To </label>
                <eprx-radio
                (keydown.tab)="focusToFirst($event)"
                    [ControlName]="'IsAppliedCashRx'"
                    [FormGroupName]="PatPricings"
                    [List]="['Cash', 'All Rx\'s']"
                    [ValueList]="[true, false]"
                    [IDForList]="['1', '2']"
                    [LabelForId]=""
                >
                </eprx-radio>
            </div> -->
        </div>
    </div>
</div>
