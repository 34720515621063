import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from "@angular/core";
import { DrugUtils } from "src/app/utils";
import * as wjcGrid from "@grapecity/wijmo.grid"
import { FormBuilder, FormGroup } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NdcOccHeaders } from "../../drug.constants";
import { PaginationModel } from "src/app/models/elastc-rx.model";

@Component({
    selector: "app-drug-ndcocc",
    templateUrl: "./drug-ndcocc.component.html"
})
export class DrugNdcoccComponent implements OnInit {
    @Input() drugCode: any;
    @Output() IsPopUpClosed = new EventEmitter<any>();

    @ViewChild("NDCOOC", { static: true })
    NDCOOC: any;
    paginationModel: PaginationModel = new PaginationModel();
    pageNu = this.paginationModel.pageNumber;
    Size = this.paginationModel.pageSize;
    ndcOccData = { wijimoSource: null, Count: 0};
    NDCFG: FormGroup;
    NDCPOP: any;
    actvHeaders = NdcOccHeaders;

    constructor(private _drugutils: DrugUtils, private _fb: FormBuilder, private _modalSvc: NgbModal) {
        this.NDCFG = this._fb.group({
            IsCompound: true
        });
     }

    ngOnInit() {
        this.getNdcOccData();
        this.NDCPOP = this._modalSvc.open(this.NDCOOC, {
            size: "lg",
            centered: true,
            keyboard: false
        });
    }

    async getNdcOccData() {
        this.ndcOccData = await this._drugutils.getNdcoccData(true, this.drugCode, this.pageNu, this.Size);
    }

    init(flex: wjcGrid.FlexGrid) {
        flex.columnHeaders.rows[0].wordWrap = true;
    }

    setPage(page: number) {
        this.pageNu = page;
        this.getNdcOccData();
    }
    setSize(size: any) {
        this.Size = size;
        this.pageNu = this.paginationModel.pageNumber;
        this.getNdcOccData();
    }

    closePopUp() {
        if (this.NDCPOP) {
            this.NDCPOP.close();
        }
        this.IsPopUpClosed.emit(null);
    }

}
