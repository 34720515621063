import { BaseUtils } from "src/app/utils/base.utils";
import { SystemSetting, KeyCodes } from "src/app/models";
import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    OnChanges,
    ElementRef,
    ViewChild,
    HostListener
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { NgbModal} from "@ng-bootstrap/ng-bootstrap";
import { CommonService, AlertService, KeyboardShortcuts, Unlisten } from "../../../../services";
import {
    PatientInfo,
    OtherAllergy,
    SystemData,
    Allergy,
    Drug
} from "../../../../models";
import { ActivatedRoute } from "@angular/router";
import { NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";
import { MsgConfig } from "../../../../app.messages";
import { Observable } from "rxjs/observable";
import { Subject } from "rxjs";
import { CollectionView } from "@grapecity/wijmo";
import * as wjcGrid from "@grapecity/wijmo.grid"
import { PatientStore } from "src/app/store";
import { CreateNPatchFG } from "src/app/utils";
import { PatientInfoUtil } from "src/app/utils/patient-info.util";
import { takeUntil } from "rxjs/operators";
import * as moment from "moment";


@Component({
    selector: "app-allergies",
    templateUrl: "./allergies.component.html",
    styles: []
})
export class PAllergiesComponent implements OnInit, OnChanges {
    patientInfo: PatientInfo;
    drugSuggestData: any[];
    patientID: number;
    selectedValue: string;
    modalRef: any;
    addPatient = false;
    addPatientAllergies: Allergy[] = [];
    addPatientDrugAllergies: Drug[] = [];
    addPatientOtherAllergies: OtherAllergy[] = [];
    selectedAllergyList: Allergy[] = [];
    selectedDrugAllergyList: Drug[] = [];
    selectedOtherAllergyList: OtherAllergy[] = [];
    allergyList: wjcGrid.FlexGrid;
    AllergyWJ: CollectionView;
    allergyHeader: string[];
    hasAllegry: boolean;
    drugFocus: boolean;
    selectedListWJ: CollectionView;
    actvHeader: string[];
    hasselectedList: boolean;
    selectWJ: wjcGrid.FlexGrid;
    fakelist = [0, 1, 2, 3 , 4, 5, 6, 7, 8, 9];
    resetDrugName = false;
    unsubscribe$: Subject<void> = new Subject();

    @ViewChild("content")
    content: any;

    @ViewChild("allergey", { static: true })
    allergey: any;
    today: any;

    @Input()
    set PatientInfo(data) {
        if (data) {
            this.patientInfo = data;
            this.selectedAllergyList = Object.assign([], this.patientInfo.PatAllergies);
            this.selectedDrugAllergyList = Object.assign([], this.patientInfo.Drugs);
            this.selectedOtherAllergyList = Object.assign([], this.patientInfo.OtherAllergies);
            this.addPatient = true;
        } else {
            this.addPatient = true;
        }
    }

    @Input() patientInfoFG: FormGroup;
    @Input() systemData: SystemData;

    @HostListener("document:keydown", ["$event"])
    onKeydownHandler(event: KeyboardEvent) {
        if (event.key === "F2") {
            event.preventDefault();
            if (this.modalRef) {
                this.closePopModal();
                this.openAllergiesModal();
            } else {
                this.openAllergiesModal();
            }
        } else if (event.which === 27 && (this.modalRef)) { // esc
            if (this.modalRef) {
                this.closePopModal();
                this.closeModal();
            }
            event.preventDefault();
        }
    }

    constructor(
        private _fb: FormBuilder,
        private modalService: NgbModal,
        private _commonServ: CommonService,
        private _actvRoute: ActivatedRoute,
        private _baseUtls: BaseUtils,
        private _alertService: AlertService,
        keyboardShortcuts: KeyboardShortcuts,
        private _patStore: PatientStore,
        private _cnpFG: CreateNPatchFG,
        private _patUtils: PatientInfoUtil
    ) {
        this._actvRoute.queryParams.pipe(takeUntil(this.unsubscribe$)).subscribe(params => {
            this.addPatient = params["AddPatient"];
        });
        this.patientID = +this._actvRoute.snapshot.paramMap.get("pid");
    }

    ngOnInit() {
        if (this.addPatient) {
            this.saveDefaultAllergy();
        }
        this._patStore.isModalTriggered$.pipe(takeUntil(this.unsubscribe$)).subscribe(resp => {
            if (resp === "ALLERGIES") {
                this.closePopModal();
                this.openAllergiesModal();
            }
        });
        this.today = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss.S');
    }

    saveDefaultAllergy() {
        if (
            this._commonServ.getSetttingValue(
                "PatientSettings",
                "PAT_ALLERGY"
            )
        ) {
            const dftlAllergydata = this._commonServ.getSetttingValue(
                "PatientSettings",
                "PAT_ALLERGY"
            );
            const dftlAllergy = this.systemData["Allergies"].filter(
                (n: any) => n.AllergyName === dftlAllergydata
            );
            if (dftlAllergy && dftlAllergy.length > 0) {
                this.selectedAllergy(dftlAllergy[0]);
                this.emitAllergyData();
            }
        }
    }

    ngOnChanges() { }

    generateAllergyWJ(data) {
        if (!data) {
            data = [];
        }
        if (data) {
            this.hasAllegry = true;
            this.AllergyWJ = new CollectionView(
                data.map(allergy => {
                    const j = {};
                    j["Name"] = allergy.AllergyName ? allergy.AllergyName : "";
                    j["Code"] = (allergy.ClassId || allergy.ClassId === 0) ? allergy.ClassId : "";
                    if (allergy.ClassId || allergy.ClassId === 0) {
                        let selectedAllergyListValue: any;
                        if (this.addPatient) {
                            selectedAllergyListValue = this.addPatientAllergies;
                        } else {
                            selectedAllergyListValue = this.patientInfo.PatAllergies;
                        }
                    if (selectedAllergyListValue.filter(
                        (n: any) => n.AllergyClassID === allergy.ClassId
                    ).length > 0) {
                        j["IsSelected"] = true;
                    } else {
                        j["IsSelected"] = false;
                    }
                    } else {
                        j["IsSelected"] = false;
                    }
                    return j;
                })
            );
            this.allergyHeader = [
                "Name",
                "Code"
            ];
        } else {
            this.hasAllegry = false;
        }
    }

    generateSelectedAllergyWJ() {
        const selectedList: any = [];
        if (this.addPatient) {
            if (this.addPatientAllergies && this.addPatientAllergies.length > 0) {
                this.addPatientAllergies.map(value => {
                    const data = {
                        Code : value["AllergyClassID"],
                        Name : value["AllergyDescription"],
                        Type : "A"
                    };
                    selectedList.push(data);
                });
            }

            if (this.addPatientDrugAllergies && this.addPatientDrugAllergies.length > 0) {
                this.addPatientDrugAllergies.map(value => {
                    const data = {
                        Code : value["DrugCode"],
                        Name : value["Name"],
                        Type : "D"
                    };
                    selectedList.push(data);
                });
            }

            if (this.addPatientOtherAllergies && this.addPatientOtherAllergies.length > 0) {
                this.addPatientOtherAllergies.map(value => {
                    const data = {
                        Code : value["Id"],
                        Name : value["Name"],
                        Type : "O"
                    };
                    selectedList.push(data);
                });
            }
        } else {
            if (this.patientInfo.PatAllergies && this.patientInfo.PatAllergies.length > 0) {
                this.patientInfo.PatAllergies.map(value => {
                    const data = {
                        Code : value["AllergyClassID"],
                        Name : value["AllergyDescription"],
                        Type : "A"
                    };
                    selectedList.push(data);
                });
            }

            if (this.patientInfo.Drugs && this.patientInfo.Drugs.length > 0) {
                this.patientInfo.Drugs.map(value => {
                    const data = {
                        Code : value["DrugCode"],
                        Name : value["Name"],
                        Type : "D"
                    };
                    selectedList.push(data);
                });
            }

            if (this.patientInfo.OtherAllergies && this.patientInfo.OtherAllergies.length > 0) {
                this.patientInfo.OtherAllergies.map(value => {
                    const data = {
                        Code : value["Id"],
                        Name : value["Name"],
                        Type : "O"
                    };
                    selectedList.push(data);
                });
            }
        }
        if (selectedList) {
            this.hasselectedList = true;
        } else {
            this.hasselectedList = false;
        }
        if (this.hasselectedList) {
            this.selectedListWJ = new CollectionView(
                selectedList.map(list => {
                    const j = {};
                    j["Code"] = list["Code"];
                    j["Description"] = list["Name"];
                    j["Type"] = list["Type"];
                    return j;
                })
            );
        }

        this.actvHeader = [
            "Code",
            "Description",
            "Type"
        ];
    }

    onRowDblclicked(s: any, e: any) {
        const ht = s.hitTest(e);
        if (s.selectedRows[0].dataItem && ht.cellType === 1) {
            if (!s.selectedRows[0].dataItem["IsSelected"]) {
                s.selectedRows[0].dataItem["IsSelected"] = true;
                const data = {
                    AllergyName: s.selectedRows[0].dataItem["Name"],
                    ClassId: s.selectedRows[0].dataItem["Code"]
                   };
                   this.selectedAllergy(data);
            } else {
                s.selectedRows[0].dataItem["IsSelected"] = false;
                this.deleteAllergy(s.selectedRows[0].dataItem["Code"], "A");
            }
        }
    }

    keyEventOnAllergyWijimo(grid) {
        if (this.hasAllegry) {
            event.preventDefault();
            if (!grid.selectedItems[0]["IsSelected"]) {
                grid.selectedItems[0]["IsSelected"] = true;
                const allergy = {
                    AllergyName: grid.selectedItems[0]["Name"],
                    ClassId: grid.selectedItems[0]["Code"]
                   };
                   this.selectedAllergy(allergy);
            } else {
                grid.selectedItems[0]["IsSelected"] = false;
                this.deleteAllergy(grid.selectedItems[0]["Code"], "A");
            }
        }
    }

    clickOnCheckBox(checkValue, item) {
        if (checkValue) {
            const allergy = {
                AllergyName: item["Name"],
                ClassId: item["Code"]
               };
               this.selectedAllergy(allergy);
        } else {
            this.deleteAllergy(item["Code"], "A");
        }
    }

    keyEventonSelectedWJ(grid) {
        if (this.hasAllegry) {
            event.preventDefault();
            this.deleteAllergy(grid.selectedItems[0]["Code"], grid.selectedItems[0]["Type"]);
        }
    }

    filterAllergy(text) {
        if (text) {
            text =  text.trim();
        }
        if (text.length >= 1) {
            this.generateAllergyWJ(
                this.systemData.Allergies.filter(v => {
                const val = v.AllergyName ? v.AllergyName : "";
                return val
                    .toLowerCase()
                    .startsWith(text.toLocaleLowerCase());
            }));
        } else {
            this.generateAllergyWJ(this.systemData["Allergies"]);
        }
    }

    focustoSelectedList(event) {
        event.preventDefault();
        if (this.selectWJ) {
            this.selectWJ.focus();
        }
    }

    focusOutFromSelectedList(event) {
        if (document.getElementById("okbutton")) {
            event.preventDefault();
            setTimeout(() => {
                document.getElementById("okbutton").focus();
            }, 20);
        }
    }

    focusOutFromCancel(event) {
        if (document.getElementById("Allergy Name")) {
            event.preventDefault();
            document.getElementById("Allergy Name").focus();
        }
    }

    focusOutFromAllergySearch(event) {
        this.drugFocus = false;
    }

    focusOutFromAllergyWJ(event, grid) {
        event.preventDefault();
        this.drugFocus = true;
    }

    init(flex: wjcGrid.FlexGrid) {
        this.allergyList = flex;
        flex.columnHeaders.rows[0].wordWrap = true;
    }

    init1(flex: wjcGrid.FlexGrid) {
        this.selectWJ = flex;
        flex.columnHeaders.rows[0].wordWrap = true;
    }

    openAllergiesModal() {
        if (this.addPatient) {
            this.selectedAllergyList = Object.assign([], this.addPatientAllergies);
            this.selectedDrugAllergyList = Object.assign([], this.addPatientDrugAllergies);
            this.selectedOtherAllergyList = Object.assign([], this.addPatientOtherAllergies);
        } else {
            this.selectedAllergyList = Object.assign([], this.patientInfo.PatAllergies);
            this.selectedDrugAllergyList = Object.assign([], this.patientInfo.Drugs);
            this.selectedOtherAllergyList = Object.assign([], this.patientInfo.OtherAllergies);
        }
        this.generateAllergyWJ(this.systemData["Allergies"]);
        this.generateSelectedAllergyWJ();
        this.modalRef = this.modalService.open(this.allergey, { size: "lg" , backdrop: false,
        keyboard: false});
    }

    saveOtherAllergValue(eve) {
        const val = eve.target.value;
        if (val) {
            eve.target.value = "";
            if (this.addPatient) {
                this.addPatientOtherAllergies.push({
                    Name: val,
                    Id: null,
                    LstConfDttm : this.today
                });
            } else {
                const otherAllergyData: any[] = this.patientInfo.OtherAllergies
                    ? this.patientInfo.OtherAllergies
                    : [];

                const isOtherAllergyExists: any =
                    otherAllergyData.filter((n: any) => n.Name === val).length >
                    0;

                if (!isOtherAllergyExists) {
                    otherAllergyData.push({
                        Name: val
                    });
                }
                this.patientInfo.OtherAllergies = otherAllergyData;
            }
            this.generateSelectedAllergyWJ();
        }
    }

    emitAllergyData() {
        if (this.addPatient) {
            this.selectedAllergyList = Object.assign([], this.addPatientAllergies);
            this.selectedDrugAllergyList = Object.assign([], this.addPatientDrugAllergies);
            this.selectedOtherAllergyList = Object.assign([], this.addPatientOtherAllergies);
        } else {
            this.selectedAllergyList = Object.assign([], this.patientInfo.PatAllergies);
            this.selectedDrugAllergyList = Object.assign([], this.patientInfo.Drugs);
            this.selectedOtherAllergyList = Object.assign([], this.patientInfo.OtherAllergies);
        }
        this.saveAllergyData();
        this._patStore.clearTriggerModalTypeToShow();
    }

    closeModal() {
        if (this.addPatient) {
        this.addPatientAllergies = Object.assign([], this.selectedAllergyList);
        this.addPatientDrugAllergies = Object.assign([], this.selectedDrugAllergyList);
        this.addPatientOtherAllergies = Object.assign([], this.selectedOtherAllergyList);
        } else {
            this.patientInfo.PatAllergies = Object.assign([], this.patientInfo.PatAllergies);
            this.patientInfo.Drugs = Object.assign([], this.selectedDrugAllergyList);
            this.patientInfo.OtherAllergies = Object.assign([], this.selectedOtherAllergyList);
        }
    }

    selectedDrugInfo(data) {
        if (!data) {
            return;
        }
        if (data.drugname) {
            this.resetDrugName = true;
        } else {
            this.resetDrugName = false;
        }
        if (this.addPatient) {
            const drugmodal: Drug = new Drug();
            drugmodal.Name = data.drugname;
            drugmodal.DrugCode = data.drugcode;
            this.addPatientDrugAllergies.push(drugmodal);
        } else {
            const drugAllergyData: any[] = this.patientInfo.Drugs
                ? this.patientInfo.Drugs
                : [];

            const isDrugAllergyExists: any =
                drugAllergyData.filter((n: any) => n.DrugCode === data.drugcode)
                    .length > 0;

            if (!isDrugAllergyExists) {
                drugAllergyData.push({
                    DrugCode: data.drugcode,
                    Name: data.drugname
                });
            } else {
                this._alertService.error("Selected allergy already exists.");
                this.resetDrugName = true;
            }
            this.patientInfo.Drugs = drugAllergyData;
        }
        this.generateSelectedAllergyWJ();
    }

    selectedAllergy(data) {
        if (this.addPatient) {
            if (this.addPatientAllergies.length > 0) {
                const isAllergyExists: any =
                this.addPatientAllergies.filter(
                        (n: any) => n.AllergyClassID === data.ClassId
                    ).length > 0;
                if (!isAllergyExists) {
                    this.addPatientAllergies.push({
                        AllergyClassID: data.ClassId,
                        AllergyName: data.AllergyName,
                        AllergyDescription: data.AllergyName,
                        LstConfDttm : this.today
                    });
                } else {
                    this._alertService.error(MsgConfig.PATIENT_ALLERGY_EXISTS);
                }
            } else {
                this.addPatientAllergies.push({
                    AllergyClassID: data.ClassId,
                    AllergyName: data.AllergyName,
                    AllergyDescription: data.AllergyName,
                    LstConfDttm : this.today
                });
            }
        } else {
            let allergyData: any[];
            if (this.patientInfo) {
                allergyData = this.patientInfo.PatAllergies && this.patientInfo.PatAllergies.length > 0
                    ? this.patientInfo.PatAllergies
                    : [];
            } else {
                allergyData = [];
            }

            if (allergyData.length > 0) {
                const isAllergyExists: any =
                    allergyData.filter(
                        (n: any) => n.AllergyClassID === data.ClassId
                    ).length > 0;
                if (!isAllergyExists) {
                    allergyData.push({
                        AllergyClassID: data.ClassId,
                        AllergyName: data.AllergyName,
                        AllergyDescription: data.AllergyName
                    });
                } else {
                    this._alertService.error(MsgConfig.PATIENT_ALLERGY_EXISTS);
                }
            } else {
                allergyData.push({
                    AllergyClassID: data.ClassId,
                    AllergyName: data.AllergyName,
                    AllergyDescription: data.AllergyName
                });
            }
            this.patientInfo.PatAllergies = allergyData;
        }
        this.generateSelectedAllergyWJ();
    }

    deleteAllergy(allergy: string, allergType: string) {
        if (this.addPatient) {
            if (allergType === "A") {
                const allergies: any = this.addPatientAllergies.filter(
                    (n: any) => n.AllergyClassID !== allergy
                );
                this.addPatientAllergies = allergies;
            } else if (allergType === "D") {
                const drugAllergies: any = this.addPatientDrugAllergies.filter(
                    (n: any) => n.DrugCode !== allergy
                );
                this.addPatientDrugAllergies = drugAllergies;
            } else if (allergType === "O") {
                const otherAllergies: any = this.addPatientOtherAllergies.filter(
                    (n: any) => n.Name !== allergy
                );
                this.addPatientOtherAllergies = otherAllergies;
            }
        } else {
            if (allergType === "A") {
                const allergies: any = this.patientInfo.PatAllergies.filter(
                    (n: any) => n.AllergyClassID !== allergy
                );
                this.patientInfo.PatAllergies = allergies;
            } else if (allergType === "D") {
                const drugAllergies: any = this.patientInfo.Drugs.filter(
                    (n: any) => n.DrugCode !== allergy
                );
                this.patientInfo.Drugs = drugAllergies;
            } else if (allergType === "O") {
                const otherAllergies: any = this.patientInfo.OtherAllergies.filter(
                    (n: any) => n.Name !== allergy
                );
                this.patientInfo.OtherAllergies = otherAllergies;
            }
        }
        this.generateSelectedAllergyWJ();
    }

    deleteSelectedAllergy(allergy: string, allergType: string) {
        if (allergType === "allergy") {
            const allergies: any = this.selectedAllergyList.filter(
                (n: any) => n.AllergyClassID !== allergy
            );
            this.selectedAllergyList = allergies;
        } else if (allergType === "drug") {
            const drugAllergies: any = this.selectedDrugAllergyList.filter(
                (n: any) => n.DrugCode !== allergy
            );
            this.selectedDrugAllergyList = drugAllergies;
        } else if (allergType === "other") {
            const otherAllergies: any = this.selectedOtherAllergyList.filter(
                (n: any) => n.Name !== allergy
            );
            this.selectedOtherAllergyList = otherAllergies;
        }
        this.saveAllergyData();
    }

    selectedResult(val) {
        this.selectedValue = val;
    }

    formatter = (x: any) => {
        return "";
    }

    saveAllergyData() {
        const patientInfo = this.patientInfoFG.getRawValue();
        if (this.selectedAllergyList && this.selectedAllergyList.length === 0) {
            patientInfo.PatAllergies = null;
        } else {
            patientInfo.PatAllergies = this.selectedAllergyList;
        }
        if (this.selectedDrugAllergyList && this.selectedDrugAllergyList.length === 0) {
            patientInfo.Drugs = null;
        } else {
            patientInfo.Drugs = this.selectedDrugAllergyList;
        }
        if (this.selectedOtherAllergyList && this.selectedOtherAllergyList.length === 0) {
            patientInfo.OtherAllergies = null;
        } else {
            patientInfo.OtherAllergies = this.selectedOtherAllergyList;
        }
        if (this.addPatient) {
            this._patStore.storePatientInfo(patientInfo);
        }
        this._patUtils.patchPatientInfo(patientInfo, this.patientInfoFG);
    }

    generateFA(key: string, values: any[]) {
        const addressFGs = values.map(val => this._fb.group(val));
        const addressFormArray = this._fb.array(addressFGs);
        this.patientInfoFG.setControl(key, addressFormArray);
    }

    closePopModal() {
        if (this.modalRef) {
            this.modalRef.close();
            this.modalRef = null;
        }
    }
    ngOnDestroy(){
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

}
