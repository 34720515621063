import { Component, OnInit, Input, Output, EventEmitter, ViewChild, HostListener } from "@angular/core";
import { AlertService } from "src/app/services";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PrintService } from "src/app/services/print.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import * as moment from "moment";
import { PrimeEscService } from "src/app/services/prime-esc.service";
import * as wjcCore from "@grapecity/wijmo";
import * as wjcGrid from "@grapecity/wijmo.grid"
import { DomSanitizer } from "@angular/platform-browser";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
    selector: "app-patient-sig-log",
    templateUrl: "./patient-sig-log.component.html"
})
export class PatientSigLogComponent implements OnInit {
    patientInfo: any;
    modalRef: any;

    @ViewChild("PatSignLog", { static: true })
    PatSignLog: any;

    @ViewChild("signReport", { static: true })
    signReport: any;

    actvModal: string;
    InFiltersFrmGrp: FormGroup;
    today: any;
    minDate: any;
    disableFilters = false;
    transactionHistory = [];
    transHistHeaders: string[];
    tarsnHistWJ: wjcCore.CollectionView;
    pdfContent: any;
    sigReport: any;
    modalRefSM: any;
    unsubscribe$: Subject<void> = new Subject();

    @Input()
    set PatientInfo(patInfo: any) {
        this.patientInfo = patInfo;
    }

    @Output()
    IsPopUpClosed = new EventEmitter<any>();

    @HostListener("window:keydown", ["$event"])
    keyEventAlt(event: KeyboardEvent) {
        if (event.which === 27 && this._modalService.hasOpenModals()) {
            if (this.modalRef) {
                this.closeModal();
                event.preventDefault();
            } else if (this.modalRefSM) {
                this.modalRefSM.close();
                this.modalRefSM = null;
                event.preventDefault();
            }
        }
    }

    constructor(
        private _alertSrv: AlertService,
        private _modalService: NgbModal,
        private _printSer: PrintService,
        private _fb: FormBuilder,
        private _primeEscSer: PrimeEscService,
        private _sanitizer: DomSanitizer,
        private _printServ: PrintService


    ) { }

    ngOnInit() {
      

        this.today = moment().format("MM/DD/YYYY");
        this.minDate = {
            year: moment(this.today).year(),
            month: moment(this.today).month() + 1,
            day: moment(this.today).date()
        };
        this.createFg();

        this.transHistHeaders = [
            "TRANS NO",
            "TRANSDATE",
            "INSTYPE",
            "RXs",
            "Counseling",
            "SIGDATA",
            "BINARY",
            "SigType",
            "TRANSSIGDATA"
        ];
        this.openPatientSignLog();
    }

    createFg() {
        this.InFiltersFrmGrp = this._fb.group({
            patientId: [this.patientInfo ? this.patientInfo.patientid : null],
            rxno: [],
            insutype: [],
            fromdate: [this.today],
            todate: [this.today]
        });
    }

    searchFilters(single?) {
        let dataToPost = this.InFiltersFrmGrp.value;
        this._primeEscSer.getSignTransHist(dataToPost).pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp) {
                    if (single && resp.length) {
                        if(!(this.transactionHistory && this.transactionHistory.length && this.transactionHistory.find(v => v["Id"] === resp[0]["Id"]))) {
                            this.transactionHistory = this.transactionHistory.concat(resp);
                        } else {
                            this.transactionHistory = resp;
                        }
                    } else {
                        this.transactionHistory = resp;
                    }
                    this.InFiltersFrmGrp.controls.rxno.patchValue(null);
                    this.generateTransHistWJ();
                }
            });
    }

    generateTransHistWJ() {
        this.tarsnHistWJ = new wjcCore.CollectionView(
            this.transactionHistory.map((transaction, i) => {
                const j = {};
                j["TRANS NO"] = transaction.Id;
                j["TRANSDATE"] = transaction.CreatedDtTm;
                j["INSTYPE"] = transaction.InsType;
                j["RXs"] = transaction.TranSignatureData;
                j["Counseling"] = transaction.CounselingReq;
                j["SIGDATA"] = (transaction && transaction.BinarySign) ? transaction.BinarySign :  null;
                j["BINARYSIGN"] = "System.Byte[]";
                j["SigType"] = transaction.SigType;
                j["TRANSSIGDATA"] = "System.Byte[]";
                return j;
            })
        );
    }

    openPatientSignLog() {
        this.searchFilters();
        this.modalRef = this._modalService.open(this.PatSignLog, {
            size: "lg",
            backdrop: false,
            keyboard: false
        });
        this.actvModal = "Patient Log";
    }

    clearData() {
        this.InFiltersFrmGrp.controls["insutype"].reset();
        this.InFiltersFrmGrp.controls["rxno"].patchValue("");
        this.InFiltersFrmGrp.controls["fromdate"].patchValue(this.today);
        this.InFiltersFrmGrp.controls["todate"].patchValue(this.today);
        this.transactionHistory = [];
        this.generateTransHistWJ();
    }

    closeModal() {
        if (this.modalRef) {
            this.modalRef.close();
            this.modalRef = null;
        }
        this.IsPopUpClosed.emit(null);
    }

    optedParticularRx(value) {
        this.disableFilters = value;
        if (value) {
            this.InFiltersFrmGrp.patchValue({
                insutype: null,
                fromdate: null,
                todate: null
            });
        } else {
            this.InFiltersFrmGrp.controls.fromdate.patchValue(this.today);
            this.InFiltersFrmGrp.controls.todate.patchValue(this.today);
        }
    }

    enteredRxNo(eve?) {
        this.searchFilters(true);
    }

    previewReport(printReport) {
        if (this.transactionHistory && this.transactionHistory.length > 0) {
            let ids = [];
            this.transactionHistory.forEach(elm => {
                ids.push(elm.Id);
            });
            let dataToPost = {
                transId: ids,
                fromdate: null,
                todate: null
            };

            if (!this.disableFilters) {
                dataToPost["fromdate"] = this.InFiltersFrmGrp.value.fromdate;
                dataToPost["todate"] = this.InFiltersFrmGrp.value.todate;
            }
            this._primeEscSer.getSignatureReport(dataToPost).pipe(takeUntil(this.unsubscribe$))
                .subscribe(resp => {
                    this.sigReport = resp;
                    if (printReport) {
                        this.print(this.sigReport);
                    } else {
                        const urlCreator = window.URL;
                        this.pdfContent = this._sanitizer.bypassSecurityTrustResourceUrl(
                            urlCreator.createObjectURL(resp)+"#toolbar=0"
                        );
                        this.modalRefSM = this._modalService.open(this.signReport, {
                            size: "lg",
                            centered: true,
                            keyboard: false,
                            backdrop: false
                        });
                    }
                });
        } else {
            this._alertSrv.error("No records to preview");
        }
    }


    print(datToPrint) {
        const newBlob = new Blob([datToPrint], { type: "application/pdf" });
        const reader = new FileReader();
        reader.readAsDataURL(newBlob);
        reader.onloadend = function () {
            const base64data = reader.result;
            this._printServ.printPdf(base64data);
            this.closeModal();
        }.bind(this);
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
