/*  use this as reference for select dropdown.
        BindLabel: Label name to show in dropdown.
        BindValue: Id to attach to the label (Id value saved to DB)
        LabelForId: To attach Label Id and For value inorder to identify select in DOM
        HasMultiple: To allow multiple selection
        List: Drop down list to show
        IsDisabled: To disable select dropdown
*/

import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, ChangeDetectorRef, OnChanges, SimpleChanges } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ValidateFormFieldsUtils } from "src/app/utils";
import { SortByProp } from "src/app/pipes";
import { NgSelectComponent } from "@ng-select/ng-select";
import * as _ from "lodash";
import {
    distinctUntilChanged
} from "../../../../../node_modules/rxjs/operators";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AccCategoryE, EditRxE, RxEntryE } from "src/app/models";
import { PrivMaskGuard } from "src/app/guards";
import { AlertService } from "src/app/services";
import { MsgConfig } from "src/app/app.messages";

@Component({
    // tslint:disable-next-line:component-selector
    selector: "eprx-select",
    templateUrl: "./select.component.html"
})
export class SelectComponent implements OnInit, OnChanges {
    hasFGInvalid: boolean;
    autoFocus: boolean;
    labelTitle1: string;
    labelTitle2: string;
    labelTitle3: string;
    bindlabel3: string;
    hasbindlabel3 = false;
    rxID = "select";
    dropdownOpen = false;
    isOpen = false;
    isFirstElem: any;
    isSearchable = true;
    hasbindlabelMr: boolean;
    BindValueList: any[];
    addItem = false;
    isFocus: boolean;
    selectOnTab = true;
    showDefaultValue: boolean;
    filterOnFocus: boolean;
    prevFilterValue: any;
    bindLabelWidth = 10;
    bindLabelWidth2 = 10;
    bindLabelWidth3 = 10;
    focusOnSelect: boolean;
    isTitle = false;
    bindlabel4: string;
    hasbindlabel4: boolean;
    hasbindlabel5:boolean;
    labelTitle4: string;
    dropdownPosition: string;
    ngSelect: any;
    labelTitle5: string;
    labelTitle6: string;
    bindlabel5: string;
    bindlabel6: string;
    showlabel3: boolean;
    @Input() smsTooltipForLongTitles = false;
    @Input() IsCardView: boolean;
    @Input()
    set LabelText(lt: string) {
        this.labelText = lt;
    }

    @Input()
    set DropdownPosition(lt: string) {
        this.dropdownPosition = lt;
    }

    @Input()
    set IsTitle(lt: boolean) {
        this.isTitle = lt;
    }

    @Input()
    set FormGroupName(fg: FormGroup) {
        this.inputFormGroup = fg;
    }

    @Input()
    set ControlName(cn: string) {
        this.custContrlName = cn;
    }

    @Input()
    set PlaceHolder(ph: string) {
        this.placeHolder = ph;
    }

    @Input()
    set InputErrors(ie: any) {
        this.inputErrors = ie;
    }

    @Input()
    set ErrorDefs(ed: object) {
        this.errorDefs = ed;
    }

    @Input() IsAppendTo: boolean;

    @Input()
    set IsDisabled(ir: boolean) {
        this.isDisabled = ir;
        if (this.isDisabled) {
            this.isOpen = true;
            this._cd.detectChanges();
            this.isOpen = false;
            // if (this.isDisabled) {
            //     this.inputFormGroup.controls[this.custContrlName].disable();
            // } else {
            //     this.inputFormGroup.controls[this.custContrlName].enable();
            // }
        }
    }

    @Input()
    set List(ls: any[]) {
        if (ls && ls.length) {
            this.dropdownList = ls as any[];
            this.filterdList = ls;
        } else {
            this.dropdownList = [];
            this.filterdList = [];
        }
    }

    @Input()
    set AddItem(data: boolean) {
        this.addItem = data;
    }

    @Input()
    set BindLabel(hm: string) {
        this.bindLabel = hm;
    }

    @Input()
    set ShowDefaultValue(data: boolean) {
        if (data) {
        this.showDefaultValue = true;
        } else {
        this.showDefaultValue = false;
        }
    }

    @Input()
    set BindValue(hm: string) {
        this.bindValue = hm;
    }

    @Input()
    set BindLabel2(hm: string) {
        this.bindLabel2 = hm;
    }

    @Input()
    set LabelForId(hm: string) {
        this.labelForId = hm;
    }

    @Input()
    set HasMultiple(hm: boolean) {
        if (hm) {
            this.selectOnTab = false;
        }
        this.hasMultiple = hm;
    }

    @Input()
    set HasBindLabelM(hb: boolean) {
        this.hasbindlabelMr = hb;
    }

    @Input()
    set BindValueML(ls: any[]) {
        this.BindValueList = ls as any[];
    }

    @Input()
    set HasBindLabel2(hb: boolean) {
        this.hasBindLabel2 = hb;
    }
    @Input()
    set HasControl(hc: boolean) {
        this.hasControl = hc;
    }

    @Input()
    set IsRequired(ir: boolean) {
        this.isRequired = ir;
    }
    @Input()
    set Show2Values(ir: boolean) {
        this.show2values = ir;
    }
    @Input()
    set Show3Values(ir: boolean) {
        this.show3values = ir;
    }
    @Input()
    set ShowLabel2(ir: boolean) {
        this.showlabel2 = ir;
    }
    @Input()
    set ShowLabel3(ir: boolean) {
        this.showlabel3 = ir;
    }
    @Input()
    set Title(title: string) {
        this.titleText = title;
    }

    @Input()
    set FormGroupInvalid(hc: boolean) {
        this.hasFGInvalid = hc;
        if (this.hasFGInvalid) {
            this.validateAllFormFields(
                this.inputFormGroup,
                this.custContrlName
            );
        }
    }

    @Input()
    set TabIndex(ti: number) {
        if (ti) {
            this.tabIndex = +ti;
        }
    }

    @Input()
    set RxSelectId(id: any) {
        this.rxID = id;
    }

    @Input()
    set AutoFocus(ti: boolean) {
        this.autoFocus = ti;
    }

    @Input()
    set IsResetDropdownID(idForReset: string) {
        if(idForReset) {
            this.resetDropdownData(idForReset);
        }
    }


    @Input()
    set MarkAsTouched(mt: boolean) {
        if (mt) {
            this.validateAllFormFields(
                this.inputFormGroup,
                this.custContrlName
            );
        } else {
            this.errorMessage = null;
        }
    }

    @Input()
    set LabelTitle1(fg: string) {
        this.labelTitle1 = fg;
    }

    @Input()
    set LabelTitle2(fg: string) {
        this.labelTitle2 = fg;
    }
    @Input()
    set LabelTitle3(fg: string) {
        this.labelTitle3 = fg;
    }

    @Input()
    set LabelTitle4(fg: string) {
        this.labelTitle4 = fg;
    }

    @Input()
    set LabelTitle5(fg: string) {
        this.labelTitle5 = fg;
    }
    @Input()
    set LabelTitle6(fg: string) {
        this.labelTitle6 = fg;
    }
    @Input()
    set InputValue(iv: any) {
        this.inputValue = iv;
    }

    @Input()
    set BindLabel3(iv: string) {
        this.bindlabel3 = iv;
    }

    @Input()
    set hasBindLabel3(iv: boolean) {
        this.hasbindlabel3 = iv;
    }

    @Input()
    set BindLabel4(iv: string) {
        this.bindlabel4 = iv;
    }
    @Input()
    set BindLabel5(iv: string) {
        this.bindlabel5 = iv;
    }
    @Input()
    set BindLabel6(iv: string) {
        this.bindlabel6 = iv;
    }
    @Input()
    set hasBindLabel4(iv: boolean) {
        this.hasbindlabel4 = iv;
    }
    @Input()
    set hasBindLabel5(iv: boolean) {
        this.hasbindlabel5 = iv;
    }

    @Input()
    set IsOpen(iv: boolean) {
        this.isOpen = true;
    }

    @Input()
    set IsInputElemHidden(id: any) {
        this.isInputElemHidden = id;
    }
    @Input()
    set IsFocusedonField(id: any) {
        if(id) {
                this.isFocusonElement();
        }
    }
    
    @Input()
    set IsFirstElem(value: any) {
        this.isFirstElem = value;
    }

    @Input()
    set IsSearchable(value: any) {
        this.isSearchable = value;
    }

    @Input()
    set SelectOnTab(value: any) {
        this.selectOnTab = value;
    }



    @Input() defaultValue: boolean;

    @Input() secondCntrlN: string;
    @Input() scndFormGroupN: FormGroup;
    @Input() bindScnCntrlV: string;
    @Input() recalWidth: boolean;

    @Input() nextEleId: string;

    @Input() addItemMaxLength: number;

    @Input() clearOnNotMatch: boolean;

    @Input() outsideVal: boolean;

    @Input() frmAuthRf: boolean;

    @Input() updateDropDownPos: boolean;

    @Input() phoneLabel: boolean;

    @Input() faxLabel: boolean;

    @Input() addressLabel:boolean

    @Input() show2ValInInputhidden: boolean;

    @Input() rxType: string;

    @Input() showBindVal: boolean;
    @Input() isFocusFirst: boolean;
    @Input() DrugId: boolean;
    @Input() BucketId: boolean;
    @Input() authRefillCheckAtSave: boolean;
    @Input() CheckSpiServiceLevel: boolean;
    @Input() cssStyle: boolean;
    @Input() forFileGenerate: boolean;
    @Input() InformationTool: boolean;   
    @Input() hoverOver: boolean;
    @Input() frmVaccnFrm: boolean;
    @Input() IsNgbTooltip: boolean;
    // @Output() TriggerChangeValue = new EventEmitter();
    @Output()
    TriggerSelectValue = new EventEmitter<{ value: string }>();
    @Output()
    AddSelectedValue = new EventEmitter<string>();
    @Output()
    TriggerOnEnterValue = new EventEmitter<{ value: string }>();

    @Output()
    TriggerOnFocus = new EventEmitter<any>();

    @Output()
    TriggerOnBlur = new EventEmitter<string>();

    @Output()
    TriggerOnTabbing = new EventEmitter();

    @Output()
    TriggerFocusOutOnTabbing = new EventEmitter<string>();

    @Output()
    TriggerOnShiftTabbing = new EventEmitter<any>();

    @Output()
    OnKeydownValue = new EventEmitter<any>();

    @Output()
    TriggerOnClear = new EventEmitter<any>();
    @Output()
    TriggerOnClearValue = new EventEmitter<any>();
    @Output()
    mmsSearchStart = new EventEmitter<any>();

    @ViewChild("Warning", { static: true })
    Warning: any;




    labelText: string;
    placeHolder: string;
    inputErrors: any;
    errorDefs: any;
    errorMessage: string;
    inputFormGroup: FormGroup;
    custContrlName: string;
    isDisabled = false;
    dropdownList: any[]=[];
    hasMultiple = false;
    bindLabel: string;
    bindValue: string;
    bindLabel2: string;
    labelForId: string;
    hasControl = true;
    isRequired: boolean;
    hasBindLabel2: boolean;
    show2values: boolean;
    showlabel2: boolean;
    titleText: string;
    tabIndex: number;
    inputValue: any;
    isInputElemHidden = false;
    isTouched = false;
    filterdList: any[]=[];
    isFocused = false;
    isWarnForAuth: boolean = false;
    show3values: boolean;
    constructor(private _validFormFieldsUtils: ValidateFormFieldsUtils,private _prvMskGrd: PrivMaskGuard,
        private _alerService: AlertService,
        private _element: ElementRef, private _cd: ChangeDetectorRef, private _mdlServ: NgbModal) { }

    ngOnInit() {
        if (this.inputFormGroup && this.custContrlName && this.inputFormGroup.get(this.custContrlName)) {
            this.inputFormGroup.get(this.custContrlName).valueChanges.pipe(distinctUntilChanged()).subscribe(v => {
                if (this.rxID !== "patGend" && this.inputFormGroup.controls["GenderId"] && !this.inputFormGroup.controls["GenderId"].value) {
                this.validateAllFormFields(this.inputFormGroup, this.custContrlName);
                this._cd.markForCheck();
                this._cd.detectChanges();
                }
            });
        }
        const fg = this.scndFormGroupN ? this.scndFormGroupN : this.inputFormGroup;
        if (this.secondCntrlN && fg && fg.get(this.secondCntrlN)) {
            fg.get(this.secondCntrlN).valueChanges.subscribe(v => {
                this._cd.markForCheck();
                this._cd.detectChanges();
            });
        }        
    }
    isFocusonElement() {
        const input = document.getElementById(this.rxID);
        if (input)
            input.focus();
    }
    ngOnChanges(changes: SimpleChanges) {
        if (this.dropdownList && this.dropdownList.length && this.bindLabel2) {
            this.calculateMaxLength();
        }
        if (this.recalWidth) {
            this.calculateMaxLength();
        }
         if(changes &&  changes.InputValue && changes.InputValue.currentValue  && this.labelText === "Drug Lot #") {
          const isIdExist = this.filterdList.filter((item: any) => {
            return changes.InputValue.currentValue != item["Id"];
       });
        if(isIdExist && !isIdExist.length) {

            this.filterdList =  _.uniqBy( this.filterdList, "LotNum");
           }
        }

    }
    resetDropdownData(idForReset) {
        if (idForReset) {
            const ngSelectElem: any = document.getElementsByClassName("ng-input");
            const len = ngSelectElem.length;
            for (let i = 0; i < len; i++) {
                if (ngSelectElem[i]["children"][0] && ngSelectElem[i]["children"][0]["id"] === idForReset) {
                    ngSelectElem[i]["children"][0].focus();
                    ngSelectElem[i]["children"][0].blur();
                }
            }
        }
    }
    triggerChange($event) {
        let data: any;
        if (this.labelText !== "Drug Manufacturer") {
            setTimeout(() => {
                // this._cd.detectChanges();
                if (!this.hasMultiple) {
                this.isOpen = false;
                }
            }, 90);
        } else {
            setTimeout(() => {
                // this._cd.detectChanges();
                if (!this.hasMultiple) {
                this.isOpen = false;
                }
            }, 110);
        }
        this.TriggerSelectValue.emit({ value: $event });
        this.filterdList = this.dropdownList;
        if (this.secondCntrlN) {
            const FG = this.scndFormGroupN ? this.scndFormGroupN : this.inputFormGroup;
            if (FG && FG.controls[this.secondCntrlN]) {
                FG.controls[this.secondCntrlN].patchValue($event ? $event[this.bindScnCntrlV] : null);
            }
        }
        if (this.labelText !== "Drug Manufacturer") {
            if (this.hasControl) {
                this.validateAllFormFields(this.inputFormGroup, this.custContrlName);
            }
        } else {
            setTimeout(() => {
                if (this.hasControl) {
                    this.validateAllFormFields(this.inputFormGroup, this.custContrlName);
                }
            }, 1);
        }
        if($event && $event.LotNum && $event.LotNum.length > 30){
            const FG = this.scndFormGroupN ? this.scndFormGroupN : this.inputFormGroup;
            FG.controls["LotNum"].patchValue(null);
            this._alerService.error("Maximum length of lot is 30")
        }
    }

    validateAllFormFields(formGroup?: FormGroup, formCntrl?: string) {
        if (formCntrl) {
          this.errorMessage = this._validFormFieldsUtils.validateFormFields(formGroup, formCntrl, this.errorDefs);
        }
    }

    addSelectedValue($event) {
        this.AddSelectedValue.emit($event);
    }

    async triggerOnBlur(e, ngSelect) {
        if (this.clearOnNotMatch) {
            await this.clearSearchValue(ngSelect);
        }
        if (ngSelect) {
            ngSelect.filterValue = null;
            this.filterdList = this.dropdownList;
        }
        this._cd.detectChanges();
        this.TriggerOnBlur.emit(e);
    }

    clearSearchValue(ngSelect: any) {
        this.ngSelect = ngSelect;
        this.isWarnForAuth = false;
        if ((ngSelect.filterValue || ngSelect.searchTerm) && !this.addItem) {
            const filterValue = ngSelect.searchTerm? ngSelect.searchTerm : ngSelect.filterValue;
            const list = new SortByProp().transform(this.dropdownList, this.bindLabel, this.bindLabel2, this.bindlabel3, this.bindlabel4,
                "asc", filterValue);
            if (!list || (list && list.length === 0)) {
                this.prevFilterValue = null;
                ngSelect.filterValue = null;
                ngSelect.searchTerm = null;
               this.filterdList = this.dropdownList;
               // ngSelect.itemsList["_filteredItems"] = ngSelect.itemsList["items"];
                ngSelect.clearModel();
                this._cd.detectChanges();
                if (this.custContrlName && this.inputFormGroup) {
                    if (!this.outsideVal || (this.outsideVal && this.inputFormGroup.value[this.custContrlName] !== ngSelect.filterValue)) {
                        if (this.frmAuthRf && !this.authRefillCheckAtSave) {
                            this.isWarnForAuth = true;
                            this._mdlServ.open(this.Warning, {centered: true, backdrop: false, windowClass: "large--content"});
                        } else {
                            this.inputFormGroup.controls[this.custContrlName].patchValue(this.defaultValue ? this.defaultValue : null);
                            this.inputFormGroup.controls[this.custContrlName].markAsDirty();
                        }
                    }
                }
            }
        }
    }

    closAuthWarn() {
        const refrxID = document.getElementById(this.rxID);
        if (refrxID) {
            const data: any = document.getElementById(this.rxID).getElementsByTagName("input")[0];
            if (data) {
                // setTimeout(() => {
                    this._cd.detectChanges();
                    const maxRefills = (this.dropdownList && this.dropdownList.length) ? this.dropdownList.length - 1 :  (this.defaultValue) ? +this.defaultValue : 0;
                    this.inputFormGroup.controls[this.custContrlName].setValue(null);
                    this.inputFormGroup.controls[this.custContrlName].markAsDirty();
                // }, 300);
            }
        }
    }

   async onEnter(event?: any, ngSelect?: NgSelectComponent) {
        if (event.key === "Enter" && this.isOpen) {
            setTimeout(async v => {
                this.filterdList = this.dropdownList;
                if (!this.hasMultiple) {
                await this.clearSearchValue(ngSelect);
                this.TriggerOnEnterValue.emit(event);
                this.isOpen = false;
                }
            }, 100);
        } else if (event.key === "Enter" && !this.isOpen && !this.isDisabled) {
            this.isOpen = true;
        } else if (event.key === "ArrowDown" && !this.isOpen && !this.isDisabled) {
            this.isOpen = true;
        } else if (event.type === "keydown" && !this.isOpen && !this.isDisabled && !(event.ctrlKey || event.altKey)
        && [37, 38, 39, 16, 9, 13, 40, 18, 17, 46].indexOf(event.which) === -1) {
            this.isOpen = true;
        } else if (this.addItem && event.target.value && this.addItemMaxLength ) {
            if ( this.rxID === "drugLotID" && event.target.value.length > 29) {
                event.preventDefault();
            } else if (this.rxID !== "drugLotID" && this.rxID === "notesLogCategry" && event.target.value.length > 14) {
                event.preventDefault();
            } else if (this.rxID !== "drugLotID" && event.target.value.length > 34) {
                event.preventDefault();
            }
        }
        if (event.key === "Backspace") {
            // setTimeout(() => {
                this._cd.detectChanges();
                this.filterdList = this.dropdownList;
                this.TriggerOnClearValue.emit(null);
                this.isOpen = false;
            // }, 100);
        }
    }

    onKeydownValue(event?: any, ngSelect?: NgSelectComponent): void {
        if (this.hasControl && !event.target.value && !ngSelect.hasValue && (event.which === 8 || event.which === 46)) {
            this.inputFormGroup.controls[this.custContrlName].patchValue(this.defaultValue ? this.defaultValue : null);
            this.inputFormGroup.controls[this.custContrlName].markAsDirty();
            if (ngSelect) {
                if (ngSelect.itemsList) {
                    ngSelect.itemsList.markItem(null);
                }
                if (ngSelect.dropdownPanel) {
                    ngSelect.dropdownPanel.markedItem = null;
                }
                this.isOpen = false;
                // setTimeout(() => {
                    this._cd.detectChanges();
                    this.isOpen = true;
                // }, 50);
            }
            this.TriggerOnClear.emit({ value: event });
        }
        this.OnKeydownValue.emit(event);
    }

    customFilterDrop = (term, item) => {
        return (item[this.bindLabel] + "").toLowerCase().startsWith(term.toLocaleLowerCase()) ||
            (this.bindLabel2 && (item[this.bindLabel2] + "").toLowerCase().startsWith(term.toLocaleLowerCase())) ||
            (this.bindlabel3 && (item[this.bindlabel3] + "").toLowerCase().startsWith(term.toLocaleLowerCase())) ||
            (this.bindlabel4 && (item[this.bindlabel4] + "").toLowerCase().startsWith(term.toLocaleLowerCase()));
    }

    filterDrpList(event: any, ngSelect): void {
        if (!(event.ctrlKey || event.altKey) && (event.which !== 44)) {
            if (!(event.ctrlKey || event.altKey) && [37, 38, 39, 16, 9, 13, 40, 18, 17, 46].indexOf(event.which) === -1
                && event.target.value !== this.prevFilterValue &&
                this.filterOnFocus && !this.addItem) {
                this.filterdList = this.dropdownList;
                if (event && event.target && event && event.target.value) {
                    const list = new SortByProp().transform(this.dropdownList, this.bindLabel, this.bindLabel2,
                        this.bindlabel3, this.bindlabel4, "asc", event.target.value);
                    this.filterdList = list;
                } else {
                    this.filterdList = this.dropdownList;
                }
            } else if (!this.filterOnFocus) {
                this.filterOnFocus = true;
            }
            // if (ngSelect && ngSelect.itemsList && (!ngSelect.itemsList.filteredItems ||
            //     (ngSelect.itemsList.filteredItems && ngSelect.itemsList.filteredItems.length === 0)) &&
            //     this.filterdList && this.filterdList.length > 0) {
            //         this.filterdList.map(v =>  ngSelect.itemsList.filteredItems.push(v));
            // }

            if (this.addItem) {
                if (document.getElementsByClassName("ng-tag-label") && document.getElementsByClassName("ng-tag-label").length > 0
                    && this.isOpen && this.filterOnFocus && [37, 38, 39, 16, 9, 13, 40, 18].indexOf(event.which) === -1) {
                    const element = ngSelect.dropdownPanel.paddingElementRef.nativeElement;
                    let height = 0;
                    if (ngSelect.itemsList && ngSelect.itemsList.filteredItems.length > 0) {
                        height = ngSelect.itemsList.filteredItems.length * 29 + 29;
                    } else {
                        height = 0;
                    }
                    const txt = "height: " + height + "px;";
                    element["style"]["cssText"] = txt;
                }
            }
            // if (!(event.ctrlKey || event.altKey || this.hasMultiple) &&
            // [37, 38, 39, 16, 9, 13, 40, 18, 17, 46].indexOf(event.which) === -1 && !event.target.value) {
            //     ngSelect.selectedItems.pop();
            //     ngSelect.selectedValues.pop();
            // }
            this.prevFilterValue = event.target.value;
           /* this.inputFormGroup.controls[this.custContrlName].patchValue(this.prevFilterValue);
            ngSelect.filterValue = this.prevFilterValue;
            if(!this.prevFilterValue) {
                ngSelect.filterValue = null;
                ngSelect.clearModel();
            } */

        }
    }

    openDropDown(ngSelect: any) {
        if (!this.isFocused) {
            this.isFocused = true;
            this.isFocus = true;
            if (ngSelect && ngSelect.selectedItems[0] && !(this.show2values || this.hasMultiple)) {
                if (ngSelect.selectedItems[0]["label"] && !this.showlabel2 && !this.outsideVal) {
                    ngSelect.filterValue =   ngSelect.selectedItems[0]["label"];
                } else if (this.showlabel2 && ngSelect.selectedItems[0]) {
                    ngSelect.filterValue = ngSelect.selectedItems[0]["value"][this.bindLabel2];
                } else if (this.outsideVal && this.inputFormGroup && this.inputFormGroup.value[this.custContrlName]) {
                    ngSelect.filterValue = this.inputFormGroup.value[this.custContrlName];
                    if (ngSelect) {
                        if (ngSelect.itemsList) {
                            ngSelect.itemsList.markItem(null);
                        }
                        if (ngSelect.dropdownPanel) {
                            ngSelect.dropdownPanel.markedItem = null;
                        }
                    }
                }
                if (ngSelect?.searchInput?.nativeElement) {
                    setTimeout(() => {
                        ngSelect.searchInput.nativeElement.select();
                    }, 3);
                }
                this.filterOnFocus = false;
            } else if (this.addItem && !ngSelect.selectedItems[0]) {
                ngSelect.filterValue = this.inputValue;
            } else if (this.show2values || this.hasMultiple) {
                this.filterOnFocus = false;
        } if ( this.show2values || !this.hasMultiple) {
            this.filterOnFocus = true;
        }
            setTimeout(() => {
            if ( !this.isFocusFirst && (!this.autoFocus && !this.isDisabled && !this.isFirstElem && this.isFocus) || this.focusOnSelect) {
                this.isOpen = true;
            } else {
                this.isOpen = false;
            }
            if (this.addItem && this.isOpen && ngSelect.dropdownPanel) {
                const element = ngSelect.dropdownPanel.paddingElementRef.nativeElement;
                    let height = 0;
                    if (ngSelect.items && ngSelect.items.length > 0) {
                        height = ngSelect.items.length * 29;
                    } else {
                        height = 0;
                    }
                    const txt = "height: " + height + "px;";
                    element["style"]["cssText"] = txt;
            }
            this.TriggerOnFocus.emit();
            if (this.isOpen) {
                this.getWidth();
            }
            this.isFocused = false;
            } , 300);
        }
    }

    taggleDropDown(ngSelect: NgSelectComponent, e?: any) {
        if (e && e.target && (e.target.className === "ng-arrow-wrapper" || (this.isFirstElem && !this.isOpen) ||
        (e.target.className && this.isOpen && ngSelect && ngSelect.selectedItems && ngSelect.selectedItems.length) ||
        (!e.target.className && !this.isOpen))) {
            if (!this.isDisabled) {
                this.isOpen = !this.isOpen;
                setTimeout(() => {
                    if (this.isOpen) {
                        this.focusOnSelect = false;
                        setTimeout(() => {
                            this.focusOnSelect = this.isOpen;
                            if (this.autoFocus && this.focusOnSelect) {
                                this.autoFocus = false;
                                setTimeout(() => {
                                    this.autoFocus = true;
                                }, 63);
                            }
                        }, 62);
                    }
                }, 60);
            }
        }
    }



    closeDropDown() {
        if (!this.isDisabled) {
            this.isOpen = false;
        }
        this.focusOnSelect = false;
        this.isFocus = false;
        this.autoFocus = false;
    }

    setInputElemHidden() {
        if (this.rxType && this.rxID === 'rxPriceSchId' && ((this.rxType == "nr" || this.rxType == "nrf") && !this._prvMskGrd.canActivate(AccCategoryE.RxEntry, RxEntryE.ChangePrice)) ||
         (this.rxType == "er" && !this._prvMskGrd.canActivate(AccCategoryE.EditRx, EditRxE.ChangePrice))) {
            this._alerService.error(MsgConfig.NO_PRIVS_ACCESS);
        }
         else {
            this.isInputElemHidden = !this.isInputElemHidden;
            this.isTouched = true;
         }   
    }

    setIsTouchedFalse() {
        this.isInputElemHidden = !this.isInputElemHidden;
        this.isTouched = false;
    }

    selectDataOnTab(ngSelect: any) {
        if (this.isOpen && !this.hasMultiple && !this.addItem) {
            const item = ngSelect.itemsList.markedItem;
            if (item) {
                ngSelect.itemsList.select(item);
                this.inputFormGroup.controls[this.custContrlName].patchValue(item["value"] && typeof item["value"] === "object"
                 ? item["value"][this.bindValue] : item["value"]);
                ngSelect.filterValue = null;
                this.filterdList = this.dropdownList;
                this.isOpen = false;
                this.inputFormGroup.controls[this.custContrlName].markAsDirty();
                this.triggerChange(item["value"]);
            } else {
                this.clearSearchValue(ngSelect);
            }
            this._cd.detectChanges();
        }
    }

    async onKeyTabbing(value?: any, ngSelect?: NgSelectComponent) {
        await this.selectDataOnTab(ngSelect);
        this.validateAllFormFields(this.inputFormGroup, this.custContrlName);
        if (this.hasControl && this.inputFormGroup && this.inputFormGroup.controls && this.inputFormGroup.controls[this.custContrlName] && this.inputFormGroup.controls[this.custContrlName].invalid) {
                value.preventDefault();
                const input = this._element.nativeElement.getElementsByTagName("input");
                input[0].focus();
                this.isOpen = true;
        }
        if(!this.isWarnForAuth)
            this.TriggerOnTabbing.emit(value);
    }

    onShiftTabbingEvent(event) {
        if (event.shiftKey && event.key === "Tab") {
            this.TriggerOnShiftTabbing.emit(event);
        }
    }

    focusOutOnTab(ngSelect) {
        if (this.selectOnTab) {
            // setTimeout(() => {
                this._cd.detectChanges();
                this.TriggerFocusOutOnTabbing.emit(ngSelect.selectedValues);
            // }, 200);
        }
    }

    getWidth() {
        // setTimeout(() => {
            this._cd.detectChanges();
            this.getWidthOnScroll();
        // }, 200);
    }

    getWidthOnScroll() {
        const data = document.getElementsByClassName("scrollable-content")[0];
        const data2 = document.getElementsByClassName("ng-dropdown-header")[0];
        const data3 = document.getElementsByClassName("total-padding")[0];
        if (data2 && data) {
            data2["style"]["width"] = data["offsetWidth"] + "px";
        }
        if (data3 && data) {
            data3["style"]["width"] = data["offsetWidth"] + "px";
        }
    }

    hoverSecondValue() {
        let result = "";
        if (this.filterdList && this.filterdList.length) {
            const d = this.filterdList.find(v => v[this.bindValue] ===
                ((this.hasControl && !this.forFileGenerate) ? this.inputFormGroup.controls[this.custContrlName].value : this.inputValue));
                 if (d) {
                    result = d ? d[this.showBindVal ? this.bindLabel : this.bindLabel2] : " ";
                 }
        }
        return result;
    }

    calculateMaxLength() {
        if (this.dropdownList && this.dropdownList.length) {
            const length = Math.max(...this.dropdownList.map(elm =>
                (elm && elm[this.bindLabel] ? (elm[this.bindLabel] + "").trim() : "").length));
                this.bindLabelWidth = (length) * 0.9;
                if (this.labelTitle1 && ((this.labelTitle1.length * 0.9) > this.bindLabelWidth)) {
                    this.bindLabelWidth = (this.labelTitle1.length * 0.9);
                }
                if (this.bindLabelWidth < 6) {
                    this.bindLabelWidth = 6;
                }
            const length1 = Math.max(...this.dropdownList.map(elm =>
                    (elm && elm[this.bindLabel2] ? (elm[this.bindLabel2] + "").trim() : "").length));
                    this.bindLabelWidth2 = (length1) * 0.9;
                    if (this.labelTitle2 && ((this.labelTitle2.length * 0.9) > this.bindLabelWidth2)) {
                        this.bindLabelWidth2 = (this.labelTitle2.length * 0.9);
                    }
            const length2 = Math.max(...this.dropdownList.map(elm =>
                (elm && elm[this.bindlabel3] ? (elm[this.bindlabel3] + "").trim() : "").length));
                this.bindLabelWidth3 = (length2) * 0.9;
                if (this.labelTitle3 && ((this.labelTitle3.length * 0.9) > this.bindLabelWidth3)) {
                    this.bindLabelWidth3 = (this.labelTitle3.length * 0.9);
                }
        }
    }
    emitClickMMS() {
        this.mmsSearchStart.emit(true)
    }
}
