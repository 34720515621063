import { Component, OnInit, Input, EventEmitter, Output, ViewChild, ChangeDetectorRef } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DrugUtils } from "src/app/utils";
import { DrugService } from "src/app/services";
import { FormGroup } from "@angular/forms";
import { MiscInfo } from "src/app/models";
import { DrugTabUtils } from "src/app/utils/drug-tabs.utils";

@Component({
    selector: "app-drug-copy",
    templateUrl: "./drug-copy.component.html"
})
export class DrugCopyComponent implements OnInit {
    @Input() originalData: any;
    @Input() drugFG: any;
    @Input() miscFG: FormGroup;
    @Input() miscInfo: MiscInfo;
    @Output() IsPopUpClosed = new EventEmitter<any>();
    @Output() EmitOpenCompndDrug = new EventEmitter();
    searchDrug: any;
    isDrugSearchFocused: boolean;
    @ViewChild("COPY", { static: true })
    COPY: any;
    copyCompData: any;

    constructor(private _modalSvc: NgbModal, private _drugUtil: DrugUtils, private _drugServ: DrugService,private _drugTabUtil: DrugTabUtils, private _cdr:ChangeDetectorRef) { }

    ngOnInit() {
        this.openCopyPopUp(this.COPY);
    }

    openCopyPopUp(content) {
        this.searchDrug = this._modalSvc.open(content, {
            centered: true,
            keyboard: false,
            backdrop: false,
            size: "lg"
        });
        this.isDrugSearchFocused = false;
        this._cdr.detectChanges()
        this.isDrugSearchFocused = true;
    }

    isFocusedOutofSearch() {
        this.isDrugSearchFocused = false;
    }

    async copyDrug(event) {
        if (event && event !== "") {
            const selectedDrugdata = await this._drugUtil.getDrugData(event.id);
            selectedDrugdata["Drug"]["Name"] = this.originalData["Drug"]["Name"];
            selectedDrugdata["Drug"]["DrugCode"] = this.originalData["Drug"]["DrugCode"];
            selectedDrugdata["Drug"]["Id"] = this.originalData["Drug"]["Id"];
            this._drugUtil.patchDrugInfoForCopy(selectedDrugdata, this.drugFG);
            this.copyCompData = null;
            await this.checkIfCompound(selectedDrugdata, event.id);
            // copy compound drug values in misc Info
            this.miscInfo = await this._drugTabUtil.getDrugMiscInfo(event.id ? event.id : 0);
            this.miscInfo = this.miscInfo ? this.miscInfo : this.miscFG.value;
            const miscInfo = Object.assign({}, this.miscInfo);
            if (miscInfo.DrugMisc) { // DrugMisc
                this.miscFG.controls.DrugMisc.patchValue({
                    DoseFrmDescId: this.drugFG.controls["DrugMisc"] && this.drugFG.controls["DrugMisc"]["DoseFrmDescId"].value ?
                                    this.drugFG.controls["DrugMisc"]["DoseFrmDescId"].value : miscInfo.DrugMisc.DoseFrmDescId,
                    DispUnitFormId: this.drugFG.controls["DrugMisc"] && this.drugFG.controls["DrugMisc"]["DispUnitFormId"].value ?
                                    this.drugFG.controls["DrugMisc"]["DispUnitFormId"].value : miscInfo.DrugMisc.DispUnitFormId,
                    AdmnstrnRouteId: this.drugFG.controls["DrugMisc"] && this.drugFG.controls["DrugMisc"]["AdmnstrnRouteId"].value ?
                                    this.drugFG.controls["DrugMisc"]["AdmnstrnRouteId"].value :miscInfo.DrugMisc.AdmnstrnRouteId,
                    CompoundTypId: this.drugFG.controls["DrugMisc"] && this.drugFG.controls["DrugMisc"]["CompoundTypId"].value ?
                                    this.drugFG.controls["DrugMisc"]["CompoundTypId"].value :miscInfo.DrugMisc.CompoundTypId,
                    AdmnstrnRoute2Id: this.drugFG.controls["DrugMisc"] && this.drugFG.controls["DrugMisc"]["AdmnstrnRoute2Id"].value ?
                                    this.drugFG.controls["DrugMisc"]["AdmnstrnRoute2Id"].value :miscInfo.DrugMisc.AdmnstrnRoute2Id
                });
            }
            this.closeModal();
        }
    }

    async checkIfCompound(data, id) {
        if (data.Drug.DrugTypeId === 2) {
            if (!this.originalData["Drug"]["Id"]) {
                 await this.getNdcForCompound();
                this.copyCompData = await this._drugServ.getDrugRefIngredients(id, 0).toPromise();
                if (this.copyCompData && this.copyCompData.list && this.copyCompData.list.length > 0) {
                    this.copyCompData.list.map(val => val.CompDrugId = null);
                }
            }
        }
    }

    async getNdcForCompound() {
        const compNDC = await this._drugUtil.getCompoundNDC();
        this.drugFG.controls["Drug"].patchValue({
            DrugCode: compNDC
        });
    }

    closeModal() {
        if (this.searchDrug) {
            this.searchDrug.close();
        }
        this.drugFG.markAsTouched();
        this._cdr.detectChanges();
        this.drugFG.markAsUntouched();
        this.drugFG.updateValueAndValidity();
        this.IsPopUpClosed.emit(null);
        if (this.copyCompData) {
            this.EmitOpenCompndDrug.emit(this.copyCompData);
        }
    }

    closeDrugsearchPopup(event) {
        if(event) this.closeModal();
    }

}
