
import { Component, OnInit, Input, ViewChild, TemplateRef, ChangeDetectorRef, Output, EventEmitter } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { DrugData, AccCategoryE, InventoryE, AuditTrail } from "src/app/models";
import { DrugTabUtils } from "src/app/utils/drug-tabs.utils";
import { CollectionView } from "@grapecity/wijmo";
import { PrivMaskGuard } from "src/app/guards"; 
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CommonUtil, DrugUtils, ValidateFormFieldsUtils, WijimoUtil } from "src/app/utils"; 
import * as wjcGrid from "@grapecity/wijmo.grid" 
import { PrintService } from "src/app/services/print.service";
import { AlertService, CommonService, PatPreDrugModalService, UserService, BucketService, InventoryService, ReportsService, DrugPriceCheckService } from "src/app/services";
import { DrugStore } from "src/app/store";
import * as moment from "moment";
import * as Sentry from "@sentry/browser";
import { DrugBucketUtil } from "src/app/utils/drug-bucket.util";
import { AddDrugToBucketComponent } from "../../shared/add-drug-to-bucket/add-drug-to-bucket.component";
import { forkJoin } from "rxjs";
import { EquivalentDrugsComponent } from "../../shared";
import { BucketListDisplayComponent } from "../../shared/bucket-list-display/bucket-list-display.component";
import { DomSanitizer } from "@angular/platform-browser";
import { InventoryTransAuditComponent } from "./inventory-trans-audit/inventory-trans-audit.component";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FormatsUtil } from "src/app/utils/formats.util";

@Component({
    selector: "app-drug-inventory",
    templateUrl: "./drug-inventory.component.html"
})
export class DrugInventoryComponent implements OnInit {

    @Input() drugId: number;
    @Input() drugFG: FormGroup;
    @Input() drugInfo: DrugData;
    @Input() dropDownValues: any;
    @Input() isPreferred: boolean;
    actvModal: any;
    invenHistWJ: CollectionView;
    phyInvWJ: CollectionView;
    PhyRecWJ: CollectionView;
    AuditTrailFG: FormGroup;
    wjHeaders: any;
    wjHeadersForInventoryAudit: any;
    hasTransHis: boolean;
    isPhysicalInventEnabl: boolean;
    invTransDispAudit: any;
    openVendor: boolean;
    activeModal: any;
    modelRef: any;
    pdfContent: any;
    isprint: boolean;
    bucketList: any;
    InventoryTransAudit: any;
    bucketINVFG: FormGroup;
    InsuFiltersFrmGrp: FormGroup;
    Selectedbucket: any;
    messageFor: any;
    InvAuditTrails: any;
    hasPrivs: boolean;
    alertMsg: string;
    viewDocs = false;
    vendorId: string;
    wjHeadersInvRecords: any;
    wjHeadersInvStatus: any;
    isDataExists = true;
    // PhyInvHeaders = [
    //     "Bucket",
    //     "Manage Inventory",
    // ];
    // PhyRecHeaders = [
    //     "Date of Entry",
    //     "Bucket Name",
    //     "Total Qty",
    //     "Packs",
    //     "Loose Qty"
    // ];
    bucketIdsTracker: any[] = [];

    @ViewChild("PRIVSWARNING")
    PRIVSWARNING: any;
    Invmessage: string;
    @ViewChild("PRIVANDMANG", { static: true })
    PRIVANDMANG: any;
    @ViewChild("PHYINVRECORDS", { static: true })
    PHYINVRECORDS: any;
    @ViewChild("flex")
    flex: wjcGrid.FlexGrid;
    @ViewChild("AddDrugToBucketConfirm")
    AddDrugToBucketConfirm: TemplateRef<any>;
    @ViewChild("UpdateCost", { static: true})
    UpdateCost: any;
    invInfoFG: FormGroup;
    openForAdd: boolean;
    resp: any;
    vendorDetails: any;
    hasVendorDetails = false;
    CostPack: any;
    vendorName: string;
    InvCal: any;
    InventoryTransactionAuditWJ: any;
    contractId: any;
    selectVendList: any;
    compoundDrugIds = [];
    compoundIng: FormGroup;
    compDrugList = [];
    unsubscribe$ : Subject<void> = new Subject();
    isOriginalCompDrug: boolean;
    isOriginalDrugAddToBucket: any;
    @Output() preferredStatus = new EventEmitter();
    constructor(private _drugTabUtil: DrugTabUtils, private _printServ: PrintService, private _modalService: NgbModal,
         private _phyInvSvc: InventoryService, private _alertSer: AlertService, private _sanitizer: DomSanitizer,
        private _alrtSvc: AlertService, private _reportsServ: ReportsService, private _buckService: BucketService, private _fb: FormBuilder,
        private _drugBuckUtil: DrugBucketUtil, private _formUtils: ValidateFormFieldsUtils,
        private _privMaskGuard: PrivMaskGuard, private _userServ: UserService, private _drugStore: DrugStore,
        private _modalSvc: NgbModal, private _comnUtil: CommonUtil, private _drugUtils: DrugUtils,  private _commonSvc: CommonService,
        private _invetoryPopup: PatPreDrugModalService, private _inventoryService: InventoryService, public _formatsUtil:FormatsUtil,
        private _wijimoUtils: WijimoUtil, private _drugpriceChk: DrugPriceCheckService, private _cdr: ChangeDetectorRef) {this.InsuFiltersFrmGrp = this._fb.group({
            pageNumber: 1,
            Code: null,
            Name: null,
            BucketType: null,
            BucketContextType: "all"}) ;
        }

    ngOnInit() {
        this.drugFG.controls["DrugPO"].patchValue({
            TargetQty: (parseFloat(this.drugFG.value["DrugPO"]["TargetQty"])).toFixed(3)
        });
        this.InvCal = this._commonSvc.getSetttingValue("InventorySettings", "Inventory_Calculation_Based_On_DPV");
        this.createBucketInvFG();
        this.createInvInfoFG();
        this.getBucketlistAndPatch(null, true);
        this.patchDefaultValHeaders();
        this._wijimoUtils.wjSavedData$.pipe(takeUntil(this.unsubscribe$)).subscribe(w => {
            if (w && w["WijmoKey"] &&
             (w["WijmoKey"] === "drugInvHistoryListWJ" || w["WijmoKey"] === "InventoryTransactionAudit"
              || w["WijmoKey"] === "physicalInvStatusRecWJ" || w["WijmoKey"] === "physicalInvRecWJ")) {
                this.patchDefaultValHeaders();
            }
        });
        this.createFG();
    }

    createBucketInvFG() {
        this.bucketINVFG = this._drugBuckUtil.createDrugBucketFG();
    }

    createInvInfoFG() {
        this.invInfoFG = this._fb.group({
            LastPhysicalInvCount: 0,
            LastPhysicalInventoryDate: null,
            PastAdjustmentsSinceLastInv: 0,
            TotalDispensed: 0,
            TotalDrugPickVerifiedQty: 0,
            TotalRecieved: 0,
        });
    }

    get DrugPO(): FormGroup {
        return this.drugFG.controls["DrugPO"] as FormGroup;
    }


    init(flex: wjcGrid.FlexGrid) {
    }

    openPhyInvRec() {
        this.genBuckInv();
        this._modalSvc.open(this.PHYINVRECORDS, { keyboard: false, size: "lg", centered: true, backdrop: false });
    }

    async viewRecord(item) {
        const resp = await this._phyInvSvc.getPhyInvData(this.drugInfo && this.drugInfo.Drug && this.drugInfo.Drug.Id ? this.drugInfo.Drug.Id : null , item["BId"]).toPromise();
        if (resp && resp.length > 0) {
            this.viewDocs = true;
            this.PhyRecWJ = new CollectionView(
                resp.map((List, i) => {
                    const j = {};
                    j["Date of Entry"] = List.ProcessedDtTm ? moment.utc(List.ProcessedDtTm).local().format("MM/DD/YYYY") : null;
                    const bucket = this.bucketList.find(e => e.Id === List.BucketId);
                    j["Bucket Name"] = bucket.Name ? bucket.Name : null;
                    j["Total Qty"] = List.NewQty;
                    j["Packs"] = List.Packs;
                    j["Loose Qty"] = List.LooseQty;
                    return j;
                })
            );
        } else {
            this.viewDocs = false;
        }
    }
    async BucketDisplayPopUp() {
            const modalRef: any = this._modalService.open(
                BucketListDisplayComponent,
                {
                    backdrop: false,
                    size: "lg",
                    keyboard: false,
                    centered: true,
                    windowClass:"model_widht_51"
                }
            );
            modalRef.componentInstance.DrugId = this.drugId;
            modalRef.componentInstance.CloseBucketDisplayPopup.pipe(takeUntil(this.unsubscribe$)).subscribe(resp => {
            modalRef.close();
                this.actvModal = null;
            });
    }
    async DrugInventoryPopUp() {
        const equiValDrugs = await this._drugUtils.getEquivalentDrugs(this.drugId);
        const modalRef: any = this._modalService.open(
            EquivalentDrugsComponent,
            {
                backdrop: false,
                size: (equiValDrugs?.length) ? "lg" :"sm",
                keyboard: false,
                centered: true,

                windowClass : (equiValDrugs?.length) ? "md-xx-lg" : "medium-sm-modal",
            }
        );
        this.actvModal = "equival";
        modalRef.componentInstance.BucketId = this.bucketINVFG.value.Id;
        modalRef.componentInstance.EqulDrugPopType = "DrugHeader";
        modalRef.componentInstance.DiscDrugInfo = { drugname: this.drugInfo.Drug.Name, strength: this.drugInfo.Drug.Strength, drugformname: this.drugInfo.Drug["DrugFormName"] , drugcode : this.drugInfo.Drug.DrugCode};
        modalRef.componentInstance.EquivalentDrugWJ = new CollectionView(equiValDrugs);
        modalRef.componentInstance.preferredDrugInfo = {isPreferred: this.isPreferred, drugname: this.drugInfo.Drug.Name, strength: this.drugInfo.Drug.Strength, drugformname: this.drugInfo.Drug["DrugFormName"] , ndc : this.drugInfo.Drug.DrugCode, drugID : this.drugInfo.Drug.Id};
        modalRef.componentInstance.emitPrefStatus.pipe(takeUntil(this.unsubscribe$)).subscribe((prefResp: any) => {
            this.drugInfo.Drug.IsPreferred = prefResp;
            this.isPreferred = prefResp;
            this.preferredStatus.emit(prefResp);
        });
        modalRef.componentInstance.CloseAdminRxPopUp.pipe(takeUntil(this.unsubscribe$)).subscribe(resp => {
        modalRef.close();
            this.actvModal = null;
        });
      }
    async genBuckInv() {
        this.viewDocs = false;
        const resp = await this._drugBuckUtil.getDrugBucketList(this.drugId);
        if (resp) {
            this.phyInvWJ = new CollectionView(
                resp.map((List, i) => {
                    const j = {};
                    j["BId"] = List.Id;
                    j["Bucket"] = List.Name ? List.Name : null;
                    j["Manage Inventory"] = List.IsInvManaged ? "Yes" : "No";
                    j["View"] = List.IsInvManaged;
                    return j;
                })
            );
        } else {
            this._alrtSvc.error("Unable to load the Buckets.");
        }

    }

    async getBucketlistAndPatch(Id?, checkIsBuckPriceExists = false ) {
       const resp = await this._drugBuckUtil.getDrugBucketList(this.drugId);
       if (resp) {
           this.bucketList = resp;
           this.setDefaultBucket(Id, checkIsBuckPriceExists);
       } else {
        this.bucketList = null;
       }
    }

    async setDefaultBucket(Id?, checkIsBuckPriceExists = false) {
        const resp1 = this._drugBuckUtil.patchDrugBucketInfo(this.bucketList, this.bucketINVFG, Id);
        if (this.bucketINVFG && !this.bucketINVFG.controls['Id'].value) {
            Sentry.captureException([
                {Screen: "Drug Inventory", value: this.drugInfo?.Drug.Id}
            ]);
        }
        if (resp1) {
            this.DrugPO.controls["BucketId"].patchValue(this.bucketINVFG.value["Id"]);
            if (this.bucketIdsTracker.length < 2) { // tracking bucket changed.
                this.bucketIdsTracker.push(this.bucketINVFG.value.Id);
            } else {
                this.bucketIdsTracker.shift();
                this.bucketIdsTracker.push(this.bucketINVFG.value.Id);
            }
            this.setInventoryValues(this.bucketINVFG && this.bucketINVFG.value && this.bucketINVFG.value.Id ? this.bucketINVFG.value.Id : null);
            const resp = await forkJoin([this._phyInvSvc.getDrugPoForBucket(this.drugInfo && this.drugInfo.Drug && this.drugInfo.Drug.Id ? this.drugInfo.Drug.Id : null, this.bucketINVFG && this.bucketINVFG.value && this.bucketINVFG.value.Id ? this.bucketINVFG.value.Id : null),
                this._phyInvSvc.getDrugInventoryInfo(this.drugInfo && this.drugInfo.Drug && this.drugInfo.Drug.Id ? this.drugInfo.Drug.Id : null, this.bucketINVFG && this.bucketINVFG.value && this.bucketINVFG.value.Id ? this.bucketINVFG.value.Id : null)]).toPromise();
            if (resp) {
                this._drugBuckUtil.patchDrugBuckPO(this.DrugPO, resp[0]);
                if (resp[1]) {
                    this.invInfoFG.patchValue(resp[1]);
                    this.invInfoFG.patchValue({
                        LastPhysicalInventoryDate: this.invInfoFG.value["LastPhysicalInventoryDate"] ?
                        moment(this.invInfoFG.value["LastPhysicalInventoryDate"]).local().format("MM/DD/YYYY hh:mm:ss a") : null,
                        TotalDrugPickVerifiedQty: this.invInfoFG.value["TotalDrugPickVerifiedQty"] ?
                        (this.invInfoFG.value["TotalDrugPickVerifiedQty"]).toFixed(3) : "0.000",
                    });
                }
            }
            if (checkIsBuckPriceExists) {
                this.checkIsBucketPriceExistsAndOpenPopUp();
            }
        }
    }

    async checkMangInvForBucket() {
        if (this.bucketINVFG.valid) {           
            this.setDefaultBucket(this.bucketINVFG.value.Id, true);
        }
    }

   async checkIsBuckPhyInv() {
        if (this.bucketINVFG.value.IsInvManaged !== this._drugBuckUtil.getInvStatus(this.bucketList, this.bucketINVFG.value.Id)) {
            if (this.bucketINVFG.value.IsInvManaged) { // for activating inv
               this.hasPrivs = this.checkPrivils();
                if (this.hasPrivs) {
                    this.isPhysicalInventEnabl = true;
                } else {
                    this.messageFor = "You must have Physical Inventory privileges to manage inventory.";
                    this._modalSvc.open(this.PRIVANDMANG, { keyboard: false, centered: true, backdrop: false,
                         windowClass: "large--content"});
                }
            } else if (!this.bucketINVFG.value.IsInvManaged) { // for inactivating inv
               this.hasPrivs = this.checkPrivils();
                if (this.hasPrivs) {
                    // tslint:disable-next-line:max-line-length
                    const bucketName = this.bucketINVFG.value.Name.toUpperCase();
                    this.messageFor = "Are you sure, you no longer want to manage the Inventory for " +  bucketName + " Bucket?";
                    this._modalSvc.open(this.PRIVANDMANG, { keyboard: false, centered: true, backdrop: false,
                         windowClass: "large--content"});
                } else {
                    this.messageFor = "You must have Physical Inventory privileges to manage inventory.";
                    this._modalSvc.open(this.PRIVANDMANG, { keyboard: false, centered: true, backdrop: false,
                         windowClass: "large--content"});
                }
            }
        } else {
            this.setDefaultBucket();
        }
    }

    checkPrivils() {
        return this._privMaskGuard.canActivate(AccCategoryE.Inventory, InventoryE.PhysicalInventory);
    }

    mangedOrNoPriv(e, val) {
            if (this.hasPrivs && val) {
                // tslint:disable-next-line:max-line-length
                const bucketName = this.bucketINVFG.value.Name.toUpperCase();
                // tslint:disable-next-line:no-unused-expression
                this.alertMsg = "Manage Inventory for " +  bucketName
                              + "  bucket is InActivated successfully";
                this.bucketPhyInv();
            } else if (!this.hasPrivs || !val) {
                this.setDefaultBucket(this.bucketINVFG.value.Id);
                e.preventDefault();
               /* not In Use */
                // setTimeout(() => {
                //     if (document.getElementById("bucketList")) {
                //         console.log(1)
                //         document.getElementById("bucketList").focus();
                //     }
                // }, 100);
        }
    }

    closeNclearMangInv(ismng?: boolean) {
        this.isPhysicalInventEnabl = false;
        if (ismng) {
            this.bucketPhyInv();
            // this.setDefaultBucket(this.bucketINVFG.value.BucketId);
            const bucketName = this.bucketINVFG.value.Name.toUpperCase();
            // tslint:disable-next-line:no-unused-expression
            this.alertMsg = "Manage Inventory for " +  bucketName + "  bucket is Activated successfully";
        } else if (!ismng) {
            this.setDefaultBucket(this.bucketINVFG.value.Id);
            /* not In Use */
            // setTimeout(() => {
            //     if (document.getElementById("bucketList")) {
            //         document.getElementById("bucketList").focus();
            //     }
            // }, 100);
        }
    }

    async bucketPhyInv() { // for inactivating manage inventory.
        const payload = this._drugBuckUtil.patchDrugInvINFO(this.drugId, this.bucketINVFG.value, this.DrugPO.value);
        const resp = await this._buckService.managBucketInv(payload).toPromise();
        if (resp) {
            this._alrtSvc.success(this.alertMsg);
            /* not In Use */
            // setTimeout(() => {
            //     if (document.getElementById("bucketList")) {
            //         document.getElementById("bucketList").focus();
            //     }
            // }, 100);
            this.getBucketlistAndPatch(this.bucketINVFG.value.Id);
        } else {
            this.getBucketlistAndPatch(this.bucketINVFG.value.Id);
            this._alrtSvc.error("Manage Inventory is unsuccessful.");
        }
    }

    async setInventoryValues(Id?: any) {
        const resp = await this._phyInvSvc.getDrugBucketInvHistory(this.drugId,
            this.bucketINVFG.value["showHisByBucktId"] ? Id : null, this.bucketINVFG.value["showHisByBucktId"]).toPromise();
        if (resp && resp.length) {
            this.hasTransHis = true;
            this.isDataExists = true;
            this.invenHistWJ = this.getTransHistory(resp, this.drugInfo && this.drugInfo["Drug"] && this.drugInfo["Drug"]["DrugCode"] ? this.drugInfo["Drug"]["DrugCode"] : null);
        } else {
            this.hasTransHis = false;
            this.isDataExists = false;
            this.invenHistWJ = new CollectionView(null)
        }
    }

    getTransHistory(data, drugCode) {
        // let bucketList: any[];
        return new CollectionView(
            data.map((inventory, i) => {
                const j = {};
                j["Date Received"] = moment.utc(inventory.ReceiptDtTm).local().format("MM/DD/YY");
                j["Vendor Name"] = inventory.VendorName;
                j["Account"] = inventory.ContractName;
                j["Qty.Received"] = inventory.Qty ? (inventory.Qty).toFixed(3) : "0.000";
                j["Price"] = inventory.AqCostPerPkg;
                j["Drug Lot"] = inventory.LotNum;
                j["LotNumData"] = inventory.LotNumData;
                j["ExpDateList"] = inventory.ExpDateList;
                j["Reference"] = inventory.Ref;
                j["PO Number"] = j["PO#"] = inventory.PONum;
                j["Received By"] = inventory.Name;
                j["Drug NDC"] = drugCode;
                // const bucket = bucketList && bucketList.length && bucketList.find(item => item.Id === inventory.BucketId);
                j["Bucket"] = inventory.BucketName ? inventory.BucketName : "";
                j["ID"] = inventory.BucketId ? inventory.BucketId : null;
                return j;
            })
        );
    }


    filterGrid() {
        this.setInventoryValues(this.bucketINVFG && this.bucketINVFG.value && this.bucketINVFG.value["Id"] ? this.bucketINVFG.value["Id"] : null);
    }

    checkIsPhysicalInventory(event) {
        if (this.drugFG && this.drugFG.value && this.drugFG.value["Drug"] && this.drugFG.value["Drug"]["IsInvMangd"] ? this.drugFG.value["Drug"]["IsInvMangd"] : null) {
            const alwDrugChng = this._privMaskGuard.canActivate(AccCategoryE.Inventory,
                InventoryE.PhysicalInventory);
            if (alwDrugChng) {
                this.isPhysicalInventEnabl = true;
            } else {
                this.Invmessage = "active";
                this._modalSvc.open(this.PRIVSWARNING, { keyboard: false, centered: true, backdrop: false });
                this.drugFG.controls["Drug"].patchValue({ IsInvMangd: false });
            }
        } else {
            this.Invmessage = "inactive";
            this._modalSvc.open(this.PRIVSWARNING, { keyboard: false, centered: true, backdrop: false });
        }
    }

    setFocusById($event, Id, type) {
        this._comnUtil.focusById(Id, $event, type);
    }

    markIsInvManged() {
        if (this.Invmessage === "inactive") {
            this.drugFG.controls["Drug"].patchValue({ IsInvMangd: !this.drugFG.value["Drug"["IsInvMangd"]] });
        }
    }

    createFG() {
        this.AuditTrailFG = this._fb.group(new AuditTrail());
    }
    print(val: boolean) {
        this.isprint = val;
        const data = {
            DrugId: this.drugFG && this.drugFG.value && this.drugFG.value["Drug"] && this.drugFG.value["Drug"].Id ? this.drugFG.value["Drug"].Id : null,
            DrugName: this.drugFG && this.drugFG.value && this.drugFG.value["Drug"] && this.drugFG.value["Drug"].Name ? this.drugFG.value["Drug"].Name : null,
            DrugNdc: this.drugFG && this.drugFG.value && this.drugFG.value["Drug"] && this.drugFG.value["Drug"].DrugCode ? this.drugFG.value["Drug"].DrugCode : null
        };
        this._reportsServ
        .getInventoryTransactionAuditReport(data).pipe(takeUntil(this.unsubscribe$)).subscribe((resp) => {
            if (resp && resp.type === "application/pdf") {
                this.InvAuditTrails = resp;
                if (this.isprint) {
                    this.printInventoryAuditReport();
                } else {
                    const urlCreator = window.URL;
                    this.pdfContent = this._sanitizer.bypassSecurityTrustResourceUrl(
                        urlCreator.createObjectURL(resp) + "#toolbar=0&zoom=115"
                    );
                }
            } else {
                this._alertSer.error("Daily Log report is unavailable.");
            }
        });
    }
    printInventoryAuditReport() {
        const newBlob = new Blob([this.InvAuditTrails], { type: "application/pdf" });
        const reader = new FileReader();
        reader.readAsDataURL(newBlob);
        reader.onloadend = function() {
            const base64data = reader.result;
            this._printServ.printPdf(base64data);
        }.bind(this);
    }

    async getTransAudit() {
        // const payload = {}
        // this.generateInventoryTransactionAuditWJ();
        const modalRef: any = this._modalService.open(
            InventoryTransAuditComponent,
            {
                backdrop: false,
                size: "lg",
                keyboard: false,
                centered: true,
                windowClass:"md-x2-lg"
            }
        );
        modalRef.componentInstance.DrugId = this.drugId;
        modalRef.componentInstance.DrugName = this.drugFG && this.drugFG.value && this.drugFG.value["Drug"] && this.drugFG.value["Drug"].DrugFullName ? this.drugFG.value["Drug"].DrugFullName : null;
         modalRef.componentInstance.DrugNDC = this.drugFG && this.drugFG.value && this.drugFG.value["Drug"] && this.drugFG.value["Drug"].DrugCode ? this.drugFG.value["Drug"].DrugCode : null;
        modalRef.componentInstance.BucketList =  this.bucketList;
        modalRef.componentInstance.CloseBucketDisplayPopup.pipe(takeUntil(this.unsubscribe$)).subscribe(resp => {
        modalRef.close();
            this.actvModal = null;
        });
        // this._modalSvc.open(Content, { windowClass: "medium-modal-60", centered: true, backdrop: false, keyboard: false });
    }
    // generateInventoryTransactionAuditWJ() {
    //     this.InventoryTransactionAuditWJ = new wjcCore.CollectionView(
    //         this.invTransDispAudit.map((item, i) => {
    //             const j = {};
    //             j["Id"] = item.Id;
    //             j["Trans. Type"] = item.TransactionType;
    //             j["Transaction Date"] = item.TransactionDate ? moment.utc(item.TransactionDate).local().format("MM/DD/YYYY hh:mm:ss") : null;
    //             j["Referenece"] = item.Ref;
    //             j["Bucket"] = item.BucketName ?  item.BucketName.toUpperCase() : null;
    //             j["Trans Qty"] = item.DispQty;
    //             j["IsActive"] = item.IsActive;
    //             return j;
    //         })
    //     );
    // }
    async patchDefaultValHeaders() {
        const storedWJ = await this._wijimoUtils.getWJSavedData("drugInvHistoryListWJ");
        this.wjHeaders = this._wijimoUtils.patchDefHeader("drugInvHistoryListWJ", storedWJ);
        const storedWJ1 = await this._wijimoUtils.getWJSavedData("InventoryTransactionAudit");
        this.wjHeadersForInventoryAudit = this._wijimoUtils.patchDefHeader("InventoryTransactionAudit", storedWJ1);
        const storedWJStatus = await this._wijimoUtils.getWJSavedData("physicalInvStatusRecWJ");
        this.wjHeadersInvStatus = this._wijimoUtils.patchDefHeader("physicalInvStatusRecWJ", storedWJStatus);
        const storedWJRecords = await this._wijimoUtils.getWJSavedData("physicalInvRecWJ");
        this.wjHeadersInvRecords = this._wijimoUtils.patchDefHeader("physicalInvRecWJ", storedWJRecords);
    }
    setNumberFormat(ControlName) {
        this.drugFG.controls["DrugPO"].patchValue({
            ControlName:
                // tslint:disable-next-line:radix
                this.drugFG.value["DrugPO"][ControlName] ? +parseInt(this.drugFG.value["DrugPO"][ControlName]).toFixed(3) : (0).toFixed(3)
        });
    }

    // Drug Vendors Start

    async openVendorData(val, IsAddOpen?: boolean) {
        if (val === "save") {
            this.openVendor = null;
            const dataPost ={
                drugId: this.drugId,
                vendorId: this.vendorId,
                ContractId: this.contractId
            };
            this.vendorDetails = await this._buckService.getInventoryPreferredVendorId(dataPost).toPromise();
            this.patchToDrugUnitCost();
        } else {
            this.openVendor = val;
        }
        this.openForAdd = IsAddOpen;
    }

    closendclearMangInv(isCancel?: boolean) {
        this.isPhysicalInventEnabl = false;
        if (isCancel) {
            const fg: any = this.drugFG.controls["Drug"];
            fg.controls["IsInvMangd"].patchValue(true);
            // this._drugSvc.updateDrugInfo(this.drugFG.getRawValue())
            this.updateDrugValues();
        }
    }

    async openEnterInventory() {
        this._invetoryPopup._patPreDrugModal$.next("Inventory");
        const modelRef = await this._invetoryPopup.modalInstanceRef;
        modelRef.componentInstance.drugId = this.drugId;
        modelRef.componentInstance.BucketInfo = this.bucketINVFG.value;
        modelRef.componentInstance.BindContract = this.drugFG.get("DrugPO").value.ContractId ?
                                                this.drugFG.get("DrugPO").value.ContractId : null;
        modelRef.componentInstance.BindVendor = this.drugFG.get("DrugPO").value.PrefVendId ?
                                                this.drugFG.get("DrugPO").value.PrefVendId : null;
        modelRef.componentInstance.frmEditDrug = true;
        modelRef.componentInstance.drugFullName = (this.drugInfo && this.drugInfo.Drug) ?
            (this.drugInfo.Drug.Name + this.drugInfo.Drug.Strength + this.drugInfo.Drug.DrugFormName) : " ";
        modelRef.componentInstance.emitInvClose.pipe(takeUntil(this.unsubscribe$)).subscribe(async resp => {
            modelRef.close();
            this._invetoryPopup._patPreDrugModal$.next(null);
            if (resp) {
                this.updateDrugValues();
            }
        });
    }

    async updateDrugValues() {
        const drugDetails = await this._drugUtils.getDrugData(this.drugId);
        this.drugFG.patchValue({
            LatestVendorName: drugDetails.LatestVendorName
        });
        this.drugInfo["DrugTrans"] = drugDetails["DrugTrans"];
        this.drugInfo["InventoryHistory"] = drugDetails["InventoryHistory"];
        this._drugStore.storeDrugInfo(Object.assign({}, this.drugInfo));
        this.getBucketlistAndPatch(this.bucketINVFG.value.Id, false);
    }

    async checkIsBucketPriceExistsAndOpenPopUp() {
        if (this.drugFG.value["Drug"]["DrugTypeId"] === 2) {
            this.compoundDrugIds = [];
            let compBucketcheck: any;
            this.compDrugList = []
            const payload = {
                PrescReFillId: 0,
                BucketId: this.bucketINVFG.value.Id,
                DrugId: this.drugId
            }
            compBucketcheck = await this._buckService.CompoundDrugBucketsInfo(payload).toPromise();
            compBucketcheck.find((i)=>{
                if(!i.IsPriceExist) {
                    this.compDrugList.push(i.DrugName)
                    this.compoundDrugIds.push(i.DrugId)
                }
            })
            this.isOriginalCompDrug = (this.compoundDrugIds.includes(this.drugId));
            if (this.compoundDrugIds && this.compoundDrugIds.length && this._drugBuckUtil.isAdvancedMode())
                this._modalService.open(this.AddDrugToBucketConfirm, {centered: true, backdrop: false, keyboard: false, windowClass: "large--content"});
            else if (this.compoundDrugIds && this.compoundDrugIds.length) 
                this.openAddDrugToBuckOrNotForCompound(null,true)
        } else {
            if (this._drugBuckUtil.isAdvancedMode() &&
            !this._drugBuckUtil.checkIsDrugBuckPriceExist(this.bucketINVFG.value)) {
            this._modalSvc.open(this.AddDrugToBucketConfirm, {centered: true, backdrop: false, keyboard: false, windowClass: "large--content"});
        } else if (!this._drugBuckUtil.checkIsDrugBuckPriceExist(this.bucketINVFG.value)) { // make manage Inventory call ?
            this.drugPoManageInv();
        }
        }
    }

    async drugPoManageInv(patchPrev?: boolean) {
        if (!this.drugId) {
            return;
        }
        let DrugPoInfo: any;
        let payload: any;
        const DrugPO: FormGroup = this._drugBuckUtil.createDrugPoFG(); // api call to get drugPO for the bucket.
        DrugPoInfo = await this._inventoryService.getDrugPoForBucket(this.drugFG.value["Drug"]["DrugTypeId"] === 2 && this.compoundDrugIds && this.compoundDrugIds.length ? this.compoundDrugIds[0] : this.drugId, this.bucketINVFG && this.bucketINVFG.value && this.bucketINVFG.value.Id ? this.bucketINVFG.value.Id : null).toPromise();
        if (DrugPoInfo) {
            this._drugBuckUtil.patchDrugBuckPO(DrugPO, DrugPoInfo);
            payload = this._drugBuckUtil.patchDrugInvINFO(this.drugFG.value["Drug"]["DrugTypeId"] === 2 && this.compoundDrugIds && this.compoundDrugIds.length ? this.compoundDrugIds[0] : this.drugId, this.bucketINVFG.value, DrugPO.value, this.drugFG.value["Drug"]["DrugTypeId"] === 2 && this.compoundIng ? this.compoundIng.value : this.drugFG.value);
        } else {
            payload = this._drugBuckUtil.patchDrugInvINFO(this.drugFG.value["Drug"]["DrugTypeId"] === 2 && this.compoundDrugIds && this.compoundDrugIds.length ? this.compoundDrugIds[0] : this.drugId, this.bucketINVFG.value, DrugPO.value, this.drugFG.value["Drug"]["DrugTypeId"] === 2 && this.compoundIng ? this.compoundIng.value : this.drugFG.value);
        }
        
        const priceInfo: any = await this._drugpriceChk.getDrugBuckPrice(this.drugFG.value["Drug"]["DrugTypeId"] === 2 && this.compoundDrugIds && this.compoundDrugIds.length ? this.compoundDrugIds[0] : this.drugId, this.bucketINVFG.value.Id).toPromise();
        if (priceInfo) {
            // patch into packcost, unitcost.
            payload.ManageInventory.CostPack = priceInfo.PackCost ? (priceInfo.PackCost).toFixed(2) : payload.ManageInventory.CostPack;
            payload.ManageInventory.UnitPriceCost = priceInfo.UnitCost ? (priceInfo.UnitCost).toFixed(3) : payload.ManageInventory.UnitPriceCost;
        }
        const resp = await this._inventoryService.drugBuckManagInv(payload).toPromise();
        if (resp && this.drugFG.value["Drug"]["DrugTypeId"] !== 2) {
            setTimeout(() => {
                this.getBucketlistAndPatch(this.bucketINVFG.value.Id, false);
            }, 200);
        }
        if (this.drugFG.value["Drug"]["DrugTypeId"] == 2) {
            this.compoundDrugIds.shift();
            this.openAddDrugToBuckOrNotForCompound(null,true)
        }
            

    }

    checkHasEnterInvPriv() {
        return this._privMaskGuard.canActivate(AccCategoryE.Inventory, InventoryE.EnterInventoryReceived);
    }

    openAddDrugToBuckOrNot(isOpen: boolean) {
        if (this.drugFG.value["Drug"]["DrugTypeId"] === 2) {
            this.openAddDrugToBuckOrNotForCompound(isOpen)
        } else {
            isOpen ? this.openAddDrugToBuck() : this.setDefaultBucket(
                this.bucketIdsTracker.length ? this.bucketIdsTracker[0] : null , false); // else patch prev bucketInfo
        }
    }

    openAddDrugToBuckOrNotForCompound(isOpen?, isBasicMode?,close?) {
        this.compoundIng = this._drugUtils.createDrugDataModal();
        if (this.compoundDrugIds && this.compoundDrugIds.length) {
            this._commonSvc.getDrugInfo(this.compoundDrugIds[0]).subscribe(async resp => {
                if (resp && !isBasicMode) {
                    await this._drugUtils.patchDrugInfo(resp, this.compoundIng, this.compoundDrugIds[0]);
                    isOpen ? this.openAddDrugToBuck() : this.setDefaultBucket(
                        this.bucketIdsTracker.length ? this.bucketIdsTracker[0] : null , false); 
                } 
                if (resp && isBasicMode)
                    this.drugPoManageInv();
            })
        }
        if (this.isOriginalCompDrug && this.compoundDrugIds && !this.compoundDrugIds.length) {
            if (!isBasicMode) {
                let bucketId = null;
                if (close === "NOTADDED")
                    bucketId = this.bucketIdsTracker.length ? this.bucketIdsTracker[0] : null;
                else if (close === "ADDED")
                    bucketId = this.bucketINVFG.value.Id;
                this.getBucketlistAndPatch(bucketId, false);
            } else {
                this.getBucketlistAndPatch(this.bucketINVFG.value.Id, false);
            }
        } 
    }

    openAddDrugToBuck() {
        if (!this.drugId) {
            return;
        }
        const modelRef = this._modalSvc.open( AddDrugToBucketComponent,
            {backdrop: false, size: "lg", windowClass: "modal-md modal-center-to-page", keyboard: false});
        modelRef.componentInstance.DrugFG = this.drugFG.value["Drug"]["DrugTypeId"] === 2 && this.compoundIng ? this.compoundIng : this.drugFG;
        modelRef.componentInstance.DrugBuckFG = this.bucketINVFG;
        modelRef.componentInstance.DrugId = this.drugFG.value["Drug"]["DrugTypeId"] === 2 && this.compoundDrugIds && this.compoundDrugIds.length ? this.compoundDrugIds[0] : this.drugId;
        modelRef.componentInstance.prefVendorList = this.dropDownValues && this.dropDownValues.Vendor ?
        this.dropDownValues.Vendor : [];
        // modelRef.componentInstance.DrugInfo = this.drugInfo || null;
        modelRef.componentInstance.DrugBucketInfo = this.bucketINVFG.value;
        modelRef.componentInstance.BucketId = this.bucketINVFG.value.Id || null;
        modelRef.componentInstance.emitAddDrugToBuckClose.pipe(takeUntil(this.unsubscribe$)).subscribe(close => {
            modelRef.close();
            let bucketId = null;
            if ( this.drugFG.value["Drug"]["DrugTypeId"] === 2 && this.drugId == this.compoundDrugIds[0]) 
                this.isOriginalDrugAddToBucket = close
            if (close === "NOTADDED") {
                if (this.drugFG.value["Drug"]["DrugTypeId"] === 2) {
                    this.compoundDrugIds.shift()
                    this.openAddDrugToBuckOrNotForCompound(true, false, this.isOriginalDrugAddToBucket)
                } else {
                    bucketId = this.bucketIdsTracker.length ? this.bucketIdsTracker[0] : null;
                }
            } else if (close === "ADDED") {
                if (this.drugFG.value["Drug"]["DrugTypeId"] === 2) {
                    this.compoundDrugIds.shift()
                    this.openAddDrugToBuckOrNotForCompound(true, false, this.isOriginalDrugAddToBucket)
                } else {
                    bucketId = this.bucketINVFG.value.Id;
                }
            }
            if (this.drugFG.value["Drug"]["DrugTypeId"] !== 2) {
                this._cdr.detectChanges()
                this.getBucketlistAndPatch(bucketId, false);
            }
        });
    }

    async drugPreferredVendor() {
        this.selectVendList = this.dropDownValues && this.dropDownValues.Vendor?.length ? this.dropDownValues.Vendor.find((item: any) => {
            return item.ContractId === this.drugFG.value.DrugPO.ContractId
        }) : null;
        this.vendorId = this.selectVendList ? this.selectVendList.Id : null;
        this.contractId = this.drugFG.value.DrugPO.ContractId;
        const dataPost ={
            drugId: this.drugId,
            vendorId: this.vendorId,
            ContractId: this.contractId
        };
        this.vendorDetails = await this._buckService.getInventoryPreferredVendorId(dataPost).toPromise();
        const vd = this.dropDownValues && this.dropDownValues.Vendor?.length ? this.dropDownValues.Vendor.find(v => v["Id"] === this.vendorId) : null;
        if (this.vendorDetails) {
            this.vendorDetails.VendorName = vd ? vd["ContractName"] + ", " + vd["VendorName"] : "";
        } else {
            this.vendorName = vd ? vd["ContractName"] + ", " + vd["VendorName"] : "";
        }
        this.hasVendorDetails = this.vendorDetails ? true : false;
        this._modalSvc.open(this.UpdateCost, {backdrop: false, keyboard: false, centered: true,  windowClass: "large--content"});
    }

    async patchToDrugUnitCost() {
        this.drugFG.controls["Drug"].patchValue({UnitPriceCost:
            (this.vendorDetails.UnitCost).toFixed(4), UnitPriceCost3Digits: (this.vendorDetails.UnitCost).toFixed(3)});
        this.CostPack = (this.vendorDetails.UnitCost * this.drugFG.value.Drug.QtyPack);
        const data = {
            DrugId: this.drugId,
            BucketId: this.drugFG.value.DrugPO.BucketId,
            UnitPriceCost: this.vendorDetails.UnitCost,
            CostPack: this.CostPack
        };
        await this._buckService.putInventoryPreferredVendorPrice(data).toPromise();
    }

    ngOnDestroy(){
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}