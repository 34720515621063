<div class="newrx--body merge--body">
    <div class="editpatient">
        <div class="row content--heading">
            <div class="col-md-8 padding-0">
                <div class="row">
                    <div class="icon">
                        <img src="assets/dist/Icons/Icon_rx2.png">
                    </div>
                    <div class="col-md-11 padding-0">
                        <div class="content--heading__title padding-0">Merge Patient
                            <span class="content--name"></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-right pull-right col-md-4 padding-0">
                <button class="hotkey_success" (click)="openModal(content)" *ngIf="pat1FmEP && pat2FmEP"
                    (keydown.enter)="openModal(content)" tabindex="3" id="Merged" appShortcutKey [AltKey]="'m'"><span>M</span> Merge</button>
                <button class="inactive" tabindex="-1" id="Merge" *ngIf="!(pat1FmEP && pat2FmEP)"><span>M</span>Merge</button>
                <button class="hotkey_success mr-0" (click)="routeBackToPrevScreen()"
                    (keydown.enter)="routeBackToPrevScreen()" tabindex="4" id="Cancel"
                    (keydown.tab)="focusOutFromField($event)" appShortcutKey [AltKey]="'c'"><span>C</span> Cancel</button>
                    <app-user-guide-icon [ActiveTabId]= "'MergePatients'" class="padding_left_1rem"></app-user-guide-icon>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 padding--right__7 ">
            <div class="exprx--common-block">
                <div class="eprx--block__header">
                    <div class="eprx--header__heading">
                        <span  *ngIf="!pat1FmEP"> Select Patient1 </span>
                        <span  *ngIf="pat1FmEP"> Patient - {{pat1FmEP?.Person?.LastName | uppercase}}
                            {{pat1FmEP?.Person?.FirstName ? ", " + (pat1FmEP?.Person?.FirstName | uppercase) : " " }}
                        </span>
                        <ul class="pull-right eprx--header__icons" *ngIf="pat1FmEP">
                            <li>
                                <a (click)="resetPatient1()" *ngIf="!urlPatienId">
                                    <i class="far fa-undo fa-lg" aria-hidden="true"></i>
                                </a>
                            </li>
                        </ul>
                    </div>

                </div>
                <div class="seconday-search--grid" *ngIf="!pat1FmEP">
                    <app-searchbox-global id="patSearch1" [IsFocused]="isInputFocused1" [RxSelectId]="'PatSearch1'"
                        tabindex="1" [TabIndex]="1" #PatSearch1 [SearchFor]="'Patient'" [IsAddNew]="false"
                        [DisableSerchFil]="true" [DisableRadioButtons]="true"
                        (SelectedPatient)="selectpatient1($event)"></app-searchbox-global>
                </div>
                <div class="eprx--block__content" *ngIf="pat1FmEP">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <label>RxCount</label>
                            <div class="label--data">&nbsp;{{pat1FmEP?.RxCount ? pat1FmEP?.RxCount : "0"}}</div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 ">
                            <label>Gender</label>
                            <div class="label--data">&nbsp;{{pat1FmEP?.Person?.GenderId === 2 ? "male" : pat1FmEP?.Person?.GenderId === 3 ?
                                    "female" : "--"}} </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <label>DOB</label>
                            <div class="label--data">&nbsp;{{pat1FmEP?.Person?.BirthDt  | date : 'MM/dd/yyyy'  : "--" }}
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <label>Insurance Id</label>
                            <div class="label--data">
                                &nbsp;{{patient1Insu?.policynum ? patient1Insu?.policynum : "--"}}</div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <label>Ins.Type</label>
                            <div class="label--data">
                                &nbsp;{{patient1Insu?.insuType ? patient1Insu?.insuType : "--" }}</div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <label>Group#</label>
                            <div class="label--data">&nbsp;{{ patient1Insu?.groupname ? patient1Insu?.groupname : "--" }}</div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <label>Phone#</label>
                            <div class="label--data">&nbsp;{{pat1FmEP?.Phones
                                    ? (pat1FmEP?.Phones[0]?.xPhone | mask: "(000)000-000000000") : "" }}</div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <label>Address</label>
                            <div class="label--data">
                                &nbsp;{{pat1FmEP?.Addresses?.AddressLine1?.trim() ? pat1FmEP?.Addresses?.AddressLine1+", " : "" }}
                                {{pat1FmEP?.Addresses?.CityName?.trim() ? pat1FmEP?.Addresses?.CityName+", " : ""}}
                                {{pat1FmEP?.Addresses?.StateName?.trim() ? pat1FmEP?.Addresses?.StateName : ""}}
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" *ngIf="pat1FmEP?.RxCount > 0">
                            <label>RxList</label>
                            <wj-flex-grid #patientHistory [headersVisibility]="'Column'"
                              [itemFormatter]="itemFormatter"  [itemsSource]="patientRxHistoryWJ" [isReadOnly]="true" [columnPicker]="'mergePatientListWJ'"
                                (updatedView)="init(patientHistory)" class="grid">
                                <wj-flex-grid-column [header]="rxheader['hName']" [binding]="rxheader['hName']" [visible]="rxheader['isVisible']"
                                    *ngFor="let rxheader of wjHeaders; let i = index"
                                    [width]="rxheader['width']">
                                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                        <div *ngIf="!(rxheader['hName'] === 'Drug Name' || rxheader['hName'] === 'Rx#' || rxheader['hName'] === 'Status')">
                                            {{item[rxheader['hName']]}}
                                        </div>
                                        <div *ngIf = "(rxheader['hName'] === 'Drug Name' || rxheader['hName'] === 'Rx#')" [ngbTooltip]="rxheader['hName'] === 'Drug Name' ? item['DrugHoverOver']  : item['RxHoverOver'] " placement = "top" triggers="mouseover:mouseleave" container="body" tooltipClass="tooltip-title"  container="body">
                                            {{item[rxheader['hName']]}}
                                        </div>
                                        <div *ngIf="(rxheader['hName'] === 'Status')" [ngbTooltip]="statusToolTip" placement="top" 	triggers="mouseover:mouseleave" container="body" tooltipClass="auto-title-tooltip" container="body">
                                            {{ item[rxheader['hName']] }}
                                        </div>
                                        <ng-template #statusToolTip>
                                        <div *ngIf="item['Status'] === 'D'" class="text-left">
                                        <span [innerHTML] = "item['File Due Date'] ? item['FileDueDateHover'] : item['StatusHoverOver']"></span><br/>
                                        <span [innerHTML] = "item['Defer Reason'] ? item['DeferReasonHover'] : ''"></span>
                                        </div>
                                        <div *ngIf="item['Status'] !== 'D'">
                                                {{ item['StatusHoverOver'] }}
                                        </div>
                                        </ng-template>
                                    </ng-template>
                                </wj-flex-grid-column>
                            </wj-flex-grid>
                            <div class="text-center" *ngIf="!(pat1FmEP?.RxCount > 0)">
                                No RX History exists
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 padding--right__7">
            <div class="exprx--common-block">
                <div class="eprx--block__header">
                    <div class="eprx--header__heading">
                        <span  *ngIf="!pat2FmEP"> Select Patient2</span>
                        <span  *ngIf="pat2FmEP">Patient - {{pat2FmEP?.Person?.LastName | uppercase}}
                            {{pat2FmEP?.Person?.FirstName ? ", " + (pat2FmEP?.Person?.FirstName | uppercase ) : " "}}</span>
                        <ul class="pull-right eprx--header__icons" *ngIf="pat2FmEP">
                            <li>
                                <a (click)="resetPatient2()">
                                    <i class="far fa-undo fa-lg" aria-hidden="true"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="seconday-search--grid" *ngIf="!pat2FmEP">
                    <app-searchbox-global id="PatSearch2" [RxSelectId]="'PatSearch2'" [IsFocused]="isInputFocused"
                        tabindex="2" [TabIndex]="2" #PatSearch2 [SearchFor]="'Patient'" [IsAddNew]="false"
                        [DisableSerchFil]="true" [DisableRadioButtons]="true"
                        (SelectedPatient)="selectpatient2($event , error)"></app-searchbox-global>
                </div>
                <div class="eprx--block__content" *ngIf="pat2FmEP">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <label>RxCount</label>
                            <div class="label--data">{{pat2FmEP?.RxCount ? pat2FmEP?.RxCount : "0"}}</div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 ">
                            <label>Gender</label>
                            <div class="label--data">{{pat2FmEP?.Person?.GenderId === 3 ? "female" : pat2FmEP?.Person?.GenderId === 2 ?
                                    "male" : "--"}} </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <label>DOB</label>
                            <div class="label--data">{{pat2FmEP?.Person?.BirthDt  | date : 'MM/dd/yyyy' : "--"}}</div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <label>Insurance Id</label>
                            <div class="label--data">
                                {{patient2Insu?.policynum ? patient2Insu?.policynum : "--"}}</div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <label>Ins.Type</label>
                            <div class="label--data">{{ patient2Insu?.insuType ? patient2Insu?.insuType : "--" }}</div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <label>Group#</label>
                            <div class="label--data">{{patient2Insu?.groupname ? patient2Insu?.groupname : "--"}} </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <label>Phone#</label>
                            <div class="label--data">{{pat2FmEP?.Phones
                                    ? (pat2FmEP?.Phones[0]?.xPhone | mask: "(000)000-000000000") : "--" }} </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <label>Address</label>
                            <div class="label--data">
                                &nbsp;{{pat2FmEP?.Addresses?.AddressLine1?.trim() ? pat2FmEP?.Addresses?.AddressLine1+", " : "" }}
                                {{pat2FmEP?.Addresses?.CityName?.trim() ? pat2FmEP?.Addresses?.CityName+", " : ""}}
                                {{pat2FmEP?.Addresses?.StateName?.trim() ? pat2FmEP?.Addresses?.StateName : ""}}
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" *ngIf="(pat2FmEP?.RxCount > 0)">
                            <label>RxList</label>
                            <wj-flex-grid #patientHistory [headersVisibility]="'Column'" [itemsSource]="patient2WJ"
                            [itemFormatter]="itemFormatter"  [isReadOnly]="true" [columnPicker]="'mergePatientListWJ'" (updatedView)="init(patientHistory)"
                                class="grid">
                                <wj-flex-grid-column [header]="rxheader['hName']" [binding]="rxheader['hName']" [visible]="rxheader['isVisible']"
                                    *ngFor="let rxheader of wjHeaders; let i = index"
                                    [width]="rxheader['width']">
                                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                        <div *ngIf="!(rxheader['hName'] === 'NDC' || rxheader['hName'] === 'Drug Name' || rxheader['hName'] === 'Rx#' ||  rxheader['hName'] === 'Status')">
                                            {{item[rxheader['hName']]}}
                                        </div>
                                        <div *ngIf="rxheader['hName'] === 'NDC'">
                                            {{item[rxheader['hName']] | mask:"AAAA0-0000-00"}}
                                        </div>
                                        <div *ngIf = "(rxheader['hName'] === 'Drug Name' || rxheader['hName'] === 'Rx#')" [ngbTooltip]="rxheader['hName'] === 'Drug Name' ? item['DrugHoverOver'] : item['RxHoverOver']" placement = "top" triggers="mouseover:mouseleave" container="body" tooltipClass="tooltip-title"  container="body">
                                            {{item[rxheader['hName']]}}
                                        </div>
                                        <div *ngIf="(rxheader['hName'] === 'Status')" [ngbTooltip]="statusToolTip" placement="top" 	triggers="mouseover:mouseleave" container="body" tooltipClass="auto-title-tooltip" container="body">
                                            {{ item[rxheader['hName']] }}
                                        </div>
                                        <ng-template #statusToolTip>
                                        <div *ngIf="item['Status'] === 'D'">
                                        <span [innerHTML] = "item['File Due Date'] ? item['FileDueDateHover'] : item['StatusHoverOver']"></span><br/>
                                        <span [innerHTML] = "item['Defer Reason'] ? item['DeferReasonHover'] : ''"></span>
                                        </div>
                                        <div *ngIf="item['Status'] !== 'D'">
                                                {{ item['StatusHoverOver'] }}
                                        </div>
                                        </ng-template>
                                    </ng-template>
                                </wj-flex-grid-column>
                            </wj-flex-grid>
                            <div class="text-center" *ngIf="!(pat2FmEP?.RxCount > 0)">
                                No RX History exists..
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #content let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Confirmation</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>Are you sure you want to Delete <span class="font-weight-bold">{{pat1FmEP?.Person?.LastName| uppercase}}{{pat1FmEP?.Person?.FirstName ? ", " + (pat1FmEP?.Person?.FirstName | uppercase) : " " }}</span> and copy the
            prescription over to
        <span class="font-weight-bold">{{pat2FmEP?.Person?.LastName| uppercase}}{{pat2FmEP?.Person?.FirstName ? ", " + (pat2FmEP?.Person?.FirstName | uppercase ) : " "}}</span>?</p>
    </div>
    <div class="modal-footer">
        <button class="hotkey_primary" (click)="mergePatient(success)" (click)="closeModal()" appShortcutKey
            InputKey="y"><b>Y</b> YES</button>
        <button type="button" class="hotkey_primary" (click)="closeModal()" appShortcutKey
            InputKey="n"><b>N</b>NO</button>
    </div>
</ng-template>
<ng-template #error let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Warning!!</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>Same patients cannot be selected for merging. </p>
    </div>
    <div class="modal-footer">

        <button type="button" autofocus class="hotkey_primary" (keyup.enter)="closeModal()" (click)="closeModal()"
            appShortcutKey InputKey="c"><b>C</b>Cancel</button>
    </div>
</ng-template>
<ng-template #success let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">success</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p> {{patient1Sugg?.lastname | uppercase}}
            {{patient1Sugg?.firstname ? ", " + (patient1Sugg?.firstname | uppercase) : " " }} has been deleted and added in
            {{patient2Sugg?.lastname | uppercase}}
            {{patient2Sugg?.firstname ? ", " + (patient2Sugg?.firstname | uppercase) : " " }}.</p>
    </div>
    <div class="modal-footer">

        <button type="button" class="hotkey_primary" (keyup.enter)="closeModal()" (click)="closeModal()" appShortcutKey
            InputKey="o"><b>O</b>Ok</button>
    </div>
</ng-template>
