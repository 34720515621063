import { ValidateFormFieldsUtils } from "src/app/utils/validate-form-fields.util";
import { ChangeDetectorRef, Component, OnInit, ViewChild, TemplateRef, OnDestroy } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import * as moment from "moment";
import { RxPickupDrugDropdowns } from "src/app/models";
import { CommonService, AlertService, PreviousRouteService } from "src/app/services";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CollectionView } from "@grapecity/wijmo";
import { MsgConfig } from "src/app";
import { ReportsService } from "src/app/services/reports.service";
import { DomSanitizer } from "@angular/platform-browser";
import { saveAs } from 'file-saver';
import { WijimoUtil } from "src/app/utils";
import { PrintService } from "src/app/services/print.service";
import { AsyncReportEnum, AsyncReportNameEnum } from "src/app/models/AsyncReports.enum";
import { CommonWarnorconfirmPopupComponent } from "../../shared/common-warnorconfirm-popup/common-warnorconfirm-popup.component";
import { Subject} from "rxjs";
import { CommonStore } from "src/app/store";
import { Location } from "@angular/common";
import { takeUntil } from "rxjs/operators";
import { ReportsUtil } from "src/app/utils/reports.util";
@Component({
    selector: "app-rx-pick-up-detail",
    templateUrl: "./rx-pick-up-detail.component.html"
})
export class RxPickUpDetailComponent implements OnInit, OnDestroy {
    rxPickUpFG: FormGroup;
    presInputValue = "";
    patientInputvalue = "";
    drugClassOptions = RxPickupDrugDropdowns;
    rxPickupFromOptions: any;
    @ViewChild("insuPopUp", { static: true })
    insuPopUp: any;
    @ViewChild("payorPopUp", { static: true })
    payorPopUp: TemplateRef<any>;
    insuranceData: any;
    wjHeaders: any;
    optedData: any[];
    prevSearchValue: any;
    insuWJ: CollectionView;
    pickupReport: Blob;
    pdfContent: any;
    isPrint: boolean;
    showPreview = false;
    modalRef: any;
    payorData: any[];
    payorWJ: CollectionView;
    optedInsList: any[];
    wjHeadersPayor: any;
    alreadyReportGenerated: any;
    count = 0;
    reportIsInProgress: string;
    formSubmitted: boolean;
    unsubscribe$: Subject<void> = new Subject();
    reportAlreadyPrevd: boolean;
    modelRef: any;

    constructor(private _fb: FormBuilder, private _commonSvc: CommonService, private _sanitizer: DomSanitizer,
        private _modalSvc: NgbModal, private _alertSer: AlertService, private _reportsServ: ReportsService,
        private _prevServ: PreviousRouteService, private _cd: ChangeDetectorRef, private _formUtils: ValidateFormFieldsUtils, private _wijimoUtils: WijimoUtil,
        private _printServ: PrintService,  private _commonStore: CommonStore,
        private _location: Location,
        private _modalService: NgbModal,
        private _reportUtil: ReportsUtil
        ) {
        this.rxPickUpFG = this._fb.group({
            IsPickedupLog: 1,
            FromDate: moment().subtract(7, "days").format("MM/DD/YYYY"),
            ToDate: moment().format("MM/DD/YYYY"),
            Rxrefill: null,
            InsuranceType: null,
            drugClass: null,
            PatientId: null,
            PrescriberId: null,
            PickUpIdQ: false,
            PickUpId: false,
            rxSelected: false,
            PickUpFromId: null,
            PayorIDs: [],
            PayorCodes: []
        });
        this.rxPickUpFG.controls["drugClass"].patchValue([1]);
        this._commonSvc.getCommonDropDownValues({IsFacility : false , IsPatientCategory: false , IsPayor: true , IsInsuranceList: false}).pipe(takeUntil(this.unsubscribe$)).subscribe(resp => {
            if (resp) {
              this.payorData = resp["payor"];
            }
          });
        this._commonSvc.systemData$.pipe(takeUntil(this.unsubscribe$)).subscribe(resp => {
            if (resp) {
               this.rxPickupFromOptions = resp.pickUpFrom ? resp.pickUpFrom : [];
            }
        });
    }

    ngOnInit() {
        this._reportsServ.IsAsyncReportExist(AsyncReportEnum.pickUpDetails).pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
            if (res) {
                this.reportIsInProgress =  this._reportUtil.getReportIsInProgress(res);
            }
        });
        this._commonStore.reportGenerated$.pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
            if (res && res.length) {
                res.map((report) => {
                    if (this._reportUtil.IsCurrentReportGenerated({ curremtReportName: AsyncReportEnum.pickUpDetails, 
                        ReportName: report.ReportName, IsReportGenerated: report.IsReportGenerated, currentRoute: this._location.path() })
                        && this._reportUtil.checkForReportGeneration(report)) {
                                        this.alreadyReportGenerated = report;
                                        this.generateReport();
                                    }
                });
            }
        });
        this.patchDefaultValHeaders();
        this._wijimoUtils.wjSavedData$.pipe(takeUntil(this.unsubscribe$)).subscribe(w => {
            if (w && w["WijmoKey"] && (w["WijmoKey"] === "primeescRxPickUpDetInsuWJ" || w["WijmoKey"] === "rxByInsuPayorPopupListWJ")) {
                this.patchDefaultValHeaders();
            }
        });
    }

    async patchDefaultValHeaders() {
        const storedWJ = await this._wijimoUtils.getWJSavedData("primeescRxPickUpDetInsuWJ");
        this.wjHeaders = this._wijimoUtils.patchDefHeader("primeescRxPickUpDetInsuWJ", storedWJ);
        const storedwJ2 = await this._wijimoUtils.getWJSavedData("rxByInsuPayorPopupListWJ");
        this.wjHeadersPayor = this._wijimoUtils.patchDefHeader("rxByInsuPayorPopupListWJ", storedwJ2);
    }

    generateReport() {
        const payLoad = {
            ReportName: this.alreadyReportGenerated.ReportName,
            AsyncReportId:  this.alreadyReportGenerated.AsyncReportId
        };
        if (this.alreadyReportGenerated.EventKey) {
            this._reportsServ.getAsyncFilters(payLoad).pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
                if (res) {
                    if (res.Filters) {
                        this._alertSer.error(MsgConfig.ASYNC_REPORT_ERROR + this.alreadyReportGenerated.EventKey);
                        this.setDefaultValues(res.Filters);
                        this.resetReport();
                    } if (res.isReportReviewed && !this.modelRef) {
                       this.reportViewedPopup();
                    }
                }
            });
        } else {
        this._reportsServ.getAsyncFilters(payLoad).pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
            if (res) {
                if (res.Filters) {
                this._reportsServ.AsyncReport(payLoad).pipe(takeUntil(this.unsubscribe$)).subscribe(resp => {
                    if (resp && resp.size > 0 && resp.type === "application/ms-excel") {
                        this.pickupReport = resp;
                               const blob = new Blob([resp], { type : 'application/ms-excel' });
                                const file = new File([blob], AsyncReportNameEnum.pickUpDetails + '.xlsx', { type: 'application/ms-excel' });
                               saveAs(file);

                    } else if (resp && resp.size > 0 && resp.type === "application/pdf") {
                        if (this.alreadyReportGenerated.IsPrint) {
                            this.pickupReport = resp;
                            this.printRxPickupAck();
                        } else {
                        this.showPreview = true;
                        const urlCreator = window.URL;
                        this.pdfContent = this._sanitizer.bypassSecurityTrustResourceUrl(
                            urlCreator.createObjectURL(resp) + "#toolbar=0&zoom=115"
                        );
                        }
                    } else if(!this.modelRef){
                        this.reportViewedPopup();
                    }
                    this.resetReport();
                });
                this.setDefaultValues(res.Filters);

                } if (res.isReportReviewed && !this.modelRef) {
                   this.reportViewedPopup();
                }
            }
        });
    }
    }
    setDefaultValues(resp?) {
        if (resp) {
           this.rxPickUpFG.patchValue({
            IsPickedupLog: resp.IsPickedupLog,
            FromDate: resp.FromDate,
            ToDate: resp.ToDate,
            rxSelected: resp.rxSelected,
            Rxrefill: resp.Rxrefill,
            InsuranceType: resp.InsuranceType,
            PayorCodes: resp.PayorIDs,
            PickUpIdQ: resp.PickUpIdQ,
            PickUpId: resp.PickUpId,
            PrescriberId: resp.PrescriberId,
            PatientId: resp.PatientId,
            drugClass: resp.drugClass,
            PickUpFromId: resp.PickUpFromId,
           });
           this.patientInputvalue =  resp.PatientName ? resp.PatientName : "";
           this.presInputValue =  resp.PrescriberName ? resp.PrescriberName : "";
        }
    }
        reportViewedPopup() {
            this.reportAlreadyPrevd = true;
            this.modelRef = this._modalService.open(CommonWarnorconfirmPopupComponent, {
                backdrop: false,
                keyboard: false, windowClass: "large--content"
                });
                this.modelRef.componentInstance.warnMsg = "The generated report has been previewed in another system.";
                this.modelRef.componentInstance.IsHeaderText = "Information";
                this.modelRef.componentInstance.okButton = true;
                this.modelRef.componentInstance.IsPopUpClosed.pipe(takeUntil(this.unsubscribe$)).subscribe((response: any) => {
                this.resetReport();
                    this.modelRef.close();
                    this.modelRef = null;
                    this.reportAlreadyPrevd = false;
                });
        }
        resetReport() {
            const reportsData = this._commonStore.reportGenerated$["source"]["value"];
            reportsData.map(report => {
                if (report.ReportName === AsyncReportEnum.pickUpDetails && report.IsReportGenerated) {
                    report.IsReportGenerated = false;
                    report.AsyncReportId = null;
                    report.Count = 0;
                    report.EventKey = null;
                    report.IsPrint = false;
                    report.IsExport = false;
                }
            });
            this.count = 0;
            this.reportIsInProgress = null;
        this._commonStore._reportGenerated$.next(reportsData);
        }

    async previewRxPickupAck(val: boolean, IsExport: boolean = false) {
        await this._formUtils.blurAndDelay();
        let resp = "VALID";
        this.isPrint = val;
        this.formSubmitted = false;
        // const pickDetailsValue = this.rxPickUpFG.getRawValue();

        if (!this.rxPickUpFG.value.rxSelected) {
            resp = this._formUtils.checkDateErrors(this.rxPickUpFG, "FromDate", "ToDate", 7);
        }
        if (resp === "DATEISSUE") {
            this._alertSer.error(MsgConfig.DATE_RANGE_ERROR);
        } else if (resp === "INVALID") {
            this._alertSer.error(MsgConfig.INVALID_DATES_ERROR);
        } else if (resp === "VALID" && this.rxPickUpFG.valid) {
            this.rxPickUpFG.value["drugClass"] = (!this.rxPickUpFG.value["drugClass"]
            ||this.rxPickUpFG.value["drugClass"] === 0) ? [1] :this.rxPickUpFG.value["drugClass"];
            const selectedInsCodes = this.rxPickUpFG.value["InsuranceType"];
            this.rxPickUpFG.value["IsExport"] = IsExport;
            const dataInfo = this.rxPickUpFG.value;
            dataInfo["InsuranceType"] = dataInfo["InsuranceType"] ? (dataInfo["InsuranceType"]).replace(/,\s/gi, ",") : dataInfo["InsuranceType"];
            delete(dataInfo.PayorCodes);
                const reportName = AsyncReportEnum.pickUpDetails;
                this._reportsServ.IsAsyncReportExist(reportName).pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
                    this.formSubmitted = false;
                    if (res) {
                        this.reportIsInProgress =  this._reportUtil.getReportIsInProgress(res);
                    } else {
                            dataInfo.PatientName = this.patientInputvalue;
                            dataInfo.PrescriberName = this.presInputValue;
                            const payload = {
                                Filters: dataInfo,
                                ReportName: AsyncReportEnum.pickUpDetails,
                                IsNotificationReq: true,
                                IsPrint: this.isPrint,
                                IsExport: IsExport
                            };
                            this._reportsServ.getAsyncReport(payload).toPromise();
                            this.reportIsInProgress =  this._reportUtil.getReportIsInProgress(res);
                        }
                });
        } else {
            this.formSubmitted = true;
        }
    }

    printRxPickupAck() {
        const newBlob = new Blob([this.pickupReport], { type: "application/pdf" });
        const reader = new FileReader();
        reader.readAsDataURL(newBlob);
        reader.onloadend = function () {
            const base64data = reader.result;
            this._printServ.printPdf(base64data);
        }.bind(this);
    }

    rxSelected() {
        this.presInputValue = "";
        this.patientInputvalue = "";
        this.rxPickUpFG.patchValue({
            InsuranceType: null, PatientId: null, Rxrefill: null,
            FromDate: moment().subtract(7, "days").format("MM/DD/YYYY"),
            ToDate: moment().format("MM/DD/YYYY"),
            PrescriberId: null, drugClass: [1], rxSelected: true, IsPickedupLog: 3
        });
        if (document.getElementById("RxNoSearch")) {
            document.getElementById("RxNoSearch").focus();
        }
    }

    dateFilterSelected() {
        this.rxPickUpFG.controls["Rxrefill"].patchValue(null);
        this.rxPickUpFG.controls["rxSelected"].patchValue(false);
    }

    openInsuSearch() {
        if (this.rxPickUpFG.value["IsPickedupLog"] !== 3) {
            this.insuranceData = this._commonSvc.insuranceData$["source"]["value"];
            this.optedData = [];
            this.prevSearchValue = null;
            this.generateInsuWJ(this.insuranceData);
            this._modalSvc.open(this.insuPopUp, { centered: true, size: "lg", backdrop: false });
        }
    }

    generateInsuWJ(data) {
        this.insuWJ = new CollectionView(
            data.map((ins, i) => {
            const j = {};
            j["Ins.Code"] = ins["PlanCode"];
            j["Ins.Name"] = ins["InsuranceName"];
            j["Bin#"] =  j["BIN"] = ins.BinNum;
            j["Processor Ctrl#"] = ins.ProcessorNum;
            j["Pharmacy Id"] = ins.PharmacyNum;
            j["Address"] = ins.AddressLine1;
            j["FullAddress"] = ins.AddressLine1 ? ins.AddressLine1  + "," + " " + ins.Address : " ";
            j["Phone No"] = j["Phone#"] = ins.PhoneNumber;
            j["RowId"] = ins["PlanCode"] + ins["Id"];
            j["Id"] = ins["Id"];
            if (this.rxPickUpFG.value["InsuranceType"] && (this.rxPickUpFG.value["InsuranceType"]).trim() !== "") {
                const selectedInsuCodes = (this.rxPickUpFG.value["InsuranceType"].toLowerCase()).split(", ");
                if (selectedInsuCodes.indexOf(j["Ins.Code"].toLowerCase()) > -1) {
                    j["IsSelected"] = true;
                    if (this.optedData && this.optedData.length > 0) {
                        if (this.optedData.findIndex(d => d["Ins.Code"] === j["Ins.Code"]) === -1) {
                            this.optedData = this.optedData ? this.optedData : [];
                            this.optedData.push(j);
                        }
                    } else {
                        this.optedData = this.optedData ? this.optedData : [];
                        this.optedData.push(j);
                    }
                } else {
                    if (this.optedData && this.optedData.length > 0
                        && this.optedData.findIndex(d => d["Ins.Code"] === j["Ins.Code"]) > -1) {
                        j["IsSelected"] = true;
                    } else {
                        j["IsSelected"] = false;
                    }
                }
            } else {
                if (this.optedData && this.optedData.length > 0
                    && this.optedData.findIndex(d => d["Ins.Code"] === j["Ins.Code"]) > -1) {
                    j["IsSelected"] = true;
                } else {
                    j["IsSelected"] = false;
                }
            }
            return j;
        })
        );
    }

    selectPrescriber(prescInfo: any) {
        this.presInputValue = null;
        this._cd.detectChanges();
        if (prescInfo) {
            this.rxPickUpFG.controls["PrescriberId"].patchValue(prescInfo.prescriberid);
            this.presInputValue = prescInfo.fullname ? ((prescInfo.fullname).toUpperCase()).replace("_", ", ") : "";
        } else {
            this.rxPickUpFG.controls["PrescriberId"].patchValue(null);
            this.presInputValue = "";
        }
    }

    selectPatient(patInfo: any) {
        this.patientInputvalue = null;
        this._cd.detectChanges();
        if (patInfo) {
            this.rxPickUpFG.controls["PatientId"].patchValue(patInfo.patientid);
            this.patientInputvalue = patInfo.fullname ? ((patInfo.fullname).toUpperCase()).replace("_", ", ") : "";
        } else {
            this.rxPickUpFG.controls["PatientId"].patchValue(null);
            this.patientInputvalue = "";
        }
    }

    addDrugClass(val) {
        let drugClasses = this.rxPickUpFG.value.drugClass
            ? this.rxPickUpFG.value.drugClass
            : [];
        if (val.Id === 1 || val.Id === 7 || val.Id === 6) {
            drugClasses = [val.Id];
        } else {
            if (drugClasses.includes(1) || drugClasses.includes(7) || drugClasses.includes(6)) {
                drugClasses.shift();
            }
            drugClasses.push(val.Id);
        }
        this.rxPickUpFG.patchValue({ drugClass: drugClasses });
    }

    fiterInsu(value) {
        if (value === "") {
            value = null;
        }
        if (value !== this.prevSearchValue) {
            if (value && value.trim() !== "") {
                this.prevSearchValue = value;
                const insuranceList = Object.assign([], this.insuranceData);
                const filteredData = insuranceList.filter(v => {
                    let valExists = false;
                    ["PlanCode", "InsuranceName", "BinNum"].forEach(function (key) {
                        const val = v[key] ? v[key] : "";
                        valExists =
                            valExists ||
                            val
                                .toString()
                                .toLowerCase()
                                .startsWith(value.toLocaleLowerCase());
                    });
                    return valExists;
                });
                this.generateInsuWJ(filteredData);
            } else {
                this.prevSearchValue = null;
                this.generateInsuWJ(this.insuranceData);
            }
        }
    }

    addSelectedValue() {
        let codesString = "";
        if (this.optedData && this.optedData.length > 0) {
            this.optedData.map((val, i) => {
                codesString = codesString + val["Ins.Code"].toUpperCase() + (i + 1 !== this.optedData.length ? ", " : "");
            });
        }
        this.rxPickUpFG.controls["InsuranceType"].patchValue(codesString);
        this.setFocusOnInsuType();
    }

    setFocusOnInsuType() {
        if (document.getElementById("InsuTypeSearch")) {
            document.getElementById("InsuTypeSearch").focus();
        }
        // this.patchDefaultValHeaders();
    }

    selectCode(item, event) {
        if ((!item["IsSelected"] || (event && event.target.checked))) {
            item["IsSelected"] = !item["IsSelected"];
            this.optedData = this.optedData ? this.optedData : [];
            this.optedData.push(item);
        } else {
            item["IsSelected"] = !item["IsSelected"];
            const index = this.optedData.findIndex(val => val["Id"] === item["Id"]);
            this.optedData.splice(index, 1);
        }
    }

    preventSpaceEvent(event) {
        event.preventDefault();
    }

    focusToFirst(event) {
        const elementId = this.rxPickUpFG.value["IsPickedupLog"] === 3 ? "byRx" : "pickUpdate";
        if (document.getElementById(elementId)) {
            event.preventDefault();
            // setTimeout(() => {
                document.getElementById(elementId).focus();
            // }, 10);

        }
    }

    routeBack() {
        this._prevServ.routeBackToPrevScreen();
    }

    openPayorPOPUP() {
        this.closeInsPopUp();
        this.optedData = [];
        this.optedData = Object.assign([], this.rxPickUpFG.value.PayorIDs) || [];
        this.prevSearchValue = null;
        this.generatePayorWJ(this.payorData || []);
        if (this.payorData && this.payorData.length) {
          this.modalRef = this._modalSvc.open(this.payorPopUp, {
            centered: true, size: "lg",
            windowClass: "md-x-lg", backdrop: false
          });
        } else {
          this._alertSer.error("No Payor data to select.");
        }
      }

      generatePayorWJ(data: any) {
        this.payorWJ = new CollectionView(
          data.map(item => {
            const k = {};
            k["Id"] = item["PayorId"];
            k["Payor Ins ID"] = item["PayorInsId"];
            k["Payor Name"] = item["PayorName"];
            k["Contact Name"] = item["ContactName"] ? item["ContactName"] : "";
            k["Address"] = item["Address1"] ? item["Address1"] : "";
            k["City"] = item["CityName"] ? item["CityName"] : "";
            k["State"] = item["StateName"] ? item["StateName"] : "";
            k["Zip"] = item["ZipCode"] + (item["ZipExtension"] ? ("-" + item["ZipExtension"]) : "");
            k["Phone"] =  k["Phone#"] = item["PhoneNum"] ? item["PhoneNum"] : "";
            k["Fax"] = k["Fax#"] = item["FaxNum"] ? item["FaxNum"] : "";
            k["Notes"] = item["PayorNotes"] ? item["PayorNotes"] : "";
            k["RowId"] = item["PayorId"];
            k["IsSelected"] = false;
            if (this.optedData && this.optedData.length) {
              k["IsSelected"] = this.optedData.indexOf(+k["Id"]) > -1 ? true : false;
            }
            return k;
          })
        );
      }

      selectedPayor() { // after selecting the payors.
        const payorIds = [];
        const payorCodes = [];
        this.optedData.forEach(payorId => {
          const payor = this.payorData.find(item => ((item["PayorId"] === +payorId["Id"]) || (item["PayorId"] === +payorId) )) || null;
          if (payor && payor["PayorId"] && payor["PayorInsId"]) {
            payorIds.push(payor["PayorId"]);
            payorCodes.push(payor["PayorInsId"]);
          }
        });
        this.rxPickUpFG.patchValue({ // patching payor ids
            PayorIDs: payorIds && payorIds.length ? payorIds : []
        });
        this.rxPickUpFG.patchValue({ // patching payor codes
            PayorCodes: payorCodes && payorCodes.length ? payorCodes.toString().toUpperCase().split(",").join(", ") : ""
        });
        this.closeInsPopUp();
      }

      closeInsPopUp() { // close the insurance select pop up.
        if (this.modalRef) {
          this.modalRef.close();
          this.modalRef = null;
        }
        // this.patchDefaultValHeaders();
      }
      fiterPayor(value) { // filtering the payor.
        if (value !== this.prevSearchValue) {
          if (value && value.trim() !== "") {
            this.prevSearchValue = value;
            const payorList = Object.assign([], this.payorData);
            const filteredData = payorList.filter(v => {
              let valExists = false;
              ["PayorInsId", "PayorName"].forEach(function (key) {
                const val = v[key] ? v[key] : "";
                valExists =
                  valExists ||
                  val
                    .toString()
                    .toLowerCase()
                    .startsWith(value.toLocaleLowerCase());
              });
              return valExists;
            });
            this.generatePayorWJ(filteredData);
          } else {
            this.prevSearchValue = null;
            this.generatePayorWJ(this.payorData || []);
          }
        }
      }

      ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
      }

}
