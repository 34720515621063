import { NgModule } from "@angular/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { PatientComponent } from "./patient.component";
import { SharedModule } from "../shared/shared.module";
import { WjGridModule } from "@grapecity/wijmo.angular2.grid";
import { PAdditionalInsuranceComponent } from "./additional-insurance/additional-insurance.component";
import { PCustomCategoryComponent } from "./custom-category/custom-category.component";
import { PDeliveryAddressComponent } from "./delivery-address/delivery-address.component";
import { PDiagnosisDetailsComponent } from "./diagnosis-details/diagnosis-details.component";
import { PFacilityInfoComponent } from "./facility-info/facility-info.component";
import { PLifeStyleConceptComponent } from "./life-style-concept/life-style-concept.component";
import { PMorePatientInfoComponent } from "./more-patient-info/more-patient-info.component";
import { PTrackPatientComponent } from "./track-patient/track-patient.component";
import {
    PPatientInfoComponent,
    PBasicInfoComponent,
    PPrimeInsuranceComponent,
    PNotesComponent,
    PAllergiesComponent,
    PPricingComponent,
    POtherInfoComponent,
    PDiagAddComponent
} from "./patient-info";
import { RouterModule } from "@angular/router";
import { PClinicalInfoComponent } from "./clinical-info/clinical-info.component";
import { PMessagingComponent } from "./messaging/messaging.component";
import { PPatientTabsComponent } from "./patient-tabs/patient-tabs.component";
import { NgxMaskModule } from "ngx-mask";
import { WjGridDetailModule } from "@grapecity/wijmo.angular2.grid.detail";
import { CommonHeaderComponent } from './common-header/common-header.component';
import { InsuranceRefModule } from "../insurance-ref/insurance-ref.module";
import { PatAllergiesComponent } from './pat-allergies/pat-allergies.component';
import { ToolsModule } from "../tools/tools.module";
import { spcCloudComponentModule } from "../spc-cloud/spc-cloud.module";

@NgModule({
    imports: [
        NgbModule,
        SharedModule,
        ToolsModule,
        RouterModule,
        NgxMaskModule.forRoot(),
        WjGridModule,
        WjGridDetailModule,
        InsuranceRefModule,
        spcCloudComponentModule
    ],
    declarations: [
        PatientComponent,
        PBasicInfoComponent,
        PPrimeInsuranceComponent,
        PPatientInfoComponent,
        PNotesComponent,
        PAllergiesComponent,
        PPricingComponent,
        POtherInfoComponent,
        PDiagAddComponent,
        PAdditionalInsuranceComponent,
        PClinicalInfoComponent,
        PCustomCategoryComponent,
        PDeliveryAddressComponent,
        PDiagnosisDetailsComponent,
        PFacilityInfoComponent,
        PLifeStyleConceptComponent,
        PMessagingComponent,
        PMorePatientInfoComponent,
        PPatientTabsComponent,
        PTrackPatientComponent,
        CommonHeaderComponent,
        PatAllergiesComponent
    ],
    exports: [PatientComponent, PatAllergiesComponent],
    entryComponents: [PatientComponent]
})
export class PatientModule {}
