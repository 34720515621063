
<div class="modal-icon">
    <div class="insurance--group--icon padding-0">
        <ul class="drug--icons">
            <span class="icons--padding" *ngIf="drugId">
                <i class="far fa-pen-square" title="Drug Sales Summary" aria-hidden="true" (click)="openModal('SalesSummary')"></i>
            </span>
            <span class="icons--padding">
                <i class="far fa-copy" title="Copy Info From Another Drug" aria-hidden="true" (click)="openModal('copyDrug')"></i>
            </span>
            <span class="icons--padding">
                <i class="far fa-sync-alt" title="Refresh Data from MMS" (click)="openModal('MMS')"></i>
            </span>
            <span class="icons--padding" *ngIf="drugId">
                <i class="far fa-search" title="Search All NDC OCC" (click)="openModal('NdcOcc')"></i>
            </span>
            <span class="icons--padding headerImage" *ngIf="drugId">
                <img src="assets/dist/Icons/icon_pills_white.svg"  (click)="openEquivalentPopUp()" title="Equivalent Drug" class="headerimg"/>
            </span>
        </ul>
    </div>
</div>

<app-drug-sales-summary *ngIf="actvModal === 'SalesSummary'" [drugInfo]="drugInfo" [drugId]="drugId" (IsPopUpClosed)="openModal($event)"></app-drug-sales-summary>
<app-drug-ndcocc *ngIf="actvModal === 'NdcOcc'" [drugCode]="drugInfo?.Drug?.DrugCode" (IsPopUpClosed)="openModal($event)"></app-drug-ndcocc>
<app-drug-refresh-mms *ngIf="actvModal === 'MMS'" [drugInfo]="drugInfo" [drugId]="drugId" [drugFG]="drugFg" [systemData]="systemData" [dropDownValue]="dropDownValue" [MMSDataDPV]="MMSDataDPV" (IsPopUpClosed)="openModal($event)" (DataforDrugManfact) = "dataforDrugManfact($event)"></app-drug-refresh-mms>
<app-drug-copy *ngIf="actvModal === 'copyDrug'" [originalData]="drugInfo" [drugFG]="drugFg" (IsPopUpClosed)="openModal($event)" (EmitOpenCompndDrug)="openCompPopup($event)" [miscFG]="miscFG" [miscInfo]="miscInfo"></app-drug-copy>
