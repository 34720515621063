import { ErxComponent } from "./erx.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ErxActionListGuard } from "src/app/guards";

const routes: Routes = [
  {path: "", component : ErxComponent, canActivate: [ErxActionListGuard]},
  {path: "eRxActionList", component : ErxComponent, canActivate: [ErxActionListGuard]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class ErxRoutingModule { }
