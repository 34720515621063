<div class="spc-container">  
    <div class="spc-header">
        <span>Chat History</span>
    </div>
    <div class="spc-sub-header">
        <div class="msg"><span>Message</span></div>
        <div class="msg-user-selection">
            <div class="user-selec-img">
                <div class="profile-pic">
                    <img class="profile-icon" 
                    [src]="gender?.toLowerCase() === 'male' ? '/assets/img/male_pic.png' :
                    gender?.toLowerCase() === 'female' ? '/assets/img/female_pic.png' : 
                            '/assets/img/dp_pic.png'" 
                        alt="Profile Image"/>
                </div>
                <div class="profile-txt">
                    <span>{{userName}}</span>
                    <!--<span *ngIf="activeInactive"><div></div> Active Now</span>-->
                </div>
            </div>
            <div class="user-hist-search">
                <!--<span class="far fa-search"></span>-->
                <div class="search-bar">
                    <span class="far fa-search"></span>
                    <input type="text" *ngIf="activeInactive" class="spc-inputTxt" (input)="onHistorySearch($event)" placeholder="Search history">
                </div>
            </div>
            <div class="user-hist-more"><img alt="ellipsis" src="/assets/img/ellipsis-vertical-regular.svg"></div>
        </div> 
    </div>
    <div class="spc-chat-container">
        <div class="msg-user">
            <div class="spc-search">
                <div class="search-bar">
                    <span class="far fa-search"></span>
                    <input type="text" class="spc-inputTxt" (input)="onSearch($event)" placeholder="Search patients">
                </div>
                <div class="spc-filter" (click)="togglePopup()" #menuIcon><img alt="filter" src="/assets/img/bars-filter-regular.svg"></div>
            </div>
            <app-spc-users-list 
            [filterPeriod]="selectedPeriod" 
            (userSelected)="onUserSelected($event)" 
            [searchText]="searchText"
            [searchText]="searchText"
            [activeInactive]="activeInactive"
            [patientID]="patientID">
        </app-spc-users-list>
        </div>
        <div class="spc-main">   
            <div id="popup-menu" class="filter-popup-menu filter-space-y-4" #popupMenu>
                <a *ngFor="let item of dateList" (click)="showSection(item.Id)">
                    <span class="drp-items">{{ item.Name }}</span>
                </a>
            </div>
            <div class="spc-history">
                <app-spc-chat-history 
                [patientID]="patientID" 
                [userName]="userName" 
                [messages]="messages" 
                [enterMessage]="enterMessage" 
                [resetMessages]="resetMessages"
                [searchHistoryText]="searchHistoryText">
            </app-spc-chat-history>
            </div>
            <div class="spc-input">
                <div class="spc-enter">
                    <input type="text" id="enteredMessage" *ngIf="activeInactive" (keydown)="onEnter($event)" [(ngModel)]="enteredMessage" class="spc-inputTxt" [placeholder]="placeholderMessage" (focus)="onFocus()" [ngClass]="{ 'error-placeholder': isError }">
                </div>
                <div class="link"><img alt="ellipsis" src="/assets/img/link-simple-regular.svg" style="display: none;" ></div>
                <button type="button" class="send-button" (click)="sendMessage()">
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px">
                        <path d="M120-160v-240l320-80-320-80v-240l760 320-760 320Z"/></svg>
                </button>
                <!--<div class="spc-send">
                    <img alt="Send" class="send-icon" (click)="sendMessage()" src="/assets/img/send.svg"/>
                </div>-->
            </div>
        </div>
    </div>
    
</div>