<div class="exprx--common-block">
    <div class="eprx--block__header">
        <div class="eprx--header__heading">
            <div class="col-sm-12 padding-0">
                <div class="row">
                    <div class="col-md-8">
                        Other Insurance
                    </div>
                    <div class="col-md-4 text-right">
                        <button class="hotkey_success  hotkey_primary--Override" autofocus
                        title="Add" appShortcutKey [AltKey]="'a'" (click)="openAddModal(otherInsData)" title="Add OtherInsurance Payer" (keydown.tab)="focusOutFrmAdd($event)"><span>A</span>ADD OTHER INS. PAYER ID</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="eprx--block__content erx--body">
        <div>
            <wj-flex-grid #otherInsPayer [headersVisibility]="'Column'" [itemsSource]="otherInsuData['wjSource']" [isReadOnly]="true" [selectionMode]="'Row'"
            [allowSorting]=true [columnPicker]="'otherInsuListWj'" [ngClass]="!otherInsuData['count'] ? 'no-data':''">
            <wj-flex-grid-column [header]="'Actions'" [width]="180">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                <i class="far  fa-edit  actions actions--Highlightfocus--icon" title="Edit" (click)="openEditMode(otherInsData, item)"></i>
                <i class="far fa-trash-alt  actions danger-actve" title="Delete" (click)="openDeleteMode(deleteInfo, item)"></i>
                </ng-template>
            </wj-flex-grid-column>
            <wj-flex-grid-column [header]="rxheader['hName']" [binding]="rxheader['hName']" [visible]="rxheader['isVisible']" *ngFor="let rxheader of wjHeaders; let i = index"
                title={{rxheader.hName}} [width]="rxheader['width']">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                 {{item[rxheader['hName']]}}
                </ng-template>
            </wj-flex-grid-column>
            <app-no-data-found *ngIf="!isDataExists"></app-no-data-found>
        </wj-flex-grid>
        <app-eprx-pagination *ngIf="(otherInsuData['count'] > 0)" [TotalCount]="otherInsuData['count']"  (OnPageSelected)="setPage($event)" (OnSizeSelected)="setSize($event)" [GridName]="'OtherInsuGrid'" [WijmoName]="otherInsPayer" (OnFocusOut)="focusToSave($event)"></app-eprx-pagination>
        </div>
    </div>

</div>


<ng-template #otherInsData let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Other Payer ID</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body edit-patient insurance--body">
        <div class="row">
            <div class="col">
                <table class="table table-hover">
                    <tbody>
                        <div *ngIf="popUpType === 'Add'" class="form-group custom--input" [ngClass]="{
                            'has-error':
                            otherInsuFG?.get('InsuPlanId')?.invalid &&
                                (otherInsuFG?.get('InsuPlanId')?.dirty || otherInsuFG?.get('InsuPlanId')?.touched) &&
                                otherInsuFG?.get('InsuPlanId')?.errors
                        }">
                            <label class="control-label pat-insuType">
                                <span> Other Insurance ID <span class="help--text__danger">*</span></span>
                                <span class="curency-format">
                                    <input type="text" #searchBoxInsType [value]="inputValue"
                                        class="right-part form-control search--input__box"
                                        placeholder="To Start Search..." appChangeDebounce [ngbTypeahead]="search"
                                        [required]="true" [resultTemplate]="rt" [inputFormatter]="formatter"
                                        (selectItem)="selectedInsType($event)" KeyUpDown [appAutofocus]="true">
                                    <div class="left-part">
                                        <input type="text" tabindex="-1" #searchBoxInsName [value]="insuName"
                                            name="searchBoxInsName" class="form-control search--input__box" readonly />
                                    </div>
                                </span>
                            </label>
                            <div class="help-block"
                                *ngIf="otherInsuFG?.get('InsuPlanId')?.invalid && (otherInsuFG?.get('InsuPlanId')?.dirty || otherInsuFG?.get('InsuPlanId')?.touched)">
                                Required
                            </div>
                            <ng-template #rt let-r="result" let-t="term">
                                <div class="card patient-card secondary-patient-card" *ngIf="r">
                                    <div class="row">
                                        <div class="col">
                                            <div class="row">
                                                <div class="card__subTit--head">
                                                    Insurance Code:
                                                </div>
                                                <div class="card__subTit--title">
                                                    {{ r?.InsurerCode }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <div class="row">
                                                <div class="card__subTit--head">
                                                    Insurance Name :
                                                </div>
                                                <div class="card__subTit--title">
                                                    {{ r?.InsuranceName }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <div class="row">
                                                <div class="card__subTit--head">
                                                    PCN Num :
                                                </div>
                                                <div class="card__subTit--title">
                                                    {{ r?.ProcessorNum }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </div>
                        <div *ngIf="popUpType === 'Update'" [formGroup]="otherInsuFG">
                            <div class="padding-0" style="margin-bottom: 0.5rem;">
                                <label class="control-label col-sm-12 padding-0">
                                    <span>Other Insurance Id</span>
                                        <input type="text" name="searchBoxOtherInsu" [attr.readonly]="true" class="form-control search--input__box" value="{{ otherInsuFG.value.OtherInsuId }}">
                                   </label>
                            </div>
                        </div>
                        <!-- <div [formGroup]="otherInsuFG">
                            <div class="padding-0" style="margin-bottom: 0.5rem;">
                                <label class="control-label col-sm-12 padding-0">
                                    <span>Other Insurance Id</span>
                                    <div *ngIf="popUpType === 'Add'">
                                        <input autofocus type="text" [value]="insuPayid" name="searchBoxOtherInsu" class="form-control search--input__box" placeholder="To Start Search..." [ngbTypeahead]="typeHeadOtherInsu"
                                        id="InsuCodeSearch" [resultTemplate]="rt" [inputFormatter]="OtherInsuformatter" (selectItem)="selectedOtherInsu($event)" KeyUpDown>
                                    </div>
                                    <div *ngIf="popUpType === 'Update'">
                                        <input type="text" autofocus name="searchBoxOtherInsu" [attr.readonly]="true" class="form-control search--input__box" value="{{ otherInsuFG.value.OtherInsuId }}">
                                    </div>

                                </label>
                            </div>
                            <ng-template #rt let-r="result" let-t="term">
                                <div class="card patient-card" *ngIf="r">
                                    <div class="row">
                                        <div class="col">
                                            <div class="row">
                                                <div class="card__subTit--head"> Insurance Code : </div>
                                                <div class="card__subTit--title"> {{r?.PayerId}}</div>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <div class="row">
                                                <div class="card__subTit--head"> Insurance Name : </div>
                                                <div class="card__subTit--title"> {{r?.Qualifier}}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <div class="row">
                                                <div class="card__subTit--head">Processor Num : </div>
                                                <div class="card__subTit--title"> {{r?.ProcessorNum}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </ng-template>
                        </div> -->
                        <eprx-input [AutoFocus]="popUpType === 'Update'" [LabelText]="'Payer ID'" [PlaceHolder]="'Payer ID'" [ControlName]="'PayerCode'" [FormGroupName]="otherInsuFG"
                            [Title]="'V. Certification'" [MaxLength]="15">
                        </eprx-input>
                        <eprx-select [LabelText]="'Payer ID Qualifier'" [PlaceHolder]="'Payer ID Qualifier'" [HasMultiple]="false" [Show2Values]="true"
                            [BindLabel2]="'Remarks'" [BindLabel]="'Name'" [FormGroupName]="otherInsuFG" [ControlName]="'PayerQualifId'" [BindValue]="'Id'"
                            [LabelForId]="'Id'" [List]="dropDownData?.PayerQualifier">
                        </eprx-select>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="hotkey_success" (click)="addOrEditOtherInsu()" appShortcutKey [AltKey]=" popUpType === 'Add'? 's' : 'u' "><span>{{ popUpType === 'Add'? 'S' : 'U' }}</span> {{ popUpType === 'Add'? 'Save' : 'Update' }}</button>
        <button type="button" class="hotkey_success" (click)="c('Close click')" appShortcutKey [AltKey]="'c'"><span>C</span> CANCEL</button>
    </div>
</ng-template>


<ng-template #deleteInfo let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">{{warningText?.deleteHeaderTxt}}</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="c('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body edit-patient">
        <div class="row">
            <div class="col">
                <table class="table table-hover">
                    <tbody>
                        <label>{{warningText?.deleteConfrmMsg}}</label>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="hotkey_primary" (click)="c('Close click')" (click)="deleteOtherInsu()" appShortcutKey InputKey="y"><b>Y</b> YES</button>
        <button type="button" class="hotkey_primary" (click)="c('Close click')" appShortcutKey InputKey="n"><b>N</b> NO</button>
    </div>
</ng-template>

<ng-template #InsuranceNotFound let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Warning</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click');closeInsuNotFound()">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>Insurance not found in the file.</p>
    </div>
    <div class="modal-footer">
        <button type="button" ngbAutoFocus class="hotkey_primary" (click)="c('Close click');closeInsuNotFound()" appShortcutKey
            InputKey="o"><b>O</b> OK</button>
    </div>
</ng-template>
