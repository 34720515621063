import { Component, OnInit, Input, Output, EventEmitter, ViewChild,
        OnDestroy, AfterContentChecked, ChangeDetectorRef } from "@angular/core";
import { CommonService, RxService, TransmissionService } from "src/app/services";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TRRxOnlyComponent } from "src/app/modules/transmission";
import { FormGroup } from "@angular/forms";
import { CollectionView } from "@grapecity/wijmo";
import { RxModel } from "src/app/models";
import { RxStore } from "src/app/store";
import { Observable, Subject } from "rxjs";
import { TransmissionUtils, WijimoUtil } from "src/app/utils";
import * as moment from "moment";
import { takeUntil } from "rxjs/operators";

@Component({
    selector: "app-reverse-claim",
    templateUrl: "./reverse-claim.component.html",
    styles: []
})
export class ReverseClaimComponent implements OnInit {
    reverseClaimInfo: any;
    modelRef: any;
    cnfmModelRef: any;
    rxTransmissionWJ: any;
    isReverseAll: boolean;
    isRetransmit: boolean;
    actnType: string;
    transmsType: string;
    transmisnData$: Observable<any>;
    insrncToRevrs: any;
    insuPriorToRevrs: any;
    revAllowDays: any;
    daysBild: number;
    wjHeaders: any = [];
    totalCount : any  = 0;
    isViewContent: boolean = false;
    @Input() rxInfo: RxModel;

    @Input() rxFG: FormGroup;

    @Input() PayRecrds: any;

    @Output()
    IsPopUpClosed = new EventEmitter<any>();

    @ViewChild("REVERSECLAIM", { static: true })
    REVERSECLAIM: any;

    @ViewChild("REVERSEALLCNFM", { static: true })
    REVERSEALLCNFM: any;

    @ViewChild("REVERSALRESTRICT", {static: true})
    REVERSALRESTRICT: any;
    reverseClaimInfoTrans: any;
    unsubscribe$ : Subject<void> = new Subject();

    constructor(
        private _rxService: RxService,
        private _modalService: NgbModal,
        private _rxStore: RxStore,
        private _transServ: TransmissionService,
        private _transUtls: TransmissionUtils,
        private _cdr: ChangeDetectorRef,
        private _wijimoUtils: WijimoUtil,
        private _comnSvc: CommonService
    ) {
        this.transmisnData$ = this._rxStore.transmissionData$;
    }

    async ngOnInit() {
        this.revAllowDays = this._comnSvc.getSetttingValue("RxSettings", "REV_ALLOW_DAYS");
        this.revAllowDays = this.revAllowDays ? parseInt(this.revAllowDays, 10) : 0;
      await  this.patchDefHeader();
        this._wijimoUtils.wjSavedData$.pipe(takeUntil(this.unsubscribe$)).subscribe(w => {
            if (w && w["WijmoKey"] && w["WijmoKey"] === "reverseClaim") {
                this.patchDefHeader();
            }
        });
        if (this.rxInfo && this.rxInfo.PrescReFill) {
            this.getReverseClaimInfo();
        }
    }
    async patchDefHeader() {
        const storedWJ = await this._wijimoUtils.getWJSavedData("reverseClaim");
      //  this.wjHeaders = this._wijimoUtils.patchDefHeader("reverseClaim", storedWJ);
    }

    async getReverseClaimInfo() {

        const resp: any = await this._rxService.getReverseClaimInfo(
                this.rxInfo.Prescription.Id, this.rxInfo.PrescReFill.ReFillNum,
                this.rxInfo.PrescReFill.PartialFillNo).toPromise();
        if (resp) {
            if (!this.isReverseAll ||
                 (this.reverseClaimInfoTrans && this.reverseClaimInfoTrans.ResponseStatus === "R" && this.isReverseAll)) {
                    this.transmsType = null;
                this.closeModal();
                this.modelRef = this._modalService.open(this.REVERSECLAIM, {
                    size: "lg",
                    keyboard: false,
                    centered : true
                });
            }
            this.reverseClaimInfo = resp;
            const rxpay = this.reverseClaimInfo.RxPay;
            this.totalCount = this.reverseClaimInfo?.RxPay.length;

            this.generateLogsWJ(rxpay);
            if (!(resp.RxPay && resp.RxPay.length)) {
                this.closeModal();
                this.getAndUpdateRxInfo();
            } else if (this.isReverseAll && (this.reverseClaimInfoTrans && this.reverseClaimInfoTrans.ResponseStatus === "A")) {
                this.transmitRevrseOnly();
            }
        } else {
            this.getAndUpdateRxInfo();
            // this.closeModal();
        }
    }
    ngAfterViewInit(): void {
        this.isViewContent = true;
    }
    generateLogsWJ(rxLogs) {
        this.rxTransmissionWJ = new CollectionView(
            rxLogs.map((Rx, i) => {
            const k = {};
            k["Rx#"] = (Rx.PrescNum);
            k["Ref#"] = (Rx.RefillNum);
            k["Fill#"] = (Rx.FillNo);
            k["Rec Type"] = (Rx.PrescNum);
            k["Cov"] = (Rx.CoverageCD);
            k["Ins.Code"] = (Rx.Ins_Code);
            k["Date"] = moment.utc(Rx.DatePaid).local().format(
                "MM/DD/YYYY hh:mm:ss a"
            );
            k["OTHPYRPDQ"] = (Rx.OthPYRPDQ);
            k["Ingr.Cost Paid"] = (Rx.ICostPaid);
            k["Fee Paid"] = (Rx.DFEEPaid);
            k["Pat Pay"] = (Rx.PTPayment);
            k["FSTAXAMT"] = (Rx.FSTaxAmt);
            k["PSTAXAMT"] = (Rx.PSTaxAmt);
            k["PSTAXRATE"] = (Rx.PSTaxRate);
            k["PSFEEPAID"] = (Rx.PSFEEPaid);
            k["OTHAMTPAID"] = (Rx.OthAMTPaid);
            k["OthPayRecog"] = (Rx.OthPayRecg);
            k["NetPaid"] = (Rx.TotAmtPaid);
            k["OTHPAYQ1"] = (Rx.OthPayQ1);
            k["OTHPAYAMT1"] = (Rx.OthPayAmt1);
            k["OTHPAYQ2"] = (Rx.OthPayQ2);
            k["OTHPAYAMT2"] = (Rx.OthPayAmt2);
            k["OTHPAYQ3"] = (Rx.OthPayQ3);
            k["OTHPAYAMT3"] = (Rx.OthPayAmt3);
            k["TAXEXEMPT"] = (Rx.TaxExempt);
            k["BASISOFREI"] = (Rx.BASISOFREI);
            k["BOC_DFEE"] = (Rx.BOC_DFEE);
            k["BOC_COPAY"] = (Rx.BOC_Copay);
            k["BOC_FSTAX"] = (Rx.BOC_FSTax);
            k["BOC_PSTAX"] = (Rx.BOC_PSTax);
            k["REJCODES"] = (Rx.REJCodes);
            k["AMT_PREDED"] = (Rx.Amt_PERDED);
            k["AMT_SALETX"] = (Rx.Amt_SaleTx);
            k["AMT_COPINS"] = (Rx.Amt_COPINS);
            k["AMT_PRODSL"] = (Rx.Amt_PRODSL);
            k["AMT_EXCMAX"] = (Rx.Amt_EXCMAX);
            k["INSNETPAID"] = (Rx.InsNetPaid);
            k["PSTaxBasisPaid"] = (Rx.PSTaxBasisPaid);
            k["AccumDedAmount"] = (Rx.InsNetPaid);
            k["INSNETPAID"] = (Rx.InsNetPaid);
            k["RemDedAmount"] = (Rx.RemDedAmount);
            k["RemBenifitAmount"] = (Rx.RemBenefitAmount);
            k["AmtProcFee"] = (Rx.AmtProcFee);
            k["PatSTaxAmt"] = (Rx.PatSTaxAmt);
            k["AmtCoIns"] = (Rx.AmtCoIns);
            k["BOC_CoIns"] = (Rx.BOC_CoIns);
            k["AmtProcFee"] = (Rx.AmtProcFee);
            k["EstGen"] = (Rx.EstGenSavings);
            k["SpendAccAmtRem"] = (Rx.SpendAccAmtRem);
            k["HPIanFundAmt"] = (Rx.HPlanFundAmt);
            k["AmtProvNetSel"] = (Rx.AmtProvNetSel);
            k["AmtProdSelBrand"] = (Rx.AmtProdSelBrand);
            k["AmtProdSelNPForm"] = (Rx.AmtProdSelNPForm);
            k["AmtProdSelNPFormB"] = (Rx.AmtProdSelNPFormB);
            k["AmtCovGap"] = (Rx.AmtCovGap);
            k["IngCostContRAmt"] = (Rx.IngCostContRAmt);
            k["DFeeContRAmt"] = (Rx.DFeeContRAmt);
            k["COBBillSeq"] =  (Rx.CoverageCD == 0) ? 'P': Rx.CoverageCD == 1? 'S': 'T' ;
            k["InsNetPaid"] = (Rx.InsNetPaid);

            return k;
        }));

    }

    transmitRevrseOnly() {
        this.insrncToRevrs = this.reverseClaimInfo.RxPay[0].Ins_Code;
        this.insuPriorToRevrs = this.reverseClaimInfo.RxPay[0].CoverageCD;
        this.transmsType = "TransReverse";
    }

    trnsmtRevrsNdReTrnsmt() {
        this.insrncToRevrs = this.reverseClaimInfo.RxPay[0].Ins_Code;
        this.insuPriorToRevrs = this.reverseClaimInfo.RxPay[0].CoverageCD;
        this.transmsType = "ReversReTrans";
    }

    transmitAdjstment() {
        this.insrncToRevrs = this.reverseClaimInfo.RxPay[0].Ins_Code;
        this.insuPriorToRevrs = this.reverseClaimInfo.RxPay[0].CoverageCD;
        this.transmsType = "TransAdjst";
    }
    checkHasAnyPayRecrds(val?) {
        this._cdr.detectChanges();
        this.reverseClaimInfoTrans = val && val.reverseClaimTranmission &&  val.reverseClaimTranmission.nCPDPRecv ? val.reverseClaimTranmission.nCPDPRecv : null;
        if (val && val.reverseClaimTranmission &&  val.reverseClaimTranmission.nCPDPRecv && val.reverseClaimTranmission.nCPDPRecv.ResponseStatus !== "R" &&this.transmsType === "ReversReTrans") {
            this.transmsType = null;
            this.insrncToRevrs = this.reverseClaimInfo.RxPay[0].Ins_Code;
            this.insuPriorToRevrs = this.reverseClaimInfo.RxPay[0].CoverageCD;
            setTimeout(() => {
                this.transmsType = "ReTransmission";
            }, 100);
        } else {
            this.transmsType = null;
            this.getReverseClaimInfo();
        }
    }

    reverseAllInsurances() {
        this.isReverseAll = true;
        this.cnfmModelRef = this._modalService.open(this.REVERSEALLCNFM, {centered: true,
            backdrop: false,
            keyboard: false,
            windowClass: "large--content"
        });
    }

    initiateReverseAll() {
        if (this.cnfmModelRef) {
            this.cnfmModelRef.close();
            this.cnfmModelRef = null;
        }
        this.transmitRevrseOnly();
    }

    closeReverseAllCnfm() {
        this.isReverseAll = false;
        if (this.cnfmModelRef) {
            this.cnfmModelRef.close();
            this.cnfmModelRef = null;
        }
        this.IsPopUpClosed.emit();
    }

    getAndUpdateRxInfo() {
        this.IsPopUpClosed.emit(null);
        this.isReverseAll = true;
    }

    closeModal(val?) {
        if (this.modelRef) {
            this.modelRef.close();
            this.modelRef = null;
        }
        if (val && val === "crossClick") {
            this.IsPopUpClosed.emit();
        }
    }

    cancelRevModal() {
        if (this.modelRef) {
            this.modelRef.close();
            this.modelRef = null;
        }
        this.IsPopUpClosed.emit();
    }

  

    checkNdTrnsmt(type: string) {
        if (this.modelRef) {
            this.modelRef.close();
            this.modelRef = null;
        }
        const INSCD = this.reverseClaimInfo.RxPay ? this.reverseClaimInfo.RxPay[0].Ins_Code.trim() : null;
        const INS = this.rxInfo && this.rxInfo["RxPay"] && this.rxInfo["RxPay"].length ?
        this.rxInfo["RxPay"].find(rec => (rec["Ins_Code"].trim() === INSCD  && (((rec["RecType"].trim()).toUpperCase() === "P" || (rec["RecType"].trim()).toUpperCase() === "C"
        || (rec["RecType"].trim()).toUpperCase() === "D")))) || null :
        this.PayRecrds && this.PayRecrds.length ?
        this.PayRecrds.find(rec => (rec["Ins_Code"].trim() === INSCD  && (((rec["RecType"].trim()).toUpperCase() === "P" ||
         (rec["RecType"].trim()).toUpperCase() === "C" || (rec["RecType"].trim()).toUpperCase() === "D")))) || null : null;
        const billDate = INS ? INS["DatePaid"] : moment();
        this.daysBild = moment().diff(moment(billDate), "days");
        if (INS && this.revAllowDays && (this.daysBild < this.revAllowDays) ) {
            if (type === "TRREV") {
                this.transmitRevrseOnly();
            } else if (type === "REV_RETR") {
                this.trnsmtRevrsNdReTrnsmt();
            } else if (type === "REVALL") {
                this.reverseAllInsurances();
            }
        } else if (INS && this.revAllowDays) {
            this._modalService.open(this.REVERSALRESTRICT, {centered: true,
                backdrop: false,
                keyboard: false,
                windowClass: "large--content"
            });
        }
    }

    ngOnDestroy(){
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
