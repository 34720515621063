import { Injectable } from "@angular/core";


@Injectable({
    providedIn: "root"
})
export class StatusCommonLoigc {
    constructor() {
    }
    getDeferStatus(rxFG: any) {
        if ((rxFG?.PrescReFill?.Status === 2 || rxFG?.Prescription?.RxStatusId === 2 || rxFG?.RxBill.StatusId === 6)) {
            return true;
        } else
            return false;
    }
    getTransferredInStatus(rxFG) {
        if (rxFG?.PrescReFill?.StatusId === 3 || rxFG?.Prescription?.RxStatusId === 3)
            return true;
        else
            return false;
    }

}
