<div class="exprx--common-block">
    <div class="eprx--block__header large_header">
        <div class="row">
            <div class="col">
                <span class="eprx--header__heading">Patient Category</span>
            </div>
            <div class="col pull-right text-right">
                <button class="hotkey_success hotkey_primary--Override" title="Add Patient Category" (keydown.tab)="focusOutFromAdd($event)"
                    autofocus (click)="openPopUpModal(customList)" appShortcutKey
                    [AltKey]="'a'"><span>A</span>ADD Patient CATEGORY</button>
            </div>
        </div>
    </div>
    <div class="eprx--block__content erx--body">
        <wj-flex-grid #PatientCustomList [headersVisibility]="'Column'" (updatedView)="init(PatientCustomList)"
            (keydown.alt.d)="getRowDetails(deleteCustom, PatientCustomList, $event)" [ngClass]="!totalCount ? 'no-data':''"
            (keydown.tab)="focusToFirst($event)" (keydown)="openPopOnWijimo(customList, $event)" [selectionMode]="'Row'"
            [itemsSource]="patientCustomWJ" [isReadOnly]="true">
            <wj-flex-grid-column [header]="'Action'" [width]="'0.5*'">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                    <i class="far fa-trash-alt actions danger-actve" title="delete"
                        (click)="getRowDetails(deleteCustom, PatientCustomList, $event)"></i>
                </ng-template>
            </wj-flex-grid-column>
            <wj-flex-grid-column [header]="'Category Code'" [width]="'1*'" [binding]="'Code'">
            </wj-flex-grid-column>
            <wj-flex-grid-column [header]="'Category Name'" [width]="'6*'" [binding]="'Name'">
            </wj-flex-grid-column>
            <app-no-data-found *ngIf="!isDataExists"></app-no-data-found>
        </wj-flex-grid>
    </div>
</div>

<!-- Custom Category list -->
<ng-template #customList let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Patient Category List</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-4">
                <eprx-input [AutoFocus]="true" [LabelText]="'Category Code'" [PlaceHolder]="'Search Category Code'"
                    (TriggerChangeValue)="getFilteredData($event, 'Code')" [HasControl]="false">
                </eprx-input>
            </div>
            <div class="col-md-4">
                <eprx-input [LabelText]="'Category Name'" [PlaceHolder]="'Search Category Name'"
                    (TriggerChangeValue)="getFilteredData($event, 'Name')" [HasControl]="false">
                </eprx-input>
            </div>
        </div>
        <wj-flex-grid #categoryList [headersVisibility]="'Column'" [selectionMode]="'Row'" [itemsSource]="customWJ"
            [isReadOnly]="true" (initialized)="Init(categoryList)" (updatedView)="init1(categoryList)"
            (dblclick)="keyEventOnWijimo(categoryList, $event)" (keydown)="closeAddPop($event)"
            (keydown.space)="preventSpaceEvent($event);changeCateg(categoryList.selectedItems[0],null)"
            *ngIf="totalCount > 0" class="add-pat-cat-wijmo">
            <wj-flex-grid-column header="Select" [width]="100">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-cell="cell">
                    <span  class= "text-center" *ngIf="!item['AlreadySelected']">
                        <input (focus)="$event.target.blur()" class="form-check-input" type="checkbox" [id]="item.RowId" name=""
                            [(ngModel)]="item.IsSelected" [checked]="item.IsSelected"
                            (click)="changeCateg(item,$event)" />
                        <label class="form-check-label" [for]="item.RowId">
                        </label>
                    </span>
                    <span  class= "text-center" *ngIf="item['AlreadySelected']">
                        <i class="far fa-check-circle text-success actions"  aria-hidden="true" title="Already Selected"></i>
                    </span>
                </ng-template>
            </wj-flex-grid-column>
            <wj-flex-grid-column [header]="'Category Code'" [width]="150" [binding]="'Code'">
            </wj-flex-grid-column>
            <wj-flex-grid-column [header]="'Category Name'" [width]="'*'" [binding]="'Name'">
            </wj-flex-grid-column>
        </wj-flex-grid>
        <app-eprx-pagination [rxId]="'custmPage'" *ngIf="totalCount > 0" [TotalCount]="totalCount" [PageSize]="pageSize"
            [WijmoName]="wijimoId1" [GridName]="'categoryList'" (OnPageSelected)="setPage($event)"
            (OnSizeSelected)="setPageSize($event)" [FromModal]="true"></app-eprx-pagination>
        <div *ngIf="totalCount === 0" class="eprx--block__footer">
            <div>
                <span></span>
                <i class="fa fa-file"></i><br/>
                No Data Found!
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" id="custmAdd" class="hotkey_success" (click)="addSelectdItems()" appShortcutKey
            [AltKey]="'a'"><span>A</span> Add Selected</button>
        <button type="button" class="hotkey_success" (click)="c('Close click')" appShortcutKey
            [AltKey]="'c'"><span>C</span> Close</button>
    </div>
</ng-template>

<!-- delete custom category -->
<ng-template #deleteCustom let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Confirmation</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="c('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>Are you sure you want to delete the selected Patient Category?</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="hotkey_primary" (click)="deleteCustomCategory(); c('Close click')" appShortcutKey
            InputKey="y"><b>Y</b> YES </button>
        <button type="button" class="hotkey_primary" (click)="c('Close click')" appShortcutKey InputKey="n"><b>N</b>
        NO</button>
    </div>
</ng-template>
