import { Component, EventEmitter, Input, NgZone, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { MessageService } from 'src/app/services/message.service';
import { CommonService } from 'src/app/services';
import { Subject, Subscription } from 'rxjs';
import { MarkAsRead, UsersHistory } from 'src/app/models/messaging-model';
import { FiltersEnum, IdentifierTypeEnum } from 'src/app/models/messaging.enum';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { WebPubSubService } from 'src/app/services/WebPubSubService';
import { UnreadCountService } from '../service/UnreadCountService';

@Component({
  selector: 'app-spc-users-list',
  templateUrl: './spc-users-list.component.html',
  styleUrls: ['./spc-users-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SpcUsersListComponent implements OnInit {
  @Output() userSelected = new EventEmitter<any>();
  @Input() filterPeriod: string = FiltersEnum.ALLHISTORY;
  @Input() searchText: string = '';
  @Input() activeInactive!: boolean;
  @Input() patientID!: string;
  markAsReadFlag:boolean = false;
  pharmacyName:any;
  unsubscribe$: Subject<void> = new Subject();
  auth0_token:any;
  npi:any;
  userList: any[] = [];
  avatarUrl: string = '';
  selectedUser: any;
  filteredList: any[] = [];
  //patientID: any;
  private messageSubscription: Subscription;
  constructor(
    private _msgServ: MessageService,
    private _userServ: UserService,
    private _commonServ: CommonService,
    private webPubSubService: WebPubSubService,
    private _unreadCountService: UnreadCountService) {}

  ngOnInit(): void {
    this.pharmacyName = this._userServ.getToken("PharmacyName");
    this.auth0_token = JSON.parse(this._commonServ.DecryptData(sessionStorage.getItem("CmnMicroSvcToken")));
    this.npi = this._userServ.getToken("UniquePharmacyId");
    this.messageSubscription = this.webPubSubService.newMessageReceived$.subscribe((hasNewMessage: any) => {
      if (hasNewMessage) {
        const patientID = hasNewMessage?.patientID;
        const newMessage = hasNewMessage?.lastMessage;
        const receivedAt = hasNewMessage?.lastMessageReceivedAt;
        const userToUpdate = this.filteredList.find(user => user?.identifier === patientID);
        if (userToUpdate) { 
            userToUpdate.lastMessage = newMessage;
            userToUpdate.lastMessageReceivedAt = receivedAt;
              if (this.activeInactive && this.patientID) {
                  const activeUser = this.filteredList.find(user => user?.identifier === this.patientID);
                  if (activeUser) {
                    activeUser.unreadCount = 0;
                    this.markAsReadFlag = true; 
                    this.markAsRead();
                  }
              } else { 
                  userToUpdate.unreadCount = (userToUpdate.unreadCount || 0) + 1;
              }
        }
      }
    });
    this.loadUsers();
  }
  private capitalizeName(name: string): string {
    return name
      .toLowerCase()
      .replace(/\b\w/g, (char) => char.toUpperCase());
  }
  loadUsers(){
    if(this.npi){
        const usersPayLoad: UsersHistory = {
              Recipient: {
                  Identifier: this.npi,
                  IdentifierType: IdentifierTypeEnum.NPI
              },
              pharmacyNPI: this.npi
        };
        this._msgServ.postMessageChatSummary(usersPayLoad)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((res: any[]) => {
          if (res) {
            this.userList = res['communicationSummary']; 
            this.userList.forEach(msg => {
              if (msg?.userName) {
                msg.userName = this.capitalizeName(msg?.userName);
              }
            });
            this.applyFilter();
          }
        });
    }
  }
  ngOnChanges() {
    this.applyFilter(this.searchText);
  }
  applyFilter(searchText: string = ''): void {
    const now = moment();
    const lowerCaseSearchText = searchText.toLowerCase(); 
    this.filteredList = this.userList.filter(user => {

      const userName = user.userName ? user.userName.toLowerCase() : '';

      const matchesSearchText = userName.includes(lowerCaseSearchText);

      const messageDate = moment(user.lastMessageReceivedAt);
      let matchesDate = false;

      switch (this.filterPeriod) {
        case FiltersEnum.TODAY:
          matchesDate = messageDate.isSame(now, 'day');
          break;
        case FiltersEnum.LASTONEWEEK:
          matchesDate = messageDate.isAfter(moment().subtract(1, 'weeks'));
          break;
        case FiltersEnum.LASTONEMONTH:
          matchesDate = messageDate.isAfter(moment().subtract(1, 'months'));
          break;
        case FiltersEnum.LASTSIXMONTHS:
          matchesDate = messageDate.isAfter(moment().subtract(6, 'months'));
          break;
        case FiltersEnum.LASTONEYEAR:
          matchesDate = messageDate.isAfter(moment().subtract(1, 'years'));
          break;
        default:
          matchesDate = true;
          break;
      }

      return matchesSearchText && matchesDate;
    });
  }

  userSelect(userData:any){
    this.selectedUser = userData;
    this.patientID = userData?.identifier;
    this.userSelected.emit(userData); 
    this.markAsReadFlag = true; 
    this.markAsRead();
  }
  isSelected(user: any): boolean {
    return this.selectedUser && this.selectedUser?.identifier === user?.identifier;
  }
  getRelativeTime(time: string): string {
    if (!time) return ''; 
    const now = moment();
    const messageTime = moment(time);
    const diffInDays = now.diff(messageTime, 'days');
  
    if (diffInDays < 2) {
      return messageTime.fromNow();
    } else {
      return messageTime.format('MM/DD/YY');
    }
  }
  
  markAsRead(){
    if(this.markAsReadFlag && this.npi && this.patientID){
        const usersPayLoad: MarkAsRead = {
                Sender: {
                    Identifier: this.patientID,
                    IdentifierType: IdentifierTypeEnum.PATIENT
                },
                Recipient: {
                    Identifier: this.npi,
                    IdentifierType: IdentifierTypeEnum.NPI
                },
                pharmacyNPI: this.npi
        };
        this._msgServ.postMessageMarkAsRead(usersPayLoad)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((res: any['StatusCode']) => {
            if (res) { 
              if(res?.StatusCode === 0){
                  this.markAsReadFlag = false;
                  this._unreadCountService.getUnreadCounts();
                  this.loadUsers();
              }
            }
        });
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    if (this.messageSubscription) {
      this.messageSubscription.unsubscribe();
    }
  }
}
