import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-prime-esc",
  templateUrl: "./prime-esc.component.html"
})
export class PrimeEscComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
