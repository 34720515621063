import { ChangeDetectorRef, Component, ElementRef, Input, NgZone, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import * as moment from 'moment';
import { MsgConfig } from 'src/app/app.messages';
import { FiltersEnum, IdentifierTypeEnum } from 'src/app/models/messaging.enum';
import { CommonService } from 'src/app/services';
import { UserService } from 'src/app/services/user.service';
import { WebPubSubService } from 'src/app/services/WebPubSubService';
import { AlertService } from "src/app/services";
@Component({
    selector: 'app-spc-cloud',
    styleUrls: ['./spc-cloud.component.css'],
    templateUrl: './spc-cloud.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class spcCloudComponent implements OnInit{
    @ViewChild('menuIcon', { static: true }) menuIcon!: ElementRef;   
    @ViewChild('popupMenu', { static: true }) popupMenu!: ElementRef;
    messages: any[] = [];
    enterMessage: any[] = [];
    userName: any;
    patientID: any;
    gender:any;
    activeInactive:boolean = false;
    @Input() userData: any;
    selectedPeriod: string = FiltersEnum.ALLHISTORY;
    searchText: string = '';
    searchHistoryText: string = '';
    enteredMessage: string = '';
    placeholderMessage: string = MsgConfig.EnterMsg;
    npi:any;
    pharmacistName:any;
    resetMessages: boolean = false;
    isError: boolean = false;
    constructor(private cd: ChangeDetectorRef,
        private _webPubSubService: WebPubSubService,
        private _userServ: UserService,
        private ngZone: NgZone,
        private _commonServ:CommonService,
        private _alertService: AlertService
    ) { }

    ngOnInit() {
        this.placeholderMessage = MsgConfig.EnterMsg;
        this.pharmacistName = this._commonServ.DecryptData(localStorage.getItem("PharmacistName"));
        this.npi = this._userServ.getToken("UniquePharmacyId");
        if(this.userData && this.userData?.identifier){
            this.onUserSelected(this.userData);
        }
    }

    onUserSelected(userData: any) {
        this.patientID = userData?.identifier;
        this.userName = userData?.userName;
        this.gender = userData?.gender;
        this.resetMessages = true;
        this.activeInactive = (userData?.identifier && userData) ? true : false;
    }
    onEnter(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            event.preventDefault();
            this.sendMessage();
        }
    }
    sendMessage(){
        if (!navigator.onLine) {
            this._alertService.error(MsgConfig.NO_Internet);
            this.enteredMessage = "";
            return;
        }
        if (!this.enteredMessage.trim()) { 
            this.isError = true;
            this.placeholderMessage = MsgConfig.PleaseEnterMsg;
            return;
        }
        this.resetMessages = false;
        this.isError = false;
        this.placeholderMessage = MsgConfig.EnterMsg;
        if (this.enteredMessage.trim() && this.patientID) {
            const enterMessage = {
                date: moment().toISOString(),
                message: this.enteredMessage,
                sender: {
                    identifier: this.npi,
                    identifierType: IdentifierTypeEnum.NPI,
                    userName: this.pharmacistName
                },
                lastMessageReceivedAt: new Date().toISOString(),
                IsRead: false
            };
            this._webPubSubService.sendMessageToPatient(this.npi, this.patientID, this.enteredMessage, this.pharmacistName, this.userName)
            .then(() => {
                this.enterMessage = [...this.enterMessage, enterMessage];
                this.cd.detectChanges();
                this.enteredMessage = '';
                this.ngZone.runOutsideAngular(() => {
                    requestAnimationFrame(() => {
                        this.scrollToBottom();
                    });
                });
            })
            .catch((error) => {
                console.error('Error sending message:', error);
            });
        } else {
            console.error('Message or PatientID is missing!');
        }
    }
    onFocus(): void { 
        if (this.isError) {
            this.isError = false;
            this.placeholderMessage = MsgConfig.EnterMsg;
        }
    }
    dateList = [
        { Name: FiltersEnum.ALLHISTORY, Id: FiltersEnum.ALLHISTORY },
        { Name: FiltersEnum.TODAY, Id: FiltersEnum.TODAY },
        { Name: FiltersEnum.LASTONEWEEK, Id: FiltersEnum.LASTONEWEEK },
        { Name: FiltersEnum.LASTONEMONTH, Id: FiltersEnum.LASTONEMONTH },
        { Name: FiltersEnum.LASTSIXMONTHS, Id: FiltersEnum.LASTSIXMONTHS },
        { Name: FiltersEnum.LASTONEYEAR, Id: FiltersEnum.LASTONEYEAR }
    ];
    togglePopup() { 
        const popupMenu = this.popupMenu.nativeElement;
        const computedStyles = window.getComputedStyle(popupMenu);
        const display = computedStyles.getPropertyValue('display'); 

        if (display === 'none') {
            const icon = this.menuIcon?.nativeElement;
            const position = icon.getBoundingClientRect();
            const top = position.bottom + window.scrollY - 10;
            let left = position.left - 90;
    
            const screenWidth = window.innerWidth;
            if (screenWidth <= 720) {
            left -= 14;
            }
            popupMenu.style.display = 'block';
            popupMenu.style.top = `${top}px`;
            popupMenu.style.left = `${left}px`;
        } else { 
            popupMenu.style.display = 'none';
        }
    }

    ngAfterViewInit() {
        document.addEventListener('click', (event) => this.onClick(event));
    }
    
    onClick(event: MouseEvent) {
        if (this.popupMenu?.nativeElement && this.menuIcon?.nativeElement) {
            const popupMenu = this.popupMenu.nativeElement;
            const menuIcon = this.menuIcon.nativeElement; 
            if (!menuIcon.contains(event.target) && !popupMenu.contains(event.target)) {
                popupMenu.style.display = 'none';
            }
        }
    }
    showSection(period:any){
        this.selectedPeriod = period;
        this.cd.detectChanges(); 
        this.closeFilter();
    }

    closeFilter() {
        if(this.popupMenu?.nativeElement){
            const popupMenu = this.popupMenu.nativeElement;
            popupMenu.style.display = 'none';
        }
    }

    onSearch(event: any): void {
        this.searchText = event.target.value;
    }

    onHistorySearch(event: any): void {
        this.searchHistoryText = event?.target?.value?.trim();
    }
    scrollToBottom() {
        const chatHistory = document.querySelector('.chat-history');
        if (chatHistory) {
            chatHistory.scrollTop = chatHistory.scrollHeight;
        }
    }
}
