<ng-template #PatSignTemp let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModal()">
        <h4 header> Patient Signature Record </h4>
        <span body>
            <div class="row">
                <div class="col-md-12">
                    <div class="exprx--common-block erx--body insurance--body">
                        <div class="eprx--block__content">
                            <div class="row">
                                <div class="col-md-12">
                                    <h5>Patient Information</h5>
                                    <hr>
                                </div>
                                <div class="col-md-6">
                                    <label>Patient</label>
                                    <div class="label--data">{{patientInfo?.fullname? patientInfo?.fullname.replace('_',', '):''}}
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label>Address</label>
                                    <div class="label--data">
                                        <span *ngIf="patientInfo?.address1 && patientInfo?.address1 !== '###'">
                                            {{patientInfo?.address1}},
                                        </span>
                                        <span *ngIf="patientInfo?.address2 && patientInfo?.address2 !== '###'">
                                            {{patientInfo?.address2}},
                                        </span>
                                        <span *ngIf="patientInfo?.city && patientInfo?.city !== '###'">
                                            {{patientInfo?.city}},
                                        </span>
                                        <span *ngIf="patientInfo?.state && patientInfo?.city !== '###'">
                                            {{patientInfo?.state}},
                                        </span>
                                        <span *ngIf="patientInfo?.zipcode && patientInfo?.zipcode !== '###'">
                                            {{patientInfo?.zipcode + "."}}
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label>DOB</label>
                                    <div class="label--data">{{ patientInfo?.dob | date: "MM/dd/yyyy" }}
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label>Gender</label>
                                    <div class="label--data">{{ patientInfo?.gender ? patientInfo?.gender  : "--" }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <label>Counseling</label>
                    <div class="label--data">{{signatureData?.Counsellingstatus ? 'Y' : 'N'}}
                    </div>
                </div>
                <div class="col-md-6">
                    <label>POS User</label>
                    <div class="label--data">{{rphData ? rphData : "--"}}
                    </div>
                </div>
                <div class="col-md-12">
                    <label>Date/Time</label>
                    <div class="label--data">{{signatureData?.PickedDate ? (signatureData?.PickedDate | localTime | date : 'MM/dd/yyyy hh:mm:ss a') : ''}}
                    </div>
                </div>
                <div class="col-md-6">
                    <label>Information</label>
                    <div class="label--data">{{  information? information : null }}
                    </div>
                </div>
                <div class="col-md-6">
                    <label>Signature</label>&nbsp;
                    <img [src]="'data:image/png;base64,'+signatureData?.BinarySign"  class = "w-50 border_1px_solid_black">

                </div>
                <div class="col-md-8" *ngIf="refillId">
                    <input type="checkbox" class="form-control">
                    <input class="form-check-input" type="checkbox" [id]="51" [(ngModel)]="isCurrentRxOnlyShown" name="isCurrentRxOnlyShown"  (ngModelChange)="showCurrentRxOnly()"/>
                                <label class="form-check-label" [for]="5" id="'Print this Rx Only'">
                                    Print this Rx only
                                </label>
                </div>
            </div>
        </span>
        <button footer ngbAutofocus class="hotkey_primary" (click)="print()" appShortcutKey InputKey="p">
            <b>P</b> Print
        </button>
        <button footer ngbAutofocus class="hotkey_primary" (click)="closeModal()" appShortcutKey InputKey="c">
            <b>C</b> Close
        </button>
    </app-custom-modal>
</ng-template>
