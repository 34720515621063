import { NgModule } from "@angular/core";
import { CommonModule, CurrencyPipe, DecimalPipe } from "@angular/common";
import { WjInputModule } from "@grapecity/wijmo.angular2.input";
import { WjGridModule } from "@grapecity/wijmo.angular2.grid";
import { WjGridFilterModule } from "@grapecity/wijmo.angular2.grid.filter";
import { WjGridGrouppanelModule } from "@grapecity/wijmo.angular2.grid.grouppanel";

import {
    FillRecsComponent,
    MARComponent,
    BillToComponent,
    DmeComponent,
    EligibilityCheckComponent,
    FileComponent,
    NotesComponent,
    OtherValuesComponent,
    QuickVerificationComponent,
    ReverseClaimComponent,
    TRPatOnlyComponent,
    TrRxInOnlyComponent,
    TransMessageComponent,
    WcomComponent,
    PayRecordsComponent,
    TrackRxComponent
} from "./index";
import { SharedModule } from "../shared/shared.module";
import { TransmissionModule } from "../transmission/transmission.module";
import { PickuprxComponent } from "./pickuprx/pickuprx.component";
import { PrimeEscModule } from "../prime-esc/prime-esc.module";
import { ErxModule } from "../erx/erx.module";

@NgModule({
    imports: [
        CommonModule,
        WjGridModule,
        WjInputModule,
        WjGridFilterModule,
        SharedModule,
        WjGridGrouppanelModule,
        TransmissionModule,
        PrimeEscModule,
        ErxModule
    ],
    declarations: [
        BillToComponent,
        DmeComponent,
        EligibilityCheckComponent,
        FileComponent,
        FillRecsComponent,
        MARComponent,
        NotesComponent,
        OtherValuesComponent,
        PayRecordsComponent,
        QuickVerificationComponent,
        ReverseClaimComponent,
        TRPatOnlyComponent,
        TrRxInOnlyComponent,
        TrackRxComponent,
        TransMessageComponent,
        WcomComponent,
        PickuprxComponent
    ],
    exports: [
        BillToComponent,
        DmeComponent,
        EligibilityCheckComponent,
        FileComponent,
        FillRecsComponent,
        MARComponent,
        NotesComponent,
        OtherValuesComponent,
        PayRecordsComponent,
        QuickVerificationComponent,
        ReverseClaimComponent,
        TRPatOnlyComponent,
        TrRxInOnlyComponent,
        TrackRxComponent,
        TransMessageComponent,
        WcomComponent,
        PickuprxComponent
    ],
    providers: [
        BillToComponent,
        DmeComponent,
        EligibilityCheckComponent,
        FileComponent,
        FillRecsComponent,
        MARComponent,
        NotesComponent,
        OtherValuesComponent,
        PayRecordsComponent,
        QuickVerificationComponent,
        ReverseClaimComponent,
        TRPatOnlyComponent,
        TrRxInOnlyComponent,
        TrackRxComponent,
        TransMessageComponent,
        WcomComponent,
        CurrencyPipe,
        DecimalPipe
    ],
    entryComponents: [
        OtherValuesComponent 
    ]
})
export class RxActionsModule {}
