<div class="exprx--common-block">
    <div class="eprx--block__header">
        <div class="eprx--header__heading">Primary Insurance Information</div>
    </div>
    <div class="eprx--block__content">
        <div [ngClass]="showErrorMsg||InsuranceEmpty ? 'row mb-2 margin_right_0_5rem' : 'row margin_right_0_5rem'">
            <div class="col form-group custom--input"
            [ngClass]="{
                'has-error':
                    Insurances?.get('InsurerId')?.invalid &&
                    (Insurances?.get('InsurerId')?.dirty || Insurances?.get('InsurerId')?.touched) &&
                    Insurances?.get('InsurerId')?.errors
            }">
                <label class="control-label  pat-insuType">
                    <span> Ins.Type <span class="help--text__danger">*</span></span>
                    <span class="curency-format">
                        <input type="text" #searchBoxInsType [value]="inputValue" name="searchBoxInsType" class="right-part form-control search--input__box"
                      id="insutype"  placeholder="To Start Search..." appChangeDebounce [ngbTypeahead]="search" [resultTemplate]="rt" [inputFormatter]="formatter"
                        KeyUpDown (selectItem)="selectedInsType($event.item, 'select')" (change)="onChangeInsurance($event.target.value)" />
                        <div class="left-part">
                            <input type="text" tabindex="-1" #searchBoxInsName [value]="insuName" name="searchBoxInsName" class="form-control search--input__box"
                            readonly/>
                        </div>
                    </span>


                </label>
                <a *ngIf="hasAddInsuFilePrivs" class="add__icon" (click)="routeToAddInsurance()" appShortcutKey
                    [AltKey]="'i'">
                    <i class="far fa-plus-circle fa-lg" title="Add Insurance(Alt+I)"></i>
                </a>
                <a *ngIf="!hasAddInsuFilePrivs" class="add__icon" (click)="openOveride(null, null, 'Add','POST_Insurance')" appShortcutKey
                    [AltKey]="'i'">
                    <i class="far fa-plus-circle fa-lg" title="user has no privilege"></i>
                </a>
                <ng-template #rt let-r="result" let-t="term">
                    <!-- <a (click)="selectedInsType(r)"> -->
                    <div class="card patient-card secondary-patient-card" *ngIf="r">
                        <div class="row">
                            <div class="col">
                                <div class="row">
                                    <div class="card__subTit--head">
                                        Insurance Code:
                                    </div>
                                    <div class="card__subTit--title">
                                        {{ r?.PlanCode }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="row">
                                    <div class="card__subTit--head">
                                        Insurance Name :
                                    </div>
                                    <div class="card__subTit--title">
                                        {{ r?.InsuranceName }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="row">
                                    <div class="card__subTit--head">
                                        Bin Num :
                                    </div>
                                    <div class="card__subTit--title">
                                        {{ r?.BinNum }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="row">
                                    <div class="card__subTit--head">
                                        PCN Num :
                                    </div>
                                    <div class="card__subTit--title">
                                        {{ r?.ProcessorNum }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- </a> -->
                </ng-template>
                <div
                    *ngIf="Insurances?.get('InsurerId')?.invalid && (Insurances?.get('InsurerId')?.dirty || Insurances?.get('InsurerId')?.touched)"
                    class="help-block line_height_1_6">
                    <div *ngIf="Insurances?.get('InsurerId')?.errors">Required</div>
                </div>
                <div class="help--text__danger" *ngIf="InsuranceEmpty && !showErrorMsg "><span>please select Ins.Type</span></div>
                <div class="help--text__danger" *ngIf="showErrorMsg && !InsuranceEmpty"><span>please select a valid Insurance</span></div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <eprx-input [RxSelectId]="'primInsID'" [LabelText]="'Policy# / Insurance ID'" [PlaceHolder]="'Policy# / Insurance ID'" [ControlName]="'PolicyNum'"
                    [FormGroupName]="InsuCards" [InputErrors]="InsuCards?.get('PolicyNum')?.errors" [ErrorDefs]="{ required: 'Required', pattern: 'Invalid!' }"
                    [ValidPattern]="regex?.AlphaNumeric" [MaxLength]="80" [IsRequired]="isPolcRequired" (keydown.tab)="checkForInsuranceDuplication()"  (keydown.enter)="checkForInsuranceDuplication()"
                    [FormGroupInvalid]="formGroupInvalid" [MarkAsTouched]="InsuCards?.get('PolicyNum')?.touched" >
                </eprx-input>
            </div>
            <div class="col">
                <eprx-select [RxSelectId]="'primInsGrpNo'" [LabelText]="'Group#'" [PlaceHolder]="'Group#'"
                [ControlName]="'InsuGrpId'" [FormGroupName]="Insurances" [BindValue]="'Id'"
                [secondCntrlN]="'Name'" [scndFormGroupN]="patientInfoFG?.controls['InsuGroups']" [bindScnCntrlV]="'Name'"
                    [InputErrors]="Insurances?.get('InsurerId')?.errors" [List]="(patGroups$ | async)" [BindLabel]="'Name'"  [LabelForId]="'Name'"
                    (TriggerSelectValue)="isTypedValue($event)" (TriggerFocusOutOnTabbing)="isTypedValue($event); focusOutFromGroup()"
                    (TriggerOnClear)="isTypedValue($event)" [AddItem]="true" [SelectOnTab]="true" [InputValue]="patientInfoFG?.value['InsuGroups']['Name']" [ShowDefaultValue]="true"
                    [addItemMaxLength]="35" (TriggerOnBlur)="selectGroupOnBlur($event['target'])" (keydown)="focusOutOnAlt($event)">
                </eprx-select>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <eprx-select [RxSelectId]="'primInsRela'" [LabelText]="'Relationship'" [PlaceHolder]="'Relationship'" [ControlName]="'RelCatId'" [secondCntrlN]="'Relation'" [FormGroupName]="Insurances"
                    [InputErrors]="Insurances?.get('RelCatId')?.errors" [List]="systemData?.RelationCat ? (systemData?.RelationCat | filterRelation:'Patient') : []" [BindLabel]="'Name'" [BindLabel2]="'Remarks'" [ShowLabel2]="true" [BindValue]="'Id'"
                    [bindScnCntrlV]="'Name'" (TriggerSelectValue)="patchRelationName()" [Show2Values]="true"
                >
                </eprx-select>
            </div>
            <div class="col">
                <eprx-input [RxSelectId]="'primInsPerCode'" [LabelText]="'Person Code'" [PlaceHolder]="'Person Code'" [ControlName]="'PerNum'" [FormGroupName]="Insurances"
                    [ErrorDefs]="{ pattern: 'Invalid!' }" [ValidPattern]="regex?.AlphaNumeric" [InputErrors]="Insurances?.get('PerNum')?.errors" [MaxLength]="3">
                </eprx-input>
            </div>
        </div>
        <div *ngIf="Insurances?.get('RelCatId')?.value !==1 &&  Insurances?.get('Relation')?.value !=='H'" class="row">
            <div class="col">
                    <eprx-input [RxSelectId]="'primInsCardFN'" [LabelText]="'Card Holder\'s First Name'" [PlaceHolder]="'Card Holder\'s First Name'" [ControlName]="'FirstName'"
                        [FormGroupName]="InsuCards" [ErrorDefs]="{ pattern: 'Invalid!' }" [ValidPattern]="regex?.AlphaNumeric" [MaxLength]="35"
                        [InputType]="'ALPHANUMERIC'" >
                    </eprx-input>
                </div>
            <div class="col">
                <eprx-input [RxSelectId]="'primInsCardLN'" [LabelText]="'Card Holder\'s Last Name'" [PlaceHolder]="'Card Holders\'s Last Name'" [ControlName]="'LastName'"
                    [FormGroupName]="InsuCards" [ErrorDefs]="{ pattern: 'Invalid!' }" [ValidPattern]="regex?.AlphaNumeric" [MaxLength]="35">
                </eprx-input>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <eprx-date-picker [RxSelectId]="'primInsCardExp'" [LabelText]="'Card Expires'" [PlaceHolder]="'Card Expires'" [ControlName]="'ExpiryDt'"
                    [FormGroupName]="InsuCards" [MinDate]="minDate" [IsRequired]="isPolcRequired" [ErrorDefs]="{ required: 'Required',minDate:'Date should be greater than today' }"
                    [MarkAsTouched]="InsuCards?.get('ExpiryDt')?.touched" [InputErrors]="InsuCards?.get('ExpiryDt')?.errors">
                </eprx-date-picker>
            </div>
            <div class="col" [formGroup]="Insurances">
                <eprx-radio [LabelText]="'Patient Assignment'" [ControlName]="'IsPatAssigned'" [FormGroupName]="Insurances"
                    [List]="['Yes', 'No']" [ValueList]="[true, false]" [IDForList]="['IsPatAssignedp2', 'IsPatAssignedp3']"
                    [LabelForId]="" [Name]="'IsPatAssigned'">
                </eprx-radio>
            </div>
        </div>
        <!-- <div class="row">
            <div class="col">
                <eprx-select [LabelText]="'Copay Type'" [PlaceHolder]="'Copay Type'" [ControlName]="'CoPayCatId'" [FormGroupName]="Insurances"
                    [List]="systemData?.CoPayCat" [BindLabel]="'Remarks'" [BindValue]="'Id'" [LabelForId]="'Name'" [InputErrors]="CoPayCatId?.errors">
                </eprx-select>
            </div>
            <div class="col padding-0">
                <eprx-input [LabelText]="'Copay Brand'" [PlaceHolder]="'Copay Brand'" [ControlName]="'CPBrandVal'" [FormGroupName]="Insurances"
                    [MaxLength]="7">
                </eprx-input>
            </div>
            <div class="col">
                check this control
                <eprx-input [LabelText]="'Copay Generic'" [PlaceHolder]="'Copay Generic'" [ControlName]="'CPGenericVal'" [FormGroupName]="Insurances"
                    [MaxLength]="7">
                </eprx-input>
            </div>
        </div> -->
    </div>
</div>

<div class="exprx--common-block" *ngIf="patientID">
    <div class="eprx--block__header">
        <div class="eprx--header__heading"> Other Insurances
                <ul class="pull-right eprx--header__icons" *ngIf="patientID">
                        <li>
                            <a (click)="routeToAdditionalInsurance()" href="javascript:;">
                                <i
                                    class="far fa-user-edit fa-lg"
                                    title="Additional Insurances"
                                ></i>
                            </a>
                        </li>
                    </ul>
        </div>
    </div>
    <div class="eprx--block__content">
        <div class="row">
            <div class="col-md-12 overflow_y_auto max-height_9rem">
                <table class="table table-striped table-bordered table-sm">
                    <thead>
                        <tr>
                            <th scope="col">
                            </th>
                            <th scope="col">
                                INS. CODE
                            </th>
                            <th scope="col">
                                INS. NAME
                            </th>
                            <th scope="col">
                                PRIORITY
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let insu of patientInsurances; let i=index;">
                            <td>
                                <i class="far fa-edit fa-sm actions" title="Edit" (click)="routeToAdditionalInsurance(insu)"></i>
                            </td>
                            <td>
                                {{insu.InsurerCode | uppercase}}
                            </td>
                            <td>
                                {{insu.InsurerName | uppercase}}
                            </td>
                            <td>
                                {{insu.InsurancePriority}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
<app-override-log *ngIf="openOverride" [OverrideEndpoint]="overrideEndpoint" [PrivMask]="privType" [CatId]="categoryId" (OverrideLoginDet)="OverrideRes($event)"></app-override-log>


<ng-template #UNIQUEINSURNUMBER let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Warning!!</h4>
        <button type="button" [tabindex] class="close" aria-label="Close" (click)="resetPolicyNumber(); d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>Already Policy No / Insurance ID exists. </p>
    </div>
    <div class="modal-footer">
        <button autofocus type="button" class="hotkey_primary"
        (click)="resetPolicyNumber(); d('Cross click')"
        (keydown.enter)="resetPolicyNumber(); d('Cross click')" appShortcutKey InputKey="o"><b>O</b> Ok</button>
    </div>
</ng-template>
