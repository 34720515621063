<div id="pdfDownload" class="exprx--common-block erx__Doc--section borderRadius_forBoxes overflow-wrap"*ngIf="IsFromRoute !== 'rph' && IsFromRoute !== 'dpv'">
    <div class="eprx--block__content erx__doc--body borderRadius_forBoxes">
        <div class="row" [ngClass]="index==1 ? 'prescrib--focus ml-0 mr-0':''">
        <div class="col-md-4 electron_rx pr-1 pl-3">
                <h5 class="pt-4 erx--label--primary">{{detailsInfo?.IsNormalRx ? (detailsInfo?.RxOriginName  + ' Rx') : 'Electronic Rx'}}</h5>
                <div  *ngIf="!IsFromQuickVerification && !IsNotesDisabled" [ngClass]="!(NotesList?.Note?.length) ? 'text-center' : ''">
                    <img src="assets/img/final one1.png" triggers="mouseover:mouseleave"  tooltipClass="tooltip-title"
                    [ngbTooltip]="'No Rx Annotations'"  placement="bottom" style="height: 5rem;" (click)="goToNotesPage()"   
                    *ngIf="!(NotesList?.Note?.length)" container="body">

                    <div class="post-it-note ellpise cursor_pointer" triggers="mouseover:mouseleave"  tooltipClass="tooltip-title"
                    [ngbTooltip]="notesToolTip"  placement="bottom" (click)="goToNotesPage()"  *ngIf="NotesList?.Note?.length" > 
                        <b>{{getSubCategory(NotesList?.Note[0]?.NoteCatId)}}:</b>&nbsp;<span [innerHTML]="NotesList?.Note[0]?.xNote"></span> 
                      </div>
                      <ng-template #notesToolTip>
                        <div *ngIf="NotesList?.Note[0]?.xNote">
                            <span [innerHTML]="NotesList?.Note[0]?.xNote"></span>
                        </div>
                    </ng-template>
                </div>
            </div>
            <div class="col-md-8 ">
                <div class="label--data-docs text-center">
                    <span title="Prescriber History" class="cursor_pointer"  [ngbTooltip]=prescriberToolTipCont placement="left" container="body" tooltipClass="erx-patient-tooltip electron_rx_tooltip" container="body" triggers="click"  *ngIf="prescriberAuditData?.length && (rxInfo && rxInfo['rxType'] === 'er')"><i class="far fa-history actions text-dark font-weight-700" ></i></span>
                    
                    <ng-template #prescriberToolTipCont>
                        <div class="scroll_electronic">
                            <div class="row text-left " *ngFor="let x of prescriberAuditData;trackBy: trackByFn">
                                <div class="col-3 pr-0">
                                    <label> Old Value</label>
                                    <div>{{x?.OldValue | uppercase}}</div>
                                </div>
                                <div class="col-3 pr-0">
                                    <label> New Value</label>
                                    <div>{{x?.NewValue | uppercase}}</div>
                                </div>
                                <div class="col-4 pr-0">
                                    <label>Date & Time </label>
                                    <div>{{x?.DateTime | date : 'MM/dd/yyyy hh:mm a'}}</div>
                                </div>
                                <div class="col-2 pr-0">
                                    <label>By </label>
                                    <div>{{x?.By | uppercase}}</div>
                                </div>
                            </div>
                        </div>
                    </ng-template>


                    <span  triggers="mouseover:mouseleave"  tooltipClass="search-tooltip-patient_electronic"
                    [ngbTooltip]="instructPrescToolTip"  placement="bottom">
                 
                        {{detailsInfo?.PrescName ? ((detailsInfo?.PrescName.replace(" ", ", ")) | uppercase) : ''}}</span>
                    <ng-template #instructPrescToolTip>
                        <div>
                                <b>{{detailsInfo?.PrescName ? ((detailsInfo?.PrescName.replace(" ", ", ")) | uppercase) : ''}} </b>
                        </div>
                    </ng-template>

                  <span triggers="mouseover:mouseleave" tooltipClass="search-tooltip-patient_electronic"
                  [ngbTooltip]="spiToolTip"  placement="bottom">
                    {{detailsInfo?.PrescSpi ? ', '+detailsInfo?.PrescSpi : ''}}
                  </span>
                  <ng-template #spiToolTip>
                    <div><b>{{detailsInfo?.PrescSpi ? detailsInfo?.PrescSpi : ''}} </b></div>
                </ng-template>

                 <span [title]="detailsInfo?.ClinicName ? '-'+detailsInfo?.ClinicName : ''">
                    {{detailsInfo?.ClinicName ? '-'+detailsInfo?.ClinicName : ''}}
                 </span>

                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center section_height--max">
                     {{detailsInfo?.PrescAddress | uppercase}}
                </div>
                <label>NPI# : <span class="label--data">{{detailsInfo?.PrescNpi ? detailsInfo?.PrescNpi :
                    '--'}}</span>
                </label><br>
                <label>Phone# : <span class="label--data">{{ detailsInfo?.PrescPhone ? (detailsInfo?.PrescPhone |
                        mask:'(000) 000-0000') : '--'}}</span>
                </label><br>
                <label>DEA# : <span class="label--data">{{detailsInfo?.PrescDea ? _formatsUtil.getDeaFormat(detailsInfo?.PrescDea) :
                    '--'}}</span></label>
                </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="exprx--common-block " [ngClass]="index==0 ? 'eprx-bg-focus':''">
                    <!-- <div class="eprx--block__header">
                            <div class="eprx--header__heading">
                                Patient
                            </div>
                        </div> -->
                    <hr class="height_1px">
                    <div class="row" *ngIf="!detailsInfo?.IsNormalRx">
                        <span class="col-9 mt-2 pr-0">
                            <div class="row">
                                <div class="col-11 pr-0 text--ellipsis">
                                    <span class="erx--label--primary supv" title="Agent">Agent: </span>
                                    <span title='{{ detailsInfo?.Prescriberagent ? (detailsInfo?.Prescriberagent?.FirstName | uppercase) : "" }}
                                    {{ detailsInfo?.Prescriberagent ? (detailsInfo?.Prescriberagent?.LastName | uppercase) : "" }}
                                    {{ detailsInfo?.Prescriberagent ? (detailsInfo?.Prescriberagent?.MiddleName | uppercase) : "" }}'>
                                        {{ detailsInfo?.Prescriberagent ? (detailsInfo?.Prescriberagent?.FirstName | uppercase) : "" }}
                                        {{ detailsInfo?.Prescriberagent ? (detailsInfo?.Prescriberagent?.LastName | uppercase) : "" }}
                                        {{ detailsInfo?.Prescriberagent ? (detailsInfo?.Prescriberagent?.MiddleName | uppercase) : "" }}

                                    </span>
                                    <span *ngIf="(!detailsInfo?.Prescriberagent?.FirstName &&  !detailsInfo?.Prescriberagent?.LastName &&  !detailsInfo?.Prescriberagent?.MiddleName)">
                                          --
                                    </span>
                                </div>
                                <div class="col-12 mt-2">
                                    <span class="erx--label--primary supv" title="Supervisor">Supervisor: </span>
                                    <span title="{{(detailsInfo?.SuperVisorName) ?  (detailsInfo?.SuperVisorName | uppercase) : '  --'}}">{{(detailsInfo?.SuperVisorName) ?  (detailsInfo?.SuperVisorName | uppercase) : '  --'}}

                                    </span>
                                </div>
                                <div class="col-6 mt-2">
                                    <span class="erx--label--primary supv" title="NPI#">NPI#: </span>
                                    <span title="{{(detailsInfo?.SuperVisorNpi) ?  detailsInfo?.SuperVisorNpi : ''}}">
                                        {{(detailsInfo?.SuperVisorNpi) ?  detailsInfo?.SuperVisorNpi : ' --'}}
                                    </span>
                                </div>
                                <div class="col-6 mt-2">
                                    <span class="erx--label--primary supv" title="DEA#">DEA#: </span>
                                    <span title="{{(detailsInfo?.SuperVisorDea) ?  detailsInfo?.SuperVisorDea : ''}}">
                                        {{(detailsInfo?.SuperVisorDea) ?  _formatsUtil.getDeaFormat(detailsInfo?.SuperVisorDea) : ' --'}}
                                    </span>
                                </div>                                
                            </div>
                        </span>
                        <span class="col-3 text-right"   
                        *ngIf="((this.detailsInfo  && (this.detailsInfo?.ProcessErxId || this.detailsInfo?.ErxId)) ? true:  ((rxInfo?.rxType !== 'nr') && !IsFromQuickVerification) ? true : false)">
                            <button class="hotkey_primary print-med-guide float-right mt-2" (click)="printDocument()">Print </button>
                        </span>
                    </div>
                    <div class="row mt-1">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-9 padding-0" [ngClass]="detailsInfo?.IsNormalRx ? 'col-9' : 'col-12'">
                                    <h5 class="erx--label--primary col-12 ">PATIENT : <span triggers="mouseover:mouseleave" tooltipClass="search-tooltip-patient_electronic" 
                                        [ngbTooltip]="instructToolTip" class="label-data-docs">{{detailsInfo?.PatName ? ((detailsInfo?.PatName.replace(" ", ", ")) | uppercase) : ''}}
                                    </span>
                                        <span style="cursor: pointer;" class="cursor_pointer"  [ngbTooltip]=patToolTipCont placement="left" title="Patient History"   container="body" tooltipClass="erx-patient-tooltip electron_rx_tooltip" container="body" triggers="click"   *ngIf="patientAuditData?.length && (rxInfo && rxInfo['rxType'] === 'er')"><i class="cursor_pointer far fa-history actions text-dark font-weight-700" 
                                            ></i></span>
                                            <ng-template #patToolTipCont>
                                                <div class="scroll_electronic">
                                                    <div class="row text-left " *ngFor="let x of patientAuditData;trackBy: trackByFn">
                                                        <div class="col-3 pr-0">
                                                            <label> Old Value</label>
                                                            <div>{{x?.OldValue | uppercase}}</div>
                                                        </div>
                                                        <div class="col-3 pr-0">
                                                            <label> New Value</label>
                                                            <div>{{x?.NewValue | uppercase}}</div>
                                                        </div>
                                                        <div class="col-4 pr-0" >
                                                            <label>Date & Time </label>
                                                            <div>{{x?.DateTime | date : 'MM/dd/yyyy hh:mm a'}}</div>
                                                        </div>
                                                        <div class="col-2 pr-0">
                                                            <label>By </label>
                                                            <div>{{x?.By | uppercase}}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                            <ng-template #instructToolTip>
                                                <div>
                                                        <b> {{detailsInfo?.PatName ? ((detailsInfo?.PatName.replace(" ", ", ")) | uppercase) : ''}} </b>
                                                </div>
                                            </ng-template>
                                    </h5>
                                </div>
                                <div class="col-3 text-right"   
                                    *ngIf="(((this.detailsInfo  && (this.detailsInfo?.ProcessErxId || this.detailsInfo?.ErxId)) ? true:  ((rxInfo?.rxType !== 'nr') && !IsFromQuickVerification) ? true : false) && detailsInfo?.IsNormalRx)">
                                    <button class="hotkey_primary print-med-guide float-right mb-0" (click)="printDocument()">Print </button>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="eprx--block__content borderRadius_forBoxes mt-0">
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <label>Address</label>
                                <div class="patient_Add"> {{detailsInfo?.PatAddress ? (detailsInfo?.PatAddress | uppercase) :
                                        '--' }}</div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 ">
                                <label>DOB</label>
                                <div class="label--data">{{ detailsInfo?.PatBirthDt ? (detailsInfo?.PatBirthDt | date : 'MM/dd/yyyy') : '--' }}
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12 px-0">
                                <label>Gender</label>
                                <div class="label--data">
                                    {{(!detailsInfo?.IsNormalRx) ? (detailsInfo?.PatGender ? ((detailsInfo?.PatGender.toLowerCase() === "m") ?
                                     "MALE" : (detailsInfo?.PatGender.toLowerCase() === "f") ? "FEMALE" : "NOT SPECIFIED") :
                                        '--' ) : detailsInfo?.PatGender}}</div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 px-0">
                                <label>Payer ID</label>
                                <div class="label--data">{{detailsInfo?.PayerId ? detailsInfo?.PayerId: '--' }}</div>
                            </div>                            
                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                <label>Phone#</label>
                                <div class="label--data"> {{ detailsInfo?.PatPhone ? (detailsInfo?.PatPhone | mask : "(000) 000-0000") : "--" }}
                                </div>
                            </div>                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-1">
            <div class="col-md-12">
                <div class="exprx--common-block" [ngClass]="index==2 ? 'eprx-bg-focus':''">
                    <!-- <div class="eprx--block__header">
                            <div class="eprx--header__heading">
                                Drug
                            </div>
                        </div> -->
                    <!-- <hr> -->
                    <h5 class="erx--label--primary">DRUG: <span class="label-data-docs" triggers="mouseover:mouseleave" tooltipClass="search-tooltip-patient_electronic"
                        [ngbTooltip]="instructDrugToolTip">{{detailsInfo?.DrugName ? (detailsInfo?.DrugName | uppercase) : '--'
                        }}
                    </span>
                        <span [ngbTooltip]=drugToolTipCont placement="left" container="body" tooltipClass="erx-patient-tooltip electron_rx_tooltip" container="body" triggers="click"  class="cursor_pointer" *ngIf="drugAuditData?.length && (rxInfo && rxInfo['rxType'] === 'er')"><i class="far fa-history actions text-dark font-weight-700" 
                        title="Drug History"></i></span>                        
                    </h5>
                        <ng-template #drugToolTipCont>
                            <div class="scroll_electronic">
                                <div class="row text-left " *ngFor="let x of drugAuditData;trackBy: trackByFn">
                                    <div class="col-3 pr-0">
                                        <label> Old Value</label>
                                        <div>{{x?.OldValue | uppercase}}</div>
                                    </div>
                                    <div class="col-3 pr-0">
                                        <label> New Value</label>
                                        <div>{{x?.NewValue | uppercase}}</div>
                                    </div>
                                    <div class="col-4 pr-0">
                                        <label>Date & Time </label>
                                        <div>{{x?.DateTime | date : 'MM/dd/yyyy hh:mm a'}}</div>
                                    </div>
                                    <div class="col-2 pr-0">
                                        <label>By </label>
                                        <div>{{x?.By | uppercase}}</div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template #instructDrugToolTip>
                            <div>
                                <b>{{detailsInfo?.DrugName ? (detailsInfo?.DrugName | uppercase) : '--'}} </b>
                            </div>
                        </ng-template>
                    <div class="eprx--block__content borderRadius_forBoxes">
                        <div class="row">
                            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 pr-0">
                                <label>NDC </label>
                                <div class="label--data">{{detailsInfo?.DrugNdc ?  this._formatsUtil.getNDCFormat(detailsInfo?.DrugNdc) : '--'
                                        }}</div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 pr-0">
                                <label>Strength</label>
                                <div class="label--data"> {{detailsInfo?.DrugStrength ?
                                        detailsInfo?.DrugStrength : '--' }}
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                <label>Form</label>
                                <div class="label--data"> {{detailsInfo?.DrugForm ? detailsInfo?.DrugForm :
                                        '--' }}</div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                <label triggers="mouseover:mouseleave" tooltipClass="search-tooltip-patient_electronic"
                                [ngbTooltip]="pucToolTip">PUC</label>
                                <div class="label--data"> {{detailsInfo?.DrugPotency ? detailsInfo?.DrugPotency : '--'}}</div> 
                                <ng-template #pucToolTip>
                                    <div><b>Potency Unit Code</b></div>
                                </ng-template>                               
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="erx_doc_scroll">
            <div class="row mt-1">
                <div class="col-md-12">
                    <div class="exprx--common-block">
                        <h5 class="erx--label--primary">Rx#: <span class="label-data-docs" [ngbTooltip]="rxInfo?.rxType === 'rf' ? rxToolTip : ''" triggers="mouseover:mouseleave" tooltipClass="search-tooltip-patient_electronic" container="body">{{(detailsInfo?.RxNum && rxInfo?.rxType != 'nr') ? detailsInfo?.RxNum : ''}}</span></h5>
                        <ng-template #rxToolTip>
                            <div ><b>Refill Response</b></div>
                        </ng-template>
                        <div class="eprx--block__content borderRadius_forBoxes">
                            <div class="row">
                                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                    <label>Ord Date </label>
                                    <div class="label--data">{{( (detailsInfo?.IsNormalRx) ? detailsInfo?.rxOrdDt :  (detailsInfo?.rxOrdDt)  ? detailsInfo?.rxOrdDt  : (rxOrdDt? rxOrdDt : '--'))
                                                }}</div>
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                    <label>Auth Ref#</label>
                                    <div class="label--data"> {{detailsInfo?.auth}}
                                    </div>
                                </div>                                
                                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                    <label>Qty</label>
                                    <div class="label--data"> {{detailsInfo?.RxQty ? (detailsInfo?.RxQty | number : '1.3-3')
                                                : '--'}}</div>
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                    <label>Days</label>
                                    <div class="label--data"> {{detailsInfo?.RxDays ? detailsInfo?.RxDays:
                                                    '--' }}</div>
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                    <label>DAW</label>
                                <div class="label--data"> {{ (detailsInfo?.IsNormalRx) ? _commonUtil.getDawValue(detailsInfo?.RxDaw) : (detailsInfo?.RxDaw ? detailsInfo?.RxDaw : '--')}}</div>
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12"
                                    *ngIf="!(detailsInfo?.IsNormalRx)" title="Total Dispensings" >
                                    <label>Total Disp.</label>
                                    <div class="label--data"> {{detailsInfo?.totalDispensings}}
                                    </div>
                                </div>                                
                                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                    <label>Eff Date</label>
                                    <div class="label--data"> {{EfctDt ? EfctDt : '--' }}</div>
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                    <label>Diag</label>
                                    <div class="label--data"> {{detailsInfo?.diagnosis ? detailsInfo?.diagnosis
                                                        : '--'}}</div>
                                </div>
                                <div class="col-md-12 mt-1">
                                    <label>Sig</label>
                                    <div>  {{detailsInfo?.RxSig ? detailsInfo?.RxSig
                                                        : '--'}}</div>
                                </div>
                                <div class="col-md-12 mt-5">
                                    <label>Message</label>
                                    <div [title]="detailsInfo?.RxMsg ? detailsInfo?.RxMsg : ''"> {{detailsInfo?.RxMsg ? detailsInfo?.RxMsg
                                                        : '--'}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
       </div>
       <div class="" [ngClass]="IsTransferredIn ? 'water_mark_Transferredin' : 'water_mark_discontinued'"
        *ngIf="IsDisctFromErx || IsTransferredIn">
      {{IsTransferredIn ? 'Transferred In' : 'Discontinued from Cancel Rx' }}
      </div>
    </div>
</div>
<div id="pdfDownload" class="exprx--common-block erx__Doc--section borderRadius_forBoxes overflow-wrap" *ngIf="IsFromRoute === 'rph' || IsFromRoute === 'dpv'" [ngStyle]="{'margin-top': IsFromRoute === 'dpv' ? '0.1rem' : '-0.9rem'}">
    <div class="eprx--block__content borderRadius_forBoxes document--border">
        <div class="row" [ngClass]="index==1 ? 'prescrib--focus ml-0 mr-0':''">
            <div class="col-md-4">
                <h4 class="pt-2">{{detailsInfo?.IsNormalRx ? (detailsInfo?.RxOriginName  + ' Rx') : 'Electronic Rx'}}</h4>
                
            </div>
            <div  *ngIf="!IsFromQuickVerification "   class="col-md-5 p-0"   title="Rx Annotations" >
                <img src="assets/img/final one1.png" class="height_2_5rem" title="No Rx Annotations" (click)="goToNotesPage()"   
                *ngIf="!(NotesList?.Note?.length)">

                <div class="post-it-note ellpise cursor_pointer" title="Rx Annotations"  (click)="goToNotesPage()"  *ngIf="NotesList?.Note?.length" > 
                    <b>    {{getSubCategory(NotesList?.Note[0]?.NoteCatId)}}: </b>
                    <span [innerHTML]="NotesList?.Note[0]?.xNote"></span>
                  </div>
            </div>
            <!-- <div *ngIf="!IsFromQuickVerification && !rxInfo?.NotesAvailability"   class="col-md-5 cursor_pointer"   title="Rx Annotations">
                <i  title="No Rx Annotations" (click)="goToNotesPage()"   
                class="fa-kit fa-notes-light margin_top_5px font_size_2rem"  *ngIf="!(NotesList?.Note?.length || rxInfo?.NotesAvailability)"></i>
                <i   title="Rx Annotations" (click)="goToNotesPage()" class="fa-kit fa-notes-solid margin_top_5px font_size_2rem text-success" *ngIf="NotesList?.Note?.length" ></i>
            </div> -->
            <div class="col-3 text-right margin_top_5px pl-0"   
            *ngIf="(((this.detailsInfo  && (this.detailsInfo?.ProcessErxId || this.detailsInfo?.ErxId)) ? true:  ((rxInfo?.rxType !== 'nr') && !IsFromQuickVerification) ? true : false) && detailsInfo?.IsNormalRx)">
                <button class="hotkey_primary print-med-guide float-right" (click)="printDocument()">Print </button>
            </div>
            <div class="col-3 text-right margin_top_5px pl-0"   
            *ngIf="(((this.detailsInfo && (this.detailsInfo?.ProcessErxId || this.detailsInfo?.ErxId)) ? true:  ((rxInfo?.rxType !== 'nr') && !IsFromQuickVerification) ? true : false) && !detailsInfo?.IsNormalRx)">
                <button class="hotkey_primary print-med-guide float-right" (click)="printDocument()">Print </button>
            </div>
        </div>
        <div class="row height_10rem">
            <div class="col-md-12">
                <div class="exprx--common-block" [ngClass]="index==0 ? 'eprx-bg-focus':''">
                    <div [ngClass]="!detailsInfo?.IsNormalRx ? 'row' : ''">
                        <div [ngClass]="detailsInfo?.IsNormalRx ? 'col-12' : 'col-7 pr-0'">
                            <div class="row height_1_8rem">
                                <div [ngClass]="!detailsInfo?.IsNormalRx ? 'col-10 pr-0' : 'col-6 p-0'" >
                                    <div class="col-12 pl-1 header-Colors">
                                <div class="row">
                                    <div class="col-4 pr-0">
                                        Prescriber -
                                    </div>
                                    <div class="col-8 p-0 left_n1rem">
                                        <span class="label--data" [title]="(detailsInfo?.PrescName.replace(' ', ', '))">{{detailsInfo?.PrescName ? ((detailsInfo?.PrescName.replace(" ", ", ")) | uppercase) : ''}}</span>
                                    </div>
                                </div>
                                         <span title="Prescriber History" class="cursor_pointer"  [ngbTooltip]=prescriberToolTipCont placement="left" container="body" tooltipClass="erx-patient-tooltip electron_rx_tooltip" container="body" triggers="click"  *ngIf="prescriberAuditData?.length && (rxInfo && rxInfo['rxType'] === 'er')"><i class="far fa-history actions font-weight-700" ></i></span>
                                         <ng-template #prescriberToolTipCont>
                                             <div class="scroll_electronic">
                                                 <div class="row text-left " *ngFor="let x of prescriberAuditData;trackBy: trackByFn">
                                                     <div class="col-3 pr-0">
                                                         <label> Old Value</label>
                                                         <div>{{x?.OldValue | uppercase}}</div>
                                                     </div>
                                                     <div class="col-3 pr-0">
                                                         <label> New Value</label>
                                                         <div>{{x?.NewValue | uppercase}}</div>
                                                     </div>
                                                     <div class="col-4 pr-0">
                                                         <label>Date & Time </label>
                                                         <div>{{x?.DateTime | date : 'MM/dd/yyyy hh:mm a'}}</div>
                                                     </div>
                                                     <div class="col-2 pr-0">
                                                         <label>By </label>
                                                         <div>{{x?.By | uppercase}}</div>
                                                     </div>
                                                 </div>
                                             </div>
                                         </ng-template>
                                        </div>
                                </div>
                                <div [ngClass]="detailsInfo?.IsNormalRx ? 'col-6 p-0' : 'col-2 p-0'"><hr class="height_1px mb-0 dashed-horizontal--line" ></div>
                            </div>
                        </div>
                        <div class="col-5 height_1_8rem" *ngIf="!detailsInfo?.IsNormalRx">
                            <div class="row pl-3">
                                <div class="col-10 padding-0">
                                    <div class="col-12 pl-1 header-Colors">
                                        <div class="row">
                                            <div class="col-4 pr-0">
                                                Agent -
                                            </div>
                                            <div class="col-8 p-0 left_n1rem">
                                                <span class="label--data" title='{{ detailsInfo?.Prescriberagent ? (detailsInfo?.Prescriberagent?.FirstName | uppercase) : "--" }}{{ detailsInfo?.Prescriberagent ? " " + (detailsInfo?.Prescriberagent?.LastName | uppercase) : "" }}{{ detailsInfo?.Prescriberagent ? " " + (detailsInfo?.Prescriberagent?.MiddleName | uppercase) : "" }}'>{{ detailsInfo?.Prescriberagent ? (detailsInfo?.Prescriberagent?.FirstName | uppercase) : "--" }}
                                                {{ detailsInfo?.Prescriberagent ? (detailsInfo?.Prescriberagent?.LastName | uppercase) : "" }}
                                                {{ detailsInfo?.Prescriberagent ? (detailsInfo?.Prescriberagent?.MiddleName | uppercase) : "" }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-2 pl-0">
                                    <hr class="height_1px mb-0 dashed-horizontal--line">
                                </div>
                            </div>
                        </div>
                    </div> 
                    <div class="mt-0">
                        <div class="col-12 p-0">
                            <div class="row">
                                <div class="col-md-7" [ngClass]="!detailsInfo?.IsNormalRx ? 'col-md-7' : 'col-md-12'">
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                            <label>Address</label>
                                            <div class="label--data" [title]="detailsInfo?.PrescAddress">{{detailsInfo?.PrescAddress | uppercase}}</div>
                                        </div>
                                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                            <label>SPI</label>
                                            <div class="label--data" [title]="detailsInfo?.PrescSpi ? detailsInfo?.PrescSpi : '--'">
                                                {{detailsInfo?.PrescSpi ? detailsInfo?.PrescSpi : '--'}}</div>
                                        </div>
                                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 ">
                                            <label>Clinic</label>
                                            <div class="label--data" [title]="detailsInfo?.ClinicName ? detailsInfo?.ClinicName : '--'"> {{detailsInfo?.ClinicName ? '-'+detailsInfo?.ClinicName : '--'}}
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                            <label>NPI#</label>
                                            <div class="label--data" [title]="detailsInfo?.PrescNpi ? detailsInfo?.PrescNpi : '--'">{{detailsInfo?.PrescNpi ? detailsInfo?.PrescNpi : '--'}}</div>
                                        </div>
                                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 ">
                                            <label>Phone#</label>
                                            <div class="label--data" [title]="detailsInfo?.PrescPhone ? detailsInfo?.PrescPhone : '--'">{{ detailsInfo?.PrescPhone ? (detailsInfo?.PrescPhone | mask:'(000) 000-0000') : '--'}}
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 ">
                                            <label>DEA#</label>
                                            <div class="label--data" [title]="detailsInfo?.PrescDea ? _formatsUtil.getDeaFormat(detailsInfo?.PrescDea) : '--'"> {{detailsInfo?.PrescDea ? _formatsUtil.getDeaFormat(detailsInfo?.PrescDea) :'--'}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-5" *ngIf="!detailsInfo?.IsNormalRx">
                                    <div class="row">
                                        <div class="col-12 pr-0">
                                            <div class="row">
                                                <div class="col-6">
                                                    <label class="erx--label--primary supv" title="Supervisor">Supervisor </label>
                                                    <div class="label--data" title="{{(detailsInfo?.SuperVisorName) ?  (detailsInfo?.SuperVisorName | uppercase) : '  --'}}">{{(detailsInfo?.SuperVisorName) ?  (detailsInfo?.SuperVisorName | uppercase) : '  --'}}
                                                    </div>
                                                </div>
                                                <div class="col-6">
                                                    <label class="erx--label--primary supv" title="DEA#">DEA# </label>
                                                    <div class="label--data" title="{{(detailsInfo?.SuperVisorDea) ? _formatsUtil.getDeaFormat(detailsInfo?.SuperVisorDea) : ''}}">
                                                        {{(detailsInfo?.SuperVisorDea) ?  _formatsUtil.getDeaFormat(detailsInfo?.SuperVisorDea) : ' --'}}
                                                    </div>
                                                </div>
                                                <div class="col-6">
                                                    <label class="erx--label--primary supv" title="NPI#">NPI# </label>
                                                    <div class="label--data"title="{{(detailsInfo?.SuperVisorNpi) ?  detailsInfo?.SuperVisorNpi : ''}}">
                                                        {{(detailsInfo?.SuperVisorNpi) ?  detailsInfo?.SuperVisorNpi : ' --'}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" [ngClass]="!detailsInfo?.IsNormalRx ? (NotesList?.Note?.length ? 'height_11rem' : 'height_10rem') : 'height_10rem'">
            <div class="col-md-12">
                <div class="exprx--common-block " [ngClass]="index==0 ? 'eprx-bg-focus':''">
                    <div class="col-12" >
                        <div class="row height_1_8rem">
                                <div class="col-6 padding-0">
                                    <div class="col-12 pl-1 header-Colors">
                                <div class="row">
                                    <div class="col-3 pr-0">
                                        Patient -
                                    </div>
                                    <div class="col-8 p-0 label-right">
                                        <span class="label--data">{{detailsInfo?.PatName ? ((detailsInfo?.PatName.replace(" ", ", ")) | uppercase) : ''}}</span>
                                    </div>
                                </div>
                                <span class="cursor_pointer"  [ngbTooltip]=patToolTipCont placement="right" title="Patient History"   container="body" tooltipClass="erx-patient-tooltip electron_rx_tooltip" container="body" triggers="click"  *ngIf="patientAuditData?.length && (rxInfo && rxInfo['rxType'] === 'er')" ><i class="cursor_pointer far fa-history actions font-weight-700" 
                                ></i></span>
                                            <ng-template #patToolTipCont>
                                                <div class="scroll_electronic">
                                                    <div class="row text-left " *ngFor="let x of patientAuditData;trackBy: trackByFn">
                                                        <div class="col-3 pr-0">
                                                            <label> Old Value</label>
                                                            <div>{{x?.OldValue | uppercase}}</div>
                                                        </div>
                                                        <div class="col-3 pr-0">
                                                            <label> New Value</label>
                                                            <div>{{x?.NewValue | uppercase}}</div>
                                                        </div>
                                                        <div class="col-4 pr-0" >
                                                            <label>Date & Time </label>
                                                            <div>{{x?.DateTime | date : 'MM/dd/yyyy hh:mm a'}}</div>
                                                        </div>
                                                        <div class="col-2 pr-0">
                                                            <label>By </label>
                                                            <div>{{x?.By | uppercase}}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                    </div>
                                </div>
                                <div class="col-6 p-0"><hr class="height_1px mb-0 dashed-horizontal--line"></div>
                        </div>

                    </div>
                    <div class="mt-0">
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <label>Address</label>
                                <div class="label--data" [title]="detailsInfo?.PatAddress"> {{detailsInfo?.PatAddress ? (detailsInfo?.PatAddress | uppercase) :
                                        '--' }}</div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                <label>Gender</label>
                                <div class="label--data">
                                    {{(!detailsInfo?.IsNormalRx) ? (detailsInfo?.PatGender ? ((detailsInfo?.PatGender.toLowerCase() === "m") ?
                                     "MALE" : (detailsInfo?.PatGender.toLowerCase() === "f") ? "FEMALE" : "NOT SPECIFIED") :
                                        '--' ) : detailsInfo?.PatGender}}</div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 ">
                                <label>DOB</label>
                                <div class="label--data">{{ detailsInfo?.PatBirthDt ? (detailsInfo?.PatBirthDt | date : 'MM/dd/yyyy') : '--' }}
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                <label>Payer ID</label>
                                <div class="label--data">{{detailsInfo?.PayerId ? detailsInfo?.PayerId: '--' }}</div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                <label>Phone#</label>
                                <div class="label--data"> {{ detailsInfo?.PatPhone ? (detailsInfo?.PatPhone | mask : "(000) 000-0000") : "--" }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row height_11rem">
            <div class="col-md-12">
                <div class="exprx--common-block" [ngClass]="index==2 ? 'eprx-bg-focus':''">
                    <div class="col-12">
                        <div class="row height_1_8rem">
                            <div class="col-6 p-0">
                                <div class="col-12 pl-1 header-Colors">
                                <div class="row">
                                    <div class="col-3 pr-0">
                                        Drug - 
                                    </div>
                                    <div class="col-8 p-0 left_n1_5rem">
                                        <span class="label--data">{{detailsInfo?.DrugName ? (detailsInfo?.DrugName | uppercase) : '--'}}</span>
                                    </div>
                                </div>
                                  <span [ngbTooltip]=drugToolTipCont placement="left" container="body" tooltipClass="erx-patient-tooltip electron_rx_tooltip" container="body" triggers="click"  class="cursor_pointer" *ngIf="drugAuditData?.length && (rxInfo && rxInfo['rxType'] === 'er')"><i class="far fa-history actions font-weight-700" 
                                    title="Drug History"></i></span></div>
                                    <ng-template #drugToolTipCont>
                                        <div class="scroll_electronic">
                                            <div class="row text-left " *ngFor="let x of drugAuditData;trackBy: trackByFn">
                                                <div class="col-3 pr-0">
                                                    <label> Old Value</label>
                                                    <div>{{x?.OldValue | uppercase}}</div>
                                                </div>
                                                <div class="col-3 pr-0">
                                                    <label> New Value</label>
                                                    <div>{{x?.NewValue | uppercase}}</div>
                                                </div>
                                                <div class="col-4 pr-0">
                                                    <label>Date & Time </label>
                                                    <div>{{x?.DateTime | date : 'MM/dd/yyyy hh:mm a'}}</div>
                                                </div>
                                                <div class="col-2 pr-0">
                                                    <label>By </label>
                                                    <div>{{x?.By | uppercase}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                            </div>
                            <div class="col-6 p-0"><hr class="height_1px mb-0 dashed-horizontal--line" ></div>
                        </div>
                    </div>
                    <div>
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                <label>Drug NDC </label>
                                <div class="label--data">{{detailsInfo?.DrugNdc ?  this._formatsUtil.getNDCFormat(detailsInfo?.DrugNdc) : '--'
                                        }}</div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                <label>Potency Unit Code</label>
                                <div class="label--data"> {{detailsInfo?.DrugPotency ? detailsInfo?.DrugPotency
                                        : '--'}}</div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 ">
                                <label>Strength</label>
                                <div class="label--data"> {{detailsInfo?.DrugStrength ?
                                        detailsInfo?.DrugStrength : '--' }}
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                <label>Form</label>
                                <div class="label--data"> {{detailsInfo?.DrugForm ? detailsInfo?.DrugForm :
                                        '--' }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="erx_doc_scroll height_20rem">
            <div class="row">
                <div class="col-md-12">
                    <div class="exprx--common-block">
                        <div class="col-12">
                            <div class="row height_1_8rem">
                                <div class="col-6 p-0">
                                    <div  class="col-12 pl-1 header-Colors">Rx - {{detailsInfo?.RxNum ? detailsInfo?.RxNum : '--'}}</div>
                                </div>
                                <div class="col-6 p-0"><hr class="height_1px mb-0 dashed-horizontal--line"></div>
                            </div>
                        </div>
                        <div>
                            <div class="row">
                                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                    <label>Eff Date</label>
                                    <div class="label--data"> {{EfctDt ? EfctDt : '--' }}</div>
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                    <label>Ord Date </label>
                                    <div class="label--data">{{( (detailsInfo?.IsNormalRx) ? detailsInfo?.rxOrdDt :  (detailsInfo?.rxOrdDt)  ? detailsInfo?.rxOrdDt  : (rxOrdDt? rxOrdDt : '--'))
                                                }}</div>
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 ">
                                    <label>Auth Ref#</label>
                                    <div class="label--data"> {{detailsInfo?.auth}}
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                    <label>Qty</label>
                                    <div class="label--data"> {{detailsInfo?.RxQty ? (detailsInfo?.RxQty | number : '1.3-3')
                                                : '--'}}</div>
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                    <label>Days</label>
                                    <div class="label--data"> {{detailsInfo?.RxDays ? detailsInfo?.RxDays:
                                                    '--' }}</div>
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                    <label>DAW</label>
                                    <div class="label--data" [title]="(detailsInfo?.IsNormalRx) ? _commonUtil.getDawValue(detailsInfo?.RxDaw) : (detailsInfo?.RxDaw ? detailsInfo?.RxDaw
                                    : '--')"> {{ (detailsInfo?.IsNormalRx) ? _commonUtil.getDawValue(detailsInfo?.RxDaw) : (detailsInfo?.RxDaw ? detailsInfo?.RxDaw
                                                    : '--')}}</div>
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 "
                                    *ngIf="!(detailsInfo?.IsNormalRx)" title="Total Dispensings" >
                                    <label>Total Disp.</label>
                                    <div class="label--data"> {{detailsInfo?.totalDispensings}}
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label>Prs.ord.Ref</label>
                                    <div class="label--data" title="{{detailsInfo?.PrsOrdRef ? detailsInfo?.PrsOrdRef
                                        : '--'}}"> {{detailsInfo?.PrsOrdRef ? detailsInfo?.PrsOrdRef
                                                        : '--'}}</div>
                                </div>
                                <div class="col-md-6">
                                    <label>Diag</label>
                                    <div class="label--data"> {{detailsInfo?.diagnosis ? detailsInfo?.diagnosis
                                                        : '--'}}</div>
                                </div>
                                <div [ngClass]="!(detailsInfo?.IsNormalRx) ? 'col-md-12':'col-md-6'">
                                    <label>Sig</label>
                                    <div>  {{detailsInfo?.RxSig ? detailsInfo?.RxSig
                                                        : '--'}}</div>
                                </div>
                                <div class="col-md-12">
                                    <label>Message</label>
                                    <div  [title]="detailsInfo?.RxMsg ? detailsInfo?.RxMsg: ''"> {{detailsInfo?.RxMsg ? detailsInfo?.RxMsg
                                                        : '--'}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
       </div>
       <div  [ngClass]="IsTransferredIn ? 'water_mark_TransferredinforVrf' : 'water_mark_discontinued'"
        *ngIf="IsDisctFromErx || IsTransferredIn">
      {{ IsTransferredIn ? 'Transferred In' : 'Discontinued from Cancel Rx'}}
      </div>
    </div>
</div>