import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { PrimeEscRoutingModule } from "./prime-esc-routing.module";
import { PrimeEscComponent } from "./prime-esc.component";
import { SignByPatientComponent } from "./sign-by-patient/sign-by-patient.component";
import { CustFormElemnsModule } from "../cust-form-elemns/cust-form-elemns.module";
import { SharedModule } from "../shared/shared.module";
import { WjGridModule } from "@grapecity/wijmo.angular2.grid";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { PrimeEscService } from "src/app/services/prime-esc.service";
import { PatientSignRecordComponent } from "./patient-sign-record/patient-sign-record.component";
import { PatientSigLogComponent } from "./patient-sig-log/patient-sig-log.component";
import { SignByDateComponent } from "./sign-by-date/sign-by-date.component";
import { SignByRxComponent } from "./sign-by-rx/sign-by-rx.component";
import { RxPickUpDetailComponent } from "./rx-pick-up-detail/rx-pick-up-detail.component";
import { POSCopayCollectedGuard, RxPickupdetailsGuard, SigByPatientGuard, SignatureByDateGuard, SignatureByRxGuard } from "src/app/guards";
import { PosCopayCollectedComponent } from './pos-copay-collected/pos-copay-collected.component';

@NgModule({
    imports: [
        CommonModule,
        PrimeEscRoutingModule,
        CustFormElemnsModule,
        SharedModule,
        WjGridModule,
        NgbModule
    ],
    declarations: [PrimeEscComponent, SignByPatientComponent, PatientSignRecordComponent,
         PatientSigLogComponent, SignByDateComponent, SignByRxComponent, RxPickUpDetailComponent, PosCopayCollectedComponent],
         exports: [PatientSignRecordComponent],
    providers: [PrimeEscService, RxPickupdetailsGuard, SignatureByRxGuard, SignatureByDateGuard, SigByPatientGuard, POSCopayCollectedGuard]
})
export class PrimeEscModule { }
