
<ng-template #PatSignLog let-c="close" let-d="dismiss" class="el--popup">
        <app-custom-modal (oncrossBtnClick)="closeModal()">
            <h4 header> Print Patient Signature Log</h4>
            <span body>
                <div class="row">
                    <div class="col-md-12">
                        <div class="exprx--common-block erx--body insurance--body">
                            <div class="eprx--block__content">
                                <div class="row">
                                    <div class="col-md-12">
                                        <h5>{{patientInfo?.fullname? (patientInfo?.fullname.replace('_',', ') | uppercase):''}}</h5>
                                        <hr>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                        <eprx-date-picker [AutoFocus]="true" [RxSelectId]="'fromdate'" [LabelText]="'Fill Date From'" [PlaceHolder]="''"
                                            [ControlName]="'fromdate'" [FormGroupName]="InFiltersFrmGrp" [MinDate]="" [MaxDate]="minDate" [IsDisabled]="disableFilters">
                                        </eprx-date-picker>
                                    </div>
                                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 ">
                                        <eprx-date-picker [RxSelectId]="'todate'" [LabelText]="'Fill Date To'" [PlaceHolder]="''" [ControlName]="'todate'"
                                            [FormGroupName]="InFiltersFrmGrp" [MinDate]="" [MaxDate]="minDate" [IsDisabled]="disableFilters">
                                        </eprx-date-picker>
                                    </div>
                                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 ">
                                        <eprx-input [LabelText]="'Insurance Name'" [PlaceHolder]="'Insurance Name'" [ControlName]="'insutype'"
                                            [FormGroupName]="InFiltersFrmGrp" [IsDisabled]="disableFilters">
                                        </eprx-input>
                                    </div>
                                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 padding__top_13px">
                                        <button  title="Search / Refresh" *ngIf="!disableFilters" class="hotkey_success" appShortcutKey [AltKey]="'f'" (click)="searchFilters()">
                                            <span>F</span> Search
                                        </button>
                                        <button title="Search / Refresh" *ngIf="disableFilters" class="inactive"> <span>F</span>Search</button>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 ">
                                        <input class="form-check-input" type="checkbox" [id]="'printParti'" (change)="optedParticularRx($event?.target?.checked)">
                                        <label class="form-check-label" [for]="'printParti'">Print Only particular Rx numbers
                                        </label>
                                    </div>
                                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 ">
                                        <eprx-input [LabelText]="''" [PlaceHolder]="''" [ControlName]="'rxno'"
                                            [FormGroupName]="InFiltersFrmGrp" [IsDisabled]="!disableFilters" (TriggerOnEnterValue)="enteredRxNo($event)">
                                        </eprx-input>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="exprx--common-block erx--body insurance--body">
                            <div class="eprx--block__header">
                                <div class="eprx--header__heading">
                                    <div class="col-sm-12 padding-0">
                                        <div class="row">
                                            <div class="col-md-10">
                                                <span>Patient Transaction Signature History</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="eprx--block__content">
                                <div class="row">
                                    <div class="col-md-12">
                                        <wj-flex-grid #transHistGrid *ngIf="transactionHistory.length > 0" [headersVisibility]="'Column'" [itemsSource]="tarsnHistWJ" [isReadOnly]="true"
                                            [selectionMode]="'Row'" [columnPicker]="''">
                                            <wj-flex-grid-column [header]="actvHeader | uppercase" [binding]="actvHeader" [visible]="true"
                                                *ngFor="let actvHeader of transHistHeaders; let i = index" [width]=" '*'">
                                                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                                    <div *ngIf="actvHeader === 'TRANSDATE'">
                                                        {{item[actvHeader] | localTime | date :'MM/dd/yyyy'}}
                                                    </div>
                                                    <div *ngIf=" !(actvHeader === 'TRANSDATE') && !(actvHeader === 'SIGDATA')" title={{item[actvHeader]}}>
                                                        {{ item[actvHeader] }}
                                                    </div>
                                                    <div *ngIf="(actvHeader === 'SIGDATA') ">
                                                        {{ item[actvHeader] }}
                                                    </div>
                                                </ng-template>
                                            </wj-flex-grid-column>
                                        </wj-flex-grid>
                                        <div class="eprx--block__footer" *ngIf="transactionHistory.length == 0">
                                            <div>
                                                <span></span>
                                                <i class="fa fa-file"></i><br/>
                                                No Data Found!
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </span>
            <button footer class="hotkey_success" (click)="clearData()"  appShortcutKey [AltKey]="'l'">
                <span>L</span> Clear
            </button>
            <button footer class="hotkey_success" (click)="previewReport(false)" appShortcutKey [AltKey]="'v'">
                <span>V</span> Preview
            </button>
            <button footer class="hotkey_success" (click)="previewReport(true)" appShortcutKey [AltKey]="'p'">
                <span>P</span> Print
            </button>
            <button footer class="hotkey_success" (click)="closeModal()" appShortcutKey [AltKey]="'c'">
                <span>C</span> Close
            </button>
        </app-custom-modal>
    </ng-template>


    <ng-template #signReport let-c="close" let-d="dismiss" class="el--popup">
        <app-custom-modal (oncrossBtnClick)="c('Close')">
            <h4 header> Signature Report</h4>
            <span body>
                <div class="col-md-12">
                    <iframe [src]="pdfContent" class="text-center" toolbar="0" width="100%" height="700"
                        type="application/pdf"></iframe>
                </div>
            </span>
            <button footer class="hotkey_primary" (click)="print(sigReport);c('Close')" appShortcutKey InputKey="p"><b>P</b>
                Print </button>
            <button footer class="hotkey_primary" (click)="c('Close')" appShortcutKey InputKey="c"><b>C</b> Close </button>
        </app-custom-modal>
    </ng-template>
