import { Component, EventEmitter, OnInit, Output ,Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { CollectionView } from "@grapecity/wijmo";
import { WijimoUtil } from 'src/app/utils';
import { DrugTabUtils } from 'src/app/utils/drug-tabs.utils';
import { CommonService, EditDrugService } from 'src/app/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PaginationModel } from 'src/app/models';

@Component({
  selector: 'app-inventory-trans-audit',
  templateUrl: './inventory-trans-audit.component.html',
  styleUrls: ['./inventory-trans-audit.component.scss']
})
export class InventoryTransAuditComponent implements OnInit {
  @Output()
  CloseBucketDisplayPopup = new EventEmitter();
  InvAuditFG:FormGroup;
  today: string;
  minDate: { year: number; month: number; day: number; };
  manageInvFG: FormGroup;
  physicalInvFG: FormGroup;
  receiveInvFG: FormGroup;
  dispensedInvFG: FormGroup;
  manageInvWJ: CollectionView;
  physicalInvWJ : CollectionView;
  receiveInvWJ:CollectionView;
  dispensedInvWJ:CollectionView;
  manageInv: any;
  physicInv:any;
  receiveInv:any;
  dispensedInvArray:any = [];
  invTransAudit: any = {};
  @Input() BucketList : any;
  @Input() DrugId :any;
  @Input() DrugName :any;
  @Input() DrugNDC:any;
  dispInventoryAuditLength: any;
  manageInventoryAuditsLength: any;
  physicalInventoryAuditsLenght: any;
  recvInventoryAuditsLength: any;
  InvAuditpayload: { DrugId: any; BucketId: any; FromDate: any; ToDate: any; manageInvPageNumber: any; manageInvPageSize: any; PhysicalInvPageNumber: any; PhysicalInvPageSize: any; RecvInvPageNumber: any; RecvInvPageSize: any; DispPageNumber: any; DispPageSize: any; DrugName: any; DrugNdc: any; ManageInvPrint: any; PhysicalInvPrint: any; RecvInvPrint: any; DispInvPrint: any; };
  pageNu: number;
  Size: any;
  isDataExists = true;
  isDataExists1 = true;
  isDataExists2 = true;
  isDataExists3 = true;
  paginationModel: PaginationModel = new PaginationModel();
  unsubscribe$: Subject<void> = new Subject();
  constructor(private _fb: FormBuilder, private _editDrugSvc: EditDrugService , private _wijimoUtils: WijimoUtil, private _drugTabUtil: DrugTabUtils,private _commonServ: CommonService) {

    this.today = moment().format("MM/DD/YYYY");
    this.minDate = {
        year: moment(this.today).year(),
        month: moment(this.today).month() + 1,
        day: moment(this.today).date()
    };
    
    this.InvAuditFG = this._fb.group({
      FromDate: this.today,
      ToDate: this.today,
      BucketId: null,
      ManageInvPrint : true,
    PhysicalInvPrint:true,
    RecvInvPrint:true,
    DispInvPrint:true
   })
   this.manageInvFG = this._fb.group({
    manageInvPageNumber : this.paginationModel.pageNumber,
    manageInvPageSize : this.paginationModel.pageSize
   });
   this.physicalInvFG = this._fb.group({
    PhysicalInvPageNumber :this.paginationModel.pageNumber,
    PhysicalInvPageSize :this.paginationModel.pageSize
   })
   this.receiveInvFG= this._fb.group({
    RecvInvPageNumber :this.paginationModel.pageNumber,
    RecvInvPageSize :this.paginationModel.pageSize
   })
   this.dispensedInvFG = this._fb.group({
    DispPageNumber:this.paginationModel.pageNumber,
    DispPageSize :this.paginationModel.pageSize
   })
  }

  ngOnInit(): void {
  
  const bucketListAll = [{Name: 'ALL', Id:0 }];
  this.BucketList = bucketListAll.concat(this.BucketList);
  this.InvAuditFG.controls["BucketId"].patchValue(this.BucketList[0].Id);
  this.searchData();

  this.patchDefaultValHeaders();
  this._wijimoUtils.wjSavedData$.pipe(takeUntil(this.unsubscribe$)).subscribe(w => {
    if (w && w["WijmoKey"] && (w["WijmoKey"] === "manageInvwj" || w["WijmoKey"] === "physicalInvWj" || w["WijmoKey"] === "receiveInvWj" || w["WijmoKey"] === "dispensedInvWj")) {
        this.patchDefaultValHeaders();
        }
    });

  }
  async patchDefaultValHeaders() {
    const storedWJ = await this._wijimoUtils.getWJSavedData("manageInvwj");
    this.manageInv = this._wijimoUtils.patchDefHeader("manageInvwj", storedWJ);

    const storedWJ1 = await this._wijimoUtils.getWJSavedData("physicalInvWj");
    this.physicInv = this._wijimoUtils.patchDefHeader("physicalInvWj", storedWJ1);

    const storedWJ2 = await this._wijimoUtils.getWJSavedData("receiveInvWj");
    this.receiveInv = this._wijimoUtils.patchDefHeader("receiveInvWj", storedWJ2);

    const storedWJ3 = await this._wijimoUtils.getWJSavedData("dispensedInvWj");
    this.dispensedInvArray = this._wijimoUtils.patchDefHeader("dispensedInvWj", storedWJ3);
}
  generatemanageinvWJ(){
    if (this.invTransAudit.manageInventoryAudits.length) {
      this.isDataExists = true;
      this.manageInvWJ = new CollectionView(
        this.invTransAudit.manageInventoryAudits.map((manage: any)=>{
         let k = {};
         k["Bucket"] = manage.BucketName;
         k["Transaction Date"] = manage.TransactionDate ? moment(manage.TransactionDate).format("MM/DD/YYYY hh:mm:ss a") : '';
         k["User"] = manage.UserName;
         k["Status"] = manage.Status ? 'yes' : 'no';
         return k;
       })
     )
    } else {
      this.manageInvWJ = new CollectionView(null);
      this.isDataExists = false;
    }
    
  }
  generatePhysicalInvWJ(){
    if ( this.invTransAudit.physicalInventoryAudits.length) {
      this.isDataExists1 = true;
      this.physicalInvWJ = new CollectionView(
        this.invTransAudit.physicalInventoryAudits.map((phy: any)=>{
         let k = {};
         k["Bucket"] = phy.BucketName;
         k["Transaction Date"] = phy.TransactionDate ? moment(phy.TransactionDate).format("MM/DD/YYYY hh:mm:ss a") : '';
         k["Transaction Qty"] =  phy.DispQty ? (phy.DispQty).toFixed(3) : "0.000";
         k["User"] = phy.UserName;
         k["Is Deleted?"] = phy.IsActive  ? 'false' : 'true';
         return k;
       })
     )
    } else {
      this.isDataExists1 = false;
      this.physicalInvWJ = new CollectionView(null)
    }
  }
  generateReceiveInvWJ(){
    if (this.invTransAudit.recvInventoryAudits.length) {
      this.isDataExists2 = true;
      this.receiveInvWJ = new CollectionView(
        this.invTransAudit.recvInventoryAudits.map((rec: any)=>{
         let k = {};
         k["Bucket"] = rec.BucketName;
         k["Transaction Date"] = rec.TransactionDate ? moment(rec.TransactionDate).format("MM/DD/YYYY hh:mm:ss a") : '';
         k["User"] = rec.UserName;
         k["Transaction Qty"] = rec.DispQty ? (rec.DispQty).toFixed(3) : "0.000";
         k["Is Deleted?"] = rec.IsActive  ? 'false' : 'true';
         return k;
       })
     )
    } else {
      this.isDataExists2 = false;
      this.receiveInvWJ = new CollectionView(null);
    }
    
  }
  generateDispensedInvWJ(){
    if (this.invTransAudit.dispInventoryAudits.length) {
      this.isDataExists3 = true;
      this.dispensedInvWJ = new CollectionView(
        this.invTransAudit.dispInventoryAudits.map((dis: any)=>{
          let k = {};
          k["Bucket"] = dis.BucketName;
          k["Transaction Date"] = dis.TransactionDate ? moment(dis.TransactionDate).format("MM/DD/YYYY hh:mm:ss a") : '';
          k["Reference Number"] = dis["PrescNum"] + "-" + dis["RefillNum"] +
          (this._commonServ.checkIsPartialFill(dis["PartialFillNo"]) ? "-" + dis["PartialFillNo"] : " ");
          k["User"] = dis.UserName;
          k["Transaction Qty"] = dis.DispQty ? (dis.DispQty).toFixed(3) : "0.000" ;
          k["Type"] = dis.Type ? ((dis.Type == 'Disp') ? "DISPENSED" : "ADDED TO INVENTORY") : '';
          return k;
        })
      )
    } else {
      this.isDataExists3 = false;
      this.dispensedInvWJ = new CollectionView(null)
    }
  }
  closeModal() {
    this.CloseBucketDisplayPopup.emit(" ");
}
searchData(){
  this.InvAuditpayload = {
    DrugId : this.DrugId,
    BucketId : !this.InvAuditFG.controls['BucketId'].value ? null : this.InvAuditFG.controls['BucketId'].value,
    FromDate :this.InvAuditFG.controls['FromDate'].value,
    ToDate :this.InvAuditFG.controls['ToDate'].value,
    manageInvPageNumber : this.manageInvFG.controls['manageInvPageNumber'].value,
    manageInvPageSize :this.manageInvFG.controls['manageInvPageSize'].value,
    PhysicalInvPageNumber :this.physicalInvFG.controls['PhysicalInvPageNumber'].value,
    PhysicalInvPageSize :this.physicalInvFG.controls['PhysicalInvPageSize'].value,
    RecvInvPageNumber :this.receiveInvFG.controls['RecvInvPageNumber'].value,
    RecvInvPageSize :this.receiveInvFG.controls['RecvInvPageSize'].value,
    DispPageNumber :this.dispensedInvFG.controls['DispPageNumber'].value,
    DispPageSize :this.dispensedInvFG.controls['DispPageSize'].value,
    DrugName : this.DrugName,
    DrugNdc : this.DrugNDC,
    ManageInvPrint:this.InvAuditFG.controls['ManageInvPrint'].value,
    PhysicalInvPrint : this.InvAuditFG.controls['PhysicalInvPrint'].value,
    RecvInvPrint : this.InvAuditFG.controls['RecvInvPrint'].value,
    DispInvPrint : this.InvAuditFG.controls['DispInvPrint'].value
  }
  this._editDrugSvc.getInventoryhistory(this.InvAuditpayload).pipe(takeUntil(this.unsubscribe$)).subscribe((resp: any) => {
    if (resp) {
      this.invTransAudit = resp;
      if (this.invTransAudit) {
        this.dispInventoryAuditLength = this.invTransAudit.dispInventoryAudits && this.invTransAudit.dispInventoryAudits.length && this.invTransAudit.dispInventoryAudits[0].TotalCount;
        this.manageInventoryAuditsLength = this.invTransAudit.manageInventoryAudits && this.invTransAudit.manageInventoryAudits.length && this.invTransAudit.manageInventoryAudits[0].TotalCount;
        this.physicalInventoryAuditsLenght = this.invTransAudit.physicalInventoryAudits && this.invTransAudit.physicalInventoryAudits.length && this.invTransAudit.physicalInventoryAudits[0].TotalCount;
        this.recvInventoryAuditsLength = this.invTransAudit.recvInventoryAudits && this.invTransAudit.recvInventoryAudits.length && this.invTransAudit.recvInventoryAudits[0].TotalCount;
      }
      this.generatemanageinvWJ();
      this.generatePhysicalInvWJ();
      this.generateReceiveInvWJ();
      this.generateDispensedInvWJ();
    }
  });
}

setPage(page: number,value?) {
  this.pageNu = page;
  this.manageInvFG.controls["manageInvPageNumber"].patchValue(value == "manageInvAudit" ? page : 1);
  this.physicalInvFG.controls["PhysicalInvPageNumber"].patchValue(value == "physicalInvAudit" ? page : 1);
  this.receiveInvFG.controls["RecvInvPageNumber"].patchValue(value == "receiveInvAudit" ? page : 1);
  this.dispensedInvFG.controls["DispPageNumber"].patchValue(value == "dispensedInvAudit" ? page : 1);
  this.searchData();
}
setSize(size: any,value) {
  this.Size = size;
  this.manageInvFG.controls["manageInvPageSize"].patchValue(value == "manageInvAudit" ? size : 30);
  this.physicalInvFG.controls["PhysicalInvPageSize"].patchValue(value == "physicalInvAudit" ? size : 30);
  this.receiveInvFG.controls["RecvInvPageSize"].patchValue(value == "receiveInvAudit" ? size : 30);
  this.dispensedInvFG.controls["DispPageSize"].patchValue(value == "dispensedInvAudit" ? size : 30);
  this.setPage(1)
}
clearData(){
this.InvAuditFG.controls['FromDate'].patchValue(this.today)
this.InvAuditFG.controls['ToDate'].patchValue(this.today);
this.InvAuditFG.controls['BucketId'].patchValue(0);
this.searchData()
}
ngOnDestroy(){
  this.unsubscribe$.next();
  this.unsubscribe$.complete();
}
}
