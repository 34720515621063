<div class="exprx--common-block">
    <div class="eprx--block__header large_header">
        <div class="row">
            <div class="col">
                <span class="eprx--header__heading">Life Style Concept</span>
            </div>
            <div class="col pull-right text-right">
                <button class="hotkey_success hotkey_primary--Override" title="Add LifeStyle Concept" (keydown.tab)="focusOutFromAdd($event)"
                    (click)="openPopUpModal(lifestyleList)" autofocus appShortcutKey
                    [AltKey]="'a'"><span>A</span>ADD LIFESTYLE CONCEPT</button>
            </div>
        </div>
    </div>
    <div class="eprx--block__content erx--body">
        <wj-flex-grid #LifeStyle [headersVisibility]="'Column'" [selectionMode]="'Row'"
            [itemsSource]="patientLifeStyleWJ" [isReadOnly]="true" [ngClass]="!totalCount ? 'no-data' : ''"
            (keydown.alt.d)="getRowDetails(deleteLifeStyleContent, LifeStyle, $event)"
            (keydown.tab)="focusToFirst($event)" (keydown)="openPopOnWijimo(lifestyleList, $event)"
        >
            <wj-flex-grid-column [header]="'Action'" [width]="'0.5*'">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                    <i class="far fa-trash-alt actions danger-actve" title="Add"
                        (click)="getRowDetails(deleteLifeStyleContent, LifeStyle, $event)"></i>
                </ng-template>
            </wj-flex-grid-column>
            <wj-flex-grid-column [header]="'Code'" [width]="'1*'" [binding]="'Code'">
            </wj-flex-grid-column>
            <wj-flex-grid-column [header]="'Description'" [width]="'5*'" [binding]="'Description'">
            </wj-flex-grid-column>
            <app-no-data-found *ngIf="!isDataExists"></app-no-data-found>
        </wj-flex-grid>
    </div>
</div>

<!-- Life Style Concept list modal-->
<ng-template #lifestyleList let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Add Life Style Concept</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-4">
                <eprx-input [LabelText]="'Code'" [AutoFocus]="true" [PlaceHolder]="'Search Code'"
                    (TriggerChangeValue)="getFilteredData($event, 'Code')" [HasControl]="false">
                </eprx-input>
            </div>
            <div class="col-md-8">
                <eprx-input [LabelText]="'Description'" [PlaceHolder]="'Search Description'"
                    (TriggerChangeValue)="getFilteredData($event, 'Description')" [HasControl]="false">
                </eprx-input>
            </div>
        </div>
        <wj-flex-grid *ngIf="totalCount > 0" #LifeStyle [headersVisibility]="'Column'" [itemsSource]="lifeStyleWJ"
            [isReadOnly]="true" [selectionMode]="'Row'" (initialized)="Init(LifeStyle)"
            (keydown.space)="preventSpaceEvent($event);changeLifeStyle(LifeStyle.selectedItems[0],null)"
            (dblclick)="keyEventOnWijimo(LifeStyle, $event)" (keydown)="closeAddPop($event)"
            (updatedView)="init(LifeStyle)" class="add-life-style-wijmo">
            <wj-flex-grid-column header="Select" [width]="100">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-cell="cell">
                    <span class="text-center" *ngIf="!item['AlreadySelected']">
                        <input (focus)="$event.target.blur()" class="form-check-input" type="checkbox" [id]="item.RowId" name=""
                            [(ngModel)]="item.IsSelected" [checked]="item.IsSelected"
                            (click)="changeLifeStyle(item,$event)" />
                        <label class="form-check-label" [for]="item.RowId">
                        </label>
                    </span>
                    <span  class="text-center" *ngIf="item['AlreadySelected']">
                        <i class="far fa-check-circle text-success actions"  aria-hidden="true" title="Already Selected"></i>
                    </span>
                </ng-template>
            </wj-flex-grid-column>
            <wj-flex-grid-column [header]="'Code'" [width]="250" [binding]="'Code'">
            </wj-flex-grid-column>
            <wj-flex-grid-column [header]="'Description'" [width]="'*'" [binding]="'Description'">
            </wj-flex-grid-column>
        </wj-flex-grid>
        <app-eprx-pagination [rxId]="'lifeStylePage'" *ngIf="totalCount > 0" [TotalCount]="totalCount" [PageSize]="pageSize"
            [WijmoName]="wijimoId" [GridName]="'LifeStyleList'" (OnPageSelected)="setPage($event)"
            (OnSizeSelected)="setPageSize($event)" [FromModal]="true"></app-eprx-pagination>
        <div *ngIf="totalCount === 0" class="eprx--block__footer">
            <div>
                <span></span>
                <i class="fa fa-file"></i><br/>
                No Data Found!
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" id="lifeStyleAdd" class="hotkey_success" (click)="addMultiple()" appShortcutKey
            [AltKey]="'a'"><span>A</span>Add Selected</button>
        <button type="button" class="hotkey_success" (click)="c('Close click')" appShortcutKey
            [AltKey]="'c'"><span>C</span> Close</button>
    </div>
</ng-template>

<!-- delete diagnosis -->
<ng-template #deleteLifeStyleContent let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Confirmation</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>Are you sure you want to delete the selected Patient Life Style?</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="hotkey_primary" (click)="c('Close click'); deleteLifeStyle()" appShortcutKey
            InputKey="y"><b>Y</b> YES</button>
        <button type="button" class="hotkey_primary" (click)="c('Close click')" appShortcutKey InputKey="n"><b>N</b>
            NO</button>
    </div>
</ng-template>
