import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { DrugTabUtils } from "src/app/utils/drug-tabs.utils";
import { DrugData, AccCategoryE, RxE, SystemData, PaginationModel } from "src/app/models";
import { ColorCode, CommonUtil, NRxUtils, WijimoUtil } from "src/app/utils";
import { PatPreDrugModalService } from "src/app/services";
import { PrivMaskGuard } from "src/app/guards";
import * as wjcGrid from "@grapecity/wijmo.grid"
import { ColorCodeRx } from "src/app/utils/rx-color.util";
import { AlertService } from "../../../services";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
@Component({
    selector: "app-drug-disp-hist",
    templateUrl: "./drug-disp-hist.component.html"
})
export class DrugDispHistComponent implements OnInit {
    dispHisFG: any;
    @Input() drugId: number;
    @Input() drugInfo: DrugData;
    @Input() systemData: SystemData;
    dispHistData: { count: number; wjSource: any; } = { count: 0, wjSource: null};
    wjHeaders: any;
    routeToRxInfo: any;
    accessPrivCate: any;
    rxPrivs: any;
    @ViewChild("inventorylist")
    flex: wjcGrid.FlexGrid;
    openOverride: boolean;
    isDataExists = true;
    paginationModel: PaginationModel = new PaginationModel();
    unsubscribe$: Subject<void> = new Subject();
    constructor(private _fb: FormBuilder,
        private _nrxUtils: NRxUtils,
        private _privMaskGuard: PrivMaskGuard,
        private _patPreDrugModalServ: PatPreDrugModalService,
        private _colorCode: ColorCode,
        private _drugTabUtil: DrugTabUtils, private _comnUtil: CommonUtil, private _wijimoUtils: WijimoUtil,
         private _colorCodeRx: ColorCodeRx,
         private _alertService: AlertService) { }

    ngOnInit() {
        this.accessPrivCate = AccCategoryE;
        this.rxPrivs = RxE;
        this.dispHisFG = this._fb.group({
            drugId: this.drugId,
            pagenumber: this.paginationModel.pageNumber,
            pagesize: this.paginationModel.pageSize,
            Therapeutic: (this.drugInfo &&  this.drugInfo["Drug"] && this.drugInfo["Drug"]["Therapeutic"]) ? this.drugInfo["Drug"]["Therapeutic"] : null,
            TherapeuticCode: (this.drugInfo &&  this.drugInfo["Drug"] && this.drugInfo["Drug"]["TherapeuticCode"]) ? this.drugInfo["Drug"]["TherapeuticCode"] : null,
            includeEquivalentDrugs: false
        });
        this.getDrugDispHisData();
        this.patchDefaultValHeaders();
        this._wijimoUtils.wjSavedData$.pipe(takeUntil(this.unsubscribe$)).subscribe(w => {
            if (w && w["WijmoKey"] && w["WijmoKey"] === "drguDispHistoryListWJ") {
                this.patchDefaultValHeaders();
            }
        });
    }

    async getDrugDispHisData() {
        this.dispHistData = await this._drugTabUtil.getDrugDispHis(this.dispHisFG.value, this.systemData);
        this.dispHistData.count ? this.isDataExists = true : this.isDataExists = false;
    }

    async patchDefaultValHeaders() {
        const storedWJ = await this._wijimoUtils.getWJSavedData("drguDispHistoryListWJ");
        this.wjHeaders = this._wijimoUtils.patchDefHeader("drguDispHistoryListWJ", storedWJ);
    }

    setPage(page: number) {
        this.dispHisFG.controls["pagenumber"].setValue(page);
        this.getDrugDispHisData();
    }
    setSize(size: any) {
        this.dispHisFG.controls["pagenumber"].setValue(1);
        this.dispHisFG.controls["pagesize"].setValue(size);
        this.getDrugDispHisData();
    }

    focusToSave(event) {
        this._comnUtil.focusById("saveButton", event);
    }

    routeToEditRx(val: any) {
        this.routeToRxInfo = val;
        if (this.checkHasPrivsToAccess(
            this.accessPrivCate.Rx,
            this.rxPrivs.EditRx)) {
                this.routeToRx();
            } else {
                this.openOveride();
            }
    }

    async routeToRx(rxNo?: any, rxId?: any) {
        const selectedrxNo = rxNo ? rxNo : +this.routeToRxInfo["Rx#"];
        const selectedrxId = rxId ? rxId : this.routeToRxInfo["PrescRefillId"];
        let rxInfo: any = await this._nrxUtils.getandStoreRxInfo(selectedrxNo, selectedrxId, false, null, null, null, true);
        if (rxInfo) {
            rxInfo = this._nrxUtils.editRxPatchValues(rxInfo);
            this._nrxUtils.createPatchStoreRxFG(rxInfo);
            this._nrxUtils.getCommonEndPoints(rxInfo);
            this._patPreDrugModalServ._patPreDrugModal$.next("Rx");
            const modalRef = this._patPreDrugModalServ.modalInstanceRef;
            modalRef.componentInstance.CloseEditMode.pipe(takeUntil(this.unsubscribe$))
                .subscribe(x => {
                    modalRef.close();
                    if (x === "Refresh") {
                        this.getDrugDispHisData();
                    }
                  this._patPreDrugModalServ._patPreDrugModal$.next(null);
            });
            modalRef.componentInstance.CloseAndReopen.pipe(takeUntil(this.unsubscribe$))
            .subscribe(d => {
                modalRef.close();
                if (d) {
                    this.routeToRx(d["rxNo"], d["rfId"]);
                } else {
                    if (d === "Refresh") {
                        this.getDrugDispHisData();
                    }
                  this._patPreDrugModalServ._patPreDrugModal$.next(null);
                }
            });
        }
    }

    checkHasPrivsToAccess(category: string, privType: string) {
        return this._privMaskGuard.canActivate(category, privType);
    }

    OverrideRes(val) {
        this.openOverride = false;
        if (val) {
            this.routeToRx();
            }
    }

    openOveride() {
        this.openOverride = true;
    }

    itemFormatter = (panel, r, c, cell) => {
        const row = panel.rows[r];
        if (row.dataItem) {
            cell.style.background = "";
            if (panel.cellType === wjcGrid.CellType.Cell && panel.columns[c].binding === "Status") {
                const colors = this._colorCode.showRxStatusColorRefactoring(row.dataItem);
                if (colors && colors.bg && colors.color) {
                    cell.style.background = colors.bg;
                    cell.style.color = colors.color;
                } else {
                    cell.style.background = "#FFF";
                    cell.style.color = "#000";
                }
                cell.style.fontWeight = "bold";
                cell.style.textAlign = "center";
            } else if (panel.cellType === wjcGrid.CellType.Cell && panel.columns[c].binding === "Rx#") {
                const rowId = row && row.dataItem ? row.dataItem["RowId"] : "";
                const RxDetails = rowId >= 0 ? this.dispHistData["resp"]["DispensedHistory"][rowId] : null;
                const colors = this._colorCodeRx.showRxColorCodeAndHoverOver(RxDetails, "RxObject", "Rx");
                if (colors && colors.bg && colors.color) {
                    cell.style.background = colors.bg;
                    cell.style.color = colors.color;
                } else {
                    cell.style.background = "#FFF";
                    cell.style.color = "#000";
                }
                cell.style.fontWeight = "bold";
                cell.style.paddingLeft = "1rem";
            } else {
                cell.style.backgroundColor = "#FFF";
                cell.style.color = "#000";
                cell.style.fontWeight = "normal";
                cell.style.textAlign = "initial";
            }
        }
    }

    toggleColorLegend() {
        this._alertService.showHelpText({RxStatus: true, DrugName: false, Status: true});
    }
    ngOnDestroy(){
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
