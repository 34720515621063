<ng-template #BILLTO let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModal()">
        <h4 header>Select Insurance To Bill</h4>
        <span body>
            <div class="eprx--block__content">
                <div *ngIf="insureList?.length === 0" class="text-center">
                    <h1>There are no Insurances for this patient.</h1>
                </div>
                <span *ngIf="insureList?.length > 0">
                    <wj-flex-grid
                        #insurances
                        [headersVisibility]="'Column'"
                        [selectionMode]="'None'"
                        [itemsSource]="insurncListModified"
                        [isReadOnly]="true"
                        [columnPicker]="''"
                    >
                        <wj-flex-grid-column header="Select">
                            <ng-template
                                wjFlexGridCellTemplate
                                [cellType]="'Cell'"
                                let-item="item"
                                let-cell="cell"
                            >
                                <input
                                    type="radio"
                                    name="SelectedIns"
                                    (change)="
                                        selectedInsurence(
                                            $event?.target?.checked,
                                            item?.insuranceid
                                        )
                                    "
                                    [value]="item.insuranceid"
                                    [checked]="
                                        patInsurList?.length > 1
                                            ? item?.insuranceid ==
                                            rxFG?.value?.RefillInsu.InsuId
                                            : 'true'
                                    "
                                />
                            </ng-template>
                        </wj-flex-grid-column>
                        <wj-flex-grid-column
                            [header]="'Ins Code'"
                            [width]=""
                            [binding]="'insuType'"
                        >
                        </wj-flex-grid-column>
                        <wj-flex-grid-column
                            [header]="'Insurance Name'"
                            [width]="400"
                            [binding]="'insurancename'"
                        >
                        </wj-flex-grid-column>
                        <wj-flex-grid-column
                            [header]="'Insurance Id #'"
                            [width]=""
                            [binding]="'insuranceid'"
                        >
                        </wj-flex-grid-column>
                        <wj-flex-grid-column
                            [header]="'Bill Order'"
                            [width]=""
                            [binding]="''"
                        >
                        </wj-flex-grid-column>
                        <wj-flex-grid-column
                            [header]="'BIN'"
                            [width]=""
                            [binding]="'binnum'"
                        >
                        </wj-flex-grid-column>
                        <wj-flex-grid-column
                            [header]="'PCN'"
                            [width]="160"
                            [binding]="'policynum'"
                        >
                        </wj-flex-grid-column>
                    </wj-flex-grid>
                </span>
            </div>
        </span>
        <button footer ngbAutofocus class="hotkey_success" (click)="closeModal()"
        appShortcutKey [AltKey]="'o'">
            <span>O</span> OK
        </button>
        <button
            footer
            class="hotkey_success mr-0"
            (click)="closeModal()"
            appShortcutKey [AltKey]="'c'"
        >
            <span>C</span> Cancel
        </button>
    </app-custom-modal>
</ng-template>
