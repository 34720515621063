import { Component, OnInit, Input, OnChanges } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";

@Component({
    selector: "app-notes",
    templateUrl: "./notes.component.html",
    styles: []
})
export class PNotesComponent implements OnInit, OnChanges {
    noteInfo: any;
    patientNoteFG: FormGroup;

    @Input()
    set notes(data) {
        this.noteInfo = data;
    }

    constructor(private _fb: FormBuilder) {
        this.patientNoteForm();
    }
    patientNoteForm() {
        this.patientNoteFG = this._fb.group({
            Patient: this._fb.group({
                Remarks: [""]
            })
        });
    }

    ngOnInit() {}

    ngOnChanges(value: any) {
        if (this.noteInfo) {
            this.patientNoteFG.patchValue(this.noteInfo);
        }
    }
}
