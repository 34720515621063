import {
    Component,
    OnInit,
    Input,
    AfterViewInit,
    Output,
    EventEmitter,
    OnChanges
} from "@angular/core";
import { PatientInfo, SystemData } from "src/app/models";
import { CommonService } from "src/app/services";
import { FormGroup, FormArray } from "@angular/forms";
import { Observable } from "rxjs";
import { PatientStore } from "src/app/store/patient.store";
import { PatientInfoUtil } from "src/app/utils/patient-info.util";
import * as moment from "moment";
import { CommonUtil } from "src/app/utils";

@Component({
    selector: "app-patient-info",
    templateUrl: "./patient-info.component.html"
})
export class PPatientInfoComponent implements OnInit, AfterViewInit, OnChanges {

    @Input() patientId: number;
    @Input() patientInfo: PatientInfo;
    @Input() patientInfoFG: FormGroup;
    @Input() systemData: SystemData;
    @Input() insuranceList: any;
    @Input() FrmFamily: boolean;
    @Input() formGroupInvalid: boolean;
    @Input() PatInsuId: number;
    @Input() focusId: any;
    @Output() emitPreferred = new EventEmitter<any>();
    @Output() GetandPatchPatientInfo = new EventEmitter<any>();
    @Output() AddtnlInsurncTab = new EventEmitter<null>();
    @Output() After340bInsurancePopupClose = new EventEmitter<boolean>();
    @Output() Exist340bInsurancePopupClose = new EventEmitter<boolean>();
    @Input() isFromDashboard : boolean;
    @Input() EditPatInfo: any;
    patientInsuList$: any;
    newInsurance: any;
    patch340BIns: boolean;
    focuschange : boolean;
    constructor(private _commonServ: CommonService, private _patientStore: PatientStore, private _commonUtil: CommonUtil) {
        this.patientInsuList$ = this._patientStore.patientInsuList$;
    }

    ngOnInit() {
        if (!this.patientId) {
            this.patchDefaultLangCode();
        }
        if(this.focusId) {
            const element: any =  document.getElementById(this.focusId) as HTMLInputElement;
            if(element) {
                  element.focus();
            }
        }
    }
    ngOnChanges() {
        if(this.focusId) {
            const element: any =  document.getElementById(this.focusId) as HTMLInputElement;
            if(element) {
                  element.focus();
            }
        }
        this.focuschange = this.isFromDashboard ? ((this.patientInfoFG.controls['Person'].value['FirstName']) ? true : false): true;
    }

    ngAfterViewInit() {
        if (!this.patientId) {
            this.focusOnReqFeild();
        }
    }

    changeInsurance(val) {
        this.newInsurance = val;
    }

    patchDefaultLangCode() {
        const patLang = this._commonServ.getSetttingValue(
            "PatientSettings", "PAT_LANGUAGE");
        if (patLang) {
            const langVal: any = this.systemData.Lang.filter(val => val.LangCode === patLang)[0];
            const patientFG = this.patientInfoFG.controls["Patient"] as FormGroup;
            patientFG.controls["LanguageId"].setValue(langVal.Id ? +langVal.Id : null);
            const insuCardFG = this.patientInfoFG.get("InsuCards") as FormGroup;
            insuCardFG.controls["ExpiryDt"].setValue("12/31/2099");
        }
    }

    addtnlInsurncTab(event) {
        this.AddtnlInsurncTab.emit(event);
    }

    savePreferred(value) {
        this.emitPreferred.emit(value);
    }

    patch340BInss(patch340BIns) {
        this.patch340BIns = false;
        setTimeout(() => {
            this.patch340BIns = patch340BIns;
        }, 100);
    }

    focusOnReqFeild() {
        if (document.querySelectorAll("input.ng-invalid") && document.querySelectorAll("input.ng-invalid")[1] && this.focuschange) {
            const query = document.querySelectorAll("input.ng-invalid");
            for (let i = 0; i < query.length; i++)
            {
                if (query[i] && (query[i]["id"] !== "undefined" && query[i]["id"] !== " ")) {
                    (<HTMLInputElement>query[i]).focus();
                    return;
                }
            }
        }
    }
    closePopUpFor340BInsurance(event) {
        this.After340bInsurancePopupClose.emit(true);
}
exist340bInsuranceInsPop(event) {
    this.Exist340bInsurancePopupClose.emit(true);
}
}
