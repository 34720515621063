import { Component, OnInit, AfterViewInit, NgZone } from "@angular/core";
import { PrimeEscService } from "src/app/services/prime-esc.service";
import { WijimoUtil } from "src/app/utils";

import * as wjcCore from "@grapecity/wijmo";
import * as wjcGrid from "@grapecity/wijmo.grid"
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
    selector: "app-sign-by-patient",
    templateUrl: "./sign-by-patient.component.html"
})
export class SignByPatientComponent implements OnInit, AfterViewInit {
    rxPatientModel: any;
    isInputFocused = false;
    ackInfoWJ: wjcCore.CollectionView;
    wjHeadersAck: any;
    wjHeadersHist: any;
    patientSignData: any;
    histInfoWJ: wjcCore.CollectionView;
    showPatientSign = false;
    showPatientSignLog = false;
    signatureData: any;
    sigType: any;
    signId: any;
    RPH: any;
    unsubscribe$: Subject<void> = new Subject();

    constructor(private _primeEscSer: PrimeEscService, 
        private _ngZone: NgZone,
        private _wijimoUtils: WijimoUtil) { }

    ngOnInit() {
        this.patchDefaultValHeaders();
        this._wijimoUtils.wjSavedData$.pipe(takeUntil(this.unsubscribe$)).subscribe(w => {
            if (w && w["WijmoKey"] && (w["WijmoKey"] === "primeescPatAckListWJ" || w["WijmoKey"] === "primeescPatHistListWJ")) {
                this.patchDefaultValHeaders();
            }
        });
    }

    ngAfterViewInit() {
        this.isInputFocused = true;
    }

    async patchDefaultValHeaders() {
        const storedWJAck = await this._wijimoUtils.getWJSavedData("primeescPatAckListWJ");
        this.wjHeadersAck = this._wijimoUtils.patchDefHeader("primeescPatAckListWJ", storedWJAck);
        const storedWJHist = await this._wijimoUtils.getWJSavedData("primeescPatHistListWJ");
        this.wjHeadersHist = this._wijimoUtils.patchDefHeader("primeescPatHistListWJ", storedWJHist);
    }

    selectedPatientInfo(patientInfo: any) {
        if (patientInfo && patientInfo.patientid) {
            this.isInputFocused = false;
            this.rxPatientModel = patientInfo;
            this.getPatientSignHistory();
        }
    }

    resetPatientInfo() {
        this.rxPatientModel = null;
        this.patientSignData = null;
        // setTimeout(() => {
            this.isInputFocused = true;
        // }, 10);
    }

    clearFilters() {
        this.resetPatientInfo();
        this.generateAckInfoListWJ([]);
        this.generateHistInfoListWJ([]);
        // this.patchDefaultValHeaders();
    }


    getPatientSignHistory() {
        this._primeEscSer.getSignHistoryByPatientId(this.rxPatientModel.patientid).pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp) {
                    this.patientSignData = resp;
                    this.generateAckInfoListWJ(resp.PrivacyAcknowledge);
                    this.generateHistInfoListWJ(resp.InsSignatureTrans);
                }
            });
    }

    generateAckInfoListWJ(ackInfoList: any[]) {
        if (ackInfoList && ackInfoList.length > 0) {
            this.ackInfoWJ = new wjcCore.CollectionView(
                ackInfoList.map((acknowldge, i) => {
                    const j = {};
                    j["Action"] = "";
                    j["Trans No"] = j["Trans#"] =acknowldge.Id;
                    j["Date"] = acknowldge.DateSigned;
                    j["Accept"] = acknowldge.PatAccept;
                    j["Sig Type"] = acknowldge.SigType;
                    j["Binary Sign"] = "System.Byte[]";
                    j["Event Type"] = acknowldge.EventType;
                    j["Sign"] = acknowldge.PatSign;
                    j["RPH"] = acknowldge["RPH"];
                    return j;
                })
            );
        } else {
            this.ackInfoWJ = null;
        }

    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
    
    generateHistInfoListWJ(histInfoList: any[]) {
        if (histInfoList && histInfoList.length > 0) {
            this.histInfoWJ = new wjcCore.CollectionView(
                histInfoList.map((historyy, i) => {
                    const j = {};
                    j["Action"] = "";
                    j["Trans No"] =  j["Trans#"] = historyy.Id;
                    j["Date"] = historyy.CreatedDtTm;
                    j["Insu"] = historyy.InsType;
                    j["Rxs Signed For"] = historyy.TranSignatureData;
                    j["Counseling"] = historyy.CounselingReq;
                    j["Sig Type"] = historyy.SigType;
                    j["Manual Group no"] = "";
                    j["PHARM"] = "";
                    j["INSMSGACCEPTANCE"] = "";
                    j["PHARMSIGDATA"] = "";
                    j["INSSIGDATA"] = "";
                    j["PHARMSIGBIN"] = "";
                    j["INSSIGBIN"] = "";
                    j["DelType"] = historyy.DelType;
                    j["UserInitial"] = historyy.UserInitial;
                    j["RPH"] = historyy["RPH"];
                    return j;
                })
            );
        } else {
            this.histInfoWJ = null;
        }

    }

    openSignModal(item, isAck) {
        this._primeEscSer.getPatAckSign(item["Trans No"], isAck).pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp) {
                    this.signatureData = resp;
                    this.sigType = isAck;
                    this.RPH = item["RPH"];
                    this.signId = item["Trans No"];
                    this._ngZone.run(() => {
                        this.showPatientSign = true;
                    });
                }
            });
    }

    focusToFirst(event) {
        if (document.getElementById("clearButton")) {
            event.preventDefault();
            // setTimeout(() => {
                document.getElementById("clearButton").focus();
            // }, 10);
        }
        
        }
}
