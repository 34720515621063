import { SharedModule } from "./../shared/shared.module";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RphVerificationRoutingModule } from "./rph-verification-routing.module";
import { RphVerificationComponent, ActionButtonsComponent } from ".";
import { CustFormElemnsModule } from "../cust-form-elemns/cust-form-elemns.module";
import { WjCoreModule } from "@grapecity/wijmo.angular2.core";
import { WjInputModule } from "@grapecity/wijmo.angular2.input";
import { WjGridModule } from "@grapecity/wijmo.angular2.grid";
import { NgxMaskModule } from "ngx-mask";
import { RxNotesComponent } from "./action-buttons/rx-notes/rx-notes.component";
import { RphFollowupComponent } from "./action-buttons/rph-followup/rph-followup.component";
import { RphVerifyComponent } from "./rph-verify/rph-verify.component";
import { RphVerifyHistComponent } from "./action-buttons/rph-verify-hist/rph-verify-hist.component";
import { InsTransResultComponent } from './action-buttons/ins-trans-result/ins-trans-result.component';
import { CanDeactivateGuard, RphVerificationGuard, RphVerifyGuard } from "../../guards";
import { TransmissionModule } from "../transmission/transmission.module";
import { ReviewPharmacistVerifComponent } from './review-pharmacist-verif/review-pharmacist-verif.component';
import { ActionsHistoryComponent } from './actions-history/actions-history.component';
import { RxActionsModule } from "../rx-actions-module/rx-actions.module";

@NgModule({
    imports: [
        CommonModule,
        RphVerificationRoutingModule,
        SharedModule,
        CustFormElemnsModule,
        WjCoreModule,
        WjInputModule,
        WjGridModule,
        NgxMaskModule.forRoot(),
        TransmissionModule,
        RxActionsModule
    ],
    declarations: [
        RphVerificationComponent,
        ActionButtonsComponent,
        RxNotesComponent,
        RphFollowupComponent,
        RphVerifyComponent,
        RphVerifyHistComponent,
        InsTransResultComponent,
        ReviewPharmacistVerifComponent,
        ActionsHistoryComponent
    ],
    exports: [
        RphVerifyComponent
    ],
    providers: [RphVerificationGuard, CanDeactivateGuard]
})
export class RphVerificationModule {}
