
<div class="exprx--common-block">
    <div class="eprx--block__header text-white pl-3 font-weight-bold">
        <div class="row">
            <div class="col-11">
                To Pharmacy Details
            </div>
            <div class="col-1" *ngIf="selectedpharmacyDetails && !sourcepharmacyInfo">
                <i  (click)="openPharmacyEdit()"
                class="far  fa-edit" title="Pharmacy Edit"></i>
            </div>
        </div>
    </div>
    <div
    class="eprx--block__content height_300 pt-0">
        <div class="seconday-search--grid lg-secondary-search--grid pt-3 mb-0" *ngIf="!sourcepharmacyInfo">
            <app-pharmacy-suggest [IsEdit]="isedit" (CloseEditPharmacy)="isedit=false"  (selectedPharm)="selectedPharmDetails($event)"></app-pharmacy-suggest>
  </div> 
    <div class="row row-space">
        <div class="col-12">
            <label>{{pharmacyabels.pName}}</label>
            <div class="label--data"><span>{{selectedpharmacyDetails ?
                    (selectedpharmacyDetails?.PharmacyName) : msgConfig.EMPTY_Data}}</span></div>
        </div>
    </div>
    <div class="row row-space">
        <div class="col-4">
            <label>{{pharmacyabels.Phone}}</label>
            <div class="label--data"><span>{{selectedpharmacyDetails ?
                    (selectedpharmacyDetails?.Phone1) : msgConfig.EMPTY_Data}}</span></div>
        </div>
        <div class="col-4">
            <label>{{pharmacyabels.Fax}}</label>
            <div class="label--data"><span>{{selectedpharmacyDetails ?
                    (selectedpharmacyDetails?.Fax) : msgConfig.EMPTY_Data}}</span></div>
        </div>
        <div class="col-4">
            <label>{{pharmacyabels.NPI}}</label>
            <div class="label--data"><span>{{selectedpharmacyDetails?.NPINum?.length ?
                    selectedpharmacyDetails?.NPINum : msgConfig.EMPTY_Data}}</span>
            </div>
        </div>
    </div>
    <div class="row row-space">
        <div class="col-12">
            <label>{{pharmacyabels.Address}}</label>
            <div class="label--data"> <span>{{(selectedpharmacyDetails?.addresses) ?
                    selectedpharmacyDetails?.addresses : msgConfig.EMPTY_Data}}</span></div>
        </div>
    </div>
    <div class="row row-space">
    </div>
</div>
</div>
