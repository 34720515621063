<div class="editpatient--body newrx--body edit--drug">
    <div class="editpatient">
        <div class="row content--heading">
            <div class="col-md-8 padding-0">
                <div class="col-md-11 padding-0">
                    <div class="content--heading__title">
                        Reports |
                        <span class="content--name"> Include Rx PickUp Details </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="exprx--common-block">
        <div class="eprx--block__header">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-11">
                            <div class="eprx--header__heading">
                                <span> Include Rx PickUp Details</span>
                            </div>
                        </div>
                        <div class="col-1 padding_left_7rem">
                            <app-user-guide-icon [ActiveTabId]= "'RxPickupDetails'"></app-user-guide-icon>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="eprx--block__content reports_default_height3 row mx-0">
            <div class="col-md-4 padding-0">
                <div class="row">
                    <div class="row col-md-12 insurance--body">
                        <div class="col-md-12 height_3rem">
                            <eprx-radio [ControlName]="'IsPickedupLog'" [FormGroupName]="rxPickUpFG"
                                [List]="['Picked Up Date', 'Filled Date']" [ValueList]="[1, 2]"
                                [IDForList]="['pickUpdate', 'filldate']" [LabelForId]="" (TriggerSelectValue)="dateFilterSelected()">
                            </eprx-radio>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <eprx-date-picker [AutoFocus]="true" [RxSelectId]="'FromPickDate'" [LabelText]="'From Date'"
                                [PlaceHolder]="''" [ControlName]="'FromDate'" [FormGroupName]="rxPickUpFG" [MinDate]=""
                                [MaxDate]="" [IsDisabled]="rxPickUpFG.value['IsPickedupLog'] === 3">
                            </eprx-date-picker>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <eprx-date-picker [RxSelectId]="'ToPickDate'" [LabelText]="'To Date'" [PlaceHolder]="''"
                                [ControlName]="'ToDate'" [FormGroupName]="rxPickUpFG" [MinDate]="" [MaxDate]=""
                                [IsDisabled]="rxPickUpFG.value['IsPickedupLog'] === 3">
                            </eprx-date-picker>
                        </div>
                        <div class="col-md-12 height_3rem">
                            <eprx-radio [ControlName]="'rxSelected'" [FormGroupName]="rxPickUpFG"
                                [List]="['By Rxs / Rx#-Ref#']" [ValueList]="[true]"
                                [IDForList]="['byRx']" [LabelForId]="" (TriggerSelectValue)="rxSelected()">
                            </eprx-radio>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <eprx-text-area [RxSelectId]="'RxNoSearch'" *ngIf="rxPickUpFG.value['IsPickedupLog'] == 3" [LabelText]="''" [ControlName]="'Rxrefill'" [FormGroupName]="rxPickUpFG" [FormGroupInvalid]="formSubmitted" [ErrorDefs]="{required: 'Required'}" [Rows]="4" [PlaceHolder]="'Comma Separated'" [IsRequired]="true" (keydown.space)="preventSpaceEvent($event)" (keydown.enter)="preventSpaceEvent($event)">
                            </eprx-text-area>
                            <eprx-text-area [RxSelectId]="'RxNoSearch'" *ngIf="rxPickUpFG.value['IsPickedupLog'] !== 3" [ReadOnly]="true" 
                            [ControlName]="'Rxrefill'" [FormGroupName]="rxPickUpFG"
                            [Rows]="4" [PlaceHolder]="'Comma Separated'">
                            </eprx-text-area>
                        </div>
                        <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9">
                            <eprx-text-area [LabelText]="'Ins Type (Comma Separated)'" [ControlName]="'InsuranceType'" [FormGroupName]="rxPickUpFG"
                            [Rows]="4" [PlaceHolder]="'Ins Type (Comma Separated)'" [ReadOnly]="rxPickUpFG.value['IsPickedupLog'] === 3"
                            [RxSelectId]="'InsuTypeSearch'">
                            </eprx-text-area>
                        </div>
                        <div class="text-right pull-right col-lg-3 col-md-3 col-sm-3 col-xs-3 px-0 margin_top_3_5rem">
                            <button [ngClass]="rxPickUpFG.value['IsPickedupLog'] !== 3 ? 'hotkey_success':'inactive'" (click)="openInsuSearch()" (keydown.enter)="openInsuSearch()"
                            appShortcutKey [AltKey]="'i'">
                                <span>I</span>SELECT INS.</button>
                        </div>
                        <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9">
                            <eprx-text-area [LabelText]="'Selected Payor(s)'" [FormGroupName]="rxPickUpFG"
                                [ControlName]="'PayorCodes'" [PlaceHolder]="'Selected Payor Ins Ids'" [Rows]="4"
                                [ReadOnly]="true" [RxSelectId]="'PayorSearch'">
                            </eprx-text-area>
                        </div>
                        <div class="text-right pull-right col-lg-3 col-md-3 col-sm-3 col-xs-3 px-0 margin_top_3_5rem">
                            <button class="hotkey_success" (click)="openPayorPOPUP()"
                                (keydown.enter)="openPayorPOPUP()" appShortcutKey [AltKey]="'r'">
                                <span>R</span>Payor
                            </button>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 height_2_5rem" [formGroup]="rxPickUpFG">
                            <input class="form-check-input" type="checkbox" [id]="'PickUpIdQ1'" formControlName="PickUpIdQ" />
                            <label class="form-check-label" [for]="'PickUpIdQ1'" id="'PickUpIdQ'">Show Pickup IDQ</label>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 height_2_5rem" [formGroup]="rxPickUpFG">
                            <input class="form-check-input" type="checkbox" [id]="'PickUpId1'" formControlName="PickUpId" />
                            <label class="form-check-label" [for]="'PickUpId1'" id="'PickUpId'">Show Pickup ID</label>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 erx--body bottom_0_3rem">
                            <label>Prescriber</label>
                            <div class="seconday-search--grid">
                                <app-searchbox-global class="common_global_search_max_content_display" id="Presc" [RxSelectId]="'Presc'"
                                    [SearchFor]="'Prescriber'" [ShowOnlyPrescName]="false" [inputdisabled]="rxPickUpFG.value['IsPickedupLog'] === 3"
                                    [IsAddNew]="false" [DisableSerchFil]="true" [DisableRadioButtons]="true"
                                    (SelectedPrescriber)="selectPrescriber($event)" [ClearInputValue]="presInputValue"></app-searchbox-global>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 erx--body bottom_0_3rem">
                            <label>Patient</label>
                            <div class="seconday-search--grid">
                                <app-searchbox-global class="common_global_search_max_content_display" id="Patient" [RxSelectId]="'Patient'"
                                    [SearchFor]="'Patient'" [ShowOnlyPrescName]="true" [inputdisabled]="rxPickUpFG.value['IsPickedupLog'] === 3"
                                    [IsAddNew]="false" [DisableSerchFil]="true" [DisableRadioButtons]="true" (SelectedPatient)="selectPatient($event)"
                                    [ClearInputValue]="patientInputvalue"></app-searchbox-global>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <eprx-select [LabelText]="'Drug Class'" [PlaceHolder]="'Drug Class'" [ControlName]="'drugClass'" [FormGroupName]="rxPickUpFG" [List]="drugClassOptions"
                             [BindLabel]="'Name'" [BindValue]="'Id'" [LabelForId]="'Name'" [IsDisabled]="rxPickUpFG.value['IsPickedupLog'] === 3"
                             [HasMultiple]="true" (AddSelectedValue)="addDrugClass($event)" [DropdownPosition]="'top'">
                            </eprx-select>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <eprx-select [LabelText]="'Picked Up From'" [PlaceHolder]="'Picked Up From'" [ControlName]="'PickUpFromId'" [FormGroupName]="rxPickUpFG" [List]="rxPickupFromOptions" [BindLabel2]="'Remarks'" [BindLabel]="'Name'" [BindValue]="'Id'" [LabelForId]="'Remarks'" [HasMultiple]="false" [Show2Values]="true" [DropdownPosition]="'top'">
                            </eprx-select>
                        </div>

                        <div class="col-md-12" >
                            <button
                                *ngIf = "!reportIsInProgress"
                                type="submit"
                                class="hotkey_success pull-right"
                                (click)="previewRxPickupAck(true)"
                                appShortcutKey
                                [AltKey]="'p'"
                                (keydown.enter)="previewRxPickupAck(true)"
                                id="Print"
                            >
                                <span>P</span> Print
                            </button>
                            <button type="submit" class="inactive" *ngIf = "reportIsInProgress" id="Print"  [title] ="reportIsInProgress"
                          >  <span>P</span>Print
                          </button>
                            <button
                                *ngIf = "!reportIsInProgress"
                                type="submit"
                                class="hotkey_success pull-right"
                                (click)="previewRxPickupAck(false)"
                                appShortcutKey
                                [AltKey]="'v'"
                                (keydown.enter)="previewRxPickupAck(false)"
                                id="Preview"
                            > <span>V</span>
                                Preview
                            </button>
                            <button type="submit" class="inactive" *ngIf = "reportIsInProgress" id="Preview" [title] ="reportIsInProgress"
                            > <span>V</span>Preview
                          </button>
                            <button
                                type="submit"
                                class="hotkey_success pull-right"
                                (click)="previewRxPickupAck(false, true)"
                                appShortcutKey
                                [AltKey]="'j'"
                                (keydown.enter)="previewRxPickupAck(false, true)"
                                id="Export" *ngIf = "!reportIsInProgress"
                            > <span>J</span>
                                Export
                            </button>
                            <button type="submit" class="inactive pull-right" *ngIf = "reportIsInProgress" id="Export" [title] ="reportIsInProgress"
                                > <span>J</span>Export
                            </button>
                            <button
                                type="button"
                                class="hotkey_success pull-right"
                                (click)="routeBack()"
                                appShortcutKey
                                [AltKey]="'c'"
                                (keydown.tab)="focusToFirst($event)"
                                (keydown.enter)="routeBack()"
                                id="Cancel" >
                                <span>C</span> Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-8" *ngIf="showPreview">
                <iframe [src]="pdfContent" class="text-center" toolbar="0" width="100%" height="100%"
                    type="application/pdf"></iframe>
            </div>
            <div class="col-md-8" *ngIf="!showPreview">
                <h3 class="reports--condi"> Please click on "PREVIEW" button to see reports </h3>
            </div>
        </div>
    </div>
</div>

<ng-template #insuPopUp let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Please Select Insurance</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="setFocusOnInsuType();d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-4">
                <eprx-input [AutoFocus]="true" [LabelText]="'Filter'" [PlaceHolder]="'Search Category Code'"
                    (TriggerChangeValue)="fiterInsu($event)" [HasControl]="false">
                </eprx-input>
            </div>
        </div>
        <wj-flex-grid #InsuList [headersVisibility]="'Column'" [selectionMode]="'Row'" [itemsSource]="insuWJ" [columnPicker]= "'primeescRxPickUpDetInsuWJ'"
            [isReadOnly]="true" (keydown.space)="preventSpaceEvent($event);selectCode(InsuList.selectedItems[0],null)">
            <wj-flex-grid-column header="Select" [width]="57">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-cell="cell">
                    <span  class="text-center">
                        <input class="form-check-input" type="checkbox" [id]="item.RowId" name=""
                            [(ngModel)]="item.IsSelected" [checked]="item.IsSelected" (click)="selectCode(item,$event)"/>
                        <label class="form-check-label" [for]="item.RowId">
                        </label>
                    </span>
                </ng-template>
            </wj-flex-grid-column>
            <wj-flex-grid-column [header]="rxheader['hName']" [binding]="rxheader['hName']" [visible]="rxheader['isVisible']" [width]="rxheader['width']"
            *ngFor="let rxheader of wjHeaders; let i = index">
            <ng-template  wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-row="row">
                <div *ngIf="(rxheader['hName'] === 'Address')"  title = {{item.FullAddress|uppercase}}>
                    {{ item[rxheader['hName']] }}
                </div>
                <div *ngIf="!(rxheader['hName'] === 'Address' || rxheader['hName'] === 'Phone#')" title = {{item[rxheader.hName]|uppercase}}>
                    {{ item[rxheader['hName']] }}
                </div>
                <span *ngIf="rxheader['hName'] === 'Phone#'" title = {{item[rxheader.hName]}}>
                    {{item[rxheader['hName']] ? (item[rxheader['hName']] | mask : "(000)000-0000") : ""}}
                </span>
            </ng-template>
            </wj-flex-grid-column>
        </wj-flex-grid>
    </div>
    <div class="modal-footer">
        <button type="button" id="custmAdd" class="hotkey_success" (click)="addSelectedValue();c('Close click')" appShortcutKey
            [AltKey]="'a'"><span>A</span> Add Selected</button>
        <button type="button" class="hotkey_success" (click)="setFocusOnInsuType();c('Close click')" appShortcutKey
            [AltKey]="'c'"><span>C</span> Close</button>
    </div>
</ng-template>
<ng-template #payorPopUp let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Please Select Payors</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close"
            (click)="d('Cross click');closeInsPopUp()">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-4">
                <eprx-input [AutoFocus]="true" [LabelText]="'Filter'" [PlaceHolder]="'Search Payor'"
                    (TriggerChangeValue)="fiterPayor($event)" [HasControl]="false">
                </eprx-input>
            </div>
        </div>
        <wj-flex-grid #InsuList [headersVisibility]="'Column'" [selectionMode]="'Row'" [itemsSource]="payorWJ"
            [isReadOnly]="true" [columnPicker]="'rxByInsuPayorPopupListWJ'">
            <wj-flex-grid-column header="Select" [width]="57">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-cell="cell">
                    <span  class="text-center">
                        <input class="form-check-input" type="checkbox" [id]="item.RowId" name=""
                            [(ngModel)]="item.IsSelected" [checked]="item.IsSelected"
                            (click)="selectCode(item,$event)" />
                        <label class="form-check-label" [for]="item.RowId">
                        </label>
                    </span>
                </ng-template>
            </wj-flex-grid-column>
            <wj-flex-grid-column [header]="rxheader['hName']" [binding]="rxheader['hName']" [visible]="rxheader['isVisible']"
                *ngFor="let rxheader of wjHeadersPayor; let i = index" [width]="rxheader['width']">>
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                    <span *ngIf="!(rxheader['hName'] === 'Phone#' || rxheader['hName'] ==='Fax#')"
                        [title]="(item[rxheader['hName']] | uppercase)">
                        {{ item[rxheader['hName']] }}
                    </span>
                    <span *ngIf="(rxheader['hName'] === 'Phone#' || rxheader['hName'] ==='Fax#')">
                        <span *ngIf="rxheader['hName'] === 'Phone#'">
                            {{item[rxheader['hName']] ? (item[rxheader['hName']] | mask : "(000)000-0000") : ""}}
                        </span>
                        <span *ngIf="rxheader['hName'] === 'Fax#'">
                            {{item[rxheader['hName']] ? (item[rxheader['hName']] | mask : "(000)000-0000") : ""}}
                        </span>
                    </span>
                </ng-template>
            </wj-flex-grid-column>
        </wj-flex-grid>
        <div class="ml-2 mt-2 col-lg-12 col-md-12 col-sm-12 col-xs-12 row">
            <label>Selected Payors : </label>
            <div class="label--data">
                {{optedData.length}}
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" id="custmAdd" class="hotkey_success" (click)="c('Close click');selectedPayor()"
            appShortcutKey [AltKey]="'s'"><span>S</span>Select/Unselect Payors</button>
        <button type="button" class="hotkey_success" (click)="c('Close click');closeInsPopUp()" appShortcutKey
            [AltKey]="'c'"><span>C</span> Close</button>
    </div>
</ng-template>
