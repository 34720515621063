import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { ErxVersions, RxChangeDia } from 'src/app/models';
import { AlertService, CommonService, ErxService, InsuranceService, PatPreDrugModalService, RxService } from 'src/app/services';
import { PrintErxService } from 'src/app/services/print-erx.service';
import { NRxUtils, ValidateFormFieldsUtils } from 'src/app/utils';
import { DrugMissingFormComponent } from '../../shared/drug-missing-form/drug-missing-form.component';
import { InsuranceListComponent } from '../../shared/insurance-list/insurance-list.component';
import { FormatsUtil } from 'src/app/utils/formats.util';

@Component({
  selector: 'app-erx-new-request',
  templateUrl: './erx-new-request.component.html',
  styles: []
})
export class ErxNewRequestComponent implements OnInit {
    @Input() RxFGInfo: any;
    @Output() CloseErxRequestModal = new EventEmitter<null>();
    @ViewChild("canDoRxChange", { static: true })
    canDoRxChange: any;
    @ViewChild("INACTIVEINSURANCE", {static: true})
    INACTIVEINSURANCE: any;


    patientSearchValue: any = "";
    prescInputValue: any = "";
    prescriberInfo: any = {};
    isShowDrugValuesPop: boolean = false;
    rxPatientModel: any = {};
    drugInfo: any = {};
    drugInputValue: any = "";
    drugFocus: boolean = false;
    patInsurList: any = [];
    patActvInsurList: any = [];
    patInsurList1: any = [];
    frmInsuFileInact: boolean = false;
    ErxChangeReqFG: FormGroup;

    PrescAddress: any;
    SearchDrug: boolean = false;
    SearchPatient: boolean = false;
    SearchPrecriber: boolean = false;
    selectDrugType: string = "NDC";
    searchPrescriber: boolean = false;
    eRxVersion: any;
    ErxChangeReqFGToSend: FormGroup;
    insuSettings$: Observable<any>;
    primaryInsuFromErxtoRequest: any = {};
    SPIDetails: any = [];
    typedValue: boolean;
    pricSchdlLst$: Observable<any>;
    modalRef: any;
    modalRefForSpi: any;
    canDoReq = false;
    cashIns: any;
    insuranceNameList: any;
    insuranceName: any;
    insuranceList: number;
    warnMsg: any;
    modelRefInsu: any;
    inActvInsu: any;
    onlyCash: boolean = false;
    insuLengthEqual: boolean;
    primaryInsuFromErxtoRequestForCash : any = {};
    unsubscribe$: Subject<void> = new Subject();
  constructor(private _cdr: ChangeDetectorRef, private _fb: FormBuilder,
    private _commonServ: CommonService,  private _modalService: NgbModal,
    private erxService: ErxService, private _alertSvc: AlertService,private _formatsUtil:FormatsUtil,
    private _formValid: ValidateFormFieldsUtils,     private _rxService: RxService,
    private _patPreDrugService: PatPreDrugModalService, private _nrxUtls: NRxUtils,
    private _insServ: InsuranceService,  private _erxServ: PrintErxService) {
      this.createFG();
      this.pricSchdlLst$ = this._commonServ.priceSchedule$;
  }

  ngOnInit() {
    this.getExrSettingVersion();
    this.patchPatientValuesFromNewRx();
  }
  patchPatientValuesFromNewRx() {
      if(this.RxFGInfo && this.RxFGInfo.value && this.RxFGInfo.value.Patient) {
        this.rxPatientModel  = this.RxFGInfo.value.Patient;
        this.SearchPatient  = true;
        this.patientSearchValue =
        this.patchPatientValues();

      }
  }
  getExrSettingVersion() {
    const versionsetting = this._commonServ.getSetttingValue("ERxSettings", "E-PRESCRIPTION_SCRIPT_VERSION");
    if (versionsetting === "2") {
        this.eRxVersion = ErxVersions.NewerVersion;
    } else {
        this.eRxVersion = ErxVersions.OldVersion;
    }
  }
  createFG() {
    this.ErxChangeReqFG = this._fb.group({
        erxId: [],
        PrescRefillId: [],
        PatientId: [],
        PrescriberId: [],
        DrugId: [],
        SPINum: [],
        AWPPack: [],
        SalesTax: [],
        RxId: [],
        drugWithNdc: [],
        drugWitDesc: [],
        currentRefillNo: [],
        Quantity: [],
        SupplyDays: [],
        PharmacistNotes: [],
        SigDesc: [],
        Daw: "Y",
        PrescriptionId: [],
        UnitPriceUNC: [],
        BalDue: [],
        DiscSchId: [],
        NpiNum: [],
        Discount: [],
        OthAMTPaid: [],
        TotalAmt: [],
        groupname: [],
        InsuranceId: [],
        RxChangeReason: [],
        PriceSchId: [],
        RxChangeCategory: [],
        RxChangeSubReason: [],
        IsTypedValue: [],
        DrugCoverageCodes: [],
        DrugDescription: [],
        DrugDescriptionTYpe: [],
        Price: [],
        ndc: [],
        SigCode: [],
        ProfFee: [],
        Sig: [],
        policynum: [],
        prescriberMobile: [],
        Copay: [],
        patientMobile: null,
        insuranceItem: [],
        count: [],
        Dur: this._fb.array([]),
        insuplanId: [],
        PrimaryDiagnosis1: this._fb.group(new RxChangeDia()),
        PrimaryDiagnosis2: this._fb.group(new RxChangeDia()),
        SecondaryDiagnosis1: this._fb.group(new RxChangeDia()),
        SecondaryDiagnosis2: this._fb.group(new RxChangeDia()),
        AlternativeDrugs: this._fb.array([]),
    });
    this.ErxChangeReqFGToSend = this._fb.group({
        erxId: [],
        PrescRefillId: [],
        PatientId: [],
        PrescriberId: [],
        DrugId: [],
        SPINum: [],
        AWPPack: [],
        RxId: [],
        currentRefillNo: [],
        Quantity: [],
        SupplyDays: [],
        PharmacistNotes: [],
        SigDesc: [],
        Daw: [],
        DrugDescription: [],
        PrescriptionId: [],
        NpiNum: [],
        InsuranceId: [],
        RxChangeReason: [],
        RxChangeCategory: [],
        count: [],
        Dur: this._fb.array([]),
        insuplanId: [],
        PrimaryDiagnosis1: this._fb.group(new RxChangeDia()),
        PrimaryDiagnosis2: this._fb.group(new RxChangeDia()),
        SecondaryDiagnosis1: this._fb.group(new RxChangeDia()),
        SecondaryDiagnosis2: this._fb.group(new RxChangeDia()),
        AlternativeDrugs: this._fb.array([]),
    });
    this.setDefaultValues();
    this.ErxChangeReqFG.controls.insuranceItem.valueChanges.pipe(distinctUntilChanged()).pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
            if(res) {
            }
    });
  }



  setDefaultValues() {
    this.ErxChangeReqFG.controls["DrugDescriptionTYpe"].patchValue("NDC");
    this.ErxChangeReqFG.controls["AWPPack"].patchValue("0.00");
    this.ErxChangeReqFG.controls["ProfFee"].patchValue("0.00");
    this.ErxChangeReqFG.controls["Price"].patchValue("0.00");
    this.ErxChangeReqFG.controls["Copay"].patchValue("0.00");
    this.ErxChangeReqFG.controls["OthAMTPaid"].patchValue("0.00");
    this.ErxChangeReqFG.controls["BalDue"].patchValue("0.00");
  }
  changeSPIADD(value) {
    if (value && value.value && value.value["SPINo"]) {
        this.prescriberInfo.prescriberspinum = value.value["SPINo"];
        this.ErxChangeReqFG.controls["SPINum"].patchValue(value.value["SPINo"]);
    }
    if (value && value.value && (value.value["AddressLine1"] || value.value["StateName"])) {
            this.PrescAddress = value.value;
            this.typedValue = false;
        } else {
            this.typedValue = true;
    }
    this.checkServiceLevel(value.value);
}

displayMessage(message) {
    if (message) {
        this._alertSvc.error(
            message , false, "long"
        );
    }
}
  checkSPIServiceLevel(event) {
    event.preventDefault();
    const data = this.ErxChangeReqFG.value;
    if (!(data.PatientId)) {
        this.displayMessage("Please Select Patient Information");
        return;
    }
    if (!(data.PrescriberId)) {
        this.displayMessage("Please Select Prescriber Information");
        return;
    }
    if (!(data.DrugId) && !(data.DrugDescription)) {
        this.displayMessage("Please Select Drug Information");
        return;
    }
    if ((!this.ErxChangeReqFG.valid) || !(this.ErxChangeReqFG.value["SPINum"])) {
        this._formValid.validateAllFormFields(this.ErxChangeReqFG);
        this.displayMessage("Please Enter Mandatory Fields");
    } else {
        if (!this.typedValue) {
            this.SPIDetails.map(val => {
                if (val["SPINo"] === this.prescriberInfo.prescriberspinum) {
                    if (val["ServiceLevel"] && (val["ServiceLevel"].trim() === "1" || val["ServiceLevel"].toLowerCase().trim() === "y")) {
                        const erxServiceType = val["ServiceLevelName"].toUpperCase();
                        if (erxServiceType.includes("NEWRXREQUEST")) {
                            this.canDoReq = true;
                        }
                    }
                }
            });
            if (!this.canDoReq) {
                this.modalRefForSpi =  this._modalService.open(this.canDoRxChange, {
                                    centered: true,
                                    keyboard: false,
                                    backdrop: false,
                                    windowClass: "large--content"
                                });
                    return;
            } else {
                this.sendErxRequest();
            }
        } else {
            this.sendErxRequest();
        }
    }
}
closeModalForSpi() {
    if (this.modalRefForSpi) {
        this.modalRefForSpi.close();
        this.modalRefForSpi = null;
    }
}
refreshSpi() {
    this._erxServ.refreshSpi(this.prescriberInfo.prescribernpinum, this.prescriberInfo.prescriberid, this.eRxVersion).pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            this.SPIDetails  = [];
                this.getSpiList();
        });
    }
    getSpiList() {
        this.erxService.getSpiList({prescriberId: this.prescriberInfo.prescriberid , version : this.eRxVersion}).pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
            this.SPIDetails  = res;
            this.SPIDetails.map((item: any) => {
                if (item && item.IsPrimary) {
                    this.PrescAddress = item;
                    this.ErxChangeReqFG.controls["SPINum"].patchValue(item["SPINo"]);
                }
            });
        });
    }
  selectedPresInfo(val) {
    this._cdr.detectChanges();
    if (val) {
        this.prescriberInfo = val;
        this.getSpiList();
        this.patchPrescriberValues();
        if(this.ErxChangeReqFG.value["DrugId"]) {
            this.drugMissingValuesClosingPop("");
        } else {
            this.focusOnDrug();
        }
    } else {
        this.prescInputValue = "";
        this.prescriberInfo = {};
        setTimeout(() => {
            this.PrescAddress = {};
        }, 200);

        this.ErxChangeReqFG.controls.prescriberMobile.reset();
        this.ErxChangeReqFG.controls.SPINum.reset();
        this.ErxChangeReqFG.controls.PrescriberId.reset();
        this.SearchPrecriber = false;
    }
}
async PatientHist() {
    this._patPreDrugService._patPreDrugModal$.next("patHist");
    const modalRef = this._patPreDrugService.modalInstanceRef;
    modalRef.componentInstance.patientID = null;
    modalRef.componentInstance.popUpOpenFr = "rph";
    modalRef.componentInstance.isFromErxRequest = true;
    modalRef.componentInstance.IsPopUpClosed.pipe(takeUntil(this.unsubscribe$)).subscribe(async res => {
        if (res) {
            if(res.Patient){
                this.rxPatientModel = await this._commonServ.getRxPatientDetailsById(res.Patient.patientid).toPromise()
            }
            this.patientSearchValue =
            (this.rxPatientModel && this.rxPatientModel .fullname) ? ((this.rxPatientModel .fullname).toUpperCase()).replace("_", ", ") : "";
           // this.rxPatientModel  = res.Patient;
            this.patchPatientValues();
            this.prescriberInfo = res.Prescriber;
            this.patchPrescriberValues();
          await  this.getSpiList();
            this.drugInfo  = res.Drug;
            this.patchDrugValue();
            const rxPrescReFill = res.PrescReFill;
            this.ErxChangeReqFG.patchValue({
                Quantity: ( rxPrescReFill && rxPrescReFill.OrdQty) ? (rxPrescReFill.OrdQty.toFixed(2) ) :  "0.00",
                SupplyDays: ( rxPrescReFill && rxPrescReFill.SupplyDays) ? rxPrescReFill.SupplyDays :  "0",
                ProfFee: ( rxPrescReFill && rxPrescReFill.ProfFee) ? (rxPrescReFill.ProfFee.toFixed(2) ) :  "0.00",
                Price: ( rxPrescReFill && rxPrescReFill.Price) ? (rxPrescReFill.Price.toFixed(2) ) :  "0.00",
                TotalAmt: ( rxPrescReFill && rxPrescReFill.TotalAmt) ? (rxPrescReFill.TotalAmt.toFixed(2)) :  "0.00",
                AWPPack: ( rxPrescReFill && rxPrescReFill.UnitPriceAWP) ? (rxPrescReFill.UnitPriceAWP.toFixed(2) ) : "0.00",
                UnitPriceUNC: ( rxPrescReFill && rxPrescReFill.UnitPriceUNC) ? (rxPrescReFill.UnitPriceUNC.toFixed(2) ) :  "0.00",
                SigDesc: ( res && res.SigCodes && res.SigCodes.Description) ? res.SigCodes.Description :  "",
                SigCode: ( res && res.SigCodes && res.SigCodes.SigCode) ? res.SigCodes.SigCode :  "",
                BalDue:  ( rxPrescReFill && rxPrescReFill.BalDue) ? (rxPrescReFill.BalDue.toFixed(2)) :  "0.00",
                DiscSchId : ( rxPrescReFill && rxPrescReFill.DiscSchId) ? rxPrescReFill.DiscSchId :  "0.00",
                PriceSchId : ( rxPrescReFill && rxPrescReFill.PriceSchId) ? rxPrescReFill.PriceSchId :  "0.00",
                Discount : ( rxPrescReFill && rxPrescReFill.Discount) ? rxPrescReFill.Discount :  "0.00",
                RxId : ( res && res.Prescription) ? (res.Prescription.PrescNum)  :  0,
                PrescRefillId : ( res && res.PrescReFill) ? (res.PrescReFill.Id)  :  0,
                currentRefillNo : res.PrescReFill.ReFillNum ,
                Daw : (res.PrescReFill && res.PrescReFill) ? (res.PrescReFill.DAWId === 4 ? "Y" : (res.PrescReFill.DAWId === 3) ? "N" : "Y") : "Y",
                CoPay : (res && res.PrescReFill && res.PrescReFill.CoPay)  ? res.PrescReFill.CoPay  : "0.00",
                OthAMTPaid: ( rxPrescReFill && rxPrescReFill.OtherAmnt) ?
                (((+rxPrescReFill.OtherAmnt) + (+res.otherAmount) + (+res.OtherFee)).toFixed(2)) :  "0.00",
            });
            this.patchInsuranceData();

        }
        modalRef.close();
        this._patPreDrugService._patPreDrugModal$.next(null);
    });
}
closeModal() {
    if (this.modalRef) {
        this.modalRef.close();
        this.modalRef = null;
    }
    this.CloseErxRequestModal.emit();
}

async patchPatientValues() {
    this.ErxChangeReqFG.controls["PatientId"].patchValue(this.rxPatientModel.patientid);
    this.ErxChangeReqFG.controls["patientMobile"].patchValue(this.rxPatientModel.telephone);
    this.patInsurList =  this.rxPatientModel["insurance"];
    this.patInsurList1 = this.patInsurList.filter((item) => item.insurerActiveStatus);
    this.cashIns = this.patInsurList.filter((item) => item.IsCash);
    this.insuranceList = this.patInsurList1.length;
    if (this.insuranceList === 1 && this.cashIns) {
        this.onlyCash = true;
        this.insuranceNameList = this.patInsurList.filter((item: any) => !item.insurerActiveStatus);
        this.insuranceName = this.insuranceNameList && this.insuranceNameList.length > 0 ? this.insuranceNameList[0].insurancename : null;
        this.warnMsg = "<span class='font-weight-bold'>" + (this.insuranceName ? this.insuranceName.toUpperCase() : "")
         + "</span>" + " is marked as Inactive. Setting Bill to as CASH.";
    } else {
        this.insuranceNameList = this.patInsurList.filter((ins: any) => ins.InsuPriority === "Primary");
        this.insuranceName = this.insuranceNameList && this.insuranceNameList.length > 0 ?
            this.insuranceNameList[0].insurancename : null;
        this.warnMsg = "<span class='font-weight-bold'>" + (this.insuranceName ? this.insuranceName.toUpperCase() : "")
        + "</span>" + " is marked as inactive, please select an active insurance to bill.";
    }
    this.patInsurList.map((ins: any) => {
        if (!ins.insurerActiveStatus && ins.InsuPriority === "Primary") {
            this.frmInsuFileInact = true;
        }
    });
}

async getInsuSettings(id) {
  const insuDetails = await this._insServ.getInsSettingsInfo(+id).toPromise();
  this._insServ.saveInsuSettings(insuDetails);
}
patchPrescriberValues() {
    this.ErxChangeReqFG.controls["PrescriberId"].patchValue(this.prescriberInfo.prescriberid);
    this.ErxChangeReqFG.controls["SPINum"].patchValue(this.prescriberInfo.prescriberspinum);
    this.ErxChangeReqFG.controls["prescriberMobile"].patchValue(this.prescriberInfo.telephone);
    this.prescInputValue = null;
    setTimeout(() => {
        this.prescInputValue = this.prescriberInfo.prescriberlastname + ", " + this.prescriberInfo.prescriberfirstname;
        this.SearchPrecriber  = true;
       }, 100);
}
async patchDrugValue() {
    this.ErxChangeReqFG.controls["DrugId"].patchValue(this.drugInfo.id);
    this.drugInputValue = null;
    setTimeout(() => {
        this.drugInputValue =   this.drugInfo.drugname ? this.drugInfo.drugname : "";
        this.SearchDrug = true;
    }, 100);
    this.ErxChangeReqFG.controls["NpiNum"].patchValue(this._formatsUtil.getNDCFormat(this.drugInfo.ndc));

        this.ErxChangeReqFG.value["NpiNum"] = this.drugInfo.ndc;
        this.isShowDrugValuesPop = false;
   setTimeout(() => {
    this.isShowDrugValuesPop = true;
   }, 100);
}

focusOnPatient() {
    this.searchPrescriber = false;
    setTimeout(() => {
        this.searchPrescriber = true;
    }, 200);
}
focusOnPrecriber() {
    this.searchPrescriber = false;
    setTimeout(() => {
        this.searchPrescriber = true;
    }, 200);
}
focusOnDrug() {
    this.drugFocus = false;
    setTimeout(() => {
        this.drugFocus = true;
    }, 200);
}
drugMissingValuesClosingPop(event) {
      if(event && event === "cancel"){
        this.isShowDrugValuesPop = false;
        this.drugInputValue = "";
        this.drugInfo = {};
        this.resetPriceCalculation();
        this.ErxChangeReqFG.controls.NpiNum.reset();
        this.ErxChangeReqFG.controls.DrugId.reset();
        this.ErxChangeReqFG.controls.SupplyDays.reset();
        this.SearchDrug = false;
      }
      else{
        const element:any =   document.getElementById("erxReqQtyNew");
        if(element){
          element.focus();
          element.select();
        }  
      }
}
async selectPatient(patInfo: any) {
    this._cdr.detectChanges();
    if (patInfo) {
        this.rxPatientModel  = patInfo;
        await this.patchPatientValues();
        this.patchInsuranceData();
    } else {
        this.patientSearchValue = null;
        this.rxPatientModel = {};
        this.SearchPatient  = false;
        this.primaryInsuFromErxtoRequest = {};
        this.primaryInsuFromErxtoRequestForCash = {};
        this.ErxChangeReqFG.controls.patientMobile.reset();
        this.ErxChangeReqFG.controls.PatientId.reset();
        this.resetPriceCalculation();
    }
}
setDrugType(type) {
    this.selectDrugType = type;
  this.ErxChangeReqFG.controls["DrugDescriptionTYpe"].patchValue(type);
  if (type === "NDC") {
      this.SearchDrug = false;
    this.focusOnDrug();
    this.ErxChangeReqFG.controls["DrugDescription"].reset();
  } else {
    this.ErxChangeReqFG.controls["DrugId"].reset();
    this.ErxChangeReqFG.controls["NpiNum"].reset();
    this.ErxChangeReqFG.controls["PriceSchId"].reset();
    this.drugInputValue = null;
  }
}

sendErxRequest() {
    this.ErxChangeReqFGToSend.patchValue(this.ErxChangeReqFG.value);
    this.ErxChangeReqFGToSend.value["NpiNum"] = this.drugInfo.ndc;
    this.erxService.postErxRequest(this.eRxVersion, this.ErxChangeReqFGToSend.value).pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
        if (res) {
            this._alertSvc.success(res);
        } else {
            this._alertSvc.success("New RX Request Sent Successfully.");
        }
      this.closeModal();
       this.closeModalForSpi();
    });
}
 async selectDrug(drugInfo: any) {
    this._cdr.detectChanges();
    if (drugInfo) {
        this.drugInfo  = drugInfo;
        await  this.patchDrugValue();
    } else {
        this.drugInputValue = "";
        this.drugInfo = {};
        this.resetPriceCalculation();
        this.ErxChangeReqFG.controls.NpiNum.reset();
        this.ErxChangeReqFG.controls.DrugId.reset();
        this.ErxChangeReqFG.controls.SupplyDays.reset();
        this.SearchDrug = false;

    }
}
resetPriceCalculation ()
{
    this.ErxChangeReqFG.controls["Quantity"].setValue("0.000");
    this.ErxChangeReqFG.controls["AWPPack"].setValue("0.00");
    this.ErxChangeReqFG.controls["UnitPriceUNC"].setValue("0.00");
    this.ErxChangeReqFG.controls["PriceSchId"].setValue(null);
    this.ErxChangeReqFG.controls["DiscSchId"].setValue(null);
    this.ErxChangeReqFG.controls["Price"].setValue("0.00");
    this.ErxChangeReqFG.controls["OthAMTPaid"].setValue("0.00");
    this.ErxChangeReqFG.controls["Discount"].setValue(null);
    this.ErxChangeReqFG.controls["BalDue"].setValue("0.00");
    this.ErxChangeReqFG.controls["ProfFee"].setValue("0.00");
    this.ErxChangeReqFG.controls["TotalAmt"].setValue("0.00");
    this.ErxChangeReqFG.controls["Copay"].setValue("0.00");

}

async closeInactModal() {
    if (this.modelRefInsu) {
        this.modelRefInsu.close();
        this.modelRefInsu = null;
    }
    this.patInsurList = await this._nrxUtls.getSortedInusuDtls(this.patInsurList);
    this.patActvInsurList = this.patInsurList.filter((item: any) => item.insurerActiveStatus);
    this.insuLengthEqual = (this.patActvInsurList.length === this.patInsurList.length) ? true : false;
    if (this.patInsurList && this.patInsurList.length && this.patActvInsurList && this.patActvInsurList.length) {
        this.ErxChangeReqFG.controls["InsuranceId"].patchValue((this.insuLengthEqual) ?
        this.patInsurList[0]["insuranceid"] : this.patActvInsurList[0]["insuranceid"]);
        this.ErxChangeReqFG.controls["policynum"].patchValue((this.insuLengthEqual) ?
        this.patInsurList[0]["policynum"] : this.patActvInsurList[0]["policynum"]);
        this.ErxChangeReqFG.controls["groupname"].patchValue((this.insuLengthEqual) ?
            this.patInsurList[0]["groupname"] : this.patActvInsurList[0]["groupname"]);
        this.ErxChangeReqFG.controls["insuranceItem"].patchValue((this.insuLengthEqual) ?
            this.patInsurList[0].insuType : this.patActvInsurList[0].insuType);
        this.getInsuSettings((this.insuLengthEqual) ? this.patInsurList[0]["insuplanId"]
        : this.patActvInsurList[0]["insuplanId"]);
        this.primaryInsuFromErxtoRequest = {insuType : this.ErxChangeReqFG.value["insuranceItem"],
         policynum : this.ErxChangeReqFG.value["policynum"] , groupname: this.ErxChangeReqFG.value["groupname"]};
    }
    this.patientSearchValue =
    this.rxPatientModel .fullname ? ((this.rxPatientModel .fullname).toUpperCase()).replace("_", ", ") : "";
    this.SearchPatient  = false;
    this.frmInsuFileInact = false;
    if (!this.onlyCash) {
        this.openInsuranceModal();
    }
}

openInsuranceModal() {
    this.modalRef = this._modalService.open(InsuranceListComponent, {backdrop: false, keyboard: false, size: "lg", centered : true});
    this.modalRef.componentInstance.patInsurList = this.patInsurList;
    this.modalRef.componentInstance.erxObj = this.ErxChangeReqFG;
    this.modalRef.componentInstance.inActInsu = this.inActvInsu;
    this.modalRef.componentInstance.closeInusranceModal.pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
        if (this.ErxChangeReqFG.value["PrescriberId"]) {
            this.focusOnDrug();
        }  else if (this.ErxChangeReqFG.value["DrugId"]) {
            this.drugMissingValuesClosingPop("");
        }  else {
            this.focusOnPrecriber();
        }
        if (res && res.InsuranceId) {
            this.primaryInsuFromErxtoRequest = res;
            this.ErxChangeReqFG.controls["InsuranceId"].patchValue(res.InsuranceId);
            this.ErxChangeReqFG.controls["patientMobile"].patchValue(this.rxPatientModel.telephone);
            this.modalRef.close();
        } else {
            this.modalRef.close();
        }
    });
}
patchInsuranceData(){
    if (this.frmInsuFileInact) {
        this.inActvInsu = true;
        this.modelRefInsu = this._modalService.open(this.INACTIVEINSURANCE,
            {keyboard: false, backdrop: false, windowClass: "large--content"});
    }
    if (this.patInsurList  && this.patInsurList .length && this.patInsurList.length > 1 && !this.frmInsuFileInact) {
        this.inActvInsu = false;
        this.onlyCash = false;
        this.closeInactModal();
    } else {
        this.ErxChangeReqFG.controls["InsuranceId"].patchValue((this.patInsurList) ?
        this.patInsurList[0]["insuranceid"] : this.patActvInsurList[0]["insuranceid"]);
        this.primaryInsuFromErxtoRequest =  this.ErxChangeReqFG.value;
        this.primaryInsuFromErxtoRequestForCash = this.patInsurList[0]
        if(this.ErxChangeReqFG.value["PrescriberId"]) {
            this.focusOnDrug();
        } else {
            this.focusOnPrecriber();
        }
    }
}
checkServiceLevel(val) {
    if (!this.prescriberInfo?.prescriberfirstname) {
        return null
    } else if (val && val["ServiceLevel"] && (val["ServiceLevel"].trim() === "1" || val["ServiceLevel"].toLowerCase().trim() === "y")) {
        if (val["ServiceLevelName"].includes("NEWRXREQUEST")) {
            return true;
        } else return false;
    } else return false;
}

ngOnDestroy(){
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
}
}
