import { Component, OnInit, ViewChild, Input, HostListener, Output, EventEmitter } from "@angular/core";
import { AlertService } from "src/app/services";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PrimeEscService } from "src/app/services/prime-esc.service";
import { PrintService } from "src/app/services/print.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";


@Component({
    selector: "app-patient-sign-record",
    templateUrl: "./patient-sign-record.component.html"
})
export class PatientSignRecordComponent implements OnInit {

    @ViewChild("PatSignTemp", { static: true })
    PatSignTemp: any;
    modalRef: any;
    actvModal: string;
    patientInfo: any;
    signatureData: any;
    imageToShow: any = null;
    sigType: any;
    signId: any;
    refillId: any = null;
    isCurrentRxOnlyShown: boolean = false;
    information: any;
    rphData: any;
    rxNumberData: any;
    unsubscribe$: Subject<void> = new Subject();

    @Input()
    set PatientInfo(patInfo: any) {
        this.patientInfo = patInfo;
    }
    @Input()
    set RefillId(refillId: any) {
        this.refillId = refillId;
    }
    @Input()
    set RPH(rph: any) {
        this.rphData = rph;
    }

    @Input()
    set SignatureData(patInfo: any) {
        this.signatureData = patInfo;
    }

    @Input()
    set rxNumber(rxnumber: any) {
        this.rxNumberData = rxnumber;
    }

    @Input()
    set SigType(patInfo: any) {
        this.sigType = patInfo;
    }

    @Input()
    set SignId(patInfo: any) {
        this.signId = patInfo;
    }

    @Output()
    IsPopupClosed = new EventEmitter<null>();

    @HostListener("window:keydown", ["$event"])
    keyEventAlt(event: KeyboardEvent) {
        if (event.which === 27 && this._modalService.hasOpenModals()) {
            this.closeModal();
            event.preventDefault();
        }
    }
    constructor(
        private _alertSrv: AlertService,
        private _modalService: NgbModal,
        private _printSer: PrintService,
        private _primeEscSer: PrimeEscService) { }

    ngOnInit() {
        this.openPatientSign();
    }

    openPatientSign() {
        this.modalRef = this._modalService.open(this.PatSignTemp, {
            size: "lg",
            backdrop: false,
            keyboard: false,
            centered : true
        });
        this.actvModal = "Patient Sign";
        this.information = this.signatureData.Information;
    }
    showCurrentRxOnly() {
        if(this.signatureData && this.signatureData.Information) {
            let copyInformation = this.signatureData.Information.split(",");
            this.information =  (this.isCurrentRxOnlyShown) ? this.rxNumberData :  this.signatureData.Information;
        }
    }
    print() {
        this.refillId = (this.isCurrentRxOnlyShown) ?  this.refillId :  null;
        this._primeEscSer.getPatientSignPrint(this.signId, this.sigType, this.refillId).pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp && resp.size > 0 && resp.type === "application/pdf") {
                    const newBlob = new Blob([resp], { type: "application/pdf" });
                    const reader = new FileReader();
                    reader.readAsDataURL(newBlob);
                    reader.onloadend = function () {
                        const base64data = reader.result;
                        this._printSer.printPdf(base64data);
                        this.closeModal();
                    }.bind(this);
                } else {
                    this._alertSrv.error("Print Unsuccessfull");
                }
            });
    }

    closeModal() {
        if (this.modalRef) {
            this.modalRef.close();
            this.IsPopupClosed.emit(null);
        }
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
