import { Component, OnInit, Input } from "@angular/core";
import { DrugTabUtils } from "src/app/utils/drug-tabs.utils";
import * as wjcGrid from "@grapecity/wijmo.grid"
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormGroup } from "@angular/forms";
import { AlertService } from "src/app/services";
import { MsgConfig } from "src/app/app.messages";
import { DrugBucketPrice, PaginationModel } from "src/app/models";
import { CommonUtil } from "src/app/utils";

@Component({
    selector: "app-drug-bucket-pricing",
    templateUrl: "./drug-bucket-pricing.component.html"
})
export class DrugBucketPricingComponent implements OnInit {
    paginationModel: PaginationModel = new PaginationModel();
    @Input() drugId: any;
    pageNu = this.paginationModel.pageNumber;
    Size = 15;
    modal: any;
    activeModal: string;
    bucketlist: any;
    bucketFG: FormGroup;
    formGroupInvalid: any;
    deleteData: any;
    bucketPriceData: { count: number; wjSource: any; result: any; } = { count: null, wjSource: null, result: null };
    warningText = {
        deleteHeaderTxt: null,
        deleteConfrmMsg: null
    }

    constructor(private _drugTabUtil: DrugTabUtils, private _comnUtil: CommonUtil,
         private _modalSvc: NgbModal, private _altSvc: AlertService) { }

    ngOnInit() {
        this.getBucket();
        this.warningText.deleteHeaderTxt = MsgConfig.Delete_Header_Text;
        this.warningText.deleteConfrmMsg = MsgConfig.Delete_Confirmation;
    }

    async getBucket() {
        this.bucketlist = await this._drugTabUtil.getBucketList();
        this.getBucketPrice();
    }

    focusToSave(event, type) {
        if ((type === "Add" && !this.bucketPriceData["count"]) || !type) {
            this._comnUtil.focusById("saveButton", event);
        }
    }

    async getBucketPrice() {
        this.bucketPriceData = await this._drugTabUtil.getDrugBuckpriceData(this.drugId, this.pageNu, this.Size);
    }

    init(flex: wjcGrid.FlexGrid) {
        flex.columnHeaders.rows[0].wordWrap = true;
    }

    setPage(page: number) {
        this.pageNu = page;
        this.getBucketPrice();
    }
    setSize(size: any) {
        this.pageNu = this.paginationModel.pageNumber;
        this.Size = size;
        this.getBucketPrice();
    }

    openAddModal(content) {
        this.bucketFG = this._drugTabUtil.createBucketFG();
        this.modal = this._modalSvc.open(content, {
            centered: true,
            keyboard: false,
            backdrop: false
        });
        this.activeModal = "Add";
    }

    openEditModal(content, item) {
        const selectedItem = this.bucketPriceData["result"].find(val => val.Id === item["Id"]);
        this.bucketFG = this._drugTabUtil.createAndPatchBucketFG(selectedItem);
        this.bucketFG.controls["UnitPrice"].patchValue(selectedItem["UnitPrice"]
        ? (parseFloat(selectedItem["UnitPrice"])).toFixed(3) : "0.000");
        this.modal = this._modalSvc.open(content, {
            centered: true,
            keyboard: false,
            backdrop: false
        });
        this.activeModal = "Update";
    }

    deletePopUpModal(content, data: any) {
        const selectedData = new DrugBucketPrice();
        const valueCntrls = { Id: "Id", DrugBucketId: "bucketId", UnitPrice: "Unit Pricing", IsActive: false };
        Object.keys(valueCntrls).map(cntrl => {
            selectedData[cntrl] = cntrl === "IsActive" ? false : data[valueCntrls[cntrl]];
        });
        this.deleteData = selectedData;
        this.modal = this._modalSvc.open(content, {
            centered: true,
            keyboard: false,
            backdrop: false,
            windowClass: "large--content"
        });
        this.activeModal = "Delete";
    }

    setNumberFormat(ControlName) {
        this.bucketFG.controls[ControlName].patchValue((Number(this.bucketFG.value[ControlName])).toFixed(3));
    }

    checkBucketValid() {
        if (this.bucketFG.valid) {
            this.formGroupInvalid = false;
            this.bucketFG.patchValue({
                DrugId: this.drugId,
                IsActive: true
            });
            const dataInfo = this.bucketFG.value;
            dataInfo["UnitPrice"] = parseFloat(dataInfo["UnitPrice"]);
            (this.activeModal === "Add") ? this.addBucketList(dataInfo) : this.updateBucketList(dataInfo);
        } else {
            this.formGroupInvalid = true;
        }
    }

    async addBucketList(data) {
        const response = await this._drugTabUtil.addBucketprice(data);
        if (response) {
            if (this.modal) {
                this.modal.close();
            }
            this.getBucketPrice();
        } else {
            this._altSvc.error(MsgConfig.SAVE_FAIL);
        }
    }

    async updateBucketList(data) {
        const response = await this._drugTabUtil.editBucketprice(data);
        if (response) {
            if (this.modal) {
                this.modal.close();
            }
            this.getBucketPrice();
        } else {
            this._altSvc.error(MsgConfig.UPDATE_FAIL);
        }
    }

    async deleteBucketList() {
        const response = await this._drugTabUtil.deleteBucketprice(this.deleteData);
        response ? this.getBucketPrice() : this._altSvc.error(MsgConfig.DELETE_FAIL);
    }

}
