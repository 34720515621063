import { Component, OnInit, Output, Input, EventEmitter, ViewChild, OnChanges, ChangeDetectorRef } from "@angular/core";
import { DrugTabTitles } from "../drug.constants";
import { AlertService } from "src/app/services";
import { PrivMaskGuard } from "src/app/guards";
import { MsgConfig } from "src/app";
import { AccCategoryE, FileMaintenance1E, DrugData } from "src/app/models";
import { FormGroup } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CommonUtil } from "src/app/utils";

@Component({
  selector: "app-drug-tab",
  templateUrl: "./drug-tab.component.html"
})
export class DrugTabComponent implements OnInit, OnChanges {
    accessPrivCate: any;
    drugNotesPrivs: any;

    @Input() drugInfo: DrugData;
    @Input() drugId: number;
    @Input() drugFG: FormGroup;
    @Input() resetToFirst: boolean;

    @Output()
    DrugActiveTab = new EventEmitter();

    @Output() addDrugResponse = new EventEmitter<any>();

    @ViewChild("warning", { static: true })
    warning: any;

    activeTabId: any;
    selectedTab: string;

    @Input()
    ActiveTabId(data: any) {
        this.activeTabId = DrugTabTitles[data];
    }

    constructor(private _alertServ: AlertService, private _privMaskGuard: PrivMaskGuard,
        private _modalSvc: NgbModal, public _commonUtil: CommonUtil, private _cdr: ChangeDetectorRef) {
        this.accessPrivCate = AccCategoryE;
        this.drugNotesPrivs = FileMaintenance1E;
    }

    ngOnInit() {
    }

    ngOnChanges(changes?: any) {
        if (changes) {
            if (changes.resetToFirst && changes.resetToFirst.currentValue) {
                if (this.resetToFirst) {
                    this.activeTabId = null;
                    this._cdr.detectChanges();
                    this.activeTabId = "Patient1";
                    this.DrugActiveTab.emit(this.activeTabId);
                }
            }
        if (changes.ActiveTabId && changes.ActiveTabId.currentValue) {
                    this.activeTabId = null;
                    this._cdr.detectChanges();
                    this.activeTabId = DrugTabTitles[changes.ActiveTabId.currentValue];
                    
            } 
        }
    }

    beforeChange(nextId: string) {
        if (nextId && this.drugId) {
            if (nextId === "Patient13") {
                if (
                    this.checkHasPrivsToAccess(
                        this.accessPrivCate.FileMaintenance1,
                        this.drugNotesPrivs.DrugNotesLog
                    )
                ) {
                    this.DrugActiveTab.emit(nextId);
                } else {
                    this._alertServ.error(MsgConfig.NO_PRIVS_ACCESS, false, "long");
                }
            } else {
                this.DrugActiveTab.emit(nextId);
            }
        } else {
            if (this.drugFG.valid && parseFloat(this.drugFG.value["Drug"]["UnitPriceAWP3Digits"]) !== 0) {
                this.selectedTab  = nextId;
                this._modalSvc.open(this.warning, {centered: true, backdrop: false, windowClass: "large--content"});
            } else {
                this.activeTabId = null;
                this._cdr.detectChanges();
                this.activeTabId = "Patient1";
                this.addDrugResponse.emit(nextId);
            }
        }
    }

    checkHasPrivsToAccess(category: string, privType: string) {
        return this._privMaskGuard.canActivate(category, privType);
    }

    emitToAddDrug() {
        this.addDrugResponse.emit(this.selectedTab);
    }

    closeWarning() {
        this.activeTabId = null;
        this._cdr.detectChanges();
        this.activeTabId = "Patient1";
        this.DrugActiveTab.emit("Patient1");
    }

}
