<div class="exprx--common-block">
    <div class="eprx--block__header">
        <div class="eprx--header__heading">
            Drug Codes
            <ul class="pull-right eprx--header__icons">
                <li>
                    <img src="assets/dist/Icons/icon_pills1_white.svg" title="Drug Indic" (click)="openModal(drugindication)">
                </li>
                <li>
                    <img src="assets/dist/Icons/icon_pills2_white.svg" title="Drug Couns"
                        (click)="openModal('DrugCounselling')">
                </li>
                <li  class="padding_top_0_2rem">
                    <i class="far fa-print text-white action pull-right font_size_1_5rem p-1" title="Print Med Guide"
                        (click)="printMedGuide(MedGuide)"></i>
                </li>
            </ul>
        </div>
    </div>
    <div class="eprx--block__content">
        <div class="row">
            <div class="'col-lg-2 col-md-2 col-sm-2 col-xs-2'">
                <eprx-input [LabelText]="'Therapeutic ID'" [AutoFocus]="true" [PlaceHolder]="'Therapeutic ID'"
                    [ControlName]="'Therapeutic'" [FormGroupName]="drugFG?.get('Drug')" [MaskPattern]="'0*'"
                    [RxSelectId]="'Therapeutic'" [MaxLength]="8" [IsRequired]="true"
                    [ErrorDefs]="{required: 'Required'}" (TriggerOnTabbing)="checkFGDirty('Therapeutic', $event)"
                    (TriggerOnEnterValue)="checkFGDirty('Therapeutic', $event)" (TriggerOnBlur)="checkFGDirty('Therapeutic', $event)"
                    (TriggerAltNdShortcutkeysClicked)="checkFGDirty('Therapeutic', $event)">
                </eprx-input>
            </div>
            <div class="'col-lg-2 col-md-2 col-sm-2 col-xs-2'">
                <eprx-input [LabelText]="'Specific Product ID'" [PlaceHolder]="'Specific Product ID'" [RxSelectId]="'TherapeuticCode'"
                    [ControlName]="'TherapeuticCode'" [FormGroupName]="drugFG?.get('Drug')" [MaskPattern]="'0*'"
                    [MaxLength]="8" [IsRequired]="true" [ErrorDefs]="{required: 'Required'}" (TriggerOnTabbing)="checkFGDirty('TherapeuticCode', $event)"
                    (TriggerOnEnterValue)="checkFGDirty('TherapeuticCode', $event)" (TriggerOnBlur)="checkFGDirty('TherapeuticCode', $event)"
                    (TriggerAltNdShortcutkeysClicked)="checkFGDirty('TherapeuticCode', $event)">
                </eprx-input>
            </div>
            <div class="'col-lg-2 col-md-2 col-sm-2 col-xs-2'" *ngIf="drugFG?.get('Drug').value['IsWarningCodeChkd']">
                <eprx-input [LabelText]="'Warning Code'" [PlaceHolder]="'WarningCode'"
                    [ControlName]="'WarningCode'" [FormGroupName]="drugFG?.get('Drug')">
                </eprx-input>
            </div>
            <div class="'col-lg-2 col-md-2 col-sm-2 col-xs-2'" *ngIf="!drugFG?.get('Drug').value['IsWarningCodeChkd']">
                <eprx-input [LabelText]="'Warning Code'" [TabIndex]="-1" [PlaceHolder]="'WarningCode'"
                [HasControl]="false" [ReadOnly]="true">
                </eprx-input>
            </div>
            <div class="'col-lg-2 col-md-2 col-sm-2 col-xs-2'">
                <label class=" col-12 padding--left__7">Display Warning Codes?</label>
                <eprx-radio [ControlName]="'IsWarningCodeChkd'" [FormGroupName]="drugFG?.get('Drug')"
                    [IsRequired]="true" [List]="['Yes', 'No']" [ValueList]="[true, false]"
                    [IDForList]="['Warn1', '0']" [LabelForId]="" (keydown.tab)="focusToSave($event)">
                </eprx-radio>
            </div>
            <div class="'col-lg-2 col-md-2 col-sm-2 col-xs-2'">
                <label class="align-label padding_bottom_1px"> Code Information </label>
                <textarea [rows]="2" [cols]="15" class="text-area h-50 w-100" readonly
                    [value]="counselingInfo?.result?.Codedescription"></textarea>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" *ngIf="!drugFG?.get('Drug').value['IsWarningCodeChkd']">
                <label class="align-label"> Drug Warnings </label>
                <wj-flex-grid #warInfo [isReadOnly]="true" [headersVisibility]="'Column'" [columnPicker]="'drugCounsellingWarningListWJ'"
                    [itemsSource]="counselingInfo?.drugWarnItemS"
                    [ngClass]="[!totalCount ? 'no-data height_20rem' : 'height_20rem']"
                    [selectionMode]="'None'" [allowDragging]="'None'" (loadedRows)="loadedWJRows(warInfo)">
                    <wj-flex-grid-column [header]="rxheader['hName']" [binding]="rxheader['hName']" [visible]="rxheader['isVisible']"
                        *ngFor="let rxheader of wjHeadersWarn; let i = index" [width]="rxheader['width']" [wordWrap]="true">
                    </wj-flex-grid-column>
                    <app-no-data-found *ngIf="!counselingInfo?.drugWarnItemS"></app-no-data-found>
                </wj-flex-grid>
            </div>
            <div class="text-right pb-2 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <button class="hotkey_success" (click)="getCounsellingData(true)">English</button>
                <button class="hotkey_success" (click)="getCounsellingData(false)">Spanish</button>
            </div>


        </div>
    </div>
</div>
<div class="exprx--common-block">
    <div class="eprx--block__header">
        <div class="eprx--header__heading">
            Dosage Info
        </div>
    </div>
    <div class="eprx--block__content">
        <wj-flex-grid #dosageInfo [isReadOnly]="true" [headersVisibility]="'Column'" [columnPicker]="'drugCounsellingListWJ'" id="drugCouncellingId"
            [itemsSource]="counselingInfo?.dosageItemS" [selectionMode]="'None'" 
            [ngStyle]="{'height': !drugFG?.get('Drug').value['IsWarningCodeChkd']? '16rem': '30rem'}" (initialized)="init(dosageInfo)">
            <wj-flex-grid-column [header]="rxheader['hName']" [binding]="rxheader['hName']" [visible]="rxheader['isVisible']" [width]="rxheader['width']"
                *ngFor="let rxheader of wjHeaders; let i = index">
            </wj-flex-grid-column>
            <app-no-data-found *ngIf="!counselingInfo?.dosageItemS"></app-no-data-found>
        </wj-flex-grid>
    </div>
</div>
<ng-template #MedGuide let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModal()">
        <h4 header> Med Guide</h4>
        <span body>
            <div class="col-md-12">
                <iframe [src]="pdfContent" class="text-center" toolbar="0" width="100%" height="550"
                    type="application/pdf"></iframe>
            </div>
        </span>
        <button footer class="hotkey_primary" (click)="print()" appShortcutKey InputKey="p"><b>P</b> Print </button>
        <button footer class="hotkey_primary" (click)="closeModal()" appShortcutKey InputKey="c"><b>C</b> Close
        </button>
    </app-custom-modal>
</ng-template>

<app-drug-counselling [SystemData]="systemData" [drugFG]="drugFG" [drugId]="drugId" (CloseDrugCounselPopUp)="closeModal($event)"
    *ngIf="activeModal === 'DrugCounselling'"></app-drug-counselling>

<ng-template #drugindication let-c="close" let-d="dismiss" class="el--popup">
    <div>
        <div class="modal-header">
            <h4 class="modal-title">Drug Indication</h4>
            <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true" class="close-button">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="col-xs-12">
                <label> Indications </label>
                <div class="col-xs-12 height_20rem overflow_y_scroll">
                    <table class="table table-bordered">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col"> Description </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let indication of counselingInfo?.result?.Indications">
                                <td scope="row"> {{indication?.IndDescription}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <label  class="padding_top_1rem"> Beer Information </label>
                <div class="col-xs-12 height_20rem overflow_y_scroll">
                    <table class="table table-bordered">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col"> CAUTION </th>
                                <th scope="col"> EVIDENCE QUALITY </th>
                                <th scope="col"> RECOMMENDATION </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let caution of counselingInfo?.result?.Caution">
                                <th scope="row"> {{caution.AVOIDCAUTION.split(".")}}</th>
                                <td>{{caution.QUALITYOFEVIDENCETEXT}}</td>
                                <td>{{caution.STRENGTHOFRECOMMENDATIONTEXT}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <div class="text-right pull-right col-md-4 padding-0">
                <button class="hotkey_primary" (click)="getPregrancyInfo(DrugPregnency)" appShortcutKey
                    InputKey="p"><b>P</b> Pregnancy Information</button>
                <button class="hotkey_primary mr-0" (click)="d('Cross click')" appShortcutKey
                    InputKey="c"><b>C</b> Cancel </button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #DrugPregnency let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModal()">
        <h4 header> Pregnency and Lactation Information </h4>
        <span body>
            <div class="col-xs-12">
                <label> Pregnancy Information </label>
                <div class="col-xs-12">
                    <table class="table table-bordered">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col"> TRIMESTER </th>
                                <th scope="col"> PREGNANCY RATING CODE </th>
                                <th scope="col"> RATING DESCRIPTION </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let pregnancyInfo of drugPregInfo?.dtPregnancyInformation">
                                <th scope="row"> {{pregnancyInfo?.TRIMESTER}}</th>
                                <td>{{pregnancyInfo?.PREGNANCYRATINGCODE}}</td>
                                <td>{{pregnancyInfo?.RATINGDESCRIPTION}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <label> LACTATION Information </label>
                <div class="col-xs-12">
                    <table class="table table-bordered">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col"> IS SAFE FOR CHILD BEARING AGE </th>
                                <th scope="col"> IS SAFE FOR LABOUR AND DELIVERY </th>
                                <th scope="col"> LACTATION RATING </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let lactationInfo of drugPregInfo?.dtLactationInformation">
                                <th scope="row"> {{lactationInfo['SAFE FOR CHILD BEARING AGE']}}</th>
                                <td>{{lactationInfo['SAFE FOR LABOR AND DELIVERY']}}</td>
                                <td>{{lactationInfo['LACTATION RATING']}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <label> ALTER LACTATION DRUGS ( LACTATION RATING= 'AVOID' OR LACTATION RATING="CAUTION") </label>
                <div class="col-xs-12">
                    <table class="table table-bordered">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col"> ALTERNATE PRODUCTION NAME </th>
                                <th scope="col"> ALTERNATE NDCB </th>
                                <th scope="col"> LACTATION RATING </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let lactationInfo of drugPregInfo?.dtLactationInformation">
                                <th scope="row"> {{lactationInfo['SAFE FOR CHILD BEARING AGE']}}</th>
                                <td>{{lactationInfo['SAFE FOR LABOR AND DELIVERY']}}</td>
                                <td>{{lactationInfo['LACTATION RATING']}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </span>
        <button footer type="submit" autofocus class="hotkey_primary" (click)="closeModal()" appShortcutKey
            InputKey="o"><b>O</b> OK </button>
    </app-custom-modal>
</ng-template>
