<div class="exprx--common-block">
    <div class="eprx--block__content">
        <div class="row" *ngIf="dispHistData['count']">
            <div class="col-10 text-right pull-right">
                <label class="col-12 padding-0">
                    Dispensing history for all equivalent drugs
                </label>
            </div>
            <div class="col-1 text-right pull-right">
                <eprx-radio [ControlName]="'includeEquivalentDrugs'" [FormGroupName]="dispHisFG" [AutoFocus]="true"
                    [IsRequired]="true" [List]="['Yes', 'No']" [ValueList]="[true, false]"
                    [IDForList]="['Comp', 'All']" [LabelForId]="" (TriggerSelectValue)="getDrugDispHisData()">
                </eprx-radio>
            </div>
        </div>
        <div class="text-right" *ngIf="dispHistData['count']">
            <button  class="submit print-med-guide background_orange"  (click)="toggleColorLegend()">Color Legend</button>
        </div>
        <wj-flex-grid #inventorylist [itemFormatter]="itemFormatter" [headersVisibility]="'Column'" [itemsSource]="dispHistData['wjSource']" [isReadOnly]="true"
            [columnPicker]="'drguDispHistoryListWJ'" [allowSorting]="true" [selectionMode]="'Row'" [ngClass]="[!isDataExists ? 'no-data' : '']">
            <wj-flex-grid-column [header]="'Action'" [width]="'0.6*'">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                    <i class="far fa-edit actions" title="Edit" (click)="routeToEditRx(item)"></i>
                </ng-template>
            </wj-flex-grid-column>
            <wj-flex-grid-column [header]="rxheader['hName']" [binding]="rxheader['hName']" [visible]="rxheader['isVisible']"
                *ngFor="let rxheader of wjHeaders; let i = index" [width]="rxheader['width']"
                [format]="rxheader['hName'] === 'TotalAmount' || rxheader['hName'] === 'Patient Copay' ? 'c2' : 'd'"
         >
         <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                <div *ngIf="(rxheader['hName'] === 'Rx#')"
                [ngbTooltip]="item['RxHoverOver']" placement="top"
                triggers="mouseover:mouseleave" container="body" tooltipClass="tooltip-title" container="body">
                {{ item[rxheader['hName']] }}
            </div>
            <div  *ngIf="!(rxheader['hName'] === 'Status' || rxheader['hName'] === 'Rx#')">
                {{ item[rxheader['hName']] }}
            </div>
            <div *ngIf="(rxheader['hName'] === 'Status')" [ngbTooltip]="statusToolTip" placement="top" 	triggers="mouseover:mouseleave" container="body" tooltipClass="auto-title-tooltip" container="body">
                {{ item[rxheader['hName']] }}
            </div>
            <ng-template #statusToolTip>
            <div *ngIf="item['Status'] === 'D'" class="text-left">
            <span [innerHTML] = "item['File Due Date'] ? item['FileDueDateHover'] : item['StatusHoverOver']"></span><br/>
            <span [innerHTML] = "item['Defer Reason'] ? item['DeferReasonHover'] : ''"></span>
            </div>
            <div *ngIf="item['Status'] !== 'D'">
                    {{ item['StatusHoverOver'] }}
            </div>
            </ng-template>
            </ng-template>
            </wj-flex-grid-column>
            <app-no-data-found *ngIf="!isDataExists"></app-no-data-found>
        </wj-flex-grid>
        <app-eprx-pagination [FromModal]="true" [TotalCount]="dispHistData['count']" [PageSize]="dispHisFG.value['pagesize']" [WijmoName]="flex"
            [GridName]="'dispHistory'" (OnPageSelected)="setPage($event)" (OnSizeSelected)="setSize($event)"
            *ngIf="dispHistData['count']" (OnFocusOut)="focusToSave($event)"></app-eprx-pagination>
    </div>
</div>
<app-override-log
    *ngIf="openOverride"
    [PrivMask]="'EditRx'"
    [CatId]="'Rx'"
    (OverrideLoginDet)="OverrideRes($event)"
></app-override-log>
