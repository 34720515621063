import { OnInit, Component } from "@angular/core";
@Component({
  selector: "app-tools",
  templateUrl: "./tools.component.html"
})
export class ToolsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
