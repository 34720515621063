<ng-template #sales let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">Drug Sales Summary</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="exprx--common-block  newrx--body edit--drug" *ngIf="salesData['salesResponse']">
            <div class="box  box-solid">
                <div class="box-body exprx--common-block">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-5">
                                    <div class="col-12 row">
                                        <label class="align-label">
                                            <h3>Drug Name:&nbsp;</h3>
                                        </label>
                                        <div class="label--data">
                                            <h3>
                                            {{ drugInfo?.Drug?.Name | uppercase}}
                                            {{ drugInfo?.Drug?.Strength ? drugInfo?.Drug?.Strength : "" | uppercase  }}
                                            {{ drugInfo?.Drug?.DrugFormName ? drugInfo?.Drug?.DrugFormName : "" | uppercase}}
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 row">
                                    <div class="col-12 row">
                                        <label class="align-label--more">
                                            <h3>Drug NDC:&nbsp;</h3>
                                        </label>
                                        <div class="label--data">
                                            <h3>{{this._formatsUtil.getNDCFormat(drugInfo?.Drug?.DrugCode)}}</h3>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <eprx-check-box [LabelText]="'Show Sales Summary For All Equivalents Drugs'"
                                        [ControlName]="'showEquivalents'" [FormGroupName]="DrugSalesFG"
                                        (TriggerChangeValue)="getDefaultSales()">
                                    </eprx-check-box>
                                </div>
                                <!-- <div class="col-md-3">
                                    <label class="align-label"> Drug Manufacturer </label>
                                    <div class="label--data">
                                        {{drugInfo?.Drug?.Name ? (drugInfo?.Drug?.Name | uppercase) : "--"}}
                                    </div>
                                </div> -->
                            </div>
                        </div>
                        <div class="mt-4 col-md-12 row">
                            <div class="salesrht"></div>
                            <div class="col-md-3 inventory  drug-inven inventory--color2 padding--left__7 padding--right__7">
                                <div class="inv--information">
                                    <div class="inv--value">
                                        <div class="inv--description">
                                            <h5>Total Rx Count : </h5>
                                            <span><h3>{{ salesData['RxCount'] }}</h3></span>
                                        </div>
                                    </div>
                                    <div class="inv--value padding_top_10px">
                                        <div class="inv--description">
                                            <h5>Avg Rx Count/month : </h5>
                                            <span><h3>{{ (salesData?.['RxCount'] / 12) | number : '1.2-2' }}</h3></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 inventory  drug-inven inventory--color3 padding--left__7 padding--right__7">
                                <div class="inv--information">
                                    <div class="inv--value">
                                        <div class="inv--description">
                                            <h5>Total Qty Dispensed : </h5>
                                            <span><h3>{{salesData && salesData['Quantity'] ? (salesData?.['Quantity'] | number : '1.3-3') : "0.000"}}</h3></span>
                                        </div>
                                    </div>
                                    <div class="inv--value padding_top_10px">
                                        <div class="inv--description">
                                            <h5>Avg Qty Dispensed/month : </h5>
                                            <span><h3>{{salesData && salesData['Quantity'] ? ((salesData['Quantity'] / 12) | number : '1.3-3') : "0.000" }}</h3></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 inventory  drug-inven inventory--color4 padding--left__7 padding--right__7">
                                <div class="inv--information">
                                    <div class="inv--value">
                                        <div class="inv--description">
                                            <h5>Total Qty Received : </h5>
                                            <span><h3>{{ salesData && salesData['RecvQty'] ? (salesData['RecvQty'] | number : '1.3-3'): "0.000" }}</h3></span>
                                        </div>
                                    </div>
                                    <div class="inv--value padding_top_10px">
                                        <div class="inv--description">
                                            <h5>Avg Qty Received/month : </h5>
                                            <span><h3>{{salesData && salesData['RecvQty'] ? ((salesData['RecvQty'] / 12) | number : '1.3-3') : "0.000" }}</h3></span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="col-md-3 inventory  drug-inven inventory--color1 padding--left__7 padding--right__7">
                                <div class="inv--information">
                                    <div class="inv--value">
                                        <div class="inv--description">
                                            <h5>Total Amount : </h5>
                                            <span><h3>{{salesData && salesData['Amount'] ? ('$'+ (salesData['Amount']| number : '1.2-2')) : "$0.00" }}</h3></span>
                                        </div>
                                    </div>
                                    <div class="inv--value padding_top_10px">
                                        <div class="inv--description">
                                            <h5>Avg Amount/month : </h5>
                                            <span><h3>{{salesData && salesData['Amount'] ? ('$'+ ((salesData['Amount'] / 12) | number : '1.2-2')) : "$0.00" }}</h3></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="box-body exprx--common-block">
                    <div class="row">
                        <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12">
                            <div class="exprx--common-block padding--left__7 padding--right__7">
                                <wj-flex-chart #sales [itemsSource]="salesData['graphData']" [bindingX]="'country'" [header]="'Drug Sales Summary Report'"
                                    [tooltipContent]="tooltip">
                                    <wj-flex-chart-legend [position]="'Bottom'"></wj-flex-chart-legend>
                                    <wj-flex-chart-series [name]="'Total Amount($)'" [binding]="'SalesAmount'"></wj-flex-chart-series>
                                    <wj-flex-chart-series [name]="'Total Qty Dispensed'" [binding]="'SalesDispensed'"></wj-flex-chart-series>
                                    <wj-flex-chart-series [name]="'Total Qty Received'" [binding]="'SalesReceived'"></wj-flex-chart-series>
                                    <!-- <wj-flex-chart-data-label [content]="setContent" position="Top"></wj-flex-chart-data-label> -->
                                </wj-flex-chart>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="box-body exprx--common-block eprx--block__footer"  *ngIf="!salesData['salesResponse']">
            <div>
                <span></span>
                <i class="fa fa-file"></i><br/>
                No Data Found!
            </div>
        </div>
    </div>
</ng-template>
