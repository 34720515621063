import { Component, OnInit, Input, AfterViewInit } from "@angular/core";
import { CommonService } from "src/app/services";
import { FormGroup } from "@angular/forms";
import { CommonUtil } from "src/app/utils";
import { SystemData } from "src/app/models";

@Component({
    selector: "app-insu-settings",
    templateUrl: "./insu-settings.component.html"
})
export class InsuSettingsComponent implements OnInit, AfterViewInit {

    @Input() insuId: any;
    @Input() dropDownData: any;
    @Input() settingInfo: any;
    @Input() insuStngFG: FormGroup;
    priceSchedule$: any;
    @Input() planInfoFG: FormGroup;
    @Input() systemData: SystemData;
    isCash: any;

    constructor(private _cmnSvc: CommonService, private _comnUtil: CommonUtil) {
        this.priceSchedule$ = this._cmnSvc.priceSchedule$;
    }

    ngOnInit() {
        this.isCash = this.systemData['DefaultValuese'][0].CashInsurerId === this.planInfoFG.value.InsuCarrier.Id;
        if(this.isCash) {
            this.insuStngFG.controls['InsuPlanPrice'].patchValue({ IsPartialFillAllowed : true});
        }
    }

    ngAfterViewInit() {
        if (document.getElementById("BasCost")) {
            const data: any = document.getElementById("BasCost").getElementsByTagName("input")[0];
            if (data) {
                data.focus();
            }
        }
    }

    setNumberFormat(FGName, ControlName) {
        const fg: any = this.insuStngFG.controls[FGName];
        fg.controls[ControlName].patchValue(fg.value[ControlName] ?
             (parseFloat(fg.value[ControlName])).toFixed(ControlName === "MaxQty" ? 3 : 2) :
             ControlName === "MaxQty" ? "0.000" : "0.00");
    }

    focusToSave(event) {
        this._comnUtil.focusById("InsSaveButton", event);
    }
}
