import { Component, OnInit, Input, ViewChild, Output, EventEmitter, AfterViewInit } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { InsuranceUtil, WijimoUtil } from "src/app/utils";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { MMSDataHeaders } from "../insurance.constants";
import { MsgConfig } from "src/app";
import { InsuranceStore } from "src/app/store";
import { AlertService, CommonService, InsuranceService } from "src/app/services";
import * as wjcGrid from "@grapecity/wijmo.grid"
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { PaginationModel } from "src/app/models/elastc-rx.model";

@Component({
    selector: "app-insu-common-header",
    templateUrl: "./insu-common-header.component.html"
})
export class InsuCommonHeaderComponent implements OnInit, AfterViewInit {

    @Input() planInfoFG: FormGroup;
    @Input() stngFG: FormGroup;
    @Input() insuId: number;
    @Input() transStngFG: FormGroup;
    @Input() insuInfo: any;
    @Input() openMMS: boolean;
    @Input() dropDownData: any;

    // tslint:disable-next-line:no-output-on-prefix
    @Output() onCopyInsuClick = new EventEmitter<any>();

    @ViewChild("searchData", { static: true })
    searchData: any;
    paginationModel: PaginationModel = new PaginationModel();

    insuForCopyId: any;
    typeHeadCopyInsu = this._insuUtil.searchOtherInsu;
    hideFilters: boolean;
    completeMMSData: any;
    model: any;
    modaltype: string;
    pageNu = this.paginationModel.pageNumber;
    Size = this.paginationModel.pageSize;
    refreshFrmGrp: FormGroup;
    selectedMMSValues: any;
    hasMMSValues: boolean;
    isDataExists = true;
    selectedSizeMMSData: any;
    searchedMMSList: any;
    actvHeaders: any;
    refreshMMSModel: any;
    wjHeaders: {hName: string, isVisible: boolean, width:number}[];
    refreshDataGrid: wjcGrid.FlexGrid;
    unsubscribe$: Subject<void> = new Subject();
    constructor(private _insuUtil: InsuranceUtil, private _insuStore: InsuranceStore, private _alertSvc: AlertService,
        private _modalSvc: NgbModal, private _fb: FormBuilder, private _insuSvc: InsuranceService, private _cmnSvc: CommonService,private _wijimoUtils: WijimoUtil) {
        this.refreshFrmGrp = this._fb.group({
            BinNum: [],
            ProcessorNum: []
        });
    }

    ngOnInit() {
        if (this.openMMS) {
            this.insuMMSSearchPopUp(this.searchData);
        }
        this.patchDefaultValHeaders();
        this._wijimoUtils.wjSavedData$.pipe(takeUntil(this.unsubscribe$)).subscribe(w => {
            if (w && w["WijmoKey"] && w["WijmoKey"] === "InsuFileSearchWJ") {
                this.patchDefaultValHeaders();
            }
        });
    }
   
    
    async patchDefaultValHeaders() {
        const storedWJ = await this._wijimoUtils.getWJSavedData("InsuFileSearchWJ");
        this.wjHeaders = this._wijimoUtils.patchDefHeader("InsuFileSearchWJ", storedWJ);
    }
    ngAfterViewInit() {
        this.planInfoFG.markAsUntouched();
    }

    // copy insurance Code Start

    openCopyModal(content) {
        this.model = this._modalSvc.open(content, { centered: true, backdrop: false });
    }

    copyInsuformatter = (x: any) => {
        return x ? x.PayerId : null;
    };

    selectedInsuForCopy(event) {
        this.insuForCopyId = event.item.InsuPlanId;
    }

    copyInsuranceData() {
        if (this.insuForCopyId) {
            this.onCopyInsuClick.emit();
            setTimeout(() => {
            const resp = this._insuUtil.CompleteInsuInfo(this.insuForCopyId, this.planInfoFG, this.stngFG, this.transStngFG);
            this.planInfoFG.markAsTouched();
            setTimeout(() => {
                this.planInfoFG.markAsTouched();
                this.planInfoFG.updateValueAndValidity();
            }, 100);
            if (this.model) {
            this.model.close();
            }
            }, 1000);
        } else {
            this._alertSvc.error("Please select Insurance to Copy");
        }
    }

    // copy insurance Code Ends

    // Refresh MMS Code Starts

    async getInsuranceMMS() {
        this.hideFilters = false;
        if ((this.refreshFrmGrp.controls['BinNum'].value && this.refreshFrmGrp.controls['BinNum'].value.length < 3)  || 
        (this.refreshFrmGrp.controls['ProcessorNum'].value && this.refreshFrmGrp.controls['ProcessorNum'].value.length < 3) ) {
            this._alertSvc.error("Enter minimum three characters to search");
        }  
         else {
            this.completeMMSData = await this._insuUtil.getInsuMMSInfo(this.refreshFrmGrp.value);
            if (this.completeMMSData && this.completeMMSData.length > 0) {
                this.selectedMMSValues = this.completeMMSData[0];
                this.mmsPagination();
                this.hasMMSValues = true;
                this.isDataExists = true;
            } else {
                this.hasMMSValues = false;
                this.isDataExists = false;
                this.searchedMMSList = null;
            }
         }
        
    }

    mmsPagination() {
        const startIndex = (this.pageNu * this.Size) - this.Size;
        const endIndex = (startIndex + this.Size);
        this.selectedSizeMMSData = this.completeMMSData.slice(startIndex, endIndex);
        if (this.selectedSizeMMSData.length) {
            this.hasMMSValues = true;
            this.searchedMMSList = this._insuUtil.generateMMSWIJIMO(this.selectedSizeMMSData);
            this.actvHeaders = MMSDataHeaders;
        } else {
            this.hasMMSValues = false;
        }
    }

    setPage(page: number) {
        this.pageNu = page;
        this.mmsPagination();
    }
    setSize(size: any) {
        this.pageNu = this.paginationModel.pageNumber;
        this.Size = size;
        this.mmsPagination();
    }

    clearFilters() {
        this.refreshFrmGrp.reset();
        this.getInsuranceMMS();
    }

    async selectMMSData(val: any) {
        this.selectedMMSValues = this.selectedSizeMMSData[val];
        if (this.insuId) {
            this.openMMSvalueForEdit();
        } else {
            this.refreshMMSModel = this._insuUtil.generateInsuMMSModel(this.planInfoFG.value, this.selectedMMSValues);
            this.insuInfo = this.planInfoFG.value;
            this.refreshMMSModel.map(value => {
                this.refreshMMSData(value, true);
            });
            const priceSchedule$ = this._cmnSvc.priceSchedule$;
            const insuOtherVal = this._insuUtil.patchStngMMSValue(this.selectedMMSValues, this.dropDownData,
                priceSchedule$ ? priceSchedule$["source"]["value"]["PriceScheduleList"] : null);
            this._insuUtil.patchPlanInfo(this.insuInfo, this.planInfoFG);
            if (insuOtherVal && insuOtherVal["stngValue"]) {
                if (insuOtherVal["stngValue"]) {
                    this.patchStngVal(this.stngFG, insuOtherVal["stngValue"]);
                }
                if (insuOtherVal["trans"]) {
                    this.patchStngVal(this.transStngFG, insuOtherVal["trans"]);
                }
            }
            this.onCopyInsuClick.emit();
            if (this.model) {
            this.model.close();
            }
            this.planInfoFG.markAsTouched();
            setTimeout(() => {
                this.planInfoFG.markAsTouched();
                this.planInfoFG.updateValueAndValidity();
            }, 100);
            if (document.getElementById("InsuCodeId")) {
                document.getElementById("InsuCodeId").focus();
            }
        }
    }

    patchStngVal(FG, value) {
        value.map(val => {
            if (val["keyName"]) {
                let subFG = null;
                if (FG.controls[val["keyName"]]) {
                    subFG = FG.controls[val["keyName"]] as FormGroup;
                }
                if (subFG && subFG.controls[val["ControlName"]]) {
                    subFG.controls[val["ControlName"]].patchValue(val["MMsvalue"]);
                }
            } else {
                if (FG.controls[val["ControlName"]]) {
                    FG.controls[val["ControlName"]].patchValue(val["MMsvalue"]);
                }
            }
        });
    }

    openMMSvalueForEdit() {
        this.refreshMMSModel = this._insuUtil.getMMSModelFrEdit(this.planInfoFG.value, this.selectedMMSValues);
        this.hideFilters = true;
    }

    refreshMMSData(val: any, checked: any) {
        if (this.insuInfo &&  val && this.insuInfo[val["keyName"]]) {
            if (checked) {
                this.insuInfo[val["keyName"]][val["ControlName"]] = val["MMsvalue"];
            } else {
                this.insuInfo[val["keyName"]][val["ControlName"]] = val["currentvalue"];
            }
        }
    }

    async updateMMSData() {
        const response = await this._insuUtil.updateInsuranceInfo([this._insuSvc.updateInsurance(this.insuInfo)]);
        if (response) {
            this._insuUtil.patchPlanInfo(this.insuInfo, this.planInfoFG);
            this._insuStore.storeInsuData(this.insuInfo);
            this._alertSvc.success(MsgConfig.UPDATE_SUCCESS);
        } else {
            this._alertSvc.error(MsgConfig.UPDATE_FAIL);
        }
    }

    async insuMMSSearchPopUp(searchData) {
        this.patchValue();
        await this.getInsuranceMMS();
        this.model = this._modalSvc.open(searchData, {keyboard: false, backdrop: false,windowClass:"max-modal-1300" , centered : true});
        this.modaltype = "MMSSearchType";
    }

    patchValue() {
        this.refreshFrmGrp.patchValue({
            BinNum: this.planInfoFG.value.InsuCarrier.BINNum ? this.planInfoFG.value.InsuCarrier.BINNum : null,
            ProcessorNum: this.planInfoFG.value.InsuPlan.ProcessorNum
        });
        this.planInfoFG.markAsUntouched();
    }
    // Refresh MMS Code Starts

    // Code For KeyEvents On Wijimo

    enterEventOnMMSGrid(event, grid) {
        if (grid["selectedItems"][0]) {
            event.preventDefault();
            this.selectMMSData(grid["selectedItems"][0]?.["row"]);
        }
    }

    spaceEventOnWijimo(event, grid) {
        document.getElementById(grid["selectedItems"][0]["link"])["checked"]
        = !document.getElementById(grid["selectedItems"][0]["link"])["checked"];
        if (grid["selectedItems"][0]["display"]) {
            event.preventDefault();
            this.refreshMMSData(
                grid["selectedItems"][0],
                document.getElementById(grid["selectedItems"][0]["link"])["checked"]
            );
        }
    }

    updateMMSInit(flex: wjcGrid.FlexGrid) {
        flex.focus();
        this.refreshDataGrid = flex;
    }

    cancelEventOnWijimo(event) {
        if (event && event.ctrlKey) {
            if (event.which === 67 && this.model) {
                event.preventDefault();
                this.model.close();
                this.model = null;
            }
        }
    }

    focusOnRefreshWijimo(event) {
        if (this.refreshDataGrid && this.hideFilters) {
            event.preventDefault();
            this.refreshDataGrid.focus();
        }
    }
    ngOnDestroy(){
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
