import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    AfterViewInit,
    OnChanges,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
} from "@angular/core";
import { FormGroup, FormControl, FormArray, FormBuilder } from "@angular/forms";
import { SystemData, PatientInfo } from "../../../../models";
import { RegEx } from "../../../../app.regex";
import * as _ from "lodash";
import { Observable } from "rxjs/observable";
import * as moment from "moment";
import { CommonService, AlertService, AdditionalInsuranceService } from "src/app/services";
import { PatientStore, CommonStore } from "src/app/store";
import { PatientInfoUtil } from "src/app/utils/patient-info.util";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { MsgConfig } from "src/app";
import { CommonUtil } from "src/app/utils";
import { PerPrefContact, Phone } from "../../../../models/patient-Info.model";
import { SendSmsComponent } from "../../../shared/send-sms/send-sms.component";
import { MessageService } from "src/app/services/message.service";
import * as Sentry from "@sentry/browser";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
@Component({
    selector: "app-basic-info",
    templateUrl: "./basic-info.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PBasicInfoComponent implements OnInit, AfterViewInit, OnChanges {
    patientCity$: Observable<any>;
    patientState$: Observable<any>;

    @Input() patientInfo: PatientInfo;
    @Input() patientInfoFG: FormGroup;
    @Input() systemData: SystemData;
    @Input() insuranceData: any;
    @Input() patientId: number;
    @Input() FrmFamily: boolean;
    @Input() formGroupInvalid: boolean;
    @Input() focusId: any;

    @Output() SavePhonePreferred = new EventEmitter();

    @Output()
    InsurFromLinkedFamliy = new EventEmitter<any>();
    editPatInfo: any;
    PerPrefContact: any;

    @Input()
    set EditPatientInfo(data: any) {
        this.editPatInfo = data;
    }
    
    addPatient: boolean;
    isDelete: boolean = false;
    isFromErxToAdd: boolean;
    eRxVersion: any;
    today: any;
    maxDate: any;
    regex = RegEx;
    ExtraPhoneFG: FormGroup;
    extraPhoneAdd: boolean;
    extraFromInValid: boolean;
    modelRef: any;
    extraPhoneIndex: any;
    phonesPreferred: any;
    contactFrDlt: any;
    phoneType: any;
    filterData: any;
    openSmsPopup: boolean;
    patientSettings: any;
    subscriptionMessage: any;
    errorToDisplay: any;
    unsubscribe$: Subject<void> = new Subject();

    get Person(): FormGroup {
        return this.patientInfoFG.get("Person") as FormGroup;
    }
    get Addresses(): FormGroup {
        return this.patientInfoFG.get("Addresses") as FormGroup;
    }

    constructor(
        private _commonServ: CommonService,
        private _patientStore: PatientStore,
        private _patUtil: PatientInfoUtil,
        private _fb: FormBuilder,
        private _modalServ: NgbModal,
        private _alertSvc: AlertService,
        private _commonUtil: CommonUtil,
        private _additionalInsu: AdditionalInsuranceService,
        private _msgServ: MessageService,
        private _cdr: ChangeDetectorRef
    ) {
        this.patientCity$ = this._patientStore.patCity$;
        this.patientState$ = this._patientStore.patState$;
    }

    ngOnInit() {
        this.today = moment().format("MM/DD/YYYY");
        this.maxDate = {
            year: moment(this.today).year(),
            month: moment(this.today).month() + 1,
            day: moment(this.today).date()
        };
        this.eRxVersion = this._commonServ.getSetttingValue("ERxSettings", "E-PRESCRIPTION_SCRIPT_VERSION");
        this.focusId = (this.focusId && this.focusId === "patDOB" || this.focusId === "primInsCardExp") ? this.focusId : "patLastName";
            const element: any =  document.getElementById(this.focusId) as HTMLInputElement;
                 if(element){
                       element.focus();
                 }

                this.preferredContact(this.patientInfoFG.value["PerPrefContact"]);
    }

    patchStateAndCity(data, type?: any) {
        this._patUtil.patchCityAndStateValue(data, this.patientInfoFG, (type ? type : null));
    }

    get Phones(): FormArray {
        return this.patientInfoFG.get("Phones") as FormArray;
    }

    async updatepatFG(value) {
        const result = value["result"];
        if (value["overRideName"]) {
            this.patientInfoFG.controls["Person"].patchValue({
                LastName: result["Person"]["LastName"]
            });
        }
        if (value["overRideGeneral"]) {
            this.patientInfoFG.controls["Addresses"].patchValue(result["Addresses"]);
            this.patchStateAndCity(result["Addresses"]);
            if (!result["Phones"] || !result["Phones"][0]["PhoneCatId"] || result["Phones"][0]["PhoneCatId"] === 0) {
                const tele: any = this.Phones.controls[0];
                tele.controls["PhoneCatId"].patchValue(3);
            } else {
                this.Phones.controls[0].patchValue(result["Phones"][0]);
                const tele: any = this.Phones.controls[0];
                tele.controls["PhoneCatId"].patchValue(3);
            }
        }
        this.patientInfoFG.controls["PatFamily"].patchValue(result["PatFamily"]);
        if (value["overRideInsu"]) {
            const insuInfo = this.patientInfoFG.value["Insurances"];
            this.patientInfoFG.controls["Insurances"].patchValue(result["Insurances"]);
            this.patientInfoFG.controls["Insurances"].patchValue({RelCatId: insuInfo["RelCatId"],
                PerNum: insuInfo["PerNum"], ChFirstName: insuInfo["ChFirstName"], ChLastName: insuInfo["ChLastName"]});
            this.patientInfoFG.controls["InsuCards"].patchValue(result["InsuCards"]);
            this.patientInfoFG.controls["InsuCarrier"].patchValue(result["InsuCarrier"]);
            this.patientInfoFG.controls["InsuGroups"].patchValue(result["InsuGroups"]);
            const insuCarrierId = this.patientInfoFG.value["Insurances"]["InsurerId"];
            const filteredGroups = await this._additionalInsu.getInsuGroups(insuCarrierId).toPromise();
            this._patientStore.storePatGroups(filteredGroups);
            this._patUtil.getPatientInsurances(result["Patient"]["Id"]);
        }
    }

    preferredContact(PerPrefContact){
        this.phonesPreferred = this._patUtil.getPhonesPrefered(PerPrefContact, this.patientInfoFG);
        this.SavePhonePreferred.emit(this.phonesPreferred);
    }
    createExtraPhoneFG() {
        this.ExtraPhoneFG = this._fb.group(new PerPrefContact());
    }

    openAddExtraPhonePopup(content) {
        this.extraPhoneAdd = true;
        this.extraFromInValid = false;
        this.createExtraPhoneFG();
        setTimeout(() => {
            this.modelRef = this._modalServ.open(content, {centered: true, backdrop: false});
        }, 100);
    }

    ngAfterViewInit() {
        this.focusId = (this.focusId && this.focusId === "patDOB" || this.focusId === "primInsCardExp") ? this.focusId : "patLastName";
        const element: any = document.getElementById(this.focusId) as HTMLInputElement;
        if (element) {
            element.focus();
        }
    }
    ngOnChanges(changes?: any){
        if(changes && changes.EditPatientInfo && changes.EditPatientInfo.currentValue && (changes.EditPatientInfo.currentValue.PerPrefContact || changes.EditPatientInfo.currentValue.PatientMessageSettings)){
           this.PerPrefContact = changes.EditPatientInfo.currentValue.PerPrefContact;
           this.patientSettings = changes.EditPatientInfo.currentValue.PatientMessageSettings ? changes.EditPatientInfo.currentValue.PatientMessageSettings.SendGeneralAlerts : null;
        } else{
            this.patientSettings = this.patientInfoFG.controls["PatientMessageSettings"].value["SendGeneralAlerts"];
        }
        this.subscriptionMessage = this._commonServ.getSetttingValue("MessageSettings", "Send_Subscription_Message_Alerts") === "1";
        this.focusId = (this.focusId && this.focusId === "patDOB" || this.focusId === "primInsCardExp") ? this.focusId : "patLastName";
        const element: any = document.getElementById(this.focusId) as HTMLInputElement;
        if (element) {
            element.focus();
        }
        const validPhoneCheck = this.editPatInfo && this.editPatInfo.Phones ? this.editPatInfo.Phones.find(x => x?.PhoneCatId === 2) : null;
        const phoneNo = this.Phones && this.Phones.value ? this.Phones.value.find(x => x.PhoneCatId === 2) : null;
        (validPhoneCheck && validPhoneCheck.xPhone) ? this.phoneValidationCheck(2, validPhoneCheck) : '';
        if ((phoneNo && phoneNo.xPhone)) {
            this.errorToDisplay = this.patientInfoFG.value.Patient["IsMobileValid"] === true ? "Valid!" : this.patientInfoFG.value.Patient["IsMobileValid"] === false ? "Invalid Mobile#" : this.phoneValidationCheck(2);
        }
        if(this.editPatInfo && this.editPatInfo.Phones && this.editPatInfo.Phones.length){
            if(this.editPatInfo.Phones[0].PhoneCatId == 3 && this.editPatInfo.Phones[1].PhoneCatId == 2){
                const detailsOfPhones = this.editPatInfo.Phones[0];
                this.editPatInfo.Phones[0] = this.editPatInfo.Phones[1];
                this.editPatInfo.Phones[1] = detailsOfPhones;
            }
        }else{
            if(this.Phones && this.Phones.controls && this.Phones.controls.length && (this.Phones.controls[0].value.PhoneCatId == 3) && (this.Phones.controls[1].value.PhoneCatId == 2)){
                const detailsOfPhones = this.Phones.controls[0];
                this.Phones.controls[0] = this.Phones.controls[1];
                this.Phones.controls[1] = detailsOfPhones;
            }
        }
       
        this.preferredContact(this.PerPrefContact);
    }


    ExtraPhoneEdit(content, data, index) {
        this.extraPhoneAdd = false;
        this.extraPhoneIndex = index;
        this.extraFromInValid = false;
        this.createExtraPhoneFG();
        this.phoneType = data;
        this.ExtraPhoneFG.patchValue(data);
        setTimeout(() => {
            this.modelRef = this._modalServ.open(content, {centered: true, backdrop: false});
        }, 100);
    }

    closeModal() {
        if(this.modelRef) {
            this.modelRef.close();
              this.modelRef = null;
        }
        this.ExtraPhoneFG.reset();
        this.ExtraPhoneFG.markAsUntouched();
    }
    closeModalSMS(eve) {
        this.openSmsPopup = eve;
    }

    checkPhoneNameDup() {
        const prefContact = this.patientInfoFG.value["PerPrefContact"];
        if(!(this.extraPhoneAdd) && this.phoneType && this.phoneType.ContactType){
            this.filterData = prefContact.filter(val => ((val["ContactType"].trim()).toLowerCase()
            === ((this.ExtraPhoneFG.value && this.ExtraPhoneFG.value["ContactType"] !== this.phoneType.ContactType ? this.ExtraPhoneFG.value["ContactType"].trim() : "")).toLowerCase()
            && val["Action"] !== "D" && val["IsActive"]));
        } else {
             this.filterData = prefContact.filter(val => ((val["ContactType"].trim()).toLowerCase()
            === ((this.ExtraPhoneFG.value && this.ExtraPhoneFG.value["ContactType"] ? this.ExtraPhoneFG.value["ContactType"].trim() : "")).toLowerCase()
            && val["Action"] !== "D" && val["IsActive"]));
        }
        if ((this.filterData && this.filterData.length > 0)) {
            this.ExtraPhoneFG.controls["ContactType"].patchValue(null);
            if (document.getElementById("ExtraPhoneName")) {
             const ele: any = document.getElementById("ExtraPhoneName").getElementsByTagName("input")[0];
             ele.focus();
            }
            this._alertSvc.error("Phone contact name already in use. Please enter another name.", false, "long");
        }
    }

    addExtraPhone() {
        if (this.ExtraPhoneFG.valid) {
            let prefContact = this.patientInfoFG.value["PerPrefContact"];
            this.extraFromInValid = false;
            const filterData = prefContact.filter(val => ((val["ContactType"].trim()).toLowerCase()
                === (this.ExtraPhoneFG.value["ContactType"].trim()).toLowerCase()
                && val["Action"] !== "D" && val["IsActive"]));
            if ((this.extraPhoneAdd && filterData && filterData.length > 0) ||
                (!this.extraPhoneAdd && filterData && filterData.length > 1)) {
                this.ExtraPhoneFG.controls["ContactType"].patchValue(null);
                if (document.getElementById("ExtraPhoneName")) {
                    const ele: any = document.getElementById("ExtraPhoneName").getElementsByTagName("input")[0];
                    ele.focus();
                }
                this._alertSvc.error("Phone contact name already in use. Please enter another name.", false, "long");
            } else {
                if (this.ExtraPhoneFG.value["IsPreferred"]) {
                    this.phonesPreferred = null;
                    prefContact.map(val => val["IsPreferred"] = false);
                    this.SavePhonePreferred.emit(this.phonesPreferred);
                }
                if (this.extraPhoneAdd) {
                    this.ExtraPhoneFG.patchValue({
                        IsActive: true,
                        Action: "A",
                        PatientId: this.patientId ? this.patientId : null
                    });
                    this.ExtraPhoneFG.controls["IsActive"].patchValue(true);
                    this.ExtraPhoneFG.controls["Action"].patchValue("A");
                    prefContact = prefContact ? prefContact : [];
                    prefContact.push(this.ExtraPhoneFG.value);
                } else {
                    prefContact[this.extraPhoneIndex] = this.ExtraPhoneFG.value;
                    prefContact[this.extraPhoneIndex]["Action"] = prefContact[this.extraPhoneIndex]["Id"] ? "U" : "A";
                }
                this.generateExtraPFA(prefContact);
                this.closeModal();
            }
        } else {
            this.extraFromInValid = true;
        }
    }
    openDeleteModal(content, val) {
        const prefContact = this.patientInfoFG.value["PerPrefContact"];
        this.contactFrDlt = prefContact[val];
        this._modalServ.open(content, {backdrop: false, centered: true, windowClass: "large--content"});
    }

    deleteExtraPhone() {
        this.contactFrDlt["Action"] = "D";
        this.contactFrDlt["IsActive"] = false;
        this.generateExtraPFA(this.patientInfoFG.value["PerPrefContact"]);
    }

    setPhonesPreferred(value) {
        this.phonesPreferred = value;
        const prefContact = this.patientInfoFG.value["PerPrefContact"];
        if (prefContact && prefContact.length > 0) {
            prefContact.map(val => val["IsPreferred"] = false);
            this.generateExtraPFA(prefContact);
        }
        this.SavePhonePreferred.emit(this.phonesPreferred);
    }

    checkIsPreferred(index: any) {
        const prefContact = this.patientInfoFG.value["PerPrefContact"];
        if (prefContact && prefContact.length > 0) {
            prefContact.map(val => val["IsPreferred"] = false);
        }
        this.phonesPreferred = null;
        this.SavePhonePreferred.emit(this.phonesPreferred);
        prefContact[index]["IsPreferred"] = true;
        this.generateExtraPFA(prefContact);
    }

    generateExtraPFA(value) {
        this._commonUtil.generateFA("PerPrefContact", value, this.patientInfoFG);
    }
    focusbyId(Id, event, isSelect?: any) {
        setTimeout(() => {
            this._commonUtil.focusById(Id, event, isSelect);
        }, 200);
    }
    openSendSMS() {
        this.openSmsPopup = true;

    }
    onAddressChange(address,type){
        const result = address.replace(/^\s+/g, '');
        if(type === "Address1"){
            this.patientInfoFG.controls["Addresses"].patchValue({
                AddressLine1: result
             });
        }else{
            this.patientInfoFG.controls["Addresses"].patchValue({
                AddressLine2: result
             });
        }

}
    phoneValidationCheck(PhoneType,event?) {
        if (PhoneType === 2 && ((this.Phones && this.Phones.value) || event)) {
            const phoneNo = this.Phones?.value?.find(x => x.PhoneCatId === PhoneType);
            if (((phoneNo && phoneNo.xPhone) || (event && event.xPhone)) && !this.errorToDisplay) {
                this._msgServ.ValidatePhone(phoneNo?.xPhone ? phoneNo.xPhone : event?.xPhone).pipe(takeUntil(this.unsubscribe$)).subscribe((resp: any) => {
                    this.errorToDisplay  = (resp && resp.result)  ? "Valid!" : "Invalid Mobile#";
                    this.patientInfoFG.controls["Patient"].patchValue({
                        IsMobileValid: resp.result,
                        MobileVerifiedDtTm: resp.VerifiedDtTm ?  resp.VerifiedDtTm : null
                    });
                    this._cdr.detectChanges();
                    if(event && event.key && event.key == "Tab"){
                    const ele = document.getElementById("patTelep"+(PhoneType)) as HTMLInputElement;
                    if (ele) {
                        ele.focus();
                    }

                    }
                    
                });
            }
        }
    }
    clearTextMsg(event,PhoneType) {
        if ((this.Phones && this.Phones.value)) {
            const phoneNo = this.Phones.value.find(x => x.PhoneCatId === PhoneType);
            if(event && event.code === 'Delete'&& phoneNo.xPhone){
                this.Phones.value.map((x,i)=> {
                    if (x.PhoneCatId === PhoneType) {
                        const tele: any = this.Phones.controls[i];
                        tele.controls["xPhone"].patchValue(null);
                    }
            })
        }
            if (phoneNo && phoneNo.xPhone) {
                if (phoneNo.xPhone.length < 10) {
                    this.errorToDisplay = "";
                } else if (this.errorToDisplay && this.errorToDisplay === "Invalid Mobile#") {
                    this.errorToDisplay = "";
                }
            } else {
                phoneNo.xPhone = "";
                }
           
    }
}
    ngOnDestroy(): void {
        this._cdr.detach();
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
