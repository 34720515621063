

<div class="editdrug--tabing navTabStyles d-flex flex-row">
    <ul ngbNav #nav="ngbNav" [(activeId)]="activeTabId" class="nav-tabs col-11"
        (tabChange)="beforeChange($event?.nextId)" #DrugTabs >
        <li [ngbNavItem]="'Patient1'"  (click)="beforeChange('Patient1')">
            <a ngbNavLink class="nav-link" id="Patient1">
                <div class="txt">
                    <div>Drug
                         Information</div>
                </div>
            </a>
        </li>
        <li [ngbNavItem]="'Patient2'" (click)="beforeChange('Patient2')">
            <a ngbNavLink class="nav-link">
                <div class="txt">
                    <div >Pricing </div>
                </div>
            </a>
        </li>
        <li [ngbNavItem]="'Patient3'" (click)="beforeChange('Patient3')">
            <a ngbNavLink class="nav-link">
                <div class="txt">
                    <div>  Inventory  </div>
                </div>
            </a>
        </li>
        <li [ngbNavItem]="'Patient4'" (click)="beforeChange('Patient4')">
            <a ngbNavLink class="nav-link">
                <div class="txt">
                    <div> Disp.
                        History  </div>
                </div>
            </a>
        </li>

        <li [ngbNavItem]="'Patient5'" (click)="beforeChange('Patient5')">
            <a ngbNavLink class="nav-link">
                <div class="txt" >
                    <div>
                        Misc.
                        Information
                    </div>
                </div>
            </a>
        </li>

        <li [ngbNavItem]="'Patient6'" (click)="beforeChange('Patient6')">
            <a ngbNavLink class="nav-link">
                <div class="txt" >
                    <div>
                        Drug
                        Counselling
                    </div>
                </div>
            </a>
        </li>
        <li [ngbNavItem]="'Patient12'"(click)="beforeChange('Patient12')">
            <a ngbNavLink class="nav-link">
                <div class="txt" >
                    <div>
                        Notes
                    </div>
                </div>
            </a>
        </li>

        <li [ngbNavItem]="'Patient8'" (click)="beforeChange('Patient8')">
            <a ngbNavLink class="nav-link">
                <div class="txt" >
                    <div>
                        Insurance
                        Preferences
                    </div>
                </div>
            </a>
        </li>


        <li [ngbNavItem]="'Patient9'" (click)="beforeChange('Patient9')">
            <a ngbNavLink class="nav-link" [ngClass]="{'avail-rec': drugInfo?.IsDocumentExists}">
                <div class="txt" >
                    <div>
                        Documents
                    </div>
                </div>
            </a>
        </li>

        <li [ngbNavItem]="'Patient10'" (click)="beforeChange('Patient10')">
            <a ngbNavLink class="nav-link">
                <div class="txt" >
                    <div>
                        Price
                        Update
                    </div>
                </div>
            </a>
        </li>


        <li [ngbNavItem]="'Patient11'" (click)="beforeChange('Patient11')">
            <a ngbNavLink class="nav-link">
                <div class="txt" >
                    <div>
                        Drug
                         Category
                    </div>
                </div>
            </a>
        </li>

       <!--<li [ngbNavItem]="'Patient12'" (click)="beforeChange('Patient12')">
            <a ngbNavLink class="nav-link">
                <div class="txt" >
                    <div>
                        Bucket
                         Pricing
                    </div>
                </div>
            </a>
        </li>-->
        
    </ul>
    <ul class="col-1 height_2_9rem border_bottom_0_21_rem_blue">
        <li class="text-right">
            <app-user-guide-icon *ngIf="!drugId" [ActiveTabId]="'AddDrug'"></app-user-guide-icon>
        </li>
    </ul>
    <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>


<ng-template #warning let-c="close" let-d="dismiss">
    <app-custom-modal (oncrossBtnClick)="c('Close click');closeWarning()">
            <h4 header>Drug</h4>
            <span body>Please save drug record first.
            </span>
            <button footer autofocus  type="button" class="hotkey_primary" (click)="c('Close click');" (click)="emitToAddDrug()" appShortcutKey InputKey="y"> <b>Y</b>YES</button>
            <button footer type="button" class="hotkey_primary" (click)="c('Close click');closeWarning()" appShortcutKey InputKey="n"> <b>N</b> NO</button>
        </app-custom-modal>

</ng-template>
