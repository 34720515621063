import {
    Component,
    OnInit,
    Input,
    EventEmitter,
    Output,
    ViewChild,
    OnDestroy,
    HostListener
} from "@angular/core";
import { RxTransferService } from "src/app/services";
import { CollectionView } from "@grapecity/wijmo";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TransmitSearch, RxModel, SystemData } from "src/app/models";
import { FormGroup } from "@angular/forms";
import { RxStore } from "src/app/store";
import { WijimoUtil } from "src/app/utils";
import * as wjcGrid from "@grapecity/wijmo.grid"
import * as moment from "moment";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FormatsUtil } from "src/app/utils/formats.util";

@Component({
    selector: "app-trans-message",
    templateUrl: "./trans-message.component.html",
    styles: []
})
export class TransMessageComponent implements OnInit, OnDestroy {

    @Input() systemData: SystemData;

    @Input() rxInfo: RxModel;

    @Input()
    set RxFG(fg: FormGroup) {
        this.rxFG = fg as FormGroup;
    }

    @Output()
    IsPopUpClosed = new EventEmitter<null>();

    @Output()
    GetNewRxInfo = new EventEmitter<null>();

    @Output()
    EmitToInitiateTransmRx = new EventEmitter<null>();

    @Output()
    PatPrescDrugInsModalType = new EventEmitter<any>();

    @ViewChild("TRANSLOG", { static: true })
    TRANSLOG: any;

    @ViewChild("WARNINGMSG", { static: true })
    WARNINGMSG: any;

    hasLogs = false;
    transLogsInfo: any;
    rxTransmissionWJ: any;
    modelRef: any;
    transmitParams: any;
    transmitResponse: any;
    unsubscribe$ : Subject<void> = new Subject();
    activeModal = "";
    rxFG: FormGroup;
    isTransmission: string;
    rxInfo$: any;
    transmissionData$: any;
    wjHeaders: { hName: string; isVisible: boolean; width: number; }[];
    rxTrLogWijmo: wjcGrid.FlexGrid;

    @HostListener("window:keyup", ["$event"])
    keyEvent(event: KeyboardEvent) {
        if (event.which === 27 || event.which === 89) { // yes
            this.closeModal();
      }
    }

    constructor(
        private _rxTrnsfrSvc: RxTransferService,
        private _modalService: NgbModal,
        private _rxStore: RxStore,
        private _wijimoUtils: WijimoUtil,
        private _formatsUtil:FormatsUtil
    ) {
        this.rxInfo$ = this._rxStore.rxInfo$;
        this.transmissionData$ = this._rxStore.transmissionData$;
    }

    ngOnInit() {
        this.transmitParams = new TransmitSearch();
        this.getTransmissionLogs();
        this.patchDefaultValHeaders();
        this._wijimoUtils.wjSavedData$.pipe(takeUntil(this.unsubscribe$)).subscribe(w => {
            if (w && w["WijmoKey"] && w["WijmoKey"] === "rxTransmissionLogWJ") {
                this.patchDefaultValHeaders();
            }
        });
    }

    async getTransmissionLogs() {
        const resp = await this._rxTrnsfrSvc
            .getRXTransmissionLog(this.rxInfo.PrescReFill.Id).toPromise();
        if (resp && resp.length > 0) {
            this.hasLogs = true;
            this.transLogsInfo = resp;
            if (this.transLogsInfo.length > 1) {
                this.generateLogsWJ(resp);
                this.modelRef = this._modalService.open(this.TRANSLOG, {
                    size: "lg", centered: true, backdrop: false, windowClass: "transmsg--modal", keyboard:false
                });
                this.activeModal = "TRANSLOG";
            } else {
                this.showTransmitLog(this.transLogsInfo[0]);
            }
        } else {
            this.modelRef = this._modalService.open(this.WARNINGMSG, {centered: true,
                keyboard: false, backdrop: false, windowClass: "large--content"
            });
            this.activeModal = "WARNINGMSG";
        }
    }

    generateLogsWJ(rxLogs) {
        this.rxTransmissionWJ = new CollectionView(
            rxLogs.map((val: any, i) =>{
                const k = {};
                k['Id'] = val.Id;
                k["Rx#"] = val.PrescNum ? val.PrescNum : "";
                k["Ref#"] = val.RefillNum;
                k["Fill#"] = (val.FillNo && val.FillNo > 0) ? val.FillNo : " ";
                k["Result"] = val.Trans_Result ? val.Trans_Result : "";
                k["Transm. Date"] = val.BilledDate ? moment.utc(val.BilledDate).local().format("MM/DD/YYYY h:mm:ss a") : "";
                k["Ins. Code"] = val.Ins_Code ? val.Ins_Code : "";
                k["Trans."] = val.TransactionType ? val.TransactionType : "";
                k["User"] = val.UserName ? val.UserName : "";
                k["Drug NDC"] = this._formatsUtil.getNDCFormat(val.DrugNDC);
                k["Ins_Code"] = val.Ins_Code ? val.Ins_Code : "";
                k["PrescRefillId"] = val.PrescRefillId ? val.PrescRefillId : "";
                k["TransactionType"] = val.TransactionType ? val.TransactionType : "";
                k["RxId"] = val.RxId ? val.RxId : "";
                k["Trans_Result"] = val.Trans_Result ? val.Trans_Result : "";
                k["ClaimId"] = val.ClaimId ? val.ClaimId : 0;
                return k;
            })
        );
    }

    closeModal() {
        if (this.modelRef) {
            this.modelRef.close();
        }
        this.modelRef = null;
        this.isTransmission = null;
        this.IsPopUpClosed.emit(null);
    }

    getNewRxInfo() {
        this.GetNewRxInfo.emit();
    }

    async showTransmitLog(item) {
        this.transmitParams.Ins_Code = item.Ins_Code;
        this.transmitParams.PrescRefillId = item.PrescRefillId;
        this.transmitParams.TransType = item.TransactionType;
        this.transmitParams.rxId = item.RxId;
        this.transmitParams.Trans_Result = item.Trans_Result;
        this.transmitParams.patientId = this.rxInfo.Patient.patientid;
        this.transmitParams["claimID"] = (item && item.ClaimId) ? item.ClaimId : 0;
        this.transmitParams["ItemId"] = item.Id;

        // await this._transmissionServ
        //     .getandStoreRxTransmissionInfo(item.Id, this.transmitParams, claimID);
        this.isTransmission = "transMessage";
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    completeTranmsns() {
        this.isTransmission = null;
        if (this.transLogsInfo.length === 1) {
            this.IsPopUpClosed.emit(null);
        }
    }

    init(flex: wjcGrid.FlexGrid) {
        this.rxTrLogWijmo = flex;
    }

    async patchDefaultValHeaders() {
        const storedWJ = await this._wijimoUtils.getWJSavedData("rxTransmissionLogWJ");
        this.wjHeaders = this._wijimoUtils.patchDefHeader("rxTransmissionLogWJ", storedWJ);
    }
}
