<div class="exprx--common-block  insurance--body">
    <div class="eprx--block__header text-white pl-3 font-weight-bold">
        <div class="row">
            <div class="col-11">
                Patient Details
            </div>
            <div class="col-1 pl-0 patient-edit--transfer-in" *ngIf="selectedPatientDetails?.patientid && !IsfrmPatHis && !selectedPatientDetails?.requestedInfo">
                <i  (click)="editPatient(selectedPatientDetails.patientid)"
                class="far  fa-edit" title="Patient Edit"></i>
            </div>
        </div>
    </div>
    <div
    class="eprx--block__content height_300 pt-0" [ngClass]="IsTransferType? 'height_253' : ''">
        <div class="seconday-search--grid dashboard--search lg-secondary-search--grid pt-3 mb-0" *ngIf="!selectedPatientDetails?.requestedInfo">
    <app-searchbox-global class="w-100" #PatSear [SearchFor]="'Patient'" [DisableSerchFil]="true"
    class="eRx_transfer-request" [DisableRadioButtons]="true" [IsHide]="true"
    (SelectedPatient)="checkIsActivePatient($event)"  [isFrmAllergies]="true"
    [inputdisabled]="isfromPatientHistory" [IsClearIConDisable]="isfromPatientHistory ? true: false"
     id="NewRxPatSearforeRx" [IsFocused]="true"  [ClearInputValue]="patientSearchValue"
        ></app-searchbox-global>
  </div> 
    <div class="row row-space" >
        <div class="col-4">
            <label>{{patientLabels.Name}}</label>
            <div class="label--data"><span>{{selectedPatientDetails ?
                    (selectedPatientDetails?.fullname) : msgConfig.EMPTY_Data}}</span></div>
        </div>
        <div class="col-4">
            <label>{{patientLabels.DOB}}</label>
            <div class="label--data"><span>{{selectedPatientDetails ?
                    (selectedPatientDetails?.dob | date: "MM/dd/yyyy" ) : msgConfig.EMPTY_Data}}</span></div>
        </div>
        <div class="col-4">
            <label>{{patientLabels.Gender}}</label>
            <div class="label--data"><span>{{selectedPatientDetails ?
                    (selectedPatientDetails?.gender) : msgConfig.EMPTY_Data}}</span></div>
        </div>
    </div>
    <div class="row row-space">
        <div class="col-6">
            <label>{{patientLabels.Insurance_Type_Code}}</label>
            <div class="label--data"><span>{{(primaryInsu?.InsurerCode) ? (primaryInsu?.InsurerCode
                    | uppercase) : msgConfig.EMPTY_Data}}({{(primaryInsu?.groupname) ? (primaryInsu?.groupname
                        | uppercase) : msgConfig.EMPTY_Data}}/{{(primaryInsu?.binnum) ? (primaryInsu?.binnum
                        | uppercase) : msgConfig.EMPTY_Data}}/{{(primaryInsu?.ProcessorNum) ? (primaryInsu?.ProcessorNum
                            | uppercase) : msgConfig.EMPTY_Data}})</span></div>
        </div>
        <div class="col-6">
            <label>{{patientLabels.Patient_notes}}</label>
            <div class="label--data ellpise" [title]="selectedPatientDetails?.notes"><span>{{(selectedPatientDetails?.notes) ?
                    selectedPatientDetails?.notes : msgConfig.EMPTY_Data}}</span></div>
        </div>
    </div>
    <div class="row row-space">
        <div class="col-6">
            <label>{{patientLabels.Address}}</label>
            <div class="label--data ellpise" [title]="selectedPatientDetails?.address | uppercase"> <span>{{(selectedPatientDetails?.address) ?
                    selectedPatientDetails?.address : msgConfig.EMPTY_Data}}</span></div>
        </div>
        <div class="col-6">
            <label>{{patientLabels.Diagnosis_codes}}</label>
            <div class="label--data ellpise" [title]="selectedPatientDetails?.Diagnosis"><span>{{selectedPatientDetails?.Diagnosis?.length ?
                    selectedPatientDetails?.Diagnosis.join(", ") : msgConfig.EMPTY_Data}}</span>
            </div>
        </div>
    </div>
    <div class="row row-space">
        <div class="col-12">
            <label>{{patientLabels.Allergies}}</label>
            <div class="label--data ellpise"
            [title]="selectedPatientDetails?.AllergiesList ?
            selectedPatientDetails?.AllergiesList.join('', ''): msgConfig.EMPTY_Data"
            [ngClass]="selectedPatientDetails?.AllergiesList?.includes(msgConfig.No_Known_Allergies) ? '' : 
                                    (selectedPatientDetails?.AllergiesList?.length ? 'allergy-color' : '')">
                <span>
                    {{selectedPatientDetails?.AllergiesList ?
                    selectedPatientDetails?.AllergiesList.join(", ") : msgConfig.EMPTY_Data}}
                </span>
            </div>
        </div>
    </div>
</div>
</div>
