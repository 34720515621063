<div class="newrx--body merge--body">
    <div class="editpatient">
        <div class="row content--heading">
            <div class="col-md-10 padding-0">
                <div class="row">
                    <div class="icon">
                        <i class="far fa-signature text-white font_size_1_25rem"></i>
                    </div>
                    <div class="col-md-11 padding-0">
                        <div class="content--heading__title padding-0">Search By Rx
                            <span class="content--name"></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <button type="button" class="hotkey_success float-right" appShortcutKey [AltKey]="'l'"
                    (click)="clearFilters()" id="clearButton"> <span>L</span>
                    Clear
                </button>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 padding--right__7 ">
            <div class="exprx--common-block">
                <div class="eprx--block__header">
                    <div class="row">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-11">
                                    <div class="eprx--header__heading">
                                        <span> Rx Filter</span>
                                    </div>
                                </div>
                                <div class="col-1 padding_left_7rem">
                                    <app-user-guide-icon [ActiveTabId]= "'SignByRx'"></app-user-guide-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="eprx--block__content">
                    <div class="row">
                        <div class="col-md-3">

                        </div>
                        <div class="col-md-3 col-sm-3 col-xs-3 ">
                            <label class="control-label col-sm-12 padding-0">
                                <span> Rx# </span>
                                <input autofocus type="text" class="form-control search--input__box"
                                    [(ngModel)]="rxNumber" (keydown.enter)="rxNumber ? searchWithRx(): ''" [id]="'RxNumber'" />
                            </label>
                        </div>
                        <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 margin_top_1rem">
                            <button  [ngClass]="rxNumber ? 'hotkey_success pull-right' : 'inactive pull-right'"  (click)="rxNumber ? searchWithRx(): ''" appShortcutKey [title]="!rxNumber ? 'Please enter Rx# to Search' : 'Search / Refresh'"
                                [AltKey]="'f'" (keydown.tab)="focusToFirst($event)">
                                <span>F</span> Search
                            </button>
                        </div>
                        <div class="col-md-3">

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="rxPatientModel">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 padding--right__7 ">
            <div class="exprx--common-block">
                <div class="eprx--block__header">
                    <div class="eprx--header__heading">
                        Patient {{rxPatientModel?.fullname ? "-" + (rxPatientModel?.fullname.replace('_',', ')|uppercase):''}}
                    </div>
                </div>
                <app-rx-patient-card [RxPatientInfo]="rxPatientModel"></app-rx-patient-card>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="RxSignData">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 padding--right__7 ">
            <div class="exprx--common-block erx--body insurance--body">
                <div class="eprx--block__header">
                    <div class="eprx--header__heading">
                        <div class="col-sm-12 padding-0">
                            <div class="row">
                                <div class="col-md-10">
                                    <span>Privacy Acknowledgement History</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="eprx--block__content">
                    <div class="row">
                        <div class="col-md-12">
                            <wj-flex-grid #ackInfoGrid *ngIf="ackInfoWJ" [headersVisibility]="'Column'"
                                [itemsSource]="ackInfoWJ" [isReadOnly]="true" [selectionMode]="'Row'"
                                [columnPicker]="'primeescSignByRxAcknListWJ'">
                                <wj-flex-grid-column [header]="'Action'" [width]="80">
                                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                        <i class="far fa-signature fa-sm actions actions--Highlightfocus--icon" title="Show Sign"
                                        (click)="openSignModal(item,true)"></i>
                                    </ng-template>
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="rxheader['hName']" [binding]="rxheader['hName']"
                                    [visible]="rxheader['isVisible']" *ngFor="let rxheader of wjHeadersAck; let i = index"
                                    [width]="rxheader['width']">
                                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                        <div *ngIf="rxheader['hName'] === 'Date'">
                                            {{item[rxheader['hName']] | localTime | date :'MM/dd/yyyy hh:mm a'}}
                                        </div>
                                        <div *ngIf="!(rxheader['hName'] === 'Date')"
                                            title={{item[rxheader.hName]}}>
                                            {{ item[rxheader['hName']] }}
                                        </div>
                                    </ng-template>
                                </wj-flex-grid-column>
                            </wj-flex-grid>
                            <h3 *ngIf="!ackInfoWJ" class="text-center">No Privacy Acknowledgement History</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 padding--right__7 ">
            <div class="exprx--common-block erx--body insurance--body">
                <div class="eprx--block__header">
                    <div class="eprx--header__heading">
                        <div class="col-sm-12 padding-0">
                            <div class="row">
                                <div class="col-md-10">
                                    <span>Rx Pickup Transactions</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="eprx--block__content">
                    <div class="row">
                        <div class="col-md-12">
                            <wj-flex-grid *ngIf="histInfoWJ" #histInfoGrid [headersVisibility]="'Column'"
                                [itemsSource]="histInfoWJ" [isReadOnly]="true" [selectionMode]="'Row'"
                                [columnPicker]="'primeescSignByRxHistListWJ'">
                                <wj-flex-grid-column [header]="'Action'" [width]="80">
                                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                        <i class="far fa-signature fa-sm actions actions--Highlightfocus--icon" title="Show Sign"
                                                (click)="openSignModal(item,false)"></i>
                                    </ng-template>
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="rxheader['hName']" [binding]="rxheader['hName']"
                                    [visible]="rxheader['isVisible']" *ngFor="let rxheader of wjHeadersHist; let i = index"
                                    [width]="rxheader['width']">
                                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                        <div *ngIf="rxheader['hName'] === 'Pickup Date'">
                                            {{item[rxheader['hName']] | localTime | date :'MM/dd/yyyy hh:mm a'}}
                                        </div>
                                        <div *ngIf="!(rxheader['hName'] === 'Pickup Date')"
                                            title="{{item[rxheader['hName']]}}">
                                            {{ item[rxheader['hName']] }}
                                        </div>
                                    </ng-template>
                                </wj-flex-grid-column>
                            </wj-flex-grid>
                            <h3 *ngIf="!histInfoWJ" class="text-center">Rx Pickup Transactions</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-patient-sign-record *ngIf="showPatientSign" [RPH]="RPH" [PatientInfo]="rxPatientModel" [SignId]="signId" [SigType]="sigType"
    [SignatureData]="signatureData" (IsPopupClosed)="showPatientSign=false"></app-patient-sign-record>
