import {
    Component,
    OnInit,
    Input,
    ChangeDetectorRef,
    AfterViewInit,
    OnDestroy,
    EventEmitter,
    Output
} from "@angular/core";
import { FormBuilder, FormGroup, FormControl } from "@angular/forms";
import { SystemData, PatientInfo } from "../../../../models";
import {
    catchError,
    debounceTime,
    distinctUntilChanged,
    map,
    tap,
    switchMap,
    takeUntil
} from "rxjs/operators";

import { RegEx } from "../../../../app.regex";
import { PatientService, CommonService } from "src/app/services";
import { Subject } from "rxjs";

@Component({
    selector: "app-pricing",
    templateUrl: "./pricing.component.html",
    styles: []
})
export class PPricingComponent implements OnInit, AfterViewInit, OnDestroy {
    priceDrops: any[] = [];
    discPriceDrops: any[] = [];
    regex: any;
    priceScheduleList: any;
    unsubscribe$: Subject<void> = new Subject();

    @Input() patientInfo: PatientInfo;
    @Input() patientInfoFG: FormGroup;
    @Input() systemData: SystemData;

    @Output() basicInfoForm = new EventEmitter<FormGroup>();

    constructor(
        private _cdr: ChangeDetectorRef,
        private _editPatientServ: PatientService,
        private _commonServ: CommonService
    ) {
        this.regex = RegEx;
    }

    ngOnInit() {
        this.getDropdowns();

        this._commonServ.priceSchedule$.pipe(takeUntil(this.unsubscribe$)).subscribe(
            resp => ((resp && resp.PriceScheduleList) ? this.priceScheduleList = resp.PriceScheduleList : [])
        );
    }

    get PatPricings(): FormGroup {
        return this.patientInfoFG.get("PatPricings") as FormGroup;
    }

    get DiscSchId(): FormControl {
        return this.patientInfoFG.controls["PatPricings"].get(
            "DiscSchId"
        ) as FormControl;
    }

    get BrandPriceSchId(): FormControl {
        return this.patientInfoFG.controls["PatPricings"].get(
            "BrandPriceSchId"
        ) as FormControl;
    }

    get GenPriceSchId(): FormControl {
        return this.patientInfoFG.controls["PatPricings"].get(
            "GenPriceSchId"
        ) as FormControl;
    }

    get IsAppliedCashRx(): FormControl {
        return this.patientInfoFG.controls["PatPricings"].get(
            "IsAppliedCashRx"
        ) as FormControl;
    }

    ngAfterViewInit() {
        this._cdr.detectChanges();
    }

    ngOnDestroy() {
        this._cdr.detach();
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    getDropdowns() {
        this._editPatientServ.getPriceSchedDrops().pipe(takeUntil(this.unsubscribe$)).subscribe(resp => {
            if (resp) {
                this.priceDrops = resp;
            }
        });
        this._editPatientServ.getDiscPriceSchedDrops().pipe(takeUntil(this.unsubscribe$)).subscribe(resp => {
            if (resp) {
                this.discPriceDrops = resp;
            }
        });
    }

    focusToFirst(event) {
        if (document.getElementById("SaveButton")) {
            event.preventDefault();
            setTimeout(() => {
                document.getElementById("SaveButton").focus();
            }, 10);

        }
    }
}
