<div class="chat-history" (scroll)="onScroll($event)">
        <div *ngIf="groupedMessages?.length === 0 && searchHistoryText" class="no-matches">
            No data found.
        </div>
        <ng-container *ngFor="let message of groupedMessages">
            <!-- Date Header -->
            <div *ngIf="message?.isHeader" class="date-header">
                <span>{{ message?.date }}</span>
            </div>
            <div *ngIf="!message?.isHeader" class="message-container"> 
                <!-- Message from the patient -->
                <div *ngIf="message?.sender?.identifierType === 3" class="spc-from-pharmacist">
                    <div class="spc-your-pharmacist">{{ message?.sender?.userName || 'Patient' }}</div>
                    <div class="spc-pharmacist-msg">
                        <span [innerHTML]="parseMessage(message?.message)"></span>
                    </div>
                    <div class="spc-recieved-date-time">{{ message?.date | date:'h:mm a' }}</div>
                </div>
        
                <!-- Message from the pharmacist -->
                <div *ngIf="message?.sender?.identifierType === 1" class="spc-frm-you">
                    <div class="spc-frm-patient">{{(message?.sender?.userName) ? message?.sender?.userName : pharmacyName}}</div>
                        <div class="spc-frm-msg">
                        <span [innerHTML]="parseMessage(message?.message)"></span>
                    </div>
                    <div class="spc-sent-date-time">
                        {{ message?.date | date:'h:mm a' }}
                        <svg
                            *ngIf="message?.status === 'sent'"
                            class="tick-icon"

                            width="14"
                            height="15"
                            viewBox="0 0 14 15"
                            [attr.fill]="message?.isRead ? '#019F08' : '#9094A0'" 
                            xmlns="http://www.w3.org/2000/svg"
                        ><title>Devlivered</title>
                            <path
                                d="M3.90838 10.788L0.612549 7.49219L1.4438 6.67552L3.92297 9.15469L4.73963 9.97135L3.90838 10.788ZM7.20422 10.788L3.90838 7.49219L4.72505 6.66094L7.20422 9.1401L12.5709 3.77344L13.3875 4.60469L7.20422 10.788ZM7.20422 7.49219L6.37297 6.67552L9.26047 3.78802L10.0917 4.60469L7.20422 7.49219Z"
                            />
                        </svg>
                        <svg
                            *ngIf="message?.status === 'queued'"
                            class="comm-check-icon"
                            xmlns="http://www.w3.org/2000/svg" 
                            height="15" 
                            viewBox="0 -960 960 960" 
                            width="15" 
                            fill="#f7941d">
                            <title>Queued</title>
                            <path d="m612-292 56-56-148-148v-184h-80v216l172 172ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-400Zm0 320q133 0 226.5-93.5T800-480q0-133-93.5-226.5T480-800q-133 0-226.5 93.5T160-480q0 133 93.5 226.5T480-160Z"/>
                        </svg>
                        <svg
                            *ngIf="message?.status === 'failed'"
                            class="comm-check-icon"
                            xmlns="http://www.w3.org/2000/svg" 
                            height="15" 
                            viewBox="0 -960 960 960" 
                            width="15" 
                            fill="#f6251a">
                            <title>Failed</title>
                            <path d="M480-280q17 0 28.5-11.5T520-320q0-17-11.5-28.5T480-360q-17 0-28.5 11.5T440-320q0 17 11.5 28.5T480-280Zm-40-160h80v-240h-80v240Zm40 360q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/>
                        </svg>
                    </div>
                </div>
            </div>
        </ng-container>
</div>