<ng-template #NDCOOC let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">Search All NDC Occurrence</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close"  (click)="closePopUp()">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="editpatient--body newrx--body edit--drug edit--drug">
            <div class="exprx--common-block">
                <div class="eprx--block__content">
                    <div class="text-right pull-right">
                        <eprx-radio [ControlName]="'IsCompound'" [FormGroupName]="NDCFG" [IsRequired]="true" [List]="['Show Compounds Only', 'All']"
                            [ValueList]="[false, true]" [IDForList]="['Comp', 'All']" [LabelForId]="" (TriggerSelectValue)="getNdcOccData()">
                        </eprx-radio>
                    </div>
                    <div *ngIf="ndcOccData['Count'] > 0">
                        <wj-flex-grid #NDCOCC [headersVisibility]="'Column'" [itemsSource]="ndcOccData['wijimoSource']" [isReadOnly]="true" [columnPicker]="''"
                            [selectionMode]="'None'" (updatedView)="init(NDCOCC)">
                            <wj-flex-grid-column [header]="actvHeader" [binding]="actvHeader" [visible]="true" *ngFor="
                                    let actvHeader of actvHeaders;
                                    let i = index
                                " [width]="'*'">
                                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                    <div *ngIf="actvHeader === 'Drug Name'" [title] ="item[actvHeader] |  uppercase">
                                        {{item[actvHeader]}}
                                    </div>
                                    <div *ngIf="actvHeader === 'NDC' || actvHeader === 'Compound NDC' || actvHeader === 'Billing NDC' || actvHeader === 'Generic NDC' || actvHeader === 'Replacement NDC'"  >
                                        {{item[actvHeader] | mask : "AAAA0-0000-00"}}
                                    </div>
                                    <div *ngIf="!(actvHeader === 'Drug Name' || actvHeader === 'NDC' || actvHeader === 'Compound NDC' || actvHeader === 'Billing NDC' || actvHeader === 'Generic NDC' || actvHeader === 'Replacement NDC' )">
                                        {{item[actvHeader]}}
                                    </div>
                                   </ng-template>
                            </wj-flex-grid-column>
                        </wj-flex-grid>
                        <app-eprx-pagination [TotalCount]="ndcOccData['Count']" [PageSize]="Size" [WijmoName]="NDCOCC" [GridName]="'NDCOCC'" (OnPageSelected)="setPage($event, NDCOOC)"
                            (OnSizeSelected)="setSize(Size, NDCOOC)" [FromModal]="true"></app-eprx-pagination>
                    </div>
                </div>
                <div *ngIf="ndcOccData['Count'] === 0" class="eprx--block__footer">
                    <div>
                        <span></span>
                        <i class="fa fa-file"></i><br/>
                        No Data Found!
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="text-right pull-right col-md-10 padding-0">
            <button type="button" autofocus class="hotkey_primary" (click)="closePopUp()" appShortcutKey InputKey="c">
               <b>C</b> Cancel
            </button>
        </div>
    </div>
</ng-template>
