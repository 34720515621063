<div class="exprx--common-block">
    <div class="eprx--block__header">
        <div class="eprx--header__heading">Other Information</div>
    </div>
    <div class="eprx--block__content">
        <div class="row">
            <div class="col">
                <eprx-input
                    [RxSelectId]="'patOthChrtId'"
                    [LabelText]="'Patient Chart#'"
                    [PlaceHolder]="'Patient Chart#'"
                    [ControlName]="'ChartNum'"
                    [FormGroupName]="PatientChartNums"
                    [InputErrors]="PatientChartNums?.get('ChartNum')?.errors"
                    [ErrorDefs]="{ pattern: 'Invalid!' }"
                    [ValidPattern]="regex?.IdNumeric"
                    [MaxLength]="150"
                    (TriggerOnShiftTabbing)="focusToElmById('PatIdVal')"
                >
                </eprx-input>
            </div>
            <div class="col">
                <eprx-select
                    [RxSelectId]="'patOthLang'"
                    [LabelText]="'Language'"
                    [PlaceHolder]="'Language'"
                    [ControlName]="'LanguageId'"
                    [secondCntrlN]="'LanguageCode'"
                    [FormGroupName]="Patient"
                    [InputErrors]="Patient?.get('LanguageId').errors"
                    [ErrorDefs]="{ required: 'Required' }"
                    [List]="systemData?.Lang"
                    [BindLabel]="'Name'"
                    [BindValue]="'Id'"
                    [bindScnCntrlV]="'LangCode'"
                    [LabelForId]="'Name'"
                    [IsRequired]="true"
                    [FormGroupInvalid]="Patient?.get('LanguageId').touched"
                >
                </eprx-select>
            </div>
        </div>
        <div class="row">
            <!-- <div class="col" *ngIf="systemData?.SplProgram">
                <eprx-select
                    [RxSelectId]="'patOthrSpl'"
                    [LabelText]="'Special Coverage'"
                    [PlaceHolder]="'Special Coverage'"
                    [ControlName]="'SplProgramId'"
                    [secondCntrlN]="'SplProgram'"
                    [FormGroupName]="PatientSplPrograms"
                    [InputErrors]="patientInfoFG?.get('SplProgramId')?.errors"
                    [List]="systemData?.SplProgram"
                    [HasMultiple]="false"
                    [BindLabel]="'Name'"
                    [BindValue]="'Id'"
                    [bindScnCntrlV]="'Name'"
                    [LabelForId]="'Name'"
                >
                </eprx-select>
            </div> -->
            <div class="col">
                <eprx-radio
                    [LabelText]="'Refill Reminder'"
                    [ControlName]="'IsRefillRemSet'"
                    [FormGroupName]="PatSettings"
                    [List]="['Yes', 'No']"
                    [Name]="'IsRefillRemSet'"
                    [ValueList]="[true,false]"
                    [IDForList]="['IsRefillRemSet1', 'IsRefillRemSet2']"
                >
                </eprx-radio>
            </div>
            <div class="col">
                <eprx-radio
                    [LabelText]="'Ez-cap?'"
                    [ControlName]="'IsEZCapReqd'"
                    [FormGroupName]="PatSettings"
                    [List]="['Yes', 'No']"
                    [Name]="'IsEZCapReqd'"
                    [ValueList]="[true, false]"
                    [IDForList]="['IsEZCapReqd1', 'IsEZCapReqd2']"
                >
                </eprx-radio>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <eprx-select
                    [RxSelectId]="'patPkgVendor'"
                    [LabelText]="'Multi-Med Packaging'"
                    [PlaceHolder]="'Multi-Med Packaging'"
                    [ControlName]="'InterfaceId'"
                    [secondCntrlN]="'MultiPackagingCode'"
                    [FormGroupName]="Patient"
                    [List]="systemData?.Interfaces"
                    [BindLabel]="'Name'"
                    [BindValue]="'Id'"
                    [bindScnCntrlV]="'Name'"
                    [LabelForId]="'Name'"
                    (TriggerSelectValue)="onSelectPkg($event?.value)"
                >
                </eprx-select>
            </div>
            <div class="col margin_top-20" [formGroup]="PatSettings">
                <input
                    class="form-check-input"
                    type="checkbox"
                    id="drugCounclng1"
                    formControlName="IsPrintAllowed"
                />
                <label class="form-check-label" for="drugCounclng1">
                    Print Drug Counselling?
                </label>
            </div>
        </div>
        <div class="row">
            <div class="col margin-top">
                <eprx-radio
                    [LabelText]="'Active'"
                    [ControlName]="'ActiveStatusId'"
                    [FormGroupName]="Person"
                    [List]="['Yes', 'No']"
                    [Name]="'ActiveStatusId'"
                    [ValueList]="[1, 2]"
                    [IDForList]="['ActiveStatusId1', 'ActiveStatusId2']"
                >
                </eprx-radio>
            </div>

            <div class="col-6 margin-top_340B" *ngIf="(Is340BPharm$ | async)">
                <eprx-check-box [LabelText]="'340B'" [ControlName]="'Is340B'" [FormGroupName]="Patient" (TriggerChangeValue)="checkFor340BIns()">
                </eprx-check-box>
            </div>
        <!-- </div>
        <div class="row"> -->
            <!-- <div [ngClass]="(Is340BPharm$ | async) ? 'offset-6 col-6 margin-top_workflow' : 'col-6 margin-top_340B'" [formGroup]="PatSettings">
                <input
                    class="form-check-input"
                    type="checkbox"
                    id="workFlowPatient1"
                    formControlName="IsWorkflowPatient"
                />
                <label class="form-check-label" for="workFlowPatient1">
                    Workflow Patient?
                </label>
            </div> -->
        </div>
    </div>
</div>

<ng-template #PatchIns340B let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Set 340B as Primary Insurance</h4>
        <button type="button" [tabindex] class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>Do you want to set 340B insurance <b>"{{Insu340B ? (Insu340B.PlanCode | uppercase) : ''}}"</b> as Primary Insurance?</p>
    </div>
    <div class="modal-footer">
        <button autofocus type="button" class="hotkey_primary"
        (click)="checkFor340BIns(true); d('Cross click')"
        (keydown.enter)="checkFor340BIns(true);d('Cross click')" appShortcutKey InputKey="y"><b>Y</b>Yes</button>
        <button  type="button" class="hotkey_primary"
        (click)="d('Cross click');closePopUpFor340BInsurance()"
        (keydown.enter)="d('Cross click')" appShortcutKey InputKey="n"><b>N</b>No</button>
    </div>
</ng-template>
