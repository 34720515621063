import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { spcCloudComponent } from './spc-cloud/spc-cloud.component';

const routes: Routes = [
    { path: "", component: spcCloudComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class spcCloudRoutingModule { }
