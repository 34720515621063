import {
    Component,
    OnInit,
    Input,
    ViewChild,
    EventEmitter,
    Output,
    OnDestroy,
    HostListener
} from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { RxService, AlertService, RxObservableService, CommonService } from "src/app/services";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs/internal/Subscription";
import { RxStore } from "src/app/store";

@Component({
    selector: "app-mar",
    templateUrl: "./mar.component.html",
    styles: []
})
export class MARComponent implements OnInit, OnDestroy {
    rxPatientMAR: any;
    modelRef: any;
    selectedMAR: any;
    actvRouteName: any;
    showPopUp = true;
    subscriptions = new Subscription();
    MarFG: FormGroup;
    routeFrom: any;

    @Input()
    set MARPatientInfo(data: any) {
        this.rxPatientMAR = data;
        // setTimeout(() => {
        //     this.openMARPopupModal();
        // }, 0);
    }
    @Input()
    set SavedData(data) {
        if (data) {
            this.MarFG.controls["FacilityId"].patchValue(data["Code"]);
        }
    }

    @Output()
    SaveMARInfo = new EventEmitter<any>();

    @Output()
    IsPopUpClosed = new EventEmitter<any>();

    @ViewChild("MARMODAL")
    MARMODAL: any;

    @HostListener("window:keydown", ["$event"])
    keyEventEsc(event: KeyboardEvent) {
        if (event.which === 27 && this.modelRef) { // esc
            this.modelRef.close();
            this.modelRef = null;
            event.preventDefault();
        }
    }


    constructor(
        private _modalService: NgbModal,
        private _rxService: RxService,
        private _alertServ: AlertService,
        private _actvRoute: ActivatedRoute,
        private _fb: FormBuilder,
        private _rxStore: RxStore,
        private _commonServ: CommonService
    ) {
        this.MarFG = this._fb.group({
            FacilityId: null
        });
        this.subscriptions.add(this._actvRoute.queryParams.subscribe(params => {
            if (Object.keys(params).length > 0) {
                this.routeFrom = params.fm;
            }
        }));
    }

    ngOnInit() {
        this.subscriptions.add(
            this._actvRoute.url.subscribe(params => {
                if (this._actvRoute.snapshot.routeConfig) {
                    this.actvRouteName = this._actvRoute.snapshot.routeConfig.path;
                } else {
                    this.actvRouteName = null;
                }
            })
        );

        if (this.actvRouteName === "mar") {
            this.showPopUp = false;
            this.rxPatientMAR = JSON.parse(this._commonServ.DecryptData(localStorage.getItem("MarInfo")));
        } else {
            this.showPopUp = true;
        }
    }

    openMARPopupModal() {
        this.modelRef = this._modalService.open(this.MARMODAL, {keyboard: false});
    }

    closeModal() {
        // this.modelRef.close();
        this.IsPopUpClosed.emit("Patient1");
    }

    selectedMARObj(marObj) {
        this.selectedMAR = marObj;
    }

    saveMAR() {
        // this.modelRef.close();
        let marObj = {
            Id: null,
            Code: this.selectedMAR.Code,
            Name: this.selectedMAR.Name,
            Remarks: null,
            TenantId: null,
            IsActive: true,
            CreatedDtTm: null,
            ModifiedDtTm: null
        };
        this.subscriptions.add(
            this._rxService.saveMARData(marObj).subscribe(resp => {
                if (resp) {
                    this._alertServ.success("MAR data saved successfully.");
                    this.closeModal();
                } else {
                    this._alertServ.error("MAR data save unsuccessful.");
                }
            })
        );
    }

    saveRxLoc() {
        // this.modelRef.close();
        let marObj = {
            Id: null,
            Code: this.selectedMAR.Code,
            Name: this.selectedMAR.Name,
            Remarks: null,
            TenantId: null,
            IsActive: true,
            CreatedDtTm: null,
            ModifiedDtTm: null
        };
        // this.SaveMARInfo.emit(marObj);
        this._rxStore.storeMarDetails(marObj);

        this._alertServ.success("MAR data saved successfully.");
    }

    ngOnDestroy() {
        if (this.subscriptions) {
            this.subscriptions.unsubscribe();
        }
    }
}
