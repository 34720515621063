<div class="exprx--common-block erx--body drug-verify-body">
    <div class="eprx--block__header">
        <div class="eprx--header__heading">
            <div class="col-sm-12 padding-0">
                <div class="row">
                    <div class="col-md-11">
                        <span> Drug Pick Verification</span>
                    </div>
                    <!-- <div class="col-md-1">
                        <button appShortcutKey type="submit" class="hotkey_success pull-right" (keydown.tab)="focusToFirst($event)"
                        (click)="redirectToDPV()" [AltKey]="'c'">
                        <span>C</span> Cancel
                    </button>
                    </div> -->
                    
                </div>
            </div>
        </div>
    </div>
    <div class="eprx--block__content">
        <div class="col-sm-12 padding-0">
            <!-- <div class="col-sm-12 water_mark_dpvStatus" [ngStyle]="{'color':StatusId?.bgColor}">
                {{ StatusId?.status }}
            </div> -->
            <div class="row">
               
               
           
            <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5 pat-pre-drug-block small--erx__screen ">
                    <div class="row">
                        <div class="col-12">
                            <app-electronic-rx-doc [ErxDocData]="detailsInfo" [RxInfoFromVerifications]="rxInfo" *ngIf="(rxInfo && rxInfo.XmlFileId) && (fileTypeToShow === 'erxDetails')" [IsFromRoute]="'dpv'">
                            </app-electronic-rx-doc>
                            <div class="exprx--common-block erx__Doc--section"  *ngIf="((fileTypeToShow !== 'erxDetails' && fileTypeToShow !== 'rxDetails') || (!totalDocs?.length))">
                                <!-- <div class="eprx--block__content erx__doc--body text-center overflow_y_scroll height_56rem"> -->
                                    <div class="eprx--block__content erx__doc--body text-center overflow_y_scroll overflow_x_hidden height_54rem">

                                    <!-- <div class="row margin_top_2rem "  *ngIf="hasDocuments" > -->
                                       <!-- <div class="row margin_top_2rem overflow_y_scroll "  *ngIf="hasDocuments" >
                                        <div class="col-md-12">
                                            <wj-flex-grid #documentInfo class="grid" [headersVisibility]="'Column'"
                                                [itemsSource]="documentsList" [isReadOnly]="true"
                                                [selectionMode]="'None'">
                                                <wj-flex-grid-column [header]="actvHeader" [binding]="actvHeader"
                                                    [visible]="true"
                                                    *ngFor="let actvHeader of ['Actions' , 'FileName']; let i = index"
                                                    title="{{ actvHeader }}"
                                                    [width]="actvHeader === 'Actions' ? 100 : '*'">
                                                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'"
                                                        let-item="item">
                                                        <div *ngIf="actvHeader === 'Actions'"
                                                             class="text-center">
                                                            <i class="far fa-tv actions margin_left_10px"
                                                                title="Preview" (click)="previewSelectedFile(item?.data)"></i>
                                                        </div>
                                                        <div *ngIf="!(actvHeader === 'Actions')">
                                                            {{ item[actvHeader] }}
                                                        </div>
                                                    </ng-template>
                                                </wj-flex-grid-column>
                                            </wj-flex-grid>
                                        </div>
                                    </div>-->
                                    <!-- <div class="row" *ngIf="fileTypeToShow && documentAvailable && hasDocuments"> -->
                                       <!-- <div class="row overflow_y_scroll" *ngIf="fileTypeToShow && documentAvailable && hasDocuments">
                                        <div class="col-md-12">
                                            <iframe *ngIf="fileTypeToShow == 'pdf'" [src]="pdfToShow"
                                                class="text-center w-100 border_3px_solid_black" toolbar="0" width="100%" height="500px"
                                                type="application/pdf"
                                               ></iframe>
                                             <img *ngIf="fileTypeToShow == 'image'" [src]="imageToShow"
                                                style="width: 100%; border: 3px solid black;"> 
                                                 <ngx-image-zoom *ngIf="fileTypeToShow == 'image'" [thumbImage]=imageToShow [fullImage]=imageToShow
                                        [magnification]="1" [enableScrollZoom]="true" [enableLens]="true" [lensHeight]="100" [lensWidth]="200">
                                    </ngx-image-zoom> 
                                        </div>
                                    </div>-->
                                    <div class="row" *ngIf="fileTypeToShow && documentAvailable && hasDocuments">
                                        <div class="col-md-12">
                                            <iframe *ngIf="fileTypeToShow == 'pdf'" [src]="pdfToShow" class="text-center w-100 border_3px_solid_black"
                                                toolbar="0" width="100%" height="600px" type="application/pdf"
                                                ></iframe>
        
                                            <!-- <img *ngIf="fileTypeToShow == 'image'" [src]="imageToShow"
                                                style="width: 100%; border: 3px solid black;height: 40rem"> -->
        
                                              <lib-ngx-image-zoom *ngIf="fileTypeToShow == 'image'" [thumbImage]=imageToShow [fullImage]=imageToShow
                                                [magnification]="1" [enableScrollZoom]="true" [enableLens]="true" [lensHeight]="100" [lensWidth]="200">
                                            </lib-ngx-image-zoom>
                                        </div>
                                    </div>
                                    <div class="row padding_left_12rem"  *ngIf="!hasDocuments">
                                        <h2>No Documents for this Rx</h2>
                                    </div>
                                </div>
                            </div>
                            <app-electronic-rx-doc [RxInfoFromVerifications]="rxInfo" *ngIf="(rxInfo && !rxInfo.XmlFileId) && (fileTypeToShow === 'rxDetails')" [IsFromRoute]="'dpv'">
                            </app-electronic-rx-doc>
                            <div class="row" *ngIf="totalDocs?.length">
                                <div class="col-12 text-center">
                                    <div class="d-inline">
                                        <i class="far fa-step-backward actions text-primary font_size_1_5rem"  (click)="navigateToNextImage(false)"></i>
                                    </div>
                                    <div class="d-inline px-2">
                                        <label class="font_size_1_5rem" > {{currentDocIndex + 1}} of {{totalDocs?.length}}</label>
                                    </div>
                                    <div class="d-inline">
                                        <i class="far fa-step-forward actions text-primary font_size_1_5rem"  (click)="navigateToNextImage(true)"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
               <div class="col-lg-7 col-md-7 col-sm-7 col-xs-7">
                <div class="eprx--block__content border_AllRadius insurance--body border_color_blue">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="row">
                            <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12">
                                <label>Rx#</label><br>
                                <div class="label--data word-break">
                                    {{RxRefillFG?.value?.RxNum}}-{{RxRefillFG?.value?.RefillNum}}{{RxRefillFG?.value?.PartialfillNum ? '-'+RxRefillFG?.value?.PartialfillNum : ''}}
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                <label>Patient Name</label><br>
                                <div class="label--data word-break">
                                    {{ rxInfo ? rxInfo?.Patient?.lastname + ", " + rxInfo?.Patient?.firstname : "--" }}
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                <label>Prescriber Name</label><br>
                                <div class="label--data word-break">
                                    {{ rxInfo ? rxInfo?.Prescriber?.prescriberlastname + ", " + rxInfo?.Prescriber?.prescriberfirstname : "--" }}
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12">
                                <label>Date Filled</label><br>
                                <div class="label--data word-break">
                                    {{ rxInfo ?.PrescReFill ?.FillDtTm | localTime | date : "MM/dd/yyyy" : "--" }}
                                </div>
                            </div> 
                        </div>
                    </div>
                    <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                        <div class="row">
                            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                <label class="align-label d-block">Rx Status</label>
                                <div class="label--data d-inline rxStatus" 
                                [ngStyle]="{'backgroundColor':StatusId?.bgColor, 
                                'color': StatusId?.colorText}">
                                    {{ StatusId?.status }}
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                <label class="align-label">Ez-cap?</label>
                                <div class="label--data ">
                                    {{ rxInfo?.Patient?.isEZCapReq  ? 'Yes' : 'No'}}
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                <label class="align-label">Qty in Hand</label>
                                <div class="label--data ">
                                    {{ rxInfo ? (_buckUtils.getQtyInHand(rxInfo.Drug?.manageinventory ? rxInfo.Drug?.manageinventory : null, rxInfo.PrescReFill.BucketId)) : "0.000"}}
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                <label>Days Supply</label>
                                <div class="label--data">
                                    {{ rxInfo ? rxInfo ?.PrescReFill ?.SupplyDays : "--" }}
                                </div>
                            </div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12" [title]="!showNdc ? 'NDC is not visible as the setting on Pharmacy Settings tab is turned off for Show Rx Drug NDC in DPV.' : ''">
                                <label class="pr-2">NDC</label> 
                                <app-common-dscsa-info *ngIf="DrugSlctd || (rxInfo?.PrescReFill?.Id && rxInfo?.IsDSCSAExists)" [DrugSlctd]="DrugSlctd" [FromScreen]="'Captured from DPV'" [PrescrefillId]="rxInfo?.PrescReFill?.Id" (DrugRunDateAndTime)="getSerilizedDatetime($event)"></app-common-dscsa-info>
                                <div class="label--data">
                                    <ng-container *ngIf="showNdc; else elseTemplate">
                                        {{ this._formatsUtil.getNDCFormat(rxInfo?.Drug ?.ndc) }}
                                    </ng-container>
                                    <ng-template #elseTemplate>
                                        XXXXX-XXXX-XX
                                    </ng-template>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                <label>Drug Name</label>
                                <div class="label--data height_4rem background_antiquewhite">
                                    {{ rxInfo ? rxInfo?.Drug?.drugname + " " + rxInfo?.Drug?.strength + " " + rxInfo?.Drug?.drugformname
                                    : "--" }}
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12">
                                <label class="align-label">Qty. in Pack</label>
                                <div class="label--data">
                                    {{ (rxInfo ? rxInfo ?.Drug ?.qtypack : 0) | number : '1.3-3' }}
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                <label>Billed Qty. Dispensed</label>
                                <div class="label--data label-weight font_size_1_7rem">
                                    {{ (rxInfo ? rxInfo?.PrescReFill?.DispQty : 0)| number : '1.3-3' }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12" *ngIf="noImgFnd">
                        <label>Drug Description</label>
                        
                        <div class="eprx--block__content border_AllRadius insurance--body border_color_blue" >
                            <span *ngIf="drugDesc; else elseTemplate1">
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                        <label class="align-label">Shape</label>
                                        <div class="label--data">
                                            {{drugDesc?.Shape}}
                                        </div>
                                        </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                        <label class="align-label">Coating</label>
                                        <div class="label--data">
                                            {{drugDesc?.CoatingName}}
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                        <label class="align-label">Color Front</label>
                                        <div class="label--data">
                                            {{drugDesc?.Color_Frnt}}
                                        </div>
                                    
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                        <label class="align-label">Color Back</label>
                                        <div class="label--data">
                                            {{drugDesc?.Color_Back}}
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                        <label class="align-label">Side1</label>
                                        <div class="label--data">
                                            {{drugDesc?.ID_FRONT}}
                                        </div>
                                    
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                        <label class="align-label">Side2</label>
                                        <div class="label--data">
                                            {{drugDesc?.ID_BACK}}
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                        <label class="align-label">Flavors</label>
                                        <div class="label--data">
                                            {{drugDesc?.FlavorName}}
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                        <label class="align-label">Scoring</label>
                                        <div class="label--data">
                                            {{drugDesc?.ScoringName}}
                                        </div>
                                    </div>
                                </div>
                            </span>
                            <ng-template #elseTemplate1>
                            No Drug Description available
                            </ng-template>
                        </div>
                    
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12" *ngIf="!noImgFnd">
                        <label>Drug Image</label>
                        <div class="col-sm-12 padding-0">
                            <img class="tablate--preview width_140px" src="{{ imgURL }}"
                                 />
                        </div>
                    </div>
                </div>
                <br>
                   <div class="col-sm-12 padding-0 ">
                    <div class="row ">
                        <div class="col-sm-5 form-group custom--input" [formGroup]="RxRefillFG" [title]="forceBarcodeScanSetting ? 'Force bottle scan setting is turned ON from Account settings (Pharmacy Settings Tab)' : ''" *ngIf="!newRecordAdded && (TotalDispVal  !== (rxInfo ? +(rxInfo?.PrescReFill?.DispQty) : 0))">
                            <label class="control-label">
                                <span *ngIf = "forceBarcodeScanSetting"> Please scan the NDC from the bottle/package</span>
                                <span *ngIf = "!forceBarcodeScanSetting"> Please enter the NDC or scan the bottle/package</span>
                                <input type="text" #searchBoxInsType class="form-control search--input__box"  [value]="InputNDC" 
                                [InputType]="'ALPHANUMERIC'"   [mask]="'A*'"   [id]="'ndcScan'"
                                    [placeholder]="forceBarcodeScanSetting ? 'Please scan the NDC from the bottle/package' :'Please enter the NDC or scan the bottle/package'" [appAutofocus]="true" [title]="forceBarcodeScanSetting ? 'Force bottle scan setting is turned ON from Account settings (Pharmacy Settings Tab)' : 'NDC Number'" (keyup.enter)="getNdcDetails($event)"
                                    [BarcodeDetector]="true" (EmitBarcode)="searchForBarcode($event)"  (keydown.Tab)="getNdcDetails($event)">
                            </label>
                        </div>
                        <div class="text-right mt-3" [ngClass]="!newRecordAdded && (TotalDispVal  !== (rxInfo ? +(rxInfo?.PrescReFill?.DispQty) : 0)) ? 'col-sm-7' : 'col-sm-12'">
                            <button id="AddNotes" type="submit" class="hotkey_success"
                            (click)="openNotesModel('rx')" appShortcutKey [AltKey]="'n'">
                            <span>N</span> Add Notes
                        </button>
                        </div>
                        <hr />
                        <!-- <div class="col-sm-12 show-transistion " [ngClass]="
                                                hideNDCForm
                                                    ? 'hide-transistion'
                                                    : 'show-transistion'"><hr />
                            <div class="col-sm-12 padding-0 height_16_5rem">
                                <div class="row ">
                                    <div class="col-md-6 ">
                                        <div class="row ">
                                            <div class="col-12 ">
                                                <label class="align-label ">
                                                    Drug Name</label>
                                                <div class="label--data background_offwhite height_6rem">
                                                    {{ rxInfo ? rxInfo .Drug .drugname + " " + rxInfo .Drug .strength + " " + rxInfo .Drug .drugformname
                                                                    : "--" }}
                                                </div>
                                            </div>
                                            <div class="col-12 ">
                                                <label class="align-label ">Manufacturer</label>
                                                <div class="label--data ">
                                                    {{ rxInfo ? rxInfo ?.Drug ?.manufacturername : "--" }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-sm-6 col-xs-12 ">
                                        <div class="col-sm-12 padding-0 ">
                                            <img class="tablate--preview width_180px" src="{{ imgURL }}"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                    </div>
                   </div>
                </div>
                <br>
                   <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0 mt-3" *ngIf="iouRecs?.length">
                    <div class="col-12 text-right">
                        <button  class="submit print-med-guide background_orange"  (click)="toggleColorLegend()">Color Legend</button>
                    </div>
                    <wj-flex-grid #flex [headersVisibility]="'Column'" [itemsSource]="IOUListWJ"
                    [columnPicker]="'DPVVerfListWJ'"  [allowSorting]="true" (initialized)="init(flex)" [itemFormatter]="itemFormatter"
                    >
                    <wj-flex-grid-column [header]="'Action'" [width]="59" [isReadOnly]="true">
                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                            <i *ngIf="(!rxInfo?.PrescReFill?.IsIou && item?.IsPicked)" class="far fa-trash-alt actions in-active line_height_2rem"  title="Cannot delete as Rx is PickedUp and Completely Dispensed"></i>
                            <i *ngIf="!(!rxInfo?.PrescReFill?.IsIou && item?.IsPicked)" class="far fa-trash-alt actions danger-actve line_height_2rem" title="Delete" (click)="openDeleteConfirm(deleteConfirm)"></i>
                            <i *ngIf="(iouRecs?.[item?.RowId]?.IsDPVVerfied)" class="far fa-print actions" title="Print Label" (click)="printLabel(item?.RowId)"></i>
                            <i *ngIf="!(iouRecs?.[item?.RowId]?.IsDPVVerfied)" class="far fa-print in-active"></i>
                            <ng-template #deleteConfirm let-c="close" let-d="dismiss" class="el--popup">
                                <app-custom-modal (oncrossBtnClick)="closeDeleteModal()">
                                    <h4 header>{{warningText?.deleteHeaderTxt}}</h4>
                                    <span body>
                                        {{warningText?.deleteConfrmMsg}}
                                    </span>
                                    <button autofocus  footer  type="button" class="hotkey_primary" (click)="c('Close click')"
                                    (click)="deleteIOU(item)" appShortcutKey InputKey="y"><b>Y</b> YES </button>
                                    <button footer type="button" class="hotkey_primary" (click)="closeDeleteModal()" appShortcutKey InputKey="n"><b>N</b> NO</button>
                                </app-custom-modal>
                            </ng-template>
                        </ng-template>
                    </wj-flex-grid-column>
                    <wj-flex-grid-column [header]="rxheader['hName']" [binding]="rxheader['hName']"
                    [visible]="rxheader['isVisible']" [width]="rxheader['width']"
                    *ngFor="let rxheader of wjHeaders; let i = index" [isReadOnly]="true">
                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-cell="cell">
                        <div *ngIf="rxheader['hName'] === 'Drug NDC' && item[rxheader['hName']]"  class="line_height_2-4rem">
                            {{ this._formatsUtil.getNDCFormat(item[rxheader['hName']])}}
                            <span [ngbTooltip]=patToolTipCont placement="right" container="body" tooltipClass="DPVVerf-tooltip" container="body" triggers="mouseover:mouseleave" [placement] = "(cell.col.index>=0 && cell.col.index < 2) ? 'right' : (cell.col.index>=2 && cell.col.index <4)?'top':'left'"
                                 class="d-inline ml-0 mr-2 padding_0_0_4_rem font-weight-bold color_white height_23px width_23px text-center font_size_1_2rem background_orange border_radius_50per">
                                𝒊</span>
                                <ng-template #patToolTipCont>
                                    <div>
                                        <div class="row text-left" *ngIf="item['IsRefrigeratorItem']">
                                            <div class="col-sm-12 water_mark_storageItem" [ngStyle]="{'color':StatusId?.bgColor}">
                                            Refrigerated
                                            </div>
                                        </div>
                                        <div class="row text-left">
                                            <!-- <div class="col-md-12">
                                                <label class="text-decoration-underline margin_bottom_1rem">Patient Info</label>
                                            </div> -->
                                            <div class="col-lg-9 col-md-9">
                                                <div class="row">
                                                    <div class="col-lg-5 col-md-5">
                                                        <label>Verfn. Source :&nbsp;&nbsp;</label>
                                                        <span class="label--data">{{item.Source ? (item.Source | titlecase) : '--'}}</span>
                                                    </div>
                                                    <div class="col-lg-7 col-md-7">
                                                        <label>User :&nbsp;&nbsp;</label>
                                                        <span class="label--data">{{item.UserID ? (item.UserID | titlecase) : '--'}}</span>
                                                    </div>
                                                </div>
                                                <br>
                                                <div class="row">
                                                    <div class="col-lg-5 col-md-5">
                                                        <label>Drug Lot# :&nbsp;&nbsp;</label>
                                                        <span class="label--data">{{item.LotNo ? item.LotNo : '--'}}</span>
                                                    </div>
                                                    <div class="col-lg-7 col-md-7">
                                                        <label>Expiration Date :&nbsp;&nbsp;</label>
                                                        <span class="label--data">{{item.ExpirationDate ? item.ExpirationDate : '--'}}</span>
                                                    </div>
                                                </div>
                                                <br>
                                                <div class="row">
                                                    <div class="col-lg-5 col-md-5">
                                                        <label>Serial# :&nbsp;&nbsp;</label>
                                                        <span class="label--data">{{item.SerialNo ? item.SerialNo : '--'}}</span>
                                                    </div>
                                                    <div class="col-lg-7 col-md-7">
                                                        <label>GTIN# :&nbsp;&nbsp;</label>
                                                        <span class="label--data">{{item.GTIN ? item.GTIN : '--'}}</span>
                                                    </div>
                                                </div>
                                                <br>
                                                <div class="row">
                                                    <div class="col-lg-5 col-md-5">
                                                        <label>Date & Time :&nbsp;&nbsp;</label>
                                                        <span class="label--data">{{item.DateTm ? item.DateTm : '--'}}</span>
                                                    </div>
                                                    <div class="col-lg-7 col-md-7">
                                                        <label>Manufacturer :&nbsp;&nbsp;</label>
                                                        <span class="label--data">{{item.Manufacturer ? item.Manufacturer : '--'}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-md-3">
                                                <div class="text-center">
                                                    <label>Drug Image</label>
                                                </div>
                                                
                                                <div class="col-sm-12 padding-0 edit--drug">
                                                <img class="Drug_tablate--preview" src="{{ item.ImgUrl }}"
                                                 />
                                                </div>
                                            </div>    
                                        </div>
                                        <!-- <div class="col-sm-12"> -->
                                            <br>
                                            <div class="row text-left">
                                                <div class="col-sm-12">
                                                    <label class="text-decoration_underline">Drug Description</label>
                                                    <div><br></div>
                                                    <div class="eprx--block__content border_AllRadius insurance--body border_color_blue" >
                                                            <div class="row">
                                                                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                    <label>Shape :&nbsp;&nbsp;</label>
                                                                    <span class="label--data">
                                                                       
                                                                        {{item?.DrugDescription?.Shape ? item?.DrugDescription?.Shape : '--'}}
                                                                    </span>
                                                                </div>
                                                                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                    <label>Color Front :&nbsp;&nbsp;</label>
                                                                    <span class="label--data">
                                                                        
                                                                        {{item?.DrugDescription?.Color_Frnt ? item?.DrugDescription?.Color_Frnt : '--'}}
                                                                    </span>
                                                                </div>
                                                                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                    <label>Color Back :&nbsp;&nbsp;</label>
                                                                    <span class="label--data">
                                                                       
                                                                        {{item?.DrugDescription?.Color_Back ? item?.DrugDescription?.Color_Back : '--'}}
                                                                    </span>
                                                                </div>
                                                            </div><br>
                                                            <div class="row">
                                                                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                    <label>Side1 :&nbsp;&nbsp;</label>
                                                                    <span class="label--data">
                                                                        
                                                                        {{item?.DrugDescription?.ID_FRONT ? item?.DrugDescription?.ID_FRONT : '--'}}
                                                                    </span>
                                                                </div>
                                                                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                    <label>Side2 :&nbsp;&nbsp;</label>
                                                                    <span class="label--data">
                                                                        
                                                                        {{item?.DrugDescription?.ID_BACK ? item?.DrugDescription?.ID_BACK : '--'}}
                                                                    </span>
                                                                </div>
                                                                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                    <label>Flavours :&nbsp;&nbsp;</label>
                                                                    <span class="label--data">
                                                                   
                                                                        {{item?.DrugDescription?.FlavorName ? item?.DrugDescription?.FlavorName : '--'}}
                                                                    </span>
                                                                </div>
                                                            </div><br>
                                                            <div class="row">
                                                                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                    <label>Scoring :&nbsp;&nbsp;</label>
                                                                    <span class="label--data">
                                                                        {{item?.DrugDescription?.ScoringName ? item?.DrugDescription?.ScoringName : '--'}}
                                                                    </span>
                                                                </div>
                                                                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                    <label>Coating :&nbsp;&nbsp;</label>
                                                                    <span class="label--data">
                                                                        {{item?.DrugDescription?.CoatingName ? item?.DrugDescription?.CoatingName : '--'}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                    </div>
                                                </div>
                                            </div>
                                        <!-- </div> -->
                                    </div>
                                </ng-template>
                            <app-common-dscsa-info *ngIf="DrugSlctd" [DrugSlctd]="DrugSlctd" [FromScreen]="'Captured from DPV'" [PrescrefillId]="rxInfo?.PrescReFill?.Id"  [IsDrugFormBarcode]="true" (DrugRunDateAndTime)="getSerilizedDatetime($event)"></app-common-dscsa-info>

                            <!-- </span> -->
                        </div>
                        <div *ngIf="!item[rxheader['hName']] && rxheader['hName'] === 'Drug NDC'">
                            <div class="col-sm-12 padding-0 ">
                                <div class="row ">
                                    <div class="col-sm-12 " [formGroup]="RxRefillFG">
                                        <input type="text" #searchBoxInsType class="form-control search--input__box" [value]="InputNDC" [id]="'ndcScanInput'"
                                        [InputType]="'ALPHANUMERIC'"   [mask]="'A*'" [placeholder]="'Please scan the NDC'" [appAutofocus]="true" [title]="forceBarcodeScanSetting ? 'Force bottle scan setting is turned ON from Account settings (Pharmacy Settings Tab)' : 'NDC Number'" (keyup.enter)="getNdcDetails($event)"
                                                [BarcodeDetector]="true" (EmitBarcode)="searchForBarcode($event)"  (keydown.Tab)="getNdcDetails($event)">
                                    </div>
                                </div>
                            </div>   
                        </div>
                        <div *ngIf="rxheader['hName'] === 'Actual Qty Disp'" [ngClass]="{'has-error':(errorMessage && item?.errorMessage)}">
                            <fieldset
                            [disabled]="(!rxInfo?.PrescReFill?.IsIou && item?.IsPicked) || !item['Drug NDC'] ? true : false">
                            <input type="number" name="searchBoxCityType"  autofocus id="DispId" class="form-control" [ngClass]="errorMessage && item?.errorMessage ? 'pl-5' : '' " value="{{item[rxheader.hName]}}"  min="1" [(ngModel)]="item[rxheader.hName]" [dropSpecialCharacters]="true" (keydown.alt.v)="validateVal(item, item.RowId, $event)"
                            (change)="validateVal(item, item.RowId, $event)"
                            [title]="(!rxInfo?.PrescReFill?.IsIou && item?.IsPicked) ? 'Cannot edit as Rx is PickedUp and Completely Dispensed' : !item['Drug NDC'] ? 'Please Scan the NDC to edit' : ''">
                        </fieldset>
                            <span *ngIf="errorMessage && item?.errorMessage"
                                class="help--text__danger ml-2" title="{{item?.errorMessage}}">
                                <i class="anime-shake far fa-exclamation-circle error_Placement font_size_1_2rem" ></i>
                            </span>
                        </div>
                        <div *ngIf="rxheader['hName'] === 'Lot'" class="col-12 mb-0 pl-0 inline-Dropdown--height">
                                <eprx-select [IsAppendTo]="true" [cssStyle]="true"
                                [PlaceHolder]="'Drug Lot #'" [HasControl]="false" [List]="item.drugLotInfo" [InputValue]="item.LotNo"
                                [BindLabel]="'LotNum'" [BindValue]="'LotNum'" [LabelForId]="'LotNum'" [AddItem]="true"  
                                (TriggerSelectValue)="enteredDrugLot(item, item.RowId,$event,true)" [ErrorDefs]="{required: 'Required'}">
                            </eprx-select>
                        </div>
                        <div *ngIf="rxheader['hName'] === 'Expiration Date'" class="col-12 mb-0 pl-0 inline-Dropdown--height">
                                <eprx-date-picker 
                                [PlaceHolder]="''"
                                [HasControl]="false" 
                                [IsAppendTo]="true"                               
                                (TriggerOnEnterValue)="enteredDrugLot(item,item.RowId,$event,false)"
                                [InputValue]="item.ExpirationDate"
                                (TriggerOnDateSelect)="enteredDrugLot(item,item.RowId,$event,false)"   
                                (TriggerOnTabbing)="enteredDrugLot(item,item.RowId,$event,false)"     (EmitBlurValue)="enteredDrugLot(item,item.RowId,$event,false)" 
                                [isWholeEventRequired]="true"                    
                                [MinDate]="minDate"
                                [ErrorDefs]="{ required: 'Required', minDate: 'Expired Date' }"
                            ></eprx-date-picker>
                        </div>
                        <div
                            *ngIf="!(rxheader.hName === 'Drug NDC' || rxheader['hName'] === 'Actual Qty Disp' || rxheader['hName'] === 'Drug Name' || rxheader['hName'] === 'Lot' || rxheader['hName'] === 'Expiration Date')"  [title]="item[rxheader.hName] " class="line_height_2-4rem">
                            {{ item[rxheader.hName] }}
                        </div>
                        <div *ngIf="(rxheader['hName'] === 'Drug Name')" class="line_height_2-4rem" [ngbTooltip]="item['DrugHoverOver']" [placement] = "(cell.col.index>=0 && cell.col.index < 2) ? 'right' : (cell.col.index>=2 && cell.col.index <3)?'top':'left'" triggers="mouseover:mouseleave" container="body" tooltipClass="tooltip-title">
                            {{item[rxheader['hName']] }}
                        </div>
                        
                    </ng-template>
                    
                    <ng-template wjFlexGridCellTemplate [cellType]="'ColumnHeader'" let-item="item">
                        <div title={{rxheader.hName}}>
                            {{ rxheader.hName }}
                        </div>
                    </ng-template>
                </wj-flex-grid-column>
                    </wj-flex-grid>
                   </div>
                   <div class="col-12 text-right mt-3">
                    <button *ngIf="(TotalDispVal>0 && TotalDispVal < rxInfo?.PrescReFill?.DispQty)" type="submit" appShortcutKey class="hotkey_success pull-right" (keydown.tab)="focusToFirst($event)"
                    (click)="addnewIOU()" [AltKey]="'a'">
                    <span>A</span> Add Partial Disp. Record
                </button>
                <button appShortcutKey [AltKey]="'v'" *ngIf="!errorMessage && iouRecs?.length" autofocus="true" type="submit" class="hotkey_success pull-right"
                            (click)="verifyInfo()">
                            <span>V</span> Verify
                </button>
                <button appShortcutKey *ngIf="errorMessage" autofocus="true" type="submit" class="inactive">
                            <span>V</span> Verify
                </button>
                   </div>
                   
               </div>
             </div>

        </div>
    </div>
</div>
<!-- <ng-template #PromptToDel let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closePromtToDel();c('close')">
        <h4 header>Information</h4>
        <span body>
            The edited quantity is equal to the total dispensed quantity, so the newly added record will be deleted.
        </span>
        <button footer class="hotkey_primary" autofocus (click)="closePromtToDel();c('close')" appShortcutKey InputKey="o">
            <b>O</b>OK
        </button>
    </app-custom-modal>
</ng-template> -->
<ng-template #TherapeuticDrugPrmpt let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="ResetNDC();c('close')">
        <h4 header>Confirmation</h4>
        <span body>
            The following NDC is a {{!IsStockbottleDrug ? 'therapeutic equivalent drug' : 'different size stock bottle'}}, would you like to proceed with this NDC?
            <div><br></div>
            <div class="row">
            <div class="col-md-6">
                <label>NDC</label>
                <div class="label--data"> {{this._formatsUtil.getNDCFormat(therapeqvResponse?.drugNDC)}}</div>
            </div>
            <div class="col-md-6">
                <label>Drug Name</label>
                <div class="label--data"> {{therapeqvResponse?.drugName}}</div>
            </div>
        </div>
    </span>
        <button footer class="hotkey_primary" autofocus (click)="continueWithTherapDrug()" appShortcutKey InputKey="y">
            <b>Y</b>Yes
        </button>
        <button footer class="hotkey_primary" (click)="ResetNDC()" appShortcutKey InputKey="n">
            <b>N</b>No
        </button>
    </app-custom-modal>
</ng-template>
<app-notes-log *ngIf="notesType === 'rx'" (CloseNotesValue)="openNotesModel(null)" [RxInfo]="rxInfo"
    [OpenAsModal]="true"  [NotesFr]="'Rx'">
</app-notes-log>
<ng-template #verifyFailed let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModal('close');c('close')">
        <h4 header>Information</h4>
        <span body>
                <!-- <div class="col-2 offset-1">
                    <i class="far fa-times-circle text-danger font_size_5rem"></i>
                </div> -->
                <!-- <div class="col-8 pt-4"> -->
                 Selected NDC#: <span class="font-weight-bold text-primary">{{this._formatsUtil.getNDCFormat(NDCValForManual)}}</span> is not a Therapeutic Equivalent Drug. Please select a different NDC.
                <!-- </div> -->
            <!-- </div> -->
        </span>
        <button footer class="hotkey_primary" (click)="closeModal('rescan');c('close')" appShortcutKey InputKey="r">
            <b>R</b>Re-Scan Drug
        </button>
        <button footer class="hotkey_primary" (click)="closeModal('editRx');c('close')" appShortcutKey InputKey="e">
            <b>E</b> Edit Rx
        </button>
        <!-- <button footer class="hotkey_primary" (click)="closeModal('continue', 'fail');c('close')" appShortcutKey InputKey="o">
            <b>O</b> Continue
        </button> -->
        <button footer class="hotkey_primary" (click)="closeModal('close');c('close')" appShortcutKey InputKey="c">
            <b>C</b> Close
        </button>
    </app-custom-modal>
</ng-template>
<ng-template #alreadyVerified let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModal('close')">
        <h4 header>Confirmation</h4>
        <span body>
            <div class="row">
                <div class="col">
                    <table class="table table-hover">
                        <tbody>
                            <label>Rx-{{ rxInfo?.Prescription.PrescNum }}-{{rxInfo?.PrescReFill?.ReFillNum}}-{{rxInfo?.PrescReFill?.PartialFillNo}} was previously verified successfully.
                            </label>
                            <div class="eprx--block__content">
                                <div class="row">
                                    <div class="col">
                                        <label> Date Verified: </label>
                                        <div class="label-data">
                                            {{ (rxInfo?.drugPickVerif)[0] ?.VerifDtTm | localTime | date: "MM/dd/yyyy" }}
                                        </div>
                                    </div>
                                    <div class="col">
                                        <label> Verified By: </label>
                                        <div class="label-data">
                                            {{ (rxInfo?.drugPickVerif)[0] ?.UserName | uppercase }}
                                        </div>
                                    </div>
                                </div>
                                <label>Do you wish to verify this Rx again?</label>
                            </div>
                        </tbody>
                    </table>
                </div>
            </div>
        </span>
        <button footer class="hotkey_primary" (click)="closeModal('continue')" appShortcutKey InputKey="y"><b>Y</b> YES
        </button>
        <button footer class="hotkey_primary" (click)="closeModal('close')" appShortcutKey InputKey="n"><b>N</b>
            NO</button>
    </app-custom-modal>
</ng-template>
<ng-template #invalidRx let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModal('close')">
        <h4 header>Warning!!</h4>
        <span body>
            <div class="row">
                <div class="col-1 pl-4">
                    <i class="far fa-times-circle text-danger font_size_3rem"></i>
                </div>
                <div class="col-8 pt-2 ml-3">
                    <label>{{ warningInfo }}</label>
                </div>
            </div>
        </span>
        <button footer class="hotkey_primary" (keydown.enter)="closeModal('close')" (click)="closeModal('close')" appShortcutKey InputKey="o"><b>O</b>
            OK</button>
    </app-custom-modal>
</ng-template>
<ng-template #verifiedRx let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModal('success')">
        <h4 header>Drug pick result</h4>
        <span body>
            <div class="row">
                <div class="col-2 offset-1">
                    <i class="far fa-check-circle text-success font_size_5rem"></i>
                </div>
                <div class="col-8 pt-4">
                    <label>You picked the right drug.</label>
                </div>
            </div>
        </span>
    <button footer  *ngIf="proceedRph" class="hotkey_primary" (click)="printLabelCheck()" appShortcutKey InputKey="p">
        <b>P</b>Print/Rph Verify
    </button>
    <button footer  *ngIf="proceedRph" class="hotkey_primary" (click)="closeModal('rph', 'pass',true)" appShortcutKey InputKey="r">
        <b>R</b> RPh Verify
    </button>
    <button footer class="hotkey_primary" (click)="closeModal('success', 'pass')" appShortcutKey InputKey="o">
        <b>O</b> OK
    </button>
     <!-- <button footer class="hotkey_primary" (click)="closeModal('IOU')" appShortcutKey InputKey="m">
            <b>M</b> Manage/Verify IOU
        </button>-->
    </app-custom-modal>
</ng-template>
<ng-template #manualDPV let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">Warning!!</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <p>
                User is forced to scan the NDC from the bottle/package, would you still like to verify manually?
            </p>
        </div>
    </div>
    <div class="modal-footer">
        <div class="text-right pull-right col-md-10 padding-0">
            <div>
                <button class="hotkey_primary" (click)="manualDPVPriv()" appShortcutKey
                    InputKey="y"><b>Y</b>YES
                </button>
                <button class="hotkey_primary" (click)="NDCValue='';d('Cross click')" appShortcutKey InputKey="n"><b>N</b>NO
                </button>
            </div>
        </div>
    </div>
</ng-template>
<app-iou *ngIf="showIOU" (IsPopUpClosed)="closeModal('success')" [RxInfo]="rxInfo"></app-iou>
<app-override-log *ngIf="openOverride" [PrivMask]="privType" [CatId]="categoryId" [frmDPVVerf]="true"
    [OverrideEndpoint]="'POST_Rx_GetRxDetails'" (OverrideLoginDet)="OverrideRes($event)"></app-override-log>
