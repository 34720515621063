<ng-template #VendorItem let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeVendorModal()">
        <h4 header>Vendor Item Codes</h4>
        <span body>
            <div class="eprx--block__content edit--drug">
                <div class="row">
                    <div class="col-xl-12 col-lg-12">
                        <div class="exprx--common-block">
                            <div class="eprx--block__header">
                                <div class="row">
                                    <div class="col">
                                        <span class="eprx--header__heading">Vendor Drug Information</span>
                                    </div>
                                    <div class="col pull-right text-right">
                                        <button
                                            class="hotkey_success hotkey_primary--Override"
                                             title="Add" id="addButton"

                                            (click)="
                                                openVendorItemModal(
                                                    'VAdd',
                                                    vendoritem,
                                                    null
                                                )
                                            " appShortcutKey [AltKey]="'a'">
                                            <span>a</span>ADD
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="eprx--block__content erx--body" >
                                 <wj-flex-grid #Vendor [headersVisibility]="'Column'" [ngClass]="!isDataExists ? 'no-data':''"
                                    [itemsSource]="vendorItemData['itemSWJ']" [columnPicker]="'drugInvVendorListWJ'"
                                    (updatedView)="vendorInit(Vendor)" [selectionMode]="'None'">
                                    <div>
                                        <wj-flex-grid-column [header]="'Actions'" [width]="'1*'">
                                            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                                <div class="text-center">
                                                    <i class="far fa-edit actions" title="edit" (click)="
                                                            openVendorItemModal(
                                                                'VEdit',
                                                                vendoritem,
                                                                item
                                                            )
                                                        "></i>

                                                    <i class="far fa-trash-alt actions danger-actve" (click)="
                                                            deleteWarnPopUp(
                                                                deleteVendor,
                                                                item?.Id
                                                            )
                                                        " title="delete"></i>
                                                </div>
                                            </ng-template>
                                        </wj-flex-grid-column>
                                        <wj-flex-grid-column [header]="rxheader['hName']" [binding]="rxheader['hName']"
                                            [isReadOnly]="true" [visible]="rxheader['isVisible']" *ngFor="let rxheader of wjHeaders; let i = index" [width]="rxheader['width']">
                                            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                                <div *ngIf="rxheader['hName'] ==='Is Discountable?'" class="text-center">
                                                    <span *ngIf="item.Discontinue">
                                                        <i class="far fa-check " title="Locked"></i>
                                                    </span>
                                                    <span *ngIf="!item.Discontinue">
                                                        <i class="far fa-times  fa-uncheck" title="Not Locked"></i>
                                                    </span>
                                                </div>
                                                <div *ngIf="rxheader['hName'] ==='Vendor Name' || rxheader['hName'] === 'Vendor Item Code' || rxheader['hName'] === 'Account'">
                                                    {{ item[rxheader['hName']] }}
                                                </div>
                                                <div *ngIf="rxheader['hName'] === 'AWP Pack' || rxheader['hName'] === 'Cost Pack'">
                                                    {{
                                                    item[rxheader['hName']]
                                                    | currency
                                                    : "$"
                                                    : "symbol"
                                                    : "1.2-2"
                                                    }}
                                                </div>
                                                <div *ngIf="rxheader['hName'] === 'Unit Price Cost' || rxheader['hName'] === 'Unit Price AWP'">
                                                    {{
                                                    item[rxheader['hName']]
                                                    | currency
                                                    : "$"
                                                    : "symbol"
                                                    : "1.3-3"
                                                    }}
                                                </div>
                                                <div *ngIf="(rxheader['hName'] === 'Last Updated')">
                                                {{item[rxheader['hName']]}}</div>
                                            </ng-template>
                                        </wj-flex-grid-column>
                                    </div>
                                    <app-no-data-found *ngIf="!isDataExists"></app-no-data-found>
                                </wj-flex-grid>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </span>
        <button footer autofocus class="hotkey_success" id="vendorDetailsCancel" appShortcutKey [AltKey]="'c'"
            (click)="closeVendorModal()">
            <span>c</span> Close
        </button>
    </app-custom-modal>
</ng-template>


<ng-template #vendoritem let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">Vendor - Drug Definition</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="c('Close click');">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body" [formGroup]="vendorItemFG">
        <div class="row">
            <div class="col-md-6">
                <label class="col-md-12 padding-0">Drug Name</label>
                <div class="label--data">
                    {{drugFG?.value?.Drug?.Name}}
                </div>
            </div>
            <div class="col-md-6">
                <label class="col-md-12 padding-0">NDC </label>
                <div class="label--data">
                    {{this._formatsUtil.getNDCFormat(drugFG?.value?.Drug?.DrugCode)}}
                </div>
            </div>
            <div class="col-md-6">
                <label class="col-md-12 padding-0"> Strength </label>
                <div class="label--data">
                    {{drugFG?.value?.Drug?.Strength}}
                </div>
            </div>
            <div class="col-md-6">
                <label class="col-md-12 padding-0">Form</label>
                <div class="label--data">
                    {{drugFG?.value?.Drug?.DrugFormName}}
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <eprx-input [AutoFocus]="activeModal === 'VAdd'" [LabelText]="'Vendor Item Code'"
                    [PlaceHolder]="'only numerical values are accepted'" [ControlName]="'ItemCode'"
                    [FormGroupName]="vendorItemFG" [IsRequired]="true" [ErrorDefs]="{ required: 'Required' }"
                    [FormGroupInvalid]="vendorFgInvalid" [InputType]="'NUMBER'">
                </eprx-input>
            </div>
            <div class="col-md-6">
                <eprx-select [LabelText]="'Vendor Name'" [PlaceHolder]="'Vendor Name'" [ControlName]="'ContractId'"
                    [FormGroupName]="vendorItemFG" [List]="dropDownValues?.Vendor" [AutoFocus]="activeModal !== 'VAdd'" [LabelTitle2]="'Vendor Name'" [LabelTitle1]="'Account'"
                    [RxSelectId]="'vendorList'" [HasMultiple]="false" [BindLabel]="'ContractName'" [BindValue]="'ContractId'" [BindLabel2]="'VendorName'" [Show2Values]="true"
                    [LabelForId]="'VendorName'" [IsRequired]="true" [ErrorDefs]="{ required: 'Required' }" [IsDisabled]="openForAdd"
                    [FormGroupInvalid]="vendorFgInvalid" (TriggerSelectValue)="checkVendorDuplicate()" [show2ValInInputhidden]="true">
                </eprx-select>
            </div>
            <div class="col-md-6">
                <eprx-input [LabelText]="'Unit Price - AWP'" [InputType]="'CURRENCY'" [PlaceHolder]="'Unit Price - AWP'"
                    [ControlName]="'UnitAWP'" [FormGroupName]="vendorItemFG"
                    (TriggerSearchValue)="convertNumberFormat('UnitAWP', 3)" [MaskPattern]="'099999.000'"
                    [DropSpclChar]="false">
                </eprx-input>
            </div>
            <div class="col-md-6">
                <eprx-input [LabelText]="'AWP Pack'" [InputType]="'CURRENCY'" [PlaceHolder]="'AWP Pack'" [ControlName]="'AWP'"
                    [FormGroupName]="vendorItemFG" (TriggerSearchValue)="convertNumberFormat('AWP', 3)"
                    [MaskPattern]="'099999.00'" [DropSpclChar]="false">
                </eprx-input>
            </div>
            <div class="col-md-6">
                <eprx-input [LabelText]="'Unit Price - Cost'" [InputType]="'CURRENCY'" [PlaceHolder]="'Unit Price - Cost'"
                    [ControlName]="'UnitCost'" [FormGroupName]="vendorItemFG" [DecimalValues]="3"
                    (TriggerSearchValue)="convertNumberFormat('UnitCost', 3)" [MaskPattern]="'099999.000'"
                    [DropSpclChar]="false">
                </eprx-input>
            </div>
            <div class="col-md-6">
                <eprx-input [LabelText]="'Cost Pack'" [InputType]="'CURRENCY'" [PlaceHolder]="'Cost Pack'" [ControlName]="'PackCost'"
                    [FormGroupName]="vendorItemFG" (TriggerSearchValue)="convertNumberFormat('PackCost', 3)"
                    [MaskPattern]="'099999.00'" [DropSpclChar]="false">
                </eprx-input>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="text-right pull-right col-md-10 padding-0">
            <div>
                <button class="hotkey_success" (click)="saveVendorItems()" appShortcutKey [AltKey]="activeModal === 'VAdd' ? 's' : 'u' ">
                    <span>{{activeModal === 'VAdd' ? 'S' : 'U' }}</span>{{activeModal === 'VAdd' ? ' Save' : 'Update' }}
                </button>
                <button class="hotkey_success" *ngIf="!openForAdd" appShortcutKey [AltKey]="'c'" appShortcutKey (click)="c('Close click');"
                    [AltKey]="'c'">
                    <span>C</span>Cancel
                </button>
                <button class="hotkey_success" *ngIf="openForAdd" appShortcutKey [AltKey]="'c'" appShortcutKey (click)=" directClose(); c('Close click');"
                    [AltKey]="'c'">
                    <span>C</span>Cancel
                </button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #deleteVendor let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">{{warningText?.deleteHeaderTxt}}</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">{{warningText?.deleteConfrmMsg}}</div>
    <div class="modal-footer">
        <div class="text-right pull-right">
            <div>
                <button class="hotkey_primary" (click)="d('Cross click')" (click)="deleteVendorDrugInfo()"
                    appShortcutKey InputKey="y"><b>Y</b> YES
                </button>
                <button class="hotkey_primary" (click)="d('Cross click')" appShortcutKey InputKey="n">
                    <b>N</b>NO
                </button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #warnVendorExist let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">Warning!!</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="setFocusById(null, 'vendorList', 'select');d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">Vendor is already associated with the drug.</div>
    <div class="modal-footer">
        <div class="text-right pull-right">
            <div>
                <button ngbAutofocus class="hotkey_primary" (click)="setFocusById(null, 'vendorList', 'select');d('Cross click')" appShortcutKey InputKey="o">
                    <b>O</b>OK
                </button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #warnngDis let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">Warning!!</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body" *ngIf="selectedVendor?.Discontinue">
        Are you sure you want to Discontinue the selected Vendor?
    </div>
    <div class="modal-body" *ngIf="!selectedVendor?.Discontinue">
        Are you sure you want to Activate the selected Vendor?
    </div>
    <div class="modal-footer">
        <div class="text-right pull-right col-md-10 padding-0">
            <div>
                <button class="hotkey_primary" (click)="d('Cross click')" (click)="vendorDisContinue()" appShortcutKey
                    InputKey="y"><b>Y</b> YES
                </button>
                <button class="hotkey_primary" (click)="d('Cross click')" appShortcutKey InputKey="n">
                    <b>N</b>NO
                </button>
            </div>
        </div>
    </div>
</ng-template>
