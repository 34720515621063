import { Component, OnInit, Input, HostListener, ViewChild, Output, EventEmitter } from "@angular/core";
import { Subscription } from "rxjs";
import { RxTransferService, AlertService, TransmissionService } from "src/app/services";
import { CollectionView } from "@grapecity/wijmo";
import { PatAuditLog, RxModel, RxPay } from "src/app/models";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { MsgConfig } from "src/app/app.messages";
import { FormBuilder, FormGroup } from "@angular/forms";
import * as moment from "moment";
import { NewAuditUtils } from "src/app/utils";

@Component({
    selector: "app-pay-records",
    templateUrl: "./pay-records.component.html"
})
export class PayRecordsComponent implements OnInit {
    subscriptions = new Subscription();
    prescRefillID: any;
    rxTransmissionWJ: any;
    modalRefSM: any;
    payRecrds: any;
    originalPayRecords: any;

    @Input() rxInfo: RxModel;
    editPayFG: FormGroup;
    benefitInfoWJ: CollectionView;
    benefitFG: FormGroup;
    modalRefBF: any;
    modalRefRJ: any;
    rejCodeWJ: any;
    rejectList: any;
    tempRxNumber: any;
    duplicateList: boolean;
    isDataExists : boolean = false;
    data : any;
    @Input() set PayRecrds(pr) {
        this.payRecrds = pr;
        if (pr && pr["length"] > 0) {
            this.generateLogsWJ(pr);
        }
    }

    @Output()
    ClosePayRecords = new EventEmitter<any>();

    @ViewChild("PayRecordDeleteWarning", { static: true })
    PayRecordDeleteWarning: any;

    @ViewChild("PayRecordEdit", { static: true })
    PayRecordEdit: any;

    @ViewChild("BenefitInfoEdit", { static: true })
    BenefitInfoEdit: any;

    @ViewChild("RejectcodesEdit", {static: true})
    RejectcodesEdit: any;

    @HostListener("window:keydown", ["$event"])
    keyEventAlt(event: KeyboardEvent) {
        if (event.which === 27) { // esc
            event.preventDefault();
            this.closeModalSM();
        }
        if (event.altKey && this._modalService.hasOpenModals() && (!this.modalRefRJ) && (!this.modalRefSM)) {
            if (event.which === 67) { // c
                event.preventDefault();
                this.previousPage();
            }
        }
    }

    constructor(private _fb: FormBuilder,
        private _rxTrnsfrSvc: RxTransferService, private _transmsnServ: TransmissionService,
        private _modalService: NgbModal, private _alertSvc: AlertService, private _auditUtils: NewAuditUtils) { }

    ngOnInit() {}

    generateLogsWJ(rxLogs) {
        this.rxTransmissionWJ = new CollectionView(rxLogs);
    }

    previousPage() {
        this.ClosePayRecords.emit("Patient1");
    }

    closeModalSM() {
        if (this.modalRefSM) {
            this.modalRefSM.close();
            this.modalRefSM = null;
        }
    }

    closeBFModal() {
        if (this.modalRefBF) {
            this.modalRefBF.close();
        }
    }

    async deletePayRecord(item: any) {
        if (item.RecType === "R" || item.RecType === "C") {
            const resp = await this._rxTrnsfrSvc.deletePayRecord(item.Id).toPromise();
            if (resp === true) {
                this._alertSvc.success(MsgConfig.DELETE_SUCCESS);
                const resp2 = await this._transmsnServ.getPayRecord(this.rxInfo.PrescReFill.Id).toPromise();
                this._transmsnServ._payRecords$.next(resp2);
            }
        } else if (item.RecType === "P") {
            this.modalRefSM = this._modalService.open(this.PayRecordDeleteWarning, {centered: true,
                backdrop: false, windowClass: "large--content"
            });
            setTimeout(() => {
                document.getElementById("payRecordDelete").focus();
            }, 100);
        }
    }

    focusOnCancel(event) {
        if (document.getElementById("cancelButton")) {
            event.preventDefault();
            document.getElementById("cancelButton").focus();
        }
    }

    openEditModal(val) {
        this.editPayFG = this._fb.group(new RxPay());
        this.editPayFG.patchValue(val);
        this.getBatchDetails();
        this.patchEditPay();
        this.modalRefSM = this._modalService.open(this.PayRecordEdit, {size: "lg", windowClass: "md-x-lg", backdrop: false});
    }

    async getBatchDetails() {
      this.data =  await this._transmsnServ.getPayDetails(this.rxInfo.PrescReFill.Id).toPromise();
      this.isDataExists =  this.data && this.data.length ? true : false;
      this.benefitInfoWJ = new CollectionView(this.data);
    }

    openBenfitEdit(val) {
        this.benefitFG = this._fb.group({
            Id: null,
            PrescRefillId: null,
            RxId: null,
            Fillno: null,
            Ins_Code: null,
            FieldName: null,
            FieldValue1: null,
            FieldValue2: null,
            RxNo: null,
            RfNo: null,
        });
        this.benefitFG.patchValue(val);
        this.benefitFG.patchValue({
            RxNo: this.rxInfo.Prescription.PrescNum,
            RfNo: this.rxInfo.PrescReFill.ReFillNum
        });
        this.modalRefBF = this._modalService.open(this.BenefitInfoEdit, {centered: true, backdrop: false});
    }

    async updateBenefit() {
        const resp = await this._transmsnServ.updateBenifitInfo(this.benefitFG.getRawValue()).toPromise();
        if (resp) {
            this._alertSvc.success(MsgConfig.UPDATE_SUCCESS);
            await this.getBatchDetails();
        }
        this.closeBFModal();
    }

    patchEditPay() {
        const cntrlNames = ["ICostPaid", "DFEEPaid", "PTPayment", "FSTaxAmt", "PSTaxAmt", "PSTaxRate",
        "INCAMTPaid", "PSFEEPaid", "OthAMTPaid", "OthPayRecg", "TotAmtPaid", "OthPayAmt1", "OthPayAmt2", "OthPayAmt3",
        "Amt_PERDED", "Amt_COPINS", "Amt_SaleTx", "Amt_PRODSL", "PSTaxBasisPaid", "AccumDedAmount",
         "RemDedAmount", "RemBenefitAmount", "Amt_EXCMAX", "DIRFee"];
         cntrlNames.map(cntrl => this.editPayFG.controls[cntrl].patchValue(
            this.editPayFG.value[cntrl] ? (parseFloat(this.editPayFG.value[cntrl])).toFixed(2) : "0.00"
         ));
         const trimCntrlN = ["BOC_PSTax", "BOC_FSTax", "BOC_Copay", "BOC_DFEE", "BASISOFREI"];
         trimCntrlN.map(cntrl => this.editPayFG.controls[cntrl].patchValue(
            this.editPayFG.value[cntrl] ? this.editPayFG.value[cntrl].trim() : null
         ));
         this.editPayFG.controls["DatePaid"].patchValue(moment.utc(this.editPayFG.value["DatePaid"]).local().format("MM/DD/YYYY"));
         this.originalPayRecords = Object.assign({}, this.editPayFG.value);
    }

    setNumberFormat(cntrl) {
        this.editPayFG.controls[cntrl].patchValue(this.editPayFG.value[cntrl] ?
            (parseFloat(this.editPayFG.value[cntrl])).toFixed(2) : "0.00");
    }

    async UpdatePayRecords() {
        await this._rxTrnsfrSvc.updatePayRecord(this.editPayFG.value).toPromise();
        this._alertSvc.success(MsgConfig.UPDATE_SUCCESS);
        const payRecordTrasmissionsModel = new PatAuditLog().payRecordTrasmissionsModel;
            this._auditUtils.saveAuditChange(
                this.originalPayRecords,
                this.editPayFG.getRawValue(),
                "PayRecords",
                "Rx",
                this.rxInfo.PrescReFill.Id,
                payRecordTrasmissionsModel
            );
        this.closeModalSM();
        await this.getPayRecords();
    }

    async getPayRecords() {
        const resp = await this._transmsnServ.getPayRecord(this.rxInfo.PrescReFill.Id).toPromise();
        this._transmsnServ.storePayRecords(resp);
        this.payRecrds = resp;
        this.generateLogsWJ(resp);
    }
    setTempRx(rejCode) {
        this.tempRxNumber = rejCode;
    }

    openRejectCodes() {
        this.rejectList = this.editPayFG.controls["REJCodes"].value.split(",");
        this.generateRejCodesWJ(this.rejectList);
        this.modalRefRJ = this._modalService.open(this.RejectcodesEdit, {centered: true, backdrop: false, size: "sm",
        windowClass: "medium-sm-modal-23"});
    }
    generateRejCodesWJ(list) {
        this.rejCodeWJ = new CollectionView(
            list.map((rx, i) => {
                const k = {};
                k["RowId"] = i;
                k["RejectCodes"] = rx;
                return k;
        })
        );
    }
    closeRejectPopup() {
        if (this.modalRefRJ) {
            this.modalRefRJ.close();
            this.modalRefRJ = null;
            this.tempRxNumber = null;
        }
    }
    removeRejectCodeModal(item) {
        this.rejectList = this.rejectList.filter((code: any) => {
            return (code !== item);
        });
        this.editPayFG.controls["REJCodes"].patchValue(this.rejectList.join(","));
    }
    addRejCode() {
        if (this.tempRxNumber) {
            this.rejectList.map((num: any) => {
                if (num === this.tempRxNumber.toUpperCase()) {
                    this.duplicateList = true;
                }
            });
            if (this.duplicateList) {
                this.duplicateList = false;
                this._alertSvc.error("Code already exists in the list.");
            } else {
                this.rejectList.push(this.tempRxNumber.toUpperCase());
                this.editPayFG.controls["REJCodes"].patchValue(this.rejectList.join(","));
                this.duplicateList = false;
            }
            this.tempRxNumber = null;
        }
    }
 }
