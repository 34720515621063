import {
    WorkmanComp,
    CarrierAddress,
    EmPhn,
    CarrOrg,
} from "./../../../models/rx-popup.model";
import { MsgConfig } from "src/app/app.messages";
import {
    Component,
    OnInit,
    Input,
    ViewChild,
    Output,
    EventEmitter,
    HostListener
} from "@angular/core";
import { WCOM, City, State, EPatient, RxModel, RxAuditLog, NewAuditLog } from "./../../../models";
import { FormBuilder, FormGroup, FormControl } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
    RxService,
    AlertService,
    RxObservableService
} from "../../../services";
import { RegEx } from "../../../app.regex";
import * as moment from "moment";
import { RxStore } from "src/app/store";
import { CommonUtil, NewAuditUtils } from "src/app/utils";
import { DateCheckUtil } from "src/app/utils/date-checks.util";
import { InsuranceUtil } from "src/app/utils";
import * as Sentry from "@sentry/browser";

@Component({
    selector: "app-wcom",
    templateUrl: "./wcom.component.html",
    styles: [],
})
export class WcomComponent implements OnInit {
    wcomFG: FormGroup;
    modalRef: any;
    WCOMDefaultValues: any;
    hasWCOMCopy = false;
    rxPatientModel: EPatient;
    WCOMDefaultVal: any;
    actvState: string;
    actvCity: string;
    regex: any;
    citySelected: any;
    stateSelected: any;
    emplrStateSelected: any;
    emplrCitySelected: any;
    carStateSelected: any;
    carCitySelected: any;
    formGroupInvalid = false;
    rxType: any;
    copyValue: any;
    maxDate: { year: number; month: number; day: number };
    rxId: any;

    @Input() rxInfo: RxModel;
    @Input() nwRxWCOMData: any;

    @Output() IsPopUpClosed = new EventEmitter<any>();

    @ViewChild("WCOMMODAL")
    WCOMMODAL: any;

    @ViewChild("Copy", { static: true })
    Copy: any;
    originalData: any;
    changesData: any;

    @HostListener("window:keydown", ["$event"])
    keyEventAlt(event: KeyboardEvent) {
        if (
            event.which === 27 &&
            this.modalRef &&
            this._modalService.hasOpenModals()
        ) {
            // esc
            this.modalRef.close();
            this.modalRef = null;
            event.preventDefault();
        }
    }

    constructor(
        private _fb: FormBuilder,
        private _modalService: NgbModal,
        private _rxService: RxService,
        private _alertServ: AlertService,
        private _rxStore: RxStore,
        private _nwAudtUtl: NewAuditUtils,
        private _rxObserServ: RxObservableService,
        private _commonUtil: CommonUtil,
        private _dateChecks: DateCheckUtil,
        private _insuUtils: InsuranceUtil
    ) {
        this.regex = RegEx;
        this.createDMEFG();
        this.maxDate = {
            year: moment(Date.now()).year(),
            month: moment(Date.now()).month() + 1,
            day: moment(Date.now()).date(),
        };
    }

    ngOnInit() {
        this.rxType = this.rxInfo["rxType"];
        this.rxId = this.rxInfo.Prescription.Id;
        this.getData();
    }

    async getData() {
        await this.getWCOMDefaultValues();
        if (this.rxType === "er" || this.rxType === "brx" && this.rxInfo.Prescription.Id) {
            await this.getWCOMInfo(this.rxInfo.Prescription.Id);
        } else if (this.nwRxWCOMData) {
            this.patchWcomValues(this.nwRxWCOMData);
            this.patchStateCity(this.nwRxWCOMData);
        }
        this.checkHasWCOMCopy(this.rxInfo.Patient.patientid, this.rxInfo.Prescription.Id);
        this.originalData = Object.assign({}, this.wcomFG.getRawValue());
        this.wcomFG.markAsPristine();
    }

    async getWCOMDefaultValues(insCode?: any) {
        this.WCOMDefaultVal = await this._rxService.getWCOMDefaultValues().toPromise();
        if(this.WCOMDefaultVal && insCode){
            (this.WCOMDefaultVal.wcomInsuCarrier).map(val=>{
                if(insCode === val.InsurerCode) {
                    const fg: any = this.wcomFG.controls["CarrOrg"];
                    fg.controls["InsurerCode"].patchValue(val["InsurerCode"]);
                    fg.controls["Id"].patchValue(val["id"]);
                    fg.controls["Name"].patchValue(val["InsurerCode"]);
                    this.WorkmanComp.controls["InsuCarrierId"].patchValue(val["id"]);
                }
            });
        }
    }

    async getWCOMInfo(rxId: number) {
        const resp = await this._rxService.getWCOMInfoByPatient(this.rxId).toPromise();
        if (resp && resp["WorkmanComp"]) {
            await this.patchWcomValues(resp);
            await this.patchStateCity(resp);
        }
    }

    patchWcomValues(data) {
        const controlNames = ["WorkmanComp", "CarrOrg", "CarrAdd", "EmpOrg", "EmpAdress", "EmPhn", "PayerAddress"];
        controlNames.map(value => {
            if (data[value]) {
                if (value === "WorkmanComp") {
                    this.wcomFG.controls[value].patchValue(data[value]);
                    const fg: any = this.wcomFG.controls[value];
                    if (data[value]["GenEqProdNum"]) {
                        fg.controls["GenEqProdNum"].patchValue(+data[value]["GenEqProdNum"]);
                    }
                    if (data[value]["AccidentDt"]) {
                        this.changesData = data[value]["AccidentDt"];
                        fg.controls["AccidentDt"].patchValue(moment.utc(data[value]["AccidentDt"]).local().format("MM/DD/YYYY"));
                    } else {
                        this.changesData = data[value]["AccidentDt"];
                    }
                } else {
                    this.wcomFG.controls[value].patchValue(data[value]);
                }
            }
        });
    }

    async checkHasWCOMCopy(patientID: number, rxId: number) {
        const resp: any = await this._rxService.checkHasWCOMCopyByPatient(patientID, rxId).toPromise();
        if (resp && resp["WorkmanComp"]) {
            this.hasWCOMCopy = true;
            this.copyValue = resp;
        }
    }

    openCopyAlert(content) {
        this.modalRef = this._modalService.open(content, {
            centered: true,
            keyboard: false,
            backdrop: false,
            windowClass: "large--content"
        });
    }

    copyLastWCOM() {
        this.removeIdFromCopyData();
        this.patchWcomValues(this.copyValue);
        this.patchStateCity(this.copyValue);
    }

    removeIdFromCopyData() {
        const controlNames = ["WorkmanComp", "CarrAdd", "EmpOrg", "EmpAdress", "EmPhn", "PayerAddress"];
        controlNames.map(value => {
            if (this.copyValue[value]) {
                this.copyValue[value]["Id"] = null;
                if (value === "WorkmanComp") {
                    this.copyValue[value]["RxId"] = this.rxId;
                    this.copyValue[value]["EmployerId"] = null;
                }
            }
        });
    }

    createDMEFG() {
        this.wcomFG = this._fb.group(new WCOM()) as FormGroup;
        this.wcomFG = this._fb.group({
            WorkmanComp: this._fb.group(new WorkmanComp()),
            CarrOrg: this._fb.group(new CarrOrg()),
            CarrAdd: this._fb.group(new CarrierAddress()),
            EmpOrg: this._fb.group(new CarrOrg()),
            EmpAdress: this._fb.group(new CarrierAddress()),
            EmPhn: this._fb.group(new EmPhn()),
            PayerAddress: this._fb.group(new CarrierAddress()),
        });
    }

    async getCarrierValues(data) {
        if (data && data["id"]) {
            const resp = await this._rxService.getWCOMCarrier(data["id"]).toPromise();
            if (resp) {
                const fg: any = this.wcomFG.controls["CarrOrg"];
                fg.controls["InsurerCode"].patchValue(null);
                const patchDetails = [
                    { FrmName: "CarrOrg", CtrlName: "Name", patName: "OrganizationName" },
                    { FrmName: "CarrAdd", CtrlName: "AddressLine1", patName: "AddressLine1" },
                    { FrmName: "CarrAdd", CtrlName: "ZipCode", patName: "ZipCode" },
                    { FrmName: "CarrAdd", CtrlName: "CityId", patName: "CityId" },
                    { FrmName: "CarrAdd", CtrlName: "StateId", patName: "StateId" },
                    { FrmName: "CarrOrg", CtrlName: "CreatedDtTm", patName: "OrgCreation" },
                    { FrmName: "CarrOrg", CtrlName: "Id", patName: "OrganisationId" },
                    { FrmName: "CarrAdd", CtrlName: "AddressLine2", patName: "AddressLine2" },
                    { FrmName: "CarrAdd", CtrlName: "CreatedDtTm", patName: "CreatedDtTm" },
                    { FrmName: "CarrAdd", CtrlName: "Id", patName: "Id" }];

                patchDetails.map(val => {
                    const Fg: any = this.wcomFG.controls[val["FrmName"]];
                    if (val["patName"] === "CountryCode") {
                        Fg.controls[val["CtrlName"]].patchValue(resp[val["patName"]] ? +resp[val["patName"]] : 0);
                    } else {
                        Fg.controls[val["CtrlName"]].patchValue(resp[val["patName"]]);
                    }
                });
                this.carCitySelected = new City();
                this.carCitySelected.Id = resp["CityId"];
                this.carCitySelected.Name = resp["CityName"];
                this.carCitySelected.StateId = resp["StateId"];
                this.carStateSelected = new State();
                this.carStateSelected.Id = resp["StateId"];
                this.carStateSelected.Name = resp["StateName"];
            }
        } else if (data && data["InsurerCode"]) {
            this.addNewItemAsInsurance(data["InsurerCode"]);
        }
    }

    focusOutFromGroup() {
        if (document.getElementById("carrName")) {
            document.getElementById("carrName").focus();
        }
    }

    get WorkmanComp(): FormGroup {
        return this.wcomFG.get("WorkmanComp") as FormGroup;
    }

    get CarrOrg(): FormGroup {
        return this.wcomFG.get("CarrOrg") as FormGroup;
    }

    get CarrierAddress(): FormGroup {
        return this.wcomFG.get("CarrAdd") as FormGroup;
    }

    get EmpAdress(): FormGroup {
        return this.wcomFG.get("EmpAdress") as FormGroup;
    }

    get EmpOrg(): FormGroup {
        return this.wcomFG.get("EmpOrg") as FormGroup;
    }

    get EmPhn(): FormGroup {
        return this.wcomFG.get("EmPhn") as FormGroup;
    }

    get PayerAddress(): FormGroup {
        return this.wcomFG.get("PayerAddress") as FormGroup;
    }

    patchStateCity(data) {
        this.citySelected = new City();
        this.citySelected.Id = data["PayerAddress"] ? data["PayerAddress"]["CityId"] : null;
        this.citySelected.Name = data["PayerAddress"] ? data["PayerAddress"]["CityName"] : null;
        this.citySelected.StateId = data["PayerAddress"] ? data["PayerAddress"]["StateId"] : null;
        this.stateSelected = new State();
        this.stateSelected.Id = data["PayerAddress"] ? data["PayerAddress"]["StateId"] : null;
        this.stateSelected.Name = data["PayerAddress"] ? data["PayerAddress"]["StateName"] : null;

        this.emplrCitySelected = new City();
        this.emplrCitySelected.Id = data["EmpAdress"] ? data["EmpAdress"]["CityId"] : null;
        this.emplrCitySelected.Name = data["EmpAdress"] ? data["EmpAdress"]["CityName"] : null;
        this.emplrCitySelected.StateId = data["EmpAdress"] ? data["EmpAdress"]["StateId"] : null;
        this.emplrStateSelected = new State();
        this.emplrStateSelected.Id = data["EmpAdress"] ? data["EmpAdress"]["StateId"] : null;
        this.emplrStateSelected.Name = data["EmpAdress"] ? data["EmpAdress"]["StateName"] : null;

        this.carCitySelected = new City();
        this.carCitySelected.Id = data["CarrAdd"] ? data["CarrAdd"]["CityId"] : null;
        this.carCitySelected.Name = data["CarrAdd"] ? data["CarrAdd"]["CityName"] : null;
        this.carCitySelected.StateId = data["CarrAdd"] ? data["CarrAdd"]["StateId"] : null;
        this.carStateSelected = new State();
        this.carStateSelected.Id = data["CarrAdd"] ? data["CarrAdd"]["StateId"] : null;
        this.carStateSelected.Name = data["CarrAdd"] ? data["CarrAdd"]["StateName"] : null;
    }

    async saveWCOMInfo(type?: any) {
        const workmanAccdtDtFG = this._fb.group({AccidentDt: this.WorkmanComp.controls["AccidentDt"].value});
        var dateChecks = [{
            control: "AccidentDt",
            minDate: "",
            maxDate: this.maxDate,
        }];
        if (this._dateChecks.checkDateValidationAtTimeofSave(workmanAccdtDtFG, dateChecks)) {
            return;
        } else {
            let enterReq = false;
            if (this.WorkmanComp.value["InsuCarrierId"] && !this.CarrOrg.value["Name"]) {
                if (!type) {
                    this._alertServ.error("Please Enter Carrier Name");
                    document.getElementById("carrName").focus();
                }
                enterReq = true;
            }
            if ((this.WorkmanComp.value["InsuCarrierId"] && this.CarrOrg.value["InsurerCode"]) && !this.CarrOrg.value["Name"]) {
                if (!type) {
                    this._alertServ.error("Please Enter Carrier ID");
                    const data: any = document.getElementById("CarrierId").getElementsByTagName("input")[0];
                    data.focus();
                }
                enterReq = true;
            }
            const cntrlNameFrCheck = [{ Ctrl: "ContPerson", FrGroup: "EmpOrg" }, { Ctrl: "xPhone", FrGroup: "EmPhn" },
            { Ctrl: "ClaimNum", FrGroup: "WorkmanComp" }, { Ctrl: "AddressLine1", FrGroup: "EmpAdress" },
            { Ctrl: "ZipCode", FrGroup: "EmpAdress" }, { Ctrl: "CityId", FrGroup: "EmpAdress" }, { Ctrl: "ZipCode", FrGroup: "EmpAdress" }];
            let isWarningMessage: boolean = false;
            if (!this.EmpOrg.value["Name"]) {
                cntrlNameFrCheck.map(checkVal => {
                    if (this.wcomFG.value[checkVal["FrGroup"]][checkVal["Ctrl"]]) {
                        if (!type) {
                            isWarningMessage = true;
                        }
                        enterReq = true;
                    }
                });
            }
            if(isWarningMessage) {
                this._alertServ.error("Please Enter Employer Name.");
                document.getElementById("EmpName").focus();
            }

            if (!enterReq) {
                this.WorkmanComp.controls["RxId"].patchValue(this.rxId);
                this.CarrierAddress.controls["IsActive"].patchValue(true);
                this.PayerAddress.controls["IsActive"].patchValue(true);
                this.EmpAdress.controls["IsActive"].patchValue(true);
                this.CarrOrg.controls["IsActive"].patchValue(true);
                this.EmpOrg.controls["IsActive"].patchValue(true);
                this.EmPhn.controls["IsActive"].patchValue(true);
                if (this.rxType === "er" || this.rxType === "rf") {
                    /* Sentry.captureException([
                        {name: this.WorkmanComp.controls["InsuCarrierId"].value},
                        {name:  this.CarrOrg.controls["Name"].value}
                    ]);*/
                    const resp = await this._rxService.saveWCOMinfo(this.wcomFG.getRawValue()).toPromise();
                    if (resp) {
                        this.originalData.WorkmanComp.AccidentDt = this.changesData;
                        const wcomRxAudtMdl = new RxAuditLog().wcomAuditModel;
                        this._nwAudtUtl.saveAuditChange(
                            this.originalData,
                            this.wcomFG.getRawValue(),
                            "WCOM",
                            "Rx",
                            this.rxInfo["PrescReFill"]["Id"],
                            wcomRxAudtMdl
                        );
                        this.originalData = Object.assign({}, this.wcomFG.getRawValue());
                        if (!type) {
                            this._alertServ.success(MsgConfig.SAVE_SUCCESS);
                        }
                        this.getWCOMInfo(this.rxInfo.Prescription.Id);
                    } else {
                        if (!type) {
                            this._alertServ.error("WCOM save unsuccessful.");
                        }
                    }
                } else {
                    this._rxStore.storeWCOMInfo(this.wcomFG.getRawValue());
                    if (!type) {
                        this._alertServ.success(MsgConfig.SAVE_SUCCESS);
                        this.cancelOnEdit();
                    }
                }
            }
        }

    }

    cancelOnEdit() {
        this.wcomFG.reset();
        this.IsPopUpClosed.emit("Patient1");
    }

    prescCitySelected(val: any, city) {
        if (city === "CarrierCity") {
            this.CarrierAddress.controls["CityId"].setValue(val.Id);
            this.CarrierAddress.controls["CityName"].setValue(val.Name);
        } else if (city === "EmployerCity") {
            this.EmpAdress.controls["CityId"].setValue(val.Id);
            this.EmpAdress.controls["CityName"].setValue(val.Name);
        } else if (city === "PayToCity") {
            this.PayerAddress.controls["CityId"].setValue(val.Id);
            this.PayerAddress.controls["CityName"].setValue(val.Name);
        }
    }

    prescStateSelected(val: any, state) {
        const cityDet = new City();
        if (state === "CarrierState") {
            this.carCitySelected = cityDet;
            this.CarrierAddress.controls["StateId"].setValue(val.Id);
            this.CarrierAddress.controls["StateName"].setValue(val.Name);
        } else if (state === "EmployerState") {
            this.emplrCitySelected = cityDet;
            this.EmpAdress.controls["StateId"].setValue(val.Id);
            this.EmpAdress.controls["StateName"].setValue(val.Name);
        } else if (state === "PayToState") {
            this.citySelected = cityDet;
            this.PayerAddress.controls["StateId"].setValue(val.Id);
            this.PayerAddress.controls["StateName"].setValue(val.Name);
        }
    }

    getCityStateFromZipCode(zipMaster, state, city) {
        if (zipMaster && zipMaster.CityId && zipMaster.StateId && zipMaster.CityName) {
            const cityDet = new City();
            cityDet.Id = zipMaster.CityId;
            cityDet.StateId = zipMaster.StateId;
            cityDet.Name = zipMaster.CityName;

            const stateDet = new State();
            stateDet.Id = zipMaster.StateId;
            stateDet.Name = zipMaster.StateName;
            if (state === "CarrierState") {
                this.carStateSelected = stateDet;
                this.carCitySelected = cityDet;
                this.CarrierAddress.controls["CityName"].setValue(
                    zipMaster.CityName
                );
                this.CarrierAddress.controls["StateName"].setValue(
                    zipMaster.StateName
                );
                this.CarrierAddress.controls["CityId"].setValue(zipMaster.CityId);
                this.CarrierAddress.controls["StateId"].setValue(zipMaster.StateId);
            } else if (state === "EmployerState") {
                this.emplrStateSelected = stateDet;
                this.emplrCitySelected = cityDet;
                this.EmpAdress.controls["CityName"].setValue(zipMaster.CityName);
                this.EmpAdress.controls["StateName"].setValue(zipMaster.StateName);
                this.EmpAdress.controls["CityId"].setValue(zipMaster.CityId);
                this.EmpAdress.controls["StateId"].setValue(zipMaster.StateId);
            } else if (state === "PayToState") {
                this.stateSelected = stateDet;
                this.citySelected = cityDet;
                this.PayerAddress.controls["StateName"].setValue(
                    zipMaster.CityName
                );
                this.PayerAddress.controls["CityName"].setValue(
                    zipMaster.StateName
                );
                this.PayerAddress.controls["CityId"].setValue(zipMaster.CityId);
                this.PayerAddress.controls["StateId"].setValue(zipMaster.StateId);
            }
        }
    }

    focusToTop(event) {
        if (this.hasWCOMCopy) {
            event.preventDefault();
            if (document.getElementById("wcomcopy")) {
                document.getElementById("wcomcopy").focus();
            }
        } else {
            event.preventDefault();
            if (document.getElementById("WcomSave")) {
                document.getElementById("WcomSave").focus();
            }
        }
    }
    async addNewItemAsInsurance(nameAndCode) {
        const planInfoFG = this._insuUtils.createInsuFG();
        const planInfo = planInfoFG.getRawValue();
        planInfo["Organization"]["Name"] = nameAndCode;
        planInfo["InsuCarrier"]["InsurerCode"] = nameAndCode ;
        planInfo["InsuPlan"]["IsActive"] = true;
        planInfo["InsuPlan"]["IsMedicaidSup"] = true;
        planInfo["InsuPlan"]["IsMedicareSup"] = true;
        planInfo["InsuPlan"]["IsMedigapSup"] = true;
        planInfo["InsuTransmission"]["InsuXmitSubCatId"] = 2;
        planInfo["InsuTransmission"]["NCPDPVer"] = "D0";
        const dropdownsData = await this._insuUtils.getDropDownData();
        const resp = await this._insuUtils.addinsuranceInfo(planInfo, dropdownsData);
        if (resp > 0) {
            this.getWCOMDefaultValues(nameAndCode);
        }
    }
}
