import { Component, OnInit, Input, EventEmitter, Output, AfterViewInit } from "@angular/core";
import { DrugData, SystemData, MiscInfo } from "src/app/models";
import { CollectionView } from "@grapecity/wijmo";
import { DrugUtils } from "src/app/utils";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { EquivalentDrugsComponent } from "../../shared";
import { FormGroup } from "@angular/forms";
import { AlertService } from "src/app/services";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-drug-common-header",
  templateUrl: "./drug-common-header.component.html"
})
export class DrugCommonHeaderComponent implements OnInit, AfterViewInit {

    @Input() drugInfo: DrugData;
    @Input() drugId: any;
    @Input() systemData: SystemData;
    @Input() dropDownValue: any;
    @Input() drugFg: FormGroup;
    @Input() openMMS: boolean;
    @Input() isPreferred: boolean;
    @Input() miscFG: FormGroup;
    @Input() miscInfo: MiscInfo;
    @Input() openNPatchFrmMMS : boolean;
    @Input() MMSDataDPV: any;
  @Output() OpenCompndDrug = new EventEmitter();
  @Output() ManFactForDrug = new EventEmitter();
  @Output() preferredStatus = new EventEmitter();
  actvModal: any;
  dataForDrug: any;
  unsubscribe$: Subject<void> = new Subject();
  constructor(private _drugUtils: DrugUtils, private _modalService: NgbModal, private _alertSrv: AlertService) { }

  ngOnInit() {
      if (this.openMMS) {
          this.openModal("MMS");
      }
      
  }

  ngAfterViewInit() {
    console.log(this.dropDownValue);
    if (this.openNPatchFrmMMS) {
      this.openModal("MMS");
    }
  }

  openModal(type) {
      this.actvModal = type;
  }

  async openEquivalentPopUp() {
    const equiValDrugs = await this._drugUtils.getEquivalentDrugs(this.drugId);
    const modalRef: any = this._modalService.open(
        EquivalentDrugsComponent,
        {
            backdrop: false,
            size: (equiValDrugs?.length) ? "lg" :"sm",
            keyboard: false,
            centered: true,
            windowClass : (equiValDrugs?.length) ? "md-xx-lg" : "medium-sm-modal",
        }
    );
    this.actvModal = "equival";
    modalRef.componentInstance.EqulDrugPopType = "DrugHeader";
    modalRef.componentInstance.DiscDrugInfo = { drugname: this.drugInfo.Drug.Name, strength: this.drugInfo.Drug.Strength, drugformname: this.drugInfo.Drug["DrugFormName"] , drugcode : this.drugInfo.Drug.DrugCode};
    modalRef.componentInstance.EquivalentDrugWJ = new CollectionView(equiValDrugs);
    modalRef.componentInstance.preferredDrugInfo = {isPreferred: this.isPreferred, drugname: this.drugInfo.Drug.Name, strength: this.drugInfo.Drug.Strength, drugformname: this.drugInfo.Drug["DrugFormName"] , ndc : this.drugInfo.Drug.DrugCode, drugID : this.drugInfo.Drug.Id};
    modalRef.componentInstance.emitPrefStatus.pipe(takeUntil(this.unsubscribe$)).subscribe((prefResp: any) => {
      this.drugInfo.Drug.IsPreferred = prefResp;
      this.isPreferred = prefResp;
      this.preferredStatus.emit(prefResp);
    });
    modalRef.componentInstance.CloseAdminRxPopUp.pipe(takeUntil(this.unsubscribe$)).subscribe(resp => {
        this.actvModal = null;
    });
  }

  openCompPopup(val) {
      this.OpenCompndDrug.emit(val);
  }
  dataforDrugManfact(event){
    this.dataForDrug = event;
    this.ManFactForDrug.emit(this.dataForDrug);
  }
  ngOnDestroy(){
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
