import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SystemData } from 'src/app/models';
import { AlertService, CommonService, RxService } from 'src/app/services';
import { CollectionView } from "@grapecity/wijmo";
import * as moment from 'moment';
import { CommonUtil, DrugUtils, WijimoUtil } from 'src/app/utils';
import { MsgConfig } from 'src/app/app.messages';
import * as wjcGrid from "@grapecity/wijmo.grid";
import { FormBuilder, FormGroup } from '@angular/forms';
import { iouInfo } from 'src/app/models/iouInfo.model';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CommonPrintService } from 'src/app/services/common-print.service';
import { FormatsUtil } from 'src/app/utils/formats.util';

@Component({
  selector: 'app-iou-capture',
  templateUrl: './iou-capture.component.html',
  styleUrls: ['./iou-capture.component.scss']
})
export class IouCaptureComponent implements OnInit {
  modal: any;
  rxInfo: any;
  iouValues: any;
  IOUListWJ: any;
  wjHeaders: any;
  isDataExists: boolean;
  additionalItems = false;
  OwedQuantity: any;
  ActDispQty: number;
  drugInputValue: boolean;
  IOUAddEditFG: FormGroup;
  isEdit: boolean;
  modalAddEdit: any;
  today: string;
  currentTime: string;
  time: { hour: number; minute: number; };
  expandedItem: any;
  ImgUrl: Promise<any>;
  drugDescp: any;
  deletedActDisp: any;
  deletedOweQty: any;
  unsubscribe$: Subject<void> = new Subject();
  selectedItemToDel: any;
  modalDelete: any;
  @Input()
    set RxInfo(rxInfo: any) {
        this.rxInfo = rxInfo[0];
    }
  @ViewChild("iouflex")
  flex: wjcGrid.FlexGrid;
  @ViewChild("DELETEIOU", {static: true})
  DELETEIOU: any;
  @Input() systemData: SystemData;
  @ViewChild("IOUADD", { static: true })
  IOUADD: any;
  @ViewChild("IOUAddEdit", {static: true})
  IOUAddEdit: any;
  @Output() IsPopupClosed = new EventEmitter();
  constructor(private _modalService: NgbModal, private _rxServ: RxService, private _comnUtil: CommonUtil, private _wijimoUtils: WijimoUtil, public _cmnSvc: CommonService, private _fb: FormBuilder, public _drugUtils: DrugUtils, private _alrtSvc: AlertService, private _cdr: ChangeDetectorRef, private _commonPrintSer: CommonPrintService,
    private _formatUtil: FormatsUtil
  ) { 
    this.createFG();
  }

  ngOnInit(): void {
    this.OwedQuantity = this.rxInfo.PrescReFill.OweQty;
    this.ActDispQty = this.rxInfo.PrescReFill.DispQty - this.OwedQuantity;
    this.today = moment().format("MM/DD/YYYY");
    this.currentTime = moment().add().format("HH:mm:ss");
    const hourAndMin = this.currentTime.split(":");
    this.time = { hour: parseFloat(hourAndMin[0]), minute: parseFloat(hourAndMin[1]) };
    this.getIOUValues();
    this.patchDefaultValHeaders();
        this._wijimoUtils.wjSavedData$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((w) => {
            if (
                w &&
                w["WijmoKey"] &&
                (w["WijmoKey"] === "iouWjList")
            ) {
                this.patchDefaultValHeaders();
            }
        });

    this.modal = this._modalService.open(this.IOUADD, {
      centered: true, backdrop:false, keyboard: false, size:"lg"
    });
  }
  createFG() {
    this.IOUAddEditFG = this._fb.group(new iouInfo());
}
  async patchDefaultValHeaders() {
    const storedWJ = await this._wijimoUtils.getWJSavedData("iouWjList");
    this.wjHeaders = this._wijimoUtils.patchDefHeader(
      "iouWjList", storedWJ);
  }
  
ngOnDestroy() {
	this.unsubscribe$.next();
	this.unsubscribe$.complete();
}
  getIOUValues() {
    const PrescId = {
      PrescrefillId: this.rxInfo.PrescReFill.Id
    }
    this._rxServ.getRxIOUNewValues(PrescId)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((resp) => {
        if (resp && resp.Iou && resp.Iou.length) {
          this.isDataExists = true;
          this.iouValues = resp.Iou;
          this.generateIOUWJ(this.iouValues);
        } else {
          this.iouValues = [];
          this.isDataExists = false;
          this.generateIOUWJ(this.iouValues);
        }
    });
  }
  init(flex: wjcGrid.FlexGrid) {
    flex.columnHeaders.rows[0].wordWrap = true;
    flex.autoSizeRow(0, true);
}
  generateIOUWJ(iouList) {
    const mapDataSet = []
    if (iouList?.length) {
      
      iouList.map(async (item, i) => {
                      const j = {};
                  j["Id"] = item.Id;
                  j["RowId"] = i;
                  j["isClicked"] = true;
                  j["TenantId"] = item.TenantId;
                  j["NDC#"] = j["DrugNDC"] =  this._formatUtil.getNDCFormat(item.DrugNDC);
                  j["Drug Name"] = j["DrugName"] = item.DrugName;
                  j["PrescRefillId"] = item.PrescRefillId;
                  j["ActDtTm"]= item.DispDtTm;
                  j["DispQty"] = j["DispQty"] =  item.DispQty.toFixed(3);
                  j["Actual Qty Disp"] = (item.DispQty).toFixed(3);
                  j["Qty. Owed"] = j["OweQty"] =  item.OweQty.toFixed(3);
                  j["Date & Time"] = j["DispDtTm"] = item.DispDtTm ? moment.utc(item.DispDtTm).local().format(
                    "MM/DD/YYYY hh:mm:ss a") : "";
                  j["Notes/Comments"] = j["Comments"] = item.Comments;
                  j["Source"]  = item.Source;
                  j["DrugId"] = item.DrugId;
                  j["DPV"] = item.IsDPVVerfied?"Y":"N";
                  j["DrugLotId"]  = (item.DrugLotId);
                  j["GTIN"]  = (item.GTIN);
                  j["SerialNo"]  = (item.SerialNo);
                  j["UserId"] = item.UserId;
                  j["Lot#"] = j["LotNum"] = item.LotNum;
                  j["Expiration Date"] = j["LotExpiryDtTm"] = item.LotExpiryDtTm ?
                      moment.utc(item.LotExpiryDtTm).local().format(MsgConfig.DATE_FORMAT) : "";
                  j["DrugDescription"] = item.drugDescription;
                  j["DrugImage"] = item.DrugImage;
                  j["ImgUrl"] = "";
                  j["IsRefrigeratorItem"] = item.IsRefrigeratorItem;
                  j["IsEquv"] = item.NotEquivalent ? false : true;
                  mapDataSet.push(j)
                  });
                }
                  this.IOUListWJ = new CollectionView(mapDataSet,
                      {
                          sortComparer: (a: any, b: any) => {
                              return moment(a).isValid() && moment(b).isValid()
                                  ? moment(a).diff(moment(b)) : null;
                          }
                      })
                      this.IOUListWJ.items.map(async (Item: any) => {
                        Item.ImgUrl = await this._drugUtils.getImageName(null, null, false, Item.DrugImage, true);
                      })
    
  }
  async showExpand(item?) {
    if (item) {
      this.ImgUrl = await this._drugUtils.getImageName(null, null, false, item.DrugImage, true);
      this.drugDescp = item.DrugDescription;
    }
  }
  async printLabel(index) {
    if (this.iouValues && this.iouValues[index]) {
        this.rxInfo.Drug.drugcode = this.iouValues[index]?.DrugNDC;
        this.rxInfo.isFrmDpvVerified = index;
        this._commonPrintSer.printLabelToRx(this.rxInfo, false, false, false, "labelPrint", "dpvVerf");
    }
  }
  /*openAddEditIOUPopup(type, data?) {
    this.isEdit = type === "Edit" ? true : false;
    this.drugInputValue = this.rxInfo.Drug.drugname ? this.rxInfo.Drug.drugname : "";
    this.IOUAddEditFG.controls["drugNDC"].patchValue(this.rxInfo.Drug.drugcode);
    this.IOUAddEditFG.controls["actDispQty"].patchValue(data ? data.DispQty : "0.000");
    this.IOUAddEditFG.controls["owedQty"].patchValue(data ? data.OweQty : "0.000");
    this.IOUAddEditFG.controls["comments"].patchValue(data.Comments);
    const startDate = moment.utc(data.ActDtTm).local().format("MM/DD/YYYYTHH:mm:ss");
    const splitDtTime = startDate.split("T");
    const hourminutes = splitDtTime[1].split(":");
    this.time = { hour: parseFloat(hourminutes[0]), minute: parseFloat(hourminutes[1]) };
    this.IOUAddEditFG.patchValue({
      startdate: moment.utc(data && data.ActDtTm).local().format("MM/DD/YYYY"),
      hours: hourminutes[0],
      minutes: hourminutes[1]
    });
    this.IOUAddEditFG.controls["gtin"].patchValue(data.GTIN);
    this.IOUAddEditFG.controls["serial"].patchValue(data.SerialNo);
    this.IOUAddEditFG.controls["LotNo"].patchValue(data.LotNum);
    this.IOUAddEditFG.controls["ExpDt"].patchValue(data.LotExpiryDtTm);
    this.modalAddEdit = this._modalService.open(this.IOUAddEdit, {
      centered: true, backdrop:false, keyboard: false
    });
  }
  timeValue(eve) {
    if (eve) {
      this.IOUAddEditFG.patchValue({
        hours: eve["hour"],
        minutes: eve["minute"]
      });
    } else {
      this.IOUAddEditFG.patchValue({
        hours: eve,
        minutes: eve
      });
    }
  }*/
  selectDrug(drugInfo: any) {
    this.drugInputValue = true;
    if (drugInfo) {
        this.IOUAddEditFG.controls["DrugId"].patchValue(drugInfo.id);
        this._cdr.detectChanges();
        this.drugInputValue = drugInfo.drugname ? drugInfo.drugname : "";
        this.IOUAddEditFG.controls["drugNDC"].patchValue(drugInfo.drugcode);
    } else {
        this.IOUAddEditFG.controls["DrugId"].patchValue(null);
        this.drugInputValue = false;
    }
}

  openDeletePopup(item) {
    this.selectedItemToDel = item;
    this.modalDelete = this._modalService.open(this.DELETEIOU, {centered: true, keyboard: false, backdrop: false, windowClass:"large--content"})
  }
  deleteIOU() {
    if (this.modalDelete) {
      this.modalDelete.close();
      this.modalDelete = null;
    }
    this.deletedActDisp = this.selectedItemToDel.DispQty;
    this.deletedOweQty = this.selectedItemToDel.OweQty;
    const deleteData = {
      PrescId: this.rxInfo.Prescription.Id,
      PrescrefillId: this.selectedItemToDel.PrescRefillId,
      IsFromDPV: false,
      iouId: this.selectedItemToDel.Id,
      DrugPickVerifId: "",
      PrescNum: this.rxInfo.Prescription.PrescNum,
      RefillNum: this.rxInfo.PrescReFill.ReFillNum,
      FillNum: this.rxInfo.PrescReFill.PartialFillNo,
    }
    this._rxServ.deleteIOURecord(deleteData)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((resp: any) => {
      if (resp) {
        this.ActDispQty = (+this.ActDispQty) - (+this.deletedActDisp);
        this.OwedQuantity = (+this.OwedQuantity) + (+this.deletedActDisp);
        this.getIOUValues();
      }
    });
  }
  closeModal() {
    if (this.modal) {
      this.modal.close();
      this.modal = null;
    }
    if (this.modalAddEdit) {
      this.modalAddEdit.close();
      this.modalAddEdit = null;
    }
    this._alrtSvc.showHelpText(null);
    this.IsPopupClosed.emit(this.OwedQuantity);
  }
  cancelAddEditIOU() {
    if (this.modalAddEdit) {
      this.modalAddEdit.close();
      this.modalAddEdit = null;
    }
  }
  toggleColorLegend() {
    this._alrtSvc.showHelpText({RxStatus: false, DrugName: false, Status: false,DrugForDrugFile: false,DrugForGridDrugFile:false,NDCEqvNonEqv: true});
  }

  itemFormatter = (panel, r, c, cell) => {
    const row = panel.rows[r];
    if (row.dataItem) {
        cell.style.background = "";
        if (panel.cellType === wjcGrid.CellType.Cell && panel.columns[c].binding === "Drug Name") {
            if (row.dataItem.IsEquv) {
              cell.style.background = "#FFF";
              cell.style.color = "#51a351";
            } else {
              cell.style.background = "#FFF";
              cell.style.color = "orange";
            }
            cell.style.fontWeight = "bold";
            cell.style.textAlign = "initial";
        } else {
            cell.style.backgroundColor = "#FFF";
            cell.style.color = "#000";
            cell.style.fontWeight = "normal";
            cell.style.textAlign = "initial";
        }
    }
}

}
