
<div class="row edit-patient">
    <div class="col-md-4">
        <app-basic-info [focusId]="focusId" [formGroupInvalid]="formGroupInvalid" [FrmFamily]="FrmFamily" (SavePhonePreferred)="savePreferred($event)"
        (InsurFromLinkedFamliy)="changeInsurance($event)" [patientInfo]="patientInfo" [patientInfoFG]="patientInfoFG"
        [insuranceData]="insuranceList" [systemData]="systemData" [patientId]="patientId" [EditPatientInfo]="EditPatInfo"></app-basic-info>
    </div>
    <div class="col-md-4">
        <app-prime-insurance [focusId]="focusId" [FrmFamily]="FrmFamily" [PatInsuId]="PatInsuId" [InsuranceName]="newInsurance" [patientID]="patientId" [patientInfo]="patientInfo" [patientInfoFG]="patientInfoFG" [insuranceData]="insuranceList"
         [Patch340BIns]="patch340BIns" [systemData]="systemData" [patientInsurances]="patientInsuList$ | async" (AddtnlInsurncTab)="addtnlInsurncTab($event)" [formGroupInvalid]="formGroupInvalid"></app-prime-insurance>
        <app-diagnosis-details [frmBasicInfo]="true" [patientId]="patientId" [patientFG]="patientInfoFG"></app-diagnosis-details>
    </div>
    <div class="col-md-4">
        <app-other-info (Exist340bInsuranceIns)="exist340bInsuranceInsPop($event)" (After340bInsurancePopupClose)="closePopUpFor340BInsurance($event)" [basicInfo]="patientInfo" [patientInfoFG]="patientInfoFG" [patientInsurances]="patientInsuList$ | async" (patch340BIns)="patch340BInss($event)" [SystemData]="systemData"></app-other-info>
        <app-pat-allergies  [patientFG]="patientInfoFG"  [systemData]="systemData"></app-pat-allergies>
        <app-pricing [patientInfo]="patientInfo"    [patientInfoFG]="patientInfoFG" [systemData]="systemData"></app-pricing>
    </div>

</div>
