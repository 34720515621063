<ul class="edit-patient--icons">
    <span>
        <i (click)="openEligblPopUpModal()" title="{{eligibilityDtTm}}" class="far fa-user-check" aria-hidden="true"></i>
    </span>
</ul>
<ul class="edit-patient--icons" *ngIf="checkHasMergePriv() && patientInfo && patientId">
    <span>
        <a>
            <i (click)="openMergePatient()" class="far fa-exchange-alt" title="Merge Patient" aria-hidden="true"></i>
        </a>
    </span>
</ul>
