

<ng-template #AddInsurance let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">ADD INSURANCE DETAILS</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="closePopup()">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <div class="eprx--block__header">
                    <div class="eprx--header__heading">
                        <h4>Insurance</h4>
                    </div>
                </div>
                <div class="eprx--block__content">
                    <div class="row">
                        <div class="col form-group custom--input" [ngClass]="{
                            'has-error':
                            insuranceFG?.get('Insurance.InsurerId')?.invalid &&
                                (insuranceFG?.get('Insurance.InsurerId')?.dirty || insuranceFG?.get('Insurance.InsurerId')?.touched) &&
                                insuranceFG?.get('Insurance.InsurerId')?.errors
                        }">
                            <label class="control-label pat-insuType">
                                <span> Ins.Type <span class="help--text__danger">*</span></span>
                                <span class="curency-format">
                                    <input type="text" #searchBoxInsType [value]="insuCode"
                                        class="right-part form-control search--input__box"
                                        placeholder="To Start Search..." appChangeDebounce [ngbTypeahead]="search"
                                        [required]="true" [resultTemplate]="rt" [inputFormatter]="formatter"
                                        (selectItem)="selectedInsType($event)" KeyUpDown [appAutofocus]="true">
                                    <div class="left-part">
                                        <input type="text" tabindex="-1" #searchBoxInsName [value]="insuName"
                                            name="searchBoxInsName" class="form-control search--input__box" readonly />
                                    </div>
                                </span>
                            </label>
                            <a class="add__icon" (click)="routeToAddInsurance()" appShortcutKey [AltKey]="'i'">
                                <i class="far fa-plus-circle fa-lg" title="Add Insurance"></i>
                            </a>
                            <div class="help-block"
                                *ngIf="insuranceFG?.get('Insurance.InsurerId')?.invalid && (insuranceFG?.get('Insurance.InsurerId')?.dirty || insuranceFG?.get('Insurance.InsurerId')?.touched)">
                                Required
                            </div>
                            <ng-template #rt let-r="result" let-t="term">
                                <div class="card patient-card secondary-patient-card" *ngIf="r">
                                    <div class="row">
                                        <div class="col">
                                            <div class="row">
                                                <div class="card__subTit--head">
                                                    Insurance Code:
                                                </div>
                                                <div class="card__subTit--title">
                                                    {{ r?.InsurerCode }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <div class="row">
                                                <div class="card__subTit--head">
                                                    Insurance Name :
                                                </div>
                                                <div class="card__subTit--title">
                                                    {{ r?.InsuranceName }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <div class="row">
                                                <div class="card__subTit--head">
                                                    Bin Num :
                                                </div>
                                                <div class="card__subTit--title">
                                                    {{ r?.BinNum }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <div class="row">
                                                <div class="card__subTit--head">
                                                    PCN Num :
                                                </div>
                                                <div class="card__subTit--title">
                                                    {{ r?.ProcessorNum }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </div>
                        <div class="col-2">
                            <eprx-input [RxSelectId]="'InsuId'" [LabelText]="'Insurance ID'"
                                [PlaceHolder]="'Insurance ID'" [FormGroupInvalid]="formGroupInvalid"
                                [ControlName]="'PolicyNum'" [FormGroupName]="insuranceFG?.get('InsuCard')"
                                [ErrorDefs]="{pattern: 'Invalid!', required: 'Required', invalid: ''}"
                                [IsRequired]="policyRequired" [MaxLength]=80
                                [MarkAsTouched]="insuranceFG?.get('InsuCard.PolicyNum')?.touched">
                            </eprx-input>
                        </div>
                        <div class="col-2">
                            <eprx-select [LabelText]="'Group#'" [PlaceHolder]="'Group#'"
                                [ControlName]="'InsuGrpId'" [FormGroupName]="insuranceFG?.get('Insurance')"
                                [List]="filteredGroups" [BindLabel]="'Name'"
                                [BindValue]="'Id'" [InputValue]="insuranceFG?.get('Insgroup.Name')?.value"
                                [LabelForId]="'Name'" (TriggerSelectValue)="isTypedValue($event)" [AddItem]="true"
                                (TriggerOnClear)="isTypedValue($event)" [SelectOnTab]="true" [addItemMaxLength]="35">
                            </eprx-select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="eprx--block__header">
                    <div class="eprx--header__heading">
                        <h4>Billing</h4>
                    </div>
                </div>
                <div class="eprx--block__content">
                    <div class="row">
                        <div class="col">
                            <eprx-select [RxSelectId]="'primInsRela12'" [LabelText]="'Relationship'"
                                [PlaceHolder]="'Relationship'" [ControlName]="'RelCatId'"
                                [FormGroupName]="insuranceFG?.get('Insurance')"
                                [List]="systemData?.RelationCat ? (systemData?.RelationCat | filterRelation:'Patient') : []"
                                [BindLabel]="'Remarks'" [BindValue]="'Id'" [LabelForId]="'Name'">
                            </eprx-select>
                        </div>
                        <div class="col">
                            <!-- check these control -->
                            <eprx-input [LabelText]="'Person Code'" [PlaceHolder]="'Person Code'"
                                [ControlName]="'PerNum'" [FormGroupName]="insuranceFG?.get('Insurance')"
                                [ErrorDefs]="{pattern: 'Invalid!'}" [ValidPattern]="regex?.AlphaNumeric"
                                [MaxLength]="3">
                            </eprx-input>
                        </div>
                        <div class="col">
                            <eprx-input [LabelText]="'Card Holder\'s Last Name'"
                                [PlaceHolder]="'Card Holder\'s Last Name'" [ControlName]="'LastName'"
                                [FormGroupName]="insuranceFG?.get('InsuCard')" [ErrorDefs]="{pattern: 'Invalid!'}"
                                [ValidPattern]="regex?.AlphaNumeric" [MaxLength]="35">
                            </eprx-input>
                        </div>
                        <div class="col">
                            <eprx-input [LabelText]="'Card Holder\'s First Name'"
                                [PlaceHolder]="'Card Holder\'s First Name'" [ControlName]="'FirstName'"
                                [FormGroupName]="insuranceFG?.get('InsuCard')" [ErrorDefs]="{pattern: 'Invalid!'}"
                                [ValidPattern]="regex?.AlphaNumeric" [MaxLength]="35">
                            </eprx-input>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <eprx-date-picker [RxSelectId]="'primInsCardExp'" [LabelText]="'Card Expires'"
                                [PlaceHolder]="'Card Expires'" [ControlName]="'ExpiryDt'" [FormGroupName]="insuranceFG?.get('InsuCard')"
                                [MinDate]="minDate" [IsRequired]="true"
                                [ErrorDefs]="{ required: 'Required' ,minDate:'Date should be greater than today'}"
                                [MarkAsTouched]="insuranceFG?.get('InsuCard.ExpiryDt')?.touched">
                            </eprx-date-picker>
                            <!-- <eprx-date-picker [LabelText]="'Card Expires'" [PlaceHolder]="'Card Expires'"
                                [ControlName]="'ExpiryDt'" [FormGroupName]="InsuCard" [MinDate]="minDate">
                            </eprx-date-picker> -->
                        </div>
                        <div class="col">
                            <eprx-select [LabelText]="'Billing Priority'" [PlaceHolder]="'Billing Priority'"
                                [ControlName]="'InsuPriId'" [FormGroupName]="insuranceFG?.get('Insurance')"
                                [List]="[{Id: 0, value: 'None'}, { Id: 2, value: 'Secondary' },
                                { Id: 3, value: 'Tertiary' }]" [BindLabel]="'value'" [BindValue]="'Id'" [LabelForId]="'Name'"
                                (TriggerSelectValue)="selectedPriority($event['value'].Id)">
                            </eprx-select>
                        </div>
                        <div class="col">
                            <eprx-radio [LabelText]="'Is Active'" [ControlName]="'ActiveStatusId'"
                                [FormGroupName]="insuranceFG?.get('Insurance')" [List]="['Yes', 'No']"
                                [ValueList]="[1, 2]" [IDForList]="['ActiveStatusId4', 'ActiveStatusId5']"
                                [LabelForId]="" [Name]="'ActiveStatusId'">
                            </eprx-radio>
                        </div>
                        <div class="col">
                            <eprx-radio [LabelText]="'Patient Assignment'" [ControlName]="'IsPatAssigned'"
                                [FormGroupName]="insuranceFG?.get('Insurance')" [List]="['Yes', 'No']"
                                [ValueList]="[true, false]" [IDForList]="['IsPatAssigned2', 'IsPatAssigned3']"
                                [LabelForId]="" [Name]="'IsPatAssigned'">
                            </eprx-radio>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="eprx--block__header">
                    <div class="eprx--header__heading">
                        <h4>Comments</h4>
                    </div>
                </div>
                <div class="eprx--block__content">
                    <div class="row">
                        <div class="col">
                            <eprx-text-area [FormGroupName]="insuranceFG?.get('Insurance')" [ControlName]="'Remarks'"
                                [PlaceHolder]="'Comments'" [Rows]="3">
                            </eprx-text-area>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="hotkey_success" (click)="checkFGValid()" appShortcutKey
            [AltKey]="'s'"><span>S</span>SAVE</button>
        <button type="button" class="hotkey_success" appShortcutKey [AltKey]="'c'"
            (click)="closePopup()"><span>C</span>CANCEL</button>
    </div>
</ng-template>
