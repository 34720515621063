<app-insurance-tabs *ngIf="currTab" [ActiveTabId]="currTab" [resetToFirst]="resetToFirst" (addInsurance)="addInsurance($event)" [insuId]="insuId" [insuFG]="planInfoFG" [insuranceInfo]="planInfo$ | async" (ActivePrescriberTab)="selectedTab($event)"></app-insurance-tabs>
<div class="editpatient--body newrx--body edit--drug insurance--body ">
    <div class="editpatient">
        <div class="row content--heading">
            <div class="col-md-7 padding-0">
                <div class="row">
                    <div class="col-md-10 ">
                        <div class="content--heading__title ">
                            {{ insuHeaders[currTab ? currTab : 'PLANINFO'] }} |
                            <span class="content--name">
                                {{ planInfoFG.value?.Organization?.Name | uppercase }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-2 edit-patient--group--icon padding-0">
                <div class="modal-icon">
                    <div class="insurance--group--icon padding-0">
                        <app-insu-common-header *ngIf="currTab" (onCopyInsuClick)="onCopyInsu()" [openMMS]="openMMS" [insuId]="insuId"
                            [insuInfo]="planInfo$ | async" [planInfoFG]="planInfoFG" [stngFG]="stngFG" [transStngFG]="transStngFG"
                            [dropDownData]="dropDownData">
                        </app-insu-common-header>
                    </div>
                </div>
            </div>

            <div class="text-right pull-right col-md-3 padding-0">
                    <button class="hotkey_success" id ="InsSaveButton"  appShortcutKey [AltKey]="'s'"
                    (click)="onSaveClick()">
                        <span>S</span> SAVE
                    </button>
                    <button class="hotkey_success" style="margin-right: 0px;"  appShortcutKey [AltKey]="'c'"
                    (click)="onCancel('cancel')">
                        <span>C</span> Cancel
                    </button>
            </div>
        </div>
    </div>

    <span>
        <div [ngSwitch]="currTab">
            <div *ngSwitchCase="tabEnum?.Patient1">
                <app-plan-info [selectedRow]="selectedRow" [insuId]="insuId" [formGroupInvalid]="formGroupInvalid" [openMMS]="openMMS" [dropDownData]="dropDownData" [planInfo]="planInfo$ | async" [systemData]="systemData$ | async" [planInfoFG]="planInfoFG"></app-plan-info>
            </div>
            <div *ngSwitchCase="tabEnum?.Patient2">
                <app-insu-settings [insuId]="insuId" [planInfoFG]="planInfoFG" [dropDownData]="dropDownData" [systemData]="systemData$ | async" [settingInfo]="insuStngInfo" [insuStngFG]="stngFG"></app-insu-settings>
            </div>
            <div *ngSwitchCase="tabEnum?.Patient3">
                <app-insu-trans-setting [insuId]="insuId" [dropDownData]="dropDownData" [transStngInfo]="insuStngInfo" [transStngFG]="transStngFG"></app-insu-trans-setting>
            </div>
            <div *ngSwitchCase="tabEnum?.Patient4">
                <app-other-insu [planInfo]="planInfo$ | async" [insuId]="insuId" [dropDownData]="dropDownData"></app-other-insu>
            </div>
            <div *ngSwitchCase="tabEnum?.Patient5">
                <div class="eprx--block__content notes--block">
                    <div [formGroup]="planInfoFG">
                        <div class="row">
                            <div class="col-lg-12 padding--right__7" formGroupName="InsuPlan">
                                <div class="form-group">
                                    <label class="control-label full--width">
                                        <span>Notes</span>
                                        <textarea  autofocus class="custom--text__area" formControlName="Remarks" value="{{ planInfoFG?.value?.InsuPlan?.Remarks}}"
                                            style="width:99.5%; top: 0.3rem;"  [maxLength]="1000"></textarea>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngSwitchCase="tabEnum?.Patient6">
                <app-notes-log [InsuGp]="planInfo$ | async" [NotesFr]="'Insurance'"></app-notes-log>
            </div>
            <div *ngSwitchCase="tabEnum?.Patient7">
                <app-document-ref [documentFr]="{From: 'Insurance', Id: planInfoFG.value?.InsuCarrier?.Id}" (RefreshParentEntity)="getPlanInfo('Patient7',true)"></app-document-ref>
            </div>
            <div *ngSwitchCase="tabEnum?.Patient8">
                <app-insu-billing [InsBillingFG]="insuBillFG"></app-insu-billing>
            </div>
        </div>
    </span>

    <app-cancel-pop-up *ngIf="popUpType === 'cancel'" (IsPopUpClosed)="onCancel($event)"></app-cancel-pop-up>

    <ng-template #UNIQUEINPLANCODE let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h4 class="modal-title">Warning!!</h4>
            <button type="button" [tabindex]="-1" class="close" aria-label="Close"
                (click)="resetPlanCode(); d('Cross click')">
                <span aria-hidden="true" class="close-button">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <p>Insurance Code Already Exists. Please enter a different one. </p>
        </div>
        <div class="modal-footer">
            <button autofocus type="button" class="hotkey_primary" (click)="resetPlanCode(); d('Cross click')"
                (keydown.enter)="resetPlanCode(); d('Cross click')" appShortcutKey InputKey="o"><b>O</b> Ok</button>
        </div>
    </ng-template>
