<div class="exprx--common-block">
    <div class="eprx--block__header">
        <div class="eprx--header__heading">

        </div>
    </div>
    <div class="eprx--block__content" >
        <dual-list id="dual" [(destination)]="drugCatFG?.value['categories']" [source]="source" [height]="'300px'"
        (destinationChange)="makeDrugCatFgDirty()">
        </dual-list>
    </div>
</div>
