<ng-template #AddQueue let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">Select Rxs to Add Refill Queue</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="eprx--block__content erx--body editpatient">
            <div class="col easy-ref--block">
                <div class="col-xs-12 col-md-12 padding-0">
                    <div class="row">
                        <div class="col-md-12 padding-0">
                            <div class="row easy-ref--content">
                                <div class="col-md-12 row">
                                    <div class="col-md-3 easy-ref inline-flex">
                                        <eprx-input [LabelText]="'Search Rx#'" [PlaceHolder]="'Search Rx#'" [HasControl]="false" [RxSelectId]="'searchRx'" (TriggerOnEnterValue)="getRx($event?.target?.value,'fromInput')"
                                            [InputType]="'number'" [isRxNumber]="true" [InputValue]="rxNumber">
                                        </eprx-input>
                                    </div>
                                    <div class="text-right pull-right col-md-9 padding-0 editpatient padding_top_16px">
                                            <div>
                                                <button class="hotkey_success" (click)="routeToPatHis()" appShortcutKey [AltKey]="'p'"><span>P</span> Pat. History</button>
                                            </div>
                                        </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <wj-flex-grid *ngIf="rxData?.length" #RefillQueue [headersVisibility]="'Column'" [isReadOnly]="true" [columnPicker]="'refillQueueWJ'"
                                        [itemsSource]="addRefillQueueListDisplayWJ" [itemFormatter]="itemFormatter"  [selectionMode]="'Row'">
                                        <wj-flex-grid-column [header]="'Action'" [width]="62">
                                            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                                <i class="far fa-trash-alt actions danger-actve" title="Delete" (click)="delRow2(item)"></i>
                                            </ng-template>
                                        </wj-flex-grid-column>
                                        <wj-flex-grid-column [header]="rxheader['hName']" [binding]="rxheader['hName']" [visible]="rxheader['isVisible']"
                                            *ngFor="let rxheader of wjHeaders;" [width]="rxheader['width']">
                                            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                                <div *ngIf="!(rxheader['hName'] === 'Rx#' || rxheader['hName'] === 'Drug Name')">
                                                    {{ item[rxheader['hName']] }}
                                                </div>
                                                <div *ngIf="(rxheader['hName'] === 'Rx#' || rxheader['hName'] === 'Drug Name')"
                                                 [ngbTooltip]="rxheader['hName'] === 'Drug Name' ? item['DrugHoverOver'] : rxheader['hName'] === 'Rx#' ? item['RxHoverOver'] : ''" placement = "top"  triggers="mouseover:mouseleave" container="body" tooltipClass="tooltip-title">
                                                        {{item[rxheader['hName']] }}
                                                </div>
                                            </ng-template>
                                        </wj-flex-grid-column>
                                    </wj-flex-grid>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer eprx--block__content erx--body">
        <div class="col-md-6 inline-flex">
            <div class="col-xs-12 inline-flex">
                <label> Total Claim : </label>
                <div class="label--data">
                    {{rxData.length}}
                </div>
            </div>
        </div>
        <div class="text-right pull-right col-md-6 padding-0 editpatient">
            <div>
                <!-- <button (click)="clearValueAndFocus()">Check</button> -->
                <button class="inactive" *ngIf="!(selectedRxs.length > 0)">Continue</button>
                <button class="hotkey_success" *ngIf="(selectedRxs.length > 0)" (click)="addToQueue()" appShortcutKey [AltKey]="'o'"><span>O</span> OK</button>
                <!-- <button [ngClass]="[(selectedRxs.length > 0) ? 'submit' : 'inactive']" (click)="addToQueue()">Continue(Ctrl+O)</button> -->
                <button class="hotkey_success" (click)="closeModal()" appShortcutKey [AltKey]="'c'"><span>C</span> Cancel</button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #Rxexists let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="clearValueAndFocus('Rxexists')">
        <h4 header> Warning!!</h4>
        <span body>
            <p>The selected
            <span *ngFor="let showRx of showRxNos;index as i">
                <b>{{showRx}}</b>
                <span *ngIf="i < showRxNos.length-1">
                    <b>, </b>
                </span>
            </span>
            Rx(s) already exists in the selected list, hence {{count}} Rx(s)  added.</p>
        </span>
        <button footer class="hotkey_primary" id="focusonRx" appShortcutKey InputKey="o" (click)="clearValueAndFocus('Rxexists')"> <b>O</b> OK
        </button>
    </app-custom-modal>
</ng-template>

<ng-template #deceasedRx let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModalAndOpenRefillDue()">
        <h4 header> Warning!!</h4>
        <span body>
            <p>Selected Patient <b>{{warningMsgPat}}</b> is deceased. Rx(s) of this patient cannot be added to Refill Queue.</p>
            <!-- <p>Selected Prescription has decease patient <b>{{warningMsgPat}}</b> unable to Add Refill Queue.</p> -->
        </span>
        <button footer class="hotkey_primary" appShortcutKey InputKey="o" (click)="closeModalAndOpenRefillDue()"> <b>O</b> OK
        </button>
    </app-custom-modal>
</ng-template>

<ng-template #duplicateRx let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="d('Cross click'); afterAddQueue()">
        <h4 header> Warning!! </h4>
        <span body>
            {{warnDuplicate}}
        </span>
        <button footer class="hotkey_primary" appShortcutKey InputKey="o" (click)="d('Cross click'); afterAddQueue()"> <b>O</b> OK
        </button>
    </app-custom-modal>
</ng-template>
