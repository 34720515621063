import { CommonService } from './../../../../services/common.service';
import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    AfterViewInit,
    ChangeDetectorRef,
    OnDestroy,
    ViewChild,
    TemplateRef
} from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { SystemData, PatientInfo } from "../../../../models";
import { RegEx } from "../../../../app.regex";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PatientInfoUtil } from 'src/app/utils/patient-info.util';
import { AdditionalInsuranceService, AlertService } from 'src/app/services';
import { CommonWarnorconfirmPopupComponent } from 'src/app/modules/shared/common-warnorconfirm-popup/common-warnorconfirm-popup.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: "app-other-info",
    templateUrl: "./other-info.component.html"
})
export class POtherInfoComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() basicInfo: PatientInfo;
    @Input() patientInfoFG: FormGroup;
    @Input() patientInsurances: any[];
    regex: any;
    systemData: SystemData;
    Insu340B: any;
    Is340BPharm$: any;
    unsubscribe$: Subject<void> = new Subject();
    @Input()
    set SystemData(sd: any) {
        this.systemData = sd;
    }

    @Output() basicInfoForm = new EventEmitter<FormGroup>();

    @Output() patch340BIns = new EventEmitter<boolean>();

    @Output() After340bInsurancePopupClose = new EventEmitter<boolean>();
    @Output() Exist340bInsuranceIns = new EventEmitter<boolean>();

    @ViewChild("PatchIns340B", {static: true})
    PatchIns340B: TemplateRef<any>;
    insuIdFor340b: any;

    constructor(private _cdr: ChangeDetectorRef, private _commonServ: CommonService,
        private _modalServ: NgbModal, private _patUtils: PatientInfoUtil,
        private _alertService: AlertService,
        private _additionalInsu: AdditionalInsuranceService) {
        this.regex = RegEx;
    }

    ngOnInit() {
        if (this.systemData && this.systemData?.Interfaces) {
            let HidePackagingInterfacesIds = this._commonServ.getSetttingValue("PharmacySettings", "Hide_PKG_INTERFACES");
            if (HidePackagingInterfacesIds && HidePackagingInterfacesIds.includes(',')) {
                HidePackagingInterfacesIds = HidePackagingInterfacesIds.split(',');
                this.systemData.Interfaces = this.systemData?.Interfaces.filter((item: any) => {
                    return !(HidePackagingInterfacesIds.includes(item['Id'].toString()))
                });
            }
            else if (HidePackagingInterfacesIds) {
                this.systemData.Interfaces = this.systemData?.Interfaces.filter((item: any) => {
                    return item['Id'] != HidePackagingInterfacesIds
                });
            }
        }
    this.Is340BPharm$ = this._commonServ.Is340BVal$;
        this.PatSettings.patchValue({
            IsEZCapReqd: this.PatSettings.value["IsEZCapReqd"] === null ? false : this.PatSettings.value["IsEZCapReqd"],
            IsPkgAllowed: this.PatSettings.value["IsPkgAllowed"] === null ? false : this.PatSettings.value["IsPkgAllowed"],
            IsPrintAllowed: this.PatSettings.value["IsPrintAllowed"] === null ? true : this.PatSettings.value["IsPrintAllowed"],
            // IsWorkflowPatient: this.PatSettings.value["IsWorkflowPatient"] === null ? false : this.PatSettings.value["IsWorkflowPatient"]
        });
        if (!(this.PatSettings && this.PatSettings.value["PatientId"])) {
            const defPatRefillRem = this._commonServ.getSetttingValue("RxSettings", "DEF_PAT_REF_REM");
            if (defPatRefillRem === "1") {
                this.PatSettings.controls["IsRefillRemSet"].patchValue(true);
            } else if (defPatRefillRem === "0") {
                this.PatSettings.controls["IsRefillRemSet"].patchValue(false);
            }
        } else if (this.PatSettings && this.PatSettings.value["PatientId"]) {
            this.PatSettings.controls["IsRefillRemSet"].patchValue(this.PatSettings.value["IsRefillRemSet"]);
        }
        this.get340bDetails();

    }
    get340bDetails()
    {
        this.insuIdFor340b = +this._commonServ.getSetttingValue("ThreeFourtyBSettings", "Ins_Code_340BSettings");

        const InsuranceList = this._commonServ.insuranceData$["source"]["value"];
        this.Insu340B = InsuranceList && InsuranceList.length ? InsuranceList.find(ins => ins.InsuCarrierId ===  this.insuIdFor340b) : null;
    }

    get Person(): FormGroup {
        return this.patientInfoFG.get("Person") as FormGroup;
    }

    get Patient(): FormGroup {
        return this.patientInfoFG.get("Patient") as FormGroup;
    }
    get PrimeInsurance(): FormGroup {
        return this.patientInfoFG.get("Insurances") as FormGroup;
    }

    get PatSettings(): FormGroup {
        return this.patientInfoFG.get("PatSettings") as FormGroup;
    }

    get PatientSplPrograms(): FormGroup {
        return this.patientInfoFG.get("PatientSplPrograms") as FormGroup;
    }

    get PatientChartNums(): FormGroup {
        return this.patientInfoFG.get("PatientChartNums") as FormGroup;
    }

    ngAfterViewInit() {
        this._cdr.detectChanges();
    }

    ngOnDestroy() {
        this._cdr.detach();
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    focusToElmById(id) {
        if (document.getElementById(id)) {
            setTimeout(() => {
                document.getElementById(id).focus();
            }, 50);
        }
    }

    checkFor340BIns(val: boolean = false) {
        if (val) {
            this.patch340BIns.emit(true);
            return;
        }
        if (this.Patient.value && this.Patient.value.Is340B && this.PrimeInsurance.value.InsurerId) {
             // check whether patient primary ins is 340b ins.
            if (this.PrimeInsurance.value.InsurerId !==  this.insuIdFor340b ) {
                const InsuranceList = this._commonServ.insuranceData$["source"]["value"];
                this.Insu340B = InsuranceList && InsuranceList.length ? InsuranceList.find(ins => ins.InsuCarrierId ===  this.insuIdFor340b) : null;
                this._modalServ.open(this.PatchIns340B , {backdrop: false, keyboard: false, windowClass: "large--content", centered: true});
            } else {
                this.patch340BIns.emit(false);
            }
        } else if (this.Patient && this.Patient.value && this.Patient.value.Is340B) {
            this._modalServ.open(this.PatchIns340B , {backdrop: false, keyboard: false, windowClass: "large--content", centered: true});
        }
    }
   async closePopUpFor340BInsurance() {
        const Insu340B: any  = this._patUtils.get340BInsurance(this.patientInfoFG.value);
        let isInsuExs: any  = false;
        if(Insu340B) {
          isInsuExs = await this._additionalInsu
          .isInsuranceExistForPatient(this.Patient.value.Id, Insu340B.InsuCarrierId, Insu340B.Id).toPromise();
          if(isInsuExs) {
            const modelRef = this._modalServ.open(CommonWarnorconfirmPopupComponent, {
                backdrop: false,
                keyboard: false, windowClass: "large--content"
            });
            modelRef.componentInstance.warnMsg = '340B Insurance <b>"' + Insu340B.PlanCode   + '"</b> already exists on the patient profile.';
            modelRef.componentInstance.okButton = true;
            modelRef.componentInstance.isPopSize = "max-wdth_29";
            modelRef.componentInstance.IsHeaderText = "Information";
            modelRef.componentInstance.IsPopUpClosed.pipe(takeUntil(this.unsubscribe$)).subscribe((resp: any) => {
                modelRef.close();
                this.Exist340bInsuranceIns.emit(true);
            });
          } else
                 this.After340bInsurancePopupClose.emit(true);
        }
    }
    onSelectPkg(val){
        this.PatSettings.patchValue({
            IsPkgAllowed: (val && val.Name !=="None")? true : false
        })
        if(!val){
            this.Patient.patchValue({
                InterfaceId: 2
            })
        }

    }
}
