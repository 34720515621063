import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from "@angular/core";
import { DrugUtils } from "src/app/utils";
import { DrugData } from "src/app/models";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormBuilder, FormGroup } from "@angular/forms";
import { FormatsUtil } from "src/app/utils/formats.util";

@Component({
    selector: "app-drug-sales-summary",
    templateUrl: "./drug-sales-summary.component.html"
})
export class DrugSalesSummaryComponent implements OnInit {

    @Input() drugInfo: DrugData;
    @Input() drugId: any;
    @Output() IsPopUpClosed = new EventEmitter<any>();
    @ViewChild("sales")
    sales: any;
    salesData: { salesResponse: any; graphData: any[]; RxCount: number; Quantity: number; Amount: number; RecvQty: number};
    SalesModal: any;
    DrugSalesFG: FormGroup;

    constructor(private _drugUtils: DrugUtils, private _modalSvc: NgbModal, private _fb: FormBuilder, public _formatsUtil:FormatsUtil) { }

    ngOnInit() {
    this.DrugSalesFG = this._fb.group({
        showEquivalents: false
    });
      this.getDefaultSales(true);
    }

    openModal() {
        this.SalesModal = this._modalSvc.open(this.sales, {
            centered: true,
            size: "lg",
            keyboard: false,
            windowClass: "md-x-lg"
        });
    }

    // setContent(ht) {   // As of now Data Label is removed.
    //     let setVal: any;
    //     if (ht && ht.value === 0) { return; }
    //     if (ht && ht.name === "Total Amount($)") {
    //         setVal = "$" + ht.value.toFixed(2);
    //     } else {
    //         setVal = ht.value.toFixed(3);
    //     }
    //     return setVal;
    // }

    tooltip(ht) {
        let tooltip: string;
        if (ht && ht.name === "Total Amount($)") {
            tooltip = "<b>" + ht.name + "</b><br/>" + ht.item.country + " $" + ht.value.toFixed(2);
        } else {
            tooltip = "<b>" + ht.name + "</b><br/>" + ht.item.country + " " + ht.value.toFixed(3);
        }
        return tooltip;
    }

    async getDefaultSales(isopen?) {
        this.salesData = await this._drugUtils.defaultSalesData(this.drugId, this.DrugSalesFG.value.showEquivalents);
        if (this.salesData && isopen) {
            this.openModal();
        }
    }

    closeModal() {
        if (this.SalesModal) {
            this.SalesModal.close();
        }
        this.IsPopUpClosed.emit(null);
    }
}
