import { Component, OnInit, Input, ViewChild, Output, EventEmitter, ChangeDetectorRef } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { SystemData, Drug } from "src/app/models";
import { CollectionView } from "@grapecity/wijmo";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as wjcGrid from "@grapecity/wijmo.grid"
import { MsgConfig } from "src/app";
import { AlertService, PatientService } from "src/app/services";
import { CommonUtil } from "src/app/utils";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import * as moment from "moment";

@Component({
    selector: "app-pat-allergies",
    templateUrl: "./pat-allergies.component.html"
})
export class PatAllergiesComponent implements OnInit {
    @Input() patientFG: FormGroup;
    @Input() systemData: SystemData;
    @Input() openModal: boolean;
    @Input() fromNpat: boolean;
    @Output() IsPopUpClosed = new EventEmitter();
    hasAllegry: boolean;
    AllergyWJ: CollectionView;
    hasselectedList: boolean;
    selectedListWJ: CollectionView;
    modalRef: any;
    delPopup: any;
    code: any;
    typeForPDO: any;
    spaceclick:boolean = false;
    ht:any;
    @ViewChild("allergey", { static: true })
    allergey: any;

    @ViewChild("delPop", { static: true })
    delPop: any;

    drugFocus: boolean;
    allergyList: wjcGrid.FlexGrid;
    selectWJ: wjcGrid.FlexGrid;
    selectedAlgy: any;
    selctedDrugAlg: any;
    slctOtherAlg: any;
    resetDrugName = false;
    selectedAlgyInfo: any;
    selectedAllergies: any;
    isFrmAllergies = true;
    warningText = {
        deleteHeaderTxt: null,
        deleteConfrmMsg: null
    }
    unsubscribe$: Subject<void> = new Subject();
    todayDate: string;
    constructor(private modalService: NgbModal, private _alertSvc: AlertService,
        private _commonUtil: CommonUtil, private _editPatient: PatientService, private _cdr: ChangeDetectorRef) {
         }

    ngOnInit() {        
        const patInfo = this.patientFG.value;
        this.selectedAlgyInfo = patInfo.PatAllergies ? patInfo.PatAllergies : [];
        this.selctedDrugAlg = patInfo.Drugs ? patInfo.Drugs : [];
        this.slctOtherAlg = patInfo.OtherAllergies ? patInfo.OtherAllergies : [];
        if (this.openModal) {
            this.openAllergiesPopUp();
        }
        this.warningText.deleteHeaderTxt = MsgConfig.Delete_Header_Text;
        this.warningText.deleteConfrmMsg = MsgConfig.Delete_Confirmation;
        this.todayDate = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss.S');
    }

    saveAllergies() {
        if(this.fromNpat) {
            this.selectedAlgyInfo = this.selectedAlgy;
            if(this.selectedAlgyInfo?.length || this.selctedDrugAlg?.length || this.slctOtherAlg?.length) {
                const allergyData = {PatientId: this.patientFG.value['patientid'], PatAllergies: this.selectedAlgy, Drugs: this.selctedDrugAlg, OtherAllergies: this.slctOtherAlg};
                this._editPatient.savePatientAllergy(allergyData).pipe(takeUntil(this.unsubscribe$)).subscribe(resp=> {
                    if(resp){
                        this.closeModal();
                        this._alertSvc.success(MsgConfig.SAVE_SUCCESS)
                    } else {
                        this.selectedAlgyInfo = [];
                        this.selctedDrugAlg = [];
                        this.slctOtherAlg = [];
                        this._alertSvc.error(MsgConfig.SAVE_FAIL)
                    }
                 });
            } else {
                this._alertSvc.error(MsgConfig.PATIENT_ALLERGY_NOTEXISTS);
            }
        } else {
            this.selectedAlgyInfo = this.selectedAlgy;
            const cntrlPairs = {PatAllergies: this.selectedAlgyInfo, Drugs: this.selctedDrugAlg, OtherAllergies: this.slctOtherAlg};
            Object.keys(cntrlPairs).map(cntlName => {
                    this._commonUtil.generateFA(cntlName, cntrlPairs[cntlName], this.patientFG);
            });
            if (this.selectedAllergies.length) {
                this.closeModal()
            } else {
                this._alertSvc.error(MsgConfig.PATIENT_ALLERGY_NOTEXISTS);
            }
        }        
    }

    openAllergiesPopUp() {
        this.generateNewSelectedAlgy();
        this.modalRef = this.modalService.open(this.allergey, { size: "lg" ,  windowClass: "modal-center-to-page", backdrop: false,
        keyboard: false});
    }

    generateNewSelectedAlgy() {
        const patInfo = this.patientFG.value
        this.selectedAlgyInfo = patInfo.PatAllergies ? patInfo.PatAllergies : [];
        this.selctedDrugAlg = patInfo.Drugs ? patInfo.Drugs : [];
        this.slctOtherAlg = patInfo.OtherAllergies ? patInfo.OtherAllergies : [];
        this.selectedAlgy = this.selectedAlgyInfo ? this.selectedAlgyInfo.map(item => { return item; }) : [];
        this.generateSelectedAllergyWJ();
        this.generateAllergyWJ(this.systemData["Allergies"]);
    }
    generateAllergyWJ(data) {
        if (!data) {
            data = [];
        }
        if (data) {
            this.hasAllegry = true;
            this.AllergyWJ = new CollectionView(
                data.map(allergy => {
                    const j = {};
                    j["Name"] = allergy.AllergyName ? allergy.AllergyName : "";
                    j["Code"] = allergy.ClassId;
                    if (this.selectedAlgy && this.selectedAlgy.length > 0) {
                        if (this.selectedAlgy.find(n => n.AllergyClassID === allergy.ClassId)) {
                            j["IsSelected"] = true;
                        } else {
                            j["IsSelected"] = false;
                        }
                    } else {
                        j["IsSelected"] = false;
                    }
                    j["Description"] = allergy.AllergyDescription;
                    return j;
                })
            );
        } else {
            this.hasAllegry = false;
        }
    }

    generateSelectedAllergyWJ() {
        const selectedList: any = [];
        if (this.selectedAlgy && this.selectedAlgy.length > 0) {
            this.selectedAlgy.map(value => {
                const data = {
                    Code: value["AllergyClassID"],
                    Name: value["AllergyDescription"],
                    Type: "A",
                    LstConfDttm : value["LstConfDttm"]
                };
                selectedList.push(data);
            });
        }
        if (this.selctedDrugAlg && this.selctedDrugAlg.length > 0) {
            this.selctedDrugAlg.map(value => {
                const data = {
                    Code: value["DrugCode"],
                    Name: value["Name"],
                    Type: "D",
                    LstConfDttm : this.todayDate
                };
                selectedList.push(data);
            });
        }
        if (this.slctOtherAlg && this.slctOtherAlg.length > 0) {
            this.slctOtherAlg.map((value,i) => {
                this.slctOtherAlg[i].LstConfDttm = this.todayDate;
                const data = {
                    Code: value["Id"],
                    Name: value["Name"],
                    Type: "O",
                    LstConfDttm : this.todayDate
                };
                selectedList.push(data);
            });
        }
        if (selectedList) {
            this.hasselectedList = true;
        } else {
            this.hasselectedList = false;
        }
        if (this.hasselectedList) {
            this.selectedAllergies = selectedList;
            this.selectedListWJ = new CollectionView(
                selectedList.map(list => {
                    const j = {};
                    j["Code"] = list["Code"];
                    j["Description"] = list["Name"];
                    j["Type"] = list["Type"];
                    j["Last Confirmed Date"] = moment.utc(list["LstConfDttm"]).format("MM/DD/YYYY");
                    return j;
                })
            );
        }
    }

    focusOutFromAllergySearch(event) {
        this.drugFocus = false;
    }

    filterAllergy(text) {
        if (text) {
            text =  text.trim();
        }
        if (text.length >= 1) {
            this.generateAllergyWJ(
                this.systemData.Allergies.filter(v => {
                const val = v.AllergyName ? v.AllergyName : "";
                return val
                    .toLowerCase()
                    .startsWith(text.toLocaleLowerCase());
            }));
        } else {
            this.generateAllergyWJ(this.systemData["Allergies"]);
        }
    }

    checkIfNoKnowAlgExist() {
        if (this.selectedAlgy && this.selectedAlgy.length > 0) {
            const noKnowAllergy = this.selectedAlgy.findIndex(a => a["AllergyClassID"] === 0);
            if (noKnowAllergy > -1) {
                this.selectedAlgy.splice(noKnowAllergy, 1);
                if (this.allergyList && document.getElementById("quick0")) {
                    document.getElementById("quick0")["checked"] = false;
                }
            }
        }
    }

    addAllergy(data) {
        if (data["AllergyClassID"] === 0 && this.selectWJ && this.selectWJ["itemsSource"]["items"] &&
             this.selectWJ["itemsSource"]["items"].length > 0) {
             this._alertSvc.error(MsgConfig.PATIENT_ALLERGY_REMOVEALL);
        } else if (this.selectedAlgy && this.selectedAlgy.length > 0 &&
            this.selectedAlgy.find(a => a["AllergyClassID"] === data["AllergyClassID"])) {
            this._alertSvc.error(MsgConfig.PATIENT_ALLERGY_EXISTS);
        } else {
            this.checkIfNoKnowAlgExist();
            this.selectedAlgy = this.selectedAlgy ? this.selectedAlgy : [];
            this.selectedAlgy.push(data);
        }
        this.generateSelectedAllergyWJ();
    }

    Init(grid) {
        grid?.cells.hostElement.addEventListener('keydown', (e) => {
            if (e.key == 'Enter') {
                grid.collectionView["_src"][grid.selection.row]["IsSelected"] = true;
                const selectedItem = grid.collectionView["_src"][grid.selection.row];
                    const data = {
                        AllergyName: selectedItem.Name,
                        AllergyClassID: selectedItem.Code,
                        AllergyDescription: selectedItem.Description,
                        LstConfDttm : this.todayDate
                    };
                this.addAllergy(data);
            }
        });
    }

    onRowDblclicked(s: any, e: any) {
        if (e && e.keyCode) {
            e.preventDefault();
            this.spaceclick = true
        }else {
            this.spaceclick=false;
             this.ht = s.hitTest(e);
        }
        if ((s.selectedItems[0] && this.spaceclick) || (s.selectedItems[0] && this.ht && this.ht.cellType === 1)) {
            if (!s.selectedItems[0]["IsSelected"]) {
                if (s.selectedItems[0]["Code"] === 0 && this.selectWJ && this.selectWJ["itemsSource"]["items"] &&
                    this.selectWJ["itemsSource"]["items"].length > 0) {
                    this._alertSvc.error(MsgConfig.PATIENT_ALLERGY_REMOVEALL);
                } else if (this.selectedAlgy && this.selectedAlgy.length > 0 &&
                    this.selectedAlgy.find(a => a["AllergyClassID"] === s.selectedItems[0]["Code"])) {
                    this._alertSvc.error(MsgConfig.PATIENT_ALLERGY_EXISTS);
                } else {
                    s.selectedItems[0]["IsSelected"] = true;
                    const data = {
                        AllergyName: s.selectedItems[0]["Name"],
                        AllergyClassID: s.selectedItems[0]["Code"],
                        AllergyDescription: s.selectedItems[0]["Description"],
                        LstConfDttm : this.todayDate  
                    };
                    this.addAllergy(data);
                }
            } else {
                s.selectedItems[0]["IsSelected"] = false;
                const index = this.selectedAlgy.findIndex(val => val["AllergyClassID"] === s.selectedItems[0]["Code"]);
                this.selectedAlgy.splice(index, 1);
                this.generateSelectedAllergyWJ();
            }
        }
    }

    clickOnCheckBox(checkValue, item, event) {
        if (checkValue) {
            if (item["Code"] === 0 && this.selectWJ && this.selectWJ["itemsSource"]["items"] &&
                this.selectWJ["itemsSource"]["items"].length > 0) {
                    this._alertSvc.error(MsgConfig.PATIENT_ALLERGY_REMOVEALL);
                event.target.checked = false;
                item["IsSelected"] = false;
            } else if (this.selectedAlgy && this.selectedAlgy.length > 0 &&
                this.selectedAlgy.find(a => a["AllergyClassID"] === item["Code"])) {
                this._alertSvc.error(MsgConfig.PATIENT_ALLERGY_EXISTS);
                event.target.checked = false;
                item["IsSelected"] = false;
            } else {
                const allergy = {
                    AllergyName: item["Name"],
                    AllergyClassID: item["Code"],
                    AllergyDescription: item["Description"],
                    LstConfDttm : this.todayDate
                };
                this.addAllergy(allergy);
            }
        } else {
            const index = this.selectedAlgy.findIndex(val => val["AllergyClassID"] === item["Code"]);
            this.selectedAlgy.splice(index, 1);
            this.generateSelectedAllergyWJ();
        }
        this.AllergyWJ.refresh();
    }

    selectedDrugInfo(data) {
        if (!data) {
            return;
        }
        this.resetDrugName = null;
        this._cdr.detectChanges();
        this.resetDrugName = data.drugname ? true : false;
        // setTimeout(() => {
        //     this.resetDrugName = data.drugname ? true : false;
        // }, 100);
        if (this.selctedDrugAlg && this.selctedDrugAlg.length > 0 &&
            this.selctedDrugAlg.find(val => val["DrugCode"] === data.drugcode)) {
                this._alertSvc.error(MsgConfig.PATIENT_ALLERGY_EXISTS);
        } else {
            this.checkIfNoKnowAlgExist();
            const drugmodal: Drug = new Drug();
            drugmodal.Name = data.drugname;
            drugmodal.DrugCode = data.drugcode;
            drugmodal.LstConfDttm = this.todayDate
            this.selctedDrugAlg = this.selctedDrugAlg  ? this.selctedDrugAlg : [];
            this.selctedDrugAlg.push(drugmodal);
        }
        this.generateSelectedAllergyWJ();
        this.resetDrugName = data.drugname ? true : false;
    }

    saveOtherAllergValue(eve) {
        const val = eve.target.value;
        if (val) {
            eve.target.value = "";
            this.slctOtherAlg.LstConfDttm = this.todayDate;
            if (this.slctOtherAlg && this.slctOtherAlg.length > 0 &&
                this.slctOtherAlg.find(o => o.Name === val)) {
                    this._alertSvc.error(MsgConfig.PATIENT_ALLERGY_EXISTS);
            } else {
                this.checkIfNoKnowAlgExist();
                this.slctOtherAlg = this.slctOtherAlg  ? this.slctOtherAlg : [];
                this.slctOtherAlg.push({Name: val, Id: null});
            }
            this.generateSelectedAllergyWJ();
        }
    }

    keyEventonSelectedWJ(grid) {
        if (this.hasAllegry) {
            grid?.cells.hostElement.addEventListener('keydown', (e) => {
                if (e.key == 'Enter') {
                    e.preventDefault();
                    this.deleteSelectedAllergy(grid.collectionView["_src"][grid.selection.row],grid.collectionView["_src"][grid.selection.row]["Type"],"PopUp");
                } 
            });   
        }
    }

    deleteSelectedAllergy(item, Type, Frm?: any) {
        const patInfo = this.patientFG.value;
        this.selectedAlgyInfo = patInfo.PatAllergies ? patInfo.PatAllergies : [];
        this.selctedDrugAlg = patInfo.Drugs ? patInfo.Drugs : [];
        this.slctOtherAlg = patInfo.OtherAllergies ? patInfo.OtherAllergies : [];
        let index:any;
        if (Type === "A") {
            if (this.selectedAlgy && this.selectedAlgy.length) {
                index = this.selectedAlgy.findIndex(val => val["AllergyClassID"] === (Frm === "PopUp" ? item.Code : item));
                this.selectedAlgy.splice(index, 1);
                this.generateAllergyWJ(this.selectedAlgy);
                if (Frm === "PopUp") {
                    if (document.getElementById("quick" + item.Code)) {
                        document.getElementById("quick" + item.Code)["value"] = null;
                }
                    this.generateAllergyWJ(this.systemData["Allergies"]);
                }
            }
            if (this.selectedAlgyInfo && this.selectedAlgyInfo.length) {
                index = this.selectedAlgyInfo.findIndex(val => val["AllergyClassID"] === (Frm === "PopUp" ? item.Code : item));
                this.selectedAlgyInfo.splice(index, 1);
                if (Frm === "PopUp") {
                    if (document.getElementById("quick" + item.Code)) {
                        document.getElementById("quick" + item.Code)["value"] = null;
                }
                    this.generateAllergyWJ(this.systemData["Allergies"]);
                }
            }
        } else if (Type === "D") {
                index = this.selctedDrugAlg.findIndex(val => val["DrugCode"] === (Frm === "PopUp" ? item.Code : item));
                this.selctedDrugAlg.splice(index, 1);
            
        } else if (Type === "O") {
                index = this.slctOtherAlg.findIndex(val => val["Name"] === (Frm === "PopUp" ? item.Description : item));
                this.slctOtherAlg.splice(index, 1);
        }
        if (Frm === "PopUp") {
            this.generateSelectedAllergyWJ();
        } else {
            if (Type === "A") {
                this._commonUtil.generateFA("PatAllergies", this.selectedAlgyInfo, this.patientFG);
            } else if (Type === "D") {
                this._commonUtil.generateFA("Drugs", this.selctedDrugAlg, this.patientFG);
            } else if (Type === "O") {
                this._commonUtil.generateFA("OtherAllergies", this.slctOtherAlg, this.patientFG);
            }
        }
    }

    openDeletePopup(code, type) {
        this.code = code;
        this.typeForPDO = type;
        this.delPopup = this.modalService.open(this.delPop , {
             centered: true,
             keyboard: false,
             backdrop: false,
             windowClass: "large--content"
        })
    }

    focusOutFromSelectedList(event) {
        if (document.getElementById("okbutton")) {
            event.preventDefault();
            this._cdr.detectChanges();
            document.getElementById("okbutton").focus();
        }
    }

    focustoSelectedList(event) {
        event.preventDefault();
        if (this.selectWJ) {
            this.selectWJ.focus();
        }
    }

    init(flex: wjcGrid.FlexGrid) {
        this.allergyList = flex;
        flex.columnHeaders.rows[0].wordWrap = true;
    }

    init1(flex: wjcGrid.FlexGrid) {
        this.selectWJ = flex;
        flex.columnHeaders.rows[0].wordWrap = true;
    }

    focusOutFromAllergyWJ(event, grid) {
        event.preventDefault();
        this.drugFocus = true;
    }

    closeModal() {
        if (this.openModal) {
            if(this.fromNpat) {
                let PatAlObject = (this.selectedAlgyInfo?.length || this.selctedDrugAlg?.length || this.slctOtherAlg?.length) ? [{Type:"A", selectedAlgyInfo:this.selectedAlgyInfo},{Type:"D", selctedDrugAlg:this.selctedDrugAlg},{Type:"O", slctOtherAlg:this.slctOtherAlg}] : []
                this.IsPopUpClosed.emit(PatAlObject); 
            } else this.IsPopUpClosed.emit(null);
        }
        if (this.modalRef) {
            this.modalRef.close();
            this.modalRef = null;
        }
    }
    ngOnDestroy(){
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
