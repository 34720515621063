<ng-template #searchData let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Search MMS Data</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="closeModal('Cross')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body edit-patient">
        <div class="erx--body">
            <div class="eprx--block__content">
                <form [formGroup]="refreshFG" *ngIf="!hideFilters">
                    <div class="col-sm-12 padding-0">
                        <div class="row">
                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                <eprx-input [AutoFocus]="true" [LabelText]="'Drug Name'"
                                [PlaceHolder]="'Drug Name - Enter min 3 Chars'" [ControlName]="'DrugName'" [FormGroupName]="refreshFG" [RxSelectId]="'DrugNameMMS'"
                                (TriggerOnEnterValue)="getDrugMMS('search')" [MinLength]="3" [FormGroupInvalid] = "formGroupInvalid" [MarkAsTouched]="refreshFG.get('DrugName').touched" [ErrorDefs]="{minlength: 'Enter min 3 Chars'}" >
                                </eprx-input>
                            </div>
                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                <eprx-input [LabelText]="'NDC'" [PlaceHolder]="'NDC - Enter min 3 Chars'" [ControlName]="'NDCCode'" [FormGroupName]="refreshFG"
                                [RxSelectId]="'NDCMMS'" [MarkAsTouched]="refreshFG.get('NDCCode').touched" [FormGroupInvalid] = "formGroupInvalid"
                                [BarCodeDetector]="true" (barCodeEmit)="getBarcodeInfo($event)" (TriggerOnEnterValue)="getDrugMMS('search')" [MinLength]="3" [ErrorDefs]="{minlength: 'Enter min 3 Chars'}" [InputType]="'ALPHANUMERIC'"   [mask]="'A*'">
                                </eprx-input>
                            </div>
                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 align-button text-right">
                                <button title="Search / Refresh" type="button" class="hotkey_success pull-right" (click)="getDrugMMS('search')"
                                    appShortcutKey [AltKey]="'f'">
                                    <span>F</span> Search
                                </button>
                                <button type="button" class="hotkey_success pull-right" (click)="clearFilters()"
                                    (keydown.tab)="focusToMMsData($event)" appShortcutKey [AltKey]="'l'">
                                    <span>L</span> CLEAR
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
                <div [ngClass]="
                        hideFilters ? 'show-transistion' : 'hide-transistion'
                    ">
                    <hr />
                    <div class="exprx--common-block">
                        <div class="eprx--block__header">
                            <div class="eprx--header__heading">
                                <div class="col-sm-12 padding-0">
                                    <div class="row">
                                        <div class="col-md-10">MMS Data</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="eprx--block__content">
                            <wj-flex-grid #refreshData (keydown)="cancelEventOnWijimo($event)"
                                [headersVisibility]="'Column'" [itemsSource]="mmsWJItemS" [isReadOnly]="true" [columnPicker]="'drugMMSSearchList'"
                                [selectionMode]="'Row'" [allowSorting]="true" [ngClass]="!hasMMSValues ? 'no-data grid max-height_35rem':'grid max-height_35rem'"
                                (updatedView)="init(refreshData)" (keydown.enter)="
                                    selectedMMSByEnter(refreshData, $event)
                                " (dblclick)="
                                    selectedMMSByEnter(refreshData, $event)
                                ">
                                <wj-flex-grid-column [header]="'Select'" [width]="112">
                                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-row = "row">
                                        <div class="insurance--body text-center">
                                            <span>
                                                <!-- <input *ngIf="mmsSearchEDI" type="radio"
                                                id="{{row.index}}" name="{{item.index}}"
                                                    (change)="selectedDrug(
                                                            $event?.target?.checked,
                                                            item)"
                                                    value="{{ item.index }}"
                                                    [checked]="(item.IsSelected)"
                                                /> -->
                                                <i class="far fa-file-import action actions--Highlightfocus--icon" title="Select" (click)="
                                                        selectMMSData(
                                                            item.Select
                                                        )
                                                    "></i>
                                                <!-- <i><input *ngIf="mmsSearchEDI" class="form-check-input ml-1" type="checkbox"  name="" [(ngModel)]="item.IsSelected"
                                                    [checked]="item.IsSelected" (click)="selectedDrug($event?.target?.checked, item)" /></i> -->
                                            </span>
                                        </div>
                                    </ng-template>
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="rxheader['hName']" [binding]="rxheader['hName']" [visible]="rxheader['isVisible']"
                                    *ngFor="let rxheader of wjHeaders;let i = index "  [width]="rxheader['width']" title="{{ rxheader['hName'] }}">
                                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                        <div  title="{{ item[rxheader['hName']] }}">
                                            {{ item[rxheader['hName']] }}
                                        </div>
                                    </ng-template>
                                </wj-flex-grid-column>
                                <app-no-data-found *ngIf="!isDataExists"></app-no-data-found>
                            </wj-flex-grid>
                            <app-eprx-pagination [TotalCount]="completeMMSData.length" [PageSize]="Size"
                                [WijmoName]="refreshData" [GridName]="'DrugMMS'"
                                (OnPageSelected)="setPage($event)" (OnSizeSelected)="setSize($event)"
                                *ngIf="hasMMSValues" [FromModal]="true"></app-eprx-pagination>
                        </div>
                    </div>
                </div>
                <div [ngClass]="
                        hideFilters ? 'hide-transistion' : 'show-transistion'
                    " class="show-transistion">
                    <hr />
                    <div class="exprx--common-block">
                        <div class="eprx--block__content edit--drug">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <wj-flex-grid #MMSdata [headersVisibility]="'Column'" [selectionMode]="'Row'"
                                        [itemsSource]="mmsObject" (keydown)="cancelEventOnWijimo($event)"
                                        [isReadOnly]="true" (keydown.space)="
                                            spaceEventOnWijimo($event, MMSdata)
                                        " (updatedView)="updateMMSInit(MMSdata)"
                                        (keydown.tab)="tabEventOnWijimo($event)">
                                        <wj-flex-grid-column [header]="'HEADING'" [width]="200" [binding]="'head'"
                                            [align]="'center'">
                                            {{ mmsObject?.head }}
                                        </wj-flex-grid-column>
                                        <wj-flex-grid-column [header]="'CURRENT VALUE'" [width]="350"
                                            [binding]="'currentvalue'" [align]="'center'">
                                            {{ mmsObject?.currentvalue }}
                                        </wj-flex-grid-column>
                                        <wj-flex-grid-column [header]="'MMS DATA VALUE'" [width]="350"
                                            [binding]="'MMsvalue'" [align]="'center'">
                                            {{ mmsObject?.MMsvalue }}
                                        </wj-flex-grid-column>
                                        <wj-flex-grid-column [header]="'USE THIS'" [width]="300" [align]="'center'">
                                            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                                <div *ngIf="item?.link">
                                                    <input class="form-check-input label-color" type="checkbox"
                                                        id="{{ item?.link }}" (change)="
                                                            refreshMMSData(
                                                                item,
                                                                $event.target
                                                                    .checked
                                                            )
                                                        " />
                                                    <label class="form-check-label" for="{{ item?.link }}">
                                                    </label>
                                                </div>
                                            </ng-template>
                                        </wj-flex-grid-column>
                                    </wj-flex-grid>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="hotkey_success" id="updateMMSButton" *ngIf="hideFilters" (click)="closeModal()" appShortcutKey [AltKey]="'u'">
           <span>U</span> UPDATE
        </button>
        <button type="button" *ngIf="hideFilters" class="hotkey_success"  (click)="resetMMSValues()" appShortcutKey [AltKey]="'c'">
           <span>C</span> CANCEL
        </button>
        <button type="button" *ngIf="!hideFilters" class="hotkey_success"  (click)="closeModal('Cancel')" appShortcutKey [AltKey]="'c'">
          <span>C</span>  CANCEL
        </button>
    </div>
</ng-template>
