import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { InsuranceRefComponent } from "./insurance-ref.component";
import { InsuranceTabsComponent } from "./insurance-tabs/insurance-tabs.component";
import { SharedModule } from "../shared/shared.module";
import { PlanInfoComponent } from "./plan-info/plan-info.component";
import { InsuSettingsComponent } from "./insu-settings/insu-settings.component";
import { InsuTransSettingComponent } from "./insu-trans-setting/insu-trans-setting.component";
import { InsuBillingComponent } from "./insu-billing/insu-billing.component";
import { OtherInsuComponent } from "./other-insu/other-insu.component";
import { WjGridModule } from "@grapecity/wijmo.angular2.grid";
import { InsuCommonHeaderComponent } from './insu-common-header/insu-common-header.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        WjGridModule,
    ],
    entryComponents: [InsuranceRefComponent],
    declarations: [InsuranceRefComponent, InsuranceTabsComponent, PlanInfoComponent,
        InsuSettingsComponent, InsuTransSettingComponent, InsuBillingComponent, OtherInsuComponent, InsuCommonHeaderComponent]
})
export class InsuranceRefModule { }
