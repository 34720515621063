
<div class="editpatient">
    <div class="row content--heading">
        <div class="col-md-8 padding-0">
            <div class="row">
                <div class="icon">
                    <img src="assets/dist/Icons/Icon_rx2.png">
                </div>
                <div class="col-md-11 padding-0">
                    <div class="content--heading__title padding-0">Durable Medical Equipment (DME)
                        <span class="content--name"></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-right pull-right col-md-4 padding-0">
            <button *ngIf="(this.routeFrom === 'nr' || this.routeFrom === 'nrf' || this.routeFrom === 'rf')"  class="hotkey_success" (click)="newRxDme()" tabindex="11" id="dmeSave"><span>S</span>Save</button>
            <button *ngIf="addDME && (this.routeFrom === 'er' || this.routeFrom === 'brx')"   class="hotkey_success" (click)="saveDME()" tabindex="11" id="dmeSave"><span>S</span>Save</button>
            <button *ngIf="!addDME && (this.routeFrom === 'er' || this.routeFrom === 'brx')"  class="hotkey_success" (click)="updateDME()" tabindex="11" id="dmeSave"><span>S</span>Save</button>
            <button class="hotkey_success"  *ngIf="(this.routeFrom === 'er' || this.routeFrom === 'brx')" (click)="closeModal()" tabindex="12" (blur)="foucsOnDIACode()" id="dmeCancel"><span>C</span>Cancel</button>
            <button class="hotkey_success"  *ngIf="(this.routeFrom === 'nr' || this.routeFrom === 'nrf' || this.routeFrom === 'rf')" (click)="closeModal()" tabindex="12" (blur)="foucsOnDIACode()" id="dmeCancel"><span>C</span>Cancel</button>
        </div>
    </div>
    <div class="exprx--common-block edit-drug">
            <div class="eprx--block__header">
                <div class="eprx--header__heading">
                    <div class="col-sm-12 padding-0">
                        <div class="row">
                            <div class="col-md-10">DME</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="eprx--block__content">
                    <div class="row">
                        <div class="col padding--right__7">
                            <!-- <div class="col-xs-12 padding--right__7">
                                <app-rx-select [RxSelectId]="'DiagnosisCodeDME'" [AutoFocus]="true" [LabelText]="'Diagnosis Code'" [PlaceHolder]="''" [ControlName]="'DiagCode'"
                                    [FormGroupName]="dmeFG" [List]="dMEDefaultValues?.Diagnosis" [RxSelectId]="'DiagnosisCodeDME'"
                                    [HasMultiple]="false" [BindLabel]="'DIAGCODE'" [BindValue]="'DIAGCODE'" [LabelForId]="'DIAGCODE'" [HasBindLabel2]="true"
                                    [BindLabel2]="'DIAGDESCR'" [TabIndex]="'1'" >
                                </app-rx-select>
                            </div> -->
                            <div class="col-xs-12 padding--right__7">
                                <eprx-input [RxSelectId]="'locatorCodeDME'" [LabelText]="'Locator Code'" [PlaceHolder]="''"  [ControlName]="'LocatorCode'"
                                    [FormGroupName]="dmeFG" [TabIndex]="'2'" [MaxLength]="2">
                                </eprx-input>
                            </div>
                            <div class="col-xs-12 padding--right__7">
                                <eprx-input [LabelText]="'Category Of Services'" [PlaceHolder]="''"
                                    [ControlName]="'ServiceCat'" [FormGroupName]="dmeFG" [TabIndex]="'5'" [MaxLength]="4">
                                </eprx-input>
                            </div>
                            <!-- <div class="col-xs-12 padding--right__7">
                                <eprx-input [LabelText]="'Place of Service'" [PlaceHolder]="''"
                                    [ControlName]="'ServicePlace'" [FormGroupName]="dmeFG" [TabIndex]="'3'" [MaxLength]="1">
                                </eprx-input>
                            </div> -->
                        </div>
                        <div class="col padding--left__7">
                            <div class="col-xs-12 padding--left__7">
                                <eprx-input [LabelText]="'Clinic Speciality'" [PlaceHolder]="''"
                                    [ControlName]="'ClinicSp'" [FormGroupName]="dmeFG" [TabIndex]="'4'" [MaxLength]="3">
                                </eprx-input>
                            </div>
                            <div class="col-xs-12 padding--left__7">
                                <eprx-input [LabelText]="'Accident Code'" [PlaceHolder]="''"  [ControlName]="'AccidentCode'"
                                    [FormGroupName]="dmeFG" [TabIndex]="'6'" [MaxLength]="1">
                                </eprx-input>
                            </div>
                        </div>
                    </div>
                        <div class="row">
                            <div class="col padding--right__7">
                                <div class="col-xs-12 padding--right__7">
                                    <eprx-radio [LabelText]="'Handicap Child Pg'" [ControlName]="'IsHandiChildPg'" [FormGroupName]="dmeFG"
                                        [List]="['Yes', 'No']" [ValueList]="[true, false]" [IDForList]="['IsHandiChildPg1', 'IsHandiChildPg2']" [TabIndex]="'7'">
                                    </eprx-radio>
                                </div>
                                <div class="col-xs-12 padding--right__7">
                                    <eprx-radio [LabelText]="'Family Planning'" [ControlName]="'IsFamilyPlan'" [FormGroupName]="dmeFG"
                                        [List]="['Yes', 'No']" [ValueList]="[true, false]" [IDForList]="['IsFamilyPlan1', 'IsFamilyPlan2']" [TabIndex]="'8'">
                                    </eprx-radio>
                                </div>
                            </div>
                            <div class="col padding--right__7">
                                <div class="col-xs-12 padding--right__7">
                                    <eprx-radio [LabelText]="'Possible Disability'" [ControlName]="'IsDisability'" [FormGroupName]="dmeFG"
                                        [List]="['Yes', 'No']" [ValueList]="[true, false]" [IDForList]="['IsDisability1', 'IsDisability2']" [TabIndex]="'9'">
                                    </eprx-radio>
                                </div>
                                <div class="col-xs-12 padding--right__7">
                                    <eprx-radio [LabelText]="'Rental'" [ControlName]="'IsRental'" [FormGroupName]="dmeFG" [List]="['Yes', 'No']"
                                     (keydown.tab)="focusOnSave($event)"   [ValueList]="[true, false]" [IDForList]="['IsRental1', 'IsRental2']" [TabIndex]="'10'">
                                    </eprx-radio>
                                </div>
                            </div>
                        </div>
                </div>
        </div>
</div>



