
<ng-template #PHYSICALINVEN let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">Add Physical Inventory Record</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')" (click)="closeCanclPhysicalInven(false)">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="eprx--block__content transmit--block">
            <div class="row">
                <div class="col-md-12">
                    <div class="exprx--common-block">
                        <div class="eprx--block__header">
                            <div class="eprx--header__heading">
                                Drug
                            </div>
                        </div>
                        <div class="eprx--block__content">
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 trasmit--rx">
                                    <div class="">
                                        <label> Drug Name </label>
                                        <div class="label--data font-weight-bold">
                                            {{
                                                drugInfo?.Drug?.Name
                                                    | uppercase
                                            }}
                                            {{
                                                drugInfo?.Drug?.Strength
                                                    ? (drugInfo?.Drug?.Strength | uppercase)
                                                    : " "
                                            }}
                                            {{
                                                drugInfo?.Drug?.DrugFormName
                                                    ? drugInfo?.Drug
                                                          ?.DrugFormName
                                                    : ("" | uppercase)
                                            }}
                                        </div>
                                    </div>
                                    <div class="">
                                        <label> Drug NDC </label>
                                        <div class="label--data font-weight-bold">
                                                {{this._formatsUtil.getNDCFormat(drugInfo?.Drug?.DrugCode)}}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 trasmit--rx">
                                    <div class="">
                                        <label> Exp. Date </label>
                                        <div class="label--data font-weight-bold">
                                            {{
                                                drugLotExpiryDate
                                            }}
                                        </div>
                                    </div>
                                    <div class="">
                                        <label> Manufacturer </label>
                                        <div class="label--data font-weight-bold">
                                            {{manuFacName ? (manuFacName | uppercase) : '--'}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">&nbsp;</div>
                    <div class="exprx--common-block">
                        <div class="eprx--block__header">
                            <div class="eprx--header__heading">
                                Quantity on Shelf
                            </div>
                        </div>
                        <div class="eprx--block__content">
                            <div class="row">
                                <div class="col-lg-4 col-md-4 col-xs-4 trasmit--rx">
                                    <eprx-input [LabelText]="'Packs'" [PlaceHolder]="'Packs'"
                                        [ControlName]="'packs'" [FormGroupName]="physcInventFG" [MaxLength]="5"
                                        [InputType]="'NUMBER'" [RxSelectId]="'packs'"  [DecimalValues]="0"
                                        (EmitOnChangeValue)="calculatePhysicInventTotalQty()">
                                    </eprx-input>
                                </div>
                                <div class="col-lg-4 col-md-4 col-xs-4 trasmit--rx">
                                    <eprx-input [LabelText]="'Loose Qty'" [PlaceHolder]="'Loose Qty'"
                                        [ControlName]="'looseQTY'" [DecimalValues]="3" [FormGroupName]="physcInventFG"
                                        [InputType]="'NUMBER'" [RxSelectId]="'looseQTY'"
                                        (EmitOnChangeValue)="calculatePhysicInventTotalQty('LooseQty')">
                                    </eprx-input>
                                </div>
                                <div class="col-lg-4 col-md-4 col-xs-4 trasmit--rx">
                                    <eprx-select [RxSelectId]="'bucketType'" [LabelText]="'Bucket'" [PlaceHolder]="''"
                                        [ControlName]="'bucketType'" [FormGroupName]="physcInventFG"
                                        [BindLabel]="'Name'" [BindValue]="'Id'" [LabelForId]="'Id'"
                                        [List]="bucketInfo?.length > 0 ? bucketInfo: ''" [InputValue]="''"
                                        [HasMultiple]="false" [HasBindLabel2]="false" [IsRequired]="true"
                                        [ErrorDefs]="{required: 'Required'}" [IsDisabled]="true"
                                        [MarkAsTouched]="physcInventFG?.get('bucketType')?.touched">
                                    </eprx-select>
                                </div>
                                   <div class="col-lg-4 col-md-4 col-xs-4 trasmit--rx">
                                    <eprx-input
                                    [LabelText]="'Quantity in Pack'" [PlaceHolder]="'Quantity in Pack'" [ControlName]="'QtyPack'" [IsDisabled]="true"
                                    [DropSpclChar]="false" [DecimalValues]="2"  [FormGroupName]="physcInventFG" [MaskPattern]="'099999.000'"
                                    [RxSelectId]="'QtyPack'">
                                </eprx-input>
                                </div>
                            <!--  <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 padding-0" style="padding-top: 25px;">
                                    <i class="far fa-search fa-lg actions" (click)="getSearchBucketList()"></i>
                                </div> -->
                            </div>
                            <div class="row">
                                <div class="col-lg-3 col-md-3 col-xs-3 trasmit--rx">
                                    <div class="">
                                        <label> Total Quantity </label>
                                        <div class="label--data font-weight-bold">
                                            {{totalQty | number : '1.3-3'}}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-9 col-md-9 col-xs-9 trasmit--rx">
                                    <div>
                                        <eprx-text-area [LabelText]="'Comments'" [ControlName]="'comments'"
                                            [FormGroupName]="physcInventFG" [RxSelectId]="'comments'"
                                            [PlaceHolder]="'Comments'" [Rows]="2" [IsRequired]="false">
                                        </eprx-text-area>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 edit--drug"  *ngIf="frmEDI810" >
                                    <img [alt]="'Drug Image'" [src]="ImageURL" class="rx-tablate--preview" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">&nbsp;</div>
                                <div class="col-12">
                                    <label class="notes-font"> NOTE: </label>
                                    <span class="notes-font" > Enter 0 if you currently do not
                                    have any Qty in stock. If you are
                                    receiving additional Inventory for this
                                    Drug. Please use the Receive Inventory
                                    or 810File processing option after
                                    enabling this feature. In order to
                                    manage the inventory properly. It is
                                    crucial that you correctly update the
                                    initial count of Qty in stock before
                                    using this drug.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12"></div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="text-right pull-right col-md-10 padding-0">
            <div>
                <button id="PHYSICALINVENOK" #PHYSICALINVENOK class="hotkey_success" appShortcutKey [AltKey]="'o'"
                    (click)="calculatePhysicalInvent()">
                    <span>O</span> Ok
                </button>
                <button class="hotkey_success mr-0" id="PHYSICALINVENCANCL" #PHYSICALINVENCANCL
                    appShortcutKey [AltKey]="'c'" (click)="closeCanclPhysicalInven(false)">
                    <span>C</span> Cancel
                </button>
            </div>
        </div>
    </div>
</ng-template>
