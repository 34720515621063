import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PrimeEscComponent } from './prime-esc.component';
import { SignByPatientComponent } from './sign-by-patient/sign-by-patient.component';
import { SignByDateComponent } from './sign-by-date/sign-by-date.component';
import { SignByRxComponent } from './sign-by-rx/sign-by-rx.component';
import { RxPickUpDetailComponent } from './rx-pick-up-detail/rx-pick-up-detail.component';
import { POSCopayCollectedGuard, RxPickupdetailsGuard, SigByPatientGuard, SignatureByDateGuard, SignatureByRxGuard } from 'src/app/guards';
import { PosCopayCollectedComponent } from './pos-copay-collected/pos-copay-collected.component';

const routes: Routes = [
    {
        path: "",
        component: PrimeEscComponent,
        children: [
            {
                path: "patientsign",
                component: SignByPatientComponent,
                canActivate: [SigByPatientGuard]
            },
            {
                path: "signbydate",
                component: SignByDateComponent,
                canActivate: [SignatureByDateGuard]
            },
            {
                path: "signbyrx",
                component: SignByRxComponent,
                canActivate: [SignatureByRxGuard]
            },
            {
                path: "pickUpDetails",
                component: RxPickUpDetailComponent,
                canActivate: [RxPickupdetailsGuard]
            },
            {
                path: "posCopayCollected",
                component: PosCopayCollectedComponent,
                canActivate: [POSCopayCollectedGuard]
            }
        ]
    }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PrimeEscRoutingModule { }
