import {
    Component,
    OnInit,
    ViewChild,
    Output,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    HostListener
} from "@angular/core";
import {
    NgbModalConfig,
    NgbModal,
    NgbActiveModal
} from "@ng-bootstrap/ng-bootstrap";
import {
    RxService,
    AlertService,
    KeyboardShortcuts,
    Unlisten,
    TransmissionService,
    CommonService,
    RxObservableService
} from "../../../services";
import { FormGroup } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { MaskConstant } from "src/app/app.mask-patterns";
import { PatientInsuList } from "src/app/models";
import * as _ from "lodash";
import { Subscription } from "rxjs/internal/Subscription";
import { RxStore } from "src/app/store";
@Component({
    selector: "app-tr-pat-only",
    templateUrl: "./tr-pat-only.component.html"
})
export class TRPatOnlyComponent implements OnInit, OnChanges, OnDestroy {
    trasmitRx: any;
    rxFG: FormGroup;
    modelRef: any;
    modelRef2: any;
    // keyboardShortcuts: KeyboardShortcuts;
    // unlisten: Unlisten;
    transcResp: string;
    transmitData: any;
    maskPattern: any;
    showCloseButton = false;
    rxInfo: any;
    patInsurList: PatientInsuList;
    warningMsg: string;
    actvIndex: number;
    patTransData: any;
    actvBatchId: number;
    subscriptions = new Subscription();
    activeModal: string;
    routeFrom: string;

    @Input()
    set RxFG(fg: FormGroup) {
        this.rxFG = fg as FormGroup;
    }

    @Input()
    set TransmitResponse(resp) {
        this.trasmitRx = resp;
        this.triggerModal();
        this.showCloseButton = true;
    }

    @Input()
    set RxInfo(ri) {
        this.rxInfo = ri;
    }

    @Input()
    set PatInsurList(pil: any) {
        this.patInsurList = pil;
    }

    @ViewChild("TRANSMITRX")
    TRANSMITRX: any;

    @Output()
    IsPopUpClosed = new EventEmitter<null>();

    @ViewChild("TRANSMISSIONCONF", { static: true })
    TRANSMISSIONCONF: any;

    @ViewChild("WARNINGMSGS", { static: true })
    WARNINGMSGS: any;

    @ViewChild("TRANSPATONLY", { static: true })
    TRANSPATONLY: any;

    @HostListener("window:keyup", ["$event"])
    keyEvent(event: KeyboardEvent) {
        if (event.which === 27 || event.which === 89) {
            // yes
            if (
                this.activeModal === "TRANSPATONLY" &&
                (this.trasmitRx.ResponseStatus === "P" ||
                    this.trasmitRx.ResponseStatus === "A")
            ) {
                this.continueTransmitProcess();
            } else if (this.activeModal === "TRANSMISSIONCONF") {
                this.saveTransmitClaims();
            } else if (this.activeModal === "WARNINGMSGS") {
                this.closeConfirmModal();
            }
        } else if (event.which === 78) {
            // no
            if (this.activeModal === "TRANSPATONLY") {
                this.closeAndReviewModal(this.trasmitRx.ResponseStatus);
                // this.closeModal();
            } else if (this.activeModal === "TRANSMISSIONCONF") {
                this.closeConfirmModal();
            }
        }
    }

    @HostListener("window:keydown.control.r", ["$event"])
    keyEvenCR(event: KeyboardEvent) {
        if (this.activeModal === "TRANSPATONLY") {
            event.preventDefault();
            this.routeToPrescriber();
        }
    }

    @HostListener("window:keydown.control.i", ["$event"])
    keyEvenCI(event: KeyboardEvent) {
        if (this.activeModal === "TRANSPATONLY") {
            event.preventDefault();
            this.routeToInsurence();
        }
    }

    @HostListener("window:keydown.control.d", ["$event"])
    keyEvenCD(event: KeyboardEvent) {
        if (this.activeModal === "TRANSPATONLY") {
            event.preventDefault();
            this.routeToDrug();
        }
    }

    @HostListener("window:keydown.control.p", ["$event"])
    keyEvenCP(event: KeyboardEvent) {
        if (this.activeModal === "TRANSPATONLY") {
            event.preventDefault();
            this.routeToPatient();
        }
    }

    constructor(
        config: NgbModalConfig,
        private _modalService: NgbModal,
        private _rxService: RxService,
        private _alertServ: AlertService,
        // keyboardShortcuts: KeyboardShortcuts,
        private _router: Router,
        private _grpTransServ: TransmissionService,
        private _commonServ: CommonService,
        private _actvRoute: ActivatedRoute,
        private _rxObserSer: RxObservableService,
        private _rxStore: RxStore
    ) {
        config.backdrop = "static";
        // this.keyboardShortcuts = keyboardShortcuts;
        this.maskPattern = MaskConstant;

        this.subscriptions.add(
            this._actvRoute.queryParams.subscribe(params => {
                if (Object.keys(params).length > 0) {
                    this.routeFrom = params.fm;
                }
            })
        );
    }

    ngOnInit() {
        // this.unlisten = this.keyboardShortcuts.listen(
        //     {
        //         "ctrl.R": (event: KeyboardEvent): void => {
        //             // this.saveRx();
        //         },
        //         "alt.I": (event: KeyboardEvent): void => {
        //             // this.activeModal = "Notes";
        //         },
        //         "ctrl.D": (event: KeyboardEvent): void => {
        //             this.routeToDrug();
        //         },
        //         "ctrl.P": (event: KeyboardEvent): void => {
        //             this.routeToPatient();
        //         }
        //     },
        //     {
        //         priority: 0,
        //         terminalWhitelist: ["/", "S", "C", "R", "Enter", "ctrl./"]
        //     }
        // );
        // if (insurence && insurence.BinNum && insurence["processorNumber"]) {
        //     this.initiateTransmitRxOnly();
        // } else if (insurence && insurence["processorNumber"]) {
        //     this.warningMsg = "MAG_Code is Required";
        //     this.activeModal.close();
        //     this.modelRef2 = this._modalService.open(this.WARNINGMSGS);
        // } else if (insurence && insurence.BinNum.length <= 5) {
        //     this.warningMsg = "BIN_NO must be valid";
        //     this.activeModal.close();
        //     this.modelRef2 = this._modalService.open(this.WARNINGMSGS);
        // } else {
        //     this.warningMsg = "BIN_NO is Required";
        //     this.activeModal.close();
        //     this.modelRef2 = this._modalService.open(this.WARNINGMSGS);
        // }
    }

    ngOnChanges() {
        if (this.patInsurList && this.rxFG) {
            const insurence = _.filter(this.patInsurList, {
                insuranceid: this.rxFG.value.RefillInsu.InsuId
            })[0];

            if (this.rxInfo) {
                this.transmitData = {
                    rxId: this.rxInfo.Prescription.Id,
                    PrescRefillId: this.rxInfo.PrescReFill.Id,
                    patientId: this.rxInfo.Prescription.PatientId,
                    TransType: "B1", // Hard coded for now
                    Ins_Code: this.rxInfo.insuranceInfo.insuCarrier.InsurerCode, //this.rxInfo.insuCarrier.InsurerCode,
                    BillAs: null,
                    PrescNum: this.rxInfo.Prescription.PrescNum
                };
            } else {
                this.transmitData = {
                    rxId: this.rxFG.value.Prescription.Id,
                    PrescRefillId: this.rxFG.value.PrescReFill.Id,
                    patientId: this.rxFG.value.Patient.patientid,
                    TransType: "B1", // Hard coded for now
                    Ins_Code: insurence ? insurence.InsurerCode : null,
                    BillAs: null,
                    PrescNum: this.rxFG.value.Prescription.PrescNum
                };
            }
            // if (insurence && insurence.BinNum && insurence.Pcn) {
            //     this.initiateTransmitRxOnly();
            // } else if (insurence && !insurence.policynum) {
            //     this.warningMsg = "MAG_Code is Required";
            //     this.modelRef2 = this._modalService.open(this.WARNINGMSGS);
            //     this.activeModal = "TRANSMISSIONCONF";
            // } else if (insurence && insurence.BinNum.length <= 5) {
            //     this.warningMsg = "BIN_NO must be valid";
            //     this.modelRef2 = this._modalService.open(this.WARNINGMSGS);
            //     this.activeModal = "TRANSMISSIONCONF";
            // } else if (insurence && !insurence.BinNum) {
            //     this.warningMsg = "BIN_NO is Required";
            //     this.modelRef2 = this._modalService.open(this.WARNINGMSGS);
            //     this.activeModal = "TRANSMISSIONCONF";
            // }

            if (insurence && insurence.binnum && insurence.policynum) {
                this.initiateTransmitRxOnly();
            } else if (insurence && !insurence.policynum) {
                this.warningMsg = "MAG_Code is Required.";
                this.modelRef2 = this._modalService.open(this.WARNINGMSGS , {keyboard: false, centered: true,
                     backdrop: false, windowClass: "large--content"});
                this.activeModal = "TRANSMISSIONCONF";
            } else if (
                insurence &&
                insurence.binnum &&
                insurence.binnum.length <= 5
            ) {
                this.warningMsg = "BIN_NO must be valid.";
                this.modelRef2 = this._modalService.open(this.WARNINGMSGS , {keyboard: false, centered: true,
                     backdrop: false, windowClass: "large--content"});
                this.activeModal = "TRANSMISSIONCONF";
            } else if (insurence && !insurence.binnum) {
                this.warningMsg = "BIN_NO is Required.";
                this.modelRef2 = this._modalService.open(this.WARNINGMSGS , {keyboard: false, centered: true,
                    backdrop: false, windowClass: "large--content"});
                this.activeModal = "TRANSMISSIONCONF";
            }
        }
    }

    initiateTransmitRxOnly() {
        this.subscriptions.add(
            this._rxService
                .transmitPatientOnlyNw(this.transmitData.patientId)
                .subscribe(resp => {
                    if (resp) {
                        this.actvIndex = 0;
                        const transmitPatData: any = resp;
                        this.patTransData = null;
                        if (transmitPatData) {
                            this.patTransData =
                                transmitPatData.transmissionDetails;
                            // this.actvBatchId = transmitPatData[0].BatchId;
                        } else {
                            this.patTransData = [];
                        }
                        this.initiatePatientPOPup();
                    }
                })
        );
    }

    initiatePatientPOPup() {
        this.trasmitRx = this.patTransData[this.actvIndex];
        // this.actvBatchId = transmitPatData[this.actvIndex].BatchId;
        this.modelRef = this._modalService.open(this.TRANSPATONLY, {
            backdrop: false,
            size: "lg",
            windowClass: "md-x-lg",
            keyboard:false,
        });
        this.activeModal = "TRANSPATONLY";

        if (this.trasmitRx.ResponseStatus === "P") {
            this.transcResp = "Paid";
        } else if (this.trasmitRx.ResponseStatus === "R") {
            this.transcResp = "Rejected";
        } else {
            if (this.trasmitRx.ResponseStatus === "A") {
                this.transcResp = "Approved";
            } else {
                this.transcResp = this.trasmitRx.ResponseStatus;
            }
        }
    }

    triggerModal() {
        if (this.trasmitRx.ResponseStatus === "P") {
            this.transcResp = "Paid";
        } else if (this.trasmitRx.ResponseStatus === "R") {
            this.transcResp = "Rejected";
        } else {
            if (this.trasmitRx.ResponseStatus === "A") {
                this.transcResp = "Approved";
            } else {
                this.transcResp = this.trasmitRx.ResponseStatus;
            }
        }
    }

    closeModal() {
        this.IsPopUpClosed.emit(null);
        if (this.modelRef) {
            this.modelRef.close();
        }
    }

    routeToPatient() {
        this.closeModal();
        this._router.navigate([
            "eprx/editpatient/" + this.trasmitRx.PatId + "/patientinfo"
        ]);
    }

    routeToDrug() {
        this.closeModal();
        this._router.navigate([
            "eprx/drug/" + this.trasmitRx.DrugId + "/druginfo"
        ]);
    }

    continueTransmitProcess() {
        if (
            (this.trasmitRx.ResponseStatus === "P" ||
                this.trasmitRx.ResponseStatus === "D") &&
            this.trasmitRx.BillIngredient > this.trasmitRx.IngredientCost
        ) {
            this.modelRef2 = this._modalService.open(this.TRANSMISSIONCONF, {keyboard: false, centered: true,
                 backdrop: false, windowClass: "large--content"});
            this.activeModal = "TRANSMISSIONCONF";
        } else {
            this.saveTransmitClaims();
        }
    }

    closeConfirmModal() {
        this.modelRef2.close();
    }

    saveTransmitClaims() {
        if (this.modelRef2) {
            this.modelRef2.close();
        }
        this.subscriptions.add(
            this._rxService
                .claimTransmitRxOnly(
                    this.transmitData.PrescRefillId,
                    2,
                    this.rxFG.value.Drug.id,
                    this.rxFG.value.PrescReFill.OrdQty
                )
                .subscribe(resp => {
                    if (resp) {
                        this._alertServ.success("Claim process completed successfully.");
                        if (this.modelRef) {
                        this.modelRef.close();
                        }
                        const billStat =
                        this.trasmitRx.ResponseStatus !== "R"
                            ? "P"
                            : "R";
                        this.closeAndReviewModal(billStat);
                    } else {
                        this._alertServ.error("Claim process unsuccessful.");
                    }
                })
        );
    }

    routeToPrescriber() {}

    routeToInsurence() {}

    closeAndReviewModal(stat) {
        if (this.trasmitRx && this.trasmitRx.BatchId) {
            this.subscriptions.add(
            this._grpTransServ
                .updateUserReviewStatus(this.trasmitRx.BatchId, stat)
                .subscribe(resp => {
                    if (resp) {
                        this.checkHasNextPatTransData();
                        if (this.rxInfo && this.rxInfo["PrescReFill"]) {
                            this.rxInfo["PrescReFill"]["ModifiedDtTm"] = new Date();
                            this._rxStore.storeRxInfo(this.rxInfo);
                        }
                    }
                }));
        } else {
            this.closeModal();
        }
    }

    ngOnDestroy() {
        if (this.subscriptions) {
            this.subscriptions.unsubscribe();
        }
    }

    checkHasNextPatTransData() {
        this.actvIndex += 1;
        if (this.patTransData[this.actvIndex]) {
            if (this.modelRef) {
                this.modelRef.close();
            }
            this.initiatePatientPOPup();
        } else {
            this.resetThisRx();
            if (this.modelRef) {
                this.modelRef.close();
            }
            this.IsPopUpClosed.emit(null);
        }
    }

    resetThisRx() {
        const defRxStart = this._commonServ.getSetttingValue(
            "RxSettings",
            "START_NEW_RX_FROM"
        );
        if (+defRxStart === 2) {
            localStorage.removeItem("prescriberInfo");
            localStorage.removeItem("drugInfo");
        } else if (+defRxStart === 1) {
            localStorage.removeItem("drugInfo");
        }
        localStorage.removeItem("RxInfo");
        localStorage.removeItem("RxID");
        localStorage.removeItem("patientId");
        localStorage.removeItem("PatientInfo");
        this._commonServ.storePatientIdinBS(null);
        this._rxObserSer.clearAllRxOtherInfoBS();
        this._router.navigate(["/eprx/rx"], {
            queryParams: {
                fm: this.routeFrom
            }
        });
    }
}
