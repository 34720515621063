<ng-template
    #REVERSECLAIM
    let-c="close"
    let-d="dismiss"
    class="el--popup"
>
    <app-custom-modal (oncrossBtnClick)="closeModal('crossClick')">
        <h4 header> Reverse Insurance Payments</h4>
        <span body>
            <div class="exprx--common-block">
                <div class="eprx--block__header">
                    <div class="eprx--header__heading">Fill Information</div>
                </div>
                <div class="eprx--block__content">
                    <div class="row trasmit--rx">
                        <div
                            class="col-lg-12 col-md-12 col-xs-12"
                        >
                            <table class="table m-0">
                                <thead>
                                    <tr>
                                        <th scope="col w-15" > Rx#</th>
                                        <th scope="col w-15" > Refill# </th>
                                        <th scope="col w-15" > Fill# </th>
                                        <th scope="col w-15" > Fill Date </th>
                                        <th scope="col w-15"> Qty Disp </th>
                                        <th scope="col w-15" > Days </th>
                                        <th scope="col w-15" > </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th class="w-15"> {{reverseClaimInfo?.PrescReFill?.PrescNum}} </th>
                                        <td class="w-15"> {{reverseClaimInfo?.PrescReFill?.ReFillNum}} </td>
                                        <td class="w-15"> {{!(reverseClaimInfo?.RxPay[0]?.FillNo) ?  '-' : reverseClaimInfo?.RxPay[0]?.FillNo }} </td>
                                        <td class="w-15"> {{reverseClaimInfo?.PrescReFill?.FillDtTm | datetimeUtc}} </td>
                                        <td class="w-15"> {{(reverseClaimInfo?.PrescReFill?.DispQty).toFixed(3)}} </td>
                                        <td class="w-15"> {{reverseClaimInfo?.PrescReFill?.SupplyDays}} </td>
                                        <td class="w-10" scope="col"> </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="exprx--common-block">
                <div class="eprx--block__header">
                    <div class="eprx--header__heading"> Adjudication Information </div>
                </div>
                <div class="eprx--block__content" *ngIf="totalCount && isViewContent">
                    <div class="row trasmit--rx">
                        <div class="col-lg-12 col-md-12 col-xs-12">
                         <wj-flex-grid  [headersVisibility]="'Column'"
                            [itemsSource]="rxTransmissionWJ" [selectionMode]="'None'">

                            </wj-flex-grid>
                        </div>
                    </div>
                </div>
                <div class="eprx--block__content eprx--block__footer" *ngIf="!totalCount">
                    <div>
                        <span></span>
                        <i class="fa fa-file"></i><br/>
                        No Data Found!
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-1 col-lg-1">&nbsp;</div>
                <div class="col-md-3 col-lg-3">
                    Reversal will be sent to
                </div>
                <div class="col-md-4 col-lg-4" >
                    <eprx-input [LabelText]="''" [HasControl]="false"
                    [InputValue]="reverseClaimInfo?.RxPay ? reverseClaimInfo?.RxPay[0]?.Ins_Code: ''"
                    [IsDisabled]="true">
                    </eprx-input>
                </div>
            </div>
        </span>
        <button
            ngbAutofocus
            footer
            class="hotkey_primary"
            (click)="checkNdTrnsmt('TRREV')"
            appShortcutKey InputKey="r"
        >
            <b>R</b> Transmit Reversal
        </button>
        <button
            footer
            class="hotkey_primary"
            (click)="checkNdTrnsmt('REV_RETR')"
            appShortcutKey InputKey="e"
        >
            <b>E</b> Reverse and Re-Transmit
        </button>

        <button
            footer
            class="hotkey_primary"
            (click)="transmitAdjstment()"
            appShortcutKey InputKey="b"
        >
        <b>B</b> Send Adjustment (B3)
        </button>

        <button
            footer
            class="hotkey_primary"
            (click)="checkNdTrnsmt('REVALL')"
            *ngIf="reverseClaimInfo?.RxPay?.length > 1"
            appShortcutKey InputKey="l"
        >
            <b>L</b> Reverse All
        </button>
        <button
            footer
            class="inactive"
            *ngIf="reverseClaimInfo?.RxPay?.length === 1"
        ><b>L</b>
            Reverse All
        </button>

        <button footer class="hotkey_primary" (click)="cancelRevModal()" appShortcutKey InputKey="c"><b>C</b> Cancel</button>
    </app-custom-modal>
</ng-template>


<ng-template #REVERSEALLCNFM let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeReverseAllCnfm()">
        <h4 header> Reverse Insurance Payments</h4>
        <span body> Reverse all Insurance payments. Are you sure? </span>
        <button
            footer
            autofocus
            type="submit"
            class="hotkey_primary"
            (click)="initiateReverseAll()"
            appShortcutKey InputKey="y"><b>Y</b> YES
        </button>
        <button
            footer
            type="submit"
            class="hotkey_primary"
            (click)="closeReverseAllCnfm()"
            appShortcutKey InputKey="n"
        >
            <b>N</b> No
        </button>
    </app-custom-modal>
</ng-template>

<ng-template #REVERSALRESTRICT let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="c('close');cancelRevModal()">
        <h4 header> Reversal Restriction</h4>
        <span body>
           Claim older than {{revAllowDays}} days is not allowed for Reversal.<br/>
            This claim is {{daysBild}} days old.
        </span>
        <button footer ngbAutofocus type="submit" class="hotkey_primary" (click)="c('close');cancelRevModal()" appShortcutKey
            InputKey="o"><b>O</b> OK
        </button>
    </app-custom-modal>
</ng-template>

<app-tr-rx-only *ngIf="transmsType === 'ReversReTrans' || transmsType === 'TransReverse'
    || transmsType === 'TransAdjst' || transmsType === 'ReTransmission'" [rxInfo]="rxFG?.value"
    [transmissionType]="transmsType" [insrncToRevrs]="insrncToRevrs" [insuPriorToRevrs]="insuPriorToRevrs"
    (IsTransmissionCompleted)="checkHasAnyPayRecrds($event)" [FromReverseClaimScreen]="true" [transreverseInfo]="reverseClaimInfo"></app-tr-rx-only>
