<div class="editpatient">
    <div class="row content--heading">
        <div class="col-md-8 padding-0">
            <div class="row">
                <div class="icon">
                    <img src="assets/dist/Icons/Icon_rx2.png">
                </div>
                <div class="col-md-11 padding-0">
                    <div class="content--heading__title padding-0">Workmen's Compensation Information
                        <span class="content--name"></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-right pull-right col-md-4 padding-0">

            <button class="hotkey_success" *ngIf="hasWCOMCopy" (click)="openCopyAlert(Copy)" id="wcomcopy" appShortcutKey [AltKey]="'o'">
                <span>O</span> Copy</button>
            <button class="inactive" *ngIf="!hasWCOMCopy"> <span>O</span> Copy</button>
            <button class="hotkey_success" id="WcomSave"
                (click)="saveWCOMInfo()" appShortcutKey [AltKey]="'s'">
                <span>S</span> Save</button>
            <button class="hotkey_success"
                (click)="cancelOnEdit()" appShortcutKey [AltKey]="'c'">
                <span>C</span> Cancel</button>
        </div>
    </div>
    <div class="exprx--common-block">
        <div class="eprx--block__content">
            <div class="row">
                <div class="col padding--right__7">
                    <div class="exprx--common-block">
                        <div class="eprx--block__header">
                            <div class="eprx--header__heading">
                                Carrier Information
                            </div>
                        </div>
                        <div class="eprx--block__content">
                            <div class="row">
                                <div class="col">
                                    <eprx-date-picker ngbAutofocus [LabelText]="'Date of Injury'" [PlaceHolder]="''"
                                        [ControlName]="'AccidentDt'" [FormGroupName]="WorkmanComp" [IsRequired]="false"
                                        [MaxDate]="maxDate" [AutoFocus]="true"></eprx-date-picker>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <eprx-select [LabelText]="'Carrier ID'" [PlaceHolder]="''" [RxSelectId]="'CarrierId'"
                                        [ControlName]="'InsuCarrierId'" [FormGroupName]="WorkmanComp"
                                        [List]="WCOMDefaultVal?.wcomInsuCarrier" [BindLabel2]="'InsurerCode'" [BindLabel]="'id'"
                                        [BindValue]="'id'" [LabelForId]="'InsurerCode'" [AddItem]="true" [SelectOnTab]="true"
                                        (TriggerSelectValue)="getCarrierValues($event?.value)"
                                        (TriggerOnClear)="getCarrierValues($event?.value)"
                                        (TriggerOnTabbing)="focusOutFromGroup()" [Show2Values]="true">
                                    </eprx-select>
                                </div>
                                <div class="col">
                                    <eprx-input [LabelText]="'Carrier Name'" [PlaceHolder]="''" [ControlName]="'Name'"
                                        [FormGroupName]="CarrOrg" [RxSelectId]="'carrName'" [MaxLength]="30"
                                        [IsRequired]="WorkmanComp?.value?.InsuCarrierId ? true: false">
                                    </eprx-input>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <eprx-input [LabelText]="'Address'" [PlaceHolder]="''"
                                        [ControlName]="'AddressLine1'" [FormGroupName]="CarrierAddress">
                                    </eprx-input>
                                </div>
                                <div class="col">
                                    <app-search-zip [FormGroupName]="CarrierAddress" [IsRequired]="false"
                                        [ControlName]="'ZipCode'" [zipExtnCntrl]="'Extension'"
                                        (OnZipSelected)="getCityStateFromZipCode($event, 'CarrierState', 'CarrierCity')"
                                        [FormGroupInvalid]="formGroupInvalid"></app-search-zip>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">

                                    <app-search-state [FormGroupName]="CarrierAddress" [ControlName]="'StateId'"
                                        [CityControlName]="'CityId'" [StateSelected]="carStateSelected"
                                        (OnStateSelected)="
                                                prescStateSelected($event, 'CarrierState')
                                            "></app-search-state>
                                </div>
                                <div class="col">

                                    <app-search-city [FormGroupName]="CarrierAddress" [ControlName]="'CityId'"
                                        [StateControlName]="'StateId'" [zipControl]="'ZipCode'" [CitySelected]="carCitySelected"
                                        (OnCitySelected)="prescCitySelected($event, 'CarrierCity')"></app-search-city>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col padding--right__7">
                    <div class="exprx--common-block">
                        <div class="eprx--block__header">
                            <div class="eprx--header__heading">
                                Employer Information
                            </div>
                        </div>
                        <div class="eprx--block__content">
                            <div class="row">
                                <div class="col">
                                    <eprx-input [LabelText]="'Name'" [RxSelectId]="'EmpName'" [PlaceHolder]="''" [ControlName]="'Name'"
                                        [FormGroupName]="EmpOrg" [MaxLength]="30">
                                    </eprx-input>
                                </div>
                                <div class="col">
                                    <eprx-input [LabelText]="'Contact'" [PlaceHolder]="''" [ControlName]="'ContPerson'"
                                        [FormGroupName]="EmpOrg">
                                    </eprx-input>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <eprx-input [LabelText]="'Phone#'" [PlaceHolder]="''" [ControlName]="'xPhone'"
                                        [FormGroupName]="EmPhn" [DropSpclChar]="true"
                                        [MaskPattern]="'(000)000-0000'"
                                        [ShowMask]="true">
                                    </eprx-input>
                                </div>
                                <div class="col">
                                    <eprx-input [LabelText]="'Claim#'" [PlaceHolder]="''" [ControlName]="'ClaimNum'"
                                        [FormGroupName]="WorkmanComp" [InputType]="'number'">
                                    </eprx-input>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <eprx-input [LabelText]="'Address'" [PlaceHolder]="''"
                                        [ControlName]="'AddressLine1'" [FormGroupName]="EmpAdress">
                                    </eprx-input>
                                </div>

                                <div class="col">
                                    <app-search-zip [FormGroupName]="EmpAdress" [IsRequired]="false"
                                        [ControlName]="'ZipCode'" [zipExtnCntrl]="'Extension'"
                                        (OnZipSelected)="getCityStateFromZipCode($event, 'EmployerState', 'EmployerCity')"
                                        [FormGroupInvalid]="formGroupInvalid"></app-search-zip>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <app-search-state [FormGroupName]="EmpAdress" [ControlName]="'StateId'"
                                        [CityControlName]="'CityId'" [StateSelected]="emplrStateSelected"
                                         (OnStateSelected)="
                                            prescStateSelected($event, 'EmployerState')
                                        "></app-search-state>
                                </div>
                                <div class="col">
                                    <app-search-city [FormGroupName]="EmpAdress" [ControlName]="'CityId'"
                                        [StateControlName]="'StateId'" [zipControl]="'ZipCode'" [CitySelected]="emplrCitySelected"
                                        (OnCitySelected)="prescCitySelected($event, 'EmployerCity')"></app-search-city>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col padding--right__7">
                    <div class="exprx--common-block">
                        <div class="eprx--block__header">
                            <div class="eprx--header__heading">
                                Payer Information
                            </div>
                        </div>
                        <div class="eprx--block__content">
                            <div class="row">
                                <div class="col">
                                    <eprx-select [LabelText]="'Bill Entity type'" [PlaceHolder]="''"
                                        [ControlName]="'BillEntCatId'" [FormGroupName]="WorkmanComp" [ShowLabel2]="true"
                                        [List]="WCOMDefaultVal?.BillEntCats" [BindLabel]="'Remarks'" [BindLabel2]="'Name'" [BindValue]="'Id'"
                                        [LabelForId]="'Name'" [bindScnCntrlV]="'Name'" [secondCntrlN]="'BillEntCatName'" [Show2Values]="true">
                                    </eprx-select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <eprx-input [LabelText]="'Gen. Eq. Prod ID'" [PlaceHolder]="''"
                                        [ControlName]="'GenEqProdNum'" [FormGroupName]="WorkmanComp"
                                        [InputType]="'Number'" [MaxLength]="1">
                                    </eprx-input>
                                </div>
                                <div class="col-md-6">
                                    <eprx-select [LabelText]="'Gen. Eq. Prod ID Q'" [PlaceHolder]="''" [updateDropDownPos]="true"
                                        [ControlName]="'GenEqProdId'" [FormGroupName]="WorkmanComp"
                                        [secondCntrlN]="'GenEqProdName'" [bindScnCntrlV]="'Name'"
                                        [List]="WCOMDefaultVal?.GenEqProds" [BindLabel]="'Remarks'" [BindLabel2]="'Name'" [ShowLabel2]="true" [BindValue]="'Id'"
                                        [LabelForId]="'Name'" [IsDisabled]="!(WorkmanComp?.value?.GenEqProdNum)" [Show2Values]="true">
                                    </eprx-select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <eprx-input [LabelText]="'Pay to ID'" [PlaceHolder]="''" [ControlName]="'PayToNum'"
                                        [FormGroupName]="WorkmanComp" [InputType]="'number'">
                                    </eprx-input>
                                </div>
                                <div class="col-md-6">
                                    <eprx-select [LabelText]="'Pay to Qualifier'" [PlaceHolder]="''" [ControlName]="'PayToQId'"
                                        [FormGroupName]="WorkmanComp" [List]="WCOMDefaultVal?.payToQ"
                                        [secondCntrlN]="'PayToQName'" [bindScnCntrlV]="'Name'" [Show2Values]="true"
                                        [BindLabel2]="'Name'" [ShowLabel2]="true" [BindValue]="'Id'" [LabelForId]="'Name'" [BindLabel]="'Code'">
                                    </eprx-select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <eprx-input [LabelText]="'Payor Name'" [PlaceHolder]="''"
                                        [ControlName]="'PayToName'" [FormGroupName]="WorkmanComp" [MaxLength]="30">
                                    </eprx-input>
                                </div>
                                <div class="col-md-6">
                                    <eprx-input [LabelText]="'Pay to Address'" [PlaceHolder]="''"
                                        [ControlName]="'AddressLine1'" [FormGroupName]="PayerAddress">
                                    </eprx-input>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <app-search-zip [FormGroupName]="PayerAddress" [IsRequired]="false"
                                        [ControlName]="'ZipCode'" [zipExtnCntrl]="'Extension'"
                                        (OnZipSelected)="getCityStateFromZipCode($event, 'PayToState', 'PayToCity')"
                                        [FormGroupInvalid]="formGroupInvalid"></app-search-zip>
                                </div>
                                <div class="col-md-6">
                                    <app-search-state [FormGroupName]="PayerAddress" [ControlName]="'StateId'"
                                        [CityControlName]="'CityId'" [StateSelected]="stateSelected"
                                        (OnStateSelected)="
                                            prescStateSelected($event, 'PayToState')
                                        "></app-search-state>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <app-search-city [FormGroupName]="PayerAddress" [ControlName]="'CityId'"
                                        [StateControlName]="'StateId'" [zipControl]="'ZipCode'" [CitySelected]="citySelected"
                                        (OnCitySelected)="prescCitySelected($event, 'PayToCity')"
                                        (keydown.tab)="focusToTop($event)"></app-search-city>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #Copy let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">Confirm Action</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">Are you sure you want to load last Workman Comp Record of this patient?</div>
    <div class="modal-footer">
        <div class="text-right pull-right col-md-10 padding-0">
            <div>
                <button class="hotkey_primary" (click)="d('Cross click')" (click)="copyLastWCOM()" appShortcutKey
                    InputKey="y"><b>Y</b> YES
                </button>
                <button class="hotkey_primary" (click)="d('Cross click')" appShortcutKey InputKey="c"><b>C</b>
                    CANCEL</button>
            </div>
        </div>
    </div>
</ng-template>
