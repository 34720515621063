<div class="editpatient--body newrx--body edit--drug">
    <div class="editpatient">
        <div class="row content--heading">
            <div class="col-md-8 padding-0">
                <div class="col-md-11 padding-0">
                    <div class="content--heading__title">
                        Reports |
                        <span class="content--name"> POS - Copay Collected </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="exprx--common-block">
        <div class="eprx--block__header">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-11">
                            <div class="eprx--header__heading">
                                <span> POS - Copay Collected</span>
                            </div>
                        </div>
                        <div class="col-1 padding_left_7rem">
                            <app-user-guide-icon [ActiveTabId]= "'Pos'"></app-user-guide-icon>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="eprx--block__content reports_default_height row mx-0">
            <div class="col-md-4 padding-0">
                <div class="row">
                    <div class="row col-md-12 margin_bottom_1rem">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <eprx-date-picker [AutoFocus]="true" [RxSelectId]="'FromPickDate'" [LabelText]="'From Filled Date'"
                                [PlaceHolder]="''" [ControlName]="'FromDate'" [FormGroupName]="posCopayCollectedFG" [MinDate]=""
                                [MaxDate]="">
                            </eprx-date-picker>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <eprx-date-picker [RxSelectId]="'ToPickDate'" [LabelText]="'To Filled Date'" [PlaceHolder]="''"
                                [ControlName]="'ToDate'" [FormGroupName]="posCopayCollectedFG" [MinDate]="" [MaxDate]="">
                            </eprx-date-picker>
                        </div>
                    </div>
                    <div class="row col-md-12 margin_bottom_1rem">
                        <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9">
                            <eprx-text-area [LabelText]="'Ins Type (Comma Separated)'" [ControlName]="'InsuranceType'" [FormGroupName]="posCopayCollectedFG"
                            [Rows]="4" [PlaceHolder]="'Ins Type (Comma Separated)'"
                            [RxSelectId]="'InsuTypeSearch'" [ReadOnly]="true">
                            </eprx-text-area>
                        </div>
                        <div class="text-right pull-right col-lg-3 col-md-3 col-sm-3 col-xs-3 px-0">
                            <button class="hotkey_success margin_top_3_5rem" (click)="openInsuSearch()" (keydown.enter)="openInsuSearch()" appShortcutKey [AltKey]="'i'">
                                <span>I</span>SELECT INS.</button>
                        </div>
                    </div>
                    <div class="row col-md-12 margin_bottom_1rem">
                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3" [formGroup]="posCopayCollectedFG">
                            <label class="form-check-label" [for]="'ForCopay'" id="'ForCopay'">For Copay</label>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6" [formGroup]="posCopayCollectedFG">
                            <input class="form-check-input" type="checkbox" [id]="'all1'" formControlName="ForCopay" (click)="forCopyChange($event?.target?.checked)"/>
                            <label class="form-check-label" [for]="'all1'" id="'all'">All</label>
                        </div>
                    </div>
                    <div class="row col-md-12 margin_bottom_1rem">
                        <div class="col-3">
                            <label class="form-check-label float-right" [for]="'ForCopayOr'" id="'ForCopayOr'">(OR)</label>
                        </div>
                    </div>
                    <div class="row col-md-12 margin_bottom_1rem">
                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3" [formGroup]="posCopayCollectedFG">
                            <label class="form-check-label" [for]="'ForCopayLess'" id="'ForCopayLess'">For Copay <</label>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 margin_bottom_n2rem">
                            <eprx-input [RxSelectId]="'copayVal'" [LabelText]="''" PlaceHolder="For Copay <"
                                [ControlName]="'ForCopayLessThan'" [FormGroupName]="posCopayCollectedFG" [DecimalValues]="0" [InputType]="'NUMBER'" [IsDisabled]="copayValEnableDisable" (TriggerOnBlur)="enableDisableCheckBox($event)">
                            </eprx-input>
                        </div>
                    </div>
                    <div class="col-md-12 messsage-height">
                        <label class="col-md-12 padding-0"> Sort By </label>
                        <eprx-radio [ControlName]="'SortBy'" [FormGroupName]="posCopayCollectedFG" [List]="['Patient', 'Rx#', 'Date Filled']" [ValueList]="['Patient', 'Rx', 'Date Filled']"
                            [IDForList]="[9, 10, 11]" [LabelForId]="" >
                        </eprx-radio>
                    </div>

                        <div class="col-md-12" >
                            <button
                              *ngIf = "!reportIsInProgress"
                                type="submit"
                                class="hotkey_success pull-right"
                                (click)="previewRxPickupAck(true)"
                                appShortcutKey
                                [AltKey]="'p'"
                                (keydown.enter)="previewRxPickupAck(true)"
                                id="Print"
                            >
                                <span>P</span> Print
                            </button>
                            <button type="submit" class="inactive" *ngIf = "reportIsInProgress" id="Print"  [title] ="reportIsInProgress"
                          >  <span>P</span>Print
                          </button>
                            <button
                                *ngIf = "!reportIsInProgress"
                                type="submit"
                                class="hotkey_success pull-right"
                                (click)="previewRxPickupAck(false)"
                                appShortcutKey
                                [AltKey]="'v'"
                                (keydown.enter)="previewRxPickupAck(false)"
                                id="Preview"
                            > <span>V</span>
                                Preview
                            </button>
                            <button type="submit" class="inactive" *ngIf = "reportIsInProgress" id="Preview" [title] ="reportIsInProgress"
                            > <span>V</span>Preview
                          </button>
                            <button
                                type="submit"
                                class="hotkey_success pull-right"
                                (click)="previewRxPickupAck(false, true)"
                                appShortcutKey
                                [AltKey]="'j'" *ngIf = "!reportIsInProgress"
                                (keydown.enter)="previewRxPickupAck(false, true)"
                                id="Export"
                            > <span>J</span>
                                Export
                            </button>
                            <button type="submit" class="inactive pull-right" *ngIf = "reportIsInProgress" id="Export" [title] ="reportIsInProgress"
                            > <span>J</span>Export
                          </button>
                            <button
                                type="button"
                                class="hotkey_success pull-right"
                                (click)="routeBack()"
                                appShortcutKey
                                [AltKey]="'c'"
                                (keydown.enter)="routeBack()"
                                id="Cancel" >
                                <span>C</span> Cancel
                            </button>
                        </div>
                </div>
            </div>
            <div class="col-md-8" *ngIf="showPreview">
                <iframe [src]="pdfContent" class="text-center" toolbar="0" width="100%" height="100%"
                    type="application/pdf"></iframe>
            </div>
            <div class="col-md-8" *ngIf="!showPreview">
                <h3 class="reports--condi"> Please click on "PREVIEW" button to see reports </h3>
            </div>
        </div>
    </div>
</div>

<ng-template #insuPopUp let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Please Select Insurance</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="setFocusOnInsuType();d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-4">
                <eprx-input [AutoFocus]="true" [LabelText]="'Filter'" [PlaceHolder]="'Search Category Code'"
                    (TriggerChangeValue)="fiterInsu($event)" [HasControl]="false">
                </eprx-input>
            </div>
        </div>
        <wj-flex-grid #InsuList [headersVisibility]="'Column'" [selectionMode]="'Row'" [itemsSource]="insuWJ" [columnPicker]= "'primeescRxPickUpDetInsuWJ'"
            [isReadOnly]="true" (keydown.space)="preventSpaceEvent($event);selectCode(InsuList.selectedItems[0],null)">
            <wj-flex-grid-column header="Select" [width]="57">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-cell="cell">
                    <span  class="text-center">
                        <input class="form-check-input" type="checkbox" [id]="item.RowId" name=""
                            [(ngModel)]="item.IsSelected" [checked]="item.IsSelected" (click)="selectCode(item,$event)"/>
                        <label class="form-check-label" [for]="item.RowId">
                        </label>
                    </span>
                </ng-template>
            </wj-flex-grid-column>
            <wj-flex-grid-column [header]="rxheader['hName']" [binding]="rxheader['hName']" [visible]="rxheader['isVisible']" [width]="rxheader['width']"
            *ngFor="let rxheader of wjHeaders; let i = index">
            <ng-template  wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-row="row">
                <div *ngIf="(rxheader['hName'] === 'Address')"  title = {{item.FullAddress|uppercase}}>
                    {{ item[rxheader['hName']] }}
                </div>
                <div *ngIf="!(rxheader['hName'] === 'Address')" title = {{item[rxheader.hName]|uppercase}}>
                    {{ item[rxheader['hName']] }}
                </div>
            </ng-template>
            </wj-flex-grid-column>
        </wj-flex-grid>
    </div>
    <div class="modal-footer">
        <button type="button" id="custmAdd" class="hotkey_success" (click)="addSelectedValue();c('Close click')" appShortcutKey
            [AltKey]="'a'"><span>A</span> Add Selected</button>
        <button type="button" class="hotkey_success" (click)="setFocusOnInsuType();c('Close click')" appShortcutKey
            [AltKey]="'c'"><span>C</span> Close</button>
    </div>
</ng-template>
